import Swal from 'sweetalert2';
import React, {Component} from 'react';
import VendorProfileForm from "./VendorProfileForm";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

class VendorProfile extends Component {
    state = {
        insurance_file: '',
        certification_file: '',
        business_registration_file: ''
    }
    componentDidMount() {
        const {match, read, getProfessions} = this.props;
        if (match.params.id) {
            read(match.params.id);
        }

        getProfessions()
    }

    setInsuranceFile = (file) => {
        this.setState({insurance_file: file})
    }

    setCertificationFile = (file) => {
        this.setState({certification_file: file})
    }
    setBusinessRegistrationFile = (file) => {
        this.setState({business_registration_file: file})
    }

    componentDidUpdate(prevProps) {
        if (this.props.item.working_area !== prevProps.item.working_area) {
            this.props.setWorkingArea({
                latitude_center: this.props.item.working_area ? this.props.item.working_area.latitude_center : prevProps.item.working_area.latitude_center,
                longitude_center: this.props.item.working_area ? this.props.item.working_area.longitude_center : prevProps.item.working_area.longitude_center,
            })
        }
    }

    submit = (data) => {
        const {item} = this.props;
        const {insurance_file, certification_file, business_registration_file}= this.state
        if (item && item.id) {
            data.professions = data.profession_ids
            console.log('DATA: ', data);
            this.props.edit(item.id, data, [
                { name: "insurance", file: insurance_file },
                { name: "certification", file: certification_file },
                { name: "business_registration", file: business_registration_file },
            ]);
        }
    }

    setWorkingArea = (data) => {
        if (data) {
            const {latitude, longitude} = data;
            if (latitude && longitude) {
                this.props.setWorkingArea({
                    latitude,
                    longitude,
                })
            }
        }else {
            this.props.setWorkingArea({
                latitude: 0,
                longitude: 0,
            })
        }
    }

    goToEditProfessions = () => {
        const {item} = this.props;
        if (item && item.id) {
            this.props.history.push(`/vendor/vendors/${item.id}/experties`)
        }
    }


    render() {
        //  State
        const {loader, item, professions} = this.props;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={() => this.props.history.goBack()}>
                                <i className="material-icons pointer">arrow_back</i> 
                            </button>
                            Vendor Profile
                        </div>
                    </div>
                </div>
                <div
                    className="card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <VendorProfileForm
                            item={item}
                            optionProfessions={professions}
                            loader={loader}
                            goBack={()=> this.props.history.goBack()}
                            onSubmit={this.submit}
                            setInsuranceFile={this.setInsuranceFile}
                            setCertificationFile={this.setCertificationFile}
                            setBusinessRegistrationFile={this.setBusinessRegistrationFile}
                            setWorkingArea={this.setWorkingArea}
                            goToEditProfessions={this.goToEditProfessions}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default VendorProfile;
