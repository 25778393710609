import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '../Utils/GridTable';
import {standardActions} from "../Utils/Grid/StandardActions";
import HeaderCard from "../HeaderCard";
import CustomerFeedbackFilterForm from "./CustomerFeedbackFilterForm"


export default class FeedbackFormCustomerList extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {};

    state ={
        gridHeight: 200
    }

    componentWillMount() {
        const {toList, page} = this.props;
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("CUSTOMER-FEEDBACK-FORM");
            if (elementId) {
                _height = elementId.clientHeight
            }
            this.setState({gridHeight: _height})
            toList(page);
        }, 300);
    }

    render() {
        const {data, loader, toList: onPageChange, remove, onSortChange, searchChange} = this.props;
        const { gridHeight } = this.state;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page"> Customer Feedback Form</div>
                    </div>
                </div>
                <CustomerFeedbackFilterForm searchAction={searchChange}/>
                <div id="CUSTOMER-FEEDBACK-FORM" className="container-list card border-dark border-card-page px-4 py-2">
                    <Grid
                        hover
                        keyField="id"
                        striped
                        data={data}
                        height={gridHeight}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        wrapperClasses="table-scroll-auto"
                        columns={[
                            {
                                dataField: "id",
                                formatter: standardActions({
                                    remove, edit: "feedback-form-customer",
                                }),
                                text: "Actions",
                            },
                            {
                                dataField: "name",
                                sort: true,
                                text: "Name",
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}
