import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderNumber,
    SelectField,
    AsyncSelectField,
    renderFileField,
    renderAddressField
} from "../../Utils/renderField/renderField";
import {api} from "../../../../utility/api";

const validate = (values, props) => {
    let errors = {};
    const {is_update, item} = props;

    if (!values.type) {
        errors.type = "This field is required";
    }
    if (is_update && item) {
        if(values.sponsored_by && values.sponsored_by.value == item.id) {
            errors.sponsored_by = "Select a different option than the one you are modifying"
        }
    }

    return errors;
};

const InfluencerForm = (props) => {
    //  State
    const { handleSubmit, is_update, item } = props;
    //  Bind
    const { setFile, goBack, setCityState } = props;

    const loadInfluencer = (search, callback) => {
        api.get("influencer", {search, ordering: "first_name"}).then((response) => {
            let options = [{label: 'None', value: null}];
            response.results.forEach((result) => {
                options.push({
                    label: `${result.first_name} ${result.last_name}`,
                    value: result.id,
                });
            });
            callback(options);
        }).catch(() => {
        });
    };

    // console.log("ITEM: ", item);

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto mt-3 w-75">
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="first_name">First Name</label>
                    <Field
                        name="first_name"
                        label="first_name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="last_name">Last Name</label>
                    <Field
                        name="last_name"
                        label="last_name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="email">Email</label>
                    <Field
                        name="email"
                        label="email"
                        component={renderField}
                        type="email"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-3 col-sm-12 form-group has-feedback">
                    <label htmlFor="phone_number">Mobile number</label>
                    <Field
                        name="phone_number"
                        placeholder="Mobile number"
                        component={renderNumber}
                        className="form-control"
                        numberFormat={"###-###-####"}
                    />
                </div>
                <div className="col-lg-3 col-sm-12 form-group has-feedback">
                    <label htmlFor="office_phone_number">
                        Office Phone number
                    </label>
                    <Field
                        name="office_phone_number"
                        placeholder="Office Phone number"
                        component={renderNumber}
                        className="form-control"
                        numberFormat={"###-###-####"}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="address">Address</label>
                    <Field
                        name="address"
                        label="address"
                        component={renderAddressField}
                        type="text"
                        className="form-control"
                        disabled={false}
                        setCityState={setCityState}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="franchise_name">Franchise</label>
                    <Field
                        name="franchise_name"
                        label="franchise_name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="re_license">RE License</label>
                    <Field
                        name="re_license"
                        label="re_license"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-3 col-sm-12 form-group has-feedback">
                    <label htmlFor="broker_office_name">
                        Broker Office Name
                    </label>
                    <Field
                        name="broker_office_name"
                        label="broker_office_name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-3 col-sm-12 form-group has-feedback">
                    <label htmlFor="broker_office_license">
                        Broker License Number
                    </label>
                    <Field
                        name="broker_office_license"
                        label="broker_office_license"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>

                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="sponsored_by">Sponsored by</label>
                    <Field
                        name="sponsored_by"
                        placeholder="Select..."
                        loadOptions={loadInfluencer}
                        component={AsyncSelectField}
                        disabled={false}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="profit_share">Profite Share</label>
                    <Field
                        name="profit_share"
                        id="profit_share"
                        disabled={false}
                        placeholder="Profite Share"
                        allowNegative={false}
                        suffix="%"
                        decimalScale={2}
                        component={renderNumber}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="type">Influencer / Broker</label>
                    <Field
                        name="type"
                        id="type"
                        placeholder="Select..."
                        options={[
                            { value: 100, label: "Influencer" },
                            { value: 200, label: "Broker" },
                        ]}
                        component={SelectField}
                        disabled={false}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback mb-4">
                    <label htmlFor="agreement">Agreement</label>
                    {is_update ? (
                        <Field
                            name="agreement"
                            setFile={setFile}
                            fileAccept=".pdf,.doc,.docx"
                            component={renderFileField}
                            fileData={item && item.agreement_data ? item.agreement_data : null}
                        />
                    ): (
                        <Field
                            name="agreement"
                            setFile={setFile}
                            fileAccept=".pdf,.doc,.docx"
                            component={renderFileField}
                        />
                    )}
                </div>
                {
                    is_update && item && item.user && (
                        <div className="form-group col-lg-6 col-md-12 col-sm-12 mb-0 mt-4">
                            <label className="mt-n4 d-block" style={{ fontSize: '1.05rem' }}> Account Settings</label>
                            <div className="row w-100 m-0 h-100">
                                <div className='rounded border col-sm-12'>
                                    <div className='row'>
                                        <div className="form-group has-feedback col-sm-12">
                                            <label htmlFor="is_active" className="mt-4 d-block ml-3">Password</label>
                                            <Link
                                                className="btn button-03 ml-3 px-4"
                                                to={`/influencers/influencer/${item.user.id}/changed-password`}
                                            >
                                                Change Password
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <button
                    onClick={goBack}
                    type="button"
                    className="btn text-white btn-secondary  mx-3 col-2"
                >
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary mx-3 col-2">
                    Save
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "influencerForm", // a unique identifier for this form
    validate,
})(InfluencerForm);
