import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/gifCardDesign";
import GifCardDesignCreateUpdate from './GifCardDesignCreateUpdate';


const ms2p = (state) => {
    return {
        ...state.gifCardDesign,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(GifCardDesignCreateUpdate);
