import {connect} from 'react-redux';
import {actions} from '../../../../redux/modules/broker/broker';
import ValidateBroker from "./ValidateBroker";

import formValueSelector from "redux-form/lib/formValueSelector";

const selector = formValueSelector('brokerForm');

const ms2p = (state) => {
    return {
        ...state.broker,
        franchiseSelected: selector(state, 'franchise'),
        provideAgentsRosterValue: selector(state, 'provide_agents_roster')
    };
};

const md2p = {...actions};

export default connect(ms2p, md2p)(ValidateBroker);
