import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/promotion";
import PromotionList from './PromotionList';


const ms2p = (state) => {
    return {
        ...state.promotions,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(PromotionList);