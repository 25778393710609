import { connect } from 'react-redux';
import { getJobItem, assignAddressData, postEditJob, changeDate, validateToCompleteJob, completeJob, rAgentCancel, dAgentCancel, noShowCompleteJob, removeNoShowStatus, validateNoShowCompleteJob } from '../../../../../../redux/modules/stats';
import EditJob from "./EditJob";

const ms2p = (state) => {
    return {
        item: state.stats.jobItem,
        loader: state.stats.jobLoader,
        noShowComplete: state.stats.noShowComplete,
        selectAddressComplete: state.stats.selectAddressComplete,
    };
};

const md2p = {
    getJobItem,
    assignAddressData,
    postEditJob,
    changeDate,
    completeJob,
    rAgentCancel,
    dAgentCancel,
    noShowCompleteJob,
    removeNoShowStatus,
    validateToCompleteJob,
    validateNoShowCompleteJob,
};

export default connect(ms2p, md2p)(EditJob);