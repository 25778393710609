import { connect } from 'react-redux'
import InfluencerList from './InfluencerList'
import {actions} from '../../../../redux/modules/influencer'

const mapStateToProps = (state, ownProps) => ({
    ...state.influencer
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerList)
