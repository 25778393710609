import React, {useEffect, useRef, useState} from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {Chart} from 'chart.js';
import classNames from 'classnames';
import ReactStars from "react-rating-stars-component";
import Select, { Creatable, Async } from 'react-select';
import Tabs, {TabPane, TabContent} from 'rc-tabs';
import InkTabBar from "rc-tabs/lib/InkTabBar";
import ChartDataLabels from 'chartjs-plugin-datalabels';


const RangerField = (props) => {
    //  State
    const {
        value,
        options=[
            {label: 'Daily', value: 'daily'},
            {label: 'Weekly', value: 'weekly'},
            {label: 'Monthly', value: 'monthly'},
            {label: 'Quarterly', value: 'quarterly'},
            {label: 'Annually', value: 'annually'}
        ]
    } = props
    //  Bind
    const {setValue} = props;
    return (
        <Select
            className={classNames('react-select-container')}
            backspaceRemovesValue={false}
            // isMulti={isMulti}
            // isSearchable={isSearchable}
            options={options}
            // placeholder={placeholder}
            onChange={(e) => {
                setValue && setValue(e);
            }}
            value={value}
            // isDisabled={disabled}
            theme={theme => ({
                ...theme,
                borderRadius: 5,
                borderLeft: "1px solid #C7C7C7",
                borderRight: "1px solid #C7C7C7",
                borderTop: "1px solid #C7C7C7",
                borderBottom: "1px solid #C7C7C7",
                fontSize: "12px !important",
                color: '7b7b7b !important',
                colors: {
                    ...theme.colors,
                    primary25: '#F1F1F1', // style to select one option
                    primary: '#7E8A97',  // style of option selected
                },
            })}
            // styles={customStyles}
        />
    )
}

const TypeField = (props) => {
    //  State
    const {value} = props
    //  Bind
    const {setValue} = props;
    return (
        <Select
            className={classNames('react-select-container')}
            backspaceRemovesValue={false}
            // isMulti={isMulti}
            // isSearchable={isSearchable}
            options={[
                {label: 'All', value: ''},
                {label: 'Job Type', value: 'job_type'},
            ]}
            // placeholder={placeholder}
            onChange={(e) => {
                setValue && setValue(e);
            }}
            value={value}
            // isDisabled={disabled}
            theme={theme => ({
                ...theme,
                borderRadius: 5,
                borderLeft: "1px solid #C7C7C7",
                borderRight: "1px solid #C7C7C7",
                borderTop: "1px solid #C7C7C7",
                borderBottom: "1px solid #C7C7C7",
                fontSize: "12px !important",
                color: '7b7b7b !important',
                colors: {
                    ...theme.colors,
                    primary25: '#F1F1F1', // style to select one option
                    primary: '#7E8A97',  // style of option selected
                },
            })}
            // styles={customStyles}
        />
    )
}

const ShowITCategory = (props) => {
    //  State
    const {data, loader, ranger} = props;
    //  Bind
    const {setRanger} = props;
    const [lastCategoryName, setLastCategoryName] = useState("Canceled");
    const [totalCategoryBars, setTotalCategoryBars] = useState(0);

    useEffect(() => {
        if (data && data.datasets && data.datasets.length) {
            const bars = sumDatasets(data.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalCategoryBars(totalBars.length)
        } else {
            setTotalCategoryBars(0)
        }

        if (data && data.datasets && data.datasets.length) {
            const _lastCategoryName = data.datasets[data.datasets.length-1].label;
            if(lastCategoryName != _lastCategoryName) {
                setLastCategoryName(_lastCategoryName)
            }
        }else {
            setLastCategoryName('Canceled')
        }
      
    }, [data.datasets])

    const sumDatasets = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.data.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num;
                } else {
                    newArray[index] = num;
                }
            });
        });
        return newArray;
    }

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Categories",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    // pointStyle: 'dash',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalCategoryBars > 12 ? "9.5": "14", family: "Poppins", weight: 'bold' },
                // formatter: function (value, context) {
                //     const data = context.chart.data;
                //     let total = 0;
                //     data.datasets.forEach(item => {
                //         const _val = item.data[context.dataIndex];
                //         if (_val > 0) {
                //             total += _val;
                //         }
                //     })
                //     if (context.dataset.label.toUpperCase().includes(lastCategoryName.toUpperCase())) {
                //         if (total > 0) {
                //             return `${total.toFixed(1)}`;
                //         }else {
                //             return ''
                //         }
                //     }else {
                //         return ''
                //     }
                // },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `${total.toFixed(1)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="px-0 mx-0 row w-100 pb-2">
            <div className="col-sm-12">
                <div className="row justify-content-between">
                    <div className="form-group col-sm-12 col-lg-4 mb-0">
                        <label className="ml-1 label-semi-bold mt-2">Ranger</label>
                        <div className="w-100 px-1 mb-2">
                            <RangerField value={ranger} setValue={(e) => {
                                console.log("Value: ", e);
                                setRanger(e, 'show_it');
                            }} />
                        </div>
                    </div>
                    <div className="mb-2 col-sm-12 col-lg-4">
                        <h6 className="mb-0 font-weight-bold text-center">Total Revenue</h6>
                        <h4 className="font-weight-bolder text-center my-0">
                            $ {data.total ? data.total.toFixed(2) : "0.00"}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 px-0">
                <LoadMask loading={loader} dark blur>
                    <div className="px-2 flex-1 pb-3 dashboard-graph">
                        <Bar data={data} options={options} plugins={[ChartDataLabels]} height={300} />
                    </div>
                </LoadMask>
            </div>
        </div>
    )
}

export const ShowITChart = (props) => {
    //  State
    const { loader, ranger, dataShowITCategory } = props;
    //  Bind
    const {setRanger} = props;

    return (
        <div className="card border-card-page col-lg-6 col-sm-12 mr-2 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    ShowIT Revenue
                </label>
            </div>
            <div className="w-100">
                <ShowITCategory data={dataShowITCategory} loader={loader} ranger={ranger} setRanger={setRanger}/>
            </div>
        </div>
    );
};

export const JobChart = (props) => {
    //  State
    const { loader, ranger, dataCategory } = props;
    //  Bind
    const {setRanger} = props;
    const [lastJobName, setLastJobName] = useState("Runner");
    const [lastCategoryName, setLastCategoryName] = useState("Canceled");
    const [totalCategoryBars, setTotalCategoryBars] = useState(0);

    const categories = [
        {name: 'Posted', background: '#757575', color: '#FFFFFF'},
        {name: 'Applied', background: '#82b1ff', color: '#FFFFFF'},
        {name: 'Hired/Claimed', background: '#2196f3', color: '#FFFFFF'},
        {name: 'Completed', background: '#2e7d32', color: '#282828'},
        {name: 'Expired', background: '#F2DCBB', color: '#282828'},
        {name: 'Canceled', background: '#c62828', color: '#282828'},
    ]

    useEffect(() => {
        if (dataCategory && dataCategory.datasets && dataCategory.datasets.length) {
            const bars = sumDatasets(dataCategory.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalCategoryBars(totalBars.length)
        } else {
            setTotalCategoryBars(0)
        }

        if (dataCategory && dataCategory.datasets && dataCategory.datasets.length) {
            const _lastJobName = dataCategory.datasets[dataCategory.datasets.length-1].label;
            if(lastJobName != _lastJobName) {
                setLastCategoryName(_lastJobName)
            }
        }else {
            setLastCategoryName('Canceled')
        }
      
    }, [dataCategory.datasets])

    const sumDatasets = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.data.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num;
                } else {
                    newArray[index] = num;
                }
            });
        });
        return newArray;
    }

    const optionsCategory = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Categories",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    // pointStyle: 'dash',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalCategoryBars > 12 ? "9.5": "14", family: "Poppins", weight: 'bold' },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `${total.toFixed(0)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="card border-card-page col ml-lg-2 mt-lg-0 mt-sm-3 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Jobs
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12">
                    <div className="row justify-content-between">
                        <div className="form-group col-sm-12 col-lg-4 mb-0">
                            <label className="ml-1 label-semi-bold mt-2">Ranger</label>
                            <div className="w-100 px-1 mb-2">
                                <RangerField value={ranger} setValue={(e) => {
                                    console.log("Value: ", e);
                                    setRanger(e, 'show_it');
                                }} />
                            </div>
                        </div>
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total Jobs</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {dataCategory.total}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Bar data={dataCategory} options={optionsCategory} plugins={[ChartDataLabels]} height={300} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export const JobStatus = (props) => {
    //  State
    const { loader, jobType, data } = props;
    //  Bind
    const {setJobType} = props;
    const [totalBars, setTotalBars] = useState(0);

    useEffect(() => {
        if (data && data.datasets && data.datasets.length) {
            const bars = sumDatasets(data.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalBars(totalBars.length)
        } else {
            setTotalBars(0)
        }
    }, [data.datasets])

    const sumDatasets = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.data.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num;
                } else {
                    newArray[index] = num;
                }
            });
        });
        return newArray;
    }
    const options  = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Categories",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    // pointStyle: 'dash',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalBars > 12 ? "9.5": "14", family: "Poppins", weight: 'bold' },
                formatter: function (value, context) {
                    if (value > 0) {
                        return value
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: jobType.value === 'job_type' ? null:  {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="card border-card-page col-lg-6 col-sm-12 mr-2 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Job Status
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12">
                    <div className="row justify-content-between">
                        <div className="form-group col-sm-12 col-lg-4 mb-0">
                            <label className="ml-1 label-semi-bold mt-2">Type</label>
                            <div className="w-100 px-1 mb-2">
                                <TypeField value={jobType} setValue={(e) => {
                                    setJobType(e, 'show_it');
                                }} />
                            </div>
                        </div>
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {data.total}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Bar data={data} options={options} plugins={[ChartDataLabels]} height={320} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export const JobType = (props) => {
    //  State
    const { loader, ranger, data } = props;
    //  Bind
    const {setRanger} = props;
    const [totalBars, setTotalBars] = useState(0);

    useEffect(() => {
        if (data && data.datasets && data.datasets.length) {
            const bars = sumDatasets(data.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalBars(totalBars.length)
        } else {
            setTotalBars(0)
        }
    }, [data.datasets])

    const sumDatasets = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.data.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num;
                } else {
                    newArray[index] = num;
                }
            });
        });
        return newArray;
    }

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Categories",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    // pointStyle: 'dash',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalBars > 12 ? "9.5": "14", family: "Poppins", weight: 'bold' },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `${total.toFixed(0)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="card border-card-page col ml-lg-2 mt-lg-0 mt-sm-3 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Job Type
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12 mb-2">
                    <div className="row justify-content-end">
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {data.total}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Bar data={data} options={options} plugins={[ChartDataLabels]} height={320} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export const ActiveAgents = (props) => {
    //  State
    const { loader, data } = props;

    return (
        <div className="card border-card-page col-lg-6 col-sm-12 mr-2 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Active Agents
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12 mb-2">
                    <div className="row justify-content-end ">
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {data.total}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Doughnut
                                data={data}
                                height={250}
                                plugins={[ChartDataLabels]}
                                options={{
                                    responsive:true,
                                    maintainAspectRatio:false,
                                    defaultFontSize:"14px",
                                    plugins: {
                                        legend: {
                                            display: true,
                                            color: "#282828"
                                        },
                                        datalabels: {
                                            color: "black",
                                            formatter: function (value, context) {
                                                if (value > 0) {
                                                    return value
                                                }else {
                                                    return ''
                                                }
                                            },
                                        }
                                    }
                                }} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export const AgentSignedUp = (props) => {
    //  State
    const { loader, data } = props;

    return (
        <div className="card border-card-page col ml-lg-2 mt-lg-0 mt-sm-3 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Agent Signed Up by Office - Top 20 Offices
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12 mb-2">
                    <div className="row justify-content-end ">
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {data.total}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Bar
                                data={data}
                                height={320}
                                plugins={[ChartDataLabels]}
                                options={{
                                    responsive:true,
                                    maintainAspectRatio:false,
                                    defaultFontSize:"14px",
                                    layout: {
                                        padding: {
                                            top: 20,
                                            right: 0,
                                            left: 0,
                                            bottom: 0
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                            color: "#282828"
                                        },
                                        datalabels: {
                                            display: true,
                                            color: "black",
                                            align: "end",
                                            anchor: "end",
                                            font: { size: "14", family: "Poppins", weight: 'bold' },
                                            formatter: function (value, context) {
                                                if (value > 0) {
                                                    return value
                                                }else {
                                                    return ''
                                                }
                                            },
                                        }
                                    }
                                }} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export const JobPerformed = (props) => {
    //  State
    const { loader, data } = props;

    return (
        <div className="card border-card-page col ml-lg-2 mt-lg-0 mt-sm-3 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Job Performed by Agents - Top 20 Offices
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12 mb-2">
                    <div className="row justify-content-end ">
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {data.total}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Bar
                                data={data}
                                height={320}
                                plugins={[ChartDataLabels]}
                                options={{
                                    responsive:true,
                                    maintainAspectRatio:false,
                                    defaultFontSize:"14px",
                                    layout: {
                                        padding: {
                                            top: 20,
                                            right: 0,
                                            left: 0,
                                            bottom: 0
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                            color: "#282828"
                                        },
                                        datalabels: {
                                            display: true,
                                            color: "black",
                                            align: "end",
                                            anchor: "end",
                                            font: { size: "14", family: "Poppins", weight: 'bold' },
                                            formatter: function (value, context) {
                                                if (value > 0) {
                                                    return value
                                                }else {
                                                    return ''
                                                }
                                            },
                                        }
                                    }
                                }} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export const JobPosted = (props) => {
    //  State
    const { loader, data } = props;

    return (
        <div className="card border-card-page col-lg-6 col-sm-12 mr-2 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Job Posted by Agents - Top 20 Offices
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12 mb-2">
                    <div className="row justify-content-end ">
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {data.total}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Bar
                                data={data}
                                height={320}
                                plugins={[ChartDataLabels]}
                                options={{
                                    responsive:true,
                                    maintainAspectRatio:false,
                                    defaultFontSize:"14px",
                                    layout: {
                                        padding: {
                                            top: 20,
                                            right: 0,
                                            left: 0,
                                            bottom: 0
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                            color: "#282828"
                                        },
                                        datalabels: {
                                            display: true,
                                            color: "black",
                                            align: "end",
                                            anchor: "end",
                                            font: { size: "14", family: "Poppins", weight: 'bold' },
                                            formatter: function (value, context) {
                                                if (value > 0) {
                                                    return value
                                                }else {
                                                    return ''
                                                }
                                            },
                                        }
                                    }
                                }} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

export const AgentSignUpTrend = (props) => {
    //  State
    const { loader, data, ranger } = props;
    //  Bind
    const {
        setRanger
    } = props;

    return (
        <div className="card border-card-page col-lg-6 col-sm-12 mr-2 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Agent Sign Up Trend
                </label>
            </div>
            <div className="px-0 mx-0 row w-100 pb-2">
                <div className="col-sm-12 ">
                    <div className="row justify-content-between">
                        <div className="form-group col-sm-12 col-lg-4 mb-0">
                            <label className="ml-1 label-semi-bold mt-2">Ranger</label>
                            <div className="w-100 px-1 mb-2">
                                <RangerField
                                    value={ranger}
                                    options={[
                                        {label: 'Daily', value: 'daily'},
                                        {label: 'Weekly', value: 'weekly'},
                                        {label: 'Monthly', value: 'monthly'},
                                        {label: 'Quarterly', value: 'quarterly'},
                                        {label: 'Annually', value: 'annually'},
                                        {label: 'All', value: 'all'}
                                    ]}
                                    setValue={(e) => {
                                    console.log("Value: ", e);
                                    setRanger(e);
                                }} />
                            </div>
                        </div>
                        <div className="mb-2 col-sm-12 col-lg-4">
                            <h6 className="mb-0 font-weight-bold text-center">Total</h6>
                            <h4 className="font-weight-bolder text-center my-0">
                                {data.total ? data.total : "0"}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-12 px-0">
                    <LoadMask loading={loader} dark blur>
                        <div className="px-2 pb-3 flex-1 dashboard-graph">
                            <Bar
                                data={data}
                                height={320}
                                plugins={[ChartDataLabels]}
                                options={{
                                    responsive:true,
                                    maintainAspectRatio:false,
                                    defaultFontSize:"14px",
                                    layout: {
                                        padding: {
                                            top: 20,
                                            right: 0,
                                            left: 0,
                                            bottom: 0
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                            color: "#282828"
                                        },
                                        datalabels: {
                                            display: true,
                                            color: "black",
                                            align: "end",
                                            anchor: "end",
                                            font: { size: "14", family: "Poppins", weight: 'bold' },
                                            formatter: function (value, context) {
                                                if (value > 0) {
                                                    return value
                                                }else {
                                                    return ''
                                                }
                                            },
                                        }
                                    }
                                }} />
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};