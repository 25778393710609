import React, { Component } from "react";
import InfluencerTeamGrid from "./InfluencerTeamGrid";
import InfluencerTeamFilter from "./InfluencerTeamFilter";

class InfluencerTeamList extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.toList();
    }

    render() {
        //  State
        const { data, page, loader } = this.props;
        //  Bind
        const { changeFilters, toList, onSortChange, sendInvite } = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Team</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <InfluencerTeamFilter changeFilters={changeFilters} />
                </div>
                <InfluencerTeamGrid
                    data={data}
                    page={page}
                    loader={loader}
                    toList={toList}
                    onSortChange={onSortChange}
                    sendInvite={sendInvite}
                />
            </div>
        );
    }
}

export default InfluencerTeamList;
