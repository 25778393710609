import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import LoadMask from "../../../../Utils/LoadMask/LoadMask";
import EditJobForm from "./EditJobForm"
import NoShowCompleteJobForm from "./NoShowCompleteJobForm";
import SelectAddressesToCompleteJobForm from "./SelectAddressesToCompleteJobForm";

export default function EditJob(props) {
    //  State
    const { open, id, item, loader, noShowComplete } = props;
    //  Bind
    const {
        onClose,
        getJobItem,
        assignAddressData,
        postEditJob,
        changeDate,
        validateToCompleteJob,
        completeJob,
        rAgentCancel,
        dAgentCancel,
        noShowCompleteJob,
        removeNoShowStatus,
        selectAddressComplete,
        validateNoShowCompleteJob,
    } = props;

    const [isDisabled, setIsDisabled] = useState(false)
    const [showCompleteJobBtn, setShowCompleteJobBtn] = useState(false)

    useEffect(() => {
        if (id !== null) {
            getJobItem(id);
        }

        return () => {
            onClose();
        };
    }, [id]);

    useEffect(() => {
        if (item !== null) {
            const HIRED = 25
            const R_CANCEL = 35
            if (item.state_job <= HIRED) {
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
            }
            //  item.no_show
            if (item.state_job >= HIRED && item.state_job < R_CANCEL) {
                setShowCompleteJobBtn(true)
            } else {
                setShowCompleteJobBtn(false)
            }
        }
    }, [item]);

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-sm-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4" style={{minHeight: '400px'}}>
                <LoadMask loading={loader} dark blur>
                    <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                        <div className="">
                            <div className="header-page col">
                                {noShowComplete || selectAddressComplete ? 'Complete Job' : 'Edit Job'}
                            </div>
                        </div>
                    </div>

                    {selectAddressComplete && (
                        <SelectAddressesToCompleteJobForm
                            item={item}
                            loader={loader}
                            onSubmit={(data) => {
                                const {addresses} = data;
                                const _addresses = addresses.filter(item => item.value === true)
                                const addresses_id = _addresses.map(item => item.id)
                                if (noShowComplete) {
                                    const _data = {
                                        type: 'completed',
                                        addresses_id,
                                    }
                                    noShowCompleteJob(item.id, _data, onClose)
                                } else {
                                    completeJob(item.id, {addresses_id}, onClose)
                                }
                            }}
                        />
                    )}
                    
                    {noShowComplete && !selectAddressComplete ? (
                        <NoShowCompleteJobForm
                            item={item}
                            loader={loader}
                            onSubmit={(data) => {
                                // noShowCompleteJob(item.id, data, onClose)
                                validateNoShowCompleteJob(item.id, data, onClose)
                            }}
                        />
                    ): null}

                    {!noShowComplete && !selectAddressComplete ? (
                        <EditJobForm
                            item={item}
                            loader={loader}
                            disabled={isDisabled}
                            showCompleteJobBtn={showCompleteJobBtn}
                            assignAddressData={assignAddressData}
                            changeDate={changeDate}
                            validateToCompleteJob={validateToCompleteJob}
                            rAgentCancel={rAgentCancel}
                            dAgentCancel={dAgentCancel}
                            removeNoShowStatus={removeNoShowStatus}
                            onCancel={onClose}
                            onSubmit={(data) => {
                                console.log('DATA: ', data);
                                postEditJob(item.id, data, onClose)
                            }}
                        />
                    ): null}
                </LoadMask>
            </div>
        </Modal>
    );
}
