import React, { Component } from 'react'
import ShowingTextForm from './ShowingTextForm'

export default class ShowingText extends Component {
    componentDidMount() {
        this.props.getSettings('showingTextForm')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {loader, onSubmitShowingText} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Showing Text</div>
                    </div>
                </div>
                <ShowingTextForm
                    onSubmit={(data) => {
                        onSubmitShowingText(data)
                    }}
                    goBack={this.goBack}
                    loader={loader}
                    />
            </div>
        )
    }
}
