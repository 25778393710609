import React, {Component} from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {NotificationManager} from "react-notifications";
import InfluencerTeamProfileForm from "./InfluencerTeamProfileForm"

class InfluencerTeamProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            team_id: null,
            nameFile: '',
            active: true,
        }
    }

    componentDidMount() {
        const {match} = this.props;
        if (match.params && match.params.id) {
            this.props.read(match.params.id);
            this.props.contactList(1, match.params.id)
            this.setState({team_id: match.params.id});
            this.props.getUrlExelTemplate();
        }
    }

    goBack = (e) => {
        this.props.history.goBack()
    }

    goTo= (id) => {
        this.props.history.push(`/influencers/${id}/`)
    }

    submit = (data) => {
        this.props.edit(this.state.team_id, data)
    }

    removeContact = (id) => {
        this.props.removeContact(id, this.state.team_id)
    }

    onChanged = (file) => {
        const {setExcel} = this.props;
        this.setState({
            nameFile: file.name,
            active: false,
        });
        setExcel(file);
    };

    setActive = (value) => {
        this.setState({
            nameFile: '',
            active,
        });
        const {setExcel} = this.props;
        setExcel(null);
    };

    goToAgents= (id) => {
        this.props.history.push(`/agents/?team=${id}`)
    }

    render() {
        //  State
        const {team_id, active, nameFile} = this.state;
        const {item, dataContact, loader, loader2, urlTemplate} = this.props;
        //  Bind
        const {searchContact, contactList} = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            Team Profile
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4 flex-1">
                    <LoadMask loading={loader} dark blur>
                        <InfluencerTeamProfileForm
                            id={team_id}
                            item={item}
                            goBack={this.goBack}
                            goTo={this.goTo}
                            goToAgents={this.goToAgents}
                            onSubmit={this.submit}
                            loaderContact={loader2}
                            contactList={contactList}
                            searchAction={searchContact}
                            data={dataContact}
                            urlTemplate={urlTemplate}
                            removeContact={this.removeContact}
                            text={nameFile}
                            handleOnChange={this.onChanged}
                            active={active}
                            setActive={this.setActive}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }

}

export default InfluencerTeamProfile
