import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { Field, FieldArray, reduxForm } from "redux-form";
import {
    renderField,
    SelectField,
    renderFieldCheck,
} from "../../../Utils/renderField/renderField";
import { CUSTOMER_ANSWER_TYPE } from "../../../../../utility/constants";

const validate = (values, props) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'This field is required';
    }
    if (values.feedback_question && values.feedback_question.length) {
        const feedbackQuestion = [];
        values.feedback_question.forEach((question, indexB) => {
            feedbackQuestion[indexB] = {};
            if (!question.question) {
                feedbackQuestion[indexB] = { question: 'This field is required' };
            }
            if (!question.question_type) {
                feedbackQuestion[indexB] = { ...feedbackQuestion[indexB], question_type: 'This field is required' };
            }
            if (!feedbackQuestion[indexB]) {
                feedbackQuestion[indexB] = undefined;
            }
        });
        errors.feedback_question = feedbackQuestion;
    } else {
        errors.feedback_question = "At least one question is required";
    }
    return errors;
};

const optionList = ({
    seeView,
    fields,
    meta: { error, touched },
    loadOptions,
}) => (
    <React.Fragment>
        {fields.map((item, index) => (
            <div
                key={index}
                className="form-group has-feedback col-12 mt-auto py-2 border"
            >
                <div className="row">
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="question">Question {index + 1}</label>
                        <Field
                            name={`${item}.question`}
                            placeholder="Question"
                            component={renderField}
                            disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="question_type">Type</label>
                        <Field
                            name={`${item}.answer_type`}
                            placeholder="Type"
                            options={CUSTOMER_ANSWER_TYPE}
                            component={SelectField}
                            disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="type">Is Required</label>
                        <Field
                            name={`${item}.is_required`}
                            disabled={seeView}
                            component={renderFieldCheck}
                            type="checkbox"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        {!seeView && (
                            <img
                                src={require("../../../../../../assets/img/delete.svg")}
                                alt="del"
                                onClick={() => fields.remove(index)}
                                className="ml-1 pointer"
                            />
                        )}
                    </div>
                </div>
            </div>
        ))}
        {!seeView && (
            <div className="col-12">
                <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => fields.push({ is_required: true })}
                >
                    Add Option
                </button>
            </div>
        )}
        {error && <div className="invalid-feedback">{error}</div>}
    </React.Fragment>
);

const Form = (props) => {
    const {
        handleSubmit,
        seeView,
        goBack,
    } = props;
    return (
        <form
            action=""
            onSubmit={handleSubmit}
            className="row mx-auto justify-content-center mt-5"
            style={{ width: "80%" }}
        >
            <div className="row w-100">
                <div className="form-group has-feedback col-md-6 mt-auto">
                    <label htmlFor="name">Name</label>
                    <Field
                        name="name"
                        placeholder="Name"
                        component={renderField}
                        disabled={seeView}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row w-100 mb-4">
                <div className="col-md-12 mt-auto">
                    <label htmlFor="feedback_question">Question List</label>
                    <div className="row">
                        <FieldArray
                            seeView={seeView}
                            name="feedback_question"
                            component={optionList}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex my-4">
                {/* <Link className="btn button-03 ml-auto mr-1 px-3" to="/vendor-client-feedback">Cancel</Link> */}
                <button type="button" className="btn button-03 ml-auto mr-1 px-3" onClick={goBack}>Cancel</button>
                <button type="submit" className="btn btn-primary ml-1 px-3">Save</button>
            </div>
        </form>
    );
};

let VendorClientFeedbackForm = reduxForm({
    form: "vendorClientFeedbackForm", // a unique identifier for this form
    validate,
    initialValues: {available: false}
})(Form);

const selector = formValueSelector("vendorClientFeedbackForm");
VendorClientFeedbackForm = connect((state) => {
    const iconValue= selector(state, 'icon')
    const imageValue= selector(state, 'image')
    return {
        icon: iconValue,
        image: imageValue,
    };
})(VendorClientFeedbackForm);

export default VendorClientFeedbackForm;
