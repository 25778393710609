import { connect } from 'react-redux'
import TutorialVideoList from './TutorialVideoList'
import {actions} from '../../../../redux/modules/tutorialVideo'

const mapStateToProps = (state) => ({
    ...state.tutorialVideos
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialVideoList)