import React, {useState, useEffect} from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Grid from "../../../Utils/GridTable";
import {
    STATE_USA_LIST,
    BROKER_OPERATIONAL_MODE_LIST,
    BROKER_ROLES_LIST,
    DENIED_BROKER,
    LOCKED_BROKER,
    PENDING_BROKER,
    VERIFIED_BROKER,
    BLOCKED_BROKER,
    CUSTOM_PAGE_SIZE_IN_BACKEND,
    STATUS_BROKER,
} from "../../../../../utility/constants";

const DeletedBrokerGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        // tabContainerHeight,
    } = props;

    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("DELETE-BROKER-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="DELETE-BROKER-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    hover
                    height={gridHeight}
                    page={page}
                    keyField={"id"}
                    data={data ? data : []}
                    loading={loader}
                    sizePerPage={CUSTOM_PAGE_SIZE_IN_BACKEND}
                    onPageChange={(value) => toList(value)}
                    wrapperClasses="table-scroll-auto"
                    columns={[
                        {
                            dataField: "status",
                            formatter: formatStatus(),
                            text: "status",
                        },
                        {
                            dataField: "owner_first_name",
                            formatter: formatFullName(),
                            text: "Broker Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "role",
                            formatter: formattRole(),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                    textAlign: "left",
                                };
                            },
                            text: "Role",
                        },
                        {
                            dataField: "license",
                            sort: true,
                            text: "RE License Number",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "150px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "state",
                            formatter: formattedState(),
                            text: "state",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "100px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "office_phone_number",
                            formatter: formatPhoneNumber(),
                            text: "Office #",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "mobile_number",
                            formatter: formatPhoneNumber(),
                            text: "Mobile #",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "owner_email",
                            sort: true,
                            text: "E-mail",
                        },
                        {
                            dataField: "franchise.name",
                            sort: true,
                            text: "Franchise",
                        },
                        {
                            dataField: "operation_access",
                            formatter: formattOperationAccess(),
                            text: "Operational Mode",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "150px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "id",
                            formatter: formatReinstate(),
                            text: "",
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export function formatStatus() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            STATUS_BROKER.map(item => {
                if (cell === item.value) {
                    text = item.label
                }
            })
            return text
        }

        const getColor = () => {
            let colorText = 'black'
            if (cell === DENIED_BROKER) {
                colorText = 'danger'
            } else if (cell === PENDING_BROKER) {
                colorText = 'warning'
            } else if (cell === VERIFIED_BROKER) {
                colorText = 'success'
            } else if (cell === LOCKED_BROKER) {
                colorText = 'danger'
            }else if (cell === BLOCKED_BROKER) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <p
                    className={`my-0 text-capitalize font-weight-bold text-${getColor()}`}
                    style={{
                        font: 'normal normal medium 13px/22px Poppins',
                        letterSpacing: '0px',
                        opacity: '1'
                    }}
                >
                    {getStatus()}
                </p>
            </React.Fragment>
        )
    };
}

export function formatFullName() {
    return (cell, row) => {
        return (
            <p className={`my-0 text-capitalize`}>
                {cell + ' ' + row.owner_last_name}
            </p>
        )
    };
}

const formattRole = () => (cell, row) => {
    const role = _.find(BROKER_ROLES_LIST, {value: cell})
    return (
        <p className={`my-0 text-capitalize`}>
            {role ? role.label : ""}
        </p>
    )
}

export function formattedState() {
    return (cell, row) => {
        const getState = () => {
            let text = ''
            STATE_USA_LIST.map(option => {
                if (option.value === cell) {
                    text = option.label
                }
            })
            return text;
        }
        return (
            <p className={`my-0 text-capitalize`}>
                {getState()}
            </p>
        )
    };
}

export function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}

const formattOperationAccess = () => (cell, row) => {
    const operation = _.find(BROKER_OPERATIONAL_MODE_LIST, {value: cell})
    return (
        <p className={`my-0 text-capitalize`}>
            {operation ? operation.label : ""}
        </p>
    )
}

function formatReinstate() {
    return (cell, row) => {
        return (
            <div className="w-100">
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`deleted-brokers/${cell}/reinstate`}
                    className="w-100 btn btn-danger text-white"
                >
                    Reinstate
                </Link>
            </div>
        );
    }
}

export default DeletedBrokerGrid;