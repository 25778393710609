import React from "react";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderSearchField, MultiSelectField } from "../../../Utils/renderField";
import {
    STATE_USA_LIST,
    SELECT_ALL_OPTION,
} from '../../../../../utility/constants'

let DeletedOfficeFiltersForm = (props) => {
    //  State
    const {
        optionFranchises=[],
        optionBrokers=[],
    } = props;

    //  Bind
    const {changeFilters} = props;

    return (
        <div className="row">
            {/* <div className={`${typeState == 'registered' ? 'col-xl-2 col-lg-4' : 'col-xl-2 col-lg-4'} col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}> */}
            <div className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}>
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search Office'}
                        component={renderSearchField}
                        onChange={(e) => {
                            changeFilters(
                                "search",
                                e.target && e.target.value ? e.target.value : ""
                            )
                        }}
                        type="text"
                    />
                </div>

            </div>
            
            <div className={`col-xl-10 col-lg-8 col-sm-12 col-md-12`}>
                <div className="row">

                    <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="state">State</label>
                            <Field
                                name="state"
                                options={STATE_USA_LIST}
                                set_value={(value) => {
                                    changeFilters(
                                        "state",
                                        value
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="franchise">Previous Franchise</label>
                            <Field
                                name="franchise"
                                options={optionFranchises}
                                set_value={(value) => {
                                    changeFilters(
                                        "franchise",
                                        value
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="broker">Previous Broker</label>
                            <Field
                                name="broker"
                                options={optionBrokers}
                                set_value={(value) => {
                                    changeFilters(
                                        "broker",
                                        value
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
};

DeletedOfficeFiltersForm = reduxForm({
    form: 'DeletedOfficeFiltersForm', // a unique identifier for this form
    initialValues: {state: SELECT_ALL_OPTION["value"]},
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DeletedOfficeFiltersForm);

const selector = formValueSelector('DeletedOfficeFiltersForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(DeletedOfficeFiltersForm);