import React, {useEffect, useState} from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const renderWysiwygField = ({
    disabled, input, initialvalue, customClass, placeholder, onFocus, meta: { touched, error },
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        console.log("VAL: ", initialvalue);
        if (initialvalue) {
            const contentBlock = htmlToDraft(initialvalue);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        }
    }, [initialvalue])
    
    const invalid = touched && error;

    return (
        <React.Fragment>
            <Editor
                editorState={editorState}
                readOnly={disabled}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(editorState) => {
                    const formato = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                    console.log("VALUE: ", formato);
                    input.onChange(formato);
                    setEditorState(editorState);
                }}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'image', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
                    },
                    textAlign: {
                        inDropdown: true,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify']
                    },
                    image: {
                        // uploadCallback: this.uploadImagen,
                        previewImage: true,
                        alt:""
                    }
                }}
            />
            {/* <input
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                onFocus={onFocus}
                className={classNames(`form-control ${customClass}`, { 'is-invalid': invalid })}
            /> */}
            {invalid && (
                <div className="invalid-feedback d-block">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};