import React from "react";
import {Field, reduxForm} from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {renderField} from "../../Utils/renderField";

const ResetForm = (props) => {
    const {handleSubmit, goBack, item, isProfile = false, showPassword, showPassAction} = props;
    return (
        <form name="changedPasseForm" className={isProfile ? "w-100" : "w-50 mx-auto"} onSubmit={handleSubmit}>
            {!isProfile &&
            <div className="row w-100 mt-3">
                <div className="form-group has-feedback col-8 mx-auto">
                    {/* <label htmlFor="brokerage_license" className="my-0 font-weight-bold">
                        "Office Name"
                    </label>
                    <p className="mt-0 mb-2 text-12">
                        {item && item.office_name}
                    </p> */}
                    <React.Fragment>
                        <label htmlFor="broker" className="my-0 font-weight-bold">Agent Full Name</label>
                        <p className="mt-0 mb-2 text-12">{item && item.first_name} {item && item.last_name} </p>
                    </React.Fragment>
                </div>
            </div>
            }
            <div className="row w-100 mx-auto">
                <div className="form-group has-feedback col-lg-9 col-sm-12 mx-auto">
                    <label htmlFor="password">
                        New Password
                    </label>
                    <div className="w-100 mx-auto row">
                        <Field
                            name="password"
                            label="Password"
                            component={renderField}
                            type={`${showPassword ? 'text' : 'password'}`}
                            className="form-control"
                        />
                        <label
                            className="button-eyes"
                            onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                            {showPassword ?
                                <i className="material-icons pointer">visibility_off</i> :
                                <i className="material-icons pointer">visibility</i>
                            }
                        </label>
                    </div>
                </div>
            </div>

            <div className="row w-100 mx-auto">
                <div className="form-group has-feedback col-lg-9 col-sm-12 mx-auto">
                    <label htmlFor="confirmPassword">
                        Confirm New Password
                    </label>
                    <div className="w-100 mx-auto row">
                        <Field
                            name="confirmPassword"
                            label="NewPassword"
                            component={renderField}
                            type={`${showPassword ? 'text' : 'password'}`}
                            className="form-control"
                        />
                        <label
                            className="button-eyes"
                            onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                            {showPassword ?
                                <i className="material-icons pointer">visibility_off</i> :
                                <i className="material-icons pointer">visibility</i>
                            }
                        </label>
                    </div>
                </div>
            </div>

            <div className="row w-100 py-4 justify-content-center mx-auto">
                {!isProfile &&
                <button type="button" className="btn button-03 col-3 mx-2" onClick={goBack}>
                    Back
                </button>
                }
                <button type="submit" className="btn btn-primary col-lg-5 col-sm-10 ">
                    Confirm
                </button>
            </div>
        </form>
    )
};

export const matchPassword = (pass, confirm) =>
    validatorFromFunction((value) => {
        return pass === confirm;
    });

export const minValue = (value) => validatorFromFunction(() => {
    if (value.length >= 6) {
        return value.length >= 'Must be at least 6 characters'
    }
    // return value.length >='Must be at least 6 characters'
});

export default reduxForm({
    form: "changedPassAgent", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            password: validators.exists()("This field is required"),
            confirmPassword: combine(
                validators.exists()('This field is required'),
                matchPassword(data.password, data.confirmPassword)()('The password don\'t match'),
            ),
        });
    },
})(ResetForm);
