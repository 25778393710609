import React, { useEffect, useState } from "react";
import moment from "moment";
import Grid from "../../../Utils/GridTable";
import _ from "lodash";
import ReactStars from "react-rating-stars-component";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { DEFAULT_PAGE_SIZE_IN_BACKEND } from "../../../../../utility/constants";

export const AgentReviewGrid = (props) => {
    //  State
    const { id, loader, data, height } = props;
    //  Bind
    const { toList, remove } = props;

    return (
        <Grid
            height={height}
            // hover
            striped
            data={data}
            keyField={"id"}
            loading={loader}
            sizePerPage={DEFAULT_PAGE_SIZE_IN_BACKEND}
            onPageChange={(value) => toList(id, value)}
            columns={[
                {
                    dataField: "id",
                    text: "",
                    formatter: standardActions({
                        customRemove: remove,
                        // view: "brokerage",
                        edit: `/agents/agent-reviews/${id}`,
                    }),
                },
                {
                    dataField: "job",
                    formatter: formatJob(),
                    text: "Reviews",
                },
                {
                    dataField: "job.review.notes",
                    formatter: formatReview(),
                    text: "Hiring Agent Review",
                    headerStyle: (colum, colIndex) => {
                        return {
                            minWidth: "190px",
                        };
                    },
                },
                {
                    dataField: "job.review.customer_feedback",
                    formatter: formatCustomerReview(),
                    text: "Client Review",
                    headerStyle: (colum, colIndex) => {
                        return {
                            minWidth: "190px",
                        };
                    },
                },
            ]}
        />
    );
};

const Rating = (props) => {
    const { rating } = props;
    const [starKeyForce, setStarKeyForce] = useState(0);

    useEffect(() => {
        setStarKeyForce((prev) => prev + 1);
    }, [rating]);

    return (
        <ReactStars
            size={15}
            count={5}
            value={rating}
            edit={false}
            isHalf={true}
            key={starKeyForce}
            onChange={() => console.log("")}
        />
    );
};

export function formatJob() {
    return (cell, row) => {
        let icon = cell && cell.job_type ? cell.job_type.icon : null;
        let jobName = cell && cell.job_type ? cell.job_type.name : null;
        let primaryColor =
            cell && cell.job_type ? cell.job_type.primary_color : null;
        let jobRating = cell && cell.review ? cell.review.total_score : 0;
        let rAgent = cell && cell.r_agent ? cell.r_agent : null;
        let office = rAgent && rAgent.office ? rAgent.office : null;
        if (cell && cell.job_type_option) {
            icon = cell.job_type_option.icon;
            jobName = cell.job_type_option.name;
            primaryColor = cell.job_type_option.primary_color;
        }
        return (
            <div
                className="card d-flex flex-column rounded overflow-hidden"
                style={{ backgroundColor: "#F3F3F3", width: "290px" }}
            >
                <div className="w-100 d-flex flex-row py-2 px-2 align-items-center">
                    <div
                        style={{
                            width: icon ? "20px" : "20px",
                            height: icon ? "20px" : "20px",
                        }}
                        className="p-0 m-0"
                    >
                        <img
                            src={
                                icon
                                    ? icon
                                    : require("../../../../../../assets/img/uploadLogo.PNG")
                            }
                            // color={primaryColor}
                            // style={{fill: primaryColor}}
                            alt="logo"
                            className="align-self-center w-75 justify-content-center"
                        />
                    </div>
                    <div className="d-flex flex-column px-2 flex-1">
                        <span
                            className="font-weight-bold"
                            style={{ color: primaryColor, fontSize: "14px" }}
                        >
                            {jobName}
                        </span>
                        <span className="text-dark mb-0">
                            <b className="font-weight-bolder mr-1">Date</b>
                            {cell
                                ? moment(cell.date, "YYYY-MM-DD").format("ll")
                                : ""}
                        </span>
                    </div>
                    <div className="">
                        <Rating rating={jobRating} />
                    </div>
                </div>
                <div
                    className="w-100 d-flex flex-row p-2 align-items-center"
                    style={{ backgroundColor: "#E9E9E9" }}
                >
                    <img
                        src={
                            rAgent.photo
                                ? rAgent.photo
                                : require("../../../../../../assets/img/uploadLogo.PNG")
                        }
                        style={{
                            width: rAgent.photo ? "20px" : "20px",
                            height: rAgent.photo ? "20px" : "20px",
                        }}
                        alt="logo"
                        className="align-self-center rounded-circle justify-content-center"
                    />
                    <div className="flex-1 px-2">
                        <span
                            className="font-weight-bold text-dark"
                            style={{ fontSize: "12px" }}
                        >
                            {rAgent.name}
                        </span>
                    </div>
                    <img
                        src={
                            office && office.logo
                                ? office.logo
                                : require("../../../../../../assets/img/uploadLogo.PNG")
                        }
                        height={office && office.logo ? 20 : 20}
                        alt="logo"
                        className="align-self-center justify-content-center"
                    />
                </div>
            </div>
        );
    };
}

export function formatReview() {
    return (cell, row) => {
        return (
            <div
                className="rounded border p-1"
                style={{ minHeight: "50px", fontSize: "12px" }}
            >
                {cell}
            </div>
        );
    };
}

export function formatCustomerReview() {
    return (cell, row) => {
        const has_feedback =
            row.job &&
            row.job.customer_feedback &&
            row.job.customer_feedback.id &&
            row.job.customer_feedback.form.length;
        let notes = _.find(has_feedback ? row.job.customer_feedback.form : [], {
            question: "Notes",
        });
        return (
            <div
                className="rounded border p-1"
                style={{ minHeight: "50px", fontSize: "12px" }}
            >
                {has_feedback
                    ? notes !== null && notes !== undefined
                        ? notes.answer
                        : ""
                    : "N/A"}
            </div>
        );
    };
}

export default AgentReviewGrid;
