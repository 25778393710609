import React, { useState, useEffect } from "react";
import Grid from "../Utils/GridTable";
import {standardActions} from "../Utils/Grid/StandardActions";
import HeaderCard from "../HeaderCard";
import {Field, reduxForm} from "redux-form";
import {SelectField, MultiSelectField, renderSearchField} from "../Utils/renderField";
import {OFFICES_PAGE_SIZE, STATE_USA_LIST, SELECT_ALL_OPTION, BROKERAGE_STATUSES, BROKERAGE_ACTIVE, BROKERAGE_LOCKED, BROKERAGE_BLOCKED} from "../../../utility/constants";

const BrokerageGrid = (props) => {
    const {
        loader,
        brokerName,
        data,
        remove,
        searchAction,
        toList,
        dataBroker,
        optionBrokers,
        optionFranchises,
        actionFilter1,
        goToAgents,
        changeFilters,
        onSortChange,
    } = props;

    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("BROKERAGE-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div className="d-flex flex-column flex-overflow-hidden-1">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">Offices</div>
                </div>
            </div>
            <div className="w-100 row mx-auto">
                {/* <HeaderCard
                    showFile={false}
                    active_link={false}
                    // active_link={true}
                    // text_link={"Create"}
                    // link={'create-brokerage/'}
                    id_header={"OfficesList"}
                    searchAction={(value) => searchAction(value)}
                    searchStyle="col-lg-12 col-sm-12 img-fluid mr-lg-3 mb-sm-1"
                    justify="justify-content-start d-flex col-lg-4 col-sm-12 col-md-6 align-items-end"
                /> */}
                <div
                    className={`col-xl-2 col-lg-4 col-sm-12 col-md-6 d-flex flex-row align-items-start`}
                >
                    <div className="form-group has-feedback w-100 mb-0">
                        <Field
                            name="search"
                            label="Search"
                            placeholder={"Search"}
                            component={renderSearchField}
                            onChange={(e) => {
                                searchAction(
                                    e.target && e.target.value ? e.target.value : ""
                                );
                            }}
                            customClass="mt-4"
                            type="text"
                        />
                    </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-12">
                    <div className="row">
                    <div className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-2`}>
                            <label htmlFor="state">State</label>
                            <Field
                                name="state"
                                options={STATE_USA_LIST}
                                set_value={(value) => {
                                    changeFilters("state", value);
                                }}
                                component={MultiSelectField}
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div
                            className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-2`}
                        >
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="franchise">Franchise</label>
                                <Field
                                    name="franchise"
                                    options={optionFranchises}
                                    set_value={(value) => {
                                        changeFilters("franchise", value);
                                    }}
                                    component={MultiSelectField}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-2`}>
                            <label htmlFor="broker">Broker</label>
                            <Field
                                name="broker"
                                options={optionBrokers}
                                set_value={(value) => {
                                    changeFilters("broker", value);
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                        <div className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-2`}>
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="status">Status</label>
                                <Field
                                    name="status"
                                    options={BROKERAGE_STATUSES}
                                    set_value={(value) => {
                                        changeFilters(
                                            "status",
                                            value
                                        )
                                    }}
                                    component={MultiSelectField}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="form-group has-feedback col-lg-3 col-sm-12 col-md-6">
                    <label htmlFor="filterBrokerId">Broker</label>
                    <Field
                        name="filterBrokerId"
                        isMulti={false}
                        options={dataBroker.results ? dataBroker.results : []}
                        set_value={actionFilter1}
                        component={SelectField}
                        className="form-control"
                        disabled={false}
                    />
                </div> */}
            </div>

            <div id='BROKERAGE-LIST' className="card border-dark  border-card-page px-4 py-2 flex-overflow-hidden-1">
                <div className="card-body px-0 py-2 d-flex flex-column">
                    <Grid
                        height={gridHeight}
                        hover
                        striped
                        data={data}
                        keyField={"id"}
                        loading={loader}
                        sizePerPage={OFFICES_PAGE_SIZE}
                        onSortChange={(field, sortOrder, state) => {
                            onSortChange(field)
                        }}
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter: standardActions({
                                    customRemove: remove,
                                    // view: "brokerage",
                                    edit: "offices",
                                }),
                            },
                            {
                                dataField: "logo",
                                formatter: loadImg(),
                                text: "",
                            },
                            {
                                dataField: "status",
                                formatter: formatStatus(),
                                text: "status",
                            },
                            {
                                dataField: "firm_name",
                                sort: true,
                                text: "Office Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "broker__name",
                                sort: true,
                                formatter: (cell, row) => {
                                    return <span>{row.broker_info ? row.broker_info.name : "" }</span>
                                },
                                text: "Broker Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "broker__franchise__name",
                                sort: true,
                                formatter: (cell, row) => {
                                    return <span>{row.broker_info && row.broker_info.franchise ? row.broker_info.franchise.name : "" }</span>
                                },
                                text: "Franchise",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "broker_info.group_name",
                                sort: false,
                                text: "Group Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "license",
                                sort: true,
                                text: "Office License Number",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "190px",
                                    };
                                },
                            },
                            {
                                dataField: "state",
                                sort: false,
                                text: "State",
                            },
                            {
                                dataField: "city",
                                sort: false,
                                text: "City",
                            },
                            {
                                dataField: "address",
                                sort: false,
                                text: "Address",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                    };
                                },
                            },
                            {
                                dataField: "address_line2",
                                sort: false,
                                text: "Address Line 2",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                    };
                                },
                            },
                            {
                                dataField: "telephone",
                                formatter: formatPhoneNumber(),
                                text: "Phone Number",
                            },
                            {
                                dataField: "agents_stats.total_agents",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_user",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Registered Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_unregistered",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Unregistered Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_user_active",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Active Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_job_posted",
                                text: "# of Job Posted",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "120px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_job_performed",
                                text: "# of Job Performed",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "120px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_show_it_share",
                                formatter: formatCurrency(),
                                text: "Total ShowIT Share",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_show_it_revenue",
                                formatter: formatCurrency(),
                                text: "Total ShowIT Revenue",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export function loadImg() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <div style={{width: cell ? "28px" : "30px", height: cell ? "22px" : "25px"}} className="p-0 m-0">
                    <img
                        src={cell ? cell : require('../../../../assets/img/uploadLogo.PNG')}
                        alt='logo'
                        className="align-self-center w-75 justify-content-center"
                    />
                </div>
            </React.Fragment>
        )
    };
}

export function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}


export function loadOffice() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <div>
                    <a
                        href={cell}
                    >
                        {cell && cell.substring(34, cell.length - 5)}
                    </a>
                </div>
            </React.Fragment>
        )
    };
}

export function formattedState() {
    return (cell, row) => {
        const getState = () => {
            let text = ''
            STATE_USA_LIST.map(option => {
                if (option.value === cell) {
                    text = option.label
                }
            })
            return text;
        }
        return (
            <p className={`my-0 text-capitalize`}>
                {getState()}
            </p>
        )
    };
}

const formatAgentTotal = (goTo) => (cell, row) => {
    return(
        <p className="my-0 text-primary pointer" onClick={(e) => goTo(row.id)}>{cell}</p>
    )
}

const formatCurrency = () => (cell, row) => {
    return (<div>
        $ {cell ? cell.toFixed(2) : "0.00"}
    </div>)
}

export function formatStatus() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            BROKERAGE_STATUSES.map(item => {
                if (cell === item.value) {
                    text = item.label
                }
            })
            return text
        }

        const getColor = () => {
            let colorText = 'black'
            if (cell === BROKERAGE_ACTIVE) {
                colorText = 'success'
            } else if (cell === BROKERAGE_LOCKED) {
                colorText = 'danger'
            }
            else if (cell === BROKERAGE_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <p
                    className={`my-0 text-capitalize font-weight-bold text-${getColor()}`}
                    style={{
                        font: 'normal normal medium 13px/22px Poppins',
                        letterSpacing: '0px',
                        opacity: '1'
                    }}
                >
                    {getStatus()}
                </p>
            </React.Fragment>
        )
    };
}

export default reduxForm({
    form: 'BrokerageGrid', // a unique identifier for this form
    initialValues: {state: SELECT_ALL_OPTION['value'], status: SELECT_ALL_OPTION['value']},
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(BrokerageGrid);

