import { connect } from 'react-redux';
import CompletingJobOffline from "./CompletingJobOffline";
import { actions } from '../../../../redux/modules/setting'

const ms2p = (state) => {
    return{
        ...state.setting,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(CompletingJobOffline);