import React, {useState} from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    renderField,
    renderCurrency,
    renderTextArea,
    renderFileImageCrop,
} from "../../Utils/renderField";

let Form = (props) => {
    const {
        handleSubmit,
        loader,
        referImage,
        referBrokerImage,
    } = props;
    //  Bind
    const {
        goBack,
        setReferImage,
        setReferBrokerImage,
    } = props;

    return (
        <div
            className="card flex-1 border-dark border-card-page px-4 py-4"
            style={{
                minWidth: "99%",
            }}
        >
            <form
                name="brokerageForm"
                className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
                onSubmit={handleSubmit}
            >
                <LoadMask loading={loader} light>
                    <div className="row">
                        <h5>Refer Agent</h5>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <label htmlFor="refer_image">Image</label>
                            <div className="w-100">
                                <Field
                                    name='refer_image'
                                    photo={referImage ? referImage : undefined}
                                    setFile={setReferImage}
                                    spectValue={null}
                                    locked={false}
                                    component={renderFileImageCrop}
                                />
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="refer_description">Description</label>
                            <Field
                                name="refer_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="refer_text">Text</label>
                            <Field
                                name="refer_text"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-3 col-sm-12">
                            <label htmlFor="refer_amount">Amount</label>
                            <Field
                                name="refer_amount"
                                component={renderCurrency}
                                placeholder="Price"
                                customClass="form-control w-100"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <h5>Refer Broker</h5>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <label htmlFor="refer_broker_image">Image</label>
                            <div className="w-100">
                                <Field
                                    name='refer_broker_image'
                                    photo={referBrokerImage ? referBrokerImage : undefined}
                                    setFile={setReferBrokerImage}
                                    spectValue={null}
                                    locked={false}
                                    component={renderFileImageCrop}
                                />
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="refer_broker_description">Description</label>
                            <Field
                                name="refer_broker_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="refer_broker_form_description">Form Description</label>
                            <Field
                                name="refer_broker_form_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="refer_broker_text">Text</label>
                            <Field
                                name="refer_broker_text"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="w-100 my-4 mx-auto text-center">
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={goBack}
                        >
                            Back
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                            disabled={loader}
                        >
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        </div>
    );
}

let ReferTextForm = reduxForm({
    form: "referTextForm", // a unique identifier for this form
})(Form);

const selector = formValueSelector("referTextForm");

ReferTextForm = connect((state) => {
    const referImage = selector(state, "refer_image");
    const referBrokerImage = selector(state, "refer_broker_image");
    return {
        referImage,
        referBrokerImage,
    };
})(ReferTextForm);

export default ReferTextForm;