import _ from "lodash";
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import {SELECT_ALL_OPTION} from '../../../utility/constants'
import { change, initialize as initializeForm } from "redux-form";


// ------------------------------------
// Constants
// ------------------------------------
const endpoint = 'influencer_team'
const SET_LOADER_2 = 'SET_LOADER_2_INFLUENCER_TEAM'
const SET_PAGE_CONTACTS = 'SET_PAGE_CONTACTS_INFLUENCER_TEAM'
const SET_SEARCH_CONTACTS = 'SET_SEARCH_CONTACTS_INFLUENCER_TEAM'
const SET_DATA_CONTACTS = 'SET_DATA_CONTACTS_INFLUENCER_TEAM'
const SET_URL_EXCEL_TEMPLATE = 'SET_URL_EXCEL_TEMPLATE_INFLUENCER_TEAM'
const SET_FILE = 'SET_FILE_INFLUENCER_TEAM'
//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "influencerTeam",
    endpoint,
    "influencerTeamProfileForm",
    "/influencer-teams"
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setLoader2 = loader2 => ({
    type: SET_LOADER_2,
    loader2,
});
const setPageContactList = pageContact => ({
    type: SET_PAGE_CONTACTS,
    pageContact,
});
const setSearchContactList = searchContact => ({
    type: SET_SEARCH_CONTACTS,
    searchContact,
});
const setDataContactList = dataContact => ({
    type: SET_DATA_CONTACTS,
    dataContact,
});
const setUrlExcelTemplate = urlTemplate => ({
    type: SET_URL_EXCEL_TEMPLATE,
    urlTemplate,
});
const setFile = file => ({
    type: SET_FILE,
    file,
});

// ------------------------------------
// Actions
// ------------------------------------
export const searchChange = (search) => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    setTimeout(() => {
        dispatch(toList());
    }, 400)
};

export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case "search":
                dispatch(searchChange(value));
                break;
            case 'state':
                setTimeout(() => {
                    dispatch(toList())
                }, 500)
                break;
            default:
                break;
        }
    };

const toList =
    (page = 1) =>
    (dispatch, getStore) => {
        const store = getStore();
        const resource = store["influencerTeam"];
        let params = { page };
        params.ordering = resource.ordering;
        const { InfluencerTeamFiltersForm } = store.form;
        if (InfluencerTeamFiltersForm && InfluencerTeamFiltersForm.values) {
            const { search, state } =
                InfluencerTeamFiltersForm.values;
            if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
                if (state.length == 0) {
                    params.influencer__state__in = "null"
                }else {
                    params.influencer__state__in = state;
                }
            }
            if (search != "" && search != undefined && search != null) {
                params.search = search
            }
        }
        dispatch(basicReducer.actions.setLoader(true));
        api.get(endpoint, params)
            .then((response) => {
                dispatch(basicReducer.actions.setData(response));
                dispatch(basicReducer.actions.setPage(page));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    };

const contactList = (page = 1, teamId) => (dispatch, getStore) => {
    dispatch(setLoader2(true));
    const resource = getStore()['influencerTeam'];
    const params = {page};
    params.ordering = resource.ordering;
    params.team_id = teamId
    params.search = resource.searchContact;

    api.get('influencer_team_contact', params)
        .then((response) => {
            dispatch(setPageContactList(page));
            dispatch(setDataContactList(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader2(false));
        });
};

const addContact = (teamId, goBack) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true))
    const dataForm = getStore().form.teamContactForm.values;
    let data = {...dataForm, influencer_team_id: teamId}

    api.post('influencer_team_contact', data)
        .then(() => {
            NotificationManager.success('Added contact', 'Success', 2000);
            if (goBack) {
                goBack()
            }
        })
        .catch((error) => {
            if (error.detail) {
                NotificationManager.error(`${error.detail}`, 'Error', 5000);
            } else {
                NotificationManager.error('Transaction error', 'Error', 2500);
            }
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const updateContact = (id, teamId, goBack) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true))
    const dataForm = getStore().form.teamContactForm.values;
    let data = {...dataForm, influencer_team_id: teamId}
    api.put(`influencer_team_contact/${id}`, data, {team_id: teamId})
        .then(() => {
            NotificationManager.success('Updated contact', 'Success', 2000);
            if (goBack) {
                goBack()
            }
        })
        .catch((error) => {
            if (error.detail) {
                NotificationManager.error(`${error.detail}`, 'Error', 5000);
            } else {
                NotificationManager.error('Transaction error', 'Error', 2500);
            }
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const removeContact = (id, teamId) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.eliminar(`influencer_team_contact/${id}`, {team_id: teamId}).then(() => {
        dispatch(contactList(1, teamId));
        NotificationManager.success('Contact removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchContact = (search, teamId) => (dispatch) => {
    dispatch(setSearchContactList(search));
    dispatch(contactList(1, teamId));
};

const customRead = (id, teamId) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    let params = {team_id: teamId}
    api.get(`broker-invite/${id}`, params)
        .then((response) => {
            dispatch(initializeForm('teamContactForm', response));
        })
        .catch(() => {
            NotificationManager.error('Error in transaction', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const getUrlExelTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrlExcelTemplate(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const setExcel = (file) => (dispatch) => {
    dispatch(setFile(file))
}

const edit = (id, data = {}) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    delete data.roster_file;
    const file = getStore().influencerTeam.file;
    if (file !== null) {
        api.putAttachments(`${endpoint}/${id}`, data,
                [{name: 'roster_file', file: file}])
            .then((response) => {
                NotificationManager.success('Record updated', 'SUCCESS', 3000);
                dispatch(setAgreementFile(null));
                dispatch(push('/influencer-teams'));
            })
            .catch((error) => {
                let msg = "Edition failed"
                if (error && error.detail) {
                    msg = error.detail
                }

                NotificationManager.error(msg, 'ERROR', 5000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    }else {
        api.put(`${endpoint}/${id}`, data).then(() => {
            NotificationManager.success('Record updated', 'Success', 3000);
            dispatch(push('/influencer-teams'));
        }).catch((error) => {
            let msg = "Edition failed"
            if (error && error.detail) {
                msg = error.detail
            }

            NotificationManager.error(msg, 'ERROR', 5000);
        }).finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
    }
};

export const actions = {
    ...basicReducer.actions,
    searchChange,
    changeFilters,
    toList,
    contactList,
    searchContact,
    addContact,
    customRead,
    removeContact,
    updateContact,
    getUrlExelTemplate,
    setExcel,
    edit,
}

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    ...basicReducer.reducers,
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [SET_SEARCH_CONTACTS]: (state, {searchContact}) => {
        return {
            ...state,
            searchContact,
        };
    },
    [SET_PAGE_CONTACTS]: (state, {pageContact}) => {
        return {
            ...state,
            pageContact,
        };
    },
    [SET_DATA_CONTACTS]: (state, {dataContact}) => {
        return {
            ...state,
            dataContact,
        };
    },
    [SET_URL_EXCEL_TEMPLATE]: (state, {urlTemplate}) => {
        return {
            ...state,
            urlTemplate,
        };
    },
    [SET_FILE]: (state, {file}) => {
        return {
            ...state,
            file,
        };
    },
}
// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
    loader2: false,
    pageContact: 1,
    searchContact: '',
    dataContact: {
        results: [],
        count: 0
    },
    urlTemplate: null,
    file: null
}

export default handleActions(reducers, initialState);
