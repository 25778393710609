import React, {Component} from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import ValidateBrokerForm from "./ValidateBrokerForm"
import {PENDING_BROKER} from "../../../../utility/constants";
import {NotificationManager} from "react-notifications";


class ValidateBroker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            real_id: null,
            is_view: false,
            is_update: false,
            is_editable: false,
        };
    }

    componentWillMount() {
        const {match} = this.props;
        if (match.path === "/brokers/broker/:id/") {
            this.props.customRead(match.params.id);
            this.setState({real_id: match.params.id, is_view: true, is_update: false});
        }
        if (match.path === "/brokers/broker/:id/edit/") {
            this.props.customRead(match.params.id);
            this.setState({real_id: match.params.id, is_view: false, is_update: true});
        }
        this.props.getAllFranchises()

        this.props.getInfluencers()
    }

    componentWillUnMount() {
        this.setState({real_id: null});
    }

    updateAction = (data) => {
        this.props.edit(data.id, data);
    };

    validateBroker = () => {
        const {item, franchiseSelected, provideAgentsRosterValue} = this.props;
        console.log('franchiseSelected: ', franchiseSelected)
        let data = item;
        let valid = true;
        if (franchiseSelected) {
            data.franchise = franchiseSelected
        } else {
            valid = false;
            NotificationManager.error('Franchise is required', 'Error', 2000);
        }

        if (provideAgentsRosterValue == true || provideAgentsRosterValue == false ) {
            data.provide_agents_roster = provideAgentsRosterValue;
            
        } else {
            if (valid) {
                valid = false;
                NotificationManager.error(`Will Broker provide agent's roster list? is required`, 'Error', 2000);
            }
        }
        
        if (valid) {
            this.props.validateBroker(data);
        }
    }

    denyBroker = () => {
        const {item, franchiseSelected} = this.props;
        // if (franchiseSelected) {
            this.props.denyBroker(item);
        // }
    }

    goBack = (e) => {
        this.props.history.goBack()
    }

    render() {
        const {item, loader, optionFranchises, goToOffices, goToAgents, goToPaymentsHistory, influencers, assignAddressData} = this.props;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            {this.state.is_update
                                ? `${
                                      item &&
                                      item.status !== undefined &&
                                      item.status === PENDING_BROKER
                                          ? "Pending Broker"
                                          : "Broker Profile"
                                  }`
                                : "View Broker"}
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <ValidateBrokerForm
                            item={item}
                            onSubmit={this.updateAction}
                            real_id={this.state.real_id}
                            is_view={this.state.is_view}
                            is_update={this.state.is_update}
                            is_editable={
                                this.state.is_view
                                    ? true
                                    : item &&
                                      item.status !== undefined &&
                                      item.status === PENDING_BROKER
                            }
                            denyBroker={this.denyBroker}
                            validateBroker={this.validateBroker}
                            franchises={optionFranchises}
                            influencers={influencers}
                            goToOffices={goToOffices}
                            goToAgents={goToAgents}
                            goToPaymentsHistory={goToPaymentsHistory}
                            franchiseSelected={this.props.franchiseSelected}
                            goBack={this.goBack}
                            assignAddressData={assignAddressData}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ValidateBroker
