import React, {useState, useEffect} from 'react';
import moment from "moment";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {CUSTOM_PAGE_SIZE_IN_BACKEND} from "../../../../utility/constants";

const ReferralRawDataGrid = (props) => {
    //  State
    const {data, loader, page, toList} = props;
    const [gridHeight, setGridHeight] = useState(200)
    //  Bind
    const { nextPage } = props;

    console.log('DATA: ', data);

    const tableOptions = {
        sizePerPage: CUSTOM_PAGE_SIZE_IN_BACKEND,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: nextPage,
    };

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("REFERRAL-RAW-DATA-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    const phoneFormatted = (cell) => {
        let newCell = cell;
    
        if (newCell && newCell.includes("+")) {
            newCell = newCell.replace("+", "");
        }
        if (newCell && newCell.includes("-")) {
            newCell = newCell.replace("-", "");
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12);
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(
                3,
                3
            )}-${newCell.substr(6, 4)}`;
        }
        return <p className={"my-0 mx-0 px-0"}>{newCell}</p>;
    };

    return (
        
        <div id="REFERRAL-RAW-DATA-LIST" className="container-list card border-dark border-card-page px-4 py-0">
            <div className="card-body px-0 py-2 d-flex flex-column overflow-hidden">
                <LoadMask loading={loader} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={gridHeight ? {maxHeight: `${gridHeight}px`}: {}}>
                        <BootstrapTable
                            data={loader ? [] : data.results ? data.results : []}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            containerClass="flex-1"
                            tableContainerClass="table-scroll-auto"
                            tableStyle={gridHeight ? {maxHeight: `${gridHeight - 60}px`, minHeight: `${gridHeight - 60}px`} : {}}
                        >

                            <TableHeaderColumn
                                isKey
                                // row="0"
                                rowSpan="2"
                                dataField="id"
                                width="0px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className='d-none'>
                                            {row.id}
                                        </div>
                                    );
                                }}
                            />
                            {/* Referrer Info */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="6"
                                headerAlign="center"
                            >
                               Referrer Info
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="referrer__fist_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.referrer
                                                ? `${row.referrer.first_name}`
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="referrer__last_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.referrer
                                                ? `${row.referrer.last_name}`
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="referrer__franchise"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.referrer
                                                ? `${row.referrer.franchise}`
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Franchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="referrer__office_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.referrer
                                                ? `${row.referrer.office_name}`
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="190px"
                            >
                                Office Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="created"
                                dataFormat={dateCreated()}
                                width="190px"
                            >
                                Date/Time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="fee"
                                dataFormat={currencyFormatted()}
                                width="190px"
                                dataAlign="center"
                            >
                                Referral Fee
                            </TableHeaderColumn>

                            {/* Referee Info */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="4"
                                headerAlign="center"
                            >
                               Referee Info
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="email"
                                dataFormat={(cell, row) => {
                                    const AGENT = 100

                                    if (row.type === AGENT) {
                                        return (
                                            <div>
                                                {cell}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="mobile_number"
                                dataFormat={(cell, row) => {
                                    const AGENT = 100

                                    if (row.type === AGENT) {
                                        return (
                                            <div>
                                                {cell ? phoneFormatted(cell) : ""}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                Phone
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="signup_date"
                                dataFormat={(cell, row) => {
                                    const AGENT = 100

                                    if (row.type === AGENT) {
                                        return (
                                            <div>
                                                {cell ? moment(cell).format('LL') : ""}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                Signup Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="transfer_status"
                                dataFormat={(cell, row) => {
                                    const AGENT = 100
                                    const TRANSFERRED = 20
                                    if (row.type === AGENT) {
                                        return (
                                            <div>
                                                {cell == TRANSFERRED ? (
                                                    <span className='text-success font-weight-bold'>
                                                        Yes
                                                    </span>
                                                ): (
                                                    <span className='text-danger font-weight-bold'>
                                                        No
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                Referral Fee delivered
                            </TableHeaderColumn>

                            {/* Broker Information */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="8"
                                headerAlign="center"
                            >
                               Broker Information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="broker_name"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200
                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell ? cell : ""}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                Broker Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="email"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200

                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                Broker Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_name"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200
                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell ? cell : ""}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                Brokerage Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="city"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200
                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell ? cell : ""}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                City
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="state"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200
                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell ? cell : ""}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                State
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="signup_date"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200

                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell ? moment(cell).format('LL') : ""}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                            >
                                Signup Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="transfer_status"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200
                                    const TRANSFERRED = 20
                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell == TRANSFERRED ? (
                                                    <span className='text-success font-weight-bold'>
                                                        Yes
                                                    </span>
                                                ): (
                                                    <span className='text-danger font-weight-bold'>
                                                        No
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                Referral Fee delivered
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="transfer_status"
                                dataFormat={(cell, row) => {
                                    const BROKER = 200
                                    const PENDING = 10
                                    if (row.type === BROKER) {
                                        return (
                                            <div>
                                                {cell == PENDING && row.user ? (
                                                    <Link
                                                        onClick={(e) => e.stopPropagation()}
                                                        to={`/broker-referral/${row.id}`}
                                                        className="w-100 text-primary"
                                                    >
                                                        Go to Transfer Agent
                                                    </Link>
                                                ): null}
                                            </div>
                                        );
                                    }
                                    return null
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                Transfer
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        </div>
    )

}

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('LLL')}
            </p>
        )
    };
}

function currencyFormatted() {
    return (cell, row) => {
        return (
            <p className={"my-0 mx-0 px-0"}>
                $ {parseFloat(cell ? cell : 0).toFixed(2)}
            </p>
        );
    }
};

export default ReferralRawDataGrid;