import React, {Component} from 'react'
import LoadMask from "../Utils/LoadMask/LoadMask";
import {PENDING_BROKER} from "../../../utility/constants";
import {NotificationManager} from "react-notifications";
import BrokerReferralForm from './BrokerReferralForm';

class BrokerReferral extends Component {

    componentWillMount() {
        const {match} = this.props;
        if (match.params && match.params.id) {
            this.props.getBrokerReferral(match.params.id);
        }
    }

    goBack = (e) => {
        this.props.history.goBack()
    }

    submit = (values) => {
        const {match} = this.props;
        if (match.params && match.params.id) {
            this.props.transferBrokerReferral(match.params.id, values);
        }
    }

    render() {
        const {data, loader} = this.props;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            <h3>Broker Referral</h3>
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <BrokerReferralForm
                            data={data}
                            goBack={this.goBack}
                            onSubmit={(values) => {
                                this.submit(values)
                            }}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default BrokerReferral
