import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/promotion";
import PromotionCreateUpdate from './PromotionCreateUpdate';


const ms2p = (state) => {
    return {
        ...state.promotions,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PromotionCreateUpdate);