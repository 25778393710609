import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import Swal from "sweetalert2";
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";
import {SELECT_ALL_OPTION} from '../../../utility/constants'

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "twilio_app_version"
const SET_ANDROID_OPTION = "SET_ANDROID_OPTION_CAMPAIGNS";
const SET_IOS_OPTION = "SET_IOS_OPTION_CAMPAIGNS";
const SHOW_TWILIO_MODAL = "SHOW_TWILIO_MODAL_TWILIO_CAMPAIGNS";
const TWILIO_DATA = "TWILIO_DATA_TWILIO_CAMPAIGNS";


//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "twilioAppVersion",
    "twilio_app_version",
    "twilioAppVersionFrom",
    "/campaigns_app_version",
);


// ------------------------------------
// Pure Actions
// ------------------------------------
const setAndroidOptions = (androidOption) => ({
    type: SET_ANDROID_OPTION,
    androidOption,
});
const setIosOptions = (iosOption) => ({
    type: SET_IOS_OPTION,
    iosOption,
});

const setShowTwilioModal = (twilioModal) => ({
    type: SHOW_TWILIO_MODAL,
    twilioModal,
});

const setTwilioData = (twilioData) => ({
    type: TWILIO_DATA,
    twilioData,
});


// ------------------------------------
// Actions
// ------------------------------------
const getAndroidOption = () => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));

    api.get(`${endpoint}/option_android`)
        .then(response => {
            let option = [];
            option = response.results;
            dispatch(setAndroidOptions(option));
        })
        .catch((err) => {
            console.log("ERR: ", err);
            dispatch(setAndroidOptions([]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const getIosOption = () => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));

    api.get(`${endpoint}/option_ios`)
        .then(response => {
            let option = [];
            option = response.results;
            dispatch(setIosOptions(option));
        })
        .catch((err) => {
            console.log("ERR: ", err);
            dispatch(setIosOptions([]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const createCampaign = (data, photos) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    let _data = {...data}
    if (photos.length) {
        api.postAttachments(endpoint, _data, photos)
            .then((response) => {
                if (response) {
                    NotificationManager.success('Campaign successfully registered', 'Success', 3000);
                    dispatch(push("/campaigns_app_version"));
                }
            })
            .catch((error) => {
                let message = error ? error.detail : 'It was not possible to create the campaign, try again later'
                NotificationManager.error(message, 'ERROR', 3000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    } else {
        api.post(endpoint, _data)
            .then((response) => {
                if (response) {
                    NotificationManager.success('Campaign successfully registered', 'Success', 3000);
                    dispatch(push("/campaigns_app_version"));
                }
            })
            .catch((error) => {
                let message = error ? error.detail : 'It was not possible to create the campaign, try again later'
                NotificationManager.error(message, 'ERROR', 3000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    }
}

const readCampaign = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
            let data = response;
            dispatch(initializeForm('twilioAppVersionFrom', data));
            setTimeout(() => {
                dispatch(getAndroidOption());
                dispatch(getIosOption());
            }, 600);
        })
        .catch((error) => {
            console.error(error);
            dispatch(basicReducer.actions.setItem({}));
            NotificationManager.error('Data could not be obtained', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const sendMessage = (id) => (dispatch) => {
    Swal.fire({
        title: 'Are you sure to send the text?',
        text: "This action can't be reverted!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, send it!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(basicReducer.actions.setLoader(true));
            api.post(`${endpoint}/${id}/send_message`)
                .then((response) => {
                    if (response.in_progress === true && response.same_day == false) {
                        dispatch(setTwilioData({
                            total_to_send:0,
                            total_sent: 0
                        }))
                        dispatch(waitSendMessage(id))
                    } else {
                        Swal.fire('Info', "Text was send today, can't send it again", "info")
                    }
                    // NotificationManager.success('Message sent successfully', 'Success', 3000);
                })
                .catch((error) => {
                    console.error(error)
                    NotificationManager.error('It was not possible to send the message, try again later', 'ERROR', 3000);
                })
                .finally(() => {
                    dispatch(basicReducer.actions.setLoader(false));
                });
        }
    })
}

const waitSendMessage = (id) => (dispatch) => {
    let intervalPromise;

    function listener() {
        api.get(`${endpoint}/${id}/status`, {id})
        .then((resp) => {
            if (resp.in_progress === true) {
                // PROCESANDO
                dispatch(setTwilioData(resp))
                dispatch(setShowTwilioModal(true));
            } else if (resp.in_progress === false) {
                // TERMINADO
                clearInterval(intervalPromise);
                dispatch(setTwilioData({
                    total_to_send: 0,
                    total_sent: 0
                }))
                dispatch(setShowTwilioModal(false));
                NotificationManager.success('Texts sent successfully', 'SUCCESS', 3000);
            }
        })
        .catch((err) => {
            let msg =
                "It was not possible to send the texts, try again later";
            dispatch(setShowTwilioModal(false));
            dispatch(setTwilioData({
                total_to_send: 0,
                total_sent: 0
            }))
            clearInterval(intervalPromise);
            NotificationManager.error(msg, 'ERROR', 3000);
        })
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
}

const updateCampaign = (id, data, photos=[]) => (dispatch, getStore) => {
    const source = getStore()['campaigns'];
    dispatch(basicReducer.actions.setLoader(true));
    let _data = {...data}
    if (photos.length) {
        api.putAttachments(`${endpoint}/${id}`, _data, photos)
            .then((response) => {
                if (response) {
                    NotificationManager.success('Campaign successfully updated', 'Success', 3000);
                    dispatch(push("/campaigns_app_version"));
                }
            })
            .catch((error) => {
                let message = error ? error.detail : 'It was not possible to update the campaign, try again later'
                NotificationManager.error(message, 'ERROR', 3000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    }else {
        api.put(`${endpoint}/${id}`, _data).then(() => {
            NotificationManager.success('Campaign successfully updated', 'Success', 3000);
            dispatch(push('/campaigns_app_version'));
        }).catch((error) => {
            let message = error ? error.detail : 'It was not possible to update the campaign, try again later'
            NotificationManager.error(message, 'ERROR', 3000);
        }).finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
    }
}

const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["campaigns"];
    const {AppVersionFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (AppVersionFilterForm && AppVersionFilterForm.values) {
        const {search} = AppVersionFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }

    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

export const actions = {
    ...basicReducer.actions,
    getAndroidOption,
    getIosOption,
    createCampaign,
    readCampaign,
    sendMessage,
    updateCampaign,
    toList,
    searchChange,
}


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_ANDROID_OPTION]: (state, {androidOption}) => {
        return {
            ...state,
            androidOption,
        };
    },
    [SET_IOS_OPTION]: (state, {iosOption}) => {
        return {
            ...state,
            iosOption,
        };
    },
    [SHOW_TWILIO_MODAL]: (state, {twilioModal}) => {
        return {
            ...state,
            twilioModal,
        };
    },
    [TWILIO_DATA]: (state, {twilioData}) => {
        return {
            ...state,
            twilioData,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    androidOption: [],
    iosOption: [],
    twilioModal: false,
    twilioData: {
        total_to_send: 0,
        total_sent: 0
    },
    ...basicReducer.initialState,
};

export default handleActions(reducers, initialState);
