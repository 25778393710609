import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {SELECT_ALL_OPTION} from '../../../utility/constants'
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "app_cancellation_delete_option"

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "cancellationDeleteOption",
    endpoint,
    "cancellationDeleteOptionForm",
    "/app_cancellation_delete_option",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------


const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["cancellationDeleteOption"];
    const {cancellationDeleteOptionFormFiltersForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (cancellationDeleteOptionFormFiltersForm && cancellationDeleteOptionFormFiltersForm.values) {
        const {search, type} = cancellationDeleteOptionFormFiltersForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
        if (type !== "" && type !== null && type !== undefined && type !== SELECT_ALL_OPTION["value"]) {
            if (type.length == 0) {
                params.type__in = 0
            }else {
                params.type__in = type;
            }
        }
    }

    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};



const edit = (id, data = {}) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Notification updated', 'Success', 3000);
        dispatch(push('/app_cancellation_delete_option'));
    }).catch((error) => {
        let msg = "Edition failed"
        if (error && error.detail) {
            msg = error.detail
        }

        NotificationManager.error(msg, 'ERROR', 5000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

const remove = id => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(toList());
        NotificationManager.success('Record removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

export const actions = {
    ...basicReducer.actions,
    toList,
    edit,
    searchChange,
    remove,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
};

export default handleActions(reducers, initialState);
