import { connect } from 'react-redux'
import DeletedBrokerList from './DeletedBrokerList'
import {actions} from '../../../../../redux/modules/deleted/broker'

const mapStateToProps = (state) => ({
    ...state.deletedBroker
})

const mapDispatchToProps = {
    ...actions,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletedBrokerList)