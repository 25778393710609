import Avatar from "react-avatar";
import React, {useState, useEffect} from 'react';
import Grid from "../../../Utils/GridTableWithScroll";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import {SIZE_PER_PAGE} from "../../../../../utility/constants";

const VendorTypeGrid = (props) => {
    const {data, loader, page, toList, remove} = props;

    const [gridHeight, setGridHeight] = useState(200)
    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("JOB-TYPE-VENDOR-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])


    return (
        <div id="JOB-TYPE-VENDOR-LIST" className="container-list card border-dark border-card-page px-4 py-2">
            <div className="card-body d-flex flex-column px-0 py-2">
                <Grid
                    hover
                    height={gridHeight}
                    striped
                    // page={page}
                    keyField={'id'}
                    loading={loader}
                    data={data && data.length > 0 ? {results: data} : []}
                    wrapperClasses="table-scroll-auto"
                    hidePagination={true}
                    // sizePerPage={200}
                    onPageChange={(value) => toList(value)}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    remove: remove,
                                    edit: 'job-type-vendor',
                                }),
                        },
                        {
                            dataField: "icon",
                            formatter: icon(),
                            text: " ",
                        },
                        {
                            dataField: "map_icon",
                            formatter: mapIcon(),
                            text: " ",
                        },
                        {
                            dataField: "title",
                            sort: false,
                            text: "Title",
                            formatter: (cell, row) => {
                                return <span style={{color: row.primary_color ? row.primary_color : 'inherit'}}>{cell}</span>
                            }
                        },
                        {
                            dataField: "sub_title",
                            sort: false,
                            text: "Sub Title",
                        },
                        {
                            dataField: "name",
                            sort: false,
                            text: "Name",
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export function icon() {
    return (cell, row) => {
        return (
            <div style={{width: '40px', filter: row.icon_color ? `opacity(0.5) drop-shadow(0 0 0 ${row.icon_color})` : 'none'}} className="d-flex flex-column justify-content-center align-content-center">
                <Avatar
                    src={cell}
                    size={40}
                    name={row.title}
                />
            </div>
        );
    };
}

export function mapIcon() {
    return (cell, row) => {
        return (
            <div style={{width: '40px', filter: row.map_icon_color ? `opacity(0.5) drop-shadow(0 0 0 ${row.map_icon_color})` : 'none'}} className="d-flex flex-column justify-content-center align-content-center">
                <Avatar
                    src={cell}
                    size={40}
                    name={row.title}
                />
            </div>
        );
    };
}

export default VendorTypeGrid;
