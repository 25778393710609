import React, { Component } from "react";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CreateUpdateLegalForm from "./CreateUpdateLegalForm";

export class TutorialVideoCreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
        };
    }

    componentWillMount() {
        const { match, read } = this.props;
        if (match.params.id) {
            read(match.params.id);
        }
        if (match.path === "/legal-link/:id/edit") {
            this.setState({ is_update: true });
        }
    }

    create = (data) => {
        console.log("DATA: ", data);
        this.props.create(data)
    };

    update = (data) => {
        const { match, item } = this.props;
        const _id = data.id ? data.id : match.params.id;
        this.props.edit(_id, data)
    };

    render() {
        //  State
        const { match, item, loader } = this.props;
        const { is_update } = this.state;
        const sendFunction = match.params.id ? this.update : this.create;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_update && "Update Legal"}
                            {!is_update && "Create Legal"}
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4">
                    <LoadMask loading={loader} dark blur>
                        <CreateUpdateLegalForm
                            item={item}
                            loader={loader}
                            onSubmit={sendFunction}
                            is_update={is_update}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default TutorialVideoCreateUpdate;
