import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/stats/referral';
import ReferralRawData from "./ReferralRawData";

const ms2p = (state) => {
    return {
        ...state.referralRawData,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReferralRawData);