import React, {useState, useEffect} from "react";
import moment from "moment"
import Avatar from "react-avatar";
import Grid from "../../../Utils/GridTable";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import {
    DEFAULT_PAGE_SIZE_IN_BACKEND,
} from "../../../../../utility/constants";


const TrackerFieldsGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        remove,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("TRACKER-FIELD-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="TRACKER-FIELD-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={DEFAULT_PAGE_SIZE_IN_BACKEND}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    remove: remove,
                                    edit: 'tracker-field',
                                }),
                        },
                        {
                            dataField: "name",
                            sort: false,
                            text: "Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "color",
                            sort: false,
                            align: 'center',
                            text: "Color",
                            formatter: (cell, row) => {
                                const SEVERITY = 200
                                const STATUS = 400
                                if (row.type == SEVERITY || row.type == STATUS) {
                                    return (
                                        <div className="pl-2 d-flex flex-row align-items-center justify-content-start">
                                            <i className="fa fa-square fa-2x" style={{color: cell}}/>
                                        </div>
                                    )
                                }

                                return null
                            }
                        },
                        {
                            dataField: "type",
                            sort: false,
                            text: "Type",
                            formatter: (cell, row) => {
                                const TYPES = {
                                    100: 'Owner',
                                    200: "Severity",
                                    300: "Type",
                                    400: "Status",
                                    500: "Agreement Signed",
                                }
                                return <span>{TYPES[cell] ? TYPES[cell] : ''}</span>
                            }
                        },
                    ]}
                />
            </div>
        </div>
    );

};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('lll')}
            </p>
        )
    };
}

export default TrackerFieldsGrid;
