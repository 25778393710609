import _ from "lodash";
import Switch from 'react-switch';
import classNames from 'classnames';
import DayPicker from '../DayPicker';
import DatePicker from "react-date-picker";
import { SketchPicker } from 'react-color';
import 'react-image-crop/dist/ReactCrop.css'
import NumberFormat from 'react-number-format';
import ReactStars from "react-rating-stars-component";
import React, {useState, useEffect, useRef} from 'react';
import Select, { Creatable, Async, components } from 'react-select';
import FileUploader from '../FileUploader/FileUploader';
import {GOOGLE_MAPS_KEY, SELECT_ALL_OPTION} from '../../../../utility/constants';
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import ReactCrop,{
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'


export class ColorPicker extends React.Component {
    state = {
        displayPicker: false,
    };

    handleClick = () => {
        if (!this.props.disabled) {
            this.setState({ displayPicker: true });
        }
    }

    handleClose = () => {
        this.setState({ displayPicker: false });
    }

    render() {
        const { input, disabled, customClass, meta: { touched, error } } = this.props;
        const invalid = touched && error;
        const { displayPicker } = this.state;
        const popover = {
            position: 'absolute',
            zIndex: '2',
        };
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        };
        return (
            <React.Fragment>
                <input
                    {...input}
                    type="text"
                    placeholder="Color"
                    disabled={disabled}
                    onClick={this.handleClick}
                    className={classNames(`form-control ${customClass}`, { 'is-invalid': invalid })}
                />
                {displayPicker && (
                    <div style={popover}>
                        <div style={cover} onClick={this.handleClose} />
                        <SketchPicker
                            color={input.value}
                            onChangeComplete={color => input.onChange(color.hex)}
                        />
                    </div>
                )}
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export const colorPicker = ({ input, placeholder, type, meta: { touched, error } }) => {
    return (
        <SketchPicker
            color={input.value}
            onChangeComplete={(color) => input.onChange(color.hex)}
        />
    );
};

export const renderField = ({
    disabled, input, customClass, placeholder, onFocus, type, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <input
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                onFocus={onFocus}
                className={classNames(`form-control ${customClass}`, { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderSearchField = ({
    disabled, input, customClass, placeholder, onFocus, type, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className='img-fluid w-100 pl-2'>
            <img
                    className="custom-search-icon"
                    src={require('../../../../../assets/img/search.svg')}
                    alt="searchIcon"
                />
            <input
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                onFocus={onFocus}
                className={classNames(`search-input ${customClass}`, { 'is-invalid': invalid })}
            />
            {
                input.value && input.value.length && (
                    <div onClick={(e) => input.onChange("")} className="pointer" style={{display: "inline", marginLeft: "-27px"}}>
                        <i className="material-icons" style={{top: "6px", fontSize: "20px", color: "#7E8A97"}}>close</i>
                    </div>
                )
            }
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};


export const renderTextArea = ({
    disabled, input, customClass, placeholder, rows, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 6}
                className={classNames(`form-control ${customClass}`, { 'is-invalid': invalid })}
                disabled={disabled}
                minLength={12}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderNumber = (
    {
        disabled, input, allowNegative = true, decimalScale, placeholder, meta: { touched, error },
    prefix = "", suffix = "", numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                allowNegative={allowNegative}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};


export const renderCurrency = ({
    input,customClass,  meta: { touched, error }, prefix = "$ ", placeholder, allowNegative = true, disabled
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames(`form-control ${customClass}`, { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                allowNegative={allowNegative}
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                disabled={disabled}
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({
    id, input, customClass, meta: { touched, error }, label, disabled,
}) => {
    const invalid = touched && error;
    return (
        <div className={`d-flex align-items-center ${customClass}`}>
            <Switch
                id={id}
                onColor="#AA1111"
                offColor="#D7D7D7"
                offHandleColor="#323232"
                handleDiameter={14}
                onHandleColor="#FFFFFF"
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={44}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check"/>
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderFieldRadio = ({ input, label, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span/>
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback d-flex">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const radioSelect2 = ({input, isSub, idOption, removeFilter, addFilter}) => {
    const [check, setCheck] = useState(true);
    const setValues = (val) => {
        if (val) {
            addFilter(idOption, isSub);
        } else {
            removeFilter(idOption, isSub);
        }
        setCheck(val);
    };
    return (
        <React.Fragment>
            <div className={`d-flex ${isSub ? 'ml-3' : ''}`}>
                <div className="px-1">
                    <label
                        id={`${input.name}`}
                        onClick={() => setValues(!check)}>
                        {check ?
                            <i className="material-icons checked-icon">radio_button_checked</i> :
                            <i className="material-icons checked-icon">radio_button_unchecked</i>
                        }
                    </label>
                </div>
            </div>
        </React.Fragment>
    );
};

export const SelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        set_value,
        item,
        setItem,
        maxHeight=null,
        meta: {touched, error},
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        if (isMulti) {
            let _value = [];
            if (value) {
                value.forEach(value_item => {
                    let _item = _.find(_options, {value: value_item});
                    if (_item) {
                        _value.push(_item)
                    }
                })
                value = _value.length > 0 ? _value : [];
            }
        }else {
            value = _.find(_options, {value});
        }
    }
    let _value = item && Object.entries(item).length !== 0 ? item : value;
    if(_options && _options.length > 0){
        _value = _value;
    } else {
        if (_value == undefined) {
            _value = null;
        }
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', {'is-invalid': invalid})}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    if (isMulti) {
                        let _value = [];
                        e.forEach((item) => {
                            if (item[valueKey]) {
                                _value.push(item[valueKey])
                            }
                        })
                        input.onChange(_value);
                    }else{
                        input.onChange(e ? e[valueKey] : null);
                    }
                    set_value && set_value(e);
                    setItem && setItem({});
                }}
                value={_value}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    borderLeft: "1px solid #C7C7C7",
                    borderRight: "1px solid #C7C7C7",
                    borderTop: "1px solid #C7C7C7",
                    borderBottom: "1px solid #C7C7C7",
                    fontSize: "12px !important",
                    color: '7b7b7b !important',
                    colors: {
                        ...theme.colors,
                        primary25: '#F1F1F1', // style to select one option
                        primary: '#7E8A97',  // style of option selected
                    },
                })}
                styles={maxHeight ? {...customStyles, menuList: style => ({...style, maxHeight: maxHeight})} : customStyles}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};


export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        meta: { touched, error },
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary25: '#B9BDC1',
                        primary: '#7E8A97',
                    },
                })}
                styles={customStyles}

            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error },
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary25: '#B9BDC1',
                        primary: '#7E8A97',
                    },
                })}
                styles={customStyles}

            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

const customStyles = {
    option: (styles, {isDisabled, isSelected}) => ({
        ...styles,

        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? "#E1E1E1 " : '#E6E6E5 0% 0% no-repeat padding-box'),
            color: !isDisabled && (isSelected ? 'black' : 'black'),
        },
    }),
    menu: style => ({
        ...style,
        background: '#FFFFFF',
        // border: '1px solid $primary',
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "14px !important",
        borderRadius: "5px",
        opacity: 1,
        marginTop: 0,
        color: '7b7b7b !important',
    }),
    menuList: style => ({
        ...style,
        maxHeight: "60vh",
    }),
    control: styles => ({
        ...styles,
        backgroundColor: "#FFFFFF",
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "12px !important",
        borderRadius: "5px",
        opacity: 1,
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: 0,
        font: "normal normal medium 13px/17px Poppins-Regular !important",
        minHeight: 27,
        marginBottom: 0,
        color: '7b7b7b !important',
    }),
};

const Option = (props) => {

    // console.log("PROPS OPT: ", props);
    const {selectProps, isSelected} = props;
    let isChecked = selectProps.isOptionSelected;
    if (isSelected) {
        isChecked = true;
    }
    return (
      <React.Fragment>
        <components.Option {...props}>
            <div className="pointer d-flex flex-row align-items-center">
                <div className="read-checkbox">
                    <i className={`fa fa-check ${isChecked ? 'checked' : ''}`}></i>
                </div>
                <label className="pointer m-0 pl-1 flex-1" style={{ wordBreak: 'break-all', lineHeight: "1"}}>{" "}{props.label}</label>
            </div>
        </components.Option>
      </React.Fragment>
    );
  };

const ValueContainer = ({
    children,
    ...props
}) => {
    const {options, selectProps} = props;
    // console.log("PROPS VALUE: ", props);
    const [selected, setSelected] = useState(0);
    const [nameItem, setNameItem] = useState(null);
    const [inputChild, setInputChild] = useState(null);

    useEffect(() => {
        const placeholder = _.find(children, { key: "placeholder" })

        if (placeholder) {
            setSelected(0)
        } else {
            // const _inputChild = _.find(children, function(o) {
            //     if (o && !o.length && o.key == null) {
            //         return true
            //     }else {
            //         return false
            //     }
            // })
            // if (_inputChild && inputChild == null) {
            //     setInputChild([_inputChild])
            // }else {
            //     setInputChild(null)
            // }
            const items = _.find(children, function (o) { return o && o.length > 0 });
            if (items && items.length) {
                if (items.length == 1) {
                    const {data} = items[0].props;
                    setNameItem(data ? data.label : null)
                }else {
                    setNameItem(null)
                }
                setSelected(items.length)
            } else {
                setSelected(0)
            }
        }
    }, [children])

    return (
        <components.ValueContainer {...props}>
            {/* {!selectProps.menuIsOpen ? (selectProps.isOptionSelected ? (selectProps.selectAllOption ? selectProps.selectAllOption["label"] : "Select All") : (
                selected > 0 ? (selected == 1 && nameItem != null ? <span className="value_selected">{nameItem}</span>:<span className="value_selected">Selected ({selected})</span>) : children   
            )): null} */}
            <div className="flex-1 d-flex flex-row align-items-center">
                {children && children.map((child, index) => {
                    if (child) {
                        if (index == 0) {
                            if (child.length) {
                                if(selected != null && selected == 1) {
                                    return <span className="value_selected">{nameItem}</span>
                                }else {
                                    return <span className="value_selected">Selected ({selected})</span>
                                }
                            }else {
                                if (selectProps.menuIsOpen) {
                                    return null
                                }else {
                                    if (child.key == 'placeholder') {
                                        return <span className="value_selected">{child.props && child.props.children ? child.props.children : "Select..."}</span>
                                    }else {
                                        return <span className="value_selected">Select...</span>
                                    }
                                }
                            }
                        } else {
                            if (child.length) {
                                return null
                            }else {
                                if (child.key == null) {
                                    return child
                                }
                                return null
                            }
                        }
                    }
                    return null
                })}
            </div>
        </components.ValueContainer>
    )
};

const MenuWithFooter = (props) => {
    //  State
    const {selectProps} = props;
    const {selectAllOption} = selectProps;
    //  Bind
    const {getValue} = props;
    const {onApply, onMenuClose, isTouchDevice} = selectProps;
    const menuRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onMenuClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef])

    const onClickApply = (e) => {
        const value = getValue();
        const _value = [];
        if (value && value.length) {
            value.forEach((item) => {
                if (item["value"] !== selectAllOption["value"]){
                    _value.push(item["value"])
                }
            })
        }
        onApply(_value)
        onMenuClose();
    }

    return (
        <div ref={menuRef}>
            <components.Menu {...props}>
                <div>
                    <div>{props.children}</div>
                    {!isTouchDevice  && selectAllOption != null && (
                        <div className="d-flex flex-row align-items-center justify-content-center my-2">
                            <button className="btn btn-secondary" type="button" onClick={onClickApply} style={{minHeight: '1.4rem'}}>Apply</button>
                        </div>
                    )}
                </div>
            </components.Menu>
        </div>
    )
}

export const MultiSelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti=true,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        set_value,
        item,
        setItem,
        meta: {touched, error},
        showSelectAllOption=true
    }) => {

    const [selectIsOpen, setSelectIsOpen] = useState(false)
    const valueRef = useRef(input.value);
    valueRef.current = input.value;

    const isTouchDevice = () => {
        return window.matchMedia("(pointer: coarse)").matches
    }

    const selectAllOption = SELECT_ALL_OPTION;
    const invalid = touched && error;
    const isSelectAllSelected = () => {
        let result = false;

        if (valueRef.current && typeof(valueRef.current) == "string" && valueRef.current == selectAllOption.value){
            result = true
        }
        // valueRef.current.length === options.length;
        // console.log("isSelectAllSelected: ", result);
        return result
    }

    // const isOptionSelected = option =>
    //     (valueRef.current && option && valueRef.current.some(({ value }) => value === option.value)) ||
    //     isSelectAllSelected();

    const getOptions = () => {
        let values = options
        if (showSelectAllOption) {
            values = [selectAllOption, ...options]
        }
        return values
    };

    const getValues = () => {
        let _value = [];
        console.log("INPUT VAL: ", input.value);
        if (input.value) {
            if (input.value !== selectAllOption.value) {
                input.value.forEach(value_item => {
                    let _item = _.find(options, { value: value_item });
                    if (_item) {
                        _value.push(_item)
                    }
                })
            }else {
                _value = selectAllOption
            }
        }

        return _value
    }

    const getValue = () =>
        isSelectAllSelected() && showSelectAllOption ? selectAllOption : getValues();

    const onMenuOpen = () => {
        console.log("ABRIENDO");
        setTimeout(()=>{
            const selectedEl = document.getElementsByClassName("MultiSelectShowIT__menu-list")[0];
            if(selectedEl) {
                selectedEl.scrollTo(0, 0)
            }
        },15);
    };

    return (
        <React.Fragment>
            <Select
                menuIsOpen={selectIsOpen}
                isClearable={false}
                className={classNames('react-select-container', {'is-invalid': invalid})}
                classNamePrefix={"MultiSelectShowIT"}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={getOptions()}
                placeholder={placeholder}
                closeMenuOnSelect={false}
                onChange={(e, actionMeta) => {
                    if (isMulti) {
                        const { action, option, removedValue } = actionMeta;
                        let valueOption = [];
                        let _value = [];
                        if (action === "select-option" && option.value === selectAllOption.value) {
                            valueOption.push(selectAllOption);
                            // console.log("CASO 1");
                        } else if (
                            (action === "deselect-option" &&
                                option.value === selectAllOption.value) ||
                            (action === "remove-value" &&
                                removedValue.value === selectAllOption.value)
                        ) {
                            valueOption = [];
                            // console.log("CASO 2");
                        } else if (
                            actionMeta.action === "deselect-option" &&
                            isSelectAllSelected()
                        ) {
                            valueOption = options.filter(({ value }) => value !== option.value);
                            // console.log("CASO 3");
                        } else {
                            const _includeSelectAll = _.find(e, function(o) {
                                return o[valueKey] == selectAllOption[valueKey]
                            })
                            if (_includeSelectAll) {
                                let valuesToExclude = [];
                                e.forEach((item) => {
                                    if(item[valueKey] !== selectAllOption[valueKey]) {
                                        valuesToExclude.push(item[valueKey])
                                    }
                                })
                                valueOption = _.filter(options, function(o) {
                                    const isExclude = _.find(valuesToExclude, function (_o) {
                                        return _o == o[valueKey]
                                    })
                                    if (isExclude) {
                                        return false
                                    }else {
                                        return true
                                    }
                                })
                            }else {
                                const _valueOption = e || [];
                                if (_valueOption.length == options.length && showSelectAllOption) {
                                    valueOption = [selectAllOption]
                                }else {
                                    valueOption = _valueOption
                                }
                            }
                            // console.log("CASO 4");
                        }
                        const includeSelectAll = _.find(valueOption, function(o) {
                            return o[valueKey] === selectAllOption[valueKey]
                        })
                        if (includeSelectAll && showSelectAllOption) {
                            _value = selectAllOption[valueKey];
                        }else {
                            valueOption.forEach(item => {
                                _value.push(item[valueKey])
                            })
                        }



                        input.onChange(_value, actionMeta);
                        if (isTouchDevice()) {
                            console.log("ES TOUCH: ", _value);
                            set_value(_value)
                        }
                    }else{
                        input.onChange(e ? e[valueKey] : null);
                    }
                }}
                value={getValue()}
                isDisabled={disabled}
                theme={theme => ({
                    ...theme,
                    borderRadius: 5,
                    borderLeft: "1px solid #C7C7C7",
                    borderRight: "1px solid #C7C7C7",
                    borderTop: "1px solid #C7C7C7",
                    borderBottom: "1px solid #C7C7C7",
                    fontSize: "12px !important",
                    color: '7b7b7b !important',
                    colors: {
                        ...theme.colors,
                        primary25: '#F1F1F1', // style to select one option
                        primary: '#7E8A97',  // style of option selected
                    },
                })}
                styles={customMultiStyles}
                allowSelectAll={true}
                hideSelectedOptions={false}
                isOptionSelected={isSelectAllSelected()}
                onApply={set_value}
                isTouchDevice={isTouchDevice()}
                selectAllOption={showSelectAllOption ? selectAllOption : null}
                onMenuOpen={() => {
                    onMenuOpen()
                    setSelectIsOpen(true)
                }}
                onMenuClose={() => setSelectIsOpen(false)}
                components={{
                    Option,
                    ValueContainer,
                    Menu: MenuWithFooter
                    // Menu: (props) => <MenuWithFooter {...props} onApply={set_value} selectAllOption={selectAllOption} />,
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

const customMultiStyles = {
    option: (styles, {isDisabled, isSelected}) => ({
        ...styles,
        backgroundColor: 'transparent',
        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? "#E1E1E1 " : '#E6E6E5 0% 0% no-repeat padding-box'),
            color: !isDisabled && (isSelected ? 'black' : 'black'),
        },
    }),
    menu: style => ({
        ...style,
        background: '#FFFFFF',
        // border: '1px solid $primary',
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "14px !important",
        borderRadius: "5px",
        opacity: 1,
        marginTop: 0,
        color: '7b7b7b !important',
    }),
    menuList: style => ({
        ...style,
        maxHeight: "60vh",
    }),
    control: styles => ({
        ...styles,
        backgroundColor: "#FFFFFF",
        borderLeft: "1px solid #C7C7C7",
        borderRight: "1px solid #C7C7C7",
        borderTop: "1px solid #C7C7C7",
        borderBottom: "1px solid #C7C7C7",
        fontSize: "12px !important",
        borderRadius: "5px",
        opacity: 1,
        paddingBottom: 0,
        paddingTop: 0,
        marginTop: 0,
        font: "normal normal medium 13px/17px Poppins-Regular !important",
        minHeight: 27,
        marginBottom: 0,
        color: '7b7b7b !important',
    }),
};



/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const customFieldFile = ({ title, customClass, containerClass='mb-3',text, id, setFile, input, type, active, remove, accept }) => {
    const action = (e) => {
        setFile(e.target.files[0]);
    };
    return (
        <div className={containerClass}>
            {active &&
            <label
                className={"btn py-0 justify-content-center d-flex " + customClass}
                htmlFor={`id${id}`}
            >{title?title:"Import Excel"}
            </label>
            }
            <input
                name={id}
                {...input}
                type={type}
                className="display-none"
                accept={
                    accept?accept:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet," +
                    " application/vnd.ms-excel"
                }
                id={`id${id}`}
                onChange={action}
            />
            {!active &&
            <div className={"justify-content-start d-flex"}>
                <p style={{
                    border: "0.5px solid gray",
                    padding: "5px",
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    textAlign: 'center',
                }} className="ml-2 my-auto">{text}</p>
                <img
                    src={require('../../../../../assets/img/delete.svg')}
                    alt='delete'
                    className={"mt-1 ml-2"}
                    style={{
                        width: "22px",
                    height: "22px",
                    cursor: "pointer",
                }}
                onClick={()=> remove()}
                />
            </div>
            }
        </div>
    );
};

export const renderFilePicker = ({
    photo, setFile, className, disabled, input, styles, meta: { touched, error },
    customImgStyle=null
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                customImgStyle={customImgStyle}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}/>
            {invalid && <div className="invalid-feedback d-block" style={styles && styles}>
                {error}
            </div>}
        </div>
    );
};

export const renderDayPicker = ({className, disabled, maxDate, minDate, initialDate, outputFormatt=null, input, meta: {touched, error}}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, {'is-invalid': invalid})}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={(input.value === '' || input.value === undefined || input.value === null) ? initialDate : input.value}
                outputFormatt={outputFormatt}
            />
            {invalid && <div className="invalid-feedback d-block">
                {error}
            </div>}
        </div>
    );
};

export const renderDatePicker = ({ className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                onChange={e => input.onChange(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    );
};

export const renderAddressField = ({
    disabled, input, customClass, placeholder, meta: { touched, error },
    setCoords=null, setCityState=null, longStateName=false
}) => {
    const invalid = touched && error;
    const [value, setValue] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const [hasGoogle, setHasGoogle] = useState(false);

    useEffect(() => {
        const _intervalId = setInterval(() => {
            // console.log("INTERVAL");
            if (window.google && window.google.maps && !hasGoogle) {
                setHasGoogle(true);
            }
        }, 300);

        setIntervalId(_intervalId)

        return () => {
            clearInterval(_intervalId);
        }
    }, [])

    useEffect(() => {
        formattValue(input)
    }, [input.value])

    useEffect(() => {
        if (hasGoogle == true && intervalId != null) {
            clearInterval(intervalId)
            formattValue(input)
        }
    }, [hasGoogle])


    const formattValue = (_input) => {
        if (_.isEmpty(_input.value)) {
            setValue(null);
            if(setCoords) {
                setCoords(null)
            }
            _input.onBlur();
        } else if (_.isString(_input.value.formatted_address)) {
            if (window.google) {
                geocodeByAddress(_input.value.formatted_address)
                    .then(results => {
                        console.log("GEO RES: ", results);
                        if (results.length) {
                            const _value = {
                                label: results[0].formatted_address,
                                value: { ...results[0], description: results[0].formatted_address }
                            }
                            setValue(_value);
                            const item = results[0];
                            if(setCoords) {
                                const {geometry} = item;
                                const {location} = geometry;
                                let coordinates = null
                                if (location) {
                                    coordinates = {
                                        latitude: location.lat(),
                                        longitude: location.lng()
                                    }
                                }
    
                                setCoords(coordinates)
                            }
                            if (setCityState) {
                                let data = {
                                    city: null,
                                    state: null,
                                    zip_code: null
                                }
                                const {address_components} = item;
                                if (address_components && address_components.length > 0) {
                                    const city = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('locality')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    const state = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('administrative_area_level_1')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    const zip_code = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('postal_code')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    data.city = city ? city.long_name: null;
                                    data.state = state ? (longStateName ? state.long_name: state.short_name): null;
                                    data.zip_code = zip_code ? zip_code.long_name : null
                                }
                                setCityState(data)
                            }
                        } else {
                            setValue(null)
                            if(setCoords) {
                                setCoords(null)
                            }
                            if (setCityState) {
                                setCityState({city: null, state: null, zip_code: null})
                            }
                        }
                        _input.onBlur();
                    })
                    .catch(error => console.error(error));
            }
        } else if (_.isString(_input.value)) {
            if (window.google) {
                geocodeByAddress(_input.value)
                    .then(results => {
                        console.log("GEO RES 2: ", results);
                        if (results.length) {
                            const _value = {
                                label: results[0].formatted_address,
                                value: { ...results[0], description: results[0].formatted_address }
                            }
                            setValue(_value);
                            const item = results[0];
                            if(setCoords) {
                                const {geometry} = item;
                                const {location} = geometry;
                                let coordinates = null
                                if (location) {
                                    coordinates = {
                                        latitude: location.lat(),
                                        longitude: location.lng()
                                    }
                                }
    
                                setCoords(coordinates)
                            }
                            if (setCityState) {
                                let data = {
                                    city: null,
                                    state: null,
                                    zip_code: null
                                }
                                const {address_components} = item;
                                if (address_components && address_components.length > 0) {
                                    const city = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('locality')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    const state = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('administrative_area_level_1')) {
                                            return true;
                                        }
                                        return false;
                                    })

                                    const zip_code = _.find(address_components, function(o) {
                                        if (o.types && o.types.includes('postal_code')) {
                                            return true;
                                        }
                                        return false;
                                    })
                                    data.city = city ? city.long_name: null;
                                    data.state = state ? (longStateName ? state.long_name: state.short_name): null;
                                    data.zip_code = zip_code ? zip_code.long_name : null
                                }
                                setCityState(data)
                            }
                        } else {
                            setValue(null)
                            if(setCoords) {
                                setCoords(null)
                            }
                            if (setCityState) {
                                setCityState({city: null, state: null, zip_code: null})
                            }
                        }
                        _input.onBlur();
                    })
                    .catch(error => console.error(error));
            }
        }
    }


    return (
        <React.Fragment>
            <GooglePlacesAutocomplete
                apiKey={GOOGLE_MAPS_KEY}
                apiOptions={{ language: 'en', region: 'us' }}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: 'us',
                    }
                }}
                selectProps={{
                    className: classNames('react-select-container', { 'is-invalid': invalid }),
                    isClearable: true,
                    placeholder: placeholder,
                    isDisabled: disabled,
                    theme: (theme) => ({
                        ...theme,
                        borderRadius: 5,
                        fontSize: "12px !important",
                        color: '#7b7b7b !important',
                        colors: {
                            ...theme.colors,
                            primary25: '#F1F1F1', // style to select one option
                            primary: '#7E8A97',  // style of option selected
                        },
                    }),
                    styles: {
                        input: (provided) => ({
                            ...provided,
                            fontSize: "13px !important",
                            color: '#495057 !important',
                        }),
                        control:(provided) => ({
                            ...provided,
                            borderColor: invalid ? "#c4183c" : "#C7C7C7",
                            borderWidth: "2px",
                        }),
                        option: (provided) => ({
                            ...provided,
                            fontSize: "13px !important",
                            color: '#495057 !important',
                            borderBottom: "1px solid #C7C7C7",
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            fontSize: "13px !important",
                            color: '#495057 !important',
                        }),
                    },
                    onChange: (e) => {
                        console.log("CHANGE VALUE: ", e);
                        setValue(e);
                        if (e) {
                            const _value = e.value ? e.value : null
                            input.onChange(_value ? _value.description : '');
                        } else {
                            input.onChange('');
                        }
                    },
                    value: value,
                }}

                onLoadFailed={(error) => (
                    console.error("Could not inject Google script", error)
                )}
            />
            {invalid && (
                <div className="invalid-feedback d-block">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderStarsField = (
    {
        disabled, input, allowNegative = true, decimalScale, placeholder, meta: { touched, error },
    
}) => {
    const invalid = touched && error;
    const [starKeyForce, setStarKeyForce] = useState(0)

    useEffect(() => {
        setStarKeyForce(prev => prev + 1)
    }, [input.value])

    return (
        <div>
            {/* <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                allowNegative={allowNegative}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                disabled={disabled}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            /> */}
            <ReactStars
                size={25}
                count={5}
                value={input.value}
                edit={true}
                isHalf={true}
                key={starKeyForce}
                onChange={(value) => {
                    // console.log("VALUE: ", value);
                    input.onChange(value)
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFileField = ({
    placeholder, setFile, className="", disabled, input, styles, meta: { touched, error },
    fileAccept=null, fileData=null,
}) => {
    const invalid = touched && error;
    const [fileObj, setFileObj] = useState(null)
    const inputRef = useRef(null)

    const handleFileInput = (event) => {
        // handle validations
        const _fileObj = event.target.files && event.target.files[0];
        if (!_fileObj) {
            return;
        }

        console.log('fileObj is', _fileObj);

        // 👇️ reset file input
        event.target.value = null;

        // 👇️ is now empty
        console.log("INPUT", event.target.files);

        setFile(_fileObj)
        setFileObj(_fileObj)
    }

    useEffect(() => {
      if(fileData) {
        console.log("FILE DATA: ", fileData);
        setFileObj(fileData)
      }
    }, [fileData])

    console.log("fileData: ", fileData);
    

    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                accept={fileAccept}
                onChange={handleFileInput}
            />
            <button
                style={{height: "calc(1.5em + .75rem + 4px)"}}
                type="button"
                onClick={(e) => inputRef.current && inputRef.current.click()}
                className="btn btn-upload w-100"
            >
                {fileObj ? fileObj.name : (placeholder || "Select the file")}
            </button>
            {invalid && (
                <div
                    className="invalid-feedback d-block"
                    style={styles && styles}
                >
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFileImageCrop = ({
    photo, setFile, className, spectValue=16/9, disabled, input, styles, meta: { touched, error },
    customImgStyle={}, locked=false
}) => {
    const invalid = touched && error;
    const imgRef = useRef(null)
    const inputRef = useRef(null)
    const [image, setImage] = useState('')
    const [imgSrc, setImgSrc] = useState('')
    const [crop, setCrop] = useState()
    const [scale, setScale] = useState(1)
    const [completedCrop, setCompletedCrop] = useState()
    const [aspect, setAspect] = useState(spectValue)
    const [rotate, setRotate] = useState(0)

    useEffect(() => {
        if (completedCrop && imgRef.current) {
            setTimeout(() => {
                onCrop(imgRef);
            }, 500);
        }
    }, [completedCrop]);

    const onSelectFile = (e, file) => {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                input.onChange(reader.result);
                return setImgSrc(reader.result ? reader.result.toString() : "")
            });
            reader.readAsDataURL(e.target.files[0]);
            const _file = file || e.target.files[0]
            setImage(_file)
            const _crop = {
                height: 50,
                unit: "px",
                width: 50,
                x: 0,
                y: 0,
            }
            setCrop(_crop)
            setTimeout(() => {
                onCrop(imgRef);
            }, 500);
        }
    }
    const onImageLoad = (e) => {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    };

    const centerAspectCrop = (
        mediaWidth,
        mediaHeight,
        aspect
    ) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    };

    const onCrop = (imageRef) => {
        const TO_RADIANS = Math.PI / 180
        if (completedCrop) {
            // create a canvas element to draw the cropped image
            const canvas = document.createElement("canvas");

            // get the image element
            const _image = imageRef.current;

            // draw the image on the canvas
            if (_image) {
                const crop = completedCrop;
                const scaleX = _image.naturalWidth / _image.width;
                const scaleY = _image.naturalHeight / _image.height;
                const ctx = canvas.getContext("2d");
                const pixelRatio = window.devicePixelRatio;
                canvas.width = crop.width * pixelRatio * scaleX;
                canvas.height = crop.height * pixelRatio * scaleY;

                if (ctx) {
                    //   ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                    //   ctx.imageSmoothingQuality = "high";
                    ctx.scale(pixelRatio, pixelRatio);
                    ctx.imageSmoothingQuality = "high";

                    const cropX = crop.x * scaleX;
                    const cropY = crop.y * scaleY;

                    const rotateRads = rotate * TO_RADIANS;
                    const centerX = image.naturalWidth / 2;
                    const centerY = image.naturalHeight / 2;
                    ctx.save();

                    // 5) Move the crop origin to the canvas origin (0,0)
                    ctx.translate(-cropX, -cropY);
                    // 4) Move the origin to the center of the original position
                    ctx.translate(centerX, centerY);
                    // 3) Rotate around the origin
                    ctx.rotate(rotateRads);
                    // 2) Scale the image
                    ctx.scale(scale, scale);
                    // 1) Move the center of the image to the origin (0,0)
                    ctx.translate(-centerX, -centerY);

                    ctx.drawImage(
                        _image,
                        0,
                        0,
                        _image.naturalWidth,
                        _image.naturalHeight,
                        0,
                        0,
                        _image.naturalWidth,
                        _image.naturalHeight
                    );
                }

                let imageType = 'image/png'
                if (image) {
                    imageType = image.type
                }
                const base64Image = canvas.toDataURL(imageType); // can be changed to jpeg/jpg etc

                if (base64Image) {
                    // @ts-ignore
                    const fileType = base64Image.split(";")[0].split(":")[1];

                    const buffer = Buffer.from(
                        base64Image.replace(/^data:image\/\w+;base64,/, ""),
                        "base64"
                    );
                    let fileName = `${Math.random().toString(10)}.${fileType == 'image/png' ? 'png': 'jpg' }`

                    if (image && image.name) {
                        fileName = image.name
                    }
                    const file = new File([buffer], fileName, {
                        type: fileType,
                    });
                    setFile(file);
                }
            }
        }
    };

    return (
        <div className={classNames(`d-flex flex-column align-items-center ${className ? className : ''}`, { "is-invalid": invalid })}>
            {photo && !imgSrc && (
                <img
                    className="img-fluid"
                    src={photo}
                    style={{...customImgStyle}}
                />
            )}
            {!!imgSrc && (
                <ReactCrop
                    crop={crop}
                    locked={locked}
                    keepSelection
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => {
                        console.log("CROP: ", c);
                        setCompletedCrop(c)
                    }}
                    aspect={aspect}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{...{transform: `scale(${scale}) rotate(${rotate}deg)`}, ...customImgStyle}}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )}
            <label className="imageCrop">
                {!!imgSrc || photo ? (
                    <span className="material-icons align-self-center text-info mt-3" style={{fontSize: '45px'}}>
                        backup
                    </span>
                ): (
                    <img
                        style={{ color: 'rgba(255,255,255,0.3)', opacity: 0.8 }}
                        className="icon icon-upload w-100"
                        src={require(`assets/img/uploadLogo.PNG`)}
                        alt=""
                    />
                )}
                <input
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                    ref={inputRef}
                />
            </label>
            {/* <FileUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                customImgStyle={customImgStyle}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}/> */}
            {invalid && (
                <div
                    className="invalid-feedback d-block"
                    style={styles && styles}
                >
                    {error}
                </div>
            )}
        </div>
    );
};

export const RenderField = {
    renderField,
    renderSearchField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    SelectField,
    AsyncSelectField,
    CreatableSelectField,
    customFieldFile,
    radioSelect2,
    renderAddressField,
    renderStarsField,
    renderFileField,
    renderFileImageCrop,
};
