import moment from "moment"
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { renderFieldRadio } from "../../../../Utils/renderField";

const validate = (values, props) => {
    let errors = {};
    if (!values.type) {
        errors.type = "This field is required";
    }

    return errors;
};

const Form = (props) => {
    //  State
    const { loader, disabled, item } = props;
    //  Bind
    const { handleSubmit } = props;
    const [actionNoComplete, setActionNoComplete] = useState('')
    const [textCompletePayment, setTextCompletePayment] = useState('')
    const [agentName, setAgentName] = useState('')

    useEffect(() => {
        const DID_NOT_CONFIRM_ARRIVAL = 10
        const DID_NOT_CONFIRM_DEPARTURE = 20
        const DID_NOT_PERFORMED_REQUIRED_TASK = 40

        const job_name = item && item.job_type ? item.job_type.name : '';
        const name = item && item.job_type_option
            ? `${item.job_type_option.name}`
            : `${job_name}`;
        const agent_name = item && item.hired
            ? item.hired.agent.first_name
            : '';
        setAgentName(agent_name)
        if(item && item.not_completed_type == DID_NOT_CONFIRM_ARRIVAL) {
            const SHOWING = 'Showing'
            if (job_name.toUpperCase().includes(SHOWING.toUpperCase()) && (item && item.job_description.length > 1)) {
                setActionNoComplete(`The arrival time has not been confirmed for one or more properties you have requested to be shown today.`)
                setTextCompletePayment('You are the only one that can complete the job and release payment.')
            }else {
                setActionNoComplete(`Arrival time was yet to be confirmed for your ${name} performed by ${agent_name}.`)
                setTextCompletePayment('Completion of job and payment is available only with your action below.')
            }
        } else if(item && item.not_completed_type == DID_NOT_CONFIRM_DEPARTURE) {
            setActionNoComplete(`Departure time was yet to be confirmed for your ${name} Job.`)
            setTextCompletePayment('Completion of job and payment is available only with your action below')
        } else if (item && item.not_completed_type == DID_NOT_PERFORMED_REQUIRED_TASK) {
            setActionNoComplete(`The job requirements were not completed for the ${name} Job.`)
            setTextCompletePayment('Completion of job and payment is available only with your action below.')
        }

    }, [item]);


    return (
        <form
            className="w-100 mx-auto justify-content-center mt-1"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="col-12">
                    <label htmlFor="">
                    {
                        `After several reminders, ${actionNoComplete} ${textCompletePayment}`
                    }
                    </label>
                </div>
            </div>
            <div className="row pt-3">
                <div className="form-group has-feedback col-sm-12">
                    <label style={{fontSize: '16px'}}>
                        Choose an option:
                    </label>
                    <div className="d-flex flex-column justify-content-center mt-2">
                        <Field
                            type="radio"
                            name="type"
                            label={`Click here if the Job was completed. ${agentName} will be paid and you will be able to submit a review.`}
                            value='completed'
                            component={renderFieldRadio}
                        />
                        <Field
                            type="radio"
                            name="type"
                            label="Click here if the job had not been completed. Job will move to canceled status, no charge to your account."
                            value="canceled"
                            component={renderFieldRadio}
                        />
                    </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center mx-auto mt-3">
                <div className="col-sm-12 col-md-3 col-lg-4 d-flex justify-content-center align-items-center">
                    <button type="submit" className="btn btn-primary">
                        Confirm
                    </button>
                </div>
            </div>
        </form>
    );
}

let NoShowCompleteJobForm = reduxForm({
    form: "noShowCompleteJobForm", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector("noShowCompleteJobForm");

NoShowCompleteJobForm = connect((state) => {
    return {
    };
})(NoShowCompleteJobForm);

export default NoShowCompleteJobForm;
