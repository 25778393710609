import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import UpdateReviewForm from "./AgentReviewForm"

export class AgentReviewEdit extends Component {

    state = {
        agent_id: null
    }

    componentDidMount() {
        const { match, read } = this.props;
        if (match.params) {
            const {id, agent_id} = match.params;

            this.setState({agent_id: agent_id})
            if (id && agent_id) {
                read(id, agent_id);
            }
        }
    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    submit = (data) => {
        const {edit} = this.props;
        edit(data, this.goBack)
    }

    render() {
        //  State
        const {loader, item} = this.props;

        return (
            <div className="d-flex flex-column flex-1">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            Update Review
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <UpdateReviewForm
                            loader={loader}
                            item={item}
                            onSubmit={this.submit}
                            goBack={this.goBack}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default AgentReviewEdit;
