import React, {useState} from "react";
import { Field, reduxForm } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    renderField,
    renderTextArea
} from "../../Utils/renderField";

const VendorTextForm = (props) => {
    const {
        handleSubmit,
        loader,
        goBack,
    } = props;

    return (
        <div
            className="card flex-1 border-dark border-card-page px-4 py-4"
            style={{
                minWidth: "99%",
            }}
        >
            <form
                name="brokerageForm"
                className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
                onSubmit={handleSubmit}
            >
                <LoadMask loading={loader} light>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="add_vendor_profession_title">Add Title</label>
                            <Field
                                name="add_vendor_profession_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="add_vendor_profession_description">Add Description</label>
                            <Field
                                name="add_vendor_profession_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="remove_vendor_profession_title">Remove Title</label>
                            <Field
                                name="remove_vendor_profession_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="remove_vendor_profession_description">Remove Description</label>
                            <Field
                                name="remove_vendor_profession_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_has_not_expertise_title">Vendor has no Expertise Title</label>
                            <Field
                                name="vendor_has_not_expertise_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_has_not_expertise_description">Vendor has no Expertise Description</label>
                            <Field
                                name="vendor_has_not_expertise_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_move_to_history_description">Description for Hiring Agent when to move to My History</label>
                            <Field
                                name="vendor_move_to_history_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_agent_move_to_history_description">Description for Vendor when to move to My History</label>
                            <Field
                                name="vendor_agent_move_to_history_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_appointment_follow_up_description">Description when confirm appointment / follow up</label>
                            <Field
                                name="vendor_appointment_follow_up_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_post_job_now_title">Post vendor job title (now)</label>
                            <Field
                                name="vendor_post_job_now_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_post_job_now_description">Post vendor job description (now)</label>
                            <Field
                                name="vendor_post_job_now_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_post_job_custom_title">Post vendor job title (custom)</label>
                            <Field
                                name="vendor_post_job_custom_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="vendor_post_job_custom_description">Post vendor job description (custom)</label>
                            <Field
                                name="vendor_post_job_custom_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="w-100 my-4 mx-auto text-center">
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={goBack}
                        >
                            Back
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                            disabled={loader}
                        >
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        </div>
    )
}

export default reduxForm({
    form: "vendorTextForm",
})(VendorTextForm);
