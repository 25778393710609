import React from "react";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderSearchField, renderNumber, SelectField, MultiSelectField } from "../Utils/renderField";
import { validate, validators, combine , validatorFromFunction} from "validate-redux-form";
import {
    STATE_USA_LIST,
    CATEGORY_REGISTERED_LIST,
    CATEGORY_UNREGISTERED_LIST,
    SELECT_ALL_OPTION,
    BROKER_OPERATIONAL_MODE_LIST,
} from '../../../utility/constants'

let AgentFiltersForm = (props) => {
    //  State
    const {
        optionStates=[],
        optionOfficeStates=[],
        optionCities=[],
        optionOfficeCities=[],
        optionFranchises=[],
        optionBrokers=[],
        optionBrokerages=[],
        optionTeams=[],
        optionInfluencers=[],
        typeState='registered',
        brokerageValue,
    } = props;

    //  Bind
    const {changeFilters} = props;

    return (
        <div className="row">
            {/* <div className={`${typeState == 'registered' ? 'col-xl-2 col-lg-4' : 'col-xl-2 col-lg-4'} col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}> */}
            <div className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}>
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search Agent'}
                        component={renderSearchField}
                        onChange={(e) => {
                            changeFilters(
                                "search",
                                e.target && e.target.value ? e.target.value : ""
                            )
                        }}
                        type="text"
                    />
                </div>

            </div>
            {typeState == 'registered' && (
                <React.Fragment>
                    <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="state_registered">State</label>
                            <Field
                                name="state_registered"
                                options={optionStates}
                                set_value={(value) => {
                                    changeFilters(
                                        "state_registered",
                                        value
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="city_registered">City</label>
                            <Field
                                name="city_registered"
                                options={optionCities}
                                set_value={(value) => {
                                    changeFilters(
                                        "city_registered",
                                        value
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
            {typeState == 'unregistered' && (
                <React.Fragment>
                    <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="state">State</label>
                            <Field
                                name="state"
                                options={optionOfficeStates}
                                set_value={(value) => {
                                    changeFilters(
                                        "state",
                                        value
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="city">City</label>
                            <Field
                                name="city"
                                options={optionOfficeCities}
                                set_value={(value) => {
                                    changeFilters(
                                        "city",
                                        value
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="franchise">Franchise</label>
                    <Field
                        name="franchise"
                        options={optionFranchises}
                        set_value={(value) => {
                            changeFilters(
                                "franchise",
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="broker">Broker</label>
                    <Field
                        name="broker"
                        options={optionBrokers}
                        set_value={(value) => {
                            changeFilters(
                                "broker",
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="brokerage">Office</label>
                    <Field
                        name="brokerage"
                        options={optionBrokerages}
                        set_value={(value) => {
                            changeFilters(
                                "brokerage",
                                value
                            )

                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            {typeState == 'registered'  && (
                <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="registered_status">Status</label>
                        <Field
                            name="registered_status"
                            options={CATEGORY_REGISTERED_LIST}
                            set_value={(value) => {
                                changeFilters(
                                    "registered_status",
                                    value
                                )
                            }}
                            component={MultiSelectField}
                            className="form-control"
                        />
                    </div>
                </div>
            )}
            {typeState == 'unregistered'  && (
                <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="unregistered_status">Status</label>
                        <Field
                            name="unregistered_status"
                            options={CATEGORY_UNREGISTERED_LIST}
                            set_value={(value) => {
                                changeFilters(
                                    "unregistered_status",
                                    value
                                )
                            }}
                            component={MultiSelectField}
                            className="form-control"
                        />
                    </div>
                </div>
            )}
            {typeState == 'registered'  && (
                <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="operation_access">Operational Mode</label>
                        <Field
                            name="operation_access"
                            options={BROKER_OPERATIONAL_MODE_LIST}
                            set_value={(value) => {
                                changeFilters(
                                    "operation_access",
                                    value
                                )
                            }}
                            component={MultiSelectField}
                            className="form-control"
                        />
                    </div>
                </div>
            )}
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="team">Team</label>
                    <Field
                        name="team"
                        options={optionTeams}
                        set_value={(value) => {
                            changeFilters(
                                "team",
                                value
                            )

                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="influencer">Influencer</label>
                    <Field
                        name="influencer"
                        options={optionInfluencers}
                        set_value={(value) => {
                            changeFilters(
                                "influencer",
                                value
                            )

                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            {typeState == 'unregistered'  && (
                <div className={`col-xl-2 col-lg-2 col-sm-12 col-md-12`}>
                </div>
            )}
                
        </div> 
    );
};

AgentFiltersForm = reduxForm({
    form: 'AgentFiltersForm', // a unique identifier for this form
    initialValues: {state: SELECT_ALL_OPTION["value"], registered_status: SELECT_ALL_OPTION["value"], unregistered_status: SELECT_ALL_OPTION["value"], operation_access: SELECT_ALL_OPTION["value"]},
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AgentFiltersForm);

const selector = formValueSelector('AgentFiltersForm');

const mstp = state => {
    const brokerageValue = selector(state, 'brokerage');

    return {
        brokerageValue
    }
}

export default connect(mstp, null)(AgentFiltersForm);
// export default AgentFiltersForm;
