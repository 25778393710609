import { api } from "api";
import Swal from 'sweetalert2';
import { push } from "react-router-redux";
import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { change, initialize as initializeForm } from 'redux-form';


// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "wholesaler";
const TEAMS = 'TEAMS_WHOLESALE';

export let { reducers, initialState, actions } = createReducer(
    "wholesalers",
    endpoint,
    "wholeSalerForm",
    "wholesalers",
);

// -----------------------------------
// Pure Actions
// -----------------------------------
const setTeams = (teams) => ({
    type: TEAMS,
    teams,
});

// -----------------------------------
// Actions
// -----------------------------------

actions.getTeams = () => (dispatch) => {
    api.get(`general/option_team`, {})
        .then((response) => {
            if (response) {
                const results = [{ label: '', value: null }, ...response.results]
                dispatch(setTeams(results))
            } else {
                dispatch(setTeams([]))
            }
        }).catch(error => {
            dispatch(setTeams([]))
        })
}

actions.edit = (id, data, attachments=[]) =>  (dispatch) => {
    dispatch(actions.setLoader(true));
    api.putAttachments(`${endpoint}/${id}`, data, attachments).then(() => {
        NotificationManager.success('WholeSaler updated', 'Success', 3000);
        dispatch(push("/wholesalers"));
    }).catch(() => {
        NotificationManager.error('Edition failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};

actions.blockUserTypeWholeSale = (id) => (dispatch, getStore) => {
    const page = getStore()['wholesalers'].page;
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}/to_block`)
        .then(response => {
            NotificationManager.success('Block WholeSaler successfully', 'SUCCESS', 2000);
            dispatch(actions.toList(page));
        })
        .catch((error) => {
            NotificationManager.error('Block WholeSaler failed, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(actions.setLoader(false));
        });
};

actions.unblockUserTypeWholeSale = (id) => (dispatch, getStore) => {
    const page = getStore()['wholesalers'].page;
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}/unblock`)
        .then(response => {
            NotificationManager.success('Unblock WholeSaler successfully', 'SUCCESS', 2000);
            dispatch(actions.toList(page));
        })
        .catch((error) => {
            NotificationManager.error('Failed Unblock WholeSaler, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(actions.setLoader(false));
        });
};

actions.goPage = (url) => (dispatch) => {
    dispatch(push(url));
};

actions.activateWholeSaler = (id, data) => (dispatch, getStore) => {
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}/activate`, data)
        .then(response => {
            NotificationManager.success('WholeSaler activated', 'Success', 3000);
            dispatch(actions.read(id))
        })
        .catch((error) => {
            NotificationManager.error('Failed Activate WholeSaler, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(actions.setLoader(false));
        });
}

actions.removeWholeSaler = id => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(push('/wholesalers'));
        NotificationManager.success('WholeSaler removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};
actions.update = (data = {}, attachments = []) => (dispatch, getStore) => {
    dispatch(actions.setLoader(true));
    api.putAttachments(`${endpoint}/${data.id}`, data, attachments).then((response) => {
        NotificationManager.success('WholeSaler updated', 'SUCCESS', 1000);
        dispatch(actions.read(data.id))
    }).catch(() => {
        NotificationManager.error('Error, please try again', 'ERROR', 1000);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
}

actions.editWholeSaler = (id, data) => (dispatch, getStore) => {
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('WholeSaler Updated', 'Success', 3000);
        dispatch(actions.read(id))
    }).catch(() => {
        NotificationManager.error('Update failed', 'ERROR', 3000);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};

// -----------------------------------
// Reducers
// -----------------------------------
reducers = {
    ...reducers,
    [TEAMS]: (state, { teams }) => {
        return {
            ...state,
            teams,
        };
    },
};

initialState = {
    ...initialState,
    teams: [],
};

export default handleActions(reducers, initialState);
