import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/brokerage/brokerage'
import Brokerage from "./Brokerage";

const ms2p = (state) => {
    return{
        ...state.brokerage,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Brokerage);

