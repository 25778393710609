import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import DeleteBrokerReinstateForm from "./DeleteBrokerReinstateForm"

class DeletedBrokerReinstate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null
        }
    }

    componentDidMount() {
        const {match} = this.props;
        if (match.params.id) {
            const id = match.params.id;
            // this.setState({id: id})
            this.props.read(id)
            this.props.getFranchisesOption();
        }
    }

    goBack = () => {
        const {history} = this.props
        history.goBack()
    }

    submit = (data) => {
        const {reinstateBroker} = this.props;
        reinstateBroker(data.id, data, this.goBack)
    }

    render() {
        //  State
        const {
            loader,
            item,
            franchises,
        } = this.props
        //  Bind

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={this.goBack}>
                                <i className="material-icons pointer">arrow_back</i> 
                            </button>
                            Reinstate Broker
                        </div>
                    </div>
                </div>
                <div
                    className="general-container card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <DeleteBrokerReinstateForm
                            item={item}
                            franchises={franchises}
                            goBack={this.goBack}
                            onSubmit={this.submit}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default DeletedBrokerReinstate;