import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/agentsJobs";
import MapAgentsJob from "./MapAgentsJob";

const ms2p = (state) => {
    return {
        ...state.agentsJobs,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(MapAgentsJob);