import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../../Utils/renderField';
import { minLength } from "../../../../../utility/validation";

const RegisterPassword = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="accountPassword" className="row w-75 justify-content-center m-auto align-content-center"
              onSubmit={handleSubmit}>
            <div className="row w-100 justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="password">Create your account password</label>
                    <Field
                        name="password"
                        label="Password"
                        placeholder="Minimum 6 characters"
                        component={renderField}
                        type="password"
                        customClass="form-control-gray"
                        className="form-control"
                        validate={[minLength(6)]}
                    />
                </div>
            </div>
            <div className="row w-100 justify-content-center">
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="confirmPassword">Confirmed your account password*</label>
                    <Field
                        name="confirmPassword" label="confirm Password"
                        component={renderField}
                        type="password"
                        customClass="form-control-gray"
                        className="form-control"/>
                </div>
            </div>

            <div className="buttons-box w-100 justify-content-center px-5 pt-5">
                <button type="submit" className="btn button-01 w-50 align-self-center">Confirm registration</button>
            </div>
        </form>
    );
};

export const matchPassword = (passe, confirm) => validatorFromFunction(() => {
    return passe === confirm;
});

export default reduxForm({
    form: 'accountPassword', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()('This field is required'),
                matchPassword(data.password, data.confirmPassword)()('The account password don\'t match'),
            ),
            password: validators.exists()('This field is required'),
        });
    },
})(RegisterPassword);
