import { api } from "api";
import _ from "lodash";
import { change, initialize } from "redux-form";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { SELECT_ALL_OPTION } from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const AGENTS = "AGENTS_AGENTSJOBS";
const SET_FRANCHISES = "SET_FRANCHISES_AGENTSJOBS";
const SET_BROKERS = "SET_BROKERS_AGENTSJOBS";
const SET_BROKERAGES = "SET_BROKERAGES_AGENTSJOBS";
const SET_JOB_TYPE = "SET_JOB_TYPE_AGENTSJOBS";
const SET_DATA_REVENUE_AGENTS = "SET_DATA_REVENUE_AGENTS_AGENTSJOBS";

const baseReducer = createReducer(
    "agentsJobs",
    "map_agent_job",
    "agentsJobFrom",
    "/agents-jobs"
);

// ------------------------------------
// Pure Actions
// ------------------------------------

const setAgentsData = (agents) => ({
    type: AGENTS,
    agents,
});

const setOptionsFranchises = (franchises) => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});

const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});
const setJobTypes = (jobTypes) => ({
    type: SET_JOB_TYPE,
    jobTypes,
});
const setDataRevenueAgents = (dataRevenueAgents) => ({
    type: SET_DATA_REVENUE_AGENTS,
    dataRevenueAgents,
});

// ------------------------------------
// Actions
// ------------------------------------
export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            // case 'state':
            //     setTimeout(() => {
            //         dispatch(toList2())
            //         dispatch(toList2Roster())
            //         dispatch(getStats())
            //     }, 500)
            //     break;
            case "franchise":
                setTimeout(() => {
                    dispatch(getBrokerOption(true));
                }, 500);
                break;
            case "broker":
                setTimeout(() => {
                    dispatch(getBrokeragesOption(true));
                }, 500);
                break;
            case "brokerage":
                setTimeout(() => {
                    dispatch(toList());
                    dispatch(getAgentsData());
                    dispatch(getDataRevenueAgents());
                }, 500);
                break;
            case "job_type":
                setTimeout(() => {
                    dispatch(toList());
                    dispatch(getAgentsData());
                }, 500);
                break;
            case "job_status":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "agent_category":
                setTimeout(() => {
                    dispatch(toList());
                    dispatch(getAgentsData());
                }, 500);
                break;
            case "operational_mode":
                setTimeout(() => {
                    dispatch(toList());
                    dispatch(getAgentsData());
                }, 500);
                break;
            default:
                break;
        }
    };

export const getFranchisesOption =
    (getData = null, selectAll = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { agentsJobsForm } = store.form;
        let franchiseValue = null;
        if (agentsJobsForm && agentsJobsForm.values) {
            const {franchise} = agentsJobsForm.values;
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                if (franchise.length == 0) {
                    franchiseValue = SELECT_ALL_OPTION["value"]
                }else {
                    franchiseValue = franchise
                }
            }
        }
        api.get("general/option_franchise", { })
            .then((response) => {
                let options = response.results;
                if (response.results.length > 1) {
                    // let value = [];
                    // response.results.map((franchise) => {
                    //     options.push({
                    //         label: franchise.name,
                    //         value: franchise.id,
                    //     });
                    //     value.push(franchise.id);
                    // });
                    if (selectAll && franchiseValue == null) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "franchise",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }

                    if (franchiseValue !== null) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "franchise",
                                franchiseValue
                            )
                        );
                    }
                } else {
                    // response.results.map((franchise) => {
                    //     options.push({
                    //         label: franchise.name,
                    //         value: franchise.id,
                    //     });
                    // });
                    const franchise_id = options.length ? options[0].value : "";
                    dispatch(
                        change("agentsJobsForm", "franchise", [franchise_id])
                    );
                    setTimeout(() => {
                        dispatch(getBrokerOption());
                    }, 500);
                }
                dispatch(setOptionsFranchises(options));
            })
            .catch(() => {
                dispatch(setOptionsFranchises([]));
                dispatch(change("agentsJobsForm", "franchise", ""));
                setTimeout(() => {
                    dispatch(getBrokerOption());
                }, 500);
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                        dispatch(getAgentsData());
                        dispatch(getDataRevenueAgents());
                    }, 500);
                }
            });
    };

export const getBrokerOption =
    (getData = null, selectAll = false, brokerId = null, brokerageId = null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { agentsJobsForm } = store.form;
        let params = { state: "", franchise: "", is_multi: true };
        let brokerValue = null
        if (agentsJobsForm && agentsJobsForm.values) {
            const { franchise, broker } = agentsJobsForm.values;
            if (
                franchise !== "" &&
                franchise !== null &&
                franchise !== undefined &&
                franchise !== SELECT_ALL_OPTION["value"]
            ) {
                if (franchise.length == 0) {
                    params.franchise = "";
                } else {
                    params.franchise = franchise;
                }
            }

            if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                if (broker.length == 0) {
                    brokerValue = SELECT_ALL_OPTION["value"]
                }else {
                    brokerValue = broker
                }
            }
        }

        api.get(`general/option_broker`, params)
            .then((response) => {
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll && brokerId == null && brokerValue == null) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "broker",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                    if (brokerValue !== null && brokerId == nul) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "broker",
                                brokerValue
                            )
                        );
                    }
                    if (brokerId !== null) {
                        dispatch(
                            change("agentsJobsForm", "broker", [brokerId])
                        );
                    }
                    setTimeout(() => {
                        if (brokerId !== null) {
                            dispatch(
                                getBrokeragesOption(getData, false, brokerageId)
                            );
                        } else {
                            dispatch(
                                getBrokeragesOption(
                                    getData,
                                    selectAll,
                                    brokerageId
                                )
                            );
                        }
                    }, 500);
                } else {
                    option = [...response.results];
                    console.log("OPT: ", option);
                    const broker_id = option.length ? option[0].value : "";
                    dispatch(change("agentsJobsForm", "broker", [broker_id]));
                    setTimeout(() => {
                        dispatch(
                            getBrokeragesOption(getData, selectAll, brokerageId)
                        );
                    }, 500);
                }
                dispatch(setBrokers(option));
            })
            .catch(() => {
                dispatch(setBrokers([]));
                dispatch(change("agentsJobsForm", "broker", ""));
                setTimeout(() => {
                    dispatch(
                        getBrokeragesOption(getData, selectAll, brokerageId)
                    );
                }, 500);
            })
            .finally(() => {});
    };

export const getBrokeragesOption =
    (getData = null, selectAll = false, brokerageId = null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { agentsJobsForm } = store.form;
        let params = {
            broker_id: "",
            state: "",
            franchise: "",
            is_multi: true,
        };
        let brokerageValue = null;
        if (agentsJobsForm && agentsJobsForm.values) {
            const { franchise, broker, brokerage } = agentsJobsForm.values;
            if (
                broker !== "" &&
                broker !== null &&
                broker !== undefined &&
                broker !== SELECT_ALL_OPTION["value"]
            ) {
                // params.broker_id = broker;
                if (broker.length == 0) {
                    params.broker_id = "";
                } else {
                    params.broker_id = broker;
                }
            }
            if (
                franchise !== "" &&
                franchise !== null &&
                franchise !== undefined &&
                franchise !== SELECT_ALL_OPTION["value"]
            ) {
                if (franchise.length == 0) {
                    params.franchise = "";
                } else {
                    params.franchise = franchise;
                }
            }

            if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
                if (brokerage.length == 0) {
                    brokerageValue = SELECT_ALL_OPTION["value"]
                }else {
                    brokerageValue = brokerage
                }
            }
        }

        api.get(`general/option_brokerage`, params)
            .then((response) => {
                let option = [];
                console.log("RESP BROKERAGE: ", response);
                if (response.results.length > 1) {
                    option = response.results;
                    if (brokerageId !== null) {
                        dispatch(
                            change("agentsJobsForm", "brokerage", [brokerageId])
                        );
                    }
                    if (selectAll && brokerageId == null && brokerageValue == null) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                    if (brokerageValue !== null && brokerageId == null) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "brokerage",
                                brokerageValue
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    const brokerage_id = option.length ? option[0].value : "";
                    dispatch(
                        change("agentsJobsForm", "brokerage", [brokerage_id])
                    );
                }
                dispatch(setBrokerages(option));
                console.log("OPTIONS BROKERAGE: ", option);
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(change("agentsJobsForm", "brokerage", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                        dispatch(getAgentsData());
                        dispatch(getDataRevenueAgents());
                    }, 500);
                }
            });
    };

export const getJobTypesOption =
    (selectAll = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { agentsJobsForm } = store.form;
        let jobTypeValue = null;
        if (agentsJobsForm && agentsJobsForm.values) {
            const {job_type} = agentsJobsForm.values;
            if (job_type !== "" && job_type !== null && job_type !== undefined && job_type !== SELECT_ALL_OPTION["value"]) {
                if (job_type.length == 0) {
                    jobTypeValue = SELECT_ALL_OPTION["value"]
                }else {
                    jobTypeValue = job_type
                }
            }
        }
        api.get("general/option_job_types", {})
            .then((response) => {
                let options = [];
                if (response.results.length > 1) {
                    options = [...response.results];
                    if (selectAll && jobTypeValue == null) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "job_type",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                    if (jobTypeValue !== null) {
                        dispatch(
                            change(
                                "agentsJobsForm",
                                "job_type",
                                jobTypeValue
                            )
                        );
                    }
                } else {
                    options = [...response.results];
                    const job_type = options.length ? options[0].value : "";
                    dispatch(change("agentsJobsForm", "job_type", [job_type]));
                    setTimeout(() => {
                        dispatch(getBrokerOption());
                    }, 500);
                }
                dispatch(setJobTypes(options));
            })
            .catch(() => {
                dispatch(setJobTypes([]));
                dispatch(change("agentsJobsForm", "job_type", ""));
            })
            .finally(() => {});
    };

const toList = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(baseReducer.actions.setLoader(true));
    }
    const store = getStore();
    const resource = store["agentsJobs"];
    const params = {};
    const { agentsJobsForm } = store.form;
    if (agentsJobsForm && agentsJobsForm.values) {
        const {
            state,
            franchise,
            broker,
            brokerage,
            job_type,
            job_status,
            operational_mode,
        } = agentsJobsForm.values;
        if (
            franchise !== "" &&
            franchise !== null &&
            franchise !== undefined &&
            franchise !== SELECT_ALL_OPTION["value"]
        ) {
            // params.franchise = franchise;
            if (franchise.length == 0) {
                params.franchise = 0;
            } else {
                params.franchise = franchise;
            }
        }
        if (
            broker !== "" &&
            broker !== null &&
            broker !== undefined &&
            broker !== SELECT_ALL_OPTION["value"]
        ) {
            // params.broker = broker;
            if (broker.length == 0) {
                params.broker = 0;
            } else {
                params.broker = broker;
            }
        }
        if (
            brokerage !== "" &&
            brokerage !== null &&
            brokerage !== undefined &&
            brokerage !== SELECT_ALL_OPTION["value"]
        ) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0;
            } else {
                params.brokerage = brokerage;
            }
        }
        if (
            operational_mode !== "" &&
            operational_mode !== null &&
            operational_mode !== undefined &&
            operational_mode !== SELECT_ALL_OPTION["value"]
        ) {
            // params.operational_mode = operational_mode;
            if (operational_mode.length == 0) {
                params.operational_mode = 0;
            } else {
                params.operational_mode = operational_mode;
            }
        }
        if (
            job_type !== "" &&
            job_type !== null &&
            job_type !== undefined &&
            job_type !== SELECT_ALL_OPTION["value"]
        ) {
            if (job_type.length == 0) {
                params.job_type = 0;
                params.job_type_option = 0;
            } else {
                const _jobTypes = _.filter(job_type, function (o) {
                    return !o.toString().toUpperCase().includes("OTH");
                });
                params.job_type = _jobTypes.length ? _jobTypes : 0;
                const _jobTypeOptions = _.filter(job_type, function (o) {
                    if (o.toString().toUpperCase().includes("OTH")) {
                        return true;
                    }
                    return false;
                });
                params.job_type_option = _jobTypeOptions.length
                    ? _jobTypeOptions.map((item) => {
                          return parseInt(item.toString().replace("OTH-", ""));
                      })
                    : 0;
            }
        }
        if (
            job_status !== "" &&
            job_status !== null &&
            job_status !== undefined &&
            job_status !== SELECT_ALL_OPTION["value"]
        ) {
            if (job_status.length == 0) {
                params.job_status = 0;
            } else {
                params.job_status = job_status;
            }
        }
    }
    api.get("map_agent_job", params)
        .then((response) => {
            dispatch(baseReducer.actions.setData(response));
        })
        .catch(() => {})
        .finally(() => {
            if (loader) {
                dispatch(baseReducer.actions.setLoader(false));
            }
        });
};

const getAgentsData = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(baseReducer.actions.setLoader(true));
    }
    const store = getStore();
    let params = {};
    const { agentsJobsForm } = store.form;
    if (agentsJobsForm && agentsJobsForm.values) {
        const {
            state,
            franchise,
            broker,
            brokerage,
            agent_category,
            operational_mode,
        } = agentsJobsForm.values;
        // if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
        //     if (state.length == 0) {
        //         params.state = "null"
        //     }else {
        //         params.state = state;
        //     }
        // }
        if (
            franchise !== "" &&
            franchise !== null &&
            franchise !== undefined &&
            franchise !== SELECT_ALL_OPTION["value"]
        ) {
            // params.franchise = franchise;
            if (franchise.length == 0) {
                params.franchise = 0;
            } else {
                params.franchise = franchise;
            }
        }
        if (
            broker !== "" &&
            broker !== null &&
            broker !== undefined &&
            broker !== SELECT_ALL_OPTION["value"]
        ) {
            // params.broker = broker;
            if (broker.length == 0) {
                params.broker = 0;
            } else {
                params.broker = broker;
            }
        }
        if (
            brokerage !== "" &&
            brokerage !== null &&
            brokerage !== undefined &&
            brokerage !== SELECT_ALL_OPTION["value"]
        ) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0;
            } else {
                params.brokerage = brokerage;
            }
        }
        if (
            operational_mode !== "" &&
            operational_mode !== null &&
            operational_mode !== undefined &&
            operational_mode !== SELECT_ALL_OPTION["value"]
        ) {
            // params.operational_mode = operational_mode;
            if (operational_mode.length == 0) {
                params.operational_mode = 0;
            } else {
                params.operational_mode = operational_mode;
            }
        }
        if (
            agent_category !== "" &&
            agent_category !== null &&
            agent_category !== undefined &&
            agent_category !== SELECT_ALL_OPTION["value"]
        ) {
            if (agent_category.length == 0) {
                params.agent_category = "null";
            } else {
                params.agent_category = agent_category;
            }
        }
    }
    api.get(`map_agent_job/agents`, params)
        .then((response) => {
            let markers = [];
            const results =
                response && response.results ? response.results : [];

            results.forEach((agent, index) => {
                let working_area = agent ? agent.working_area : null;
                if (working_area) {
                    let _center =
                        working_area.latitude && working_area.longitude
                            ? {
                                  lat: parseFloat(working_area.latitude.toFixed(7)),
                                  lng: parseFloat(working_area.longitude.toFixed(7)),
                              }
                            : null;
                    if (_center) {
                        _center = getCenter(_center.lat, _center.lng, markers);
                        markers.push({
                            ..._center,
                            ...agent,
                            name: `${agent.first_name} ${agent.last_name}`,
                        });
                    }
                }
            });

            dispatch(
                setAgentsData({
                    results: markers,
                    count: response && response.count ? response.count : 0,
                })
            );
        })
        .catch(() => {
            dispatch(
                setAgentsData({
                    results: [],
                    count: 0,
                })
            );
        })
        .finally(() => {
            if (loader) {
                dispatch(baseReducer.actions.setLoader(false));
            }
        });
};

const getCenter = (lat, lng, _markers) => {
    const center = { lat, lng };
    const similarCoords = _.find(_markers, { lat, lng });
    if (similarCoords) {
        let R = 6378137;
        let dn = 3;
        let de = 0;
        let dLat = dn / R;
        let dLon = de / (R * Math.cos((Math.PI * lat) / 180));
        const _center = {
            lat: lat + dLat * (180 / Math.PI),
            lng: lng + dLon * (180 / Math.PI),
        };
        return getCenter(_center.lat, _center.lng, _markers);
    }
    return center;
};

const getDataRevenueAgents = () => (dispatch, getStore) => {
    const store = getStore();
    const { agentsJobsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
    };
    if (agentsJobsForm && agentsJobsForm.values) {
        const { state, franchise, broker, brokerage, category, time } =
            agentsJobsForm.values;
        if (
            franchise !== "" &&
            franchise !== null &&
            franchise !== undefined &&
            franchise !== SELECT_ALL_OPTION["value"]
        ) {
            params.franchise = franchise;
        }
        if (
            broker !== "" &&
            broker !== null &&
            broker !== undefined &&
            broker !== SELECT_ALL_OPTION["value"]
        ) {
            params.broker = broker;
        }
        if (
            brokerage !== "" &&
            brokerage !== null &&
            brokerage !== undefined &&
            brokerage !== SELECT_ALL_OPTION["value"]
        ) {
            params.brokerage = brokerage;
        }
    }

    api.get("dashboard/revenue", params)
        .then((response) => {
            if (response) {
                dispatch(setDataRevenueAgents(response.results));
            } else {
                dispatch(
                    setDataRevenueAgents({
                        total_agent: 0,
                        total_user: 0,
                        posted: {},
                        applied: {},
                        pending: {},
                        total_to_revenue: {},
                    })
                );
            }
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(
                setDataRevenueAgents({
                    total_agent: 0,
                    total_user: 0,
                    posted: {},
                    applied: {},
                    pending: {},
                    total_to_revenue: {},
                })
            );
        })
        .finally(() => {});
};

export const actions = {
    ...baseReducer.actions,
    toList,
    getAgentsData,
    changeFilters,
    getFranchisesOption,
    getBrokerOption,
    getBrokeragesOption,
    getJobTypesOption,
    getDataRevenueAgents,
};

export const reducers = {
    ...baseReducer.reducers,
    [AGENTS]: (state, { agents }) => {
        return {
            ...state,
            agents,
        };
    },
    [SET_FRANCHISES]: (state, { franchises }) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, { brokers }) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, { brokerages }) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_JOB_TYPE]: (state, { jobTypes }) => {
        return {
            ...state,
            jobTypes,
        };
    },
    [SET_DATA_REVENUE_AGENTS]: (state, { dataRevenueAgents }) => {
        return {
            ...state,
            dataRevenueAgents,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    ...baseReducer.initialState,
    agents: {
        results: [],
        count: 0,
    },
    brokers: [],
    brokerages: [],
    franchises: [],
    jobTypes: [],
    dataRevenueAgents: {
        total_agent: 0,
        total_user: 0,
        posted: {},
        applied: {},
        pending: {},
        total_to_revenue: {},
    },
};

export default handleActions(reducers, initialState);
