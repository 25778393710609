import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/cuenta/register';
import CreateAdmin from "./CreateAdmin";
import {formValueSelector} from "redux-form";
const selector = formValueSelector('adminForm');

const ms2p = (state) => {
    return{
        ...state.register,
        updatePass: selector(state, 'showField'),
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateAdmin);
