import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { formValueSelector } from "redux-form";
import Tabs, {TabPane, TabContent} from 'rc-tabs';
import {formatPhoneNumber} from "../../Brokerage/BrokerageGrid";
import FileUploader from "../../Utils/FileUploader/FileUploader";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import GridTableWithScroll from "../../Utils/GridTableWithScroll";
import {formatName, formatText, brokerName} from "../../Agent/AgentUnregistered/AgentUnregisteredGrid";
import {
    renderField,
    renderTextArea,
    SelectField,
    MultiSelectField
} from "../../Utils/renderField";
import {
    DEFAULT_STATUS_CAMPAIGN_UNREGISTERED,
    STATE_USA_LIST,
    AGENT_UNREGISTERED_PAGE_SIZE,
    SELECT_ALL_OPTION,
} from "../../../../utility/constants";

const validate = (values, props) => {
    const errors = {}

    if (!values.title) {
        errors.title = "This field is required";
    }

    if (!values.text_message) {
        errors.text_message = "This field is required";
    }

    // if (!props.image) {
    //     props.setImageRequired();
    // }

    return errors
}

const CampaignForm = (props) => {
    //  State
    const {
        is_view,
        is_update,
        franchises,
        brokers,
        brokerages,
        page,
        loader,
        data,
        image,
        image_error=null,
        agents_error,
        anyTouched,
        item,
        typeValue,
        agent_statusValue,
        loaderAgentsData,
        unregisteredAgentsPage,
        unregisteredAgentsData,
    } = props;
    const itemImg = ((is_update || is_view) && item && item.image && typeValue == 'mms') ? item.image : null;
    const showSendBtn = is_view;
    const TYPES = [{label: 'SMS', value: 'sms'}, {label: 'MMS', value: 'mms'}];

    //  Bind
    const {handleSubmit, changeFilters, onFileChange, getDataAgents, sendMessage} = props;

    const [tab, setTab] = useState('Registered')

    console.log("TYPE: ", typeValue);

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="row">
                        <div className="mt-3 col-lg-12 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="title">Title</label>
                                <Field
                                    name="title"
                                    label="Title"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-12 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="text_message">SMS</label>
                                <Field
                                    name="text_message"
                                    label="Title"
                                    component={renderTextArea}
                                    type="text"
                                    customClass="form-control text-area-h-auto"
                                    disabled={is_view}
                                    rows={6}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-8 col-sm-12 mb-0">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <label htmlFor="agent_status">Type</label>
                            <Field
                                name="type"
                                isMulti={false}
                                options={TYPES}
                                set_value={(option) => {
                                    console.log(option);
                                }}
                                component={SelectField}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        {
                            typeValue == 'mms' && (
                                <div className="content-drop-zone col-lg-7 col-sm-12">
                                    <label htmlFor="photo">Images</label>
                                    <FileUploader
                                        onFileChange={onFileChange}
                                        fieldName={"campaign_photo"}
                                        width="100%"
                                        dashed={true}
                                        img={image ? null : itemImg}
                                        disabled={is_view || typeValue == 'sms'}
                                    />
                                    {image_error && anyTouched && (
                                        <div className="invalid-feedback d-block">
                                            {image_error}
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    </div>
                    <div className="row">
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="agent_status">Status</label>
                                <Field
                                    name="agent_status"
                                    options={DEFAULT_STATUS_CAMPAIGN_UNREGISTERED}
                                    set_value={() => changeFilters("status")}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="state">State</label>
                                <Field
                                    name="state"
                                    options={STATE_USA_LIST}
                                    set_value={(option) =>
                                        changeFilters(
                                            "state",
                                            option ? option.value : ""
                                        )
                                    }
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="franchise_id">Franchise</label>
                                <Field
                                    name="franchise_id"
                                    options={franchises ? franchises : []}
                                    set_value={(option) => {
                                        console.log("OPTION: ", option);
                                        changeFilters(
                                            "franchise",
                                            option ? option.value : ""
                                        );
                                    }}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="broker_id">Broker</label>
                                <Field
                                    name="broker_id"
                                    options={brokers ? brokers : []}
                                    set_value={(option) => {
                                        console.log("OPTION: ", option);
                                        changeFilters(
                                            "broker",
                                            option ? option.value : ""
                                        );
                                    }}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="brokerage_id">Office</label>
                                <Field
                                    name="brokerage_id"
                                    options={brokerages ? brokerages : []}
                                    set_value={() => changeFilters("brokerage")}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`row mx-auto mt-3 col-lg-5 col-sm-12 justify-content-between mt-4 mb-5`}
            >
                <a
                    className={`btn btn-danger text-white col-lg-5 col-sm-12`}
                    href="/#/campaigns"
                >
                    {" "}
                    Cancel
                </a>
                {!is_view && (
                    <button
                        className={`btn btn-success col-lg-5 col-sm-12`}
                        disabled={loader}
                    >
                        {is_update ? "update" : "create"}
                    </button>
                )}
                {showSendBtn && sendMessage && (
                    <button
                        onClick={sendMessage}
                        type="button"
                        className="btn btn-secondary col-lg-5 col-sm-12"
                        disabled={loader}
                    >
                        Send Message
                    </button>
                )}
            </div>
            {(agent_statusValue != SELECT_ALL_OPTION["value"]) && (
                <div className="row w-75 px-lg-5 mx-auto mt-5">
                    <div className="col-12 px-0">
                        <GridTableWithScroll
                            hover
                            page={agent_statusValue && agent_statusValue.includes(5) ? page : unregisteredAgentsPage}
                            keyField={"id"}
                            data={agent_statusValue && agent_statusValue.includes(5) ? data: unregisteredAgentsData}
                            loading={loader}
                            sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                            wrapperClasses="table-scroll-5"
                            onPageChange={(value) => getDataAgents(value, 5)}
                            columns={[
                                {
                                    dataField: "first_name",
                                    formatter: formatName(),
                                    text: "First Name",
                                },
                                {
                                    dataField: "last_name",
                                    formatter: formatName(),
                                    text: "Last Name",
                                },
                                {
                                    dataField: "email",
                                    short: true,
                                    text: "E-mail",
                                },
                                {
                                    dataField: "phone",
                                    formatter: formatPhoneNumber(),
                                    text: "phone",
                                },
                                {
                                    dataField: "license",
                                    formatter: formatText(),
                                    text: "License",
                                },
                                {
                                    dataField: "broker",
                                    formatter: brokerName(),
                                    text: "Broker",
                                },
                                {
                                    dataField: "account_status",
                                    text: "Status",
                                },
                            ]}
                        />

                        {agents_error && anyTouched && (
                            <div className="invalid-feedback d-block font-weight-bold">
                                {agents_error}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {(agent_statusValue == SELECT_ALL_OPTION["value"]) && (
                <div className="row w-75 px-lg-5 mx-auto mt-5">
                    <div className="col-12 px-0">
                        <Tabs
                            activeKey={tab}
                            defaultActiveKey="Registered"
                            tabBarPosition="top"
                            onChange={(key) => setTab(key)}
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Registered" key="Registered" className="py-0">
                                <div className="px-3 pt-2 pb-3">
                                    <GridTableWithScroll
                                        hover
                                        page={page}
                                        keyField={"id"}
                                        data={data}
                                        loading={loader}
                                        sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                        wrapperClasses="table-scroll-5"
                                        onPageChange={(value) => getDataAgents(value, 5)}
                                        columns={[
                                            {
                                                dataField: "first_name",
                                                formatter: formatName(),
                                                text: "First Name",
                                            },
                                            {
                                                dataField: "last_name",
                                                formatter: formatName(),
                                                text: "Last Name",
                                            },
                                            {
                                                dataField: "email",
                                                short: true,
                                                text: "E-mail",
                                            },
                                            {
                                                dataField: "phone",
                                                formatter: formatPhoneNumber(),
                                                text: "phone",
                                            },
                                            {
                                                dataField: "license",
                                                formatter: formatText(),
                                                text: "License",
                                            },
                                            {
                                                dataField: "broker",
                                                formatter: brokerName(),
                                                text: "Broker",
                                            },
                                            {
                                                dataField: "account_status",
                                                text: "Status",
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Unregistered" key="Unregistered">
                                <div className="px-3 pt-2 pb-3">
                                    <GridTableWithScroll
                                        hover
                                        page={unregisteredAgentsPage}
                                        keyField={"id"}
                                        data={unregisteredAgentsData}
                                        loading={loaderAgentsData}
                                        sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                        wrapperClasses="table-scroll-5"
                                        onPageChange={(value) => getDataAgents(value, 1)}
                                        columns={[
                                            {
                                                dataField: "first_name",
                                                formatter: formatName(),
                                                text: "First Name",
                                            },
                                            {
                                                dataField: "last_name",
                                                formatter: formatName(),
                                                text: "Last Name",
                                            },
                                            {
                                                dataField: "email",
                                                short: true,
                                                text: "E-mail",
                                            },
                                            {
                                                dataField: "phone",
                                                formatter: formatPhoneNumber(),
                                                text: "phone",
                                            },
                                            {
                                                dataField: "license",
                                                formatter: formatText(),
                                                text: "License",
                                            },
                                            {
                                                dataField: "broker",
                                                formatter: brokerName(),
                                                text: "Broker",
                                            },
                                            {
                                                dataField: "account_status",
                                                text: "Status",
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                        

                        {agents_error && anyTouched && (
                            <div className="invalid-feedback d-block font-weight-bold">
                                {agents_error}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </form>
    );
};

let CampaignReduxForm = reduxForm({
    form: "campaignFrom", // a unique identifier for this form
    validate,
    initialValues: {type: 'sms', agent_status: SELECT_ALL_OPTION["value"], state: SELECT_ALL_OPTION["value"]}
})(CampaignForm);

const selector = formValueSelector("campaignFrom");
CampaignReduxForm = connect((state) => {
    const typeValue = selector(state, "type");
    const agent_statusValue = selector(state, 'agent_status');
    return {
        typeValue,
        agent_statusValue,
    };
})(CampaignReduxForm);

export default CampaignReduxForm;
