import React from "react";
import { Field, reduxForm } from "redux-form";
import { SELECT_ALL_OPTION } from "../../../../utility/constants";
import { renderSearchField, MultiSelectField } from "../../Utils/renderField";

let AppReviewFilterForm = (props) => {
    //  Bind
    const { searchAction } = props;
    return (
        <div className="row mt-2 w-100 mb-3 align-items-center">
            <div
                className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}
            >
                <div className="form-group has-feedback w-100 mb-0">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={"Search"}
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="recomendation">Recommendation points</label>
                    <Field
                        name="recomendation"
                        options={[{label: "1", value: 1}, {label: "2", value: 2}, {label: "3", value: 3}, {label: "4", value: 4}, {label: "5", value: 5}]}
                        set_value={(value) => {
                            searchAction(
                                value
                            )   
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
        </div>
    );
}


AppReviewFilterForm = reduxForm({
    form: 'appReviewFilterForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        recomendation: SELECT_ALL_OPTION["value"],
    }
})(AppReviewFilterForm);


export default AppReviewFilterForm;