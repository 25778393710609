import React, { Component } from "react";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import VendorSettingForm from "./VendorSettingForm"

export default class VendorSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subIcons: [],
            quoteIcon: null,
        };
    }

    componentWillMount() {
        this.props.getItem()
    }

    goBack = () => {
        this.props.history.goBack()
    }

    setIcon = position => (icon) => {
        const {subIcons} = this.state;
        if (!!subIcons[position]) {
            subIcons[position].file = icon;
        } else {
            subIcons[position] = {file: icon, name: position};
        }
        this.setState({subIcons: [...subIcons]});
    }

    resetIcons = () => {
        this.setState({subIcons: []})
    }

    setQuoteIcon = (icon) => {
        this.setState({quoteIcon: icon});
    }

    render() {
        //  State
        const { loader, item } = this.props;
        const { subIcons, quoteIcon } = this.state
        //  Bind
        const {update} = this.props


        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            Vendor Settings
                        </div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <VendorSettingForm
                            onSubmit={(data) => {
                                console.log('SUB ICONS: ', subIcons);
                                update({...data}, [{file: quoteIcon, name: "quote_icon"},...subIcons], this.resetIcons)
                            }}
                            goBack={this.goBack}
                            setIcon={this.setIcon}
                            setQuoteIcon={this.setQuoteIcon}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}