import React, {useState, useEffect} from "react";
import moment from "moment"
import Avatar from "react-avatar";
import Grid from "../../Utils/GridTable";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {
    AGENT_REGISTERED_PAGE_SIZE,
    CATEGORY_REGISTERED_LIST,
    REGISTERED_PENDING, REGISTERED_RE_ASSIGN, REGISTERED_ACTIVE, REGISTERED_LOCKED, REGISTERED_BLOCKED, REASON_LOCKED_BY,
} from "../../../../utility/constants";
import { formatPhoneNumber } from "../../Brokerage/BrokerageGrid";


const WholeSalerGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        remove,
        goPage,
        unblockUser,
        blockUserModal,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("WHOLESALER-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="WHOLESALER-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={AGENT_REGISTERED_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    // customRemove: remove,
                                    edit: '/wholesalers',
                                }),
                        },
                        {
                            dataField: "photo",
                            formatter: avatar(),
                            text: " ",
                        },
                        {
                            dataField: "brokerage_information",
                            formatter: formatStatusAccount(),
                            text: "Status",
                        },
                        {
                            dataField: "first_name",
                            sort: true,
                            text: "First Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "last_name",
                            sort: true,
                            text: "Last Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "email",
                            sort: true,
                            text: "E-mail",
                        },
                        {
                            dataField: "mobile_number",
                            formatter: formatPhoneNumber(),
                            text: "Phone",
                        },
                        {
                            dataField: "state",
                            text: "State",
                        },
                        {
                            dataField: "city",
                            text: "City",
                        },
                        {
                            dataField: "date_joined",
                            formatter: (cell, row) => {
                                return <span>{cell ? moment(cell).format('ll') : ""}</span>
                            },
                            sort: true,
                            text: "SignUp Date",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "brokerage_information",
                            text: "Operational Mode",
                            formatter: (cell, row) => {
                                return <span>Open Market</span>
                            }
                        },
                        {
                            dataField: "team__name",
                            sort: false,
                            text: "Team",
                            formatter: (cell, row) => {
                                return (
                                    <span>{row.team_data ? row.team_data.name : ''}</span>
                                )
                            },
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "team__influencer__first_name",
                            sort: false,
                            text: "Influencer",
                            formatter: (cell, row) => {
                                return (
                                    <span>{row.team_data && row.team_data.influencer ? `${row.team_data.influencer.first_name} ${row.team_data.influencer.last_name}` : ''}</span>
                                )
                            },
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "total_posted",
                            sort: false,
                            align: "right",
                            text: "Total Posted",
                        },
                        {
                            dataField: "active",
                            sort: false,
                            text: "Active",
                        },
                        {
                            dataField: 'reason_locked',
                            formatter: setReason(goPage),
                            text: 'Lock Reason'
                        },
                        {
                            dataField: "locked",
                            formatter: formatStatusLocked(blockUserModal, unblockUser, goPage),
                            text: "",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "200px",
                                };
                            },
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.first_name}
                />
            </div>
        );
    };
}

export function formatStatusAccount() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            CATEGORY_REGISTERED_LIST.forEach(item => {
                if (row.agent_account_status === item.value) {
                    text = item.label
                }
            })
            return text
        }
        const getColor = () => {
            let colorText = 'black'
            if (row.agent_account_status === REGISTERED_PENDING) {
                colorText = 'warning'
            } else if (row.agent_account_status === REGISTERED_RE_ASSIGN) {
                colorText = 'warning'
            } else if (row.agent_account_status === REGISTERED_ACTIVE) {
                colorText = 'success'
            } else if (row.agent_account_status === REGISTERED_LOCKED) {
                colorText = 'danger'
            } else if (row.agent_account_status === REGISTERED_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <span className={`text-capitalize font-weight-bold text-${getColor()}`}>
                    {getStatus()}
                </span>
            </React.Fragment>
        )
    };
}

function setReason(goPage) {
    return (cell, row) => {
        let reason = ''
        // console.log('reason: ', row)
        if (!row.locked) {
            return null;
        }
        if (cell) {
            REASON_LOCKED_BY.map((item) => {
                if (item.value === cell) (reason = item.label)
            })
        }

        return (
            <div className="d-flex flex-row align-items-center justify-content-between" style={{width: '150px', overflow: 'hidden'}}>
                <span className="text-danger font-weight-bold">
                    {reason}
                </span>
                <div className="pointer" style={{position: 'relative'}} onClick={(e) => {
                        e.stopPropagation()
                        goPage(`/wholesalers/${row.id}/lock-notes`)
                    }}>
                    <i className="material-icons pointer ml-2 text-dark" style={{
                        minWidth: "1.9rem",
                        maxWidth: "1.9rem",
                        minHeight: "1.9rem",
                        maxHeight: "1.9rem",
                    }}>note_text
                    </i>
                    <span className="badge bg-danger" style={{
                        position: 'absolute',
                        borderRadius: "50%",
                        left: "3px",
                        top: "-1px",
                        padding: "5px",
                        width: "15px",
                        height: "15px",
                        fontSize: "10px",
                        lineHeight: "inherit",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        }}>{row.lock_notes}</span>
                </div>
            </div>
        );
    };
}

export function formatStatusLocked(blockUser, unblockUser, goPage) {
    return (cell, row) => {
        if (cell === true) {
            return (
                <div className="row" style={{ width: '9rem' }}>
                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            unblockUser(row)
                        }}
                        className="btn btn-danger px-0 d-inline mx-0"
                        style={{ fontSize: '10px !important' }}
                    >
                        Unlock User
                    </button>
                </div>
            )
        }else {
            return (
                <div className="row" style={{ width: "13rem" }}>
                    {row.agent_account_status == 10 && (
                        <React.Fragment>
                            <div
                                title="Pending Notes"
                                className="pointer"
                                style={{ position: "relative", width: "40px", overflow: "hidden"}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    goPage(
                                        `/wholesalers/${row.id}/pending-notes`
                                    );
                                }}
                            >
                                <i
                                    className="material-icons pointer ml-2 text-dark"
                                    style={{
                                        minWidth: "1.9rem",
                                        maxWidth: "1.9rem",
                                        minHeight: "1.9rem",
                                        maxHeight: "1.9rem",
                                    }}
                                >
                                    note_text
                                </i>
                                <span
                                    className="badge bg-info"
                                    style={{
                                        position: "absolute",
                                        borderRadius: "50%",
                                        left: "3px",
                                        top: "-1px",
                                        padding: "5px",
                                        width: "15px",
                                        height: "15px",
                                        fontSize: "10px",
                                        lineHeight: "inherit",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {row.pending_notes ? row.pending_notes : 0}
                                </span>
                            </div>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // activeUser(row)
                                    goPage(`/wholesalers/${row.id}/edit`);
                                }}
                                className={`btn btn-info px-4 d-inline mx-0 ${row.agent_account_status == 10 ? "ml-3" : ""}`}
                            >
                                Activate User
                            </button>
                        </React.Fragment>
                    )}
                    {row.agent_account_status == 20 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                blockUser(row);
                            }}
                            className="btn btn-danger px-0 d-inline mx-0"
                            style={{ fontSize: "10px !important" }}
                        >
                            Lock User
                        </button>
                    )}
                </div>
            );

        }
    };
}

export default WholeSalerGrid;
