import React, { Component } from 'react'
import _ from 'lodash'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CreateUpdateCancellationDeleteOptionForm from './CreateUpdateCancellationDeleteOptionForm'

class CreateUpdateCancellationDeleteOption extends Component {
    constructor(props) {
        super(props)
        this.state = {
            is_update: false,
        };
    }

    componentWillMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
        }
        if (match.path === '/app_cancellation_delete_option/:id/edit') {
            this.setState({is_update: true});
        }
    }

    componentDidMount() {

    }

    createInfluencer = (data) => {
        let _data = _.cloneDeep(data)
        this.props.create(_data)
    }

    updateInfluencer = (data) => {
        const {match} = this.props;
        const _id = data.id ? data.id : match.params.id
        let _data = _.cloneDeep(data)
        this.props.edit(_id, _data);
    };

    goBack = () => {
        const {history} = this.props;
        history.goBack()
    }

    render() {

        const {match, item, loader} = this.props;
        const {is_update} = this.state;
        const sendFunction = match.params.id
            ? this.updateInfluencer
            : this.createInfluencer;


        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_update && "Update Option"}
                            {!is_update && "Create Option"}
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4">
                    <LoadMask loading={loader} dark blur>
                        <CreateUpdateCancellationDeleteOptionForm
                            onSubmit={sendFunction}
                            item={item}
                            is_update={is_update}
                            goBack={this.goBack}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default CreateUpdateCancellationDeleteOption