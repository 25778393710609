import React, {Component} from 'react';
import AdminListGrid from "./AdminListGrid";

class AdminList extends Component {
    componentWillMount() {
        const {toList} = this.props;
        setTimeout(() => {
            toList();
        }, 500);

    }

    render() {
        const {loader, page, data, searchChange, toList, remove} = this.props;
        return (
            <AdminListGrid
                loader={loader}
                data={data}
                page={page}
                toList={toList}
                searchAction={searchChange}
                remove={remove}
            />
        )
    }
}

export default AdminList;
