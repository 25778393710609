import {api} from "api";
import _ from "lodash"
import moment from "moment";
import Swal from 'sweetalert2';
import momentTZ from 'moment-timezone';
import {handleActions} from "redux-actions";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {change, initialize as initializeForm} from "redux-form";
import {getDashboardDateRange, SELECT_ALL_OPTION} from "../../../utility/constants"

const SET_LOADER = "SET_LOADER_STATS";
const SET_ITEM = "SET_ITEM_REVENUE_STAT";
const SET_PAGE = "SET_PAGE_REVENUE_STAT";
const SET_PAGE_2 = "SET_PAGE_PERFORMANCE_STAT";
const SET_DATA_JOB = "SET_DATA_JOB_FILTER_STATS";
const SET_DATA_BROKER_OPTION = "SET_DATA_BROKER_OPTION_STATS";
const SET_DATA_BROKER_OPTION2 = "SET_DATA_BROKER_OPTION2_STATS";
const SET_DATA_AGENT = "SET_DATA_AGENT_FILTER_STATS";
const SET_JOB_TYPE_ID_STATS = "SET_JOB_TYPE_ID_STATS";
const SET_JOB_OPTION_ID_STATS = "SET_JOB_OPTION_ID_STATS";
const SET_DATA_OFFICES = "SET_DATA_OFFICES_FILTER_STATS";
const SET_DATA_OFFICES2 = "SET_DATA_OFFICES2_FILTER_STATS";
const SET_ITEM_PERFORMANCE = "SET_ITEM_PERFORMANCE_STATS";
const SET_ITEM_AGENT_DETAIL = "SET_ITEM_AGENT_DETAIL_STATS";
const SET_DATA_AGENT2 = "SET_DATA_AGENT2_STATS";
const SET_JOB_POSTED_DATA = "SET_JOB_POSTED_DATA_STATS";
const SET_PAGE_JOB_POSTED_DATA = "SET_PAGE_JOB_POSTED_DATA_STATS";
const SET_ITEM_POSTED_DETAILS = "SET_ITEM_POSTED_DETAILS_STATS";
const SET_DATA_STATS = "SET_DATA_STATS_STATS";
const SET_DOWNLOAD_LOADER = "SET_DOWNLOAD_LOADER_STATS";
const SET_JOB_STATUS = "SET_JOB_STATUS_STATS";
const VERSION_LOADER = "VERSION_LOADER_STATS";
//  EDIT JOB
const JOB_ITEM = "JOB_ITEM_STATS";
const JOB_LOADER = "JOB_LOADER_STATS";
const NO_SHOW_COMPLETE = "NO_SHOW_COMPLETE_DETAILS_STATS"
const SELECT_ADDRESS_COMPLETE = "SELECT_ADDRESS_COMPLETE_DETAILS_STATS"

const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});

const setDownloadLoader = (downloadLoader) => ({
    type: SET_DOWNLOAD_LOADER,
    downloadLoader,
});

const setPagination = (page) => ({
    type: SET_PAGE,
    page,
});

const setPagination2 = (page2) => ({
    type: SET_PAGE_2,
    page2,
});

const setPaginationJobPosted = (pageJobsPosted) => ({
    type: SET_PAGE_JOB_POSTED_DATA,
    pageJobsPosted,
});

const setItem = (item) => ({
    type: SET_ITEM,
    item,
});

const setItemPerformance = (itemPerformance) => ({
    type: SET_ITEM_PERFORMANCE,
    itemPerformance,
});

const setDataOffices = (dataOfficeName) => ({
    type: SET_DATA_OFFICES,
    dataOfficeName,
});

const setDataOffices2 = (dataOfficeName2) => ({
    type: SET_DATA_OFFICES2,
    dataOfficeName2,
});

const setDataBrokerOption = (dataBrokerName) => ({
    type: SET_DATA_BROKER_OPTION,
    dataBrokerName,
});

const setDataBrokerOption2 = (dataBrokerName2) => ({
    type: SET_DATA_BROKER_OPTION2,
    dataBrokerName2,
});

const setNameJob = (dataJobType) => ({
    type: SET_DATA_JOB,
    dataJobType,
});

const setDateAgent = (dataAgentName) => ({
    type: SET_DATA_AGENT,
    dataAgentName,
});

const setDateAgent2 = (dataAgentName2) => ({
    type: SET_DATA_AGENT2,
    dataAgentName2,
});

const setDetailsAgent = (itemDetails) => ({
    type: SET_ITEM_AGENT_DETAIL,
    itemDetails,
});

const setJopPostedData = (jobPostedData) => ({
    type: SET_JOB_POSTED_DATA,
    jobPostedData,
});

const setItemPostedDetails = (itemPostedDetails) => ({
    type: SET_ITEM_POSTED_DETAILS,
    itemPostedDetails,
});

const setValueFilter = (value, type) => ({
    type: type,
    value,
});

const setDataStats = dataStats => ({
    type: SET_DATA_STATS,
    dataStats,
});

const setJobStatus = jobStatus => ({
    type: SET_JOB_STATUS,
    jobStatus,
});

const setVersionLoader = (versionLoader) => ({
    type: VERSION_LOADER,
    versionLoader,
});

const setJobItem = (jobItem) => ({
    type: JOB_ITEM,
    jobItem,
});

const setJobLoader = (jobLoader) => ({
    type: JOB_LOADER,
    jobLoader,
});

const setNoShowComplete = (noShowComplete) => ({
    type: NO_SHOW_COMPLETE,
    noShowComplete,
});

const setSelectAddressComplete = (selectAddressComplete) => ({
    type: SELECT_ADDRESS_COMPLETE,
    selectAddressComplete,
})

const getNameAllJobType = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("job_type/all_job_type")
        .then((response) => {
            let JOB = [{
                label: "All",
                value: '',
            }];
            response.map((nameJob, indexOne) => {
                if (nameJob.job_type_option.length > 0) {
                    nameJob.job_type_option.map((option, index) => {
                        JOB.push({
                            value: indexOne + '.' + index,
                            jobTypeId: nameJob.id,
                            optionId: option.id,
                            label: option.name,
                        });
                    });
                } else {
                    JOB.push({
                        jobTypeId: nameJob.id,
                        label: nameJob.name,
                        value: indexOne,
                        optionId: null,
                    });
                }
            });
            dispatch(setNameJob(JOB));
        })
        .catch((error) => {
            console.log('getAllJobType error--', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getAllOffices = (idBroker = '', isPostedJob = false) => (dispatch) => {
    let params = {}
    if (idBroker !== '') {
        params.broker_id = idBroker
    }
    api.get('brokerage/option_select', params)
        .then(response => {
            let option = [];
            if (response && response.results.length > 0) {
                option = [{
                    label: 'All',
                    value: '',
                }, ...response.results];
            } else {
                option = [{
                    label: 'No options',
                    value: '',
                }]
            }
            if (isPostedJob) {
                dispatch(setDataOffices2(option));
            } else {
                dispatch(setDataOffices(option));
            }
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const getDataRevenue = (page = 1, broker_id = '', brokerage = '', agent_id = '', start_date, end_date) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const source = getStore()['stats'];
    const job_type = source.jobType;
    const job_type_option = source.jobOption;
    if (start_date === undefined) {
        const today = moment().date()
        start_date = moment(Date.now()).subtract(today - 1, 'days').format('YYYY-MM-DD')
    }
    if (end_date === undefined) {
        end_date = moment(Date.now()).format('YYYY-MM-DD');
    }
    if (moment(start_date) > moment(end_date)) {
        NotificationManager.error('Start date is greater than end date', 'ERROR', 3000);
        dispatch(setLoader(false));
    } else {
        const params = {
            page,
            job_type_option,
            start_date,
            brokerage,
            end_date,
            job_type,
            agent_id,
            broker_id,
        };
        api.get('broker-user/stats_revenue', params)
            .then(response => {
                dispatch(setItem(response));
                dispatch(setPagination(page));
            })
            .catch((error) => {
                console.log('--- error getDataRevenue ---', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
}

const getPerformance = (page = 1, broker_id = '', brokerage = '', agent_id = '', start_date, end_date, rating = '') => (dispatch) => {
    dispatch(setLoader(true));
    if (start_date === undefined) {
        const today = moment().date()
        start_date = moment(Date.now()).subtract(today - 1, 'days')
            // .subtract(5, "months")
            .format('DD-MM-YYYY')
    }
    if (end_date === undefined) {
        end_date = moment(Date.now()).format('DD-MM-YYYY');
    }
    if (moment(start_date) > moment(end_date)) {
        NotificationManager.error('Start date is greater than end date', 'ERROR', 3000);
        dispatch(setLoader(false));
    } else {
        // const params = {
        //     page,
        //     broker_id,
        //     brokerage,
        //     agent_id,
        //     rating,
        //     start_date,
        //     end_date,
        // };
        let params = {page, time: [start_date, end_date]}
        api.get('agent/stats_performance', params)
            .then(response => {
                dispatch(setItemPerformance(response));
                dispatch(setPagination2(page));
            })
            .catch((error) => {
                console.log('--- error getPerformance ---', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
}

const getJobPostedStats = (page = 1, broker_id = '', brokerage = '', agent_id = '', start_date, end_date) => (dispatch) => {
    dispatch(setLoader(true));
    if (start_date === undefined) {
        const today = moment().date()
        start_date = moment(Date.now()).subtract(today - 1, 'days').format('YYYY-MM-DD')
    }
    if (end_date === undefined) {
        end_date = moment(Date.now()).format('YYYY-MM-DD');
    }
    if (moment(start_date) > moment(end_date)) {
        NotificationManager.error('Start date is greater than end date', 'ERROR', 3000);
        dispatch(setLoader(false));
    } else {
        const params = {
            page,
            broker_id,
            brokerage,
            agent_id,
            start_date,
            end_date,
        };
        api.get('agent/job_posted_stats', params)
            .then(response => {
                dispatch(setJopPostedData(response));
                dispatch(setPaginationJobPosted(page));
            })
            .catch((error) => {
                console.log('--- error getPerformance ---', error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
}

const getAgentsByOffice = (brokerage = '', broker_id = '', isPostedJob = false) => (dispatch) => {
    api.get("broker-user/agent_by_office", {brokerage, broker_id})
        .then((response) => {
            const labelUser = response.data;
            let userArray = [{
                label: 'All',
                value: '',
            }];
            if (labelUser.length > 0) {
                labelUser.map((user) => {
                    userArray.push({
                        label: `${user.first_name + ' ' + user.last_name}`,
                        value: user.id,
                        brokerage: user.brokerage_information_id,
                    });
                });
            } else {
                userArray = [{
                    label: 'No options',
                    value: '',
                }]
            }
            if (isPostedJob) {
                dispatch(change('JobPosted', 'agentJobPosted', ''))
                dispatch(setDateAgent2(userArray));
            } else {
                dispatch(change('PerformanceForm', 'agent', ''))
                dispatch(setDateAgent(userArray));
            }

        })
        .catch((error) => {
        })
        .finally(() => {
        });
}

// -------------------------------------------------
// -------  SET VALUES TO FILTER           ---------
// -------------------------------------------------
const setJobTypeFilter = (selected) => (dispatch) => {
    // console.log('selected', selected)
    if (selected.value === '') {
        dispatch(setValueFilter('', SET_JOB_TYPE_ID_STATS));
        dispatch(setValueFilter('', SET_JOB_TYPE_ID_STATS));
        dispatch(setValueFilter('', SET_JOB_OPTION_ID_STATS));
    } else if (selected.optionId === null) {
        // filter by job type
        dispatch(setValueFilter('', SET_JOB_OPTION_ID_STATS));
        dispatch(setValueFilter(selected.jobTypeId, SET_JOB_TYPE_ID_STATS));
    } else {
        // filter by job type option
        dispatch(setValueFilter('', SET_JOB_TYPE_ID_STATS));
        dispatch(setValueFilter(selected.optionId, SET_JOB_OPTION_ID_STATS));
    }
};

const setFilterBroker = (selected) => (dispatch) => {
    // console.log('selected', selected)
    const brokerId = selected.value
    dispatch(getAllOffices(brokerId));
    dispatch(getAgentsByOffice('', brokerId))
};

const goPage = (url) => (dispatch) => {
    dispatch(push(url));
};

const getReviewDetails = (agent = '', page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("broker-user/review_detail", {agent_id: agent, page})
        .then((response) => {
            const change_ = {...response}
            dispatch(setDetailsAgent(change_));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const getPostsDetails = (agent = '', page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("broker-user/posts_details", {agent_id: agent, page})
        .then((response) => {
            console.log('data: ', response)
            const change_ = {...response}
            dispatch(setItemPostedDetails(change_));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const getAllBrokerOption = (isPostedJob = false) => (dispatch) => {
    api.get('brokerage/option_broker')
        .then(response => {
            let option = [];
            option = [{
                label: 'All',
                value: '',
            }, ...response.results];
            if (isPostedJob) {
                dispatch(setDataBrokerOption2(option));
            } else {
                dispatch(setDataBrokerOption(option));
            }
        })
        .catch(() => {
        })
        .finally(() => {
        });
}

const setDefaultValue = () => (dispatch) => {
    dispatch(setDetailsAgent(
        {
            results: [],
            count: 0,
        }
    ));
}

const getDataBrokerRawData = (page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    if (page === 1) {
        dispatch(setItem([]));
    }

    api.get('broker-user/raw_data_stats', {page})
        .then((response) => {
            dispatch(setDataStats(response));
            dispatch(setPagination(page));
        })
        .catch((error) => {
            console.log('--- error getDataBrokerRawData ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const downloadBrokerRawDataReport = () => (dispatch) => {
    dispatch(setDownloadLoader(true));

    api.getBlob('broker-user/raw_data_stats', {type_request: 'report'})
        .then((blob) => {
            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "Broker_raw_data.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
        .catch((error) => {
            console.log('--- error getDataBrokerRawData ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(false));
        });
};

// ------- JOB DETAILS RAW DATA

const getJobDetailsRawData = (page=1, loader=true, withTime=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader(true));
    }
    const store = getStore()
    const source = store['stats'];
    if (page === 1) {
        dispatch(setDataStats({
            results: [],
            count: 0,
        }));
    }
    let params = {page}

    // filter
    const {jobDetailsRawDataForm} = store.form;

    // const {jobStatus} = source;
    // params.status = jobStatus ? jobStatus : '';
    if (jobDetailsRawDataForm && jobDetailsRawDataForm.values) {
        const {time, start_date, end_date, jobStatus, search} = jobDetailsRawDataForm.values;
        if (jobStatus !== "" && jobStatus !== null && jobStatus !== undefined && jobStatus !== SELECT_ALL_OPTION["value"]) {
            if (jobStatus.length == 0) {
                params.status = "null"
            }else {
                params.status = jobStatus;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = withTime ? getDashboardDateRange(2, "weeks") : '';
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
    }else {
        params.time = getDashboardDateRange(2, "weeks");
    }

    // if (jobStatus != null) {
    //     dispatch(change('jobDetailsRawDataForm', 'jobStatus', ''))
    // }

    api.get('broker-user/job_details_raw_data_stats', params)
        .then((response) => {
            console.log(response)
            dispatch(setDataStats(response));
            dispatch(setPagination(page));
        })
        .catch((error) => {
            console.log('--- error getDataJobDetailsRawData ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader(false));
            }
        });
}

const setJobStatusFilter = (selected) => (dispatch) =>  {
    // if(selected.value === '') {
    //     dispatch(setJobStatus(''))
    // }else {
    //     dispatch(setJobStatus(selected.value))
    // }
    dispatch(getJobDetailsRawData())
    console.log("Selected: ", selected);
}

const downloadJobDetailsRawDataReport = () => (dispatch, getStore) => {
    dispatch(setDownloadLoader(true));
    const store = getStore()
    const source = store['stats'];
    let params = {type_request: 'report'}
    // filter
    const {jobStatus} = source;
    params.status = jobStatus ? jobStatus : '';

    const {jobDetailsRawDataForm} = store.form;
    if (jobDetailsRawDataForm && jobDetailsRawDataForm.values) {
        const {time, start_date, end_date, jobStatus} = jobDetailsRawDataForm.values;
        if (jobStatus !== "" && jobStatus !== null && jobStatus !== undefined && jobStatus !== SELECT_ALL_OPTION["value"]) {
            if (jobStatus.length == 0) {
                params.status = "null"
            }else {
                params.status = jobStatus;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(2, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(2, "weeks");
    }
    // if (jobDetailsRawDataForm && jobDetailsRawDataForm.values) {
    //     const {time, start_date, end_date} = jobDetailsRawDataForm.values;
    //     if (time !== "" && time !== null && time !== undefined) {
    //         if (time === 'custom') {
    //             let startDate = moment().format("DD-MM-YYYY")
    //             let endDate = moment().format("DD-MM-YYYY")

    //             if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
    //                 startDate = moment(start_date).format("DD-MM-YYYY")
    //             }
    //             if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
    //                 endDate = moment(end_date).format("DD-MM-YYYY")
    //             }
    //             params.time = [startDate, endDate];
    //         }else {
    //             params.time = time;
    //         }
    //     }else {
    //         params.time = getDashboardDateRange(1, "weeks");
    //     }
    // }else {
    //     params.time = getDashboardDateRange(1, "weeks");
    // }

    api.get('broker-user/job_details_raw_data_stats', params)
        .then((data) => {
            if (data) {
                NotificationManager.info(
                    "The download will start in a moment. Please do not reload the page until the file has been downloaded",
                    "Download in progress!",
                    8000
                );
                dispatch(waitDownload(data.id))
            }
        })
        .catch((error) => {
            console.log('--- error downloadJobDetailsRawDataReport ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(false));
        });

};


const waitDownload = (id) => (dispatch) => {
    let intervalPromise;

    function listener() {
        api.get('archive/download_status', {id})
        .then((resp) => {
            if (resp.status === 10) {
                // PROCESANDO
                dispatch(setDownloadLoader(true));
            } else if (resp.status === 20) {
                // TERMINADO
                clearInterval(intervalPromise);
                let name = resp.file
                    ? resp.file.split("/media/archives/")[1]
                    : "job_details_raw_data.xlsx";

                const context = {
                    name: name,
                    url: resp.file,
                };
                
                dispatch(setDownloadLoader(false));
                
                //  DOWNLOAD EXCEL FILE
                const a = document.createElement('a');
                a.href = context.url;
                a.download = context.name
                document.body.appendChild(a);
                a.click();
                a.remove();
                NotificationManager.success('File downloaded successfully', 'SUCCESS', 3000);
            }
        })
        .catch((err) => {
            let msg =
                "An error occurred while downloading the file. Please try later";
            if (err.status) {
                msg = err.notes;
            }
            dispatch(setDownloadLoader(false));
            clearInterval(intervalPromise);
            NotificationManager.error(msg, 'ERROR', 3000);
        })
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
}

// -------  AGENT RAW DATA

const getAgentRawData = (page=1) => (dispatch, getStore) => {
    const store = getStore();
    dispatch(setLoader(true));
    if (page === 1) {
        dispatch(setDataStats({
            results: [],
            count: 0,
        }));
    }
    let params = {page}

    const { AgentRawDataFilterForm } = store.form;
    if (AgentRawDataFilterForm && AgentRawDataFilterForm.values) {
        const { search } = AgentRawDataFilterForm.values
        if (search) {
            params.search = search
        }
    }

    api.get('broker-user/agent_raw_data_stats', params)
        .then((response) => {
            dispatch(setDataStats(response));
            dispatch(setPagination(page));
        })
        .catch((error) => {
            console.log('--- error getDataAgentRawData ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

export const agentRawSearchChange = (search) => (dispatch) => {
    dispatch(getAgentRawData());
};

export const changeAgentRawFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case 'search':
                setTimeout(() => {
                    dispatch(agentRawSearchChange(value));
                }, 600)
                break;
        
            default:
                break;
        }
    }

const downloadAgentRawDataReport = () => (dispatch) => {
    dispatch(setDownloadLoader(true));

    api.getBlob('broker-user/agent_raw_data_stats', {type_request: 'report'})
        .then((blob) => {
            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "Agent_raw_data.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
        .catch((error) => {
            console.log('--- error downloadAgentRawDataReport ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(false));
        });
};

// -------  WALLET MANAGEMENT

const getWalletManagement = (page=1) => (dispatch) => {
    dispatch(setLoader(true));
    if (page === 1) {
        dispatch(setDataStats({
            results: [],
            count: 0,
        }));
    }
    let params = {page}

    api.get('broker-user/wallet_management_stats', params)
        .then((response) => {
            dispatch(setDataStats(response));
            dispatch(setPagination(page));
        })
        .catch((error) => {
            console.log('--- error getDataWalletManagement ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const downloadWalletManagementReport = () => (dispatch) => {
    dispatch(setDownloadLoader(true));

    api.getBlob('broker-user/wallet_management_stats', {type_request: 'report'})
        .then((blob) => {
            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "Wallet_management.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
        .catch((error) => {
            console.log('--- error downloadWalletManagementReport ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(false));
        });
};

// -------  PAYOUT

const getPayout = (page=1) => (dispatch) => {
    dispatch(setLoader(true));
    if (page === 1) {
        dispatch(setDataStats({
            results: [],
            count: 0,
        }));
    }
    let params = {page}

    api.get('broker-user/payout_stats', params)
        .then((response) => {
            dispatch(setDataStats(response));
            dispatch(setPagination(page));
        })
        .catch((error) => {
            console.log('--- error getDataPayout ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const downloadPayoutReport = () => (dispatch) => {
    dispatch(setDownloadLoader(true));

    api.getBlob('broker-user/payout_stats', {type_request: 'report'})
        .then((blob) => {
            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = "Payout.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
        .catch((error) => {
            console.log('--- error downloadPayoutReport ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(false));
        });
};

const downloadVisitorReport = (id, reportType='full') => (dispatch) => {
    dispatch(setLoader(true));

    api.getBlob('broker-user/open_house_visitors_report', {id, report_type: reportType})
        .then((blob) => {
            if (blob) {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Visitors_feedback_report_${id}.xlsx`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        })
        .catch((error) => {
            console.log('--- error getDataBrokerRawData ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setTimeFilter = (value) => (dispatch) =>  {
    setTimeout(() => {
        dispatch(getJobDetailsRawData())
    }, 400);
}
const readAppVersion = (id) => (dispatch) => {
    dispatch(setVersionLoader(true));
    api.get(`app_version_log/${id}`).then((response) => {
        dispatch(initializeForm('appVersionForm', response))
    }).catch((error) => {
        NotificationManager.error("Error obtaining data", 'ERROR', 3000);
    }).finally(() => {
        dispatch(setVersionLoader(false));
    })
}

const submitVersion = (data, goBack, toList) => (dispatch) => {
    dispatch(setVersionLoader(true));
    const {id} = data;
    if (id) {
        api.put(`app_version_log/${id}`, data).then((response) => {
            NotificationManager.success('Registered successfully', 'Success', 3000);
            goBack()
            toList()
        }).catch((error) => {
            NotificationManager.error("It was not possible to save the data", 'ERROR', 3000);
        }).finally(() => {
            dispatch(setVersionLoader(false));
        })
    } else {
        api.post(`app_version_log`, data).then((response) => {
            NotificationManager.success('Registered successfully', 'Success', 3000);
            goBack()
        }).catch((error) => {
            NotificationManager.error("It was not possible to save the data", 'ERROR', 3000);
        }).finally(() => {
            dispatch(setVersionLoader(false));
        })
    }
}

const deleteVersion = (id, toList) => (dispatch) => {
    dispatch(setVersionLoader(true));
    api.eliminar(`app_version_log/${id}`).then((response) => {
        NotificationManager.success('Version removed', 'Success', 3000);
        toList()
    }).catch((error) => {
        console.log('ERROR: ', error);
        NotificationManager.error("It was not possible remove version", 'ERROR', 3000);
    }).finally(() => {
        dispatch(setVersionLoader(false));
    })

}

export const getJobItem = (id) => (dispatch) => {
    dispatch(setJobItem(null));
    dispatch(setJobLoader(true));
    dispatch(initializeForm('editJobFrom', {}))
    dispatch(setNoShowComplete(false))
    dispatch(setSelectAddressComplete(false))

    api.get(`job_posting/${id}`)
        .then((response) => {
            dispatch(setJobItem(response));
            const data = {
                id: response.id,
                date: response.date
            }

            data.descriptions = response.job_description.map(item => {
                const _item = {...item}
                let startDateFinal = momentTZ(_item.date_start).format()
                let startTime = momentTZ(_item.date_start).format("kk:mm:ss")
                //  START DATE FORMAT LOCAL TIMEZONE
                if (_item.timezone) {
                    const startDate = momentTZ(_item.date_start).tz(_item.timezone)
                    console.log("S DATE: ", startDate.format('YYYY-MM-DD kk:mm:ss'));
                    startDateFinal = startDate.format()
                    startTime = moment(startDate.format('YYYY-MM-DD kk:mm:ss')).format("kk:mm:ss")
                }
                return {
                    ..._item,
                    date_start: startDateFinal,
                    start_time: startTime,
                    date_mask: `${item.duration}    mins`,
                    duration: `${item.duration}    mins`,
                }
            })
            console.log("DATA: ", data);
            setTimeout(() => {
                dispatch(initializeForm('editJobFrom', data))
            }, 600);
        })
        .catch((error) => {
            console.log('--- error getJobItem ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setJobLoader(false));
        });
}

export const assignAddressData = (data, index, type) => async(dispatch, getStore) => {
    const store = getStore()
    const {editJobFrom} = store.form
    const {values} = editJobFrom

    if (values) {
        const descriptions = _.cloneDeep(values.descriptions)
        const description = _.cloneDeep(descriptions[index])
        if (description) {
            switch (type) {
                case 'COORDS':
                    description.latitude = data.latitude
                    description.longitude = data.longitude
                    //  CHANGE DATE START
                    let dateString = `${values.date} ${description.start_time}`
                    await api.get('general/get_timezone', { latitude: data.latitude, longitude: data.longitude })
                        .then(response => {
                            if (response) {
                                const tz = response.timezone;
                                console.log('TZ: ', tz);
                                dateString = momentTZ.tz(dateString, tz).format()
                                console.log("DATA STR MOMENT: ", dateString);
                                description.date_start = dateString
                                description.timezone = tz
                                // finalDate = this.asignTimezone(dateString, jobDate, tz)
                            } else {
                                // dateString = moment(dateString, "YYYY-MM-DD kk:mm:ss").format()
                                dateString = momentTZ.tz(dateString, description.timezone).format()
                                description.date_start = dateString
                            }
                        }).catch(err => {
                            // dateString = moment(dateString, "YYYY-MM-DD kk:mm:ss").format()
                            dateString = momentTZ.tz(dateString, description.timezone).format()
                            description.date_start = dateString
                        })
                    descriptions.splice(index, 1, description)
                    dispatch(change('editJobFrom', 'descriptions', descriptions))
                    break;
                case 'ADDRESS_DATA':
                    description.city = data.city
                    description.state = data.state
                    description.zip_code = data.zip_code
                    descriptions.splice(index, 1, description)
                    dispatch(change('editJobFrom', 'descriptions', descriptions))
                    break;
                case 'END_TIME':
                    const {value} = data;
                    console.log('DATA END_TIME: ', data);
                    let startTime = moment(value, "kk:mm:ss")
                    const duration = description.duration.replace("    mins", "")
                    description.date_end = startTime.add(parseInt(duration), 'minutes').format("hh:mm A")
                    //  CHANGE DATE START
                    let _dateString = `${values.date} ${value}`
                    _dateString = momentTZ.tz(_dateString, description.timezone).format()
                    description.date_start = _dateString
                    console.log("DESCRIPTION: ", description);
                    descriptions.splice(index, 1, description)
                    dispatch(change('editJobFrom', 'descriptions', descriptions, true))
                    break;
                default:
                    break;
            }
        }
    }

}

export const changeDate = (date) => (dispatch, getStore) => {
    const store = getStore()
    const {editJobFrom} = store.form
    const {values} = editJobFrom
    if (values) {
        const {descriptions} = values;
        let finalDescriptions = []
        descriptions.forEach(description => {
            const _description = _.cloneDeep(description)
            //  CHANGE DATE START
            let _dateString = `${date} ${description.start_time}`
            _dateString = momentTZ.tz(_dateString, description.timezone).format()
            _description.date_start = _dateString

            finalDescriptions.push(_description)
        });

        console.log("FINAL: ", finalDescriptions);

        dispatch(change('editJobFrom', 'descriptions', finalDescriptions, true))
    }
}

export const postEditJob = (id, data, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {page} = store['stats']
    dispatch(setJobLoader(true));

    api.post(`job_posting/${id}/edit_job_admin`, data)
        .then(response => {
            NotificationManager.success('The job was successfully edited', 'Success', 3000);
            onClose()
            dispatch(getJobDetailsRawData(page))
            dispatch(initializeForm('editJobFrom', {}))
        }).catch((error) => {
            console.log('--- error postEditJob ---', error);
            let message = "It not posible to modify the job"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        }).finally(() => {
            dispatch(setJobLoader(false));
        })
}
export const validateToCompleteJob = (id, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {jobItem} = store['stats']
    if (jobItem.no_show) {
        dispatch(setNoShowComplete(true));
    } else {
        dispatch(setNoShowComplete(false));
        dispatch(setSelectAddressComplete(true));
        const APPROVED = 200
        const descriptions = jobItem.job_description ? jobItem.job_description : []
        const addresses = descriptions.filter(item => item.status === APPROVED)
        dispatch(initializeForm('selectAddressesToCompleteJob', {addresses: addresses.map(item => {
            return {id: item.id, value: true, label: item.address}
        })}))
    }
}

export const completeJob = (id, data, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {page} = store['stats']
    console.log("DATA: ", data);
    Swal.fire({
        title: 'Are you sure you want to complete the job?',
        text: "This action cannot be reversed",
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, Complete job!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setJobLoader(true));
            api.post(`job_posting/${id}/complete_job_admin`, data)
                .then(response => {
                    NotificationManager.success('The job was successfully completed', 'Success', 3000);
                    onClose()
                    dispatch(getJobDetailsRawData(page))
                }).catch((error) => {
                    console.log('--- error postEditJob ---', error);
                    let message = "Could not complete the job"
                    if (error.detail) message = error.detail;
                    NotificationManager.error(message, 'ERROR', 3000);
                }).finally(() => {
                    dispatch(setJobLoader(false));
                })
        }
    })
}

export const validateNoShowCompleteJob = (id, data, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {jobItem} = store['stats']
    const {type: completeType} = data
    if (completeType) {
        if (completeType === "canceled") {
            dispatch(noShowCompleteJob(id, data, onClose))
        } else {
            dispatch(setSelectAddressComplete(true));
            const APPROVED = 200
            const descriptions = jobItem && jobItem.job_description ? jobItem.job_description : []
            const addresses = descriptions.filter(item => item.status === APPROVED)
            dispatch(initializeForm('selectAddressesToCompleteJob', {addresses: addresses.map(item => {
                return {id: item.id, value: true, label: item.address}
            })}))
        }
    }
}

export const noShowCompleteJob = (id, data, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {page} = store['stats']
    console.log("DATA: ", data);
    Swal.fire({
        title: 'Are you sure you want to complete the job?',
        text: "This action cannot be reversed",
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, Complete job!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setJobLoader(true));
            api.put(`job_posting/${id}/process_no_show`, data)
                .then(response => {
                    NotificationManager.success('The job was successfully completed', 'Success', 3000);
                    onClose()
                    dispatch(getJobDetailsRawData(page))
                }).catch((error) => {
                    console.log('--- error postEditJob ---', error);
                    let message = "Could not complete the job"
                    if (error && error.detail) message = error.detail;
                    NotificationManager.error(message, 'ERROR', 3000);
                }).finally(() => {
                    dispatch(setJobLoader(false));
                })
        }
    })
}

export const changeFilterFieldForm = (value, field) => (dispatch) => {
    dispatch(change('jobDetailsRawDataForm', field, value))
}

const changeJobTransferType = (data) => (dispatch, getStore) => {
    const store = getStore()
    const {page} = store['stats']
    const {transfer_type} = data
    console.log("DATA HOLD: ", data);

    let text = ""
    if (transfer_type == 200) {
        text = "<p>When you choose to hold the payment to the ShowIn Agent, it means that the funds associated with that payment will be retained Permanently within your account until you decide to release the payment. Essentially, you have control over if the funds are transferred to the ShowIn Agent.</p>"
        text += "<p>This allows you to keep the funds in your account until you are satisfied with the service provided by the ShowIn Agent. You can review the performance or completion of the task before releasing the payment to ensure that your expectations have been met.</p>"
        text += "<p>Holding the payment provides you with an added layer of security and control over job performance, giving you the ability to manage and assess the quality of the service before finalizing the payment.</p>"
    } else {
        text = "<p>When pressing release, the Broker will recieve this message and they need to confirm or decnline</p>"
        text += "<p>By releasing the payment, you confirm that the job was done properly and professionally. It signifies your satisfaction with the service provided by the ShowIn Agent. Releasing the payment also initiates the immediate transfer of funds to the ShowIn Agent's account.</p>"
    }

    Swal.fire({
        html: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c4183c',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'CONFIRM',
        cancelButtonText: 'DECLINE',
        reverseButtons: true,
        width: '60%'
    }).then((result) => {
        if (result.value) {
            dispatch(setJobLoader(true));
            api.put(`broker-user/change_job_transfer_type`, data)
                .then(response => {
                    NotificationManager.success('The job was successfully edited', 'Success', 3000);
                    dispatch(getJobDetailsRawData(page))
                }).catch((error) => {
                    console.log('--- error postEditJob ---', error);
                    let message = "Could not edit the job"
                    if (error.detail) message = error.detail;
                    NotificationManager.error(message, 'ERROR', 3000);
                }).finally(() => {
                    dispatch(setJobLoader(false));
                })
        }
    })
}

const setJobSearchFilter = (selected) => (dispatch) =>  {
    setTimeout(() => {
        dispatch(getJobDetailsRawData())
    }, 400);
}

export const rAgentCancel = (id, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {page, jobItem} = store['stats']
    const APPLIED = 20
    let htmlTemplate = `
    <textarea id="cancel-reason" autocapitalize="off" class="swal2-textarea" placeholder="Kindly provide the reason" style="display: flex;"></textarea>
    `
    if (jobItem && jobItem.state_job > APPLIED) {
        htmlTemplate += `
        <label id="pay-checkbox" class="swal2-checkbox" style="display: flex;">
            <input class="pay-cancellation" type="checkbox" value="1" id="pay-cancellation" checked>
            <span class="swal2-label">Do you want to pay the cancellation fee?</span>
        </label>
        `
    }

    Swal.fire({
        title: 'Are you sure you want to cancell the Job (R-Agent)?',
        text: "This action cannot be reversed",
        html: htmlTemplate,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, Cancell job!',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            const cancelReason = document.getElementById("cancel-reason").value
            const payCheckbox = document.getElementById('pay-checkbox')

            let payCancel = null
            try {
                var checkboxInput = payCheckbox.querySelector("input[type=checkbox]");
                payCancel = checkboxInput.checked
            } catch (error) {
                console.log("ERROR: ", error);
            }
            if (!cancelReason) {
                Swal.showValidationMessage(`You need to provide the reason!`)
            }
            return { cancelReason, payCancel }
        }
    }).then((result) => {
        console.log("RESULT: ", result);
        const {value} = result
        if (value && value.cancelReason && (value.payCancel === true || value.payCancel === false || value.payCancel === null)) {
            dispatch(setJobLoader(true));
            api.put(`job_posting/${id}/cancel_job`, {cancellation_note: value.cancelReason, generates_payment: value.payCancel})
                .then(response => {
                    NotificationManager.success('The job was successfully cancelled', 'Success', 3000);
                    onClose()
                    dispatch(getJobDetailsRawData(page))
                }).catch((error) => {
                    console.log('--- error postEditJob ---', error);
                    let message = "Could not cancell the job"
                    if (error && error.detail) message = error.detail;
                    NotificationManager.error(message, 'ERROR', 3000);
                }).finally(() => {
                    dispatch(setJobLoader(false));
                })
        }
    })
}

export const dAgentCancel = (id, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {page} = store['stats']
    Swal.fire({
        title: 'Are you sure you want to cancell the Job (D-Agent)?',
        text: "This action cannot be reversed",
        html: `
            <textarea id="cancel-reason" autocapitalize="off" class="swal2-textarea" placeholder="Kindly provide the reason" style="display: flex;"></textarea>

            <label id="pay-checkbox" class="swal2-checkbox" style="display: flex;">
                <input class="pay-cancellation" type="checkbox" value="1" id="pay-cancellation" checked>
                <span class="swal2-label">Do you want to pay the cancellation fee?</span>
            </label>
        `,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, Cancell job!',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
            const cancelReason = document.getElementById("cancel-reason").value
            const payCheckbox = document.getElementById('pay-checkbox')
            var checkboxInput = payCheckbox.querySelector("input[type=checkbox]");
            const payCancel = checkboxInput.checked
            if (!cancelReason) {
                Swal.showValidationMessage(`You need to provide the reason!`)
            }
            return { cancelReason, payCancel }
        }
    }).then((result) => {
        console.log("RESULT: ", result);
        const {value} = result
        if (value && value.cancelReason && (value.payCancel === true || value.payCancel === false)) {
            dispatch(setJobLoader(true));
            api.put(`job_posting/${id}/agent_d_cancel_job`, {reason: value.cancelReason, generates_payment: value.payCancel})
                .then(response => {
                    NotificationManager.success('The job was successfully cancelled', 'Success', 3000);
                    onClose()
                    dispatch(getJobDetailsRawData(page))
                }).catch((error) => {
                    console.log('--- error postEditJob ---', error);
                    let message = "Could not cancell the job"
                    if (error && error.detail) message = error.detail;
                    NotificationManager.error(message, 'ERROR', 3000);
                }).finally(() => {
                    dispatch(setJobLoader(false));
                })
        }
    })
}

export const removeNoShowStatus = (id, onClose) => (dispatch, getStore) => {
    const store = getStore()
    const {page} = store['stats']
    Swal.fire({
        title: 'Are you sure you want to remove the no show status?',
        text: "This action cannot be reversed",
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, Remove it!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setJobLoader(true));
            api.put(`job_posting/${id}/remove_no_show_status`, {})
                .then(response => {
                    NotificationManager.success('The change was successfully done', 'Success', 3000)
                    onClose()
                    dispatch(getJobDetailsRawData(page))
                }).catch((error) => {
                    console.log('--- error postEditJob ---', error);
                    let message = "Could not remove the no show status"
                    if (error && error.detail) message = error.detail;
                    NotificationManager.error(message, 'ERROR', 3000);
                }).finally(() => {
                    dispatch(setJobLoader(false));
                })
        }
    })
}

// -----------------------------------------------------
// ------------------   ACTION      --------------------
// -----------------------------------------------------
export const actions = {
    getAllBrokerOption,
    getNameAllJobType,
    getAgentsByOffice,
    getJobPostedStats,
    getReviewDetails,
    setJobTypeFilter,
    getPostsDetails,
    setDefaultValue,
    getDataRevenue,
    getPerformance,
    getAllOffices,
    setFilterBroker,
    goPage,
    getDataBrokerRawData,
    downloadBrokerRawDataReport,
    getJobDetailsRawData,
    setJobStatusFilter,
    downloadJobDetailsRawDataReport,
    getAgentRawData,
    downloadAgentRawDataReport,
    getWalletManagement,
    downloadWalletManagementReport,
    getPayout,
    downloadPayoutReport,
    downloadVisitorReport,
    setTimeFilter,
    readAppVersion,
    submitVersion,
    deleteVersion,
    changeAgentRawFilters,
    changeFilterFieldForm,
    changeJobTransferType,
    setJobSearchFilter,
};


export const reducers = {
    [SET_LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_ITEM]: (state, {item}) => {
        return {
            ...state,
            item,
        };
    },
    [SET_ITEM_PERFORMANCE]: (state, {itemPerformance}) => {
        return {
            ...state,
            itemPerformance,
        };
    },
    [SET_PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [SET_PAGE_2]: (state, {page2}) => {
        return {
            ...state,
            page2,
        };
    },
    [SET_DATA_OFFICES]: (state, {dataOfficeName}) => {
        return {
            ...state,
            dataOfficeName,
        };
    },
    [SET_DATA_OFFICES2]: (state, {dataOfficeName2}) => {
        return {
            ...state,
            dataOfficeName2,
        };
    },
    [SET_DATA_JOB]: (state, {dataJobType}) => {
        return {
            ...state,
            dataJobType,
        };
    },
    [SET_DATA_AGENT]: (state, {dataAgentName}) => {
        return {
            ...state,
            dataAgentName,
        };
    },
    [SET_DATA_AGENT2]: (state, {dataAgentName2}) => {
        return {
            ...state,
            dataAgentName2,
        };
    },
    [SET_JOB_TYPE_ID_STATS]: (state, {value}) => {
        return {
            ...state,
            jobType: value,
        };
    },
    [SET_JOB_OPTION_ID_STATS]: (state, {value}) => {
        return {
            ...state,
            jobOption: value,
        };
    },
    [SET_ITEM_AGENT_DETAIL]: (state, {itemDetails}) => {
        return {
            ...state,
            itemDetails,
        };
    },
    [SET_JOB_POSTED_DATA]: (state, {jobPostedData}) => {
        return {
            ...state,
            jobPostedData,
        };
    },
    [SET_ITEM_POSTED_DETAILS]: (state, {itemPostedDetails}) => {
        return {
            ...state,
            itemPostedDetails,
        };
    },
    [SET_DATA_BROKER_OPTION]: (state, {dataBrokerName}) => {
        return {
            ...state,
            dataBrokerName,
        };
    },
    [SET_DATA_BROKER_OPTION2]: (state, {dataBrokerName2}) => {
        return {
            ...state,
            dataBrokerName2,
        };
    },
    [SET_DATA_STATS]: (state, {dataStats}) => {
        return {
            ...state,
            dataStats,
        };
    },
    [SET_DOWNLOAD_LOADER]: (state, {downloadLoader}) => {
        return {
            ...state,
            downloadLoader,
        };
    },
    [SET_JOB_STATUS]: (state, {jobStatus}) => {
        return {
            ...state,
            jobStatus,
        };
    },
    [VERSION_LOADER]: (state, {versionLoader}) => {
        return {
            ...state,
            versionLoader,
        };
    },
    [JOB_ITEM]: (state, {jobItem}) => {
        return {
            ...state,
            jobItem,
        };
    },
    [JOB_LOADER]: (state, {jobLoader}) => {
        return {
            ...state,
            jobLoader,
        };
    },
    [NO_SHOW_COMPLETE]: (state, {noShowComplete}) => {
        return {
            ...state,
            noShowComplete,
        };
    },
    [SELECT_ADDRESS_COMPLETE]: (state, {selectAddressComplete}) => {
        return {
            ...state,
            selectAddressComplete,
        };
    },
};

export const initialState = {
    // values to filter by job in stats-revenue
    page: 1,
    page2: 1,
    pageJobsPosted: 1,
    jobType: '',
    jobOption: '',
    // values of all data
    loader: false,
    versionLoader: false,
    dataJobType: [],
    dataAgentName: [],
    dataAgentName2: [],
    dataBrokerName: [],
    dataBrokerName2: [],
    dataOfficeName: [],
    dataOfficeName2: [],
    item: {
        results: [],
        count: 0,
        date: [moment(Date.now()).format('YYYY-MMM-DD'), moment(Date.now()).format('YYYY-MMM-DD')]
    },
    itemPerformance: {
        results: [],
        count: 0,
    },
    jobPostedData: {
        results: [],
        count: 0,
    },
    itemDetails: {
        results: [],
        count: 0,
    },
    itemPostedDetails: {
        results: [],
        count: 0,
    },
    dataStats: {
        results: [],
        count: 0,
    },
    jobStatus: '',
    downloadLoader: false,
    jobItem: null,
    jobLoader: false,
    noShowComplete: false,
    selectAddressComplete: false,
};

export default handleActions(reducers, initialState);

