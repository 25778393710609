import Swal from "sweetalert2";
import React, { Component } from 'react'
import WholeSalerFiltersForm from './WholeSalerFilters';
import WholeSalerGrid from './WholeSalerGrid';


class WholeSalerList extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount() {
        this.props.toList();
    }

    blockUserModal = (item) => {
        Swal.fire({
            title: '',
            text: '',
            html: `<p style="font-size: 20px; font-weight: bold"> Are you sure to lock agent ${item.first_name}?</p>`,
            type: 'warning',
            reverseButtons: true,
            customClass:'text-12',
            showCancelButton: true,
            cancelButtonColor: '#AA1111',
            confirmButtonColor: '#007bff',
            confirmButtonText: 'Yes, lock user!',
        })
            .then((result) => {
                if (result.value) {
                    this.props.blockUserTypeWholeSale(item.id);
                }
            });
    };

    unblockUserModal = (item) => {
        Swal.fire({
            title: '',
            text: '',
            html: `<p style="font-size: 20px; font-weight: bold"> Are you sure to unlock agent ${item.first_name}?</p>`,
            type: 'warning',
            reverseButtons: true,
            customClass:'text-12',
            showCancelButton: true,
            cancelButtonColor: '#AA1111',
            confirmButtonColor: '#007bff',
            confirmButtonText: 'Yes, unlock user!',
        })
            .then((result) => {
                if (result.value) {
                    this.props.unblockUserTypeWholeSale(item.id);
                }
            });
    };

    render() {
        // State
        const {loader, page, data} = this.props;
        //  Bind
        const {toList, searchChange, removeWholeSaler, onSortChange, goPage} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">WholeSalers</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <WholeSalerFiltersForm searchAction={searchChange} />
                </div>

                <WholeSalerGrid
                    data={data}
                    page={page}
                    loader={loader}
                    toList={toList}
                    onSortChange={onSortChange}
                    remove={removeWholeSaler}
                    goPage={goPage}
                    blockUserModal={this.blockUserModal}
                    unblockUser={this.unblockUserModal}
                />
            </div>
        );
    }
}

export default WholeSalerList