// import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import SingUpFormStep1 from "./Forms/SignUpFormStep1";
import SingUpFormStep2 from "./Forms/SignUpFormStep2";
import SingUpFormStep3 from "./Forms/SignUpFormStep3";
import StepFinish from "./Forms/StepFinish";
import StepProgressBar from "./Forms/StepProgessBar";
import "react-step-progress-bar/styles.css";


class Register extends Component {

    actionOnSubmit = () => {
        const {
            onSubmit,
        } = this.props;
        onSubmit();
    };

    // componentWillMount() {
    // const { getRoles } = this.props;
    // getRoles();
    // }

    render() {
        const {
            loader,
            onSubmitStep,
            step,
            success,
            roles,
        } = this.props;

        if (localStorage.getItem('token')) {
            return (<Redirect to="/"/>);
        }
        return (
            <div className="body-img">
                <div className="blue-gradient-bg">
                    <div className="login-wrapper  h-100" style={{ minWidth: '60%' }}>
                        <div className="card card-login col-sm-7 col-7 px-2" style={{ minHeight: '40%' }}>
                            <div className="container-logo-register justify-content-center  align-self-center">
                                <img src={require("../../../../../assets/img/logo-01.png")} className="logo-form"
                                     alt={"showIt logo"}/>
                            </div>
                            <h5 className="text-center mt-2 " style={{
                                font: "normal normal 600 20px/19px Poppins-SemiBold",
                                letterSpacing: 0,
                                color: "#414141",
                                opacity: 1,
                            }}
                            >BROKER</h5>

                            <LoadMask loading={loader} light>
                                <div className="mt-0 mb-2 mx-auto">
                                    {!success && <StepProgressBar progress={step}/>}
                                    {step === 33 && <SingUpFormStep1 onSubmit={() => onSubmitStep()}/>}
                                    {step === 66 && <SingUpFormStep2 roles={roles}  onSubmit={() => onSubmitStep()}/>}
                                    {!success && step === 99 && <SingUpFormStep3 onSubmit={this.actionOnSubmit}/>}
                                    {success && <StepFinish/>}
                                </div>
                            </LoadMask>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
