import Swal from 'sweetalert2';
import React, {Component} from 'react';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CreateAgentRosterForm from './CreateAgentRosterForm'

class CreateAgentRoster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false
        };
    }

    componentWillMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
            this.setState({isEdit: true})
        }
    }

    submitAction = (data) => {
        const {match} = this.props;
        if (match.params.brokerage_id) {
            this.props.newAgentRoster(data, match.params.brokerage_id);
        }
    }

    submitEditAction = (data) => {
        this.props.update(data);
    }

    remove = () => {
        const {match, read} = this.props;
        if (match.params.id) {
            Swal.fire({
                title: 'Are you sure to delete the record?',
                text: "This action can't be reverted!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#7E8A97',
                cancelButtonColor: '#B9BDC1',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.removeAgentRoster(match.params.id)
                }
            });
        }
    }

    render() {
        //  State
        const {loader} = this.props;
        const {isEdit} = this.state;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="w-100 pr-4">
                        <div className="header-page d-flex justify-content-between">
                            <div>{isEdit ? "Edit" : "Create"} Agent Roster</div>
                        </div>
                    </div>
                </div>
                <div
                    className="card general-container border-dark border-card-page px-4 py-2"
                    style={{
                        minWidth: "99%",
                        minHeight: "15%",
                    }}
                >
                    <LoadMask loading={loader} light>
                        <CreateAgentRosterForm
                            onSubmit={
                                isEdit
                                    ? this.submitEditAction
                                    : this.submitAction
                            }
                            remove={isEdit ? this.remove : null}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CreateAgentRoster;
