import React from "react";
import { Field, reduxForm } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    customFieldFile,
    renderField,
} from "../../Utils/renderField";
import {
    validate,
    validatorFromFunction,
    validators,
} from "validate-redux-form";

const influencerTeamForm = (props) => {
    const {
        handleSubmit,
        text,
        handleOnChange,
        active,
        setActive,
        loader2,
        closeModal,
        urlTemplate,
    } = props;

    return (
        <form
            name="brokerageForm"
            className="pt-2 flex col-sm-12 col-lg-8 mx-auto"
            onSubmit={handleSubmit}
            style={{ minHeight: "36rem" }}
        >
            <LoadMask loading={loader2} light>
                <div className="row">
                    <div className="form-group has-feedback col-lg-12 col-sm-12">
                        <label htmlFor="name">Name</label>
                        <Field
                            name="name"
                            label="Name"
                            placeholder={""}
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-lg-12 col-sm-12">
                        <label htmlFor="total_agents"># of agents in your team</label>
                        <Field
                            type="text"
                            name="total_agents"
                            label="# of agents in your team"
                            component={renderField}
                            placeholder={""}
                            className="form-control"
                        />
                    </div>
                </div>

                <React.Fragment>
                    <p className="text-12 mt-1 mb-3 text-justify">
                        In order to give your agents access to all the
                        perks on ShowIT Solutions™ platform, you will
                        need to upload your agents roster. To simplify
                        the process, please download agents roster
                        template.
                    </p>
                    {/*-------------------------------------------------------------------------------*/}
                    {/*-----------------        DOWNLOAD AGENT TEMPLATE            -------------------*/}
                    {/*-------------------------------------------------------------------------------*/}
                    <div className="flex-row text-center">
                        <div className="col-md-6 mx-auto">
                            <label className="ml-1 text-12">
                                Agent Roster Template
                            </label>
                            <a
                                href={urlTemplate}
                                download="template.xlsx"
                                className="btn btn-success text-white col-lg-8"
                            >
                                Download
                            </a>
                        </div>
                    </div>
                    {/*<div className="row w-75 px-5 mx-auto flex-1">*/}
                    {/*-------------------------------------------------------------------------------*/}
                    {/*-----------------            UPLOAD AGENT ROSTER            -------------------*/}
                    {/*-------------------------------------------------------------------------------*/}
                    <div className="flex-row text-center mt-2">
                        <div className="col-md-6 mx-auto">
                            <label className="text-12">
                                Import your agent list
                            </label>
                            {/*----------------------------------------*/}
                            {/*------------   if is create  -----------*/}
                            {/*----------------------------------------*/}
                            <Field
                                id={"office_excel"}
                                name="office_excel"
                                setFile={handleOnChange}
                                component={customFieldFile}
                                title={"Import"}
                                text={text}
                                customClass="btn-primary col-lg-8 col-sm-8 py-1 mx-auto"
                                type={"file"}
                                active={active}
                                accept={"*"}
                                remove={() => setActive(true)}
                            />
                        </div>
                    </div>
                </React.Fragment>

                {/*-------------------------------------------------------------------------------*/}
                {/*-----------------        ACTIONS CREATE OR UPDATE           -------------------*/}
                {/*-------------------------------------------------------------------------------*/}
                <div className="w-100 my-4 mx-auto text-center">
                    <label
                        className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                        onClick={closeModal}
                    >
                        Cancel
                    </label>
                    <button
                        type="submit"
                        className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                        disabled={loader2}
                    >
                        Create
                    </button>
                </div>
            </LoadMask>
        </form>
    );
};

const numberPhone = (number) =>
    validatorFromFunction(() => {
        return number.length === 10;
    });

export default reduxForm({
    form: "influencerTeamForm", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            name: validators.exists()("This field is required"),
        });
    },
})(influencerTeamForm);
