import React, {Component} from 'react';
import AgentPerformanceFilterForm from './Filters';
import {connect} from "react-redux";
import PerformanceGrid from "./PerformanceGrid";
import {formValueSelector, reduxForm} from "redux-form";


class Performance extends Component {
    componentDidMount() {
        const {getFranchisesOption, getBrokerOption} = this.props;

        getFranchisesOption(null, true)
        getBrokerOption(true, true)
    }

    getDataAction = (page = 1) => {
        this.props.getPerformance(
            page,
            this.props.brokerIdInPerformance,
            this.props.office,
            this.props.agent,
            this.props.startDate,
            this.props.endDate,
            this.props.rating
        )
    }

    changeDataFilter = (selected)=>{
        console.log('click: ', selected)
        this.props.getAgentsByOffice('', selected.value);
        this.props.getAllOffices(selected.value);
    }

    render() {
        const {
            loader,
            data,
            franchises,
            brokers,
            brokerages,
            toList,
            changeFilters,
        } = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Stats / Agent Performance</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <AgentPerformanceFilterForm
                        optionFranchises={franchises}
                        optionBrokers={brokers}
                        optionBrokerages={brokerages}
                        changeFilters={changeFilters}
                    />
                </div>
                {/* <div className="card border-card-page px-0 py-2">
                    <Filters
                        dataBroker={dataBrokerName}
                        changeDataFilter={this.changeDataFilter}
                        dataAgentName={dataAgentName}
                        dataOfficeName={dataOfficeName}
                        applyAction={this.getDataAction}
                        getAgents={getAgentsByOffice}
                    />
                </div> */}
                <PerformanceGrid
                    loader={loader}
                    data={data}
                    nextPage={toList}
                />
            </div>
        );
    }
}

const selector = formValueSelector('PerformanceForm');

Performance = reduxForm({
    form: 'PerformanceForm', // a unique identifier for this form
})(Performance);

const mstp = state => ({
    brokerIdInPerformance: selector(state, 'brokerIdPerformance'),
    startDate: selector(state, 'start_date'),
    endDate: selector(state, 'end_date'),
    rating: selector(state, 'rating'),
    office: selector(state, 'office'),
    agent: selector(state, 'agent'),
});

export default connect(mstp, null)(Performance)
