import React, { Component } from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import OpenHouseTextForm from './OpenHouseTextForm';

export default class OpenHouseText extends Component {
    state = {
        proTipIcon: null,
        proTipDownloadIcon: null,
        encourageIcon: null,
    }
    componentDidMount() {
        this.props.getSettings('openHouseTextForm')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    setProTipIcon = (icon) => {
        this.setState({proTipIcon: icon})
    }
    setProTipDownloadIcon = (icon) => {
        this.setState({proTipDownloadIcon: icon})
    }
    setEncourageIcon = (icon) => {
        this.setState({encourageIcon: icon})
    }

    resetIcons = () => {
        this.setState({
            proTipIcon: null,
            proTipDownloadIcon: null,
            encourageIcon: null,
        })
    }

    submit = (data) => {
        const {proTipIcon, proTipDownloadIcon, encourageIcon} = this.state
        const {onSubmitOpenHouseText} = this.props
        //  Icons
        const icons = []
        if (proTipIcon) {
            icons.push({file: proTipIcon, name: 'open_house_pro_tip_icon'})
            
        }
        if (proTipDownloadIcon) {
            icons.push({file: proTipDownloadIcon, name: 'open_house_pro_tip_download_icon'})
        }
        if (encourageIcon) {
            icons.push({file: encourageIcon, name: 'open_house_encourage_icon'})
        }

        //  Update
        onSubmitOpenHouseText({...data}, icons, () => {
            this.resetIcons()
        })
    }

    render() {
        const {loader, onSubmitOpenHouseText} = this.props

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Open House Texts</div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <OpenHouseTextForm
                            onSubmit={(data) => {
                                this.submit(data)
                            }}
                            goBack={this.goBack}
                            loader={loader}
                            setProTipIcon={this.setProTipIcon}
                            setProTipDownloadIcon={this.setProTipDownloadIcon}
                            setEncourageIcon={this.setEncourageIcon}
                            />
                    </LoadMask>
                </div>
            </div>
        )
    }
}