import React, {Component} from 'react'
import LoadMask from "../Utils/LoadMask/LoadMask";
import AdminForm from "./CreateAdminForm"


class CreateAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
            real_id: null,
            showPassword: false,
        };
    }

    componentWillMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
            this.setState({real_id: match.params.id});
        }
        if (match.path === '/manager/:id/') {
            this.setState({is_view: true, is_update: false});

        }
        if (match.path === '/manager/:id/edit') {
            this.setState({is_view: false, is_update: true});
            read(match.params.id)
        }
    }

    componentWillUnMount() {
        this.setState({real_id: null, is_update: false, is_view: false});
    }

    updateForm = (data) => {
        const { customEdit } = this.props;
        customEdit(data.id, data);
    };

    showPass = (value) => {
        this.setState({showPassword: value})
    }

    render() {
        const {match, createAdmin, loader } = this.props;
        const sendFunction = match.params.id
            ? this.updateForm
            : createAdmin;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {this.state.is_view && "View Manager"}
                            {this.state.is_update && "Update Manager"}
                            {!this.state.is_update && !this.state.is_view  &&" create manager"}
                        </div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4">
                    <LoadMask loading={loader} dark blur>
                        <AdminForm
                            onSubmit={sendFunction}
                            is_view={this.state.is_view}
                            real_id={this.state.real_id}
                            showPassAction={this.showPass}
                            is_update={this.state.is_update}
                            updatePass={this.props.updatePass}
                            showPassword={this.state.showPassword}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default CreateAdmin
