import _ from "lodash";
import { api } from "api";
import moment from "moment";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = 'refer'

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "referralRawData",
    endpoint,
    "referralRawDataForm",
    "/stats/referral-raw-data"
);

// ------------------------------------
// Pure Actions
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

basicReducer.actions.toList = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()["referralRawData"];
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/detail_raw_data`, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

const searchChange = search => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    dispatch(basicReducer.actions.toList());
};

export const actions = {
    ...basicReducer.actions,
    searchChange,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
}

export default handleActions(reducers, initialState);
