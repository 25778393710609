import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const SET_LOGO = 'SET_LOGO_FRACHISE';
const endpoint  = "franchises";

export const baseReducer = createReducer(
    "franchises",
    endpoint,
    "franchiseFrom",
    "/franchises",
);

const setLogo = logo => ({
    type: SET_LOGO,
    logo,
});


const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["franchises"];
    const {FranchisesFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (FranchisesFilterForm && FranchisesFilterForm.values) {
        const {search} = FranchisesFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }

    dispatch(baseReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(baseReducer.actions.setData(response));
        dispatch(baseReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

const create = (data = {}) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    delete data.logo;
    const logo = getStore().franchises.logo;
    if (logo.length !== 0) {
        api.postAttachments(endpoint, data,
                [{name: 'logo', file: logo.logo}])
            .then((response) => {
                NotificationManager.success('Record created', 'SUCCESS', 3000);
                dispatch(setLogoFile([]));
                dispatch(push('/franchises'));
            })
            .catch((error) => {
                console.log("ERROR", error);
                NotificationManager.error('Create failed', 'ERROR');
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }
};

const setLogoFile = (data) => dispatch => {
    dispatch(setLogo(data))
}

const edit = (id, data = {}) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    delete data.logo;
    const logo = getStore().franchises.logo;
    if (logo.length !== 0) {
        api.putAttachments(`${endpoint}/${id}`, data,
                [{name: 'logo', file: logo.logo}])
            .then((response) => {
                NotificationManager.success('Record updated', 'SUCCESS', 3000);
                dispatch(setLogoFile([]));
                dispatch(push('/franchises'));
            })
            .catch((error) => {
                console.log("ERROR", error);
                NotificationManager.error('Edition failed', 'ERROR');
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }else {
        api.put(`${endpoint}/${id}`, data).then(() => {
            NotificationManager.success('Record updated', 'Success', 3000);
            dispatch(push('/franchises'));
        }).catch(() => {
            NotificationManager.error('Edition failed', 'ERROR', 0);
        }).finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
    }
};

export const actions = {
    ...baseReducer.actions,
    toList,
    searchChange,
    setLogoFile,
    create,
    edit
}

export const reducers = {
    [SET_LOGO]: (state, logo) => {
        return {
            ...state,
            logo,
        };
    },
    ...baseReducer.reducers,
}

export const initialState = {
    logo: [],
    ...baseReducer.initialState,
}



export default handleActions(reducers, initialState);
