import React from "react";
import { Link } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import {
    renderField,
    SelectField,
    renderFieldCheck,
} from "../../Utils/renderField/renderField";
import { CLIENT_ANSWER_TYPE } from "../../../../utility/constants";

const validate = (values, props) => {
    const errors = {};
    if (!values.name) {
        errors.name = "This field is required";
    }
    if (values.feedback_question && values.feedback_question.length) {
        const feedbackQuestion = [];
        values.feedback_question.forEach((question, indexB) => {
            feedbackQuestion[indexB] = {};
            if (!question.question) {
                feedbackQuestion[indexB] = {
                    question: "This field is required",
                };
            }
            if (!question.question_type) {
                feedbackQuestion[indexB] = {
                    ...feedbackQuestion[indexB],
                    question_type: "This field is required",
                };
            }
            if (!feedbackQuestion[indexB]) {
                feedbackQuestion[indexB] = undefined;
            }
        });
        errors.feedback_question = feedbackQuestion;
    } else {
        errors.feedback_question = "At least one question is required";
    }
    return errors;
};

const optionList = ({
    seeView,
    fields,
    meta: { error, touched },
    loadOptions,
}) => (
    <React.Fragment>
        {fields.map((item, index) => (
            <div
                key={index}
                className="form-group has-feedback col-12 mt-auto py-2 border"
            >
                <div className="row">
                    <div className="form-group has-feedback col-md-8 mt-auto">
                        <label htmlFor="question">Question {index + 1}</label>
                        <Field
                            name={`${item}.question`}
                            placeholder="Question"
                            component={renderField}
                            disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-4 mt-auto">
                        <label htmlFor="question_type">Type</label>
                        <Field
                            name={`${item}.answer_type`}
                            placeholder="Type"
                            options={CLIENT_ANSWER_TYPE}
                            component={SelectField}
                            disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-4 mt-auto">
                        <label htmlFor="type">Is Required</label>
                        <Field
                            name={`${item}.is_required`}
                            disabled={seeView}
                            component={renderFieldCheck}
                            type="checkbox"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-8 mt-auto">
                        {!seeView && (
                            <img
                                src={require("../../../../../assets/img/delete.svg")}
                                alt="del"
                                onClick={() => fields.remove(index)}
                                className="ml-1 pointer"
                            />
                        )}
                    </div>
                </div>
            </div>
        ))}
        {!seeView && (
            <div className="col-12">
                <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => fields.push({ is_required: true })}
                >
                    Add Option
                </button>
            </div>
        )}
        {error && <div className="invalid-feedback">{error}</div>}
    </React.Fragment>
);

const FeedbackFormClientForm = (props) => {
    //  State
    const {loader, is_update} = props;
    //  Bind
    const { handleSubmit } = props;
    return (
        <div
            className="card general-container border-dark border-card-page px-4 py-4"
            // style={{
            //     minWidth: "99%",
            //     minHeight: "15%",
            // }}
        >
            <form
                action=""
                onSubmit={handleSubmit}
                className="row w-75 mx-auto justify-content-center mt-5"
            >
                <div className="row w-100">
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="name">Name</label>
                        <Field
                            name="name"
                            placeholder="Name"
                            component={renderField}
                            // disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-md-12 mt-auto">
                        <label htmlFor="feedback_question">Question List</label>
                        <div className="row">
                            <FieldArray
                                name="feedback_question"
                                component={optionList}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex mb-4">
                    <Link
                        className="btn button-03 ml-auto mr-1"
                        to="/feedback-form-client"
                    >
                        Cancel
                    </Link>

                    <button
                        type="submit"
                        className="btn btn-primary mr-auto ml-1"
                        disabled={loader}
                    >
                        {is_update ? 'Update' : 'Save'}
                    </button>
                </div>
            </form>
        </div>
    );
};

let FeedbackFormReduxClientForm = reduxForm({
    form: "feedbackFormClientForm", // a unique identifier for this form
    validate,
})(FeedbackFormClientForm);

export default FeedbackFormReduxClientForm;
