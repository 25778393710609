import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";
import {SELECT_ALL_OPTION} from '../../../utility/constants'

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "app_promotion"
const SET_BROKERS = "SET_BROKERS_PROMOTION";
const SET_BROKERAGES = "SET_BROKERAGES_PROMOTION";
const SET_TEAMS = "SET_TEAMS_PROMOTION";
const SET_AGENTS_DATA = "SET_AGENTS_DATA_PROMOTION";
const SET_AGENTS_PAGE = "SET_AGENTS_PAGE_PROMOTION";

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "promotions",
    endpoint,
    "promotionFrom",
    "/promotions",
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});
const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});
const setAgentsData = (agentsData) => ({
    type: SET_AGENTS_DATA,
    agentsData,
});
const setTeams = (teams) => ({
    type: SET_TEAMS,
    teams,
});
const setAgentsPage = (agentsPage) => ({
    type: SET_AGENTS_PAGE,
    agentsPage,
});

// ------------------------------------
// Actions
// ------------------------------------
const getBrokerOption = (state=null, getAgents=null, selectAll=false) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    const store = getStore()
    let params = {state: ''};
    const { promotionFrom } = store.form;
    let brokerValue = null;
    if (promotionFrom && promotionFrom.values) {
        const { broker_id } = promotionFrom.values;
        if (broker_id !== "" && broker_id !== null && broker_id !== undefined && broker_id !== SELECT_ALL_OPTION["value"]) {
            if (broker_id.length == 0) {
                brokerValue = SELECT_ALL_OPTION["value"]
            }else {
                brokerValue = broker_id
            }
        }
    }

    api.get('campaign/option_broker', params)
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                if (brokerValue !== null ) {
                    dispatch(
                        change("promotionFrom", "broker_id", brokerValue)
                    );
                }
                if (selectAll && brokerValue == null) {
                    dispatch(change('promotionFrom', 'broker_id', SELECT_ALL_OPTION["value"]))
                }
                dispatch(getBrokeragesOption(brokerValue, getAgents, selectAll))
            }else {
                option = [...response.results];
                const broker_id = option.length ? option[0].value : SELECT_ALL_OPTION["value"]
                dispatch(change('promotionFrom', 'broker_id', [broker_id]))
                dispatch(getBrokeragesOption(broker_id, getAgents, selectAll))
            }
            console.log("BROKER OPTION: ", option);
            dispatch(setBrokers(option));
        })
        .catch((err) => {
            console.log("BROKER ERR: ", err);
            dispatch(setBrokers([]))
            dispatch(change('promotionFrom', 'broker_id', SELECT_ALL_OPTION["value"]))
            dispatch(getBrokeragesOption(null, getAgents, selectAll))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
            if (getAgents) {
                setTimeout(() => {
                    dispatch(getDataAgents())
                }, 500)
            }
        });
};

const getBrokeragesOption = (broker_id=null, getAgents=null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const {promotionFrom} = store.form;
    dispatch(basicReducer.actions.setLoader(true));
    let params = {broker_id: '', state: ''};
    let brokerageValue = null;
    if (promotionFrom && promotionFrom.values) {
        const { state, broker_id, brokerage_id } = promotionFrom.values;
        if (broker_id !== "" && broker_id !== null && broker_id !== undefined && broker_id !== SELECT_ALL_OPTION["value"]) {
            if (broker_id.length == 0) {
                params.broker_id = ""
            }else { 
                console.log("AAA 2: ", broker_id);
                params.broker_id = broker_id;
            }
        }

        if (brokerage_id !== "" && brokerage_id !== null && brokerage_id !== undefined && brokerage_id !== SELECT_ALL_OPTION["value"]) {
            if (brokerage_id.length == 0) {
                brokerageValue = SELECT_ALL_OPTION["value"]
            }else {
                brokerageValue = brokerage_id
            }
        }
    }

    api.get('campaign/option_brokerage', params)
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                if (selectAll && brokerageValue == null) {
                    dispatch(
                        change(
                            "promotionFrom",
                            "brokerage_id",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
                if(brokerageValue !== null) {
                    dispatch(
                        change(
                            "promotionFrom",
                            "brokerage_id",
                            brokerageValue
                        )
                    );
                }
            }else {
                option = [...response.results];
                dispatch(change('promotionFrom', 'brokerage_id', option.length ? [option[0].value] : SELECT_ALL_OPTION["value"]))
            }
            dispatch(setBrokerages(option));
        })
        .catch(() => {
            dispatch(setBrokerages([]))
            dispatch(change('promotionFrom', 'brokerage_id', SELECT_ALL_OPTION["value"]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
            if (getAgents) {
                setTimeout(() => {
                    dispatch(getDataAgents())
                }, 500)
            }
        });
};

const getTeamsOption = (getAgents=null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const {promotionFrom} = store.form;
    dispatch(basicReducer.actions.setLoader(true));
    let teamValue = null;

    if (promotionFrom && promotionFrom.values) {
        const { team_id } = promotionFrom.values;

        if (team_id !== "" && team_id !== null && team_id !== undefined && team_id !== SELECT_ALL_OPTION["value"]) {
            if (team_id.length == 0) {
                teamValue = SELECT_ALL_OPTION["value"]
            }else {
                teamValue = team_id
            }
        }
    }

    api.get('general/option_team')
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                console.log("SELECT: ", selectAll);
                if (selectAll && teamValue == null) {
                    dispatch(
                        change(
                            "promotionFrom",
                            "team_id",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
                if(teamValue !== null) {
                    dispatch(
                        change(
                            "promotionFrom",
                            "team_id",
                            teamValue
                        )
                    );
                }
            }else {
                option = [...response.results];
                dispatch(change('promotionFrom', 'team_id', option.length ? [option[0].value] : SELECT_ALL_OPTION["value"]))
            }
            dispatch(setTeams(option));
        })
        .catch(() => {
            dispatch(setTeams([]))
            dispatch(change('promotionFrom', 'team_id', SELECT_ALL_OPTION["value"]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
            if (getAgents) {
                setTimeout(() => {
                    dispatch(getDataAgents())
                }, 500)
            }
        });
}

const getDataAgents = (page = 1, pageType=null) => (dispatch, getStore) => {
    const store = getStore();
    const {promotionFrom} = store.form;
    let params = {
        account_status: 5,
        broker_id: "",
        state: "",
        brokerage_id: "",
        team_id: "",
        page
    };
    if (promotionFrom && promotionFrom.values) {
        const {state, broker_id, brokerage_id, team_id} = promotionFrom.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = null
            }else {
                params.state = state;
            }
        }
        if (broker_id !== "" && broker_id !== null && broker_id !== undefined && broker_id !== SELECT_ALL_OPTION["value"]) {
            if (broker_id.length == 0) {
                params.broker_id = null
            }else {
                params.broker_id = broker_id;
            }
        }
        if (brokerage_id !== "" && brokerage_id !== null && brokerage_id !== undefined && brokerage_id !== SELECT_ALL_OPTION["value"]) {
            if (brokerage_id.length == 0) {
                params.brokerage_id = null
            }else {
                params.brokerage_id = brokerage_id;
            }
        }
        if (team_id !== "" && team_id !== null && team_id !== undefined && team_id !== SELECT_ALL_OPTION["value"]) {
            if (team_id.length == 0) {
                params.team_id = null
            }else {
                params.team_id = team_id;
            }
        }
    }

    dispatch(getFilterAgents(page, params));
};

const getFilterAgents = (page, params) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`general/filter_agents`, params)
        .then(response => {
            if (response) {
                dispatch(setAgentsData(response))
                dispatch(setAgentsPage(page))
            }
        })
        .catch((error) => {
            dispatch(setAgentsData({
                results: [],
                count: 0,
            }))
            let message = error ? error.detail : 'It was not possible to obtain the list of agents'
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const changeFilters = (type, value=null) => (dispatch) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'status':
            setTimeout(() => {
                dispatch(getDataAgents())
            }, 500)
            break;
        case 'state':
            dispatch(getBrokerOption(value, true))
            break;
        case 'broker':
            dispatch(getBrokeragesOption(value, true))
            break;
        case 'brokerage':
            setTimeout(() => {
                dispatch(getDataAgents())
            }, 500)
            break;
        case 'team':
            setTimeout(() => {
                dispatch(getDataAgents())
            }, 500)
            break;
        default:
            break;
    }
}

const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["campaigns"];
    const {promotionFiltersForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (promotionFiltersForm && promotionFiltersForm.values) {
        const {search} = promotionFiltersForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }

    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

const createPromotion = (data, photos) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    let _data = { ...data };
    _data.broker_id =
        _data.broker_id != undefined &&
        _data.broker_id != SELECT_ALL_OPTION["value"] &&
        _data.broker_id != ""
            ? _data.broker_id
            : null;
    _data.brokerage_id =
        _data.brokerage_id != undefined &&
        _data.brokerage_id != SELECT_ALL_OPTION["value"] &&
        _data.brokerage_id != ""
            ? _data.brokerage_id
            : null;
    _data.state =
        _data.state != undefined &&
        _data.state != SELECT_ALL_OPTION["value"] &&
        _data.state != ""
            ? _data.state
            : null;
    _data.team_id =
        _data.team_id != undefined &&
        _data.team_id != SELECT_ALL_OPTION["value"] &&
        _data.team_id != ""
            ? _data.team_id
            : null;
    if (photos.length) {
        api.postAttachments(endpoint, _data, photos)
            .then((response) => {
                if (response) {
                    NotificationManager.success(
                        "Promotion successfully registered",
                        "Success",
                        3000
                    );
                    dispatch(push("/promotions"));
                }
            })
            .catch((error) => {
                let message = error
                    ? error.detail
                    : "It was not possible to create the promotion, try again later";
                NotificationManager.error(message, "ERROR", 3000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    } else {
        NotificationManager.error("Image is required", "ERROR", 3000);
    }
};

const readPromotion = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
            let data = response;
            data.state = data.states != null && data.states != '' ? data.states : SELECT_ALL_OPTION["value"]
            data.agent_status = data.account_statuses != null && data.account_statuses != '' ? data.account_statuses : SELECT_ALL_OPTION["value"]
            data.broker_id = data.brokers_id != null && data.brokers_id != '' ? data.brokers_id : SELECT_ALL_OPTION["value"]
            data.brokerage_id = data.offices_id != null && data.offices_id != '' ? data.offices_id : SELECT_ALL_OPTION["value"]
            data.team_id = data.teams_id != null && data.teams_id != '' ? data.teams_id : SELECT_ALL_OPTION["value"]
            dispatch(initializeForm('promotionFrom', data));
            setTimeout(() => {
                dispatch(getBrokerOption(response.state, true));
                dispatch(getTeamsOption())
            }, 600);
        })
        .catch((error) => {
            console.error(error);
            dispatch(basicReducer.actions.setItem({}));
            NotificationManager.error('Data could not be obtained', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const updatePromotion = (id, data, photos=[]) => (dispatch, getStore) => {
    const source = getStore()['campaigns'];
    dispatch(basicReducer.actions.setLoader(true));
    let _data = {...data}
    _data.agent_status = _data.agent_status != undefined && _data.agent_status != SELECT_ALL_OPTION["value"] && _data.agent_status != '' ? _data.agent_status : null
    _data.broker_id = _data.broker_id != undefined && _data.broker_id != SELECT_ALL_OPTION["value"] && _data.broker_id != '' ? _data.broker_id : null
    _data.brokerage_id = _data.brokerage_id != undefined && _data.brokerage_id != SELECT_ALL_OPTION["value"] && _data.brokerage_id != '' ? _data.brokerage_id : null
    _data.state = _data.state != undefined && _data.state != SELECT_ALL_OPTION["value"] && _data.state != '' ? _data.state : null
    _data.team_id = _data.team_id != undefined && _data.team_id != SELECT_ALL_OPTION["value"] && _data.team_id != '' ? _data.team_id : null
    if (photos.length) {
        api.putAttachments(`${endpoint}/${id}`, _data, photos)
            .then((response) => {
                if (response) {
                    NotificationManager.success('Promotion successfully updated', 'Success', 3000);
                    dispatch(push("/promotions"));
                }
            })
            .catch((error) => {
                let message = error ? error.detail : 'It was not possible to update the promotion, try again later'
                NotificationManager.error(message, 'ERROR', 3000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    }else {
        api.put(`${endpoint}/${id}`, _data).then(() => {
            NotificationManager.success('Promotion successfully updated', 'Success', 3000);
            dispatch(push('/promotions'));
        }).catch((error) => {
            let message = error ? error.detail : 'It was not possible to update the promotion, try again later'
            NotificationManager.error(message, 'ERROR', 3000);
        }).finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
    }
}

export const actions = {
    ...basicReducer.actions,
    getBrokerOption,
    getBrokeragesOption,
    getTeamsOption,
    getDataAgents,
    changeFilters,
    toList,
    searchChange,
    createPromotion,
    readPromotion,
    updatePromotion,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_BROKERS]: (state, {brokers}) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, {brokerages}) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_TEAMS]: (state, {teams}) => {
        return {
            ...state,
            teams,
        };
    },
    [SET_AGENTS_DATA]: (state, {agentsData}) => {
        return {
            ...state,
            agentsData,
        };
    },
    [SET_AGENTS_PAGE]: (state, {agentsPage}) => {
        return {
            ...state,
            agentsPage,
        };
    }
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    brokers: [],
    brokerages: [],
    teams: [],
    agentsData: {
        results: [],
        count: 0,
    },
    agentsPage: 1,
    ...basicReducer.initialState,
};

export default handleActions(reducers, initialState);
