import _ from "lodash"
import {api} from "api";
import moment from "moment";
import { handleActions } from "redux-actions";
import {NotificationManager} from "react-notifications";
import { SELECT_ALL_OPTION, getDashboardDateRange } from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------

const LOADER = 'LOADER_VENDOR_RAW_DATA';
const DATA = 'DATA_VENDOR_RAW_DATA';
const PAGE = 'PAGE_VENDOR_RAW_DATA';
const DOWNLOAD_LOADER = 'DOWNLOAD_LOADER_VENDOR_RAW_DATA';


// ------------------------------------
// Actions
// ------------------------------------
const setData = (type, payload) => ({
    type,
    payload
})


const getList = (page = 1, loader=true, withTime = true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setData(LOADER, true))
    }
    const store = getStore();
    const source = store.vendorRawData;
    let params = {page}
    // filter
    const {vendorRawDataForm} = store.form;
    if (vendorRawDataForm && vendorRawDataForm.values) {
        const {time, start_date, end_date, jobStatus, search} = vendorRawDataForm.values;
        if (jobStatus !== "" && jobStatus !== null && jobStatus !== undefined && jobStatus !== SELECT_ALL_OPTION["value"]) {
            if (jobStatus.length == 0) {
                params.job_status = "null"
        
            } else {
                params.job_status = jobStatus
            }

        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined) {
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined) {
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            } else {
                params.time = time;
            }
        } else {
            params.time = withTime ? getDashboardDateRange(2, "weeks") : '';
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
    } else {
        params.time = getDashboardDateRange(2, "weeks");
    }

    api.get('vendor_job_posting/job_details_raw_data', params).then((response) => {
        dispatch(setData(DATA, response))
        dispatch(setData(PAGE, page))
    }).catch((error) => {
        console.log('--- error getDataJobDetailsRawData ---', error);
        let message = "Error obtaining data"
        if (error.detail) message = error.detail;
        NotificationManager.error(message, 'ERROR', 3000);
    }).finally(() => {
        if (loader) {
            dispatch(setData(LOADER, false))
        }
    })
}

const setJobStatusFilter = (selected) => (dispatch) =>  {
    dispatch(getList())
    console.log("Selected: ", selected);
}

const setSearchFilter = (selected) => (dispatch) =>  {
    setTimeout(() => {
        dispatch(getList())
    }, 400);
}

const setTimeFilter = (value) => (dispatch) =>  {
    setTimeout(() => {
        dispatch(getList())
    }, 400);
}

const downloadReport = () => (dispatch, getStore) => {
    dispatch(setData(DOWNLOAD_LOADER, true))
    const store = getStore()
    let params = {type_request: 'report'}
    // filter
    const {vendorRawDataForm} = store.form;
    if (vendorRawDataForm && vendorRawDataForm.values) {
        const {time, start_date, end_date, jobStatus, search} = vendorRawDataForm.values;
        if (jobStatus !== "" && jobStatus !== null && jobStatus !== undefined && jobStatus !== SELECT_ALL_OPTION["value"]) {
            if (jobStatus.length == 0) {
                params.job_status = "null"
        
            } else {
                params.job_status = jobStatus
            }

        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined) {
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined) {
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            } else {
                params.time = time;
            }
        } else {
            params.time = getDashboardDateRange(2, "weeks");
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
    } else {
        params.time = getDashboardDateRange(2, "weeks");
    }

    console.log("PARAMS: ", params);

    api.get('vendor_job_posting/job_details_raw_data', params)
        .then((data) => {
            if (data) {
                NotificationManager.info(
                    "The download will start in a moment. Please do not reload the page until the file has been downloaded",
                    "Download in progress!",
                    8000
                );
                dispatch(waitDownload(data.id))
            }
        })
        .catch((error) => {
            console.log('--- error downloadJobDetailsRawDataReport ---', error);
            let message = "Error obtaining data"
            if (error.detail) message = error.detail;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setData(DOWNLOAD_LOADER, true))
        });
};

const waitDownload = (id) => (dispatch) => {
    let intervalPromise;

    function listener() {
        api.get('archive/download_status', {id})
        .then((resp) => {
            if (resp.status === 10) {
                // PROCESANDO
                dispatch(setData(DOWNLOAD_LOADER, true));
            } else if (resp.status === 20) {
                // TERMINADO
                clearInterval(intervalPromise);
                let name = resp.file
                    ? resp.file.split("/media/archives/")[1]
                    : "vendor_details_raw_data.xlsx";

                const context = {
                    name: name,
                    url: resp.file,
                };
                
                dispatch(setData(DOWNLOAD_LOADER, false));
                
                //  DOWNLOAD EXCEL FILE
                const a = document.createElement('a');
                a.href = context.url;
                a.download = context.name
                document.body.appendChild(a);
                a.click();
                a.remove();
                NotificationManager.success('File downloaded successfully', 'SUCCESS', 3000);
            }
        })
        .catch((err) => {
            let msg =
                "An error occurred while downloading the file. Please try later";
            if (err.status) {
                msg = err.notes;
            }
            dispatch(setData(DOWNLOAD_LOADER, false));
            clearInterval(intervalPromise);
            NotificationManager.error(msg, 'ERROR', 3000);
        })
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
}

export const actions = {
    getList,
    setJobStatusFilter,
    setSearchFilter,
    setTimeFilter,
    downloadReport,
}

// ------------------------------------
// Reducer
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { payload }) => {
        return {
            ...state,
            loader: payload
        };
    },
    [DATA]: (state, { payload }) => {
        return {
            ...state,
            data: payload
        };
    },
    [PAGE]: (state, { payload }) => {
        return {
            ...state,
            page: payload
        };
    },
    [DOWNLOAD_LOADER]: (state, { payload }) => {
        return {
            ...state,
            downloadLoader: payload
        };
    },
}

// ------------------------------------
// Initial state
// ------------------------------------

const initialState = {
    loader: false,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    page: 1,
    downloadLoader: false,
};

export default handleActions(reducers, initialState);
