import moment from "moment";
import { connect } from "react-redux";
import { api } from "../../../../../utility/api";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderFilePicker } from "../../../Utils/renderField/renderField";
import {
    renderField,
    renderNumber,
    renderSwitch,
    renderTextArea,
    renderAddressField,
    SelectField,
} from "../../../Utils/renderField";
import {
    BROKERAGE_STATUSES,
    BROKERAGE_ACTIVE,
    BROKERAGE_LOCKED,
    BROKERAGE_BLOCKED,
} from "../../../../../utility/constants";

const validate = (values) => {
    let errors = {};

    if (!values.firm_name) {
        errors.firm_name = "This field is required";
    }
    if (!values.broker) {
        errors.broker = "This field is required";
    }
    if (!values.license) {
        errors.license = "This field is required";
    }
    if (!values.address) {
        errors.address = "This field is required";
    }

    return errors;
};

const ReinstateForm = (props) => {
    //  State
    const { item, loader, optionBroker = [], brokerValue } = props;
    //  Bind
    const { handleSubmit, goBack } = props;

    const [brokerInfo, setBrokerInfo] = useState(null);

    useEffect(() => {
        if (
            brokerValue != "" &&
            brokerValue != null &&
            brokerValue != null
        ) {
            setBrokerInfo(null)
            api.get(`broker/${brokerValue}`)
                .then((response) => {
                    setBrokerInfo(response);
                })
                .catch((error) => {
                    setBrokerInfo(null);
                });
        } else {
            setBrokerInfo(null);
        }
    }, [brokerValue]);

    const getStatus = (status) => {
        let text = "";
        BROKERAGE_STATUSES.map((item) => {
            if (status === item.value) {
                text = item.label;
            }
        });
        return text;
    };

    const getColor = (status) => {
        let colorText = "black";
        if (status === BROKERAGE_ACTIVE) {
            colorText = "success";
        } else if (status === BROKERAGE_LOCKED) {
            colorText = "danger";
        } else if (status === BROKERAGE_BLOCKED) {
            colorText = "danger";
        }
        return colorText;
    };

    console.log("BROKER: ", brokerInfo);

    return (
        <form
            name="DeletedOfficesForm"
            className="my-1 col-sm-12 p-sm-0"
            style={{ minHeight: "20rem" }}
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="form-group has-feedback">
                        <Field
                            photo={item.logo ? item.logo : null}
                            setFile={() => {}}
                            name="logo"
                            component={renderFilePicker}
                            disabled={true}
                            // className="avatar-round"
                        />
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {item && (
                            <span
                                className={`text-${getColor(
                                    item.status
                                )} font-weight-bold`}
                            >
                                {getStatus(item.status)}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {moment(item.created).format("ll")}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Registered Agents
                        </span>
                        {item && item.agents_stats && (
                            <span className="font-weight-bolder text-primary">
                                {item.total_registered_user
                                    ? item.total_registered_user
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Unregistered Agents
                        </span>
                        {item && item.agents_stats && (
                            <span className="font-weight-bolder text-primary">
                                {item.total_unregistered_user
                                    ? item.total_unregistered_user
                                    : 0}
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="firm_name">Office name</label>
                            <Field
                                name="firm_name"
                                label="Office name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                // disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="broker">Broker</label>
                            <Field
                                name="broker"
                                label="state"
                                // disabled={is_view}
                                options={optionBroker}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="franchise">Franchise</label>
                            <div className="form-control">
                                {brokerInfo && brokerInfo.franchise
                                    ? brokerInfo.franchise.name
                                    : ""}
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="form-group has-feedback col-lg-6 col-sm-12">
                                    <label htmlFor="license">
                                        Office License #
                                    </label>
                                    <Field
                                        name="license"
                                        label="Office License #"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        // disabled={is_view}
                                    />
                                </div>
                                <div className="form-group has-feedback col-lg-6 col-sm-12">
                                    <label
                                        htmlFor="telephone"
                                        className="mx-0 px-0"
                                    >
                                        Phone #
                                    </label>
                                    <Field
                                        numberFormat={"###-###-####"}
                                        name="telephone"
                                        component={renderNumber}
                                        className="form-control"
                                        // disabled={is_view}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address">Address</label>
                            <Field
                                name="address"
                                label="Address"
                                component={renderField}
                                type="text"
                                className="form-control"
                                // disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address_line2">
                                Address Line 2
                            </label>
                            <Field
                                name="address_line2"
                                label="Address Line 2"
                                component={renderField}
                                type="text"
                                className="form-control"
                                // disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="provide_roster">
                                Broker Provide agent's roster list?
                            </label>
                            <div className="form-control">
                                {brokerInfo
                                    ? brokerInfo.provide_agents_roster
                                        ? "YES"
                                        : "NO"
                                    : ""}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="operational">
                                Operational Mode
                            </label>
                            <div className="form-control">
                                {brokerInfo && brokerInfo.operation_access_name
                                    ? brokerInfo.operation_access_name
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mx-auto ">
                <button
                    type="button"
                    onClick={goBack}
                    className={`w-100 btn btn-secondary text-white col-lg-3 col-sm-5 mx-4 mt-2`}
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="btn btn-primary col-lg-3 col-sm-5 mx-4 mt-2"
                >
                    Reinstate
                </button>
            </div>
        </form>
    );
};

let DeletedOfficesForm = reduxForm({
    form: "deletedOfficesForm", // a unique identifier for this form
    validate,
})(ReinstateForm);

const selector = formValueSelector("deletedOfficesForm");

const mstp = (state) => {
    const brokerValue = selector(state, "broker");
    return { brokerValue };
};

export default connect(mstp, null)(DeletedOfficesForm);
