import { connect } from 'react-redux';
import { setTab, getUrlFileTemplate } from '../../../redux/modules/agent/agentRoster';
import { changeFilters, getStatesOption, getOfficeStatesOption, getFranchisesOption, getBrokerOption, getBrokeragesOption, getStats, resetFilterForm, getTeamOption, changeFilterFieldForm, toList2 as toList, getInfluencerOption } from '../../../redux/modules/agent/agent';
import { toList2 as toListRoster } from '../../../redux/modules/agent/agentRoster';
import React, {Component} from 'react';
import Tabs, {TabPane, TabContent} from 'rc-tabs';
import AgentRegistered from './AgentRegistered/AgentContainer';
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import AgentRoster from "./AgentUnregistered";
import AgentFiltersForm from './AgentFiltersForm'
import "./agent.css"


class AgentTab extends Component {

    state = {
        tabContainerHeight: '200px',
    }

    componentDidMount() {
        const {location} = this.props;
        const {
            getStatesOptionAgent,
            getOfficeStatesOptionAgent,
            getFranchisesOptionAgent,
            getBrokerOptionAgent,
            getUrlFileTemplateAgent,
            resetAgentFilterForm,
            getTeams,
            getInfluencers,
            changeFilterForm,
        } = this.props;
        setTimeout(() => {
            const tabContainerHeight = document.getElementById('AGENT-TAB-CONTAINER').clientHeight;
            this.setState({tabContainerHeight})
            let brokerId = null;
            let brokerageId = null;
            let teamId = null;
            if (location && location.search) {
                const searchParams = new URLSearchParams(location.search)
                const _brokerId = searchParams.get('broker')
                if (_brokerId) {
                    brokerId = parseInt(_brokerId)
                }
                const _brokerageId = searchParams.get('brokerage')
                if (_brokerageId) {
                    brokerageId = parseInt(_brokerageId)
                }
                const _teamId = searchParams.get('team')
                if (_teamId) {
                    teamId = parseInt(_teamId)
                }
            }
            if (brokerId !== null || brokerageId !== null || teamId !== null) {
                resetAgentFilterForm()
                if (teamId) {
                    changeFilterForm([teamId], 'team')
                }
                setTimeout(() => {
                    getUrlFileTemplateAgent()
                    getStatesOptionAgent(null, true)
                    getOfficeStatesOptionAgent(null, true)
                    getFranchisesOptionAgent(null, true)
                    getTeams(null, true)
                    getInfluencers(null, true)
                    getBrokerOptionAgent(true, true, brokerId, brokerageId)
                }, 400);
            }else {
                setTimeout(() => {
                    getUrlFileTemplateAgent()
                    getStatesOptionAgent(null, true)
                    getOfficeStatesOptionAgent(null, true)
                    getFranchisesOptionAgent(null, true)
                    getTeams(null, true)
                    getInfluencers(null, true)
                    getBrokerOptionAgent(true, true, brokerId, brokerageId)
                }, 400);
            }
        }, 300);
        // getBrokeragesOptionAgent()
        // getStatsAgent()

        this.agentInterval = setInterval(this.refreshData, 60000)

    }

    refreshData = () => {
        const {registeredPage, unregisteredPage, toListAgent, toListAgentRoster, getStatsAgent} = this.props;
        toListAgent(registeredPage, false)
        toListAgentRoster(unregisteredPage, false)
        getStatsAgent()
    }

    componentWillUnmount() {
        if (this.agentInterval) {
            clearInterval(this.agentInterval)
        }
    }

    render() {
        //  State
        const {tab, states, officeStates, cities, officeCities, franchises, brokers, brokerages, stats, teams, influencers} = this.props;
        //  Bind
        const {changeTab, changeFiltersAgent} = this.props;

        const getPercent = (total, count) => {
            try {
                if (total > 0) {
                    let percent = 0;
                    if (total >= count) {
                        percent = (count * 100) / total;
                    }
        
                    return percent;
                }else{
                    return 0
                }
            } catch (error) {
                return 0
            }
        }
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0 justify-content-between">
                    <div className="">
                        <div className="header-page">Agents</div>
                    </div>
                    <div className='stats-section'>
                        <div className='stats-item'>
                            <span className='text-center'>Total Agents</span>
                            {stats.total_agent}
                        </div>
                        <div className='stats-item'>
                            <span className='text-center'>Unregistered</span>
                            {stats.total_unregistered} ({getPercent(stats.total_agent, stats.total_unregistered).toFixed(2)}%)
                        </div>
                        <div className='stats-item'>
                            <span className='text-center'>Registered</span>
                            {stats.total_registered} ({getPercent(stats.total_agent, stats.total_registered).toFixed(2)}%)
                        </div>
                        <div className='stats-item'>
                            <span className='text-center'>Active</span>
                            {stats.total_user_active} ({getPercent(stats.total_registered, stats.total_user_active).toFixed(2)}%)
                        </div>
                    </div>
                </div>
                <Tabs
                    activeKey={tab}
                    defaultActiveKey="Registered"
                    tabBarPosition="top"
                    onChange={(key) => changeTab(key)}
                    renderTabBar={() => <ScrollableInkTabBar/>}
                    renderTabContent={() => <TabContent/>}
                >
                    <TabPane tab="Users" key="Registered" className="py-0 d-flex flex-column">
                        <div className="px-3">
                            <AgentFiltersForm
                                optionStates={states}
                                optionCities={cities}
                                optionFranchises={franchises}
                                optionBrokers={brokers}
                                optionBrokerages={brokerages}
                                changeFilters={changeFiltersAgent}
                                optionTeams={teams}
                                optionInfluencers={influencers}
                                typeState='registered'/>
                        </div>
                        <div id="AGENT-TAB-CONTAINER" className="flex-1 px-3 pt-2 mb-1 d-flex flex-column" style={{position: 'relative'}}>
                            <AgentRegistered tabContainerHeight={this.state.tabContainerHeight}/>
                        </div>
                    </TabPane>
                    <TabPane tab="Unregistered" key="Unregistered" className="py-0 d-flex flex-column">
                        <div className="px-3">
                            <AgentFiltersForm
                                optionOfficeStates={officeStates}
                                optionOfficeCities={officeCities}
                                optionFranchises={franchises}
                                optionBrokers={brokers}
                                optionBrokerages={brokerages}
                                changeFilters={changeFiltersAgent}
                                optionTeams={teams}
                                optionInfluencers={influencers}
                                typeState='unregistered'/>
                        </div>
                        <div id="AGENT-TAB-CONTAINER" className="flex-1 px-3 pt-2 mb-1 d-flex flex-column" style={{position: 'relative'}}>
                            <AgentRoster tabContainerHeight={this.state.tabContainerHeight}/>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        )
    }

}

const ms2p = (state) => {
    return{
        tab: state.agentRoster.tab,
        states: state.agent.states,
        officeStates: state.agent.officeStates,
        cities: state.agent.cities,
        officeCities: state.agent.officeCities,
        franchises: state.agent.franchises,
        brokers: state.agent.brokers,
        brokerages: state.agent.brokerages,
        teams: state.agent.teams,
        influencers: state.agent.influencers,
        stats: state.agent.stats,
        registeredPage: state.agent.page,
        unregisteredPage: state.agentRoster.page,
    };
};

const md2p = { 
    changeTab: setTab,
    changeFiltersAgent: changeFilters,
    getOfficeStatesOptionAgent: getOfficeStatesOption,
    getStatesOptionAgent: getStatesOption,
    getFranchisesOptionAgent: getFranchisesOption,
    getBrokerOptionAgent: getBrokerOption,
    getBrokeragesOptionAgent: getBrokeragesOption,
    getStatsAgent: getStats,
    getUrlFileTemplateAgent: getUrlFileTemplate,
    resetAgentFilterForm: resetFilterForm,
    getTeams: getTeamOption,
    getInfluencers: getInfluencerOption,
    changeFilterForm: changeFilterFieldForm,
    toListAgent: toList,
    toListAgentRoster: toListRoster,
};

export default connect(ms2p, md2p)(AgentTab);
