import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import _ from "lodash"
import { Field, reduxForm, FieldArray } from "redux-form";
import { formValueSelector } from "redux-form";
import Tabs, {TabPane, TabContent} from 'rc-tabs';
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import {formatPhoneNumber} from "../../Brokerage/BrokerageGrid";
import GridTableWithScroll from "../../Utils/GridTableWithScroll";
import {formatName, formatText, brokerName} from "../../Agent/AgentUnregistered/AgentUnregisteredGrid";
import {
    renderField,
    renderDayPicker,
    renderTextArea,
    SelectField,
    MultiSelectField,
} from "../../Utils/renderField";
import {renderWysiwygField} from '../../Utils/renderField/wysiwygField';
import {
    DEFAULT_STATUS_CAMPAIGN_UNREGISTERED,
    STATE_USA_LIST,
    AGENT_UNREGISTERED_PAGE_SIZE,
    SELECT_ALL_OPTION,
} from "../../../../utility/constants";
import moment from "moment";

const validate = (values, props) => {
    const errors = {}
    const {email_templateValue, typesEmails} = props;    

    if (!values.title) {
        errors.title = "This field is required";
    }
    if(!values.sendgrid_type) {
        errors.sendgrid_type = "This field is required";
    }else {
        if(values.sendgrid_type === 1000) {
            if (!values.types_emails) {
                errors.types_emails = "This field is required";
            }else {
                if(values.types_emails.length <= 0){
                    errors.types_emails = "This field is required";
                }
            }

            //  Extra fields
            if (email_templateValue != "" && email_templateValue != null && email_templateValue != undefined) {
                const _template = _.find(typesEmails, {value: email_templateValue})
                if (_template && _template.extra_fields) {
                    let extraFieldsError = {}
                    if (_template.extra_fields.length){
                        _template.extra_fields.forEach((item) => {
                            if (values.extra_fields) {
                                if (!values.extra_fields[item.key]) {
                                    extraFieldsError[item.key] = "This field is required";
                                }else {
                                    if (item.type == "time") {
                                        const timePattern = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/
                                        if (!values.extra_fields[item.key].match(timePattern)) {
                                            extraFieldsError[item.key] = "Invalid format, Ex: 13:00:00";
                                        }
                                    }
                                }
                            }else {
                                extraFieldsError[item.key] = "This field is required";
                            }
                        })
                    }
                    errors.extra_fields = extraFieldsError;
                }
            }
        }
        if(values.sendgrid_type === 2000) {
            if (!values.text_message) {
                errors.text_message = "This field is required";
            }
            if (!values.subject) {
                errors.subject = "This field is required";
            }else {
                if (values.subject.length > 255) {
                    errors.subject = "The text is too long, the maximum characters is 255";
                }
            }
        }
    }

    return errors
}

const SendgridCampaignForm = (props) => {
    //  State
    const {
        is_view,
        is_update,
        franchises,
        brokers,
        brokerages,
        page,
        loader,
        data,
        agents_error,
        anyTouched,
        typeValue,
        item,
        typesEmails,
        typesCampaigns,
        unregisteredAgentsData,
        loaderAgentsData,
        unregisteredAgentsPage,
        agent_statusValue,
        email_templateValue,
        markRedyToSend,
    } = props;
    const showSendBtn = is_view;
    const TYPES = [{ label: 'Template', value: 1000 }, { label: 'Custom', value: 2000 }, { label: 'Campaign', value: 3000 }];

    //  Bind
    const {handleSubmit, changeFilters, onFileChange, getDataAgents, sendMessage} = props;

    const [tab, setTab] = useState('Registered')
    const [extraFields, setExtraFields] = useState([])

    useEffect(() => {
        if (email_templateValue != "" && email_templateValue != null && email_templateValue != undefined) {
            const _template = _.find(typesEmails, {value: email_templateValue})
                if (_template && _template.extra_fields) {
                    if (_template.extra_fields.length){
                        setExtraFields(_template.extra_fields)
                    }else {
                        setExtraFields([])
                    }
                }else {
                    setExtraFields([])
                }
        } else {
            setExtraFields([])
        }
    }, [email_templateValue])

    useEffect(() => {
        if (agent_statusValue != SELECT_ALL_OPTION["value"] && is_view && typeValue == '3000') {
            setTab('Agents')
        }
    }, [typeValue])

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="title">Title</label>
                                <Field
                                    name="title"
                                    label="Title"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        {typeValue == 2000 && (
                            <div className="col-lg-12 col-sm-12">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="subject">Subject</label>
                                    <Field
                                        name="subject"
                                        label="Subject"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled={is_view}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-12 col-sm-12">
                            {typeValue == 1000 && (
                                // <div className="form-group has-feedback w-100">
                                //     <label htmlFor="types_emails">Emails</label>
                                //     <Field
                                //         name="types_emails"
                                //         isMulti={true}
                                //         isClearable={false}
                                //         options={typesEmails}
                                //         component={SelectField}
                                //         type="text"
                                //         className="form-control"
                                //         disabled={is_view}
                                //     />
                                // </div>
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="email_template">Email Template</label>
                                    <Field
                                        name="email_template"
                                        isMulti={false}
                                        isClearable={false}
                                        options={typesEmails}
                                        component={SelectField}
                                        type="text"
                                        className="form-control"
                                        disabled={is_view}
                                    />
                                </div>
                            )}
                            {typeValue == 3000 && (
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="sendgrid_campaign">Campaign</label>
                                    <Field
                                        name="sendgrid_campaign"
                                        isMulti={false}
                                        isClearable={false}
                                        options={typesCampaigns}
                                        component={SelectField}
                                        type="text"
                                        className="form-control"
                                        disabled={is_view}
                                    />
                                </div>
                            )}
                        </div>
                        {typeValue == 1000 && extraFields.length > 0 ? (
                            <div className="form-group has-feedback w-100 col-sm-12 mt-auto">
                                <label htmlFor="extra_fields">Extra Fields</label>
                                {extraFields.map((extraFieldItem, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="form-group has-feedback col-sm-12 mt-auto"
                                        >
                                            <label
                                                htmlFor={`extra_fields.${extraFieldItem.key}`}
                                            >
                                                {extraFieldItem.label}
                                            </label>
                                            {extraFieldItem.type == "date" ? (
                                                <Field
                                                    name={`extra_fields.${extraFieldItem.key}`}
                                                    placeholder=""
                                                    component={renderDayPicker}
                                                    disabled={is_view}
                                                    initialDate={""}
                                                    type="text"
                                                    outputFormatt="MM/DD/YYYY"
                                                />
                                            ) : null}
                                            {extraFieldItem.type == "time" ? (
                                                <Field
                                                    name={`extra_fields.${extraFieldItem.key}`}
                                                    placeholder="HH:MM:SS"
                                                    component={renderField}
                                                    disabled={is_view}
                                                />
                                            ) : null}

                                            {extraFieldItem.type == "text" ? (
                                                <Field
                                                    name={`extra_fields.${extraFieldItem.key}`}
                                                    placeholder=""
                                                    component={renderField}
                                                    disabled={is_view}
                                                    type="text"
                                                    className="form-control"
                                                />
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </div>
                        ): null}
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-8 col-sm-12 mb-0">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <label htmlFor="sendgrid_type">Type</label>
                            <Field
                                name="sendgrid_type"
                                isMulti={false}
                                options={TYPES}
                                set_value={(option) => {
                                    console.log(option);
                                }}
                                component={SelectField}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="agent_status">Status</label>
                                <Field
                                    name="agent_status"
                                    options={DEFAULT_STATUS_CAMPAIGN_UNREGISTERED}
                                    set_value={() => changeFilters("status")}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="state">State</label>
                                <Field
                                    name="state"
                                    options={STATE_USA_LIST}
                                    set_value={(option) =>
                                        changeFilters(
                                            "state",
                                            option ? option.value : ""
                                        )
                                    }
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="franchise_id">Franchise</label>
                                <Field
                                    name="franchise_id"
                                    options={franchises ? franchises : []}
                                    set_value={(option) => {
                                        console.log("OPTION: ", option);
                                        changeFilters(
                                            "franchise",
                                            option ? option.value : ""
                                        );
                                    }}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="broker_id">Broker</label>
                                <Field
                                    name="broker_id"
                                    options={brokers ? brokers : []}
                                    set_value={(option) => {
                                        console.log("OPTION: ", option);
                                        changeFilters(
                                            "broker",
                                            option ? option.value : ""
                                        );
                                    }}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="brokerage_id">Office</label>
                                <Field
                                    name="brokerage_id"
                                    options={brokerages ? brokerages : []}
                                    set_value={() => changeFilters("brokerage")}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {typeValue == 2000 && (
                <div className="row w-75 px-lg-5 mx-auto pt-2">
                    <div className="form-group has-feedback col-lg-12 col-12 mb-0">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="text_message">Email Text</label>
                            <Field
                                name="text_message"
                                label="Title"
                                component={renderWysiwygField}
                                type="text"
                                customClass="form-control"
                                disabled={is_view}
                                initialvalue={item ? item.text_message : null}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`row mx-auto mt-3 col-lg-5 col-sm-12 justify-content-between mt-4 mb-5`}
            >
                <a
                    className={`btn btn-danger text-white col-lg-5 col-sm-12`}
                    href="/#/sendgrid-campaigns"
                >
                    {" "}
                    Cancel
                </a>
                {!is_view && (
                    <button
                        className={`btn btn-success col-lg-5 col-sm-12`}
                        disabled={loader}
                    >
                        {is_update ? "update" : "create"}
                    </button>
                )}
                {showSendBtn && sendMessage && typeValue != 3000 && (
                    <button
                        onClick={sendMessage}
                        type="button"
                        className="btn btn-secondary col-lg-5 col-sm-12"
                        disabled={loader}
                    >
                        Send Email
                    </button>
                )}
                {showSendBtn && sendMessage && typeValue == 3000 && (
                    <button
                        onClick={markRedyToSend}
                        type="button"
                        className="btn btn-secondary col-lg-5 col-sm-12"
                        disabled={loader}
                    >
                        {item.ready_to_send ? "Cancel Campaign" : "Execute"}
                    </button>
                )}
            </div>
            {(agent_statusValue != SELECT_ALL_OPTION["value"]) && (
                is_view && typeValue == '3000' ? (
                    <div className="row w-75 px-lg-5 mx-auto mt-5">
                        <div className="col-12 px-0">
                            <Tabs
                                activeKey={tab}
                                defaultActiveKey="Agents"
                                tabBarPosition="top"
                                onChange={(key) => setTab(key)}
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Agents" key="Agents" className="py-0">
                                    <div className="px-3 pt-2 pb-3">
                                        <GridTableWithScroll
                                            hover
                                            page={agent_statusValue && agent_statusValue.includes(5) ? page : unregisteredAgentsPage}
                                            keyField={"id"}
                                            data={agent_statusValue && agent_statusValue.includes(5) ? data: unregisteredAgentsData}
                                            loading={loader}
                                            sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                            wrapperClasses="table-scroll-5"
                                            onPageChange={(value) => getDataAgents(value, 5)}
                                            columns={[
                                                {
                                                    dataField: "first_name",
                                                    formatter: formatName(),
                                                    text: "First Name",
                                                },
                                                {
                                                    dataField: "last_name",
                                                    formatter: formatName(),
                                                    text: "Last Name",
                                                },
                                                {
                                                    dataField: "email",
                                                    short: true,
                                                    text: "E-mail",
                                                },
                                                {
                                                    dataField: "phone",
                                                    formatter: formatPhoneNumber(),
                                                    text: "phone",
                                                },
                                                {
                                                    dataField: "license",
                                                    formatter: formatText(),
                                                    text: "License",
                                                },
                                                {
                                                    dataField: "broker",
                                                    formatter: brokerName(),
                                                    text: "Broker",
                                                },
                                                {
                                                    dataField: "account_status",
                                                    text: "Status",
                                                },
                                            ]}
                                        />
                                    </div>
                                </TabPane>
                                {typeValue == '3000' && item && item.emails && item.emails.length > 0 && (
                                    <TabPane tab="Emails" key="Emails" className="py-0">
                                        <div className="px-3 pt-2 pb-3">
                                            <GridTableWithScroll
                                                hover
                                                page={1}
                                                keyField={"template_id"}
                                                loading={loader}
                                                sizePerPage={100}
                                                data={{results: typesEmails && typesEmails.length > 0 ? item.emails : []}}
                                                wrapperClasses="table-scroll-5"
                                                onPageChange={(value) => console.log('PAGE: ', value)}
                                                columns={[
                                                    {
                                                        dataField: "template_id",
                                                        formatter: (cell, row) => {
                                                            let name = ''
                                                            if (cell) {
                                                                const item = _.find(typesEmails, {value: cell})
                                                                if (item) {
                                                                    name = item.label
                                                                } 
                                                            }
                                                            return <span>{name}</span>
                                                        },
                                                        text: "Name",
                                                    },
                                                    {
                                                        dataField: "date_str_delivery",
                                                        text: "Date",
                                                    },
                                                    {
                                                        dataField: "sended_date",
                                                        text: "Sent Date",
                                                        formatter: (cell, row) => {
                                                            console.log('CELL: ', cell);
                                                            return (
                                                                <div className='py-1'>
                                                                    {cell ? moment(cell).format('lll') : ""}
                                                                </div>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        dataField: "total_to_sent",
                                                        text: "Total Sent",
                                                        align: "right",
                                                        formatter: (cell, row) => {
                                                            const total = row.total_sent_unregistered + row.total_sent_registered
                                                            return (
                                                                <div className='py-1'>
                                                                    {item.total_to_sent}/{total}
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </TabPane>
                                )}
                            </Tabs>
                        </div>
                    </div>
                ) : (
                    <div className="row w-75 px-lg-5 mx-auto mt-5">
                        <div className="col-12 px-0">
                            <GridTableWithScroll
                                hover
                                page={agent_statusValue && agent_statusValue.includes(5) ? page : unregisteredAgentsPage}
                                keyField={"id"}
                                data={agent_statusValue && agent_statusValue.includes(5) ? data: unregisteredAgentsData}
                                loading={loader}
                                sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                wrapperClasses="table-scroll-5"
                                onPageChange={(value) => getDataAgents(value, 5)}
                                columns={[
                                    {
                                        dataField: "first_name",
                                        formatter: formatName(),
                                        text: "First Name",
                                    },
                                    {
                                        dataField: "last_name",
                                        formatter: formatName(),
                                        text: "Last Name",
                                    },
                                    {
                                        dataField: "email",
                                        short: true,
                                        text: "E-mail",
                                    },
                                    {
                                        dataField: "phone",
                                        formatter: formatPhoneNumber(),
                                        text: "phone",
                                    },
                                    {
                                        dataField: "license",
                                        formatter: formatText(),
                                        text: "License",
                                    },
                                    {
                                        dataField: "broker",
                                        formatter: brokerName(),
                                        text: "Broker",
                                    },
                                    {
                                        dataField: "account_status",
                                        text: "Status",
                                    },
                                ]}
                            />
    
                            {agents_error && anyTouched && (
                                <div className="invalid-feedback d-block font-weight-bold">
                                    {agents_error}
                                </div>
                            )}
                        </div>
                    </div>

                )
            )}
            {(agent_statusValue == SELECT_ALL_OPTION["value"]) && (
                <div className="row w-75 px-lg-5 mx-auto mt-5">
                    <div className="col-12 px-0">
                        <Tabs
                            activeKey={tab}
                            defaultActiveKey="Registered"
                            tabBarPosition="top"
                            onChange={(key) => setTab(key)}
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Registered" key="Registered" className="py-0">
                                <div className="px-3 pt-2 pb-3">
                                    <GridTableWithScroll
                                        hover
                                        page={page}
                                        keyField={"id"}
                                        data={data}
                                        loading={loader}
                                        sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                        wrapperClasses="table-scroll-5"
                                        onPageChange={(value) => getDataAgents(value, 5)}
                                        columns={[
                                            {
                                                dataField: "first_name",
                                                formatter: formatName(),
                                                text: "First Name",
                                            },
                                            {
                                                dataField: "last_name",
                                                formatter: formatName(),
                                                text: "Last Name",
                                            },
                                            {
                                                dataField: "email",
                                                short: true,
                                                text: "E-mail",
                                            },
                                            {
                                                dataField: "phone",
                                                formatter: formatPhoneNumber(),
                                                text: "phone",
                                            },
                                            {
                                                dataField: "license",
                                                formatter: formatText(),
                                                text: "License",
                                            },
                                            {
                                                dataField: "broker",
                                                formatter: brokerName(),
                                                text: "Broker",
                                            },
                                            {
                                                dataField: "account_status",
                                                text: "Status",
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Unregistered" key="Unregistered">
                                <div className="px-3 pt-2 pb-3">
                                    <GridTableWithScroll
                                        hover
                                        page={unregisteredAgentsPage}
                                        keyField={"id"}
                                        data={unregisteredAgentsData}
                                        loading={loaderAgentsData}
                                        sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                        wrapperClasses="table-scroll-5"
                                        onPageChange={(value) => getDataAgents(value, 1)}
                                        columns={[
                                            {
                                                dataField: "first_name",
                                                formatter: formatName(),
                                                text: "First Name",
                                            },
                                            {
                                                dataField: "last_name",
                                                formatter: formatName(),
                                                text: "Last Name",
                                            },
                                            {
                                                dataField: "email",
                                                short: true,
                                                text: "E-mail",
                                            },
                                            {
                                                dataField: "phone",
                                                formatter: formatPhoneNumber(),
                                                text: "phone",
                                            },
                                            {
                                                dataField: "license",
                                                formatter: formatText(),
                                                text: "License",
                                            },
                                            {
                                                dataField: "broker",
                                                formatter: brokerName(),
                                                text: "Broker",
                                            },
                                            {
                                                dataField: "account_status",
                                                text: "Status",
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPane>
                            {is_view && typeValue == '3000' && item && item.emails && item.emails.length > 0 && (
                                <TabPane tab="Emails" key="Emails" className="py-0">
                                    <div className="px-3 pt-2 pb-3">
                                        <GridTableWithScroll
                                            hover
                                            page={1}
                                            keyField={"template_id"}
                                            loading={loader}
                                            sizePerPage={100}
                                            data={{results: typesEmails && typesEmails.length > 0 ? item.emails : []}}
                                            wrapperClasses="table-scroll-5"
                                            onPageChange={(value) => console.log('PAGE: ', value)}
                                            columns={[
                                                {
                                                    dataField: "template_id",
                                                    formatter: (cell, row) => {
                                                        let name = ''
                                                        if (cell) {
                                                            const item = _.find(typesEmails, {value: cell})
                                                            if (item) {
                                                                name = item.label
                                                            } 
                                                        }
                                                        return <span>{name}</span>
                                                    },
                                                    text: "Name",
                                                },
                                                {
                                                    dataField: "date_str_delivery",
                                                    text: "Date",
                                                },
                                                {
                                                    dataField: "sended_date",
                                                    text: "Sent Date",
                                                    formatter: (cell, row) => {
                                                        return (
                                                            <div className='py-1'>
                                                                {cell ? moment(cell).format('lll') : ""}
                                                            </div>
                                                        )
                                                    }
                                                },
                                                {
                                                    dataField: "total_to_sent",
                                                    text: "Total Sent",
                                                    align: "right",
                                                    formatter: (cell, row) => {
                                                        const total = row.total_sent_unregistered + row.total_sent_registered
                                                        return (
                                                            <div className='py-1'>
                                                                {item.total_to_sent}/{total}
                                                            </div>
                                                        )
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>
                                </TabPane>
                            )}
                        </Tabs>
                        

                        {agents_error && anyTouched && (
                            <div className="invalid-feedback d-block font-weight-bold">
                                {agents_error}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </form>
    );
};

let SendgridCampaignReduxForm = reduxForm({
    form: "sendgridCampaignFrom", // a unique identifier for this form
    validate,
    initialValues: {agent_status: SELECT_ALL_OPTION["value"], state: SELECT_ALL_OPTION["value"]}
})(SendgridCampaignForm);

const selector = formValueSelector("sendgridCampaignFrom");
SendgridCampaignReduxForm = connect((state) => {
    const typeValue = selector(state, "sendgrid_type");
    const agent_statusValue = selector(state, 'agent_status');
    const email_templateValue = selector(state, 'email_template');
    return {
        typeValue,
        agent_statusValue,
        email_templateValue,
    };
})(SendgridCampaignReduxForm);

export default SendgridCampaignReduxForm;
