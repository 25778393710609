import moment from 'moment';
import React, { Component } from 'react'
import Grid from "../../Utils/GridTable";
import {CUSTOM_PAGE_SIZE_IN_BACKEND}  from "../../../../utility/constants"

class PaymentsHistory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: null,
        };
    }

    componentWillMount() {
        const {match} = this.props;

        if (match.params && match.params.id) {
            this.setState({id: match.params.id})
            this.props.getPaymentsHistory(match.params.id);
        }
    }

    goBack = () => {
        const {history} = this.props
        history.goBack()
    }

    render() {
        //  State
        const {paymentLoader, paymentData, paymentPage} = this.props;
        //  Bind
        const {getPaymentsHistory} = this.props;

        return (
            <div className="flex-1 d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            Payments History
                        </div>
                    </div>
                </div>
                <div
                    className="flex-1 card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: "99%",
                        minHeight: "15%",
                    }}
                >
                    <Grid
                        hover
                        page={paymentPage}
                        keyField={"id"}
                        data={paymentData && paymentData.results ? paymentData : {results: []}}
                        loading={paymentLoader}
                        sizePerPage={CUSTOM_PAGE_SIZE_IN_BACKEND}
                        // onSortChange={(field, sortOrder, state) => {
                        //     onSortChange(field)
                        // }}
                        onPageChange={(value) => getPaymentsHistory(this.state.id, value)}
                        columns={[
                            {
                                dataField: "created",
                                formatter: (cell, row) => {
                                    return <span>{cell ? moment(cell).format("lll") : ""}</span>
                                },
                                text: "Date",
                            },
                            {
                                dataField: "amount",
                                formatter: formatPaymentAmount(),
                                text: "Amount",
                            },
                            {
                                dataField: "fee_amount",
                                formatter: formatPaymentAmount(),
                                text: "Stripe Montly Fee",
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export function formatPaymentAmount() {
    return (cell) => {
        return (
            <div>
                <p className="p-0 m-0 row justify-content-end" style={{ width: '4.5rem' }}>
                    $&nbsp;&nbsp;{parseFloat(cell)
                    .toFixed(2)}
                </p>
            </div>
        );
    };
}

export default PaymentsHistory