import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import { renderFilePicker } from "../../../Utils/renderField/renderField";
import {
    renderField,
    renderNumber,
    renderSwitch,
    SelectField,
} from "../../../Utils/renderField";
import {
    LOCKED_BROKER,
    PENDING_BROKER,
    STATE_USA_LIST,
    VERIFIED_BROKER,
    DENIED_BROKER,
    STATUS_BROKER,
    BROKER_ROLES_LIST,
    BROKER_OPERATIONAL_MODE_LIST,
    BLOCKED_BROKER,
} from "../../../../../utility/constants";

const validate = (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "This field is required";
    }
    if (!values.role) {
        errors.role = "This field is required";
    }
    if (!values.owner_email) {
        errors.owner_email = "This field is required";
    }
    if (!values.franchise_id) {
        errors.franchise_id = "This field is required";
    }
    if (!values.operation_access) {
        errors.operation_access = "This field is required";
    }
    if (!values.license) {
        errors.license = "This field is required";
    }
    if (!values.office_phone_number) {
        errors.office_phone_number = "This field is required";
    } else {
        if (values.office_phone_number.length < 10) {
            errors.office_phone_number = "Invalid phone number";
        }
    }
    if (
        values.provide_agents_roster !== true &&
        values.provide_agents_roster !== false
    ) {
        errors.provide_agents_roster = "This field is required";
    }

    return errors;
};

const DeleteBrokerReinstateForm = (props) => {
    const {
        handleSubmit,
        franchises,
        item,
        goBack,
    } = props;

    const getStatus = (status) => {
        let text = "";
        STATUS_BROKER.map((item) => {
            if (status === item.value) {
                text = item.label;
            }
        });
        return text;
    };

    const getColor = (status) => {
        let colorText = "black";
        if (status === DENIED_BROKER) {
            colorText = "danger";
        } else if (status === PENDING_BROKER) {
            colorText = "warning";
        } else if (status === VERIFIED_BROKER) {
            colorText = "success";
        } else if (status === LOCKED_BROKER) {
            colorText = "danger";
        } else if (status === BLOCKED_BROKER) {
            colorText = "danger";
        }
        return colorText;
    };

    const getOfficeLicences = (broker) => {
        let result = ""
        broker.offices.forEach((office, index) => {
            if (index == 0) {
                result += `${office.license}`
            }else {
                result += `   /   ${office.license}`
            }
        });

        return result;
    }

    return (
        <form
            name="registerAdmin"
            className="my-1 col-sm-12 p-sm-0"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="form-group has-feedback">
                        <Field
                            photo={item.logo_office ? item.logo_office : null}
                            setFile={() => {}}
                            name="photo"
                            component={renderFilePicker}
                            disabled={true}
                            // className="avatar-round"
                        />
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {moment(item.created).format("ll")}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {item && (
                            <span
                                className={`text-${getColor(
                                    item.status
                                )} font-weight-bold`}
                            >
                                {getStatus(item.status)}
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="name">Broker Full Name</label>
                            <Field
                                name="name"
                                label="Broker Full Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="role">Role</label>
                            <Field
                                name="role"
                                label="state"
                                disabled={false}
                                options={BROKER_ROLES_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="license">RE License #</label>
                            <Field
                                name="license"
                                label="License number"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="owner_email">Email</label>
                            <Field
                                name="owner_email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label
                                htmlFor="office_phone_number"
                                className="mx-0 px-0"
                            >
                                Phone #
                            </label>
                            <Field
                                numberFormat={"###-###-####"}
                                name="office_phone_number"
                                component={renderNumber}
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label
                                htmlFor="mobile_number"
                                className="mx-0 px-0"
                            >
                                Mobile #
                            </label>
                            <Field
                                numberFormat={"###-###-####"}
                                name="mobile_number"
                                component={renderNumber}
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="group_name">Group Name</label>
                            <Field
                                name="group_name"
                                label="Group Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address">Address</label>
                            <Field
                                name="address"
                                label="Address"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={false}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="franchise_id">Franchise</label>
                            <Field
                                name="franchise_id"
                                label="franchise"
                                options={franchises}
                                component={SelectField}
                                className="form-control mb-2 pb-5"
                            />
                            {franchises.length === 0 && (
                                <p className="mt-2">
                                    <a
                                        href="/#/franchises"
                                        className="text-danger text-12"
                                    >
                                        No franchises, please create a franchise
                                        to continue
                                    </a>
                                </p>
                            )}
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="account_status">State</label>
                            <Field
                                name="state"
                                label="state"
                                disabled={false}
                                options={STATE_USA_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="provide_agents_roster">
                                Will Broker provide agent's roster list?
                            </label>
                            <Field
                                name="provide_agents_roster"
                                label="provide_agents_roster"
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                component={SelectField}
                                className="form-control mb-2 pb-5"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="operation_access">
                                Operational Mode
                            </label>
                            <Field
                                name="operation_access"
                                label="Operational Mode"
                                disabled={false}
                                options={BROKER_OPERATIONAL_MODE_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mx-auto ">
                <button type="button" onClick={goBack} className={`w-100 btn btn-secondary text-white col-lg-3 col-sm-5 mx-4 mt-2`}>
                    Back
                </button>
                <button
                    type="submit"
                    className="btn btn-primary col-lg-3 col-sm-5 mx-4 mt-2"
                >
                    Reinstate
                </button>
            </div>
        </form>
    );
};

// const numberPhone = (number) => validatorFromFunction(() => {
//     return number.length === 10;
// });

export default reduxForm({
    form: "deletedBrokersForm", // a unique identifier for this form
    validate,
})(DeleteBrokerReinstateForm);
