import { api } from "api";
import Swal from 'sweetalert2';
import { push } from "react-router-redux";
import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { change, initialize as initializeForm } from 'redux-form';


// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "vendor_user";
const PROFESSIONS = 'PROFESSIONS_VENDORS';
const PROFESSIONS_DATA = 'PROFESSIONS_DATA_VENDORS';

export const setProfessions = professions => ({
    type: PROFESSIONS,
    professions,
});

const setProfessionsData = professionsData => ({
    type: PROFESSIONS_DATA,
    professionsData,
});

export let { reducers, initialState, actions } = createReducer(
    "vendors",
    endpoint,
    "vendorUserForm",
    "vendor/vendors",
);
actions.readVendorProfessions = (id) => async(dispatch) => {
    dispatch(actions.setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(actions.setItem(response));
        const { professions_data } = response;
        const professions = professions_data.map(profession => {
            return {
                ...profession,
                label: profession.name,
            }
        });
        dispatch(setProfessionsData(professions));
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
}

actions.getProfessions = () => (dispatch) => {
    api.get('job_type_vendor/options')
        .then((response) => {
            dispatch(setProfessions(response && response.results ? response.results : []));
        })
        .catch(() => {
            dispatch(setProfessions([]));
        })
}
actions.sendInvite = (id) => (dispatch, getStore) => {
    Swal.fire({
        title: "Are you sure to activate and send the invitation?",
        text: "This action will activate and grant access to the vendor!",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#7E8A97",
        cancelButtonColor: "#B9BDC1",
        confirmButtonText: "Yes, send it!",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            const { page } = getStore().vendors;
            api.post(`vendor_user/${id}/activate_invite`).then((response) => {
                NotificationManager.success(
                    "Invitation sent successfully",
                    "Success",
                    3000
                );
                dispatch(actions.toList(page));
            }).catch((error) => {
                let msg = "The invitation could not be sent";
                if (error && error.detail) {
                    msg = error.detail;
                }
                NotificationManager.error(msg, "ERROR", 0);
            })
        }
    })
}
actions.removeVendor = (item) => (dispatch) => {
    const {id} = item;
    dispatch(actions.setLoader(true));
    api.post(`vendor_user/send_validation_code`).then(reponse => {
        Swal.fire({
            title: 'Are you sure you want to delete this Vendor?',
            text: "To confirm please enter the verification code",
            input: 'text',
            type: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Next',
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
                return api.post(`vendor_user/validate_code`, {code}).then(response => {
                    return response
                }).catch(error => {
                    let msg = "Could not validate the code try again"
                    if (error && error.detail) {
                        msg = error.detail
                    }
                    Swal.showValidationMessage(
                        `${msg}`
                    )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value && result.value.detail === "Correct code") {
                let htmlTemplate = `<p style="font-size: 17px; font-weight: bold;margin-bottom: 0;">This action can't be reverted!</p>`

                Swal.fire({
                    title: 'Are you sure you want to delete this Vendor?',
                    html: htmlTemplate,
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#7E8A97',
                    cancelButtonColor: '#B9BDC1',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.value) {
                        dispatch(actions.remove(id))
                    }
                });
                console.log("DELETED");
            }
        })
    }).catch(error => {
        NotificationManager.error('Failed to send verification code, please try again later', 'Error', 1000);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    })
}
actions.edit = (id, data, attachments=[]) =>  (dispatch) => {
    dispatch(actions.setLoader(true));
    api.putAttachments(`vendor_user/${id}`, data, attachments).then(() => {
        NotificationManager.success('Vendor updated', 'Success', 3000);
        dispatch(push("/vendor/vendors"));
    }).catch(() => {
        NotificationManager.error('Edition failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};

actions.setWorkingArea = (data) => (dispatch) => {
    const {latitude, longitude} = data
    dispatch(change('vendorUserForm', 'latitude', latitude))
    dispatch(change('vendorUserForm', 'longitude', longitude))
}

actions.addProfession = (id, data) => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.post(`${endpoint}/${id}/add_profession`, data).then((response) => {
        NotificationManager.success('Profession added successfully', 'Success', 3000);
        if (response) {
            dispatch(actions.setItem(response));
            const { professions_data } = response;
            const professions = professions_data.map(profession => {
                return {
                    ...profession,
                    label: profession.name,
                }
            });
            dispatch(setProfessionsData(professions));
        } else {
            dispatch(actions.readVendorProfessions(id));
        }
    }).catch(() => {
        NotificationManager.error('Profession could not be added', 'ERROR', 0);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    })
}

actions.removeProfession = (id, data) => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}/remove_profession`, data).then((response) => {
        NotificationManager.success('Profession removed successfully', 'Success', 3000);
        if (response) {
            dispatch(actions.setItem(response));
            const { professions_data } = response;
            const professions = professions_data.map(profession => {
                return {
                    ...profession,
                    label: profession.name,
                }
            });
            dispatch(setProfessionsData(professions));
        } else {
            dispatch(actions.readVendorProfessions(id));
        }
    }).catch(() => {
        NotificationManager.error('Profession could not be removed', 'ERROR', 0);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    })
}

actions.approveProfession = (id, data) => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}/approve_profession`, data).then((response) => {
        NotificationManager.success('Profession approved successfully', 'Success', 3000);
        if (response) {
            dispatch(actions.setItem(response));
            const { professions_data } = response;
            const professions = professions_data.map(profession => {
                return {
                    ...profession,
                    label: profession.name,
                }
            });
            dispatch(setProfessionsData(professions));
        } else {
            dispatch(actions.readVendorProfessions(id));
        }
    }).catch(() => {
        NotificationManager.error('Profession could not be approved', 'ERROR', 0);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    })
}

actions.rejectProfession = (id, data) => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}/reject_profession`, data).then((response) => {
        let msg = 'Profession rejected successfully';
        if (data.type === 'remove') {
            msg = 'Change registered successfully';
        }
        NotificationManager.success(msg, 'Success', 3000);
        if (response) {
            dispatch(actions.setItem(response));
            const { professions_data } = response;
            const professions = professions_data.map(profession => {
                return {
                    ...profession,
                    label: profession.name,
                }
            });
            dispatch(setProfessionsData(professions));
        } else {
            dispatch(actions.readVendorProfessions(id));
        }
    }).catch(() => {
        NotificationManager.error('Profession could not be rejected', 'ERROR', 0);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    })
}

reducers = {
    ...reducers,
    [PROFESSIONS]: (state, {professions}) => {
        return {
            ...state,
            professions,
        };
    },
    [PROFESSIONS_DATA]: (state, {professionsData}) => {
        return {
            ...state,
            professionsData,
        };
    },
}

initialState = {
    ...initialState,
    professions: [],
    professionsData: [],
}

export default handleActions(reducers, initialState);
