import moment from "moment"
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { renderFieldCheck } from "../../../../Utils/renderField";

const validate = (values, props) => {
    let errors = {};

    return errors;
};

const renderAddresses = ({ fields, meta: { error, touched } }) => (
    <React.Fragment>
        {fields.map((item, index) => {
            const data = fields.get(index);
            return (
                <div key={index} className="form-group has-feedback col-12 mt-auto py-2 border-bottom">
                    <Field
                        name={`${item}.value`}
                        component={renderFieldCheck}
                        type="checkbox"
                        label={`     ${data.label}`}
                        className="form-control"
                    />
                </div>
            )
        })}
    </React.Fragment>
)

const Form = (props) => {
    //  State
    const { loader, item } = props;
    //  Bind
    const { handleSubmit } = props;

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-1"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="col-12 mt-auto">
                    <label className="h5 mt-2">Select the addresses to be paid</label>
                    <FieldArray name="addresses" component={renderAddresses} />
                </div>
            </div>
            <div className="row justify-content-center align-items-center mx-auto mt-3">
                <div className="col-sm-12 col-md-3 col-lg-4 d-flex justify-content-center align-items-center">
                    <button type="submit" className="btn btn-primary" disabled={loader}>
                        Complete
                    </button>
                </div>
            </div>
        </form>
    )
}

let SelectAddressesToCompleteJobForm = reduxForm({
    form: "selectAddressesToCompleteJob", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector("selectAddressesToCompleteJob");

SelectAddressesToCompleteJobForm = connect((state) => {
    return {
    };
})(SelectAddressesToCompleteJobForm);

export default SelectAddressesToCompleteJobForm;