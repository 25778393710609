import moment from "moment";

export const SINGLE = 1;
export const MULTIPLE = 5;
export const FROM_TO = 10;

export const CLIENT = 1;
export const VENDOR = 5;

export const APPLY = 100;
export const CLAIM = 200;
export const APPLY_OR_CLAIM = 300;

export const TEXT = 1;
export const TEXTAREA = 5;
export const BOOLEAN = 10;
export const PHOTO = 15;
export const SCALE = 20;
export const EMAIL = 40;
export const NUMBER = 45;
export const CURRENCY = 50;
export const PHONE = 55;
export const OPTIONS_MAYBE = 25;
export const OPTIONS_NOTSURE = 30;

// services type
export const INSTALL_REMOVE = 1
export const TAKE_PHOTO_VIDEO = 5
export const MOVE = 10
// size page
export const SIZE_PER_PAGE = 50
// status broker model
export const PENDING_BROKER = 1
export const VERIFIED_BROKER = 5
export const DENIED_BROKER = 10
export const LOCKED_BROKER = 15
export const BLOCKED_BROKER = 20

// -------------- SECTION OF AGENT ACCOUNT STATUS -----------------------
export const PENDING = 'Pending';
export const REGISTERED = 'Registered';
export const DISABLED = 'Disabled';

// account status agent
const PENDING_VALUE = 1
const REGISTERED_VALUE = 5
const DISABLED_VALUE = 15

//  Options type
export const BASIC = 10
export const VALUE = 20
export const FULL = 30
export const MY_OPEN_HOUSE = 40

export const DEFAULT_CATEGORY_UNREGISTERED = [
    {label: 'All', value: ''},
    {label: 'Registered', value: REGISTERED_VALUE},
    {label: 'Unregistered', value: PENDING_VALUE},
]
export const DEFAULT_STATUS_CAMPAIGN_UNREGISTERED = [
    {label: 'Registered', value: REGISTERED_VALUE},
    {label: 'Unregistered', value: PENDING_VALUE},
]
export const CATEGORY_UNREGISTERED_LIST = [
    {label: 'Registered', value: REGISTERED_VALUE},
    {label: 'Unregistered', value: PENDING_VALUE},
]

export const STATUS_BROKER = [
    {value: PENDING_BROKER, label: 'Pending'},
    {value: VERIFIED_BROKER, label: 'Active'},
    {value: DENIED_BROKER, label: 'Deny'},
    {value: LOCKED_BROKER, label: 'Locked'},
    {value: BLOCKED_BROKER, label: 'Blocked'},
];

//  address type
export const JOB_TYPES = [
    {value: SINGLE, label: 'Single Address'},
    {value: MULTIPLE, label: 'Multiple Addresses'},
    {value: FROM_TO, label: 'From-to Address'},
];

// TO AGENT FILTER
//  REGISTERED
export const REGISTERED_PENDING = 10;
export const REGISTERED_RE_ASSIGN = 15;
export const REGISTERED_ACTIVE = 20;
export const REGISTERED_LOCKED = 40;
export const REGISTERED_BLOCKED = 50;
export const DEFAULT_CATEGORY_REGISTERED_ROSTER = [
    {label: 'All', value: ''},
    {label: 'Active', value: 20},
    {label: 'Locked', value: true},
]
export const DEFAULT_CATEGORY_REGISTERED = [
    {label: 'All', value: ''},
    {label: 'Pending', value: 10},
    {label: 'Active', value: 20},
    {label: 'Locked', value: true},
]
export const CATEGORY_REGISTERED_LIST = [
    {label: 'Pending', value: REGISTERED_PENDING},
    {label: 'Re-Assign', value: REGISTERED_RE_ASSIGN},
    {label: 'Active', value: REGISTERED_ACTIVE},
    {label: 'Locked', value: REGISTERED_LOCKED},
    {label: 'Blocked', value: REGISTERED_BLOCKED},
]


// LIST_SHOW_WHEN
// AD(for Additional Services model)
export const AD_ALWAYS = 1
export const AD_INSTALL = 5
export const AD_REMOVE = 10

export const LIST_SHOW_WHEN = [
    {value: AD_ALWAYS, label: 'Always'},
    {value: AD_INSTALL, label: 'Install'},
    {value: AD_REMOVE, label: 'Remove'},
];

// notes type
export const CUSTOMER_TYPE = [
    {value: CLIENT, label: 'Client'},
    {value: VENDOR, label: 'Vendor'},
];

//  types application
export const TYPES_APPLICATION = [
    {value: APPLY, label: 'Apply'},
    {value: CLAIM, label: 'Claim'},
    {value: APPLY_OR_CLAIM, label: 'Apply or Claim'},
];

// other services list
export const OTHER_SERVICES_LIST = [
    {value: INSTALL_REMOVE, label: 'Pickup From /  Install at'},
    {value: TAKE_PHOTO_VIDEO, label: 'Take Photo Or Video'},
    {value: MOVE, label: 'Move in or Move out'},
];

export const ANSWER_TYPE = [
    {value: CURRENCY, label: 'Currency'},
    {value: EMAIL, label: 'Email'},
    {value: NUMBER, label: 'Number'},
    {value: PHONE, label: 'Phone'},
    {value: SCALE, label: 'Scale (1 - 5)'},
    {value: TEXT, label: 'Text'},
    {value: TEXTAREA, label: 'Textarea'},
    {value: BOOLEAN, label: 'Yes/No'},
    {value: OPTIONS_MAYBE, label: 'Yes/Maybe/No'},
    {value: OPTIONS_NOTSURE, label: 'Yes/Not sure/No'},
];

export const CUSTOMER_ANSWER_TYPE = [
    ...ANSWER_TYPE,
];

export const CLIENT_ANSWER_TYPE = [
    {value: TEXTAREA, label: 'Textarea'},
    {value: BOOLEAN, label: 'Yes/No'},
];

//  Options types
export const OPTION_TYPES = [
    {value: BASIC, label: 'Basic'},
    {value: VALUE, label: 'Value'},
    {value: FULL, label: 'Full'},
    {value: MY_OPEN_HOUSE, label: 'My Open House'},
];


export const getMonths = () => {
    let dateArray = [];
    let day = moment().date()
    let numberMonth = 5
    for (let x = 0; x <= 5; x++) {
        const initialDate = new moment(Date.now()).subtract(day - 1, 'days').subtract(numberMonth - x, "months")
        dateArray.push(initialDate.format('DD-MM-YYYY'))
    }
    return dateArray
}

export const get1Week = () => {
    let dateWeekArray = [];
    const days = 6;
    for (let x = 0; x <= 6; x++) { // 7 days
        const initialDate = new moment(Date.now()).subtract(days - x, 'days')
        dateWeekArray.push(initialDate.format('DD-MM-YYYY'))
    }
    return dateWeekArray
}
export const getDashboardDateRange = (amount, unit) => {
    if (unit == 'months' || unit == 'years') {
        const endDate = moment();
        let startDate = unit == 'months' ? moment(`01-${endDate.format("MM-YYYY")}`, "DD-MM-YYYY'") : moment(`01-01-${endDate.format("YYYY")}`, "DD-MM-YYYY'")
        const _amount = amount - 1;
        if (_amount > 0) {
            const _endDate = _.cloneDeep(startDate)
            startDate = _endDate.subtract(_amount, unit)
        }
        const result = [startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY')]
        return result;
    } else if (unit == 'weeks') {
        const endDate = moment();
        let startDate = moment().startOf('week');
        const _amount = amount - 1;
        if (_amount > 0) {
            const referenceDate = moment().subtract(_amount, unit);
            startDate = referenceDate.startOf('week');
        }
        const result = [startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY')]
        return result;
    } else {
        const startDate = moment().subtract(amount, unit);
        const endDate = moment();
        const result = [startDate.format('DD-MM-YYYY'), endDate.format('DD-MM-YYYY')]
        return result;
    }
}

export const get2Week = () => {
    let date2WeekArray = [];
    const days = 13;
    for (let x = 0; x <= 13; x++) { // 14 days
        const initialDate = new moment(Date.now()).subtract(days - x, 'days')
        date2WeekArray.push(initialDate.format('DD-MM-YYYY'))
    }
    return date2WeekArray
}

export const get1Month = () => {
    let date1Month = [];
    const numberWeeks = 4;
    for (let x = 0; x <= 4; x++) { // 4 weeks
        const initialDate = new moment(Date.now()).subtract(numberWeeks - x, 'week')
        date1Month.push(initialDate.format('DD-MM-YYYY'))
    }
    return date1Month
}

export const get2Month = () => {
    let date1Month = [];
    const numberWeeks = 8;
    for (let x = 0; x <= numberWeeks; x++) { // 8 weeks
        const initialDate = new moment(Date.now()).subtract(numberWeeks - x, 'week')
        date1Month.push(initialDate.format('DD-MM-YYYY'))
    }
    return date1Month
}

export const get3Month = () => {
    let dateArray = [];
    let numberWeek = 6;
    for (let x = 0; x <= numberWeek; x++) {
        const initialDate = new moment(Date.now()).subtract((numberWeek - x) * 2, "week")
        dateArray.push(initialDate.format('DD-MM-YYYY'))
    }
    return dateArray
}

export const getMonthsLabel = () => {
    let dateArray = [];
    let day = moment().date()
    let numberMonth = 5
    for (let x = 0; x <= 5; x++) {
        const initialDate = moment(Date.now()).subtract(day - 1, 'days').subtract(numberMonth - x, "months")
        dateArray.push(initialDate.format('MMM').toLocaleLowerCase('us'))
    }
    return dateArray
}

export const LabelDaysOfWeek = (dates) => {
    let weekDays = [];
    dates.map((date) => {
        let dateResult = moment(date, 'DD-MM-YYYY').toString()
        dateResult = moment(dateResult).format('DD MMM')
        weekDays.push(dateResult)
    })
    return weekDays
}

export const getDateRange = (dates) => {
    const len = dates.length
    let start = moment(dates[0], 'DD-MM-YYYY').toString()
    let end = moment(dates[len - 1], 'DD-MM-YYYY').toString()
    start = moment(start).format('DD MMM')
    end = moment(end).format('DD MMM')
    return `${start} - ${end}`
}

export const getMonthsRange = () => {
    let day = moment().date()
    const initialDate = moment(new Date()).subtract(day - 1, 'days').subtract(5, "months")
    const current_date = moment(new Date())
    return `${initialDate.format('MMM')}${' - '}${current_date.format('MMM')}`
}

// MOUNT LIST
export const MOUNT_LIST = [
    {label: 'January', value: 1},
    {label: 'February', value: 2},
    {label: 'March', value: 3},
    {label: 'April', value: 4},
    {label: 'May', value: 5},
    {label: 'June', value: 6},
    {label: 'July', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9},
    {label: 'October', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12},
];

export const setListMonth = () => {
    const currentMonth = moment().month() + 1;
    const initialMonth = moment().month() - 5;
    let NEW_LIST = [];
    MOUNT_LIST.map((month, index) => {
        if (initialMonth <= index && currentMonth > index) {
            NEW_LIST.push(month);
        }
    });
    return NEW_LIST;
};

// Week LIST
export const WEEK_LIST = [
    {label: 'ALL', value: ''},
    {label: 'This week', value: 1},
];

// MOUNT LIST
export const NEW_MONTH_LIST = [
    // {label: 'default', value: ''},
    {label: '1 Week', value: get1Week()},
    {label: '2 Week', value: get2Week()},
    {label: '1 Month', value: ''},
    {label: '2 Month', value: get2Month()},
    {label: '3 Month', value: get3Month()},
    // {label: '3 Month', value: 5},
];

export const DASHBOARD_TIME = [
    // {label: 'default', value: ''},
    {label: '1 Week', value: ''},
    {label: '2 Week', value: getDashboardDateRange(2, 'weeks')},
    {label: '3 Week', value: getDashboardDateRange(3, 'weeks')},
    {label: '1 Month', value: getDashboardDateRange(1, 'months')},
    {label: '2 Month', value: getDashboardDateRange(2, 'months')},
    {label: '3 Month', value: getDashboardDateRange(3, 'months')},
    {label: '6 Month', value: getDashboardDateRange(6, 'months')},
    {label: '1 Year', value: getDashboardDateRange(1, 'years')},
    {label: '2 Year', value: getDashboardDateRange(2, 'years')},
    {label: 'Custom', value: 'custom'},
];

export const AGENT_PERFORMANCE_TIME = [
    // {label: 'default', value: ''},
    {label: '1 Week', value: getDashboardDateRange(1, 'weeks')},
    {label: '2 Week', value: getDashboardDateRange(2, 'weeks')},
    {label: '3 Week', value: getDashboardDateRange(3, 'weeks')},
    {label: '1 Month', value: ''},
    {label: '2 Month', value: getDashboardDateRange(2, 'months')},
    {label: '3 Month', value: getDashboardDateRange(3, 'months')},
    {label: '6 Month', value: getDashboardDateRange(6, 'months')},
    {label: '1 Year', value: getDashboardDateRange(1, 'years')},
    {label: '2 Year', value: getDashboardDateRange(2, 'years')},
    {label: 'Custom', value: 'custom'},
];
export const JOB_DETAILS_RAW_DATA_TIME = [
    // {label: 'default', value: ''},
    {label: '1 Week', value: getDashboardDateRange(2, 'weeks')},
    {label: '2 Week', value: ''},
    {label: '3 Week', value: getDashboardDateRange(3, 'weeks')},
    {label: '1 Month', value: getDashboardDateRange(1, 'months')},
    {label: '2 Month', value: getDashboardDateRange(2, 'months')},
    {label: '3 Month', value: getDashboardDateRange(3, 'months')},
    {label: '6 Month', value: getDashboardDateRange(6, 'months')},
    {label: '1 Year', value: getDashboardDateRange(1, 'years')},
    {label: '2 Year', value: getDashboardDateRange(2, 'years')},
    {label: 'Custom', value: 'custom'},
];

export const RANKING_LIST = [
    {label: 'ALL', value: ''},
    {label: 'Star 1', value: [1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9]},
    {label: 'Star 2', value: [2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9]},
    {label: 'Star 3', value: [3, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9]},
    {label: 'Star 4', value: [4, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9]},
    {label: 'Star 5', value: [5, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9]},
];

export const RANKING_LIST_INT = [
    {label: 'ALL', value: ''},
    {label: 'Star 1', value: [1, 1.9]},
    {label: 'Star 2', value: [2, 2.9]},
    {label: 'Star 3', value: [3, 3.9]},
    {label: 'Star 4', value: [4, 4.9]},
    {label: 'Star 5', value: [5, 5]},
];


// -------------- SECTION OF ALL STATES OF USA --------------------
export const STATE_USA_LIST = [
    {label: "Alabama", value: "AL"},
    {label: "Alaska", value: "AK"},
    {label: "Arizona", value: "AZ"},
    {label: "Arkansas", value: "AR",},
    {label: "California", value: "CA",},
    {label: "Colorado", value: "CO",},
    {label: "Connecticut", value: "CT",},
    {label: "Delaware", value: "DE",},
    {label: "District of Columbia", value: "DC",},
    {label: "Florida", value: "FL",},
    {label: "Georgia", value: "GA",},
    {label: "Idaho", value: "ID",},
    {label: "Indiana", value: "IN",},
    {label: "Iowa", value: "IA",},
    {label: "Kansas", value: "KS",},
    {label: "Maine", value: "ME",},
    {label: "Maryland", value: "MD",},
    {label: "Massachusetts", value: "MA",},
    {label: "Mississippi", value: "MS",},
    {label: "Missouri", value: "MO",},
    {label: "Nebraska", value: "NE"},
    {label: "Nevada", value: "NV"},
    {label: "New Hampshire", value: "NH"},
    {label: "New Jersey", value: "NJ"},
    {label: "New Mexico", value: "NM"},
    {label: "New York", value: "NY"},
    {label: "North Carolina", value: "NC"},
    {label: "North Dakota", value: "ND"},
    {label: "Ohio", value: "OH"},
    {label: "Oklahoma", value: "OK"},
    {label: "Oregon", value: "OR"},
    {label: "Pennsylvania", value: "PA"},
    {label: "Rhode Island", value: "RI"},
    {label: "South Carolina", value: "SC"},
    {label: "South Dakota", value: "SD"},
    {label: "Tennessee", value: "TN"},
    {label: "Texas", value: "TX"},
    {label: "Utah", value: "UT"},
    {label: "Vermont", value: "VT"},
    {label: "Virginia", value: "VA"},
    {label: "Washington", value: "WA"},
    {label: "West Virginia", value: "WV"},
    {label: "Wisconsin", value: "WI"},
];

// DEFINE PAGE SIZE BY VIEW
const DEFAULT_PAGE_SIZE_IN_BACKEND = 20
export const CUSTOM_PAGE_SIZE_IN_BACKEND = 50
export const ADMIN_GRID_PAGE_SIZE = DEFAULT_PAGE_SIZE_IN_BACKEND
export const AGENT_REGISTERED_PAGE_SIZE = 50
export const AGENT_UNREGISTERED_PAGE_SIZE = 50
export const STATS_REVENUE_PAGE_SIZE = 50
export const STATS_PERFORMED_PAGE_SIZE = 50
export const FRANCHISE_PAGE_SIZE = 20
export const BROKER_PAGE_SIZE = 20
export const OFFICES_PAGE_SIZE = 50
export const JOB_TYPE_PAGE_SIZE = 20
export const AGENT_FEEDBACK_PAGE_SIZE = 20
export const CUSTOM_FEEDBACK_PAGE_SIZE = 20
export const CAMPAIGNS_PAGE_SIZE = 20

export const LOCKED_BY_BROKER = 1
export const LOCKED_BY_ADMIN = 5
export const LOCKED_BY_MULTIPLE_CANCELLATION = 10
export const LOCKED_BY_NO_SHOW = 15
export const BROKER_WAS_DELETED_BY_ADMIN = 20
export const BROKER_WAS_LOCKED_BY_ADMIN = 25
export const OFFICE_WAS_DELETED_BY_ADMIN = 30
export const OFFICE_WAS_LOCKED_BY_ADMIN = 35
export const OFFICE_WAS_LOCKED_BY_BROKER = 40
export const WAS_DELETED_BY_ADMIN = 45
export const ROSTER_LIST_ISSUE = 50
export const INFLUENCER_WAS_DELETED_BY_ADMIN = 55
export const ACCOUNT_WAS_DELETED_BY_AGENT = 60


export const REASON_LOCKED_BY = [
    {value: LOCKED_BY_BROKER, label: 'By ShowIT Broker'},
    {value: LOCKED_BY_ADMIN, label: 'By ShowIT Admin'},
    {value: LOCKED_BY_MULTIPLE_CANCELLATION, label: 'Multiple cancellation'},
    {value: LOCKED_BY_NO_SHOW, label: 'No Show'},
    {value: BROKER_WAS_DELETED_BY_ADMIN,label: 'Broker was deleted by ShowIT'},
    {value: BROKER_WAS_LOCKED_BY_ADMIN,label: 'Broker was locked by ShowIT'},
    {value: OFFICE_WAS_DELETED_BY_ADMIN,label: 'Office was deleted by ShowIT'},
    {value: OFFICE_WAS_LOCKED_BY_ADMIN,label: 'Office was locked by ShowIT'},
    {value: OFFICE_WAS_LOCKED_BY_BROKER,label: 'Office was locked by Broker'},
    {value: WAS_DELETED_BY_ADMIN,label: 'Agent was deleted by ShowIT'},
    {value: ROSTER_LIST_ISSUE, label: 'Roster list issue'},
    {value: INFLUENCER_WAS_DELETED_BY_ADMIN, label: 'Influencer was deleted by ShowIT'},
    {value: ACCOUNT_WAS_DELETED_BY_AGENT, label: 'Account was deleted by Agent'},
]

//  BROKERAGE REASON LOCKED
export const BROKERAGE_LOCKED_BY_BROKER = 1
export const BROKERAGE_LOCKED_BY_ADMIN = 5
export const BROKERAGE_BROKER_WAS_DELETED_BY_ADMIN = 10
export const BROKERAGE_BROKER_WAS_LOCKED_BY_ADMIN = 15
export const BROKERAGE_WAS_DELETED_BY_ADMIN = 20
export const BROKERAGE_REASON_LOCKED_BY = [
    {value: BROKERAGE_LOCKED_BY_BROKER, label: 'Locked by Broker'},
    {value: BROKERAGE_LOCKED_BY_ADMIN, label: 'Locked by ShowIT'},
    {value: BROKERAGE_BROKER_WAS_DELETED_BY_ADMIN, label: 'Broker was Deleted by ShowIT'},
    {value: BROKERAGE_BROKER_WAS_LOCKED_BY_ADMIN, label: 'Broker was locked by ShowIT'},
    {value: BROKERAGE_WAS_DELETED_BY_ADMIN, label: 'Office was deleted by ShowIT'}
]

export const MANAGING = 1
export const QUALIFYING = 5
export const OFFICE_MANAGER = 10
export const AGENT_COORDINATOR = 15
export const MCA_FINANCE = 20
export const PRODUCTIVITY_COACH = 25
export const STAFF = 30

export const USER_ROLES_LIST = [
    {value: MANAGING, label: "Owner"},
    {value: OFFICE_MANAGER, label: "Office Manager"},
    {value: AGENT_COORDINATOR, label: "Agent Coordinator"},
    {value: MCA_FINANCE, label: "MCA Finance"},
    {value: STAFF, label: "Staff"},
];

//  BROKER ROLES
export const OWNER_BROKER = 1
export const MANAGING_BROKER = 5
export const QUALIFYING_BROKER = 10
export const OPERATING_PARTNER = 15

export const BROKER_ROLES_LIST = [
    {value: OWNER_BROKER, label: "Owner Broker"},
    {value: MANAGING_BROKER, label: "Managin Broker"},
    {value: QUALIFYING_BROKER, label: "Qualifying Broker"},
    {value: OPERATING_PARTNER, label: "Operating Partner"},
];
//  BROKER OPERATIONAL MODEL
export const WITHIN_YOUR_BROKERAGE = 1
export const WITHIN_YOUR_FRANCHISE = 5
export const OPEN_MARKETING = 10
export const BROKER_OPERATIONAL_MODE_LIST = [
    {value: WITHIN_YOUR_BROKERAGE, label: "Within the office / Group"},
    {value: WITHIN_YOUR_FRANCHISE, label: "Franchise"},
    {value: OPEN_MARKETING, label: "Open Market"},
]

//  MULTI SELECT (filters)
export const SELECT_ALL_OPTION = {
    value: "<SELECT_ALL>",
    label: "Select All"
};

//  BROKERAGE STATUSS
export const BROKERAGE_ACTIVE = 100;
export const BROKERAGE_LOCKED = 200;
export const BROKERAGE_BLOCKED = 300;
export const BROKERAGE_STATUSES = [
    {value: BROKERAGE_ACTIVE, label: "Active"},
    {value: BROKERAGE_LOCKED, label: "Locked"},
    {value: BROKERAGE_BLOCKED, label: "Blocked"},
]

// KEYS
export const GOOGLE_MAPS_KEY = 'AIzaSyC23DZRQF9GPKdoXNl6afwNrZTAra-RkLk';

//  SHOWING ANSWERS
export const ANSWERS_SHOWING = [
    {id: 1, name: 'House was shown', checked: false},
    {id: 2, name: 'Agent arrived to the property but Seller denied entry', checked: false},
    {id: 3, name: 'Agent did not arrive to the property because Seller refused showing', checked: false},
    {id: 4, name: 'Agent arrived to the property but buyer decided not to view', checked: false},
    {id: 5, name: 'Agent did not arrive to the property because buyer decided not to view', checked: false},
    {id: 6, name: 'Other - See Notes', checked: false},
];
export const ANSWERS_SHOWING_WAS_NOT_SHOWN = [
    {id: 2, name: 'Agent arrived to the property but Seller denied entry', checked: false},
    {id: 3, name: 'Agent did not arrive to the property because Seller refused showing', checked: false},
    {id: 4, name: 'Agent arrived to the property but buyer decided not to view', checked: false},
    {id: 5, name: 'Agent did not arrive to the property because buyer decided not to view', checked: false},
    {id: 6, name: 'Other - See Notes', checked: false},
];

export const PROFESSION_PENDING = 100
export const PROFESSION_APPROVED = 200
export const PROFESSION_REJECTED = 300
export const PROFESSION_REMOVED = 400

export const PROFESSION_EDIT_FROM_SIGN_UP = 10
export const PROFESSION_EDIT_FROM_ADMIN = 20
export const PROFESSION_EDIT_FROM_VENDOR = 30