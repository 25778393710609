import React, { Component } from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CounterOfferForm from './CounterOfferForm';

export default class OpenHouseText extends Component {
    state = {
    }

    componentDidMount() {
        this.props.getSettings('counterOfferForm')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {loader, onSubmitCounterOffer} = this.props

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Counter Offer</div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <CounterOfferForm
                            goBack={this.goBack}
                            loader={loader}
                            onSubmit={(data) => {
                                console.log("DATA:" , data);
                                onSubmitCounterOffer(data)
                            }}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}