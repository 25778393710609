import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

class StepProgressBar extends React.Component {
    render() {
        const { progress } = this.props;

        return (
            <div className="justify-content-center d-flex px-4 mb-4">
                <ProgressBar
                    percent={ progress }
                    filledBackground="#7E8A97"
                    unfilledBackground="#C7C7C7"
                    height={ 6 }
                    width={ '30%' }
                    transitionDuration= { 500 }
                >
                    <Step transition="scale">
                        { ( { accomplished } ) => (
                            <div style={ {width: "5px", height: "15px", backgroundColor: "white"}}> </div>
                            // <img
                            //     style={ { filter: `grayscale(${ accomplished ? 0 : 0 }%)` } }
                            //     width="30"
                            //     src="https://vignette.wikia.nocookie.net/pkmnshuffle/images/9/9d/Pichu.png/revision/latest?cb=20170407222851"
                            // />
                        ) }
                    </Step>
                    <Step transition="scale">
                        { ( { accomplished } ) => (
                            <div style={ {width: "5px", height: "15px", backgroundColor: "white"}}> </div>
                        ) }
                    </Step>
                    <Step transition="scale">
                        { ( { accomplished } ) => (
                            <div style={ {width: "5px", height: "15px", backgroundColor: "white"}}> </div>
                        ) }
                    </Step>
                    <Step transition="scale">
                        { ( { accomplished } ) => (
                            <div style={ {width: "5px", height: "15px", backgroundColor: "white"}}> </div>
                        ) }
                    </Step>
                </ProgressBar>
            </div>
        )
    }
}

export default StepProgressBar;
