import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const LoginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="form-validate mb-lg w-75 mx-auto" onSubmit={handleSubmit}>
            <div className="form-group has-feedback text-center mb-3 mt-1 mx-2">
                <label htmlFor="email">E-mail</label>
                <Field
                    name="email"
                    label="Email"
                    component={renderField}
                    type="email"
                    className="form-control"
                    customClass="form-control-gray text-center mx-0"
                />
            </div>

            <div className="form-group has-feedback text-center my-3 mx-2">
                <label htmlFor="password">Password</label>
                <Field
                    customClass="form-control-gray text-center"
                    name="password"
                    label="Password"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <div className="form-group has-feedback text-center pt-2 mx-2">
                <button type="submit" className="btn button-01 w-100 ">Sign up</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('This field is required'),
            password: validators.exists()('This field is required'),
        });
    },
})(LoginForm);
