import React, {Component} from 'react';
import ChangedPasswordForm from "./ChangedPasswordForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ChangedPassword extends Component {

    static defaultProps = {
        loading: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            type: 'agent',
        };

    }

    componentDidMount() {
        const {match, read, readBrokerUser, getAgentUser, getVendor, getWholeSaler} = this.props;
        if (match.params.type) {
            this.setState({type: match.params.type})
            if (match.params.type == "influencer") {
                getAgentUser(match.params.id)
            } else if (match.params.type == "vendors") {
                getVendor(match.params.id)
            } else if (match.params.type == "wholesaler") {
                getWholeSaler(match.params.id)
            }
            else {
                readBrokerUser(match.params.id)
            }
        }else{
            read(match.params.id);
        }
    }

    goBack = () => {
        const {history} = this.props;
        history.goBack();
    }

    sendData = (data) => {
        const {item, changedPasse, changedBrokerPassword, changedInfluencerPassword, changedWholeSalerPassword} = this.props;
        const {type} = this.state;
        if (type !== "agent" && type !== "influencer") {
            changedBrokerPassword(item.id, data, this.goBack);
        } else if (type === "influencer") {
            changedInfluencerPassword(
                item.id,
                { ...data, email: item.email },
                this.goBack
            );
        } else if (type === "wholesaler") {
            changedWholeSalerPassword(
                item.id,
                { ...data, email: item.email },
                this.goBack
            )
        } 
        else {
            changedPasse(item.id, { ...data, email: item.email });
        }
    }

    showPass = (value) => {
        this.setState({showPassword: value})
    }

    render() {
        const {loader, item} = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">Change Password</div>
                    </div>
                </div>
                <div className="card flex-1 border-dark border-card-page px-4 py-2">
                    <LoadMask loading={loader} dark blur>
                        <ChangedPasswordForm
                            loader={loader}
                            goBack={this.goBack}
                            onSubmit={this.sendData}
                            item={item}
                            type={this.state.type}
                            showPassAction={this.showPass}
                            showPassword={this.state.showPassword}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ChangedPassword;
