import _ from "lodash"
import React, {useState, useEffect} from "react";
import moment from "moment"
import Grid from "../../../Utils/GridTable";
import Avatar from "react-avatar";
import {reduxForm, Field} from 'redux-form';
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import {
    AGENT_REGISTERED_PAGE_SIZE,
    CATEGORY_REGISTERED_LIST,
    REGISTERED_PENDING, REGISTERED_ACTIVE, REGISTERED_LOCKED, REGISTERED_BLOCKED, REASON_LOCKED_BY,
    ACCOUNT_WAS_DELETED_BY_AGENT,
} from "../../../../../utility/constants";


const DeletationModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;
    //  TYPES
    const NO_LONGER_LICENSED_REAL_STATE_AGENT = 10
    const NO_LONGER_WANTING_TO_USE_THIS_PLATFORM = 20
    const SIGNED_UP_BY_MISTAKE = 30
    const OTHER = 40
    
    const TYPES = [
        {value: NO_LONGER_LICENSED_REAL_STATE_AGENT, label: 'No Longer a Licensed Real Estate Agent'},
        {value: NO_LONGER_WANTING_TO_USE_THIS_PLATFORM, label: 'No Longer Wanting to Use This Platform'},
        {value: SIGNED_UP_BY_MISTAKE, label: 'Signed Up by Mistake'},
        {value: OTHER, label: 'Other'}
    ]

    const getReason = () => {
        let reason = ""
        if (data && data.type) {
            const item = _.find(TYPES, {value: data.type})

            reason = item ? item.label : ""
        }

        return reason
    }

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-5 col-11",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Reason for Deletion
                        </div>
                    </div>
                    {data && (
                        <div className="w-100 col-sm-12">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label htmlFor="">Type</label>
                                    <div className='form-control p-2'>
                                        {getReason()}
                                    </div>
                                </div>
                                <div className="col-sm-12 mt-3">
                                    <label htmlFor="">Reason</label>
                                    <div
                                        className="rounded border p-2"
                                        style={{ minHeight: "50px", fontSize: "12px", whiteSpace: "pre-line" }}
                                    >
                                        {data.reason ? data.reason : "N/A"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

const DeletedAgentList = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        // tabContainerHeight,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)
    const [deletationModal, setDeletationModal] = useState(false)
    const [deletationData, setDeletationData] = useState(null)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("DELETE-AGENT-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    const openModal = (row) => {
        const data = {type: row.delete_type, reason: row.reason_delete}
        setDeletationData(data)
        setDeletationModal(true)
    }

    const closeDeletationModal = () => {
        setDeletationData(null)
        setDeletationModal(false)
    }

    return (
        <div id="DELETE-AGENT-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={AGENT_REGISTERED_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "photo",
                            formatter: avatar(),
                            text: " ",
                        },
                        {
                            dataField: "brokerage_information",
                            formatter: formatStatusAccount(),
                            text: "Status",
                        },
                        {
                            dataField: "first_name",
                            sort: true,
                            text: "First Name",
                        },
                        {
                            dataField: "last_name",
                            sort: true,
                            text: "Last Name",
                        },
                        {
                            dataField: "email",
                            sort: false,
                            text: "E-mail",
                        },
                        {
                            dataField: "mobile_number",
                            formatter: formatPhoneNumber(),
                            text: "Phone",
                        },
                        {
                            dataField: "real_estate_licence",
                            sort: true,
                            text: "License",
                        },
                        {
                            dataField: "total_performed",
                            sort: false,
                            align: "right",
                            text: "Total Performed",
                        },
                        {
                            dataField: "total_posted",
                            sort: false,
                            align: "right",
                            text: "Total Posted",
                        },
                        {
                            dataField: "total_reviews",
                            sort: false,
                            align: "right",
                            text: "Total Reviews",
                        },
                        {
                            dataField: "previous_broker",
                            sort: false,
                            formatter: formatPreviousBroker(),
                            text: "Previous Broker Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "150px",
                                };
                            },
                        },
                        {
                            dataField: "previous_offices",
                            sort: false,
                            formatter: formatPreviousOffice(),
                            text: "Previous Office Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "150px",
                                };
                            },
                        },
                        {
                            dataField: "previous_franchise",
                            sort: false,
                            formatter: formatPreviousFranchise(),
                            text: "Previous Franchise",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                };
                            },
                        },
                        {
                            dataField: "updated",
                            sort: false,
                            formatter: formatDateOfDeletion(),
                            text: "Date of deletion",
                        },
                        {
                            dataField: 'reason_locked',
                            formatter: setReason(openModal),
                            text: 'Reason'
                        },
                        {
                            dataField: "id",
                            formatter: formatReinstate(),
                            text: "",
                        },
                    ]}
                />
            </div>

            <DeletationModal open={deletationModal} data={deletationData} onClose={closeDeletationModal} />
        </div>
    );

};

function brokerName() {
    return (cell, row) => {
        if (cell && cell.id) {
            return (
                <Link
                onClick={(e) => e.stopPropagation()}
                to={`brokers/broker/${cell.id}/edit`}
                className="w-100 text-primary"
                >
                    {cell ? cell.owner_first_name + ' ' + cell.owner_last_name : ''}
                </Link>
            )
        }
        return (
            <span></span>
        )
    };
}

export function formatStatusLocked(blockUser, unblockUser, activeUser) {
    return (cell, row) => {
        if (cell === true) {
            return (
                <div className="row" style={{ width: '9rem' }}>
                    <button
                        onClick={(e) => {
                            e.stopPropagation()
                            unblockUser(row)
                        }}
                        className="btn btn-danger px-0 d-inline mx-0"
                        style={{ fontSize: '10px !important' }}
                    >
                        Unlock User
                    </button>
                </div>
            )
        }else {
            return (
                <div className="row" style={{width: '9rem'}}>
                    {row.agent_account_status == 10 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                // activeUser(row)
                                activeUser(`/agents/agent/${row.id}/edit`)
                            }}
                            className={`btn btn-info px-4 d-inline mx-0`}
                        >
                            Activate User
                        </button>
                    )}
                    {row.agent_account_status == 20 && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation()
                                blockUser(row)
                            }}
                            className="btn btn-danger px-0 d-inline mx-0"
                            style={{ fontSize: '10px !important' }}
                        >
                            Lock User
                        </button>
                    )}
                </div>
            );

        }
    };
}

export function formatStatus() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <p className={`my-0 text-capitalize text-${cell === 'pending' ? 'danger' : 'success'}`}>
                    {cell}
                </p>
            </React.Fragment>
        )
    };
}

export function formatBrokerage() {
    return (cell, row) => {
        if (row.brokerage_information && row.brokerage_information.id) {
            return (
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`offices/${row.brokerage_information.id}/edit`}
                    className="w-100 text-primary"
                >
                    {cell}
                </Link>
            )
        }
        return <span></span>
    };
}

// export function formatReviews() {
//     return (cell, row) => {
//         return (
//             <Link
//                 onClick={(e) => e.stopPropagation()}
//                 to={`/agents/agent-reviews/${row.id}`}
//                 className="w-100 text-primary"
//             >
//                 {cell}
//             </Link>
//         )
//     };
// }

export function formatStatusAccount() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            CATEGORY_REGISTERED_LIST.forEach(item => {
                if (row.agent_account_status === item.value) {
                    text = item.label
                }
            })
            return text
        }
        const getColor = () => {
            let colorText = 'black'
            if (row.agent_account_status === REGISTERED_PENDING) {
                colorText = 'warning'
            } else if (row.agent_account_status === REGISTERED_ACTIVE) {
                colorText = 'success'
            } else if (row.agent_account_status === REGISTERED_LOCKED) {
                colorText = 'danger'
            } else if (row.agent_account_status === REGISTERED_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <span className={`text-capitalize font-weight-bold text-${getColor()}`}>
                    {getStatus()}
                </span>
                {/* {row.blocked === true ? (
                    <span className={`text-danger font-weight-bold`}>
                        Blocked
                    </span>
                ): (
                    row.locked === true ? (
                        <span className={`text-danger font-weight-bold`}>
                            Locked
                        </span>
                    ): (
                        <React.Fragment>
                            {row.agent_account_status == 10 && (
                                <span className="text-warning font-weight-bold">Pending</span>
                            )}
                            {row.agent_account_status == 20 && (
                                <span className="text-success font-weight-bold">Active</span>
                            )}
                        </React.Fragment>
                    )
                )} */}
            </React.Fragment>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.first_name}
                />
            </div>
        );
    };
}

function setReason(openModal=null) {
    return (cell, row) => {
        let reason = ''
        if (cell) {
            REASON_LOCKED_BY.forEach((item) => {
                if (item.value === cell) (reason = item.label)
            })
        }

        return (
            <div className="d-flex flex-row align-items-center justify-content-between" style={{width: '150px', overflow: 'hidden'}}>
                <span className="text-danger font-weight-bold">
                    {reason}
                </span>
                {cell == ACCOUNT_WAS_DELETED_BY_AGENT && (
                    <i className="fa fa-eye bg-danger text-white p-2 rounded pointer" onClick={(e) => openModal(row)}></i>
                )}
            </div>
        );
    };
}

export function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}
function formatPreviousBroker() {
    return (cell, row) => {
        let result = "";
        return (
            <div>
                {row.previous_offices && row.previous_offices.map((item, index) => {
                    if (index == 0) {
                        return (
                            <React.Fragment>
                                {item.broker_is_active ? (
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`brokers/broker/${item.broker_id}/edit`}
                                        className="w-100 text-primary"
                                    >
                                        {item.broker}
                                    </Link>
                                ): (
                                    <span>
                                        {item.broker}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment>
                            <span>, </span>
                            {item.broker_is_active ? (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`brokers/broker/${item.broker_id}/edit`}
                                    className="w-100 text-primary"
                                >
                                    {item.broker}
                                </Link>
                            ) : (
                                <span>{item.broker}</span>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        )
    };
}

function formatPreviousOffice() {
    return (cell, row) => {
        let result = "";

        // if (row.previous_offices && row.previous_offices.length) {
        //     row.previous_offices.forEach((item, index) => {
        //         if (index == 0) {
        //             result += `${item.office}`
        //         }else {
        //             result += `, ${item.office}`
        //         }
        //     })
        // }

        return (
            <div>
                {row.previous_offices && row.previous_offices.map((item, index) => {
                    if (index == 0) {
                        return (
                            <React.Fragment>
                                {item.is_active ? (
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`offices/${item.id}/edit`}
                                        className="w-100 text-primary"
                                    >
                                        {item.office}
                                    </Link>
                                ): (
                                    <span>
                                        {item.office}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment>
                            <span>, </span>
                            {item.is_active ? (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`offices/${item.id}/edit`}
                                    className="w-100 text-primary"
                                >
                                    {item.office}
                                </Link>
                            ) : (
                                <span>{item.office}</span>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        )
    };
}

function formatPreviousFranchise() {
    return (cell, row) => {
        let result = "";

        if (row.previous_offices && row.previous_offices.length) {
            row.previous_offices.forEach((item, index) => {
                if (index == 0) {
                    result += `${item.franchise}`
                }else {
                    result += `, ${item.franchise}`
                }
            })
        }

        return (
            <span>{result}</span>
        )
    };
}

function formatDateOfDeletion() {
    return (cell, row) => {

        return (
            <span>{moment(cell).format('ll')}</span>
        )
    };
}

function formatReinstate() {
    return (cell, row) => {
        return (
            <div className="w-100">
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`deleted-agents/${cell}/reinstate`}
                    className="w-100 btn btn-danger text-white"
                >
                    Reinstate
                </Link>
            </div>
        );
    }
}

export default DeletedAgentList;
