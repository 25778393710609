import React from "react";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderField,
    SelectField,
    ColorPicker,
} from "../../../Utils/renderField/renderField";

const SEVERITY = 200
const STATUS = 400

const validate = (values, props) => {
    let errors = {};
    const {is_update, item} = props;

    if (!values.name) {
        errors.name = "This field is required";
    }
    if (!values.type) {
        errors.type = "This field is required";
    } else {
        if (values.type == SEVERITY || values.type == STATUS) {
            if (!values.color) {
                errors.color = "This field is required";
            }
        }
    }

    return errors;
};

const Form = (props) => {
    //  State
    const { handleSubmit, is_update, item, typeValue } = props;
    //  Bind
    const { goBack } = props;
    const options = [
        {label: "Owner", value: 100},
        {label: "Severity", value: SEVERITY},
        {label: "Type", value: 300},
        {label: "Status", value: 400},
        {label: "Agreement Signed", value: 500}
    ]

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto mt-3 w-75">
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="name">Name</label>
                    <Field
                        name="name"
                        label="name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="type">Type</label>
                    <Field
                        name="type"
                        id="type"
                        placeholder="Select..."
                        options={options}
                        component={SelectField}
                        disabled={false}
                        type="text"
                        className="form-control"
                    />
                </div>
                {typeValue == SEVERITY || typeValue == STATUS ? (
                    <div className="col-lg-6 col-sm-12 form-group has-feedback">
                        <label htmlFor="color">Color</label>
                        <Field
                            name="color"
                            id="color"
                            placeholder="Primary Color"
                            component={ColorPicker}
                            type="text"
                            className="form-control"
                        />
                    </div>
                ): null}
                {/* <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12 pl-4">
                    <label htmlFor="reason_required" className="mt-4 mb-3">Reason is required?</label>
                    <Field
                        name="reason_required"
                        label=""
                        component={renderSwitch}
                        type="checked"
                    />
                </div> */}
            </div>
            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <button
                    onClick={goBack}
                    type="button"
                    className="btn text-white btn-secondary  mx-3 col-2"
                >
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary mx-3 col-2">
                    Save
                </button>
            </div>
        </form>
    );
};

const TrackerFieldFrom = reduxForm({
    form: "trackerFieldFrom", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector('trackerFieldFrom');

const mstp = state => {
    const typeValue = selector(state, 'type')
    return {
        typeValue,
    }
}

export default connect(mstp, null)(TrackerFieldFrom);
