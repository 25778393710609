import React, {useState, useEffect} from 'react';
import moment from "moment";
import Avatar from "react-avatar";
import HeaderCard from "../../HeaderCard";
import Grid from "../../Utils/GridTableWithScroll";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {CAMPAIGNS_PAGE_SIZE} from "../../../../utility/constants";
import AppReviewFilterForm from './AppReviewFilterForm';

const AppReviewGrid = (props) => {
    const {data, loader, page, searchAction, toList, remove} = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("REVIEW-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">App Reviews</div>
                </div>
            </div>
            <AppReviewFilterForm 
                searchAction={searchAction}
            />
            <div id="REVIEW-LIST" className="container-list card border-dark border-card-page px-4 py-2">
                <div className="card-body d-flex flex-column px-0 py-2">
                    <Grid
                        hover
                        height={gridHeight}
                        striped
                        page={page}
                        keyField={'id'}
                        loading={loader}
                        data={data ? data : []}
                        wrapperClasses="table-scroll-auto"
                        sizePerPage={CAMPAIGNS_PAGE_SIZE}
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "agent__photo",
                                formatter: avatar(),
                                text: " ",
                            },
                            {
                                dataField: "created",
                                formatter: dateCreated(),
                                text: "Date created",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                    };
                                },
                            },
                            {
                                dataField: "agent__first_name",
                                sort: false,
                                text: "Agent Name",
                                formatter: (cell, row) => {
                                    return (
                                        <p className="my-0">
                                            {row.agent ? `${row.agent.first_name} ${row.agent.last_name}` : ""}
                                        </p>
                                    );
                                },
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "agent__email",
                                sort: false,
                                text: "Agent Email",
                                formatter: (cell, row) => {
                                    return (
                                        <p className="my-0">
                                            {row.agent ? row.agent.email : ""}
                                        </p>
                                    );
                                },
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "agent__broker",
                                sort: false,
                                text: "Agent Broker",
                                formatter: (cell, row) => {
                                    return (
                                        <p className="my-0">
                                            {row.agent ? row.agent.broker : ""}
                                        </p>
                                    );
                                },
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "agent__office",
                                sort: false,
                                text: "Agent Office",
                                formatter: (cell, row) => {
                                    return (
                                        <p className="my-0">
                                            {row.agent ? row.agent.office : ""}
                                        </p>
                                    );
                                },
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "how_like",
                                sort: false,
                                text: "Probability to recommend",
                                formatter: (cell, row) => {
                                    return (
                                        <div className='d-flex flex-row align-items-center'>
                                            {Array(cell).fill(0).map((_, i) => (
                                                <i key={i} className="fa fa-star text-warning px-1" style={{fontSize: '15px'}}></i>
                                            ))} 
                                        </div>
                                    );
                                },
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "how_satisfied",
                                sort: false,
                                text: "Experience",
                                formatter: (cell, row) => {
                                    return (
                                        <p className="my-0 text-justify">
                                            {cell}
                                        </p>
                                    );
                                },
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "200px",
                                    };
                                },
                            },
                            {
                                dataField: "let_us",
                                sort: false,
                                text: "Improvements",
                                formatter: (cell, row) => {
                                    return (
                                        <p className="my-0 text-justify">
                                            {cell}
                                        </p>
                                    );
                                },
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "200px",
                                    };
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('LLL')}
            </p>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={row.agent ? row.agent.photo : null}
                    round={true}
                    name={row.agent ? row.agent.first_name : ""}
                />
            </div>
        );
    };
}

export default AppReviewGrid;