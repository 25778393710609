import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray } from "redux-form";
import { formValueSelector } from "redux-form";
import {
    renderField,
    renderDayPicker,
    renderTextArea,
    SelectField,
} from "../../../Utils/renderField";
//  TYPES
const SENDGRID = 100
const TWILIO = 200

const validate = (values, props) => {
    let errors = {};
    if (!values.name) {
        errors.name = "This field is required";
    }
    // console.log("VALUES: ", values);
    if (values.campaign_emails && values.campaign_emails.length) {
        let emailErrors = [];
        values.campaign_emails.forEach((email, indexB) => {
            emailErrors[indexB] = {};
            if(email.type == SENDGRID) {
                if (!email.template_id) {
                    emailErrors[indexB] = {
                        template_id: "This field is required",
                    };
                }
            }
            if(email.type == TWILIO) {
                if (!email.text_message) {
                    emailErrors[indexB] = {
                        text_message: "This field is required",
                    };
                }
            }
            if (!email.date) {
                emailErrors[indexB] = {
                    ...emailErrors[indexB],
                    date: "This field is required",
                };
            }
            if (!email.time) {
                emailErrors[indexB] = {
                    ...emailErrors[indexB],
                    time: "This field is required",
                };
            }else {
                const timePattern = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/
                if (!email.time.match(timePattern)) {
                    emailErrors[indexB] = {
                        ...emailErrors[indexB],
                        time: "Invalid format, Ex: 13:00:00",
                    };
                }
            }
            
            if (!emailErrors[indexB]) {
                emailErrors[indexB] = undefined;
            }
        });
        errors.campaign_emails = emailErrors;
    } else {
        errors.campaign_emails = { _error: "At least one email is required" };
    }

    return errors;
};

const emailList = ({
    seeView,
    typesEmails,
    fields,
    meta: { error, submitFailed },
}) => {
    const isInvalid = error && submitFailed;

    return (
        <React.Fragment>
            {fields.map((item, index) => {
                const itemValues = fields.get(index)
                return (
                    <div
                        key={index}
                        className="form-group has-feedback col-12 mt-auto py-2 border"
                    >
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <label htmlFor=""> #{index + 1}</label>
                            {itemValues && itemValues.type == TWILIO ? (
                                <i className="material-icons ml-2">sms</i>
                            ): (
                                <i className="material-icons ml-2">email</i>
                            )}
                        </div>
                        <div className="row">
                            <div className="form-group has-feedback col-md-4 col-sm-12 mt-auto">
                                {itemValues && itemValues.type == SENDGRID && (
                                    <React.Fragment>
                                        <label htmlFor={`${item}.template_id`}>
                                            Email Template
                                        </label>
                                        <Field
                                            name={`${item}.template_id`}
                                            isMulti={false}
                                            isClearable={false}
                                            options={typesEmails}
                                            component={SelectField}
                                            type="text"
                                            className="form-control"
                                            disabled={seeView}
                                        />
                                    </React.Fragment>
                                )}
                                {itemValues && itemValues.type == TWILIO && (
                                    <React.Fragment>
                                        <label htmlFor={`${item}.text_message`}>
                                            SMS
                                        </label>
                                        <Field
                                            name={`${item}.text_message`}
                                            label="SMS"
                                            component={renderTextArea}
                                            type="text"
                                            customClass="form-control text-area-h-auto"
                                            disabled={seeView}
                                            rows={3}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="form-group has-feedback col-md-4 col-sm-12 mt-auto">
                                <label htmlFor={`${item}.date`}>Date</label>
                                <Field
                                    name={`${item}.date`}
                                    placeholder=""
                                    component={renderDayPicker}
                                    disabled={seeView}
                                    initialDate={""}
                                    type="text"
                                    outputFormatt="YYYY-MM-DD"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-3 col-sm-12 mt-auto">
                                <label htmlFor={`${item}.time`}>Time</label>
                                <Field
                                    name={`${item}.time`}
                                    placeholder="HH:MM:SS"
                                    component={renderField}
                                    disabled={seeView}
                                />
                            </div>
                            <div className="form-group has-feedback col-md-1 px-0 d-flex flex-row align-items-center justify-content-center">
                                {!seeView && (
                                    <img
                                        src={require("../../../../../../assets/img/delete.svg")}
                                        alt="del"
                                        title="Remove"
                                        width={20}
                                        onClick={() => fields.remove(index)}
                                        className="ml-1 pointer"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
            {!seeView && (
                <div className="col-12">
                    <button
                        className="btn btn-success"
                        type="button"
                        onClick={() =>
                            fields.push({
                                type: SENDGRID,
                            })
                        }
                    >
                        Add Email
                    </button>
                    <button
                        className="btn btn-success ml-3"
                        type="button"
                        onClick={() =>
                            fields.push({
                                type: TWILIO,
                            })
                        }
                    >
                        Add SMS
                    </button>
                </div>
            )}
            {isInvalid && (
                <div className="invalid-feedback d-block col-12">{error}</div>
            )}
        </React.Fragment>
    );
};

const SendgridCustomCampaignForm = (props) => {
    //  State
    const { is_view, is_update, item, loader, typesEmails } = props;
    //  Bind
    const { handleSubmit, goBack } = props;

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-1"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="form-group has-feedback col-lg-8 col-sm-12">
                    <label htmlFor="name">Name</label>
                    <Field
                        name="name"
                        label="Name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={is_view}
                    />
                </div>
                <div className="form-group has-feedback col-lg-12    col-sm-12">
                    <label htmlFor="campaign_emails">Emails</label>
                    <div className="row">
                        <FieldArray
                            name="campaign_emails"
                            component={emailList}
                            seeView={is_view}
                            typesEmails={typesEmails}
                        />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className={`row my-3`}>
                        <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-center justify-content-center my-2">
                            <button
                                type="button"
                                className="btn btn-danger text-white w-100"
                                onClick={goBack}
                            >
                                Back
                            </button>
                        </div>
                        {!is_view && (
                            <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-center justify-content-center my-2">
                                <button
                                    className={`btn btn-success w-100`}
                                    disabled={loader}
                                >
                                    {is_update ? "update" : "create"}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

let SendgridCustomCampaignReduxForm = reduxForm({
    form: "sendgridCustomCampaignFrom", // a unique identifier for this form
    validate,
})(SendgridCustomCampaignForm);

const selector = formValueSelector("sendgridCustomCampaignFrom");
SendgridCustomCampaignReduxForm = connect((state) => {
    return {};
})(SendgridCustomCampaignReduxForm);

export default SendgridCustomCampaignReduxForm;
