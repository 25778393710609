import { connect } from 'react-redux'
import TutorialVideoCreateUpdate from './TutorialVideoCreateUpdate'
import {actions} from '../../../../redux/modules/tutorialVideo'

const mapStateToProps = (state) => ({
    ...state.tutorialVideos
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorialVideoCreateUpdate)