import React, { Component } from 'react'
import CancellationDeleteOptionFilters from './CancellationDeleteOptionFilters'
import CancellationDeleteOptionGrid from './CancellationDeleteOptionGrid'

class CancellationDeleteOptionList extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount() {

    }

    componentDidMount() {
        this.props.toList();
    }

    render() {
        // State
        const {loader, page, data} = this.props;
        //  Bind
        const {toList, searchChange, onSortChange, remove} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Cancellation or Agent Delete Option</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <CancellationDeleteOptionFilters searchAction={searchChange} />
                </div>

                <CancellationDeleteOptionGrid data={data} page={page} loader={loader} toList={toList} onSortChange={onSortChange} remove={remove}/>
            </div>
        )
    }
}

export default CancellationDeleteOptionList