import React, { Component } from "react";
import FilterStats from "./FilterStat";
import StatsRevenueGrid from "./StatsRevenueGrid";

class StatsRevenue extends Component {
    componentDidMount() {
        const {getFranchisesOption, getBrokerOption, getJobTypesOption, getAgentsByOffice} = this.props;

        getFranchisesOption(null, true)
        getBrokerOption(true, true)
        getJobTypesOption(true);
        getAgentsByOffice(null, true)
    }

    changePage = (page) => {
        this.props.toList(page)
    };

    render() {
        //  State
        const {
            loader,
            data,
            franchises,
            brokers,
            brokerages,
            jobTypes,
            agents,

            dataJobType,
            dataAgentName,
            dataOfficeName,
            setJobTypeFilter,
            getAgentsByOffice,
            dataBrokerName,
            setFilterBroker,
        } = this.props;
        //  Bind
        const {changeFilters} = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">Stats / Revenue</div>
                    </div>
                    <div className="row justify-content-end align-content-end align-items-end pr-3 pb-1">
                        <h5 className="font-weight-bold mr-4 text-dark">
                            Total USD: {data && data.usd ? data.usd : 0.0}
                        </h5>
                    </div>
                </div>
                <div className="col-sm-12">
                    <FilterStats
                        optionFranchises={franchises}
                        optionBrokers={brokers}
                        optionBrokerages={brokerages}
                        optionJobTypes={jobTypes}
                        optionAgents={agents}

                        changeFilters={changeFilters}
                    />
                </div>
                <StatsRevenueGrid
                    data={data}
                    loader={loader}
                    nextPage={this.changePage}
                />
            </div>
        );
    }
}

export default StatsRevenue
