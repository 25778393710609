import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/vendors";
import VendorList from './VendorList';


const ms2p = (state) => {
    return {
        ...state.vendors,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(VendorList);