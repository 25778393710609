import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/notificaciones/notificaciones';
import Notifications from './Notificaciones';


const ms2p = (state) => {
    return { ...state.notifications };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Notifications);
