import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import VendorTypeForm from "./CreateUpdateVendorTypeForm";

export default class CreateUpdateVendorType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
            image_error: null,
            icon: null,
            map_icon: null,
            image: null,
        };
    }

    componentWillMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
        }
        if (match.path === '/job-type-vendor/:id/edit') {
            this.setState({is_update: true});
        }
    }

    setMainIcon = (icon) => {
        this.setState({icon});
    }

    setMainMapIcon = (map_icon) => {
        this.setState({map_icon});
    }
    
    setMainImage = (image) => {
        this.setState({image});
    }

    render() {
        //  State
        const { loader, match: {params: {id}} } = this.props;
        const { is_update, image, icon, map_icon } = this.state;
        //  Bind
        const {create, edit} = this.props

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_update && "Update Vendor Type"}
                            {!is_update && "Create Vendor Type"}
                        </div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <VendorTypeForm
                            onSubmit={(data) => {
                                if (is_update) {
                                    edit(
                                        id,
                                        {...data, icon: null, map_icon: null, image: null, client_feedback_form: data.client_feedback_form ? data.client_feedback_form.value : null},
                                        [{file: icon, name: "icon"}, {file: map_icon, name: "map_icon"}, {file: image, name: "image"}]
                                    )
                                } else {
                                    create(
                                        {...data, icon: null, map_icon: null, image: null, client_feedback_form: data.client_feedback_form ? data.client_feedback_form.value : null},
                                        [{file: icon, name: "icon"}, {file: map_icon, name: "map_icon"}, {file: image, name: "image"}]
                                    )
                                }
                            }}
                            setMainIcon={this.setMainIcon}
                            setMainImage={this.setMainImage}
                            setMainMapIcon={this.setMainMapIcon}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}
