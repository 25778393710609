import React, {useState, useEffect} from "react";
import moment from "moment"
import Grid from "../../Utils/GridTable";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {
    AGENT_REGISTERED_PAGE_SIZE,
    STATE_USA_LIST
} from "../../../../utility/constants";


const InfluencerTeamGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("INFLUENCER-TEAM-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div
            id="INFLUENCER-TEAM-LIST"
            className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1"
        >
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={"id"}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={AGENT_REGISTERED_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field);
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    edit: 'influencer-teams',
                                }),
                        },
                        {
                            dataField: "influencer__name",
                            sort: true,
                            text: "Influencer",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.influencer) {
                                    return (
                                        <span>
                                            {row.influencer.first_name}{" "}
                                            {row.influencer.last_name}
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "name",
                            sort: true,
                            text: "Team Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "influencer__sponsored_by__name",
                            sort: false,
                            text: "Sponcer",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (
                                    row.influencer &&
                                    row.influencer.sponsored_by
                                ) {
                                    return (
                                        <span>
                                            {
                                                row.influencer.sponsored_by_data
                                                    .first_name
                                            }{" "}
                                            {
                                                row.influencer.sponsored_by_data
                                                    .last_name
                                            }
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__state",
                            sort: false,
                            text: "State",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: formattedState(),
                        },
                        {
                            dataField: "influencer__city",
                            sort: false,
                            text: "City",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.influencer) {
                                    return <span>{row.influencer.city}</span>;
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__address",
                            sort: false,
                            text: "Address",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "190px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.influencer) {
                                    return (
                                        <span>{row.influencer.address}</span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__office_phone_number",
                            sort: false,
                            text: "Office Phone #",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.influencer) {
                                    return (
                                        <span>
                                            {row.influencer.office_phone_number}
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__franchise_name",
                            sort: false,
                            text: "Franchise",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.influencer) {
                                    return (
                                        <span>
                                            {row.influencer.franchise_name}
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__re_license",
                            sort: false,
                            text: "Office License #",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.influencer) {
                                    return (
                                        <span>{row.influencer.re_license}</span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__total_agent__total",
                            sort: false,
                            text: "Total Agents",
                            align: "center",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.total_agent) {
                                    return (
                                        <span>{row.total_agent.total}</span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__total_agent__registered",
                            sort: false,
                            text: "Registered Agents",
                            align: "center",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.total_agent) {
                                    const percent = row.total_agent.total
                                        ? parseFloat(
                                              (row.total_agent.registered *
                                                  100) /
                                                  row.total_agent.total
                                          )
                                        : 0;
                                    return (
                                        <span>
                                            {row.total_agent.registered} (
                                            {percent.toFixed(2)} %)
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__total_agent__active",
                            sort: false,
                            text: "Active Agents",
                            align: "center",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.total_agent) {
                                    const percent = row.total_agent.registered
                                        ? parseFloat(
                                              (row.total_agent.active * 100) /
                                                  row.total_agent.registered
                                          )
                                        : 0;
                                    return (
                                        <span>
                                            {row.total_agent.active} (
                                            {percent.toFixed(2)} %)
                                        </span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__total_job__posted",
                            sort: false,
                            text: "# of Job Posted",
                            align: "center",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.total_job) {
                                    return (
                                        <span>{row.total_job.posted}</span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__total_job__performed",
                            sort: false,
                            text: "# of Job Performed",
                            align: "center",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.total_job) {
                                    return (
                                        <span>{row.total_job.performed}</span>
                                    );
                                }
                                return null;
                            },
                        },
                        {
                            dataField: "influencer__total_job__profit_share",
                            sort: false,
                            text: "Total Profit Share",
                            align: "center",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (row.total_job) {
                                    return (
                                        <span>$ {row.total_job.profit_share.toFixed(2)}</span>
                                    );
                                }
                                return null;
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );

};

function formattedState() {
    return (cell, row) => {
        if (row.influencer) {
            const state = row.influencer.state;
            const getState = () => {
                let text = ''
                STATE_USA_LIST.map(option => {
                    if (option.value === state) {
                        text = option.label
                    }
                })
                return text;
            }
            return (
                <p className={`my-0 text-capitalize`}>
                    {getState()}
                </p>
            )
        }

        return null
    };
}

export default InfluencerTeamGrid;
