import { handleActions } from 'redux-actions';
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { api } from "api";

// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_AGENT';
const SET_FILES_AGENT = 'SET_FILES_AGENT';

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "brokerageAgent",
    "agent",
    "agentForm",
    "agent",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

const onChange = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('agent', {}, [{name: "rosters",file: data}])
        .then((response) => {
            if (response.results === "already exist"){
                NotificationManager.info('File already exist', 'NOTIFICATIONS', 0);
            }else{
                dispatch(basicReducer.actions.toList())
            }
        })
        .catch(() => {
            NotificationManager.error('Upload file failed', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setFilesAgent = (files) => (dispatch) => {
    dispatch({
        type: SET_FILES_AGENT,
        files,
    });
};

// ------------------------------------
// Actions
// ------------------------------------

export const actions = {
    onChange,
    setFilesAgent,
    ...basicReducer.actions,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_FILES_AGENT]: (state, { files }) => {
        return {
            ...state,
            files,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    files: [],
    ...basicReducer.initialState,
};

export default handleActions(reducers, initialState);

