import React, { Component } from 'react'
import DeletedAgentFilters from './DeletedAgentFilters'
import DeletedAgentGrid from './DeletedAgentGrid'

class DeletedAgentList extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount() {
        const {getFranchisesOption, getBrokerOption} = this.props;

        getFranchisesOption(null, true)
        getBrokerOption(true, true)
    }

    componentWillUnmount() {

    }

    render() {
        //  State
        const {franchises, brokers, brokerages, data, page, loader} = this.props;
        //  Bind
        const {changeFilters, toList, onSortChange} = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Deleted Agent</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <DeletedAgentFilters
                        optionFranchises={franchises}
                        optionBrokers={brokers}
                        optionBrokerages={brokerages}
                        changeFilters={changeFilters}
                    />
                </div>
                <DeletedAgentGrid data={data} page={page} loader={loader} toList={toList} onSortChange={onSortChange}/>
                {/* <div className="card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">

                </div> */}
            </div>
        );
    }
}

export default DeletedAgentList