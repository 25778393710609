import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/vendorClientFeedbackForm";
import VendorClientFeedbackList from './VendorClientFeedbackList';


const ms2p = (state) => {
    return {
        ...state.vendorClientFeedback,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VendorClientFeedbackList);
