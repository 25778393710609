import moment from "moment";
import _ from 'lodash'
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import React, {Component, useState, useEffect} from 'react';
import AgentPerformanceDetailFilterForm from './AgentPerformanceDetailFilters'
import { Modal } from "react-responsive-modal";
import ReactStars from "react-rating-stars-component";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import {scoreFormat} from "../JobPerformance/PerformanceGrid";
import GridTableWithScroll from "../../../Utils/GridTableWithScroll";
import {STATS_PERFORMED_PAGE_SIZE} from "../../../../../utility/constants";


const AgentRating = (props) => {
    const { rating } = props;
    const [starKeyForce, setStarKeyForce] = useState(0)

    useEffect(() => {
        setStarKeyForce(prev => prev + 1)
    }, [rating])


    return (
        <ReactStars
            size={28}
            count={5}
            value={rating}
            edit={false}
            isHalf={true}
            key={starKeyForce}
            onChange={() => console.log('')}
        />
    )
}


const CustomerFeedbackModal = (props) => {
    //  State
    const {open, data} = props;
    //  Bind
    const {onClose} = props;
    // console.log("DATA: ", data);
    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-4 col-md-8 col-10", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Customer Feedback
                        </div>
                    </div>
                </div>
                {data && data.feedback && data.feedback.length ? (
                    data.feedback.map((item, index) => {
                        return (
                            <div className="form-group" key={index}>
                                <label htmlFor="">{item.question}</label>
                                {item.type == 5 ? (
                                    <textarea
                                        style={{ resize: 'none' }}
                                        className="form-control text-area-h-auto"
                                        disabled="true"
                                        value={item.answer}
                                        rows={6}
                                        />
                                ): null}

                                { item.type == 10 ? (
                                    <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                                        <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center">
                                            <label>YES</label>
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    disabled={true}
                                                    checked={item.answer != 'N/A' ? (item.answer == 'Yes' ? true : false) : null }
                                                />
                                                <span className="fa fa-check"/>
                                                {/* &nbsp;{label} */}
                                            </label>
                                        </div>
                                        <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center ">
                                            <label>No</label>
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    disabled={true}
                                                    checked={item.answer != 'N/A' ? (item.answer == 'No' ? true : false) : null }
                                                />
                                                <span className="fa fa-check"/>
                                                {/* &nbsp;{label} */}
                                            </label>
                                        </div>
                                    </div>
                                ): null}
                            </div>
                        )
                    })
                ): null}
            </div>
        </Modal>
    )
}

class AgentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCustomerFeedbackModal: false,
            customerFeedbackData: null,
            id: null,
            gridHeight: 200,
        }
    }
    componentDidMount() {
        const {match, readDetails, getJobTypesOption, setCategorySelectAll} = this.props;
        let _height = 200;
        setCategorySelectAll()
        setTimeout(() => {
            const elementId = document.getElementById("STATS-AGENT-PERFORMANCE-DETAIL");
            if (elementId) {
                _height = elementId.clientHeight
            }
            console.log("HE: ", _height);
            this.setState({gridHeight: _height})
            getJobTypesOption(true);
            if (match.params.id) {
                this.setState({id:match.params.id})
                this.props.read(match.params.id, 1)
            } else {

            }
        }, 300);
    }

    nextPage = (page = 1) => {
        const {match} =this.props;
        this.props.read(match.params.id, page)
    }

    componentWillUnmount() {
        // this.props.setDefaultValue();
    }

    formatPhoneNumber = (cell = '') => {
        let phone = ''
        if (cell.length > 10) {
            cell = cell.substr(cell.length - 10, 12)
        }
        phone = `${cell.substr(0, 3)}-${cell.substr(3, 3)}-${cell.substr(6, 4)}`
        return phone
    }


    openCustomerFeedbackModal = (data) => {
        this.setState({customerFeedbackData: data})
        setTimeout(() => {
            this.setState({showCustomerFeedbackModal: true})
        }, 300);
    }
    
    closeCustomerFeedbackModal = () => {
        this.setState({showCustomerFeedbackModal: false})
        this.setState({customerFeedbackData: null})
    }

    goBack = () => {
        const {history} = this.props
        history.goBack()
    }

    render() {
        const {loader, item, dataDetail, pageDetail, jobTypes, goPage} = this.props;
        const {id, gridHeight} = this.state;

        //  Bind
        const {changeDetailFilters} = this.props;

        return (
            <div id="PAGE-CONTAINER" className="d-flex flex-column flex-1">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            Stats / Agent Performance Details
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <AgentPerformanceDetailFilterForm
                        id={id}
                        changeFilters={changeDetailFilters}
                        optionJobTypes={jobTypes}
                    />
                </div>
                <div id="STATS-AGENT-PERFORMANCE-DETAIL" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
                    <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                        <LoadMask loading={loader} light>
                            <div className="flex-1 d-flex flex-column col-sm-12 p-sm-0">
                                <div className="row flex-1 pt-2">
                                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                                        {item && (
                                            <React.Fragment>
                                                <div className="form-group has-feedback d-flex flex-row align-items-center justify-content-center">
                                                    {/* <label htmlFor="photo">Photo</label> */}
                                                    <Avatar
                                                        src={item.photo}
                                                        name={item.first_name}
                                                        size={165}
                                                        round
                                                    />
                                                </div>
                                                <div className="form-group has-feedback d-flex flex-row align-items-center justify-content-center mb-0">
                                                    {/* <label htmlFor="photo">Photo</label> */}
                                                    <h4 className="mb-0 text-center">
                                                        {item.first_name}{" "}
                                                        {item.last_name}
                                                    </h4>
                                                </div>
                                                <div className="col-sm-12 d-flex flex-column align-items-center px-0 mb-4">
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <AgentRating
                                                            rating={
                                                                item.rating >=
                                                                    0 &&
                                                                item.rating
                                                            }
                                                        />
                                                    </div>
                                                    <span
                                                        className="font-weight-bolder h4 mb-0"
                                                        style={{
                                                            color: "#F7D701",
                                                        }}
                                                    >
                                                        {item.rating >= 0
                                                            ? item.rating
                                                            : 0}
                                                    </span>
                                                </div>
                                                <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                                    <span className="font-weight-bolder h6 mb-1">
                                                        Job Performed
                                                    </span>
                                                    <span className="font-weight-bolder text-primary">
                                                        {item.total_performed}
                                                    </span>
                                                </div>
                                                <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                                                    <span className="font-weight-bolder h6 mb-1">
                                                        Job Posted
                                                    </span>
                                                    <span className="font-weight-bolder text-primary">
                                                        {item.total_posted}
                                                    </span>
                                                </div>
                                                <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                                                    <span className="font-weight-bolder h6 mb-1">
                                                        Job Reviews
                                                    </span>
                                                    <span className="font-weight-bolder text-primary pointer" onClick={(e) => goPage(`/agents/agent-reviews/${item.id}`)}>
                                                        {item.total_reviews}
                                                    </span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="flex-1 d-flex flex-column col-xl-9 col-lg-9 col-md-7 col-sm-12">
                                        <GridTableWithScroll
                                            hover
                                            height={gridHeight}
                                            page={pageDetail}
                                            data={dataDetail}
                                            keyField={"job__id"}
                                            wrapperClasses={'table-scroll-auto'}
                                            loading={loader}
                                            onPageChange={(value) =>
                                                this.nextPage(value)
                                            }
                                            sizePerPage={
                                                STATS_PERFORMED_PAGE_SIZE
                                            }
                                            remote={true}
                                            rowEvents={null}
                                            columns={[
                                                {
                                                    dataField: "icon",
                                                    formatter: loadIcon(),
                                                    text: "",
                                                },
                                                {
                                                    dataField: "job_type_name",
                                                    headerStyle: (
                                                        colum,
                                                        colIndex
                                                    ) => {
                                                        return {
                                                            minWidth: "150px",
                                                            textAlign: "left",
                                                        };
                                                    },
                                                    text: "Job Type",
                                                },
                                                {
                                                    dataField: "category",
                                                    short: true,
                                                    text: "Category",
                                                },
                                                {
                                                    dataField:
                                                        "hiring_performing_agent",
                                                    formatter: (cell, row) => {
                                                        if (row.hiring_performing_agent && row.hiring_performing_agent.office_id) {
                                                            return (
                                                                <Link
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                    to={`/offices/${row.hiring_performing_agent.office_id}/edit`}
                                                                    className="w-100 text-primary"
                                                                >
                                                                    {row.hiring_performing_agent ? row.hiring_performing_agent.agent : ""}
                                                                </Link>
                                                            );
                                                        }
                                                        return (
                                                            <span>{row.hiring_performing_agent ? row.hiring_performing_agent.agent : ""}</span>
                                                        )
                                                    },
                                                    headerStyle: (
                                                        colum,
                                                        colIndex
                                                    ) => {
                                                        return {
                                                            minWidth: "170px",
                                                            textAlign: "left",
                                                        };
                                                    },
                                                    text: "Hiring / Performing Agent",
                                                },
                                                {
                                                    dataField:
                                                        "date_posted_performed",
                                                    formatter: dateCompleted(),
                                                    headerStyle: (
                                                        colum,
                                                        colIndex
                                                    ) => {
                                                        return {
                                                            minWidth: "130px",
                                                            textAlign: "left",
                                                        };
                                                    },
                                                    text: "Date Posted / Performed",
                                                },
                                                {
                                                    dataField: "review.rating",
                                                    formatter: scoreFormat(),
                                                    text: "Review",
                                                },
                                                {
                                                    dataField: "review.notes",
                                                    short: true,
                                                    headerStyle: (
                                                        colum,
                                                        colIndex
                                                    ) => {
                                                        return {
                                                            minWidth: "150px",
                                                            textAlign: "left",
                                                        };
                                                    },
                                                    text: "Hirign Agent Review",
                                                },
                                                {
                                                    dataField:
                                                        "customer_feedback",
                                                    short: true,
                                                    text: "Client Review",
                                                    headerStyle: (
                                                        colum,
                                                        colIndex
                                                    ) => {
                                                        return {
                                                            minWidth: "150px",
                                                            textAlign: "left",
                                                        };
                                                    },
                                                    formatter: customerComments(
                                                        this
                                                            .openCustomerFeedbackModal
                                                    ),
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </LoadMask>
                    </div>
                </div>

                <CustomerFeedbackModal
                    open={
                        this.state.showCustomerFeedbackModal &&
                        this.state.customerFeedbackData
                    }
                    data={this.state.customerFeedbackData}
                    onClose={this.closeCustomerFeedbackModal}
                />
            </div>
        );
    }
}

// function hiringAgentName() {
//     return (cell, row) => {
//         return (
//             <div>
//                 {cell + ' ' + row.job__agent__last_name}
//             </div>
//         )
//     }
// }

// function jobName() {
//     return (cell, row) => {
//         return (
//             <div>
//                 {row.job__job_type_option ? row.job__job_type_option__name : cell}
//             </div>
//         )
//     }
// }

function dateCompleted() {
    return (cell) => {
        return (
            <div>
                {moment(cell).format('ll')}
            </div>
        )
    }
}

function loadIcon() {
    return (cell, row) => {
        return (
            <div className="p-0 m-0 justify-content-center container-cell-icon mx-auto">
                <img
                    src={cell ? cell : row.icon_option}
                    alt=' '
                    className="align-self-center"
                    style={{maxWidth: '95%', maxHeight: '90%'}}
                />
            </div>
        )
    }
}

function customerComments(openCustomerFeedbackModal) {
    return (cell, row) => {
        const has_feedback = row.customer_feedback && row.customer_feedback.length;
        let notes = _.find(has_feedback ? row.customer_feedback : [], {question: 'Notes'})
        return (
            <div className="d-flex flex-row align-items-center py-1 w-100">
                <span className="text-justify flex-1">
                    {has_feedback ? (
                        notes !== null && notes !== undefined ? (
                            notes.answer
                            ): ""
                            ): 'N/A'}
                </span>
                {has_feedback ? (
                    <button
                        className="btn btn-link d-flex align-items-center justify-content-center ml-2"
                        style={{
                            minWidth: "1.6rem",
                            maxWidth: "1.6rem",
                            minHeight: "1.6rem",
                            maxHeight: "1.6rem",
                        }}
                        onClick={() => openCustomerFeedbackModal({feedback: row.customer_feedback})}
                    >
                        <i className="fa fa-eye"></i>
                    </button>
                ): null}
            </div>
        )
    }
}


export default AgentDetails;
