import moment from "moment";
import Swal from 'sweetalert2';
import _, { set } from "lodash";
import "react-responsive-modal/styles.css";
import AppVersionForm from "./AppVersionForm";
import { Modal } from "react-responsive-modal";
import {api} from '../../../../../utility/api';
import React, {useState, useEffect} from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { SIZE_PER_PAGE, STATE_USA_LIST } from "../../../../../utility/constants";

const AppVersionModal = (props) => {
    //  State
    const { open, generalLoader } = props;
    //  Bind
    const { onClose, readAppVersion, submitVersion, deleteVersion } = props;

    const [data, setData] = useState({results: []});
    const [loader, setLoader] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        if (open) {
            toList()
        }
    
      return () => {
        setLoader(false)
        setData(null)
      }
    }, [open])
    
    console.log("DATA: ", data);

    const toList = () => {
        setLoader(true)
            api.get("app_version_log/all")
                .then((response) => {
                    if(response) {
                        setData(response)
                    }
                }).catch((error) => {
                    console.log("ERROR: ", error);
                    setData({results: []})
                })
                .finally(() => {
                    setLoader(false);
                });
    }

    const goBack = () => {
        setShowForm(false)
        setIsUpdate(false)
    }
    const create = () => {
        setShowForm(true)
        setIsUpdate(false)
    }
    const edit = (id) => {
        readAppVersion(id)
        setShowForm(true)
        setIsUpdate(true)
    }

    const remove = (id) => {
        Swal.fire({
            title: 'Are you sure to delete the version?',
            text: "This action can't be reverted!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                deleteVersion(id, toList)
            }
        });
    }

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-11 overflow-hidden",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="w-100 row py-2 px-0 mx-auto d-flex flex-column">
                <div className="page-header pl-1 mt-3 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page">
                            {!showForm && "App Version"}
                            {showForm && !isUpdate && "New Version"}
                            {showForm && isUpdate && "Update Version"}
                        </div>
                    </div>
                    {!showForm && (
                        <button style={{height: '30px'}} className="btn btn-primary my-2" disabled={loader || generalLoader} onClick={create}>Create</button>
                    )}
                </div>
                <LoadMask loading={loader || generalLoader} dark blur>
                    <div className="h-auto overflow-auto" style={{maxHeight: '60vh', minHeight: showForm ? '50vh': '30vh'}}>
                        {!showForm && (
                            <React.Fragment>
                                <div className="my-1 p-2 d-flex flex-row align-items-center">
                                    <span className="flex-3 px-1 label font-weight-bold">Android</span>
                                    <span className="flex-3 px-1 label font-weight-bold">IOS</span>
                                    <span className="flex-3 px-1 label font-weight-bold">Released</span>
                                    <div className="flex-1">
                                    </div>
                                </div>
                                {data && data.results && data.results.map((item, index) => {
                                    return (
                                        <div className="card my-1 shadow-sm p-1 d-flex flex-row align-items-center" key={index}>
                                            <span className="flex-3 px-1 small">{item.android}</span>
                                            <span className="flex-3 px-1 small">{item.ios}</span>
                                            <span className="flex-3 px-1 small">{moment(item.released).format('ll')}</span>
                                            <div className="flex-1">
                                                <i className="mx-2 fa fa-pencil pointer" onClick={(e) => edit(item.id)}/>
                                                <i className="mx-2 fa fa-trash text-danger pointer" onClick={(e) => remove(item.id)}/>
                                            </div>
                                        </div>
                                    )
                                })}
                                {!data || (data && data.results && data.results.length <= 0) && (
                                    <span className="w-100 text-center my-4">No Results</span>
                                ) }
                            </React.Fragment>
                        )}
                        {showForm && (
                            <AppVersionForm
                                loader={generalLoader}
                                goBack={goBack}
                                onSubmit={(data) => submitVersion(data, goBack, toList)}
                            />
                        )}
                    </div>
                </LoadMask>
            </div>
        </Modal>
    );
};

const phoneFormatted = (cell) => {
    let newCell = cell;

    if (newCell && newCell.includes("+")) {
        newCell = newCell.replace("+", "");
    }
    if (newCell && newCell.includes("-")) {
        newCell = newCell.replace("-", "");
    }
    if (newCell && newCell.length > 10) {
        newCell = newCell.substr(newCell.length - 10, 12);
    }
    if (newCell) {
        newCell = `${newCell.substr(0, 3)}-${newCell.substr(
            3,
            3
        )}-${newCell.substr(6, 4)}`;
    }
    return <p className={"my-0 mx-0 px-0"}>{newCell}</p>;
};


export const AgentRawDataGrid = (props) => {
    const { loader = false, data, nextPage, page, readAppVersion, submitVersion, versionLoader=false, deleteVersion } = props;

    const [gridHeight, setGridHeight] = useState(200)
    const [appVersion, setAppVersion] = useState(false)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("STATS-AGENT-RAW-DATA");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    const tableOptions = {
        sizePerPage: SIZE_PER_PAGE,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: nextPage,
    };

    const getState = (value) => {
        let text = ''
        const _value = value ? value.toUpperCase() : '';
        const state_obj = _.find(STATE_USA_LIST,{ 'value': _value})
        if (state_obj !== null && state_obj !== undefined) {
            text = state_obj.label
        }
        return text;
    }
    return (
        <div id="STATS-AGENT-RAW-DATA" className="container-list card border-dark border-card-page px-4 py-0">
            <div className="card-body px-0 py-2 d-flex flex-column overflow-hidden">
                <LoadMask loading={loader} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={gridHeight ? {maxHeight: `${gridHeight - 30}px`}: {}}>
                        <BootstrapTable
                            data={loader ? [] : data.results ? data.results : []}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            containerClass="flex-1"
                            tableContainerClass="react-bs-table-bordered-95"
                        >
                            <TableHeaderColumn
                                row="0"
                                rowSpan="2"
                                dataField="id"
                                isKey
                                width="0px"
                                dataFormat={(cell, row) => {
                                    return <div />;
                                }}
                            />
                            {/* PERSONAL INFORMATION */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="8"
                                headerAlign="center"
                                width="150px"
                            >
                                Personal Information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__first_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.first_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                First Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__last_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.last_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__real_estate_licence"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information
                                                    .real_estate_licence
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                RE Licence #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__mobile_number"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? phoneFormatted(
                                                    row.personal_information
                                                        .mobile_number
                                                )
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Cell Phone
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__email"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.email
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__street"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.street
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Address
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__state"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.state
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                State
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__city"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.city
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                City
                            </TableHeaderColumn>

                            {/* BROKERAGE INFORMATION */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="6"
                                headerAlign="center"
                                width="150px"
                            >
                                Brokerage Information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_information__franchise"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.brokerage_information
                                                ? row.brokerage_information.franchise
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Franchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_information__office_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.brokerage_information
                                                ? row.brokerage_information.office_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Office Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_information__license"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.brokerage_information
                                                ? row.brokerage_information.license
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                License #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_information__state"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className='text-capitalize'>
                                            {row.brokerage_information
                                                ? row.brokerage_information.state
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                State
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_information__city"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.brokerage_information
                                                ? row.brokerage_information.city
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                City
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_information__address"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.brokerage_information
                                                ? row.brokerage_information.address
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Address
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="date_joined"
                                width="100px"
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p className="p-0 m-0">
                                                {cell && moment(cell).format("ll")}
                                            </p>
                                        </div>
                                    );
                                }}
                            >
                                Signup date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="is_an_active_user"
                                width="100px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p
                                                className={`p-0 m-0 ${
                                                    cell
                                                        ? "text-success"
                                                        : "text-danger"
                                                }`}
                                            >
                                                {cell ? "Yes" : "No"}
                                            </p>
                                        </div>
                                    );
                                }}
                            >
                                Active
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="locked"
                                width="115px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p
                                                className={`p-0 m-0 ${
                                                    cell
                                                        ? "text-success"
                                                        : "text-danger"
                                                }`}
                                            >
                                                {cell ? "Yes" : "No"}
                                            </p>
                                        </div>
                                    );
                                }}
                            >
                                Locked
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="locked_reason_name"
                                dataFormat={(cell, row) => {
                                    return <div>{cell && row.locked ? cell : ""}</div>;
                                }}
                                width="150px"
                            >
                                Reason
                            </TableHeaderColumn>

                            {/* VERSION NUMBER */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="2"
                                headerAlign="center"
                                width="150px"
                            >
                                {/* Version Number */}
                                <div
                                    style={{ cursor: "pointer" }}
                                    className="w-100 d-flex flex-row align-items-center justify-content-center"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAppVersion(true);
                                    }}
                                >
                                    Version Number
                                    <i
                                        className="fa fa-info-circle text-info ml-2"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                </div>
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="android_version"
                                width="100px"
                            >
                                Android
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="ios_version"
                                width="100px"
                            >
                                <div style={{textTransform: 'initial'}}>iOS</div>
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>

            <AppVersionModal
                open={appVersion} 
                generalLoader={versionLoader}
                onClose={() => setAppVersion(false)}
                readAppVersion={readAppVersion}
                submitVersion={submitVersion}
                deleteVersion={deleteVersion}
                />
        </div>
    );
};
