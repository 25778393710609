import React from "react";
import { Field, reduxForm } from "redux-form";
import { SELECT_ALL_OPTION } from "../../../../utility/constants";
import { renderSearchField, MultiSelectField } from "../../Utils/renderField";

let GiftCardRawDataFilterForm = (props) => {
    //  Bind
    const { searchAction } = props;
    return (
        <div className="row mt-2 w-100 mb-3 align-items-center">
            <div
                className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}
            >
                <div className="form-group has-feedback w-100 mb-0">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={"Search"}
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>
            </div>
        </div>
    );
}


GiftCardRawDataFilterForm = reduxForm({
    form: 'giftCardRawDataFilterForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(GiftCardRawDataFilterForm);


export default GiftCardRawDataFilterForm;