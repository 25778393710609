import React from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderFilePicker } from "../../Utils/renderField/renderField";
import {
    renderField,
    renderNumber,
    renderSwitch,
    SelectField,
    renderAddressField,
} from "../../Utils/renderField";
import {
    LOCKED_BROKER,
    PENDING_BROKER,
    STATE_USA_LIST,
    VERIFIED_BROKER,
    DENIED_BROKER,
    STATUS_BROKER,
    BROKER_ROLES_LIST,
    BROKER_OPERATIONAL_MODE_LIST,
    BLOCKED_BROKER,
} from "../../../../utility/constants";

const validate = (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "This field is required";
    }
    if (!values.role) {
        errors.role = "This field is required";
    }
    if (!values.owner_email) {
        errors.owner_email = "This field is required";
    }
    if (!values.franchise) {
        errors.franchise = "This field is required";
    }
    if (!values.operation_access) {
        errors.operation_access = "This field is required";
    }
    if (!values.license) {
        errors.license = "This field is required";
    }
    if (!values.office_phone_number) {
        errors.office_phone_number = "This field is required";
    } else {
        if (values.office_phone_number.length < 10) {
            errors.office_phone_number = "Invalid phone number";
        }
    }
    if (
        values.provide_agents_roster !== true &&
        values.provide_agents_roster !== false
    ) {
        errors.provide_agents_roster = "This field is required";
    }
    if (values.payment_through_broker == true) {
        if (!values.hold_payment_for) {
            errors.hold_payment_for = "This field is required";
        }
    }

    return errors;
};

const ValidateBrokerForm = (props) => {
    const {
        handleSubmit,
        franchises,
        influencers,
        validateBroker,
        denyBroker,
        item,
        is_update,
        is_view,
        goToOffices,
        goToAgents,
        goToPaymentsHistory,
        goBack,
        assignAddressData,
        payment_through_brokerValue,
    } = props;

    const getStatus = (status) => {
        let text = "";
        STATUS_BROKER.map((item) => {
            if (status === item.value) {
                text = item.label;
            }
        });
        return text;
    };

    const getColor = (status) => {
        let colorText = "black";
        if (status === DENIED_BROKER) {
            colorText = "danger";
        } else if (status === PENDING_BROKER) {
            colorText = "warning";
        } else if (status === VERIFIED_BROKER) {
            colorText = "success";
        } else if (status === LOCKED_BROKER) {
            colorText = "danger";
        } else if (status === BLOCKED_BROKER) {
            colorText = "danger";
        }
        return colorText;
    };

    return (
        <form
            name="registerAdmin"
            className="my-1 col-sm-12 p-sm-0"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    {item.logo_office && (
                        <div className="form-group has-feedback">
                            <Field
                                photo={item.logo_office ? item.logo_office : null}
                                setFile={() => {}}
                                name="photo"
                                component={renderFilePicker}
                                disabled={true}
                                // className="avatar-round"
                            />
                        </div>
                    )}
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {moment(item.created).format("ll")}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {item && (
                            <span
                                className={`text-${getColor(
                                    item.status
                                )} font-weight-bold`}
                            >
                                {getStatus(item.status)}
                            </span>
                        )}
                    </div>
                    {item && item.status === VERIFIED_BROKER && (
                        <React.Fragment>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Total Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointer"
                                        onClick={(e) => goToAgents(item.id)}
                                    >
                                        {item.agents_stats.total_agents
                                            ? item.agents_stats.total_agents
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Registered Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointer"
                                        onClick={(e) => goToAgents(item.id)}
                                    >
                                        {item.agents_stats.total_user
                                            ? item.agents_stats.total_user
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Unregistered Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointer"
                                        onClick={(e) => goToAgents(item.id)}
                                    >
                                        {item.agents_stats.total_unregistered
                                            ? item.agents_stats
                                                  .total_unregistered
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Active Agents
                                </span>
                                {item && item.agents_stats && (
                                    <span
                                        className="font-weight-bolder text-primary pointer"
                                        onClick={(e) => goToAgents(item.id)}
                                    >
                                        {item.agents_stats.total_user_active
                                            ? item.agents_stats
                                                  .total_user_active
                                            : 0}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Total ShowIT Share
                                </span>
                                {item && (
                                    <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToPaymentsHistory(item.id)}>
                                        ${" "}
                                        {item.total_show_it_share
                                            ? item.total_show_it_share.toFixed(
                                                  2
                                              )
                                            : "0.00"}
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                                <span className="font-weight-bolder h6 mb-1">
                                    Total ShowIT Revenue
                                </span>
                                {item && (
                                    <span className="font-weight-bolder text-primary">
                                        ${" "}
                                        {item.total_show_it_revenue
                                            ? item.total_show_it_revenue.toFixed(
                                                  2
                                              )
                                            : "0.00"}
                                    </span>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="name">Broker Full Name</label>
                            <Field
                                name="name"
                                label="Broker Full Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="role">Role</label>
                            <Field
                                name="role"
                                label="state"
                                disabled={is_view}
                                options={BROKER_ROLES_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="license">RE License #</label>
                            <Field
                                name="license"
                                label="License number"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="owner_email">Email</label>
                            <Field
                                name="owner_email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label
                                htmlFor="office_phone_number"
                                className="mx-0 px-0"
                            >
                                Phone #
                            </label>
                            <Field
                                numberFormat={"###-###-####"}
                                name="office_phone_number"
                                component={renderNumber}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label
                                htmlFor="mobile_number"
                                className="mx-0 px-0"
                            >
                                Mobile #
                            </label>
                            <Field
                                numberFormat={"###-###-####"}
                                name="mobile_number"
                                component={renderNumber}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="group_name">Group Name</label>
                            <Field
                                name="group_name"
                                label="Group Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address">Address</label>
                            <Field
                                name="address"
                                label="address"
                                placeholder='Address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                longStateName={true}
                                setCityState={(data) => {
                                    console.log('ADDRESS DATA: ', data);
                                    if (data) {
                                        assignAddressData(data)
                                    }
                                }}
                            />
                            {/* <Field
                                name="address"
                                label="Address"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            /> */}
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="languages"># of Offices</label>
                            <div className="d-flex flex-row">
                                <div
                                    className="flex-1 form-control pointer text-primary"
                                    onClick={(e) => goToOffices(item.id)}
                                >
                                    {item &&
                                        (item.has_multiple_offices
                                            ? item.number_offices
                                                ? item.number_offices
                                                : ""
                                            : item.offices
                                            ? item.offices.length
                                            : "")}
                                </div>
                                <Link
                                    className="btn btn-sm btn-primary mx-2 mb-2 d-flex flex-row align-items-center justify-content-center"
                                    to={`/brokers/${item.id}/new-office`}
                                    disabled={is_view}
                                    title="Add new office"
                                    style={{
                                        width: "30px",
                                        minWidth: "30px",
                                        height: "30px",
                                        minHeight: "30px",
                                    }}
                                >
                                    <i className="fa fa-plus-circle text-white"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="languages">Brokerage Licence</label>
                            <div
                                className="form-control text-area-h-auto"
                                style={{ height: "auto !important" }}
                            >
                                {item &&
                                    item.offices &&
                                    item.offices.map((office, index) => {
                                        if (index == 0) {
                                            return (
                                                <span>{office.license}</span>
                                            );
                                        }
                                        return (
                                            <span>
                                                <span className="pr-3"></span>
                                                <span className="pr-3">/</span>
                                                <span>{office.license}</span>
                                            </span>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="franchise">Franchise</label>
                            <Field
                                name="franchise"
                                label="franchise"
                                options={franchises}
                                component={SelectField}
                                className="form-control mb-2 pb-5"
                            />
                            {franchises.length === 0 && (
                                <p className="mt-2">
                                    <a
                                        href="/#/franchises"
                                        className="text-danger text-12"
                                    >
                                        No franchises, please create a franchise
                                        to continue
                                    </a>
                                </p>
                            )}
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="account_status">State</label>
                            <Field
                                name="state"
                                label="state"
                                disabled={is_view}
                                options={STATE_USA_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="provide_agents_roster">
                                Will Broker provide agent's roster list?
                            </label>
                            <Field
                                name="provide_agents_roster"
                                label="provide_agents_roster"
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                component={SelectField}
                                className="form-control mb-2 pb-5"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="operation_access">
                                Operational Mode
                            </label>
                            <Field
                                name="operation_access"
                                label="Operational Mode"
                                disabled={is_view}
                                options={BROKER_OPERATIONAL_MODE_LIST}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="influencer_id">Influencer</label>
                            <Field
                                name="influencer_id"
                                label="influencer_id"
                                options={influencers}
                                component={SelectField}
                                className="form-control mb-2 pb-5"
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="form-group col-sm-12 mb-0">
                            <label
                                className="mt-n4 d-block"
                                style={{ fontSize: "1.05rem" }}
                            >
                                {" "}
                                Account Settings
                            </label>
                            <div className="row w-100 m-0 h-100">
                                <div className="rounded border col-sm-12 py-2">
                                    <div className="row">
                                        {item.owner_id && (
                                            <div className="form-group mb-0 has-feedback col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                <label
                                                    htmlFor="is_active"
                                                    className="d-block"
                                                >
                                                    Password
                                                </label>
                                                {is_view ? (
                                                    <button
                                                        className="btn button-03 px-4"
                                                        disabled={true}
                                                        style={{
                                                            hover: "none",
                                                        }}
                                                    >
                                                        Change Password
                                                    </button>
                                                ) : (
                                                    <Link
                                                        className="btn button-03 px-4"
                                                        to={`/brokers/owner/${item.owner_id}/changed-password`}
                                                        disabled={is_view}
                                                    >
                                                        Change Password
                                                    </Link>
                                                )}
                                            </div>
                                        )}
                                        <div className="form-group mb-0 has-feedback col-xl-1 col-lg-2 col-md-3 col-sm-12">
                                            <label
                                                htmlFor="locked"
                                                className="mb-3"
                                            >
                                                Lock account
                                            </label>
                                            <Field
                                                name="locked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view}
                                            />
                                        </div>
                                        <div className="form-group mb-0 has-feedback col-xl-1 col-lg-2 col-md-3 col-sm-12">
                                            <label
                                                htmlFor="block"
                                                className="mb-3"
                                            >
                                                Block account
                                            </label>
                                            <Field
                                                name="block"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view}
                                            />
                                        </div>
                                        <div className="form-group mb-0 has-feedback col-xl-1 col-lg-2 col-md-3 col-sm-12">
                                            <label
                                                htmlFor="show_it_share"
                                                className="mb-3"
                                            >
                                                ShowIT Share ?
                                            </label>
                                            <Field
                                                name="show_it_share"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view}
                                            />
                                        </div>
                                        <div className="form-group mb-0 has-feedback col-xl-2 col-lg-2 col-md-3 col-sm-12">
                                            <label
                                                htmlFor="payment_through_broker"
                                                className="mb-3"
                                            >
                                                Payment through Brokerage
                                            </label>
                                            <Field
                                                name="payment_through_broker"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view}
                                            />
                                        </div>
                                        {payment_through_brokerValue ? (
                                            <div className="form-group mb-0 has-feedback col-xl-3 col-lg-4 col-md-3 col-sm-12">
                                                <label
                                                    htmlFor="hold_payment_for"
                                                    className="mb-3"
                                                >
                                                    Hold payment for (hr)
                                                </label>
                                                <Field
                                                    name="hold_payment_for"
                                                    id="hold_payment_for"
                                                    placeholder="Hold payment for (hr)"
                                                    component={renderNumber}
                                                    decimalScale={0}
                                                    type="text"
                                                    className="form-control"
                                                    disabled={is_view}
                                                />
                                            </div>
                                        ): null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {
                item && item.status === LOCKED_BROKER &&
                <div className="w-100 border">
                    <label className="px-2 pt-2 pb-0 mb-0">Setting lock</label>
                    <div className="row w-100 mx-auto col-12 my-3">
                        <label htmlFor="Unlock" className="align-self-end"> Unlock </label>
                        <Field
                            name={'unlock'}
                            disabled={is_view}
                            component={renderSwitch}
                            customClass="text-center pt-2 pl-3"
                        />
                    </div>
                </div>
            } */}

            <div className="row justify-content-center mx-auto mt-lg-3">
                {item &&
                item.status !== undefined &&
                (item.status === PENDING_BROKER ||
                    item.status === DENIED_BROKER) ? (
                    <React.Fragment>
                        <img
                            src={require("../../../../../assets/img/exclamation-triangle-solid.svg")}
                            alt="warning"
                            style={{
                                width: "24px",
                                height: "24px",
                                color: "#c4183c",
                            }}
                        />
                        <p className="text-center text-danger text-12 ml-1 mt-1">
                            {item.status === PENDING_BROKER &&
                                "This Brokerage needs to be activated"}
                            {item.status === DENIED_BROKER &&
                                "This Brokerage is denied"}
                        </p>
                    </React.Fragment>
                ) : null}
            </div>
            <div className="row justify-content-center mx-auto ">
                {/* <Link
                    to="/brokers"
                    className="btn button-03 col-lg-3 col-sm-5 mx-4 mt-2"
                >
                    {" "}
                    Back{" "}
                </Link> */}
                <button
                    type="button"
                    onClick={goBack}
                    className={`w-100 btn btn-secondary text-white col-lg-2 col-md-4 col-sm-12 mx-4 mt-2`}
                >
                    Back
                </button>
                {is_update &&
                item &&
                item.status !== undefined &&
                item.status !== PENDING_BROKER ? (
                    item.status == DENIED_BROKER ? (
                        <button
                            type="button"
                            disabled={franchises.length === 0}
                            onClick={validateBroker}
                            className="btn btn-success col-lg-2 col-md-4 col-sm-12 mx-4 mt-2"
                        >
                            Activate
                        </button>
                    ) : null
                ) : (
                    <React.Fragment>
                        {item &&
                        item.status !== undefined &&
                        item.status !== VERIFIED_BROKER &&
                        item.status !== LOCKED_BROKER ? (
                            <React.Fragment>
                                <button
                                    type="button"
                                    disabled={franchises.length === 0}
                                    onClick={denyBroker}
                                    className="btn button-04 col-lg-2 col-md-4 col-sm-12 mx-4 mt-2"
                                >
                                    Deny
                                </button>
                                <button
                                    type="button"
                                    disabled={franchises.length === 0}
                                    onClick={validateBroker}
                                    className="btn btn-success col-lg-2 col-md-4 col-sm-12 mx-4 mt-2"
                                >
                                    Activate
                                </button>
                            </React.Fragment>
                        ) : null}
                    </React.Fragment>
                )}
                {is_update && (
                    <button
                        type="submit"
                        className="btn btn-primary col-lg-2 col-md-4 col-sm-12 mx-4 mt-2"
                    >
                        Update
                    </button>
                )}
            </div>
        </form>
    );
};

// const numberPhone = (number) => validatorFromFunction(() => {
//     return number.length === 10;
// });

let BrokerForm =  reduxForm({
    form: "brokerForm", // a unique identifier for this form
    validate,
})(ValidateBrokerForm);

const selector = formValueSelector('brokerForm');

const mstp = state => ({
    payment_through_brokerValue: selector(state, 'payment_through_broker'),
})


export default connect(mstp, null)(BrokerForm)