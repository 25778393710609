import React from 'react';
import { connect } from "react-redux";
import {Field, reduxForm} from "redux-form";
import { formValueSelector } from "redux-form";
import { renderFilePicker } from "../../Utils/renderField/renderField";
import {renderField, renderSwitch, renderTextArea} from "../../Utils/renderField";

const validate = (values) => {
    let errors = {}

    if (!values.logo) {
        errors.logo = "This field is required"
    }
    if (!values.name) {
        errors.name = "This field is required"
    }
    if (values.sold_certified_agents) {
        if (!values.sold_field) {
            errors.sold_field = "This field is required"
        }
        if (!values.sold_message) {
            errors.sold_message = "This field is required"
        }
    }

    return errors
}

const FranchiseForm = (props) => {
    //  State
    const {handleSubmit, is_view, is_update, item, sold_certified_agentsValue} = props;
    //  Bind
    const {setFiles} = props;

    return (
        <form className="w-100 mx-auto justify-content-center mt-5" onSubmit={handleSubmit}>
            <div className="row mx-auto mt-3 justify-content-center w-100">
                <div className="col-lg-8 col-sm-12">
                    <div className="row">
                        <div className="col-sm-12 form-group has-feedback">
                            <label htmlFor="logo">Logo</label>
                            <Field
                                name="logo"
                                photo={item && item.logo ? item.logo : require("../../../../../assets/img/uploadLogo.PNG")}
                                className="w-100"
                                component={renderFilePicker}
                                setFile={setFiles}
                                styles={{marginLeft: '2.9rem'}}
                            />
                        </div>
                        <div className="col-sm-12 form-group has-feedback">
                            <label htmlFor="name">Franchise Name</label>
                            <Field
                                name="name"
                                label="name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="col-sm-12 col-md-4 form-group has-feedback">
                            <label htmlFor="sold_certified_agents">Sold Certified Agents ?</label>
                            <Field
                                name="sold_certified_agents"
                                label={null}
                                component={renderSwitch}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>

                        {sold_certified_agentsValue && (
                            <React.Fragment>
                                <div className="col-sm-12 col-md-8 form-group has-feedback">
                                    <label htmlFor="sold_field">Sold Text Field</label>
                                    <Field
                                        name="sold_field"
                                        label="name"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        disabled={is_view}
                                    />
                                </div>
                                <div className="col-sm-12 form-group has-feedback">
                                    <label htmlFor="sold_message">Sold Message</label>
                                    <Field
                                        name="sold_message"
                                        rows={6}
                                        component={renderTextArea}
                                        customClass="text-area-h-auto border"
                                        disabled={is_view}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            <div className="row mx-auto mt-3 col-lg-5 col-sm-12 justify-content-between mt-4 mb-5">
                <a className="btn btn-danger text-white col-lg-5 col-sm-12 " href="/#/franchises"> Cancel</a>
                {!is_view &&
                <button className="btn btn-success col-lg-5 col-sm-12">
                    {
                        is_update
                            ? 'update'
                            : 'create'
                    }

                </button>
                }
            </div>
        </form>
    )
}

let Form = reduxForm({
    form: 'franchiseFrom', // a unique identifier for this form
    validate
})(FranchiseForm);

const selector = formValueSelector("franchiseFrom");
Form = connect((state) => {
    const sold_certified_agentsValue = selector(state, 'sold_certified_agents');
    return {
        sold_certified_agentsValue
    };
})(Form);

export default Form;

