import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/Tracker";
import TrackerList from './TrackerList';


const ms2p = (state) => {
    return {
        ...state.tracker,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TrackerList);
