import React, {Component} from 'react';
import {connect} from "react-redux";
import {formValueSelector, reduxForm} from "redux-form";
import {validate, validators} from "validate-redux-form";
import {ProfileForm} from "../JobTypeForm";


const JobTypeEditForm = reduxForm({
    form: 'JobTypeEditForm', // a unique identifier for this form
    asyncBlurFields: [],
    validate: (data) => {
        return validate(data, {
            name: validators.exists()('This field is required'),
            job_type: validators.exists()('This field is required'),
            base_price: validators.exists()('This field is required'),
        });
    },
})(ProfileForm);

const selector = formValueSelector('JobTypeEditForm');

const mstp = state => ({
    multiple_description: selector(state, 'multiple_description'),
    icon: selector(state, 'icon'),
    job_type_option: selector(state, 'job_type_option'),
    is_duration: selector(state, 'is_duration'),
    allow_hired_agent: selector(state, 'allow_hired_agent'),
    enable_customer_feedback: selector(state, 'enable_customer_feedback'),
    is_other_services: selector(state, 'is_other_services'),
    enable_feedback: selector(state, 'enable_feedback'),
    job_type: selector(state, 'job_type'),
});

const JobTypeConnectedForm = connect(mstp, null)(JobTypeEditForm);

export default class JobTypeView extends Component {
    componentDidMount() {
        const {read, match: {params: {id}}} = this.props;
        read(id);
    }

    render() {
        const {edit, match: {params: {id}}} = this.props;
        return (
            <React.Fragment>
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page"> View Job Type</div>
                    </div>
                </div>
                <JobTypeConnectedForm onSubmit={(data) => {
                    edit(id, data);
                }} seeView setIcon={() => {
                }}/>
            </React.Fragment>
        );
    }
}
