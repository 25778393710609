import _ from "lodash";
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { change, initialize as initializeForm } from "redux-form";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { SELECT_ALL_OPTION } from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const SET_AGREEMENT = "SET_AGREEMENT_INFLUENCER"
const SET_INFLUENCER_DATA = "SET_INFLUENCER_DATA_INFLUENCER"
const SET_URL_EXCEL_TEMPLATE = "SET_URL_EXCEL_TEMPLATE_INFLUENCER"
const SET_DATA_MONTHLY_PROFITE = 'SET_DATA_MONTHLY_PROFITE_INFLUENCER';
const SET_LOADER_MONTHLY_PROFITE = 'SET_LOADER_MONTHLY_PROFITE_INFLUENCER';
const SET_DATA_YEARLY_PROFITE = 'SET_DATA_YEARLY_PROFITE_INFLUENCER';
const SET_LOADER_YEARLY_PROFITE = 'SET_LOADER_YEARLY_PROFITE_INFLUENCER';
const SET_YEAR = 'SET_YEAR_INFLUENCER';
const SET_FILE = 'SET_FILE_INFLUENCER';
const SET_LOADER_2 = 'SET_LOADER_2_INFLUENCER';
const endpoint = 'influencer'

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "influencer",
    endpoint,
    "influencerForm",
    "/deleted-agents"
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setAgreement = agreement => ({
    type: SET_AGREEMENT,
    agreement,
});
const setInfluencerData = influencerData => ({
    type: SET_INFLUENCER_DATA,
    influencerData,
});
const setUrlExcelTemplate = urlTemplate => ({
    type: SET_URL_EXCEL_TEMPLATE,
    urlTemplate,
});
const setDataMonthlyProfile = monthlyProfite => ({
    type: SET_DATA_MONTHLY_PROFITE,
    monthlyProfite,
});
const setMonthlyProfileLoader = monthlyProfiteLoader => ({
    type: SET_LOADER_MONTHLY_PROFITE,
    monthlyProfiteLoader,
});
const setDataYearlyProfile = yearlyProfite => ({
    type: SET_DATA_YEARLY_PROFITE,
    yearlyProfite,
});
const setYearlyProfileLoader = yearlyProfiteLoader => ({
    type: SET_LOADER_YEARLY_PROFITE,
    yearlyProfiteLoader,
});
const setYear = year => ({
    type: SET_YEAR,
    year,
});
const setFile = file => ({
    type: SET_FILE,
    file,
});
const setLoader2 = loader2 => ({
    type: SET_LOADER_2,
    loader2,
});

// ------------------------------------
// Actions
// ------------------------------------
export const searchChange = (search) => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    setTimeout(() => {
        dispatch(toList());
    }, 400)
};

export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case "search":
                dispatch(searchChange(value));
                break;
            default:
                break;
        }
    };

const toList =
    (page = 1) =>
    (dispatch, getStore) => {
        const store = getStore();
        const resource = store["influencer"];
        let params = { page };
        params.ordering = resource.ordering;
        const { influencerFiltersForm } = store.form;
        if (influencerFiltersForm && influencerFiltersForm.values) {
            const { search } =
                influencerFiltersForm.values;

                if (search != "" && search != undefined && search != null) {
                params.search = search
            }
        }
        dispatch(basicReducer.actions.setLoader(true));
        api.get(endpoint, params)
            .then((response) => {
                dispatch(basicReducer.actions.setData(response));
                dispatch(basicReducer.actions.setPage(page));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    };

const create =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(basicReducer.actions.setLoader(true));
        delete data.agreement;
        const agreement = getStore().influencer.agreement;
        let files = []
        if (agreement !== null) {
            files.push(
                { name: "agreement", file: agreement },
            )
        }
        api.postAttachments(endpoint, data, files)
            .then((response) => {
                NotificationManager.success(
                    "Record created",
                    "SUCCESS",
                    3000
                );
                dispatch(setAgreementFile(null));
                dispatch(push("/influencers"));
            })
            .catch((error) => {
                console.log("ERROR", error);
                NotificationManager.error("Create failed", "ERROR");
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    };

const setAgreementFile = (data) => dispatch => {
    console.log("FILE DATA: ", data);
    dispatch(setAgreement(data))
}

const read = id => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/${id}`).then((response) => {
        dispatch(basicReducer.actions.setItem(response));
        let data = response;
        if (data.sponsored_by !== null && data.sponsored_by !== undefined && data.sponsored_by_data !== null) {
            data.sponsored_by = {
                label: `${data.sponsored_by_data.first_name} ${data.sponsored_by_data.last_name}`,
                value: data.sponsored_by_data.id
            }
        }
        console.log("DATA TO EDIT: ", data);
        dispatch(initializeForm("influencerForm", data));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const edit = (id, data = {}) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    delete data.agreement;
    delete data.logo;
    const agreement = getStore().influencer.agreement;
    if (agreement !== null) {
        api.putAttachments(`${endpoint}/${id}`, data,
                [{name: 'agreement', file: agreement}])
            .then((response) => {
                NotificationManager.success('Record updated', 'SUCCESS', 3000);
                dispatch(setAgreementFile(null));
                dispatch(push('/influencers'));
            })
            .catch((error) => {
                console.log("ERROR", error);
                NotificationManager.error('Edition failed', 'ERROR');
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    }else {
        api.put(`${endpoint}/${id}`, data).then(() => {
            NotificationManager.success('Record updated', 'Success', 3000);
            dispatch(push('/influencers'));
        }).catch(() => {
            NotificationManager.error('Edition failed', 'ERROR', 0);
        }).finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
    }
};


const sendInvite = (id) => (dispatch, getStore) => {
    Swal.fire({
        title: "Are you sure to send the invitation?",
        text: "This action will create the influencer user!",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#7E8A97",
        cancelButtonColor: "#B9BDC1",
        confirmButtonText: "Yes, send it!",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(basicReducer.actions.setLoader(true));
            const { page } = getStore().influencer;
            api.post(`${endpoint}/${id}/send_invite`, {})
                .then(() => {
                    NotificationManager.success(
                        "Invitation sent successfully",
                        "Success",
                        3000
                    );
                    dispatch(toList(page));
                })
                .catch((error) => {
                    let msg = "The invitation could not be sent";
                    if (error && error.detail) {
                        msg = error.detail;
                    }
                    NotificationManager.error(msg, "ERROR", 0);
                })
                .finally(() => {
                    dispatch(basicReducer.actions.setLoader(false));
                });
        }
    });
};

const reDendInvite = (id) => (dispatch, getStore) => {
    Swal.fire({
        title: "Are you sure to Resend the invitation?",
        text: "This action generates a new password for the user!",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#7E8A97",
        cancelButtonColor: "#B9BDC1",
        confirmButtonText: "Yes, send it!",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(basicReducer.actions.setLoader(true));
            const { page } = getStore().influencer;
            api.post(`${endpoint}/${id}/re_send_invite`, {})
                .then(() => {
                    NotificationManager.success(
                        "Invitation sent successfully",
                        "Success",
                        3000
                    );
                    dispatch(toList(page));
                })
                .catch((error) => {
                    let msg = "The invitation could not be sent";
                    if (error && error.detail) {
                        msg = error.detail;
                    }
                    NotificationManager.error(msg, "ERROR", 0);
                })
                .finally(() => {
                    dispatch(basicReducer.actions.setLoader(false));
                });
        }
    });
};

const toListInfluencer = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true))
    api.get(`${endpoint}/sponcer_list`, {id}).then((response) => {
        if (response) {
            dispatch(setInfluencerData(response))
        } else {
            dispatch(setInfluencerData({
                results: [],
                count: 0,
                totals: {
                    current_month_fee: 0,
                    current_month_jobs: 0,
                    current_year_fee: 0,
                    current_year_jobs: 0,
                    total_agents: 0,
                    total_agents_registered: 0,
                }
            }))
        }
    }).catch((err) => {
        dispatch(setInfluencerData({
            results: [],
            count: 0,
            totals: {
                current_month_fee: 0,
                current_month_jobs: 0,
                current_year_fee: 0,
                current_year_jobs: 0,
                total_agents: 0,
                total_agents_registered: 0,
            }
        }))
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false))
    })
}

const getUrlExelTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrlExcelTemplate(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const getMonthlyProfite = (id) => (dispatch, getStore) => {
    dispatch(setMonthlyProfileLoader(true))
    const store = getStore();
    const {year} = store.influencer;
    let params = {id};
    if (year && year.value) {
        params.year = year.value
    }

    api.get(`${endpoint}/sponcer_month_graph`, params)
        .then((response) => {
            console.log('RESPONSE: ', response);
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (item[names] === null) {
                        data.push(0)
                    } else {
                        data.push(item[names])
                    }
                    const _name = _.find(labels, function(o) {
                        return o == names
                    })
                    if (!_name) {
                        labels.push(names);
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: "Fee",
                    borderWidth: 1.6,
                    borderColor: '#5C7CCF',
                    backgroundColor: '#5C7CCF',
                })
            })
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataMonthlyProfile(dataFormatter));
        }).catch((err) => {
            dispatch(setDataMonthlyProfile({
                total: 0,
                labels: [],
                datasets: [],
            }))
        }).finally(() => {
            dispatch(setMonthlyProfileLoader(false))
        })
}

const getYearlyProfite = (id) => (dispatch) => {
    dispatch(setYearlyProfileLoader(true))

    api.get(`${endpoint}/sponcer_year_graph`, {id})
        .then((response) => {
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                response.data.forEach(item => {
                    let data = []
                    for (let names in item) {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function(o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                    }
                    dataReceived.push({
                        data: data,
                        tension: 0.4,
                        label: "Fee",
                        borderWidth: 1.6,
                        borderColor: '#E28B2A',
                        backgroundColor: '#E28B2A',
                    })
                })
            })
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataYearlyProfile(dataFormatter));
        }).catch((err) => {
            dispatch(setDataYearlyProfile({
                total: 0,
                labels: [],
                datasets: [],
            }))
        }).finally(() => {
            dispatch(setYearlyProfileLoader(false))
        })
}

const createTeam = (id, data, closeModal) => (dispatch, getStore) => {
    dispatch(setLoader2(true))
    delete data.office_excel
    const store = getStore();
    const {file} = store.influencer;
    console.log("FILE: ", file);
    let _attachments = []
    if (file) {
        _attachments.push({name: 'roster_file', file: file})
    }
    api.postAttachments('influencer_team', data, _attachments, {id})
        .then((response) => {
            dispatch(setFile(null))
            closeModal && closeModal()
            dispatch(toListInfluencer(id))
            NotificationManager.success('Team created', 'SUCCESS', 3000);
        }).catch(error => {
            let msg = "Create failed"
            if (error && error.detail) {
                msg = error.detail
            }
            NotificationManager.error(msg, 'ERROR', 5000);
        })
        .finally(() => {
            dispatch(setLoader2(false));
        });
}

const setExcel = (file) => (dispatch) => {
    dispatch(setFile(file))
}

const setCityState = (data) => (dispatch) => {
    console.log("FINAL DATA: ", data);
    const state = data && data.state ? data.state : null
    const city = data && data.city ? data.city : null
    dispatch(change('influencerForm', 'state', state))
    dispatch(change('influencerForm', 'city', city))
}

const remove = id => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.eliminar(`${endpoint}/${id}`).then(() => {
        dispatch(toList());
        NotificationManager.success('Record removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const removeInfluencer = (item) => (dispatch, getStore) => {
    const {id} = item;
    dispatch(basicReducer.actions.setLoader(true));
    api.post(`${endpoint}/send_validation_code`).then(reponse => {
        Swal.fire({
            title: 'Are you sure you want to delete this Influencer?',
            text: "To confirm please enter the verification code",
            input: 'text',
            // inputLabel: "To confirm please enter the verification code",
            // inputPlaceholder: "To confirm please enter the verification code",
            type: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Next',
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            showLoaderOnConfirm: true,
            preConfirm: (code) => {
                return api.post(`${endpoint}/validate_code`, {code}).then(response => {
                    return response
                }).catch(error => {
                    let msg = "Could not validate the code try again"
                    if (error && error.detail) {
                        msg = error.detail
                    }
                    Swal.showValidationMessage(
                        `${msg}`
                    )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value && result.value.detail === "Correct code") {
                let htmlTemplate = `<p style="font-size: 15px;margin-bottom: 0;">by doing that, all the agents under this influencer will be moved to the Open Market office</p><br/>`
                htmlTemplate += `<p style="font-size: 17px; font-weight: bold;margin-bottom: 0;">This action can't be reverted!</p>`

                Swal.fire({
                    title: 'Are you sure you want to delete this Influencer?',
                    html: htmlTemplate,
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: '#7E8A97',
                    cancelButtonColor: '#B9BDC1',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.value) {
                        dispatch(remove(id))
                    }
                });
                console.log("DELETED");
            }
        })
    }).catch(error => {
        NotificationManager.error('Failed to send verification code, please try again later', 'Error', 1000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    })
};

export const actions = {
    ...basicReducer.actions,
    searchChange,
    changeFilters,
    toList,
    setAgreementFile,
    create,
    read,
    edit,
    sendInvite,
    toListInfluencer,
    getUrlExelTemplate,
    getMonthlyProfite,
    getYearlyProfite,
    setExcel,
    createTeam,
    setCityState,
    reDendInvite,
    removeInfluencer,
}
// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [SET_AGREEMENT]: (state, {agreement}) => {
        return {
            ...state,
            agreement,
        };
    },
    ...basicReducer.reducers,
    [SET_INFLUENCER_DATA]: (state, {influencerData}) => {
        return {
            ...state,
            influencerData,
        };
    },
    [SET_URL_EXCEL_TEMPLATE]: (state, {urlTemplate}) => {
        return {
            ...state,
            urlTemplate,
        };
    },
    [SET_DATA_MONTHLY_PROFITE]: (state, {monthlyProfite}) => {
        return {
            ...state,
            monthlyProfite,
        };
    },
    [SET_LOADER_MONTHLY_PROFITE]: (state, {monthlyProfiteLoader}) => {
        return {
            ...state,
            monthlyProfiteLoader,
        };
    },
    [SET_DATA_YEARLY_PROFITE]: (state, {yearlyProfite}) => {
        return {
            ...state,
            yearlyProfite,
        };
    },
    [SET_LOADER_YEARLY_PROFITE]: (state, {yearlyProfiteLoader}) => {
        return {
            ...state,
            yearlyProfiteLoader,
        };
    },
    [SET_YEAR]: (state, {year}) => {
        return {
            ...state,
            year,
        };
    },
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [SET_FILE]: (state, {file}) => {
        return {
            ...state,
            file,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    agreement: null,
    ...basicReducer.initialState,
    file: null,
    influencerData: {
        results: [],
        count: 0,
        totals: {
            current_month_fee: 0,
            current_month_jobs: 0,
            current_year_fee: 0,
            current_year_jobs: 0,
            total_agents: 0,
            total_agents_registered: 0,
        }
    },
    urlTemplate: '',
    monthlyProfite: {
        total: 0,
        labels: [],
        datasets: [],
    },
    monthlyProfiteLoader: false,
    yearlyProfite: {
        total: 0,
        labels: [],
        datasets: [],
    },
    yearlyProfiteLoader: false,
    year: {
        label: new Date().getFullYear().toString(),
        value: new Date().getFullYear(),
    },
    loader2: false,
}

export default handleActions(reducers, initialState);
