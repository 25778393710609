import React from 'react';
import { Link } from "react-router-dom";

const StepFinish = () => {
    return (
        <div>
            <div className=" w-75 mx-auto text-center">
                <h5 style={{
                    font: "normal normal 600 18px/19px Poppins-SemiBold",
                    letterSpacing: 0,
                    color: "#414141",
                    opacity: 1,
                }}> Thank you for register up.</h5>

                <p className="py-0 my-0 text-login">
                    Thank you for registering with ShowIt.
                </p>
                <p className="text-login">
                    By registering, you are allowing your top agents to be more productive by leveraging their time and
                    resources, while your newer agents can gain valuable experience from day 1.
                    At the same time, you will generate an additional income stream for your brokerage.
                </p>
                <p className="py-0 my-0 ">Next step</p>
                <p className="mb-2 text-login">
                    Check your inbox for instructions how your agents can start leveraging ShowIT today.
                </p>
                <p className="text-login">
                    Please setup your Payout under Menu / Payment
                </p>

            </div>

            <div className="buttons-box w-75  px-5 mt-5 mx-auto ">
                <Link to="/login" className="btn button-01 w-50 pt-2 text-white d-block ">Login</Link>
            </div>
        </div>
    );
};

export default StepFinish;
