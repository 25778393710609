import _ from "lodash"
import { api } from "api";
import Swal from 'sweetalert2';
import { handleActions } from 'redux-actions';
import {initialize as initializeForm} from "redux-form"
import { NotificationManager } from "react-notifications";

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "review"
const LOADER = "AGENT_REVIEWS_LOADER";
const DATA = "AGENT_REVIEWS_DATA";
const PAGE = "AGENT_REVIEWS_PAGE";
const ITEM = "AGENT_REVIEWS_ITEM";

// ------------------------------------
// Pure Actions
// ------------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

// ------------------------------------
// Actions
// ------------------------------------
const toList = (agentId, page = 1) => (dispatch) => {
    // const resource = getStore()[storeId];
    const params = { page, agent_id: agentId };
    // params.ordering = resource.ordering;
    // params.search = resource.search;
    dispatch(setLoader(true));
    api.get(`${endpoint}/agent_reviews`, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const read = (id, agentId) => (dispatch) => {
    const params = { id, agent_id: agentId };
    dispatch(setLoader(true));
    api.get(`${endpoint}/agent_reviews`, params).then((response) => {
        const item =  response;
        dispatch(setItem(item));
        let data = {
        }
        if (item.job && item.job.review) {
            const {id, professionalism, timeliness, communication, quality, feedback, notes} = item.job.review;
            data['professionalism'] = professionalism;
            data['timeliness'] = timeliness;
            data['communication'] = communication;
            data['quality'] = quality;
            data['feedback'] = feedback;
            data['notes'] = notes;
            data['review_id'] = id;
        }
        if (item.job && item.job.customer_feedback){
            const has_feedback =
                item.job.customer_feedback.id &&
                item.job.customer_feedback.form.length;
            let notes = _.find(
                has_feedback ? item.job.customer_feedback.form : [],
                {
                    question: "Notes",
                }
            );
            if (item.job.customer_feedback.id) {
                data['customer_feedback_id'] = item.job.customer_feedback.id
            }
            if (notes != null && notes != null && notes != '') {
                data["customer_feedback_notes"] = has_feedback
                    ? notes !== null && notes !== undefined
                        ? notes.answer
                        : ""
                    : "";
            }
        }

        dispatch(initializeForm("updateAgentReviewForm", data))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const edit = (data, goBack) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${endpoint}/update_agent_review`, data).then((response) => {
        NotificationManager.success('Review updated', 'Success', 3000);
        goBack()
    }).catch(() => {
        NotificationManager.error('Edition failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const remove = (item) => (dispatch) => {
    Swal.fire({
        title: 'Are you sure to delete the review?',
        text: "This action can't be reverted!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            let data = {};
            if (item.job && item.job.review) {
                const {id} = item.job.review;
                data["review_id"] = id;
            }
            if (item.job && item.job.customer_feedback){
                data['customer_feedback_id'] = item.job.customer_feedback.id
            }
            console.log("ITEM: ", data);
            dispatch(setLoader(true));
            api.put(`${endpoint}/delete_agent_review`, data).then((response) => {
                NotificationManager.success('Review updated', 'Success', 3000);
                if(item.agent) {
                    dispatch(toList(item.agent))
                }
            }).catch(() => {
                NotificationManager.error('Edition failed', 'ERROR', 0);
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    });
};

export const actions = {
    toList,
    read,
    edit,
    remove,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
        agent: null
    },
    item: {},
    page: 1,
};

export default handleActions(reducers, initialState);