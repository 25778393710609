import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/stats'
import Details from "./PostsDetails";

const ms2p = (state) => {
    return{
        ...state.stats,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Details);
