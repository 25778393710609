import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { formValueSelector } from "redux-form";
import FileUploader from "../../../Utils/FileUploader/FileUploader";
import {
    renderField,
    renderTextArea,
    SelectField,
} from "../../../Utils/renderField";

const validate = (values, props) => {
    const errors = {}

    if (!values.title) {
        errors.title = "This field is required";
    }

    if (!values.text_message) {
        errors.text_message = "This field is required";
    }
    if (!values.android_version) {
        errors.android_version = "This field is required";
    }
    if (!values.ios_version) {
        errors.ios_version = "This field is required";
    }

    return errors
}

const Form = (props) => {
    //  State
    const {
        is_view,
        is_update,
        androidOptions,
        iosOptions,
        loader,
        image,
        image_error=null,
        anyTouched,
        item,
        typeValue,
    } = props;
    const itemImg = ((is_update || is_view) && item && item.image && typeValue == 'mms') ? item.image : null;
    const showSendBtn = is_view;
    const TYPES = [{label: 'SMS', value: 'sms'}, {label: 'MMS', value: 'mms'}];

    //  Bind
    const {handleSubmit, onFileChange, sendMessage} = props;

    const [tab, setTab] = useState('Registered')

    console.log("TYPE: ", typeValue);

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="row">
                        <div className="mt-3 col-lg-12 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="title">Title</label>
                                <Field
                                    name="title"
                                    label="Title"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-12 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="text_message">SMS</label>
                                <Field
                                    name="text_message"
                                    label="Title"
                                    component={renderTextArea}
                                    type="text"
                                    customClass="form-control text-area-h-auto"
                                    disabled={is_view}
                                    rows={6}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-8 col-sm-12 mb-0">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <label htmlFor="agent_status">Type</label>
                            <Field
                                name="type"
                                isMulti={false}
                                options={TYPES}
                                set_value={(option) => {
                                    console.log(option);
                                }}
                                component={SelectField}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        {
                            typeValue == 'mms' && (
                                <div className="content-drop-zone col-lg-7 col-sm-12">
                                    <label htmlFor="photo">Images</label>
                                    <FileUploader
                                        onFileChange={onFileChange}
                                        fieldName={"campaign_photo"}
                                        width="100%"
                                        dashed={true}
                                        img={image ? null : itemImg}
                                        disabled={is_view || typeValue == 'sms'}
                                    />
                                    {image_error && anyTouched && (
                                        <div className="invalid-feedback d-block">
                                            {image_error}
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    </div>
                    <div className="row">
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="android_version">Android Version</label>
                                <Field
                                    name="android_version"
                                    isMulti={false}
                                    options={androidOptions}
                                    set_value={(option) => {
                                        console.log(option);
                                    }}
                                    component={SelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="ios_version">iOS Version</label>
                                <Field
                                    name="ios_version"
                                    isMulti={false}
                                    options={iosOptions}
                                    set_value={(option) => {
                                        console.log(option);
                                    }}
                                    component={SelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`row mx-auto mt-3 col-lg-5 col-sm-12 justify-content-between mt-4 mb-5`}
            >
                <a
                    className={`btn btn-danger text-white col-lg-5 col-sm-12`}
                    href="/#/campaigns_app_version"
                >
                    {" "}
                    Cancel
                </a>
                {!is_view && (
                    <button
                        className={`btn btn-success col-lg-5 col-sm-12`}
                        disabled={loader}
                    >
                        {is_update ? "Update" : "Create"}
                    </button>
                )}
                {showSendBtn && sendMessage && (
                    <button
                        onClick={sendMessage}
                        type="button"
                        className="btn btn-secondary col-lg-5 col-sm-12"
                        disabled={loader}
                    >
                        Send Message
                    </button>
                )}
            </div>
        </form>
    );
};

let CreateAppVersionForm = reduxForm({
    form: "twilioAppVersionFrom", // a unique identifier for this form
    validate,
    initialValues: {type: 'sms'}
})(Form);

const selector = formValueSelector("twilioAppVersionFrom");
CreateAppVersionForm = connect((state) => {
    const typeValue = selector(state, "type");
    const agent_statusValue = selector(state, 'agent_status');
    return {
        typeValue,
        agent_statusValue,
    };
})(CreateAppVersionForm);

export default CreateAppVersionForm;
