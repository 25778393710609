import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { SelectField, MultiSelectField } from "../../Utils/renderField";
import {renderDayPicker} from "../../Utils/renderField/renderField";
import {
    STATE_USA_LIST,
    SELECT_ALL_OPTION,
    AGENT_PERFORMANCE_TIME,
} from '../../../../utility/constants'

let FilterStatsRevenueForm = (props) => {
    //  State
    const {
        optionFranchises=[],
        optionBrokers=[],
        optionBrokerages=[],
        optionJobTypes=[],
        optionAgents=[],
        timeValue,
        start_dateValue,
        end_dateValue,
    } = props;

    //  Bind
    const {changeFilters} = props;
    return (
        <div className="row">
            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="state">State</label>
                    <Field
                        name="state"
                        options={STATE_USA_LIST}
                        set_value={(value) => {
                            changeFilters("state", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="franchise">Franchise</label>
                    <Field
                        name="franchise"
                        options={optionFranchises}
                        set_value={(value) => {
                            changeFilters("franchise", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>

            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="broker">Broker</label>
                    <Field
                        name="broker"
                        options={optionBrokers}
                        set_value={(value) => {
                            changeFilters("broker", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>

            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="brokerage">Office</label>
                    <Field
                        name="brokerage"
                        options={optionBrokerages}
                        set_value={(value) => {
                            changeFilters("brokerage", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div
                className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="job_type">Job Type</label>
                    <Field
                        name="job_type"
                        options={optionJobTypes}
                        set_value={(value) => {
                            changeFilters("job_type", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="agent">Agent</label>
                    <Field
                        name="agent"
                        options={optionAgents}
                        set_value={(value) => {
                            changeFilters("agent", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                <div className="form-group has-feedback w-100">
                    <label htmlFor="time">Time</label>
                    <Field
                        name="time"
                        isMulti={false}
                        options={AGENT_PERFORMANCE_TIME}
                        set_value={(option) => {
                            changeFilters("time", option ? option.value : "");
                        }}
                        component={SelectField}
                        className="form-control"
                    />
                </div>
            </div>
            {timeValue == "custom" && (
                <React.Fragment>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="start_date">Start Date</label>
                            <Field
                                name="start_date"
                                initialDate={moment()
                                    .format("YYYY-MM-DD")
                                    .toString()}
                                component={renderDayPicker}
                                maxDate={
                                    end_dateValue
                                        ? moment(end_dateValue).toDate()
                                        : new Date()
                                }
                                onChange={(e) => {
                                    changeFilters("start_date", "");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 mt-2">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="end_date">End Date</label>
                            <Field
                                name="end_date"
                                initialDate={moment()
                                    .format("YYYY-MM-DD")
                                    .toString()}
                                component={renderDayPicker}
                                minDate={
                                    start_dateValue
                                        ? moment(start_dateValue).toDate()
                                        : new Date()
                                }
                                onChange={(e) => {
                                    changeFilters("end_date", "");
                                }}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

FilterStatsRevenueForm = reduxForm({
    form: 'FilterStatsRevenueForm', // a unique identifier for this form
    initialValues: {state: SELECT_ALL_OPTION["value"]}
})(FilterStatsRevenueForm);

const selector = formValueSelector('FilterStatsRevenueForm');

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    }
}

export default connect(mstp, null)(FilterStatsRevenueForm);
