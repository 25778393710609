import React, {Component} from 'react';
import AgentsUnregistered from "./AgentUnregisteredGrid";
import {SELECT_ALL_OPTION} from "../../../../utility/constants"


class AgentRoster extends Component {

    state = {
        file: null,
        fileUpdate: null,
        license: '',
        photo: null,
        nameFile: '',
        nameFileUpdate: '',
        active: true,
        activeFileUpdate: true,
        brokerage_id: 0,
    };

    componentDidMount() {
        this.props.toList2();
    }

    // componentWillUnmount() {
    //     this.props.setToDefaultValueFilter()
    // }

    actionFile = (data) => {
        const {setFiles} = this.props;
        setFiles(data)
        this.setState({photo: data});
    }

    setActive = (value) => {
        this.setState({active: value, file: null, nameFile: ''});
    }

    onChanged = (file) => {
        this.setState({nameFile: file ? file.name : "", file: file, active: file ? false : true});
        if (file) {
            const id = this.idSelectedOffice()
            this.props.uploadAgentList(id, file);
        }
    };

    setActiveFileUpdate = (value) => {
        this.setState({activeFileUpdate: value, fileUpdate: null, nameFileUpdate: ''});
    }

    onChangeUpdateRoster = (file) => {
        this.setState({nameFileUpdate: file ? file.name : "", fileUpdate: file, activeFileUpdate: file ? false : true});
        if (file) {
            const id = this.idSelectedOffice()
            this.props.updateRosterList(id, file);
        }
    }

    downLoadRosterList = () => {
        // const id = this.props.office_selected
        // let idOffice = null;
        // if (office_selected && office_selected.length) {
        //     idOffice = office_selected
        // }
        this.props.downloadRosterList()
    }


    sendMassiveInvites = () => {
        this.props.sendInviteToAllAgent();
    }

    idSelectedOffice = () => {
        const office_selected = this.props.office_selected
        let idOffice = null;
        if (office_selected && office_selected.length == 1) {
            if (office_selected[0] != SELECT_ALL_OPTION["value"]) {
                idOffice = office_selected[0]
            }
        }

        return idOffice;
    }


    render() {
        const {
            page,
            data,
            loader,
            urlTemp,
            toList2,
            offices,
            optionsBroker,
            searchChange2,
            setOfficeFilter,
            setCategoryFilter,
            setValueBrokerFilter,
            provide_agents_roster,
            office_selected,
            downloadLoader,
            tabContainerHeight,
            onSortChange,
        } = this.props;

        return (
            <AgentsUnregistered
                loader={loader}
                data={data}
                page={page}
                toList={toList2}
                urlTemp={urlTemp}
                options={offices}
                sendInvites2={this.sendMassiveInvites}
                text={this.state.nameFile}
                active={this.state.active}
                setActive={this.setActive}
                filter1={setCategoryFilter}
                filter2={setOfficeFilter}
                filter3={setValueBrokerFilter}
                searchAction={searchChange2}
                optionsBroker={optionsBroker}
                handleOnChange={this.onChanged}
                sendInvite={this.props.sendAgentInvite}
                provideAgentsRoster={provide_agents_roster}
                officeSelected={this.idSelectedOffice()}
                downloadLoader={downloadLoader}
                downLoadRosterList={this.downLoadRosterList}
                tabContainerHeight={tabContainerHeight}
                onSortChange={onSortChange}

                textFileUpdate={this.state.nameFileUpdate}
                activeFileUpdate={this.state.activeFileUpdate}
                onChangeUpdateRoster={this.onChangeUpdateRoster}
                setActiveFileUpdate={this.setActiveFileUpdate}
            />
        )
    }
}

export default AgentRoster;
