import React, {useState, useEffect} from 'react';
import HeaderCard from "../HeaderCard";
import AdminFilterForm from "./AdminFilterForm"
import Grid from "../Utils/GridTableWithScroll";
import {standardActions} from "../Utils/Grid/StandardActions";
import {avatar} from "../Agent/AgentRegistered/AgentGrid";
import {formatPhoneNumber} from "../Brokerage/BrokerageGrid";
import {ADMIN_GRID_PAGE_SIZE} from "../../../utility/constants";


const AdminListGrid = (props) => {
    const {data, loader, page, searchAction, toList, remove} = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("MANAGERS-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])
    

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">Managers</div>
                </div>
            </div>
            {/* <HeaderCard
                showFile={false}
                active_link={true}
                text_link={"Create"}
                id_header={'AdminList'}
                link={'manager/create-manager/'}
                justify={'justify-content-between mt-2'}
                searchAction={(value) => searchAction(value)}
                searchStyle="col-lg-4 col-sm-12 img-fluid mb-sm-1 ml-3"
            /> */}
            <AdminFilterForm searchAction={searchAction}/>
            <div id="MANAGERS-LIST" className="container-list flex-1 card border-dark border-card-page px-4 py-2">
                <div className="card-body px-0 py-2 d-flex">
                    <Grid
                        hover
                        keyField={'id'}
                        loading={loader}
                        height={gridHeight}
                        page={page}
                        data={data}
                        sizePerPage={ADMIN_GRID_PAGE_SIZE}
                        onPageChange={(value) => toList(value)}
                        wrapperClasses="table-scroll-auto"
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        remove: remove,
                                        // view: "manager",
                                        edit: 'manager',
                                    }),
                            },
                            {
                                dataField: "photo",
                                formatter: avatar(),
                                text: " ",
                            },
                            {
                                dataField: "first_name",
                                sort: true,
                                text: "First Name",
                            },
                            {
                                dataField: "last_name",
                                sort: true,
                                text: "Last Name",
                            },
                            {
                                dataField: "mobile_number",
                                formatter: formatPhoneNumber(),
                                text: "mobile number",
                            },
                            {
                                dataField: "email",
                                sort: true,
                                text: "E-mail",
                            },
                            {
                                dataField: "",
                                text: ""
                            }
                        ]}
                    />
                </div>

            </div>

        </div>
    );
};

export default AdminListGrid;
