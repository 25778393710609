import {handleActions} from 'redux-actions';
import {push} from "react-router-redux";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import {setMe, getMe} from "./login";

const LOADER = 'LOGIN_LOADER';

export const constants = {};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
// ------------------------------------
// Actions
// ------------------------------------

export const update = (data = {}, attachments = []) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.putAttachments('user/update_me', data, attachments).then((response) => {
        dispatch(getMe());
        dispatch(push("/"));
        // dispatch(setMe(response));
        NotificationManager.success('Profile updated', 'SUCCESS', 3000);
    }).catch(() => {
        NotificationManager.error('Fail to update profile, please try again', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changePassword = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`broker-user/change_password`, data)
        .then((response) => {
            const me = response;
            dispatch(setMe(me));
            NotificationManager.success('Account password updated', 'SUCCESS', 3000);
        })
        .catch(() => {
            NotificationManager.error('Error to change password', 'ERROR', 2000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const actions = {
    update,
    getMe,
    changePassword,
};

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
};

export default handleActions(reducers, initialState);
