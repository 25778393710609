import React from 'react';
import {Link} from "react-router-dom";
import {Field, FieldArray} from 'redux-form';
import Grid from '../Utils/Grid';
import {
    renderField,
    SelectField,
    renderFieldCheck,
    renderSwitch,
    AsyncSelectField,
    ColorPicker,
    renderCurrency,
    renderFilePicker,
    renderNumber,
    renderTextArea,
    renderFileImageCrop,
} from '../Utils/renderField/renderField';
import {
    CUSTOMER_TYPE,
    JOB_TYPES,
    SINGLE,
    OTHER_SERVICES_LIST,
    INSTALL_REMOVE,
    TAKE_PHOTO_VIDEO, MOVE, LIST_SHOW_WHEN, AD_ALWAYS,
    TYPES_APPLICATION,
    OPTION_TYPES, BASIC,
} from "../../../utility/constants";
import {api} from "../../../utility/api";

const loadCustomerForms = (search, callback) => {
    api.get("feedback-form-customer", {search}).then((response) => {
        const options = [];
        response.results.forEach((result) => {
            options.push({
                label: result.name,
                value: result.id,
            });
        });
        callback(options);
    }).catch(() => {
    });
};

const loadAgentForms = (search, callback) => {
    api.get("feedback-form-agent", {search}).then((response) => {
        const options = [];
        response.results.forEach((result) => {
            options.push({
                label: result.name,
                value: result.id,
            });
        });
        callback(options);
    }).catch(() => {
    });
};

const loadClientForms = (search, callback) => {
    api.get("feedback-form-agent-customer", {search}).then((response) => {
        const options = [];
        response.results.forEach((result) => {
            options.push({
                label: result.name,
                value: result.id,
            });
        });
        callback(options);
    }).catch(() => {
    });
};

const renderPrices = ({fields, seeView, nameArray, meta: {error}}) => (
    <React.Fragment>
        <div className="w-100 px-2">
            <Grid
                // hover
                keyField="id"
                striped
                data={{results: fields}}
                loading={false}
                hidePagination={true}
                columns={[
                    {
                        dataField: "id",
                        formatter: (cell, row) => (
                            <div className="d-flex justify-content-center align-items-center">
                                {!seeView && (
                                    <img
                                        src={require("../../../../assets/img/delete.svg")}
                                        alt="del"
                                        style={{width: "1.5rem"}}
                                        onClick={() => {
                                            let index = row.replace(`${nameArray}[`, '');
                                            index = index.replace(']', '');
                                            fields.remove(parseInt(index));
                                        }}
                                        className="pointer"
                                    />
                                )}
                            </div>
                        ),
                        text: "",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="">
                                <Field
                                    name={`${row}.duration`}
                                    component={renderNumber}
                                    disabled={'disabled'}
                                    customClass="form-control w-100"
                                />
                            </div>
                        ),
                        text: "Hours",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="my-2">
                                <Field
                                    name={`${row}.price`}
                                    disabled={seeView}
                                    component={renderCurrency}
                                    placeholder="Price"
                                    customClass="form-control w-100"
                                />
                            </div>
                        ),
                        text: "Price",
                    },
                ]}
            />
        </div>
        <div className="d-flex justify-content-center mt-2">
            {!seeView && (
                <button type="button" className="btn button-02 py-0"
                        onClick={() => fields.push({duration: fields.length + 1})}>
                    Add Hourly Price
                </button>
            )}
        </div>
    </React.Fragment>
);

const renderOptions = ({ fields, seeView, nameArray, meta: { error } }) => (
    <React.Fragment>
        <div className="w-100 px-2 pb-3">
            <Grid
                // hover
                keyField="id"
                striped
                data={{ results: fields }}
                loading={false}
                hidePagination={true}
                columns={[
                    {
                        dataField: "id",
                        formatter: (cell, row) => (
                            <div className="d-flex justify-content-center align-items-center">
                                {!seeView && (
                                    <img
                                        src={require("../../../../assets/img/delete.svg")}
                                        alt="del"
                                        style={{ width: "1.5rem" }}
                                        onClick={() => {
                                            let index = row.replace(
                                                `${nameArray}[`,
                                                ""
                                            );
                                            index = index.replace("]", "");
                                            fields.remove(parseInt(index));
                                        }}
                                        className="pointer"
                                    />
                                )}
                            </div>
                        ),
                        text: "",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="">
                                <Field
                                    disabled={seeView}
                                    name={`${row}.name`}
                                    component={renderField}
                                    customClass="form-control w-100"
                                    type="text"
                                />
                            </div>
                        ),
                        text: "Name",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="">
                                <Field
                                    name={`${row}.type`}
                                    id="type"
                                    placeholder="Type"
                                    options={OPTION_TYPES}
                                    component={SelectField}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        ),
                        headerStyle: { width: '180px' },
                        text: "Type",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="">
                                <Field
                                    disabled={seeView}
                                    name={`${row}.hours_limit`}
                                    component={renderNumber}
                                    customClass="form-control w-100"
                                />
                            </div>
                        ),
                        headerStyle: { width: '100px' },
                        text: "Maximum hours",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="my-2">
                                <Field
                                    name={`${row}.base_price`}
                                    disabled={seeView}
                                    component={renderCurrency}
                                    placeholder="$ 0.00"
                                    customClass="form-control w-100"
                                />
                            </div>
                        ),
                        headerStyle: { width: '100px' },
                        text: "Base Price",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="my-2">
                                <Field
                                    name={`${row}.lead_report_price`}
                                    disabled={seeView}
                                    component={renderCurrency}
                                    placeholder="$ 0.00"
                                    customClass="form-control w-100"
                                />
                            </div>
                        ),
                        headerStyle: { width: '100px' },
                        text: "Report Price",
                    },
                    {
                        dataField: "id",
                        formatter: (cell, row, rowIndex) => (
                            <div className="my-2">
                                <Field
                                    disabled={seeView}
                                    name={`${row}.d_agent_is_paid`}
                                    component={renderSwitch}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                        ),
                        text: "Pay the Showing agent",
                    },
                ]}
            />
        </div>
        <div className="d-flex justify-content-center mt-5">
            {!seeView && (
                <button
                    type="button"
                    className="btn button-02 py-0"
                    onClick={() => fields.push({
                        name: null,
                        type: BASIC,
                        hours_limit: null,
                        base_price: null,
                        lead_report_price: null,
                        d_agent_is_paid: false,
                    })}
                >
                    Add Option
                </button>
            )}
        </div>
    </React.Fragment>
);

const renderVendorHours = ({fields, seeView, nameArray, meta: {error}}) => (
    <React.Fragment>
        <div className="w-100 px-2">
            <div className="row w-100 mx-1 header-services">
                <div className={"col-1"}><label className={"text-white"}> </label></div>
                <div className={"col-8"}><label className={"text-white"}> Hours </label></div>
                <div className={"col-3"}><label className={"text-white"}> Fee</label></div>
            </div>
            {fields.map((row, indexS) => (
                <div
                    key={`prices-${indexS}-ss`}
                    className="row w-100 mx-1 mb-1 py-2"
                    style={{backgroundColor: (indexS % 2 !== 0) ? '#E6E6E5' : 'white'}}>
                    <div className="col-1 justify-content-center">
                        {!seeView && (
                            <img
                                src={require("../../../../assets/img/delete.svg")}
                                alt="del"
                                style={{width: "1.5rem"}}
                                onClick={() => {
                                    fields.remove(indexS);
                                }}
                                className="pointer align-self-center ml-3 mt-1"
                            />
                        )}
                    </div>
                    <div className="col-8">
                        <Field
                            name={`${row}.duration`}
                            component={renderField}
                            disabled={false}
                            customClass="form-control w-100"
                            placeholder="Description"
                        />
                    </div>
                    <div className="col-3">
                        <Field
                            name={`${row}.price`}
                            disabled={seeView}
                            component={renderCurrency}
                            placeholder="Fee"
                            customClass="form-control w-100"
                        />
                    </div>
                </div>
            ))
            }
        </div>
        <div className="d-flex justify-content-center mt-2">
            {!seeView && (
                <button
                    type="button"
                    className="btn button-02 py-0"
                    onClick={() => fields.push({
                        duration:
                            fields.length > 0 ?
                                (parseFloat(fields.get(fields.length - 1).duration) + parseFloat('0.5'))
                                : parseFloat('0.5')
                    })}
                >
                    Add Service
                </button>
            )}
        </div>
    </React.Fragment>
);

const renderExtraServices = ({fields, seeView, nameArray, meta: {error}}) => (
    <React.Fragment>
        {fields.map((row, indexS) => (
            <div className="mb-2 text-left px-2">
                <label> Extra Services #{indexS+1}</label>
                <div
                    key={`service-${indexS}-ss`}
                    className="ml-1"
                >
                    <div className="row w-100 mx-1 justify-content-between pt-1">
                        <div className="justify-content-center" style={{width: '5%'}}>
                            {!seeView && (
                                <img
                                    src={require("../../../../assets/img/delete.svg")}
                                    alt="del"
                                    style={{width: "1.5rem"}}
                                    onClick={() => {
                                        fields.remove(indexS);
                                    }}
                                    className="pointer align-self-center ml-3 mt-1"
                                />
                            )}
                        </div>
                        <div className="d-flex flex-column" style={{width: '95%'}}>
                            <div className="w-100 d-flex">
                                <div className="pr-2 flex-3">
                                    <label htmlFor={`${row}.description`}> Description </label>
                                    <Field
                                        name={`${row}.description`}
                                        component={renderField}
                                        disabled={seeView}
                                        customClass="form-control w-100"
                                        placeholder="Description"
                                    />
                                </div>
                                <div className="pr-1 flex-1">
                                    <label htmlFor={`${row}.fee`}> Fee </label>
                                    <Field
                                        name={`${row}.fee`}
                                        disabled={seeView}
                                        component={renderCurrency}
                                        placeholder="Fee"
                                        customClass="form-control w-100"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
        <div className="d-flex justify-content-center mt-2">
            {!seeView && (
                <button
                    type="button"
                    className="btn button-02 py-0"
                    onClick={() => fields.push({
                        extra_field: false,
                        services_shackle_code: false,
                        show_if: AD_ALWAYS
                    })}
                >
                    Add
                </button>
            )}
        </div>
    </React.Fragment>
)

const renderAdditionalServices = ({fields, seeView, nameArray, meta: {error}}) => (
    <React.Fragment>
        {fields.map((row, indexS) => (
            <div className="mb-2 text-left">
                <label> Services #{indexS+1}</label>
                {/* <div className="row w-100 mx-1 header-services justify-content-between bg-light">
                    <div className="" style={{width: '5%'}}>
                        <label className={""}> </label>
                    </div>
                    <div className="px-0 align-items-center my-auto" style={{width: '38%'}}>
                        <label className={"align-items-center"}> Description </label>
                    </div>
                    <div className="align-items-center my-auto" style={{width: '12%'}}>
                        <label className={""}> Fee</label>
                    </div>
                    <div className="mx-0 text-center my-auto" style={{width: '10%'}}>
                        <label className={""}> Copy Field </label>
                    </div>
                    <div className="pr-1 text-center pt-1" style={{width: '12%'}}>
                        <label className={""}> Shackle Code </label>
                    </div>
                    <div className="form-group has-feedback my-auto" style={{width: '18%'}}>
                        <label className={"pl-1"}> Show when </label>
                    </div>
                </div> */}
                <div
                    key={`service-${indexS}-ss`}
                    className="ml-1"
                    // style={{
                    //     backgroundColor: (indexS % 2 !== 0) ? '#E6E6E5' : 'white'
                    // }}
                >
                    <div className="row w-100 mx-1 justify-content-between pt-1">
                        <div className="justify-content-center" style={{width: '5%'}}>
                            {!seeView && (
                                <img
                                    src={require("../../../../assets/img/delete.svg")}
                                    alt="del"
                                    style={{width: "1.5rem"}}
                                    onClick={() => {
                                        fields.remove(indexS);
                                    }}
                                    className="pointer align-self-center mr-3 mt-1"
                                />
                            )}
                        </div>
                        <div className="d-flex flex-column" style={{width: '95%'}}>
                            <div className="w-100 d-flex">
                                <div className="pr-2 flex-3">
                                    <label htmlFor={`${row}.description`}> Description </label>
                                    <Field
                                        name={`${row}.description`}
                                        component={renderField}
                                        disabled={seeView}
                                        customClass="form-control w-100"
                                        placeholder="Description"
                                    />
                                </div>
                                <div className="pr-1 flex-1">
                                    <label htmlFor={`${row}.fee`}> Fee </label>
                                    <Field
                                        name={`${row}.fee`}
                                        disabled={seeView}
                                        component={renderCurrency}
                                        placeholder="Fee"
                                        customClass="form-control w-100"
                                    />
                                </div>
                                <div className="mx-0 text-center flex-1">
                                    <label htmlFor={`${row}.extra_field`}> Copy Field </label>
                                    <Field
                                        name={`${row}.extra_field`}
                                        disabled={seeView}
                                        component={renderSwitch}
                                        customClass="text-center pt-2 mx-auto row justify-content-center"
                                    />
                                </div>
                                <div className="pr-1 text-center flex-1">
                                    <label htmlFor={`${row}.active_shackle_code`}>Shackle code</label>
                                    <Field
                                        name={`${row}.active_shackle_code`}
                                        disabled={seeView}
                                        component={renderSwitch}
                                        customClass="text-center pt-2 mx-auto row justify-content-center"
                                    />
                                </div>
                                <div className="form-group has-feedback text-left flex-1">
                                    <label className="pl-1" htmlFor={`${row}.show_when`}>Show when</label>
                                    <Field
                                        name={`${row}.show_if`}
                                        placeholder="show when..."
                                        options={LIST_SHOW_WHEN}
                                        component={SelectField}
                                        disabled={seeView}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="row w-100 d-flex mt-2 mb-3">
                                <div className="col-lg-3 form-group">
                                    <label className="pl-1" htmlFor={`${row}.has_extra_services`}>Has extra services</label>
                                    <Field
                                        name={`${row}.has_extra_services`}
                                        disabled={seeView}
                                        component={renderSwitch}
                                        customClass=" pt-2 mx-auto row justify-content-center"
                                    />
                                </div>
                                <div className="col-lg-4 form-group">
                                    <label className="pl-1" htmlFor={`${row}.title`}>Title</label>
                                    <Field
                                        name={`${row}.title`}
                                        disabled={seeView}
                                        component={renderField}
                                        customClass="form-control w-100"
                                    />
                                </div>
                                <div className="col-lg-5 form-group">
                                    <label className="pl-1" htmlFor={`${row}.text`}>Text</label>
                                    <Field
                                        name={`${row}.text`}
                                        rows={3}
                                        placeholder="Text"
                                        component={renderTextArea}
                                        customClass="text-area-h-auto border"
                                        disabled={seeView}
                                    />
                                </div>
                                <div className="col-lg-5 form-group">
                                    <label className="pl-1" htmlFor={`${row}.label_fee`}>Label Fee</label>
                                    <Field
                                        name={`${row}.label_fee`}
                                        disabled={seeView}
                                        component={renderField}
                                        customClass="form-control w-100"
                                    />
                                </div>
                                <div className="col-lg-5 form-group">
                                    <label className="pl-1" htmlFor={`${row}.confirmation_label`}>Label Confirmation</label>
                                    <Field
                                        name={`${row}.confirmation_label`}
                                        disabled={seeView}
                                        component={renderField}
                                        customClass="form-control w-100"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        fields.get(indexS).extra_field === true &&
                        <div className="row w-100 mx-1 mt-n2 pb-1">
                            <div className="justify-content-center" style={{width: '4%'}}>
                            </div>
                            <div className="col-4 text-left">
                                <label className="text-left mb-0"> Name extra field</label>
                                <Field
                                    name={`${row}.name_field`}
                                    component={renderField}
                                    disabled={seeView}
                                    customClass="form-control w-100"
                                    placeholder="insert name"
                                />
                            </div>
                            {
                                fields.get(indexS).is_copy === true &&
                                <div className="col-2 text-left">
                                    <label className="text-left mb-0"> Fee per copy</label>
                                    <Field
                                        name={`${row}.copy_fee`}
                                        disabled={seeView}
                                        component={renderCurrency}
                                        placeholder="fee per copy"
                                        customClass="form-control w-100"
                                    />
                                </div>
                            }
                            <div className="col-2 text-center">
                                <label className="text-left "> Is copy </label>
                                <Field
                                    name={`${row}.is_copy`}
                                    disabled={seeView}
                                    component={renderSwitch}
                                    customClass="text-center pt-2 mx-auto row justify-content-center"
                                />
                            </div>
                        </div>

                    }

                    {/* EXTRA SERVICES */}
                    {
                        fields.get(indexS).has_extra_services === true && (
                            <div className="row w-100 my-3 mx-auto" style={{paddingLeft: '5%'}}>
                                <div className="form-group has-feedback col-12 pt-2 px-0">
                                    <label className='ml-1' htmlFor={`${row}.extra_services`} style={{fontSize:22}}>Extra Service</label>
                                    <FieldArray
                                        id='extra_services'
                                        name={`${row}.extra_services`}
                                        nameArray={`${row}.extra_services`}
                                        component={renderExtraServices}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        ))
        }
        <div className="d-flex justify-content-center mt-2">
            {!seeView && (
                <button
                    type="button"
                    className="btn button-02 py-0"
                    onClick={() => fields.push({
                        extra_field: false,
                        services_shackle_code: false,
                        show_if: AD_ALWAYS
                    })}
                >
                    Add Service
                </button>
            )}
        </div>
    </React.Fragment>
);

const optionList = ({seeView, fields, setIcon, setMapIcon, icons, jobType, meta: {error}}) => (
    <React.Fragment>
        {fields.map((item, index) => (
            <div className='border-solid py-4 container mb-4' key={`option${index}`}>
                <div className="w-100 border-bottom m-2 d-flex justify-content-between">
                    <h4 className="font-weight-bold">Option {index + 1}</h4>
                    {!seeView && (
                        <div>
                            <img
                                src={require("../../../../assets/img/delete.svg")}
                                alt="del"
                                onClick={() => (fields.remove(index))}
                                className="ml-auto pointer"
                            />
                        </div>
                    )}
                </div>
                {/* region OPTION INFO */}
                <div className="form-group has-feedback col-md-12 mt-auto border py-2">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.name`}>Name</label>
                            <Field
                                name={`${item}.name`}
                                placeholder="Name"
                                disabled={seeView}
                                component={renderField}
                                type="text"
                                customClass="form-control w-100"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.primary_color`}>Name Color</label>
                            <Field
                                name={`${item}.primary_color`}
                                disabled={seeView}
                                placeholder="Name Color"
                                component={ColorPicker}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-12 mt-auto">
                            <label htmlFor={`${item}.description`}>Description</label>
                            <Field
                                name={`${item}.description`}
                                id="description"
                                rows={2}
                                placeholder="Description"
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                                disabled={seeView}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.icon`}>Icon</label>
                            {/* <Field
                                name={`${item}.icon`}
                                photo={icons[index] ? icons[index].icon : undefined}
                                disabled={seeView}
                                setFile={setIcon(index)}
                                component={renderFilePicker}
                                // className="form-control"
                            /> */}
                            <Field
                                name={`${item}.icon`}
                                id={`${item}.icon`}
                                photo={icons[index] ? icons[index].icon : undefined}
                                setFile={setIcon(index)}
                                spectValue={1}
                                disabled={seeView}
                                component={renderFileImageCrop}
                                customImgStyle={{filter: icons[index] && icons[index].icon_color ? `opacity(0.5) drop-shadow(0 0 0 ${icons[index].icon_color})` : 'none'}}
                                // className="form-control"
                            />
                            <label htmlFor={`${item}.icon_color`}>Icon Color</label>
                            <Field
                                name={`${item}.icon_color`}
                                disabled={seeView}
                                placeholder="Icon Color"
                                component={ColorPicker}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.map_icon`}>Map Icon</label>
                            {/* <Field
                                name={`${item}.map_icon`}
                                photo={icons[index] ? icons[index].map_icon : undefined}
                                disabled={seeView}
                                setFile={setMapIcon(index)}
                                component={renderFilePicker}
                                // className="form-control"
                            /> */}
                            <Field
                                name={`${item}.map_icon`}
                                id={`${item}.map_icon`}
                                photo={icons[index] ? icons[index].map_icon : undefined}
                                setFile={setMapIcon(index)}
                                spectValue={1}
                                disabled={seeView}
                                component={renderFileImageCrop}
                                customImgStyle={{filter: icons[index] && icons[index].map_icon_color ? `opacity(0.5) drop-shadow(0 0 0 ${icons[index].map_icon_color})` : 'none'}}
                                // className="form-control"
                            />
                            <label htmlFor={`${item}.map_icon_color`}>Map Icon Color</label>
                            <Field
                                name={`${item}.map_icon_color`}
                                disabled={seeView}
                                placeholder="Map Icon Color"
                                component={ColorPicker}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-4 mt-auto">
                            <label htmlFor={`${item}.base_price`}>Base Fee</label>
                            <Field
                                name={`${item}.base_price`}
                                disabled={seeView}
                                allowNegative={false}
                                placeholder="Base Fee"
                                component={renderCurrency}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.app_fee`}>App Fee</label>
                            <Field
                                name={`${item}.app_fee`}
                                disabled={seeView}
                                placeholder="App Fee"
                                allowNegative={false}
                                suffix="%"
                                decimalScale={2}
                                component={renderNumber}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.broker_fee`}>Broker Fee (Over profit)</label>
                            <Field
                                name={`${item}.broker_fee`}
                                disabled={seeView}
                                placeholder="Broker Fee"
                                allowNegative={false}
                                suffix="%"
                                decimalScale={2}
                                component={renderNumber}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.allow_hired_agent`}>
                                Allow Hired agent to schedule appointment
                            </label>
                            <Field
                                disabled={seeView}
                                name={`${item}.allow_hired_agent`}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.client_required`}>Customer Information Required</label>
                            <Field
                                name={`${item}.client_required`}
                                disabled={seeView}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.customer_type`}>Customer type</label>
                            <Field
                                name={`${item}.customer_type`}
                                placeholder="Customer Type"
                                options={CUSTOMER_TYPE}
                                component={SelectField}
                                disabled={seeView}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.allow_additional_fee`}>Allow Additional Fee</label>
                            <Field
                                disabled={seeView}
                                name={`${item}.allow_additional_fee`}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.type_application`}>Type of application</label>
                            <Field
                                name={`${item}.type_application`}
                                id="type_application"
                                placeholder="Type of application"
                                options={TYPES_APPLICATION}
                                component={SelectField}
                                disabled={seeView}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        {(icons[index] && !icons[index].allow_hired_agent && (jobType === SINGLE)) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="is_duration">Is based on duration?</label>
                                <Field
                                    disabled={seeView}
                                    name={`${item}.is_duration`}
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                        )}
                        {(icons[index] ? icons[index].is_duration : false) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="duration_limit">Maximum duration</label>
                                <Field
                                    name={`${item}.duration_limit`}
                                    placeholder="Duration Limit"
                                    component={renderNumber}
                                    decimalScale={0}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}
                        {(icons[index] ? icons[index].is_duration : false) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="additional_hour_fee">Every additional 3 minutes fee</label>
                                <Field
                                    name={`${item}.additional_hour_fee`}
                                    placeholder="Every additional 3 minutes fee"
                                    component={renderCurrency}
                                    decimalScale={0}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}
                    </div>
                </div>
                {/* endregion OPTION INFO */}
                {((icons[index] ? icons[index].is_duration : false) && (jobType === SINGLE)) && (
                    <div className="col-12 w-100 border form-group">
                        <div className="row w-100 border mb-2">
                            <div className="form-group has-feedback col-12 mt-auto">
                                <label htmlFor="enable_feedback">Hourly price table</label>
                                <FieldArray
                                    id="prices"
                                    seeView={seeView}
                                    name={`${item}.prices`}
                                    nameArray={`${item}.prices`}
                                    component={renderVendorHours}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {/* region AGENT FEEDBACK FORM */}
                <div className="col-12 w-100 border form-group">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.enable_feedback`}>Enable Agent Feedback Form</label>
                            <Field
                                name={`${item}.enable_feedback`}
                                disabled={seeView}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        {(icons[index] && icons[index].enable_feedback) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor={`${item}.agent_feedback_form`}>Agent Feedback Form</label>
                                <Field
                                    name={`${item}.agent_feedback_form`}
                                    placeholder="Agent Form Type"
                                    loadOptions={loadAgentForms}
                                    component={AsyncSelectField}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}
                    </div>
                </div>
                {/* endregion AGENT FEEDBACK FORM */}
                {/* region CUSTOMER FEEDBACK FORM */}
                <div className="col-12 w-100 border form-group">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.enable_customer_feedback`}>Enable Customer Feedback Form</label>
                            <Field
                                name={`${item}.enable_customer_feedback`}
                                disabled={seeView}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        {(icons[index] && icons[index].enable_customer_feedback) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor={`${item}.customer_feedback_form`}>Customer Feedback Form</label>
                                <Field
                                    name={`${item}.customer_feedback_form`}
                                    placeholder="Customer Form Type"
                                    loadOptions={loadCustomerForms}
                                    component={AsyncSelectField}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}
                    </div>
                </div>
                {/* endregion CUSTOMER FEEDBACK FORM */}

                {(fields.length > 1) && (<hr/>)}
            </div>
        ))}
        {!seeView && (
            <div className="col-12">
                <button
                    className="btn button-02"
                    type="button"
                    onClick={() => fields.push({
                        allow_hired_agent: false,
                    })}
                >
                    Add Option
                </button>
            </div>
        )}
        {(error) && (
            <div className="invalid-array-feedback col-12">
                {error}
            </div>
        )}
    </React.Fragment>
);

// eslint-disable-next-line max-len
const optionListOtherService = ({seeView, fields, setIcon, setMapIcon, icons, jobType, allowedHiredAgent, meta: {error}}) => (
    <React.Fragment>
        {fields.map((item, index) => (
            <div className='border-solid py-4 container mb-4' key={`option2${index}`}>
                <div className="w-100 border-bottom m-2 d-flex justify-content-between">
                    <h4 className="font-weight-bold">Option {index + 1} - Other services</h4>
                    {!seeView && (
                        <div>
                            <img
                                src={require("../../../../assets/img/delete.svg")}
                                alt="del"
                                onClick={() => (fields.remove(index))}
                                className="ml-auto pointer"
                            />
                        </div>
                    )}
                </div>
                {/* region OPTION INFO */}
                <div className="form-group has-feedback col-md-12 mt-auto border py-2">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.name`}>Name</label>
                            <Field
                                name={`${item}.name`}
                                placeholder="Name services"
                                disabled={seeView}
                                component={renderField}
                                type="text"
                                customClass="form-control w-100"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.address_type`}>Address Type </label>
                            <Field
                                name={`${item}.address_type`}
                                placeholder="Address type"
                                options={JOB_TYPES}
                                component={SelectField}
                                disabled={seeView}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-12 mt-auto">
                            <label htmlFor={`${item}.description`}>Description</label>
                            <Field
                                name={`${item}.description`}
                                id="description"
                                rows={2}
                                placeholder="Description"
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                                disabled={seeView}
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6">
                            <label htmlFor={`${item}.services_type`}>Services type</label>
                            <Field
                                name={`${item}.service_type`}
                                id={`${item}.service_type`}
                                placeholder="service type"
                                options={OTHER_SERVICES_LIST}
                                component={SelectField}
                                disabled={seeView}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6">
                            <label htmlFor={`${item}.primary_color`} className={'mt-3'}>Name Color</label>
                            <Field
                                name={`${item}.primary_color`}
                                disabled={seeView}
                                placeholder="Primary Color"
                                component={ColorPicker}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.icon`}>Icon</label>
                            {/* <Field
                                name={`${item}.icon`}
                                photo={icons[index] ? icons[index].icon : undefined}
                                disabled={seeView}
                                setFile={setIcon(index)}
                                component={renderFilePicker}
                            /> */}
                            <Field
                                name={`${item}.icon`}
                                id={`${item}.icon`}
                                photo={icons[index] ? icons[index].icon : undefined}
                                setFile={setIcon(index)}
                                spectValue={1}
                                disabled={seeView}
                                component={renderFileImageCrop}
                                customImgStyle={{filter: icons[index] && icons[index].icon_color ? `opacity(0.5) drop-shadow(0 0 0 ${icons[index].icon_color})` : 'none'}}
                                // className="form-control"
                            />
                            <label htmlFor={`${item}.icon_color`} className={'mt-0'}>Icon Color</label>
                            <Field
                                name={`${item}.icon_color`}
                                disabled={seeView}
                                placeholder="Icon Color"
                                component={ColorPicker}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.map_icon`}>Map Icon</label>
                            {/* <Field
                                name={`${item}.map_icon`}
                                photo={icons[index] ? icons[index].map_icon : undefined}
                                disabled={seeView}
                                setFile={setMapIcon(index)}
                                component={renderFilePicker}
                            /> */}
                            <Field
                                name={`${item}.map_icon`}
                                id={`${item}.map_icon`}
                                photo={icons[index] ? icons[index].map_icon : undefined}
                                setFile={setMapIcon(index)}
                                spectValue={1}
                                disabled={seeView}
                                component={renderFileImageCrop}
                                customImgStyle={{filter: icons[index] && icons[index].map_icon_color ? `opacity(0.5) drop-shadow(0 0 0 ${icons[index].map_icon_color})` : 'none'}}
                                // className="form-control"
                            />
                            <label htmlFor={`${item}.map_icon_color`} className={'mt-0'}>Map Icon Color</label>
                            <Field
                                name={`${item}.map_icon_color`}
                                disabled={seeView}
                                placeholder="Icon Color"
                                component={ColorPicker}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6">
                            <label htmlFor={`${item}.base_price`} className={'mt-3'}>Base Fee</label>
                            <Field
                                name={`${item}.base_price`}
                                disabled={seeView}
                                allowNegative={false}
                                placeholder="Base Fee"
                                component={renderCurrency}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.app_fee`}>App Fee</label>
                            <Field
                                name={`${item}.app_fee`}
                                disabled={seeView}
                                placeholder="App Fee"
                                allowNegative={false}
                                suffix="%"
                                decimalScale={2}
                                component={renderNumber}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.broker_fee`}>Broker Fee (Over profit)</label>
                            <Field
                                name={`${item}.broker_fee`}
                                disabled={seeView}
                                placeholder="Broker Fee"
                                allowNegative={false}
                                suffix="%"
                                decimalScale={2}
                                component={renderNumber}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        {(icons[index] && icons[index].service_type === INSTALL_REMOVE) && (
                            <React.Fragment>
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor={`${item}.draw_on_map`}>Draw on map</label>
                                    <Field
                                        name={`${item}.draw_on_map`}
                                        disabled={seeView}
                                        id="draw_on_map"
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.enable_number_sign`}>
                                        Enable field # of Marks (Signs\Directionals)
                                    </label>
                                    <Field
                                        name={`${item}.enable_number_sign`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.enable_remove_install`}>
                                        Enable options Remove/install
                                    </label>
                                    <Field
                                        name={`${item}.enable_remove_install`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.switch_return_key`}>
                                        Enable return a key to the Hired Agent
                                    </label>
                                    <Field
                                        name={`${item}.switch_return_key`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.switch_drop_off`}>Enable switch, drop off at</label>
                                    <Field
                                        name={`${item}.switch_drop_off`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        {(icons[index] && ((icons[index].service_type === MOVE) || (icons[index].service_type === INSTALL_REMOVE))) && (
                            <React.Fragment>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.enable_number_copies`}>Enable # of copies field </label>
                                    <Field
                                        name={`${item}.enable_number_copies`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.show_fee_per_copy`}>Enable fee per copy</label>
                                    <Field
                                        disabled={seeView}
                                        name={`${item}.show_fee_per_copy`}
                                        component={renderSwitch}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                {
                                    icons[index].show_fee_per_copy === true &&
                                    <div className="form-group has-feedback col-md-6 mt-auto ">
                                        <label htmlFor={`${item}.fee_per_copy`}> Fee per copy</label>
                                        <Field
                                            name={`${item}.fee_per_copy`}
                                            disabled={seeView}
                                            component={renderCurrency}
                                            placeholder="fee per copy"
                                            customClass="form-control w-100"
                                        />
                                    </div>
                                }
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.enable_move_in_move_out`}>
                                        Enable move in / move out
                                    </label>
                                    <Field
                                        name={`${item}.enable_move_in_move_out`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.enable_shackle_code`}>Enable field shackle code</label>
                                    <Field
                                        name={`${item}.enable_shackle_code`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto ">
                                    <label htmlFor={`${item}.upload_form`}>Enable upload form field </label>
                                    <Field
                                        name={`${item}.upload_form`}
                                        disabled={seeView}
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        {(icons[index] && icons[index].service_type === TAKE_PHOTO_VIDEO) && (
                            <React.Fragment>
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor={`${item}.min_photo`}>Minimum photos</label>
                                    <Field
                                        name={`${item}.min_photo`}
                                        placeholder="0"
                                        component={renderNumber}
                                        allowNegative={false}
                                        disabled={seeView}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor={`${item}.min_video`}>Minimum videos</label>
                                    <Field
                                        name={`${item}.min_video`}
                                        placeholder="0"
                                        component={renderNumber}
                                        allowNegative={false}
                                        disabled={seeView}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor={`${item}.price_video_photo`}>
                                        Price of taking both (photos and videos)
                                    </label>
                                    <Field
                                        name={`${item}.price_video_photo`}
                                        placeholder="0"
                                        component={renderCurrency}
                                        allowNegative={false}
                                        disabled={seeView}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.allow_hired_agent`}>
                                Allow Hired agent to schedule appointment
                            </label>
                            <Field
                                disabled={seeView}
                                name={`${item}.allow_hired_agent`}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.client_required`}>Customer Information Required</label>
                            <Field
                                name={`${item}.client_required`}
                                disabled={seeView}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.customer_type`}>Customer type</label>
                            <Field
                                name={`${item}.customer_type`}
                                placeholder="Customer Type"
                                options={CUSTOMER_TYPE}
                                component={SelectField}
                                disabled={seeView}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.allow_additional_fee`}>Allow Additional Fee</label>
                            <Field
                                disabled={seeView}
                                name={`${item}.allow_additional_fee`}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.type_application`}>Type of application</label>
                            <Field
                                name={`${item}.type_application`}
                                id="type_application"
                                placeholder="Type of application"
                                options={TYPES_APPLICATION}
                                component={SelectField}
                                disabled={seeView}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        {(!allowedHiredAgent && (jobType === SINGLE)) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="is_duration">Is based on duration?</label>
                                <Field
                                    disabled={seeView}
                                    name={`${item}.is_duration`}
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                        )}
                        {(icons[index] ? icons[index].is_duration : false) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="duration_limit">Maximum duration</label>
                                <Field
                                    name={`${item}.duration_limit`}
                                    placeholder="Duration Limit"
                                    component={renderNumber}
                                    decimalScale={0}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}

                    </div>
                </div>
                {/* endregion OPTION INFO */}
                {((icons[index] ? icons[index].is_duration : false) && (jobType === SINGLE)) && (
                    <div className="col-12 w-100 border form-group">
                        <div className="row w-100 border mb-2">
                            <div className="form-group has-feedback col-12 mt-auto">
                                <label htmlFor="enable_feedback">Hourly price table</label>
                                <FieldArray
                                    id="prices2"
                                    seeView={seeView}
                                    name={`${item}.prices`}
                                    nameArray={`${item}.prices`}
                                    component={renderPrices}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {/* region AGENT FEEDBACK FORM */}
                <div className="col-12 w-100 border form-group">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.enable_feedback`}>Enable Agent Feedback Form</label>
                            <Field
                                name={`${item}.enable_feedback`}
                                disabled={seeView}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        {(icons[index] && icons[index].enable_feedback) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor={`${item}.agent_feedback_form`}>Agent Feedback Form</label>
                                <Field
                                    name={`${item}.agent_feedback_form`}
                                    placeholder="Agent Form Type"
                                    loadOptions={loadAgentForms}
                                    component={AsyncSelectField}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}
                    </div>
                </div>
                {/* endregion AGENT FEEDBACK FORM */}
                {/* region CUSTOMER FEEDBACK FORM */}
                <div className="col-12 w-100 border form-group">
                    <div className="row">
                        <div className="form-group has-feedback col-md-6 mt-auto">
                            <label htmlFor={`${item}.enable_customer_feedback`}>Enable Customer Feedback Form</label>
                            <Field
                                name={`${item}.enable_customer_feedback`}
                                disabled={seeView}
                                component={renderFieldCheck}
                                type="checkbox"
                                className="form-control"
                            />
                        </div>
                        {(icons[index] && icons[index].enable_customer_feedback) && (
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor={`${item}.customer_feedback_form`}>Customer Feedback Form</label>
                                <Field
                                    name={`${item}.customer_feedback_form`}
                                    placeholder="Customer Form Type"
                                    loadOptions={loadCustomerForms}
                                    component={AsyncSelectField}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        )}
                    </div>

                </div>
                <div className="row w-100 border mb-2 mx-auto">
                    <div className="form-group has-feedback col-12 pt-2 text-center">
                        <label htmlFor={`${item}.additional_services`} style={{fontSize:22}}>Additional Service</label>
                        <FieldArray
                            id='additional_services'
                            name={`${item}.additional_services`}
                            nameArray={`${item}.additional_services`}
                            component={renderAdditionalServices}
                        />
                    </div>
                </div>
                {/* endregion CUSTOMER FEEDBACK FORM */}

                {(fields.length > 1) && (<hr/>)}
            </div>
        ))}
        {!seeView && (
            <div className="col-12">
                <button className="btn button-02" type="button" onClick={() => fields.push()}>Add Option</button>
                <p className="invalid-array-feedback"> {fields.length < 1 && 'At least one characteristic is required'}</p>
            </div>
        )}
        {(error) && (
            <div className="invalid-array-feedback col-12">
                {error}
            </div>
        )}
    </React.Fragment>
);

export const ProfileForm = (props) => {
    const {
        handleSubmit,
        allow_hired_agent: allowedHiredAgent,
        is_duration: isDuration,
        job_type_option: icons,
        multiple_description: multipleDescription,
        enable_customer_feedback: enableCustomerFeedback,
        enable_feedback: enableFeedback,
        enable_client_feedback: enableClientFeedback,
        job_type: jobType,
        icon_colorValue,
        map_icon_colorValue,
        editView,
        seeView,
        setIcon,
        icon,
        map_icon,
        is_other_services: isOtherServices,
        setMainIcon,
        setMainMapIcon,
        setMapIcon,
    } = props;
    return (
        <div
            className="general-container card border-dark border-card-page px-4 py-4"
            style={{
                minWidth: '99%',
                // minHeight: '15%',
            }}
        >
            <form action="" onSubmit={handleSubmit} className="row mx-auto justify-content-center mt-5"
                  style={{width: '80%'}}>
                {/* region MAIN INFO */}
                <div className="row w-100">
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="name">Name</label>
                        <Field
                            name="name"
                            id="name"
                            placeholder="Name"
                            component={renderField}
                            disabled={editView || seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>

                    <div className="form-group has-feedback col-md-6 mt-auto">
                        {!isOtherServices &&
                        <React.Fragment>
                            <label htmlFor="job_type">Type</label>
                            <Field
                                name="job_type"
                                id="job_type"
                                placeholder="Type"
                                options={JOB_TYPES}
                                component={SelectField}
                                disabled={editView || seeView}
                                type="text"
                                className="form-control"
                            />
                        </React.Fragment>
                        }
                    </div>
                    <div className="form-group has-feedback col-md-12 mt-auto">
                        {!multipleDescription && !isOtherServices && (
                            <React.Fragment>
                                <label htmlFor="description">Description</label>
                                <Field
                                    name="description"
                                    id="description"
                                    rows={2}
                                    placeholder="Description"
                                    component={renderTextArea}
                                    customClass="text-area-h-auto border"
                                    disabled={seeView}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="is_other_services">Other Services (Sub-menu)</label>
                        <Field
                            disabled={editView || seeView}
                            name="is_other_services"
                            id="other_services"
                            component={renderSwitch}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="multiple_description">Includes Characteristics (Sub-menu)</label>
                        <Field
                            disabled={editView || seeView}
                            name="multiple_description"
                            id="multiple_description"
                            component={renderSwitch}
                            type="checkbox"
                            className="form-control"
                        />
                    </div>
                </div>
                {/* endregion MAIN INFO */}
                {/*---------------------- NORMAL JOB  -----------------------*/}
                {!multipleDescription && !isOtherServices && (
                    <div className='border-solid col-12 justify-content-center align-content-center py-3 mb-3 pl-5'>
                        {/* region SECONDARY INFO */}
                        <div className="row w-100 border-danger mb-2">
                            <div className="form-group has-feedback col-md-4">
                                <label htmlFor="primary_color">Name Color</label>
                                <Field
                                    name="primary_color"
                                    id="primary_color"
                                    disabled={seeView}
                                    placeholder="Primary Color"
                                    component={ColorPicker}
                                    type="text"
                                    className="form-control"
                                />
                                <label htmlFor="base_price" className='mt-3'>Base Fee</label>
                                <Field
                                    name="base_price"
                                    id="base_price"
                                    disabled={seeView}
                                    placeholder="Base Fee"
                                    allowNegative={false}
                                    component={renderCurrency}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-4 mt-auto">
                                <label htmlFor="icon">Icon</label>
                                {/* <Field
                                    name="icon"
                                    id="icon"
                                    photo={icon}
                                    disabled={seeView}
                                    setFile={setMainIcon}
                                    component={renderFilePicker}
                                    // className="form-control"
                                /> */}
                                <Field
                                    name="icon"
                                    id="icon"
                                    photo={icon}
                                    setFile={setMainIcon}
                                    spectValue={1}
                                    disabled={seeView}
                                    component={renderFileImageCrop}
                                    customImgStyle={{filter: icon_colorValue ? `opacity(0.5) drop-shadow(0 0 0 ${icon_colorValue})` : 'none'}}
                                    // className="form-control"
                                />
                                <label htmlFor="icon_color">Icon Color</label>
                                <Field
                                    name="icon_color"
                                    id="icon_color"
                                    disabled={seeView}
                                    placeholder="Icon Color"
                                    component={ColorPicker}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-4 mt-auto">
                                <label htmlFor="map_icon">Map Icon</label>
                                {/* <Field
                                    name="map_icon"
                                    id="map_icon"
                                    photo={map_icon}
                                    disabled={seeView}
                                    setFile={setMainMapIcon}
                                    component={renderFilePicker}
                                    // className="form-control"
                                /> */}
                                <Field
                                    name="map_icon"
                                    id="map_icon"
                                    photo={map_icon}
                                    setFile={setMainMapIcon}
                                    spectValue={1}
                                    disabled={seeView}
                                    component={renderFileImageCrop}
                                    customImgStyle={{filter: map_icon_colorValue ? `opacity(0.5) drop-shadow(0 0 0 ${map_icon_colorValue})` : 'none'}}
                                    // className="form-control"
                                />
                                <label htmlFor="map_icon_color">Map Icon Color</label>
                                <Field
                                    name="map_icon_color"
                                    id="map_icon_color"
                                    disabled={seeView}
                                    placeholder="Map Icon Color"
                                    component={ColorPicker}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="app_fee">App Fee</label>
                                <Field
                                    name="app_fee"
                                    id="app_fee"
                                    disabled={seeView}
                                    placeholder="App Fee"
                                    allowNegative={false}
                                    suffix="%"
                                    decimalScale={2}
                                    component={renderNumber}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="broker_fee">Broker Fee (Over profit)</label>
                                <Field
                                    name="broker_fee"
                                    id="broker_fee"
                                    disabled={seeView}
                                    placeholder="Broker Fee"
                                    allowNegative={false}
                                    suffix="%"
                                    decimalScale={2}
                                    component={renderNumber}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="allow_hired_agent">Allow Hired agent to schedule appointment</label>
                                <Field
                                    disabled={seeView}
                                    name="allow_hired_agent"
                                    id="allow_hired_agent"
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="client_required">Customer Information Required</label>
                                <Field
                                    name="client_required"
                                    id="client_required"
                                    disabled={seeView}
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="customer_type">Customer type</label>
                                <Field
                                    name="customer_type"
                                    id="customer_type"
                                    placeholder="Customer Type"
                                    options={CUSTOMER_TYPE}
                                    component={SelectField}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="allow_additional_fee">Allow Additional Fee</label>
                                <Field
                                    disabled={seeView}
                                    name="allow_additional_fee"
                                    id="allow_additional_fee"
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="type_application">Type of application</label>
                                <Field
                                    name="type_application"
                                    id="type_application"
                                    placeholder="Type of application"
                                    options={TYPES_APPLICATION}
                                    component={SelectField}
                                    disabled={seeView}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            {(!allowedHiredAgent && (jobType === SINGLE)) && (
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor="is_duration">Is based on duration?</label>
                                    <Field
                                        disabled={seeView}
                                        name="is_duration"
                                        id="is_duration"
                                        component={renderFieldCheck}
                                        type="checkbox"
                                        className="form-control"
                                    />
                                </div>
                            )}
                            {isDuration && (
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor="duration_limit">Maximum duration</label>
                                    <Field
                                        name="duration_limit"
                                        id="duration_limit"
                                        placeholder="Duration Limit"
                                        component={renderNumber}
                                        decimalScale={0}
                                        disabled={seeView}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            )}
                        </div>
                        {/* endregion SECONDARY INFO */}
                        {/* region Prices Table */}
                        {(isDuration && (jobType === SINGLE)) && (
                            <div className="row w-100 border mb-2">
                                <div className="form-group has-feedback col-12 mt-auto">
                                    <label htmlFor="enable_feedback">Hourly price table</label>
                                    <FieldArray
                                        id="prices"
                                        seeView={seeView}
                                        name="prices"
                                        nameArray="prices"
                                        component={renderPrices}
                                    />
                                </div>
                            </div>
                        )}
                        {/* region OPTIONS TABLE */}
                        {(isDuration && (jobType === SINGLE)) && (
                            <div className="row w-100 border mb-2">
                                <div className="form-group has-feedback col-12 mt-auto">
                                    <label htmlFor="enable_feedback">Options</label>
                                    <FieldArray
                                        id="options"
                                        seeView={seeView}
                                        name="options"
                                        nameArray="options"
                                        component={renderOptions}
                                    />
                                </div>
                            </div>
                        )}
                        {/* endregion OPTIONS TABLE */}
                        <div className="row w-100 border mb-2">
                            <div className="form-group has-feedback col-12 mt-auto">
                                <label htmlFor="enable_feedback">Additional Service</label>
                                <FieldArray
                                    id="additional_services1"
                                    seeView={seeView}
                                    name="additional_services"
                                    nameArray="additional_services"
                                    component={renderAdditionalServices}
                                />
                            </div>
                        </div>
                        {/* region AGENT FEEDBACK FORM */}
                        <div className="row w-100 border mb-2">
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="enable_feedback">Enable Agent Feedback Form</label>
                                <Field
                                    name="enable_feedback"
                                    id="enable_feedback"
                                    disabled={seeView}
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                            {enableFeedback && (
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor="agent_feedback_form">Agent Feedback Form</label>
                                    <Field
                                        name="agent_feedback_form"
                                        id="agent_feedback_form"
                                        placeholder="Agent Form Type"
                                        loadOptions={loadAgentForms}
                                        component={AsyncSelectField}
                                        disabled={seeView}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            )}

                        </div>
                        {/* endregion AGENT FEEDBACK FORM */}
                        {/* region CUSTOMER FEEDBACK FORM */}
                        <div className="row w-100 border mb-2">
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="enable_customer_feedback">Enable Customer Feedback Form</label>
                                <Field
                                    name="enable_customer_feedback"
                                    id="enable_customer_feedback"
                                    disabled={seeView}
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                            {enableCustomerFeedback && (
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor="customer_feedback_form">Customer Feedback Form</label>
                                    <Field
                                        name="customer_feedback_form"
                                        id="customer_feedback_form"
                                        placeholder="Customer Form Type"
                                        loadOptions={loadCustomerForms}
                                        component={AsyncSelectField}
                                        disabled={seeView}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            )}
                        </div>
                        {/* endregion CUSTOMER FEEDBACK FORM */}
                        {/* region CLIENT FEEDBACK FORM */}
                        <div className="row w-100 border mb-2">
                            <div className="form-group has-feedback col-md-6 mt-auto">
                                <label htmlFor="enable_client_feedback">Enable Client Feedback Form</label>
                                <Field
                                    name="enable_client_feedback"
                                    id="enable_client_feedback"
                                    disabled={seeView}
                                    component={renderFieldCheck}
                                    type="checkbox"
                                    className="form-control"
                                />
                            </div>
                            {enableClientFeedback && (
                                <div className="form-group has-feedback col-md-6 mt-auto">
                                    <label htmlFor="client_feedback_form">Client Feedback Form</label>
                                    <Field
                                        name="client_feedback_form"
                                        id="client_feedback_form"
                                        placeholder="Client Form Type"
                                        loadOptions={loadClientForms}
                                        component={AsyncSelectField}
                                        disabled={seeView}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            )}
                        </div>
                        {/* endregion CLient FEEDBACK FORM */}
                    </div>
                )}
                {/*--------------------- JOB TYPE VENDOR --------------------*/}
                {multipleDescription && !isOtherServices && (
                    <div className="row w-100">
                        <div className="col-md-12 mt-auto">
                            <div className="row">
                                <FieldArray
                                    seeView={seeView}
                                    icons={icons}
                                    setIcon={setIcon}
                                    setMapIcon={setMapIcon}
                                    name="job_type_option"
                                    id="job_type_option1"
                                    jobType={jobType}
                                    allowedHiredAgent={allowedHiredAgent}
                                    component={optionList}
                                />

                            </div>
                        </div>
                    </div>
                )}
                {/*------------------ OTHER SERVICES DESCRIPTION ----------------------*/}
                {!multipleDescription && isOtherServices && (
                    <div className="row w-100">
                        <div className="col-md-12 mt-auto">
                            <div className="row">
                                <FieldArray
                                    seeView={seeView}
                                    icons={icons}
                                    setIcon={setIcon}
                                    setMapIcon={setMapIcon}
                                    name="job_type_option"
                                    id="job_type_option_other_services"
                                    jobType={jobType}
                                    allowedHiredAgent={allowedHiredAgent}
                                    component={optionListOtherService}
                                />

                            </div>
                        </div>
                    </div>
                )}

                <div className="d-flex mb-4">
                    <Link className="btn button-03 ml-auto mr-1 px-3" to="/job-types">Cancel</Link>
                    {!seeView && (
                        <button type="submit" className="btn btn-primary ml-1 px-3">Save</button>
                    )}
                </div>
            </form>
        </div>
    );
};
