import React, {useState, useEffect} from 'react';
import moment from "moment";
import HeaderCard from "../../../HeaderCard";
import SendgridCampaignFilterForm from "./SendgridCampaignFilterForm"
import Grid from "../../../Utils/GridTableWithScroll";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import {CAMPAIGNS_PAGE_SIZE} from "../../../../../utility/constants";


const CampaignGrid = (props) => {
    const {data, loader, page, searchAction, toList, remove} = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("SENDGRID-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">Campaigns</div>
                </div>
            </div>
            {/* <HeaderCard
                showFile={false}
                active_link={true}
                text_link={"Create"}
                id_header={'SendgridCustomCampaignList'}
                link={'sendgrid-custom-campaigns/create'}
                justify={'justify-content-between mt-2'}
                searchAction={(value) => searchAction(value)}
                searchStyle="col-lg-4 col-sm-12 img-fluid mb-sm-1 ml-3"
            /> */}
            <SendgridCampaignFilterForm searchAction={searchAction}/>
            <div id="SENDGRID-LIST" className="container-list card border-dark border-card-page px-4 py-2">
                <div className="card-body d-flex flex-column px-0 py-2">
                    <Grid
                        hover
                        height={gridHeight}
                        striped
                        page={page}
                        keyField={'id'}
                        loading={loader}
                        data={data ? data : []}
                        wrapperClasses="table-scroll-auto"
                        sizePerPage={CAMPAIGNS_PAGE_SIZE}
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        remove: remove,
                                        view: "sendgrid-custom-campaigns",
                                        edit: 'sendgrid-custom-campaigns',
                                    }),
                            },
                            {
                                dataField: "created",
                                formatter: dateCreated(),
                                text: "Date created",
                            },
                            {
                                dataField: "name",
                                sort: false,
                                text: "Name",
                            },
                        ]}
                    />
                </div>
            </div>

        </div>
    );
};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('LLL')}
            </p>
        )
    };
}


export default CampaignGrid;
