import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/jobType/jobType";
import JobTypeCreate from './JobTypeCreate';


const ms2p = (state) => {
    return {
        ...state.jobType,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(JobTypeCreate);
