import React, { Component } from "react";
import ReportsFiltersForm from "./ReporsFiltersForm";
import {
    ShowITChart,
    JobChart,
    JobStatus,
    JobType,
    ActiveAgents,
    AgentSignedUp,
    JobPerformed,
    JobPosted,
    AgentSignUpTrend,
} from "./ReportsComponents";

class Report extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {
            getBrokerOption,
            getFranchisesOption,
            getDataShowITCategories,
            getDataJobCategories,
            getDataJobStatus,
            getDataJobType,
            getDataActiveAgent,
            getDataAgentSignedUp,
            getDataJobPerformed,
            getDataJobPosted,
            getDataAgentSignUpTrend,
        } = this.props;

        //  Filters
        getBrokerOption(null, true);
        getFranchisesOption(null, true);
        //  Data
        getDataShowITCategories();
        getDataJobCategories();
        getDataJobStatus();
        getDataJobType();
        getDataActiveAgent();
        getDataAgentSignedUp();
        getDataJobPerformed();
        getDataJobPosted();
        getDataAgentSignUpTrend();
    }

    render() {
        //  State
        const {
            loader,
            loader2,
            franchises,
            brokers,
            brokerages,
            showITRanger,
            dataShowITCategory,
            dataCategory,
            dataJobStatus,
            dataJobType,
            jobType,
            dataActiveAgent,
            dataAgentSignedUp,
            dataJobPerformed,
            dataJobPosted,
            dataAgentSignUpTrend,
            agentSignRange
        } = this.props;
        //  Bind
        const { changeFilters, setRanger, setJobType, setAgentSignRanger } = this.props;

        return (
            <div className="w-100 px-1 pb-5">
                <div className="page-header pl-1 pt-3 no-gutters row justify-content-between w-100 mb-0">
                    <div className="">
                        <div className="header-page">Reports</div>
                    </div>
                </div>

                {/* FILTERS */}
                <ReportsFiltersForm
                    optionFranchises={franchises}
                    optionBrokers={brokers}
                    optionBrokerages={brokerages}
                    changeFilters={changeFilters}
                />

                <div className="row px-1">
                    <div className="row w-100 mx-0 px-0 mb-sm-2 mb-lg-0">
                        {/* ShowIT */}
                        <ShowITChart
                            loader={loader}
                            ranger={showITRanger}
                            setRanger={setRanger}
                            dataShowITCategory={dataShowITCategory}
                        />
                        {/*-------------------------------------------------*/}
                        {/*------------------- CARD RIGHT TOP  -------------*/}
                        {/*-------------------------------------------------*/}
                        {/* Job */}
                        <JobChart
                            loader={loader}
                            ranger={showITRanger}
                            setRanger={setRanger}
                            dataCategory={dataCategory}
                        />
                    </div>
                </div>
                <div className="row px-1 py-3">
                    <div className="row w-100 mx-0 px-0 mb-sm-2 mb-lg-0">
                        <JobStatus
                            loader={loader2}
                            data={dataJobStatus}
                            ranger={agentSignRange}
                            jobType={jobType}
                            setJobType={setJobType}
                        />
                        <JobType loader={loader2} data={dataJobType} />
                    </div>
                </div>
                <div className="row px-1 py-3">
                    <div className="row w-100 mx-0 px-0 mb-sm-2 mb-lg-0">
                        <AgentSignUpTrend
                            loader={loader2}
                            ranger={agentSignRange}
                            data={dataAgentSignUpTrend}
                            setRanger={setAgentSignRanger}
                        />
                        <AgentSignedUp
                            loader={loader2}
                            data={dataAgentSignedUp}
                        />
                    </div>
                </div>
                <div className="row px-1 py-3">
                    <div className="row w-100 mx-0 px-0 mb-sm-2 mb-lg-0">
                        <JobPosted
                            loader={loader2}
                            data={dataJobPosted}
                        />
                        <JobPerformed
                            loader={loader2}
                            data={dataJobPerformed}
                        />
                    </div>
                </div>
                <div className="row px-1 py-3">
                    <div className="row w-100 mx-0 px-0 mb-sm-2 mb-lg-0">
                        <ActiveAgents loader={loader2} data={dataActiveAgent} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Report;
