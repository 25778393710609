import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {SELECT_ALL_OPTION} from '../../../utility/constants'
import {createReducer} from "../baseReducer/baseReducer";
import Swal from "sweetalert2";

// ------------------------------------
// Constants
// ------------------------------------
const SET_FRANCHISES = "SET_FRANCHISES_SENDGRID_CAMPAIGNS";
const SET_BROKERS = "SET_BROKERS_SENDGRID_CAMPAIGNS";
const SET_BROKERAGES = "SET_BROKERAGES_SENDGRID_CAMPAIGNS";
const SET_TYPES_EMAILS = "SET_TYPES_EMAILS_SENDGRID_CAMPAIGNS";
const SET_TYPES_CAMPAIGN = "SET_TYPES_CAMPAIGN_SENDGRID_CAMPAIGNS";
const SET_AGENTS_DATA = "SET_AGENTS_DATA_SENDGRID_CAMPAIGNS";
const SET_UNREGISTERED_AGENTS_DATA = "SET_UNREGISTERED_AGENTS_DATA_SENDGRID_CAMPAIGNS";
const SET_UNREGISTERED_AGENTS_PAGE = "SET_UNREGISTERED_AGENTS_PAGE_SENDGRID_CAMPAIGNS";
const SET_LOADER_AGENTS_DATA = "SET_LOADER_AGENTS_DATA_SENDGRID_CAMPAIGNS";
const SET_AGENTS_PAGE = "SET_AGENTS_PAGE_SENDGRID_CAMPAIGNS";
const SHOW_SENDGRID_MODAL = "SHOW_SENDGRID_MODAL_SENDGRID_CAMPAIGNS";
const SENDGRID_DATA = "SENDGRID_DATA_SENDGRID_CAMPAIGNS";
const endpoint = "sendgrid_campaign";

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "sendgridCampaigns",
    endpoint,
    "sendgridCampaignFrom",
    "/sendgrid-campaigns",
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setFranchises = (franchises) => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});
const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});
const setTypesEmails = (typesEmails) => ({
    type: SET_TYPES_EMAILS,
    typesEmails,
});
const setTypesCampaigns = (typesCampaigns) => ({
    type: SET_TYPES_CAMPAIGN,
    typesCampaigns,
});
const setAgentsData = (agentsData) => ({
    type: SET_AGENTS_DATA,
    agentsData,
});
const setUnregisteredAgentsData = (unregisteredAgentsData) => ({
    type: SET_UNREGISTERED_AGENTS_DATA,
    unregisteredAgentsData,
});
const setLoaderAgentsData = (loaderAgentsData) => ({
    type: SET_LOADER_AGENTS_DATA,
    loaderAgentsData,
});
const setUnregisteredAgentsPage = (unregisteredAgentsPage) => ({
    type: SET_UNREGISTERED_AGENTS_PAGE,
    unregisteredAgentsPage,
});
const setAgentsPage = (agentsPage) => ({
    type: SET_AGENTS_PAGE,
    agentsPage,
});

const setShowSendgridModal = (sendgridModal) => ({
    type: SHOW_SENDGRID_MODAL,
    sendgridModal,
});

const setSendgridData = (sendgridData) => ({
    type: SENDGRID_DATA,
    sendgridData,
});

// ------------------------------------
// Actions
// ------------------------------------

export const getFranchisesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { sendgridCampaignFrom } = store.form;
    let franchiseValue = null;
    if (sendgridCampaignFrom && sendgridCampaignFrom.values) {
        const {franchise_id} = sendgridCampaignFrom.values;
        if (franchise_id !== "" && franchise_id !== null && franchise_id !== undefined && franchise_id !== SELECT_ALL_OPTION["value"]) {
            if (franchise_id.length == 0) {
                franchiseValue = SELECT_ALL_OPTION["value"]
            }else {
                franchiseValue = franchise_id
            }
        }
    }
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && franchiseValue === null) {
                    console.log("FRANCHISE ALL: ", selectAll);
                    console.log("FRANCHISE ALL: ", franchiseValue);
                    dispatch(
                        change(
                            "sendgridCampaignFrom",
                            "franchise_id",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (franchiseValue !== null) {
                    dispatch(
                        change(
                            "sendgridCampaignFrom",
                            "franchise_id",
                            franchiseValue
                        )
                    );
                }
            } else {
                dispatch(
                    change(
                        "sendgridCampaignFrom",
                        "franchise_id",
                        SELECT_ALL_OPTION["value"],
                    )
                );
            }
            dispatch(setFranchises(options));
        })
        .catch(() => {
            dispatch(setFranchises([]));
            dispatch(change("sendgridCampaignFrom", "franchise_id", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(getDataAgents())
                }, 500)
            }
        });
};
const getBrokerOption = (state=null, getAgents=null, selectAll=false) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    const store = getStore()
    let params = {state: ''};
    const { sendgridCampaignFrom } = store.form;
    let brokerValue = null;
    if (sendgridCampaignFrom && sendgridCampaignFrom.values) {
        const { broker_id } = sendgridCampaignFrom.values;
        if (broker_id !== "" && broker_id !== null && broker_id !== undefined && broker_id !== SELECT_ALL_OPTION["value"]) {
            if (broker_id.length == 0) {
                brokerValue = SELECT_ALL_OPTION["value"]
            }else {
                brokerValue = broker_id
            }
        }
    }

    api.get(`${endpoint}/option_broker`, params)
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                if (brokerValue !== null ) {
                    dispatch(
                        change("sendgridCampaignFrom", "broker_id", brokerValue)
                    );
                }
                if (selectAll && brokerValue == null) {
                    dispatch(change('sendgridCampaignFrom', 'broker_id', SELECT_ALL_OPTION["value"]))
                }
                dispatch(getBrokeragesOption(brokerValue, getAgents, selectAll))
            }else {
                option = [...response.results];
                const broker_id = option.length ? option[0].value : SELECT_ALL_OPTION["value"]
                dispatch(change('sendgridCampaignFrom', 'broker_id', [broker_id]))
                dispatch(getBrokeragesOption(broker_id, getAgents, selectAll))
            }
            dispatch(setBrokers(option));
        })
        .catch(() => {
            dispatch(setBrokers([]))
            dispatch(change('sendgridCampaignFrom', 'broker_id', SELECT_ALL_OPTION["value"]))
            dispatch(getBrokeragesOption(null, getAgents, selectAll))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
            if (getAgents) {
                setTimeout(() => {
                    dispatch(getDataAgents())
                }, 500)
            }
        });
};

const getBrokeragesOption = (broker_id=null, getAgents=null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const {sendgridCampaignFrom} = store.form;
    dispatch(basicReducer.actions.setLoader(true));
    let params = {broker_id: '', state: ''};
    // if (broker_id !== '' && broker_id !== null && broker_id !== undefined) {
    //     params.broker_id = broker_id;
    // }
    // if (sendgridCampaignFrom && sendgridCampaignFrom.values) {
    //     const {state} = sendgridCampaignFrom.values;
    //     if (state !== '' && state !== null && state !== undefined) {
    //         params.state = state
    //     }
    // }

    let brokerageValue = null;
    if (sendgridCampaignFrom && sendgridCampaignFrom.values) {
        const { state, broker_id, brokerage_id } = sendgridCampaignFrom.values;
        if (broker_id !== "" && broker_id !== null && broker_id !== undefined && broker_id !== SELECT_ALL_OPTION["value"]) {
            console.log("AAA: ", broker_id);
            if (broker_id.length == 0) {
                console.log("AAA 1: ", broker_id);
                params.broker_id = ""
            }else {
                console.log("AAA 2: ", broker_id);
                params.broker_id = broker_id;
            }
        }

        if (brokerage_id !== "" && brokerage_id !== null && brokerage_id !== undefined && brokerage_id !== SELECT_ALL_OPTION["value"]) {
            if (brokerage_id.length == 0) {
                brokerageValue = SELECT_ALL_OPTION["value"]
            }else {
                brokerageValue = brokerage_id
            }
        }
    }

    console.log("PARAMS: ", params);


    api.get(`${endpoint}/option_brokerage`, params)
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                if (selectAll && brokerageValue == null) {
                    dispatch(
                        change(
                            "sendgridCampaignFrom",
                            "brokerage_id",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
                if(brokerageValue !== null) {
                    dispatch(
                        change(
                            "sendgridCampaignFrom",
                            "brokerage_id",
                            brokerageValue
                        )
                    );
                }
            }else {
                option = [...response.results];
                dispatch(change('sendgridCampaignFrom', 'brokerage_id', option.length ? [option[0].value] : SELECT_ALL_OPTION["value"]))
            }
            dispatch(setBrokerages(option));
        })
        .catch(() => {
            dispatch(setBrokerages([]))
            dispatch(change('sendgridCampaignFrom', 'brokerage_id', SELECT_ALL_OPTION["value"]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
            if (getAgents) {
                setTimeout(() => {
                    dispatch(getDataAgents())
                }, 500)
            }
        });
};

const getDataAgents = (page = 1, pageType=null) => (dispatch, getStore) => {
    const store = getStore();
    const {sendgridCampaignFrom} = store.form;
    let params = {
        account_status: "",
        broker_id: "",
        state: "",
        brokerage_id: "",
        page
    };
    let agentStatus = SELECT_ALL_OPTION["value"]
    if (sendgridCampaignFrom && sendgridCampaignFrom.values) {
        const {state, agent_status, franchise_id, broker_id, brokerage_id} = sendgridCampaignFrom.values;
        if (agent_status !== "" && agent_status !== null && agent_status !== undefined && agent_status !== SELECT_ALL_OPTION["value"]) {
            if (agent_status.length == 0) {
                agentStatus = SELECT_ALL_OPTION["value"]
            }else {
                agentStatus = agent_status
            }
        }
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = null
            }else {
                params.state = state;
            }
        }
        if (franchise_id !== "" && franchise_id !== null && franchise_id !== undefined && franchise_id !== SELECT_ALL_OPTION["value"]) {
            if (state.franchise_id == 0) {
                params.franchise_id = null
            }else {
                params.franchise_id = franchise_id;
            }
        }
        if (broker_id !== "" && broker_id !== null && broker_id !== undefined && broker_id !== SELECT_ALL_OPTION["value"]) {
            if (state.broker_id == 0) {
                params.broker_id = null
            }else {
                params.broker_id = broker_id;
            }
        }
        if (brokerage_id !== "" && brokerage_id !== null && brokerage_id !== undefined && brokerage_id !== SELECT_ALL_OPTION["value"]) {
            if (state.brokerage_id == 0) {
                params.brokerage_id = null
            }else {
                params.brokerage_id = brokerage_id;
            }
        }
    }

    console.log("STATUS: ", agentStatus);

    if (agentStatus == null || agentStatus == SELECT_ALL_OPTION["value"]) {
        //  REGISTERED
        params.account_status = 5;
        dispatch(getFilterAgents(page, params));
        //  UNREGISTERED
        dispatch(getFilterUnregisteredAgents(page, params));
    }else {
        if (agentStatus.includes(1)) {
            //  UNREGISTERED
            dispatch(getFilterUnregisteredAgents(page, params));
            dispatch(setAgentsData({
                results: [],
                count: 0,
            }))
        }else {
            //  REGISTERED
            params.account_status = 5;
            dispatch(getFilterAgents(page, params));
            dispatch(setUnregisteredAgentsData({
                results: [],
                count: 0,
            }))
        }
    }

};

const getFilterAgents = (page, params) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/filter_agents`, params)
        .then(response => {
            if (response) {
                dispatch(setAgentsData(response))
                dispatch(setAgentsPage(page))
            }
        })
        .catch((error) => {
            dispatch(setAgentsData({
                results: [],
                count: 0,
            }))
            let message = error ? error.detail : 'It was not possible to obtain the list of agents'
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const getFilterUnregisteredAgents = (page, params) => (dispatch) => {
    dispatch(setLoaderAgentsData(true));
    const unregisteredParams = { ...params, account_status: 1 };
    api.get(`${endpoint}/filter_agents`, unregisteredParams)
        .then(response => {
            if (response) {
                dispatch(setUnregisteredAgentsData(response))
                dispatch(setUnregisteredAgentsPage(page))
            }
        })
        .catch((error) => {
            dispatch(setUnregisteredAgentsData({
                results: [],
                count: 0,
            }))
            let message = error ? error.detail : 'It was not possible to obtain the list of agents'
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoaderAgentsData(false));
        });
}

const getTypesEmails = () => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/option_email`, {})
        .then(response => {
            let option = [];
            option = response.results;
            dispatch(setTypesEmails(option));
        })
        .catch(() => {
            dispatch(setTypesEmails([]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};
const getTypesCampaigns = () => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/option_campaign`, {})
        .then(response => {
            let option = [];
            option = response.results;
            dispatch(setTypesCampaigns(option));
        })
        .catch(() => {
            dispatch(setTypesCampaigns([]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const changeFilters = (type, value=null) => (dispatch) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'status':
            setTimeout(() => {
                dispatch(getDataAgents())
            }, 500)
            break;
        case 'state':
            dispatch(getBrokerOption(value, true))
            break;
        case 'franchise':
            setTimeout(() => {
                dispatch(getDataAgents())
            }, 500)
            break;
        case 'broker':
            dispatch(getBrokeragesOption(value, true))
            break;
        case 'brokerage':
            setTimeout(() => {
                dispatch(getDataAgents())
            }, 500)
            break;
        default:
            break;
    }
}

const createCampaign = (data) => (dispatch) => {
    let _data = {...data}
    _data.agent_status = _data.agent_status != undefined && _data.agent_status != SELECT_ALL_OPTION["value"] && _data.agent_status != '' ? _data.agent_status : null
    _data.franchise_id = _data.franchise_id != undefined && _data.franchise_id != SELECT_ALL_OPTION["value"] && _data.franchise_id != '' ? _data.franchise_id : null
    _data.broker_id = _data.broker_id != undefined && _data.broker_id != SELECT_ALL_OPTION["value"] && _data.broker_id != '' ? _data.broker_id : null
    _data.brokerage_id = _data.brokerage_id != undefined && _data.brokerage_id != SELECT_ALL_OPTION["value"] && _data.brokerage_id != '' ? _data.brokerage_id : null
    _data.state = _data.state != undefined && _data.state != SELECT_ALL_OPTION["value"] && _data.state != '' ? _data.state : null

    dispatch(basicReducer.actions.setLoader(true));
    api.post(endpoint, _data)
        .then((response) => {
            if (response) {
                NotificationManager.success('Campaign successfully registered', 'Success', 3000);
                dispatch(push("/sendgrid-campaigns"));
            }
        })
        .catch((error) => {
            let message = error ? error.detail : 'It was not possible to create the campaign, try again later'
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const readCampaign = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
            let data = response;
            data.state = data.state != null && data.state != '' ? data.state : SELECT_ALL_OPTION["value"]
            data.agent_status = data.agent_status != null && data.agent_status != '' ? data.agent_status : SELECT_ALL_OPTION["value"]
            data.franchise_id = data.franchises_id != null && data.franchises_id != '' ? data.franchises_id : SELECT_ALL_OPTION["value"]
            dispatch(initializeForm('sendgridCampaignFrom', response));
            setTimeout(() => {
                dispatch(getBrokerOption(response.state, true, true));
                dispatch(getFranchisesOption(true))
            }, 600)
        })
        .catch((error) => {
            console.error(error);
            dispatch(basicReducer.actions.setItem({}));
            NotificationManager.error('Data could not be obtained', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const updateCampaign = (id, data) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    const source = getStore()['sendgridCampaigns'];
    let _data = {...data}
    _data.agent_status = _data.agent_status != undefined && _data.agent_status != SELECT_ALL_OPTION["value"] && _data.agent_status != '' ? _data.agent_status : null
    _data.franchise_id = _data.franchise_id != undefined && _data.franchise_id != SELECT_ALL_OPTION["value"] && _data.franchise_id != '' ? _data.franchise_id : null
    _data.broker_id = _data.broker_id != undefined && _data.broker_id != SELECT_ALL_OPTION["value"] && _data.broker_id != '' ? _data.broker_id : null
    _data.brokerage_id = _data.brokerage_id != undefined && _data.brokerage_id != SELECT_ALL_OPTION["value"] && _data.brokerage_id != '' ? _data.brokerage_id : null
    _data.state = _data.state != undefined && _data.state != SELECT_ALL_OPTION["value"] && _data.state != '' ? _data.state : null
    api.put(`${endpoint}/${id}`, _data).then(() => {
        NotificationManager.success('Campaign successfully updated', 'Success', 3000);
        dispatch(push('/sendgrid-campaigns'));
    }).catch((error) => {
        let message = error ? error.detail : 'It was not possible to update the campaign, try again later'
        NotificationManager.error(message, 'ERROR', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

const sendMessage = (id) => (dispatch) => {
    Swal.fire({
        title: 'Are you sure to send the email?',
        text: "This action can't be reverted!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, send it!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(basicReducer.actions.setLoader(true));
            api.post(`${endpoint}/${id}/send_email`)
                .then((response) => {
                    if (response.in_progress === true && response.same_day == false) {
                        dispatch(setSendgridData({
                            total_to_send:0,
                            total_sent: 0
                        }))
                        dispatch(waitSendMessage(id))
                    } else {
                        Swal.fire('Info', "Email/Text was send today, can't send it again", "info")
                    }
                })
                .catch((error) => {
                    console.error(error)
                    NotificationManager.error('It was not possible to send the emails, try again later', 'ERROR', 3000);
                })
                .finally(() => {
                    dispatch(basicReducer.actions.setLoader(false));
                });
        }
    })
}

const waitSendMessage = (id) => (dispatch) => {
    let intervalPromise;

    function listener() {
        api.get(`${endpoint}/${id}/status`, {id})
        .then((resp) => {
            if (resp.in_progress === true) {
                // PROCESANDO
                dispatch(setSendgridData(resp))
                dispatch(setShowSendgridModal(true));
            } else if (resp.in_progress === false) {
                // TERMINADO
                clearInterval(intervalPromise);
                dispatch(setSendgridData({
                    total_to_send: 0,
                    total_sent: 0
                }))
                dispatch(setShowSendgridModal(false));
                NotificationManager.success('Emails sent successfully', 'SUCCESS', 3000);
            }
        })
        .catch((err) => {
            let msg =
                "It was not possible to send the emails, try again later";
            dispatch(setShowSendgridModal(false));
            dispatch(setSendgridData({
                total_to_send: 0,
                total_sent: 0
            }))
            clearInterval(intervalPromise);
            NotificationManager.error(msg, 'ERROR', 3000);
        })
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
}

const markRedyToSend = (id, data) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`${endpoint}/${id}/ready_to_send`, data)
        .then((response) => {
            NotificationManager.success('Registered successfully', 'Success', 3000);
            dispatch(readCampaign(id))
        })
        .catch((error) => {
            console.error(error)
            NotificationManager.error('Failed to register, try again later', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["sendgridCampaigns"];
    const {SendgridEmailFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (SendgridEmailFilterForm && SendgridEmailFilterForm.values) {
        const {search} = SendgridEmailFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }

    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

const unStop = id => (dispatch) => {
    Swal.fire({
        title: 'Are you sure to unstop the campaign?',
        text: 'It does not stop sending emails, it only stops the loader to send scheduled campaigns',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, unstop it!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(basicReducer.actions.setLoader(true));
            api.put(`${endpoint}/${id}/unstop`)
                .then(() => {
                    NotificationManager.success('Campaign successfully unstopped', 'Success', 3000);
                    dispatch(toList());
                })
                .catch(() => {
                    NotificationManager.error('It was not possible to unstop the campaign, try again later',
                        'ERROR', 3000);
                })
                .finally(() => {
                    dispatch(basicReducer.actions.setLoader(false));
                });
        }
    });
};

export const actions = {
    ...basicReducer.actions,
    getFranchisesOption,
    getBrokerOption,
    getBrokeragesOption,
    getDataAgents,
    changeFilters,
    getTypesEmails,
    getTypesCampaigns,
    createCampaign,
    readCampaign,
    sendMessage,
    updateCampaign,
    markRedyToSend,
    toList,
    searchChange,
    unStop,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_FRANCHISES]: (state, {franchises}) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, {brokers}) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, {brokerages}) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_AGENTS_DATA]: (state, {agentsData}) => {
        return {
            ...state,
            agentsData,
        };
    },
    [SET_AGENTS_PAGE]: (state, {agentsPage}) => {
        return {
            ...state,
            agentsPage,
        };
    },
    [SET_TYPES_EMAILS]: (state, {typesEmails}) => {
        return {
            ...state,
            typesEmails,
        };
    },
    [SET_TYPES_CAMPAIGN]: (state, {typesCampaigns}) => {
        return {
            ...state,
            typesCampaigns,
        };
    },
    [SET_UNREGISTERED_AGENTS_DATA]: (state, {unregisteredAgentsData}) => {
        return {
            ...state,
            unregisteredAgentsData,
        };
    },
    [SET_LOADER_AGENTS_DATA]: (state, {loaderAgentsData}) => {
        return {
            ...state,
            loaderAgentsData,
        };
    },
    [SET_UNREGISTERED_AGENTS_PAGE]: (state, {unregisteredAgentsPage}) => {
        return {
            ...state,
            unregisteredAgentsPage,
        };
    },
    [SHOW_SENDGRID_MODAL]: (state, {sendgridModal}) => {
        return {
            ...state,
            sendgridModal,
        };
    },
    [SENDGRID_DATA]: (state, {sendgridData}) => {
        return {
            ...state,
            sendgridData,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    franchises: [],
    brokers: [],
    brokerages: [],
    typesEmails: [],
    typesCampaigns: [],
    agentsData: {
        results: [],
        count: 0,
    },
    agentsPage: 1,
    unregisteredAgentsData: {
        results: [],
        count: 0,
    },
    loaderAgentsData: false,
    unregisteredAgentsPage: 1,
    sendgridModal: false,
    sendgridData: {
        total_to_send: 0,
        total_sent: 0
    },
    ...basicReducer.initialState,
};

export default handleActions(reducers, initialState);
