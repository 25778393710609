import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeletedOfficeFilters from './DeletedOfficeFilters'
import DeletedOfficeGrid from './DeletedOfficeGrid'

class DeletedOfficeList extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount() {
        this.props.getFranchisesOption(null, true);
        this.props.getBrokerOption(null, true);
        setTimeout(() => {
            this.props.toList();
        }, 500);
    }

    componentDidMount() {

    }

    render() {
        //  State
        const {franchises, brokers, data, page, loader} = this.props;
        //  Bind
        const {changeFilters, toList} = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Deleted Office</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <DeletedOfficeFilters
                        optionFranchises={franchises}
                        optionBrokers={brokers}
                        changeFilters={changeFilters}
                    />
                </div>
                <DeletedOfficeGrid data={data} page={page} loader={loader} toList={toList} />
            </div>
        )
    }
}

DeletedOfficeList.propTypes = {

}

export default DeletedOfficeList