import React, { Component } from "react";
import TutorialVideosGrid from "./TutorialVideosGrid";

export class TutorialVideoList extends Component {
    componentWillMount() {
        this.props.toList();
    }
    render() {
        // State
        const { loader, page, data, searchChange, toList, remove } = this.props;

        return (
            <TutorialVideosGrid
                data={data}
                page={page}
                loader={loader}
                toList={toList}
                remove={remove}
                searchAction={searchChange}
            />
        );
    }
}

export default TutorialVideoList;
