import {api} from "api";
import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { createReducer } from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "vendor-client-feedback-form";
export const { reducers, initialState, actions } = createReducer(
    "vendorClientFeedback",
    endpoint,
    "vendorClientFeedbackForm",
    "/vendor-client-feedback",
);

actions.toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["vendorClientFeedback"];
    const {vendorClientFeedbackFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;
    if (vendorClientFeedbackFilterForm && vendorClientFeedbackFilterForm.values) {
        const {search} = vendorClientFeedbackFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }
    dispatch(actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(actions.setData(response));
        dispatch(actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};
actions.searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(actions.toList());
    }, 400);
};

actions.create = (data, goBack=null) => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.post(endpoint, data).then(() => {
        NotificationManager.success('Feedback Form created', 'Success', 3000);
        if (goBack)
            goBack();
    }).catch(() => {
        NotificationManager.error('Creation failed', 'ERROR');
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};

actions.edit = (id, data, goBack=null) => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Feedback Form updated', 'Success', 3000);
        console.log('goBack: ', goBack);
        if (goBack)
            goBack();
    }).catch(() => {
        NotificationManager.error('Edition failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};

export default handleActions(reducers, initialState);
