import React from 'react';


export function Search({searchChange}) {
    return (
        <div className="w-25 img-fluid">
            <input
                type="text"
                className="search-input"
                onChange={
                    e => searchChange(e.target.value)
                }
                placeholder="Search..."
            />
            <img
                className="custom-search-icon"
                src={require('../../../../assets/img/search.svg')}
                alt="searchIcon"
            />
        </div>
    );
}
