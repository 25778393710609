import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
    renderNumber,
    renderField,
    renderFieldCheck,
    renderTextArea,
    renderFilePicker,
    renderFileImageCrop,
} from "../../Utils/renderField/renderField";

const validate = (values, props) => {
    const errors = {};
    if (!values.broker_fee) {
        errors.broker_fee = 'This field is required';
    }
    if (!values.influencer_fee) {
        errors.influencer_fee = 'This field is required';
    }

    if (values.details && values.details.length) {
        const detalsError = [];
        values.details.forEach((detail, indexB) => {
            detalsError[indexB] = {};
            if (!detail.title) {
                detalsError[indexB] = { detail: 'This field is required' };
            }
            if (!detail.description) {
                detalsError[indexB] = { ...detalsError[indexB], description: 'This field is required' };
            }
            if (!detail.icon) {
                detalsError[indexB] = { ...detalsError[indexB], icon: 'This field is required' };
            }
            if (!detalsError[indexB]) {
                detalsError[indexB] = undefined;
            }
        });
        errors.details = detalsError;
    }

    return errors;
};

const renderDetails = (props) => {
    const {fields, setIcon, icons, meta: {error}} = props
    // console.log('ERROR Details: ', props);
    const invalid =  error;
    return (
        <div className="border border py-3">
            <div className="w-100 px-2">
                {fields.map((row, indexS) => (
                    <div
                        key={`prices-${indexS}-ss`}
                        className={`row w-100 mx-1 mb-1 py-2 ${fields.length == indexS + 1 ? 'mb-3' : 'mb-1'}`}
                        style={{backgroundColor: (indexS % 2 !== 0) ? '#F1F1F1' : 'white'}}>
                        <div className="col-10 col-md-10 justify-content-center">
                            <label htmlFor={`${row}.icon`}>Icon</label>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-4">
                                    <Field
                                        name={`${row}.icon`}
                                        photo={icons[indexS] ? icons[indexS].icon : undefined}
                                        setFile={setIcon(indexS)}
                                        spectValue={1}
                                        component={renderFileImageCrop}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-md-2 justify-content-center">
                            <img
                                src={require("../../../../../assets/img/delete.svg")}
                                alt="del"
                                style={{width: "1.5rem"}}
                                onClick={() => {
                                    fields.remove(indexS);
                                }}
                                className="pointer align-self-center ml-3 mt-1"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="title">Title</label>
                            <Field
                                name={`${row}.title`}
                                placeholder="Title"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="description">Description</label>
                            <Field
                                name={`${row}.description`}
                                rows={3}
                                placeholder="Description"
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-center">
                <button
                    type="button"
                    className="btn button-01 py-0"
                    onClick={() => fields.push({})}
                >
                    Add Detail
                </button>
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
        </div>
    )
}

const Form = (props) => {
    //  State
    const {detailsValue: icons, quoteIcon, enable_client_feedbackValue, client_feedback_formValue} = props
    //  Bind
    const { handleSubmit, goBack, setIcon, setQuoteIcon } = props;

    return (
        <form
            action=""
            onSubmit={handleSubmit}
            className="row mx-auto justify-content-center mt-5"
            style={{ width: "80%" }}
        >
            <div className="row w-100">
                <div className="form-group has-feedback col-md-6 col-12 mt-4">
                    <label htmlFor="broker_fee">Broker Fee (Over profit)</label>
                    <Field
                        name="broker_fee"
                        id="broker_fee"
                        placeholder="Broker Fee"
                        allowNegative={false}
                        suffix="%"
                        decimalScale={2}
                        component={renderNumber}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12 mt-4">
                    <label htmlFor="influencer_fee">Influencer Fee (Over profit)</label>
                    <Field
                        name="influencer_fee"
                        id="influencer_fee"
                        placeholder="Influencer Fee"
                        allowNegative={false}
                        suffix="%"
                        decimalScale={2}
                        component={renderNumber}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 mt-4">
                    <label htmlFor="enable_client_feedback">Enable Client Feedback Form</label>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <Field
                            name="enable_client_feedback"
                            id="enable_client_feedback"
                            component={renderFieldCheck}
                            type="checkbox"
                            className="form-control"
                        />
                        {enable_client_feedbackValue && (
                            <Link className="btn button-primary ml-auto mr-1 px-3" to={client_feedback_formValue ? `${'/vendor/client-feedback/' + client_feedback_formValue + '/edit'}` : '/vendor/client-feedback/create'}>{client_feedback_formValue ? 'Edit' : 'Create'} Feedback Form</Link>
                        )}
                    </div>
                </div>
                <div className="form-group has-feedback col-md-6 mt-4">
                    <label htmlFor="quote_icon">Quotes Icon</label>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-4">
                            <Field
                                name="quote_icon"
                                photo={quoteIcon}
                                setFile={setQuoteIcon}
                                spectValue={1}
                                component={renderFileImageCrop}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group has-feedback col-md-6 mt-4">
                    <label htmlFor="vendor_title_not_available">Vendor Title not Available</label>
                    <Field
                        name='vendor_title_not_available'
                        placeholder="Vendor Title not Available"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 mt-4">
                    <label htmlFor="vendor_text_not_available">Vendor Text not Available</label>
                    <Field
                        name='vendor_text_not_available'
                        placeholder="Vendor Text not Available"
                        rows={3}
                        component={renderTextArea}
                        customClass="text-area-h-auto border"
                    />
                </div>
                <div className="col-12 w-100 form-group mt-4">
                    <div className="row mb-2">
                        <div className="form-group has-feedback col-12 mt-auto">
                            <label htmlFor="details">Details</label>
                            <FieldArray
                                id="details"
                                name="details"
                                icons={icons}
                                setIcon={setIcon}
                                component={renderDetails}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex mb-4">
                <button type="button" className="btn button-03 ml-auto mr-1 px-3" onClick={(e) => goBack()}>Cancel</button>
                <button type="submit" className="btn btn-primary ml-1 px-3">Save</button>
            </div>
        </form>
    )
}

let VendorSettingForm = reduxForm({
    form: "vendorSettingForm", // a unique identifier for this form
    validate
})(Form);

const selector = formValueSelector("vendorSettingForm");
VendorSettingForm = connect((state) => {
    const detailsValue = selector(state, 'details')
    const enable_client_feedbackValue = selector(state, 'enable_client_feedback')
    const client_feedback_formValue = selector(state, 'client_feedback_form')
    const quote_iconValue= selector(state, 'quote_icon')

    return {
        detailsValue,
        enable_client_feedbackValue,
        client_feedback_formValue,
        quoteIcon: quote_iconValue,
    };
})(VendorSettingForm);

export default VendorSettingForm;