import React, {useState, useEffect} from "react";
import moment from "moment"
import Avatar from "react-avatar";
import Grid from "../../Utils/GridTable";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {
    DEFAULT_PAGE_SIZE_IN_BACKEND,
} from "../../../../utility/constants";


const CancellationDeleteOptionGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        remove,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("APP-CANCELLATION-DELETE-OPTION-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="APP-CANCELLATION-DELETE-OPTION-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={DEFAULT_PAGE_SIZE_IN_BACKEND}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    remove: remove,
                                    edit: 'app_cancellation_delete_option',
                                }),
                        },
                        {
                            dataField: "index",
                            sort: false,
                            text: "Index",
                        },
                        {
                            dataField: "label",
                            sort: false,
                            text: "Text",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "value",
                            sort: false,
                            text: "Value",
                        },
                        {
                            dataField: "reason_required",
                            sort: false,
                            text: "Reason Required",
                            formatter: (cell, row) => {
                                return <span>{cell ? "Yes" : "No"}</span>
                            }
                        },

                        {
                            dataField: "type",
                            sort: false,
                            text: "Type",
                            formatter: (cell, row) => {
                                let typeText = ''
                                if (cell == 100) {
                                    typeText = 'Cancellation'
                                } else if (cell == 200) {
                                    typeText = "Delete Agent Account"
                                } else if (cell == 300) {
                                    typeText = "Vendor Cancellation"
                                }
                                return <span>{typeText}</span>
                            }
                        },
                    ]}
                />
            </div>
        </div>
    );

};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('lll')}
            </p>
        )
    };
}

export default CancellationDeleteOptionGrid;
