import React, {Component} from 'react';
import {connect} from "react-redux";
import {reduxForm, formValueSelector} from "redux-form";
import {validators} from "../validation";
import {ProfileForm} from "../JobTypeForm";


const JobTypeCreateForm = reduxForm({
    form: 'JobTypeCreateForm', // a unique identifier for this form
    validate: validators,
})(ProfileForm);

const selector = formValueSelector('JobTypeCreateForm');

const mstp = state => ({
    multiple_description: selector(state, 'multiple_description'),
    icon: selector(state, 'icon'),
    map_icon: selector(state, 'map_icon'),
    icon_colorValue: selector(state, 'icon_color'),
    map_icon_colorValue: selector(state, 'map_icon_color'),
    additional_services: selector(state, 'additional_services'),
    job_type_option: selector(state, 'job_type_option'),
    is_duration: selector(state, 'is_duration'),
    allow_hired_agent: selector(state, 'allow_hired_agent'),
    enable_customer_feedback: selector(state, 'enable_customer_feedback'),
    enable_client_feedback: selector(state, 'enable_client_feedback'),
    enable_feedback: selector(state, 'enable_feedback'),
    services_type: selector(state, 'services_type'),
    job_type: selector(state, 'job_type'),
    is_other_services: selector(state, 'is_other_services'),
});

const JobTypeConnectedForm = connect(mstp, null)(JobTypeCreateForm);

export default class JobTypeCreate extends Component {
    state = {
        icon: null,
        map_icon: null,
        subIcons: [],
        mapIcons: [],
    }

    setIcon = position => (icon) => {
        const {subIcons} = this.state;
        if (!!subIcons[position]) {
            subIcons[position].file = icon;
        } else {
            subIcons[position] = {file: icon, name: position};
        }
        this.setState({subIcons: [...subIcons]});
    }

    setMapIcon = position => (icon) => {
        const {mapIcons} = this.state;
        if (!!mapIcons[position]) {
            mapIcons[position].file = icon;
        } else {
            const name = `map_${position}`
            mapIcons[position] = {file: icon, name: name};
        }
        this.setState({mapIcons: [...mapIcons]});
    }

    setMainIcon = (icon) => {
        this.setState({icon});
    }

    setMainMapIcon = (map_icon) => {
        this.setState({map_icon});
    }

    render() {
        const {create} = this.props;
        const {icon, map_icon, subIcons, mapIcons} = this.state;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                       <div className="header-page"> Create Job Type</div>
                    </div>
                </div>
                <JobTypeConnectedForm
                    onSubmit={(data) => {
                        if (data.job_type_option) {
                            data.job_type_option.forEach((item) => {
                                item.icon = null;
                                item.map_icon = null;
                                item.customer_feedback_form = item.customer_feedback_form ? item.customer_feedback_form.value : null;
                                item.agent_feedback_form = item.agent_feedback_form ? item.agent_feedback_form.value : null;
                            });
                        }
                        create(
                            {
                                ...data,
                                icon: null,
                                map_icon: null,
                                customer_feedback_form: data.customer_feedback_form ? data.customer_feedback_form.value : null,
                                agent_feedback_form: data.agent_feedback_form ? data.agent_feedback_form.value : null,
                                client_feedback_form: data.client_feedback_form ? data.client_feedback_form.value : null,
                            },
                            [{file: icon, name: "icon"}, {file: map_icon, name: "map_icon"}, ...subIcons, ...mapIcons]
                        );
                    }}
                    icon={icon}
                    map_icon={map_icon}
                    setIcon={this.setIcon}
                    setMainIcon={this.setMainIcon}
                    setMapIcon={this.setMapIcon}
                    setMainMapIcon={this.setMainMapIcon}
                />
            </div>
        );
    }
}
