import React from "react";
import Swal from "sweetalert2";
import {Link} from 'react-router-dom'
import HeaderCard from "../HeaderCard";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import LoadMask from "../Utils/LoadMask/LoadMask";
import { SIZE_PER_PAGE } from "../../../utility/constants";
import { renderFilePicker } from "../Utils/renderField/renderField";
import GridTableWithScroll from "../Utils/GridTableWithScroll";
import Avatar from "react-avatar";
import { formatPhoneNumber } from "./BrokerageGrid";
import { standardActions } from "../Utils/Grid/StandardActions";
import {
    USER_ROLES_LIST,
    MANAGING,
    OFFICE_MANAGER,
    STAFF,
    BROKERAGE_STATUSES,
    BROKERAGE_ACTIVE,
    BROKERAGE_LOCKED,
    BROKERAGE_BLOCKED,
} from "../../../utility/constants";
import {
    renderField,
    renderNumber,
    renderSwitch,
    SelectField,
    renderAddressField,
} from "../Utils/renderField";

const validate = (values) => {
    let errors = {};
    if (!values.firm_name) {
        errors.firm_name = "This field is required";
    }
    if (!values.broker) {
        errors.broker = "This field is required";
    }
    if (!values.license) {
        errors.license = "This field is required";
    }
    if (!values.address) {
        errors.address = "This field is required";
    }
    return errors;
};

const DetailsWithBroker = (props) => {
    const {
        handleSubmit,

        id,
        data,
        loader,
        loader2,
        office,
        agentList,
        searchAction,
        setLogo,
        isValidation,
        removeInvite,
        goBack,
        goToAgents,
        optionBroker,
        assignAddressData,
    } = props;

    const formatPhoneNumberOffice = (cell) => {
        let phone = "";
        if (cell.length > 10) {
            cell = cell.substr(cell.length - 10, 12);
        }
        phone = `${cell.substr(0, 3)}-${cell.substr(3, 3)}-${cell.substr(
            6,
            4
        )}`;
        return phone;
    };

    const getStatus = (status) => {
        let text = "";
        BROKERAGE_STATUSES.map((item) => {
            if (status === item.value) {
                text = item.label;
            }
        });
        return text;
    };

    const getColor = (status) => {
        let colorText = "black";
        if (status === BROKERAGE_ACTIVE) {
            colorText = "success";
        } else if (status === BROKERAGE_LOCKED) {
            colorText = "danger";
        } else if (status === BROKERAGE_BLOCKED) {
            colorText = "danger";
        }
        return colorText;
    };

    return (
        <form
            name="detailsWithBroker"
            className="my-1 col-sm-12 p-sm-0"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="form-group has-feedback">
                        <Field
                            photo={office.logo ? office.logo : null}
                            setFile={setLogo}
                            name="logo"
                            component={renderFilePicker}
                            // disabled={true}
                            // className="avatar-round"
                        />
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {office && (
                            <span
                                className={`text-${getColor(
                                    office.status
                                )} font-weight-bold`}
                            >
                                {getStatus(office.status)}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {moment(office.created).format("ll")}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Total Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_agents
                                    ? office.agents_stats.total_agents
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Registered Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_user
                                    ? office.agents_stats.total_user
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Unregistered Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_unregistered
                                    ? office.agents_stats.total_unregistered
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Active Agents
                        </span>
                        {office && office.agents_stats && (
                            <span className="font-weight-bolder text-primary pointer" onClick={(e) => goToAgents(office.id)}>
                                {office.agents_stats.total_user_active
                                    ? office.agents_stats.total_user_active
                                    : 0}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Total ShowIT Share
                        </span>
                        {office && (
                            <span className="font-weight-bolder text-primary">
                                ${" "}
                                {office.total_show_it_share
                                    ? office.total_show_it_share.toFixed(2)
                                    : "0.00"}
                            </span>
                        )}
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Total ShowIT Revenue
                        </span>
                        {office && (
                            <span className="font-weight-bolder text-primary">
                                ${" "}
                                {office.total_show_it_revenue
                                    ? office.total_show_it_revenue.toFixed(2)
                                    : "0.00"}
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="firm_name">Office name</label>
                            <Field
                                name="firm_name"
                                label="Office name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                // disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="broker">Broker</label>
                            <Field
                                name="broker"
                                label="state"
                                // disabled={is_view}
                                options={optionBroker}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="franchise">Franchise</label>
                            <div className="form-control">
                                {office && office.broker_info && office.broker_info.franchise
                                    ? office.broker_info.franchise.name
                                    : ""}
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="form-group has-feedback col-lg-6 col-sm-12">
                                    <label htmlFor="license">
                                        Office License #
                                    </label>
                                    <Field
                                        name="license"
                                        label="Office License #"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                        // disabled={is_view}
                                    />
                                </div>
                                <div className="form-group has-feedback col-lg-6 col-sm-12">
                                    <label
                                        htmlFor="telephone"
                                        className="mx-0 px-0"
                                    >
                                        Phone #
                                    </label>
                                    <Field
                                        numberFormat={"###-###-####"}
                                        name="telephone"
                                        component={renderNumber}
                                        className="form-control"
                                        // disabled={is_view}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address">Address</label>
                            <Field
                                name="address"
                                label="address"
                                placeholder='Search an address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                longStateName={true}
                                setCityState={(data) => {
                                    console.log('ADDRESS DATA: ', data);
                                    if (data) {
                                        assignAddressData(data)
                                    }
                                }}
                            />
                            {/* <Field
                                name="address"
                                label="Address"
                                component={renderField}
                                type="text"
                                className="form-control"
                                // disabled={is_view}
                            /> */}
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="address_line2">
                                Address Line 2
                            </label>
                            <Field
                                name="address_line2"
                                label="Address Line 2"
                                component={renderField}
                                type="text"
                                className="form-control"
                                // disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="provide_roster">
                                Broker Provide agent's roster list?
                            </label>
                            <div className="form-control">
                                {office &&
                                office.broker_info &&
                                office.broker_info.provide_agents_roster
                                    ? "YES"
                                    : "NO"}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="operational">
                                Operational Mode
                            </label>
                            <div className="form-control">
                                {office && office.broker_info
                                    ? office.broker_info.operation_access_name
                                    : ""}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-sm-12">
                            <label htmlFor="Autorized" className="h5">
                                Authorized Personnel
                            </label>
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-12">
                                        <HeaderCard
                                            id_header={"agentGridList"}
                                            searchAction={(value) =>
                                                searchAction(value)
                                            }
                                            showFile={false}
                                            // placeholder="Search Agent"
                                            placeholder="Search"
                                            searchStyle={
                                                "col-lg-5 col-sm-12 img-fluid mr-lg-3 mb-sm-1"
                                            }
                                            // justify={"justify-content-start d-flex col-lg-5 col-sm-12 col-md-6 align-items-end"}
                                            active_link={true}
                                            link={`users/invite`}
                                            text_link={"Invite"}
                                        />
                                    </div>
                                    <div
                                        className="d-flex flex-column flex-1 mt-3 col-sm-12"
                                    >
                                        <div className="flex-1 d-flex flex-column">
                                            <GridTableWithScroll
                                                height={355}
                                                hover
                                                wrapperClasses={
                                                    "table-scroll-auto"
                                                }
                                                striped
                                                keyField={"id"}
                                                data={data}
                                                loading={loader2}
                                                sizePerPage={SIZE_PER_PAGE}
                                                onPageChange={(value) =>
                                                    agentList(value)
                                                }
                                                columns={[
                                                    // {
                                                    //     dataField: "user.photo",
                                                    //     formatter: avatar(),
                                                    //     text: " ",
                                                    // },
                                                    {
                                                        dataField: "id",
                                                        text: "",
                                                        formatter:
                                                            standardActions({
                                                                remove: removeInvite,
                                                                // view: "users",
                                                                edit: `users`,
                                                            }),
                                                    },
                                                    {
                                                        dataField:
                                                            "account_status",
                                                        formatter:
                                                            formatStatus(),
                                                        text: "Status",
                                                    },
                                                    // {
                                                    //     dataField:
                                                    //         "first_name",
                                                    //     formatter:
                                                    //         fillName(),
                                                    //     text: "Full Name",
                                                    // },
                                                    {
                                                        dataField: "first_name",
                                                        // formatter:
                                                        //     fillName(),
                                                        text: "First Name",
                                                    },
                                                    {
                                                        dataField: "last_name",
                                                        // formatter:
                                                        //     fillName(),
                                                        text: "Last Name",
                                                    },
                                                    {
                                                        dataField: "email",
                                                        text: "E-mail",
                                                    },
                                                    {
                                                        dataField: "phone",
                                                        formatter:
                                                            formatPhoneNumber(),
                                                        text: "Mobile number",
                                                    },
                                                    {
                                                        dataField: "roles",
                                                        formatter:
                                                            formatRoles(),
                                                        text: "Role",
                                                    },
                                                    {
                                                        dataField: "password",
                                                        formatter:
                                                            resetPassword(),
                                                        text: "",
                                                        headerStyle: (colum, colIndex) => {
                                                            return {
                                                                minWidth: "160px",
                                                            };
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-12 mb-0">
                            <label
                                className="mt-n4 d-block"
                                style={{ fontSize: "1.05rem" }}
                            >
                                {" "}
                                Account Settings
                            </label>
                            <div className="row w-100 m-0 h-100">
                                <div className="rounded border col-sm-12 py-2">
                                    <div className="row">
                                        <div className="form-group mb-0 has-feedback col-lg-4 col-md-6 col-sm-12">
                                            <label
                                                htmlFor="locked"
                                                className="mb-3"
                                            >
                                                Lock
                                            </label>
                                            <Field
                                                name="locked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                onChange={(e, val) => {
                                                    console.log("VALOR LOCKE: ", val);
                                                    if (val == true) {
                                                        Swal.fire("Lock Office", "By doing that all the agents under this office will be Locked from using the ShowIT Solutions™ app", "info")
                                                    }
                                                }}
                                                // disabled={is_view}
                                            />
                                        </div>
                                        <div className="form-group mb-0 has-feedback col-lg-4 col-md-6 col-sm-12">
                                            <label
                                                htmlFor="blocked"
                                                className="mb-3"
                                            >
                                                Block
                                            </label>
                                            <Field
                                                name="blocked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                onChange={(e, val) => {
                                                    console.log("VALOR LOCKE: ", val);
                                                    if (val == true) {
                                                        Swal.fire("Block Office", "By doing that all the agents under this office will be Blocked from using the ShowIT Solutions™ app", "info")
                                                    }
                                                }}
                                                // disabled={is_view}
                                            />
                                        </div>
                                        <div className="form-group mb-0 has-feedback col-lg-4 col-md-6 col-sm-12">
                                            <label
                                                htmlFor="affiliated"
                                                className="mb-3"
                                            >
                                                Affiliated with ShowIT?
                                            </label>
                                            <Field
                                                name="affiliated"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mx-auto ">
                <button
                    type="button"
                    onClick={goBack}
                    className={`w-100 btn btn-secondary text-white col-lg-3 col-sm-5 mx-4 mt-2`}
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="btn btn-primary col-lg-3 col-sm-5 mx-4 mt-2"
                >
                    Update
                </button>
            </div>
        </form>
    );
};

function fillName() {
    return (cell, row) => {
        return <p className={"my-0"}>{cell + " " + row.last_name}</p>;
    };
}

export function formatStatus() {
    return (cell, row) => {
        return (
            <React.Fragment>
                {row.roles !== STAFF && (
                    <p
                        className={`my-0 text-capitalize fw-bold text-${
                            cell === 1 ? "danger" : "success"
                        }`}
                    >
                        {cell === 1 ? "Sent" : "Registered"}
                    </p>
                )}
            </React.Fragment>
        );
    };
}

export function formatRoles() {
    return (cell, row) => {
        const getStatus = () => {
            let text = "";
            USER_ROLES_LIST.map((item) => {
                if (cell === item.value) {
                    if (cell == STAFF) {
                        text = `${item.label} (${row.staff_name})`;
                    }else {
                        text = item.label;
                    }
                }
            });
            return text;
        };
        return <div className="m-0 p-0">{getStatus()}</div>;
    };
}

const resetPassword = () => (cell, row) => {
    return (
        <div className="w-100 d-flex flex-row align-items-center justify-content-center">
            {row.account_status == 5 && (
                <Link to={`/offices/users/${row.user.id}/change-password`} className="btn btn-success text-white">
                    Reset Password
                </Link>
            )}
        </div>
    )
}

export default reduxForm({
    form: "detailsWithBroker", // a unique identifier for this form
    validate,
})(DetailsWithBroker);
