import React, { Component } from 'react';
import GifCardDesignGrid from "./GifCardDesignGrid";
import GifCardDesignFiltersForm from './GifCardDesignFiltersForm';

class GifCardDesignList extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.toList();
    }

    render() {
        // State
        const { loader, page, data } = this.props;
        //  Bind
        const { toList, searchChange, remove, onSortChange } = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Gift Card Design</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <GifCardDesignFiltersForm searchAction={searchChange} />
                </div>

                <GifCardDesignGrid
                    data={data}
                    page={page}
                    loader={loader}
                    toList={toList}
                    onSortChange={onSortChange}
                    remove={remove}
                />
            </div>
        );
    }
}

export default GifCardDesignList;
