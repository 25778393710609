import { connect } from 'react-redux'
import CreateUpdateLegal from './CreateUpdateLegal'
import {actions} from '../../../../redux/modules/legal'

const mapStateToProps = (state) => ({
    ...state.legal
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateLegal)