import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import HeaderCard from "../../HeaderCard";
import { Field, reduxForm } from "redux-form";
import GridTableWithScroll from "../../Utils/GridTableWithScroll";
import { standardActions } from "../../Utils/Grid/StandardActions";
import {SIZE_PER_PAGE, USER_ROLES_LIST, STAFF} from '../../../../utility/constants'

import {
    renderField,
    customFieldFile,
    renderFieldRadio,
} from "../../Utils/renderField";

const validate = (values) => {
    let errors = {};

    return errors;
}


const InfluencerTeamProfileForm = (props) => {
    const {
        handleSubmit,
        id,
        item,
        loaderContact,
        data,
        urlTemplate,
        active,
        text,
    } = props;
    //  Bind
    const {
        goTo,
        goBack,
        contactList,
        searchAction,
        removeContact,
        setActive,
        handleOnChange,
        goToAgents,
    } = props;

    const createMask = (phone) => {
        if (phone) {
            return phone.replace(/(\d{3})(\d{3})(\d{4})/,"$1-$2-$3");
        }

        return ''
    }


    return (
        <form
            name="registerAdmin"
            className="my-1 col-sm-12 p-sm-0"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        <span className="font-weight-bolder text-success">
                            Active
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            {item && item.created
                                ? moment(item.created).format("ll")
                                : ""}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Total Agents
                        </span>
                        <span
                            className="font-weight-bolder text-primary pointer"
                            onClick={() => {
                                if (item && item.id) {
                                    goToAgents(item.id);
                                }
                            }}
                        >
                            {item && item.total_agent && item.total_agent.total
                                ? item.total_agent.total
                                : 0}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Unregistered Agents
                        </span>
                        <span className="font-weight-bolder text-primary pointer"
                            onClick={() => {
                                if (item && item.id) {
                                    goToAgents(item.id);
                                }
                            }}>
                            {item &&
                            item.total_agent &&
                            item.total_agent.total &&
                            item.total_agent.registered
                                ? item.total_agent.total -
                                  item.total_agent.registered
                                : 0}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Registered Agents
                        </span>
                        <span className="font-weight-bolder text-primary pointer"
                            onClick={() => {
                                if (item && item.id) {
                                    goToAgents(item.id);
                                }
                            }}>
                            {item &&
                            item.total_agent &&
                            item.total_agent.registered
                                ? item.total_agent.registered
                                : 0}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Active Agents
                        </span>
                        <span className="font-weight-bolder text-primary pointer" 
                            onClick={() => {
                                if (item && item.id) {
                                    goToAgents(item.id);
                                }
                            }}>
                            {item && item.total_agent && item.total_agent.active
                                ? item.total_agent.active
                                : 0}
                        </span>
                    </div>

                    <div className="col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Total Profit Share
                        </span>
                        <span className="font-weight-bolder text-primary">
                            ${" "}
                            {item &&
                            item.total_job &&
                            item.total_job.profit_share
                                ? item.total_job.profit_share.toFixed(2)
                                : "0.00"}
                        </span>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="name">Team Name</label>
                            <Field
                                name="name"
                                label="Team Name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="total_agents">Total Agents</label>
                            <Field
                                name="total_agents"
                                label="Total Agents"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="">Influencer</label>
                            <div
                                className="form-control pointer text-primary"
                                onClick={() => {
                                    if (item && item.influencer) {
                                        goTo(item.influencer.id);
                                    }
                                }}
                            >
                                {item &&
                                    item.influencer &&
                                    `${item.influencer.first_name} ${item.influencer.last_name}`}
                            </div>
                        </div>
                        <div className="form-group cursor-not-allowed has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="">Address</label>
                            <div className="form-control">
                                {item &&
                                    item.influencer &&
                                    `${item.influencer.address}`}
                            </div>
                        </div>
                        <div className="form-group cursor-not-allowed has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="">Phone #</label>
                            <div className="form-control">
                                {item &&
                                    item.influencer &&
                                    `${createMask(
                                        item.influencer.phone_number
                                    )}`}
                            </div>
                        </div>
                        <div className="form-group cursor-not-allowed has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="">Franchise</label>
                            <div className="form-control">
                                {item &&
                                    item.influencer &&
                                    `${item.influencer.franchise_name}`}
                            </div>
                        </div>
                        <div className="form-group cursor-not-allowed has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="">Office License #</label>
                            <div className="form-control">
                                {item &&
                                    item.influencer &&
                                    `${item.influencer.re_license}`}
                            </div>
                        </div>
                        <div className="form-group cursor-not-allowed has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="">Sponcered By</label>
                            <div className="form-control">
                                {item &&
                                    item.influencer &&
                                    item.influencer.sponsored_by &&
                                    `${item.influencer.sponsored_by_data.first_name} ${item.influencer.sponsored_by_data.last_name}`}
                            </div>
                        </div>
                        <div className="col-sm-12 mt-3">
                            <p className="text-12 mt-1 mb-3 text-justify">
                                Update Agent's Roster List
                            </p>
                            <div className="row w-100 align-items-center justify-content-center">
                                <div className="col-md-3 col-sm-12 d-flex flex-column align-items-center justify-content-center">
                                    <label className="text-12">
                                        Agent Roster Template
                                    </label>
                                    <a
                                        href={urlTemplate}
                                        download="template.xlsx"
                                        className="btn btn-success text-white mb-2"
                                    >
                                        Download
                                    </a>
                                </div>
                                <div className="col-md-3 col-sm-12 d-flex flex-column align-items-center justify-content-center">
                                    <label className="text-12">
                                        Import your agent list
                                    </label>
                                    {/*----------------------------------------*/}
                                    {/*------------   if is create  -----------*/}
                                    {/*----------------------------------------*/}
                                    <Field
                                        id={"office_excel"}
                                        name="excel-office"
                                        setFile={handleOnChange}
                                        component={customFieldFile}
                                        title={"Import"}
                                        text={text}
                                        customClass="btn py-1 btn-primary"
                                        type={"file"}
                                        active={active}
                                        accept={"*"}
                                        containerClass=''
                                        remove={() => setActive(true)}
                                    />
                                </div>
                                <div className="col-md-3 col-sm-12 d-flex flex-column align-items-center justify-content-center">
                                    <label className="text-12">
                                        Update/Replace Roster List?
                                    </label>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <Field
                                            type="radio"
                                            name="roster_replace"
                                            label="Update"
                                            value='update'
                                            component={renderFieldRadio}
                                        />
                                        <Field
                                            type="radio"
                                            name="roster_replace"
                                            label="Replace"
                                            value="replace"
                                            component={renderFieldRadio}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group has-feedback col-sm-12">
                            <label htmlFor="Autorized" className="h5">
                                Contacts
                            </label>
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-12">
                                        <HeaderCard
                                            id_header={
                                                "influencerTeamContactsList"
                                            }
                                            searchAction={(value) =>
                                                searchAction(value, id)
                                            }
                                            showFile={false}
                                            // placeholder="Search Agent"
                                            placeholder="Search"
                                            searchStyle={
                                                "col-lg-5 col-sm-12 img-fluid mr-lg-3 mb-sm-1"
                                            }
                                            // justify={"justify-content-start d-flex col-lg-5 col-sm-12 col-md-6 align-items-end"}
                                            active_link={true}
                                            link={`contact/create`}
                                            text_link={"Add"}
                                        />
                                    </div>
                                    <div className="d-flex flex-column flex-1 mt-3 col-sm-12">
                                        <div className="flex-1 d-flex flex-column">
                                            <GridTableWithScroll
                                                height={345}
                                                hover
                                                wrapperClasses={
                                                    "table-scroll-auto"
                                                }
                                                striped
                                                keyField={"id"}
                                                data={data}
                                                loading={loaderContact}
                                                sizePerPage={SIZE_PER_PAGE}
                                                onPageChange={(value) =>
                                                    contactList(value, id)
                                                }
                                                columns={[
                                                    // {
                                                    //     dataField: "user.photo",
                                                    //     formatter: avatar(),
                                                    //     text: " ",
                                                    // },
                                                    {
                                                        dataField: "id",
                                                        text: "",
                                                        formatter:
                                                            standardActions({
                                                                remove: removeContact,
                                                                // view: "users",
                                                                edit: `contact`,
                                                            }),
                                                    },
                                                    {
                                                        dataField: "first_name",
                                                        // formatter:
                                                        //     fillName(),
                                                        text: "First Name",
                                                    },
                                                    {
                                                        dataField: "last_name",
                                                        // formatter:
                                                        //     fillName(),
                                                        text: "Last Name",
                                                    },
                                                    {
                                                        dataField: "email",
                                                        text: "E-mail",
                                                    },
                                                    {
                                                        dataField: "phone",
                                                        formatter:
                                                            formatPhoneNumber(),
                                                        text: "Mobile number",
                                                    },
                                                    {
                                                        dataField: "roles",
                                                        formatter:
                                                            formatRoles(),
                                                        text: "Role",
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center mx-auto mt-3">
                <div className="col-sm-12 col-md-3 d-flex justify-content-center align-items-center">
                    <button
                        type="button"
                        onClick={goBack}
                        className={`btn btn-secondary text-white`}
                    >
                        Back
                    </button>
                </div>
                <div className="col-sm-12 col-md-3 d-flex justify-content-center align-items-center">
                    <button type="submit" className="btn btn-primary">
                        update
                    </button>
                </div>
            </div>
        </form>
    );
}

function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}

function formatRoles() {
    return (cell, row) => {
        const getStatus = () => {
            let text = "";
            USER_ROLES_LIST.map((item) => {
                if (cell === item.value) {
                    if (cell == STAFF) {
                        text = `${item.label} (${row.staff_name})`;
                    }else {
                        text = item.label;
                    }
                }
            });
            return text;
        };
        return <div className="m-0 p-0">{getStatus()}</div>;
    };
}
export default reduxForm({
    form: "influencerTeamProfileForm", // a unique identifier for this form
    initialValues: {roster_replace: 'update'},
    validate,
})(InfluencerTeamProfileForm);
