import React, { Component } from "react";
import VendorTypeFilterForm from './VendorTypeFilterForm'
import VendorTypeGrid from './VendorTypeGrid'

export default class VendorTypeList extends Component {

    componentWillMount() {
        this.props.toList();
    }
    

    render() {
        // State
        const {data, loader, page, searchChange, toList, remove} = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Vendor Type</div>
                    </div>
                </div>

                <VendorTypeFilterForm
                    searchAction={searchChange}/>

                <VendorTypeGrid 
                    data={data}
                    loader={loader}
                    page={page}
                    toList={toList}
                    remove={remove}
                    />
            </div>
        );
    }
}
