import React from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {STATE_USA_LIST, SELECT_ALL_OPTION} from '../../../../utility/constants';
import { renderSearchField, MultiSelectField } from "../../Utils/renderField";
import { Field, reduxForm, formValueSelector } from "redux-form";

let InfluencerTeamFiltersForm = (props) => {
    //  State
    const {} = props;
    //  Bind
    const {changeFilters} = props;

    return (
        <div className="row justify-content-between mt-2 w-100 mb-3">
            <div className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}>
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search Influencer'}
                        component={renderSearchField}
                        onChange={(e) => {
                            changeFilters(
                                "search",
                                e.target && e.target.value ? e.target.value : ""
                            )
                        }}
                        type="text"
                    />
                </div>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="row">
                    <div className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-2`}>
                        <label htmlFor="state">State</label>
                        <Field
                            name="state"
                            options={STATE_USA_LIST}
                            set_value={(value) => {
                                changeFilters("state", value);
                            }}
                            component={MultiSelectField}
                            className="form-control"
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

InfluencerTeamFiltersForm = reduxForm({
    form: 'InfluencerTeamFiltersForm', // a unique identifier for this form
    initialValues: {state: SELECT_ALL_OPTION['value'] },
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(InfluencerTeamFiltersForm);

const selector = formValueSelector('InfluencerTeamFiltersForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(InfluencerTeamFiltersForm);
