import React, {Component} from 'react';
import PropTypes from "prop-types";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import InfluencerTeamContactForm from "./InfluencerTeamContactForm";

class InfluencerTeamContact extends Component {

    static defaultProps = {
        loading: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
            id: null,
            team_id: null
        };
    }

    componentDidMount() {
        const {match, setRealId} = this.props;
        let team_id = ''
        if(match.params.team_id) {
            team_id = match.params.team_id
        }
        if (match.params.id && team_id != '') {
            this.props.customRead(match.params.id, team_id);
            this.setState({
                is_update: true,
                id: match.params.id,
            });
        }

        if (team_id != '') {
            this.setState({
                team_id: team_id
            });
        }
    }

    componentWillUnmount() {
        const {setItem} = this.props
        setItem({});
    }

    updateForm = (data) => {
        const {team_id, id} = this.state;
        if (team_id) {
            this.props.updateContact(id, team_id, this.goBack)
        }
    };

    createContact = (data) => {
        const {team_id} = this.state;
        if (team_id) {
            this.props.addContact(team_id, this.goBack)
        }
    };

    goBack = () => {
        const {history} = this.props;
        history.goBack()
    }


    render() {
        const {loader, cancelInvite, item2, match} = this.props;
        const {team_id} = this.state;
        const sendFunction = match.params.id
            ? this.updateForm
            : this.createContact;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">
                            {
                                this.state.is_update ? "update Contact" : "New Contact"
                            }
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-2">
                    <LoadMask loading={loader} light>
                        <InfluencerTeamContactForm
                            onSubmit={sendFunction}
                            loader={loader}
                            is_update={this.state.is_update}
                            goBack={this.goBack}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default InfluencerTeamContact;