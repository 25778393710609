import React, {useEffect, useRef, useState} from "react";
import { Bar } from "react-chartjs-2";
import LoadMask from "../Utils/LoadMask/LoadMask";
import {Chart} from 'chart.js';
import classNames from 'classnames';
import ReactStars from "react-rating-stars-component";
import Select, { Creatable, Async } from 'react-select';
import Tabs, {TabPane, TabContent} from 'rc-tabs';
import InkTabBar from "rc-tabs/lib/InkTabBar";
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Chart.register(ChartDataLabels);

const RangerField = (props) => {
    //  State
    const {value} = props
    //  Bind
    const {setValue} = props;
    return (
        <Select
            className={classNames('react-select-container')}
            backspaceRemovesValue={false}
            // isMulti={isMulti}
            // isSearchable={isSearchable}
            options={[
                {label: 'Daily', value: 'daily'},
                {label: 'Weekly', value: 'weekly'},
                {label: 'Monthly', value: 'monthly'},
                {label: 'Quarterly', value: 'quarterly'},
                {label: 'Annually', value: 'annually'}
            ]}
            // placeholder={placeholder}
            onChange={(e) => {
                setValue && setValue(e);
            }}
            value={value}
            // isDisabled={disabled}
            theme={theme => ({
                ...theme,
                borderRadius: 5,
                borderLeft: "1px solid #C7C7C7",
                borderRight: "1px solid #C7C7C7",
                borderTop: "1px solid #C7C7C7",
                borderBottom: "1px solid #C7C7C7",
                fontSize: "12px !important",
                color: '7b7b7b !important',
                colors: {
                    ...theme.colors,
                    primary25: '#F1F1F1', // style to select one option
                    primary: '#7E8A97',  // style of option selected
                },
            })}
            // styles={customStyles}
        />
    )
}

const ShowITCategory = (props) => {
    //  State
    const {data, loader, ranger} = props;
    //  Bind
    const {setRanger} = props;
    const [lastCategoryName, setLastCategoryName] = useState("Canceled");
    const [totalCategoryBars, setTotalCategoryBars] = useState(0);

    useEffect(() => {
        if (data && data.datasets && data.datasets.length) {
            const bars = sumDatasets(data.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalCategoryBars(totalBars.length)
        } else {
            setTotalCategoryBars(0)
        }

        if (data && data.datasets && data.datasets.length) {
            const _lastCategoryName = data.datasets[data.datasets.length-1].label;
            if(lastCategoryName != _lastCategoryName) {
                setLastCategoryName(_lastCategoryName)
            }
        }else {
            setLastCategoryName('Canceled')
        }
      
    }, [data.datasets])

    const sumDatasets = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.data.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num;
                } else {
                    newArray[index] = num;
                }
            });
        });
        return newArray;
    }

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Categories",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    // pointStyle: 'dash',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalCategoryBars > 12 ? "9.5": "14", family: "Poppins", weight: 'bold' },
                // formatter: function (value, context) {
                //     const data = context.chart.data;
                //     let total = 0;
                //     data.datasets.forEach(item => {
                //         const _val = item.data[context.dataIndex];
                //         if (_val > 0) {
                //             total += _val;
                //         }
                //     })
                //     if (context.dataset.label.toUpperCase().includes(lastCategoryName.toUpperCase())) {
                //         if (total > 0) {
                //             return `${total.toFixed(1)}`;
                //         }else {
                //             return ''
                //         }
                //     }else {
                //         return ''
                //     }
                // },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `${total.toFixed(1)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="px-0 mx-0 row w-100 pb-2">
            <div className="col-sm-12">
                <div className="row justify-content-between">
                    <div className="form-group col-sm-12 col-lg-4 mb-0">
                        <label className="ml-1 label-semi-bold mt-2">Ranger</label>
                        <div className="w-100 px-1 mb-2">
                            <RangerField value={ranger} setValue={(e) => {
                                console.log("Value: ", e);
                                setRanger(e, 'show_it');
                            }} />
                        </div>
                    </div>
                    <div className="mb-2 col-sm-12 col-lg-4">
                        <h6 className="mb-0 font-weight-bold text-center">Total Revenue</h6>
                        <h4 className="font-weight-bolder text-center my-0">
                            $ {data.total ? data.total.toFixed(2) : "0.00"}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 px-0">
                <LoadMask loading={loader} dark blur>
                    <div className="px-2 flex-1 pb-3 dashboard-graph">
                        <Bar data={data} options={options} plugins={[ChartDataLabels]} height={200} />
                    </div>
                </LoadMask>
                {/*----------------------------------------*/}
                {/*-------   STATS LINE ALL JOB    --------*/}
                {/*----------------------------------------*/}
                <div className="w-100 filter-job py-2 mt-3 px-2 dashboard-list-graph">
                    {(data && data.datasets && data.datasets.length > 0) &&
                        data.datasets.map((item, index) => (
                            <div
                                className="mx-auto mb-1 row px-2 py-1 align-items-center text-white text-small-chart"
                                style={{
                                    backgroundColor: item.backgroundColor,
                                }}
                                key={`key-${index}-agent`}
                            >
                                <p className="col-6 px-0 py-0 my-0" style={{ color: item.color }}>
                                    {item.label}
                                </p>
                                <p className="col-3 px-0 my-0" style={{ color: item.color }}>
                                    {item.count_job}&nbsp; {item.count_job > 1 ? 'Jobs' : 'Job'}
                                </p>
                                <p className="col-3 px-2 my-0 justify-content-between text-right" style={{ color: item.color }}>
                                    USD &nbsp;{(item.usd === 0) ? '0.00' : item.usd.toFixed(2)}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export const ShowITChart = (props) => {
    //  State
    const { data, loader, jobNames, ranger, dataShowITCategory } = props;
    //  Bind
    const {setRanger} = props;
    const [lastJobName, setLastJobName] = useState("Runner");
    const [totalBars, setTotalBars] = useState(0);
    const [tab, setTab] = useState("jobs");

    useEffect(() => {
        if (data && data.datasets && data.datasets.length) {
            const bars = sumDatasets(data.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalBars(totalBars.length)
        } else {
            setTotalBars(0)
        }
      
    }, [data.datasets])

    const sumDatasets = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.data.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num;
                } else {
                    newArray[index] = num;
                }
            });
        });
        return newArray;
    }
    

    if (data && data.datasets && data.datasets.length) {
        const _lastJobName = data.datasets[data.datasets.length-1].label;
        if(lastJobName != _lastJobName) {
            setLastJobName(_lastJobName)
        }
    }

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Type of Job",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    // pointStyle: 'dash',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalBars > 12 ? "9.5" : "14", family: "Poppins", weight: 'bold'},
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `${total.toFixed(1)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="card border-card-page col-lg-6 col-sm-12 mr-2 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    ShowIT Revenue
                </label>
            </div>
            <Tabs
                    activeKey={tab}
                    defaultActiveKey="jobs"
                    tabBarPosition="top"
                    onChange={(key) => setTab(key)}
                    renderTabBar={() => <InkTabBar/>}
                    renderTabContent={() => <TabContent/>}
                >
                <TabPane tab="Jobs" key="jobs" className="py-0">
                    {/*-------------------------------------------------*/}
                    {/*--------------------- BODY CARD -----------------*/}
                    {/*-------------------------------------------------*/}
                    <div className="px-0 mx-0 row w-100 pb-2">
                        <div className="col-sm-12">
                            <div className="row justify-content-between">
                                <div className="form-group col-sm-12 col-lg-4 mb-0">
                                    <label className="ml-1 label-semi-bold mt-2">Ranger</label>
                                    <div className="w-100 px-1 mb-2">
                                        <RangerField value={ranger} setValue={(e) => {
                                            console.log("Value: ", e);
                                            setRanger(e, 'show_it');
                                        }} />
                                    </div>
                                </div>
                                <div className="mb-2 col-sm-12 col-lg-4">
                                    <h6 className="mb-0 font-weight-bold text-center">Total Revenue</h6>
                                    <h4 className="font-weight-bolder text-center my-0">
                                    $ {parseFloat(data.revenue ? data.revenue : 0).toFixed(2)}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 px-0">
                            <LoadMask loading={loader} dark blur>
                                <div className="p-2 flex-1">
                                    <div className="row justify-content-end mx-auto px-3">
                                        <a
                                            className="pointer flex-row align-items-center row px-0"
                                            style={{ fontSize: "0.7rem" }}
                                            href="#/stats/revenue"
                                        >
                                            View all stats
                                            <i className="material-icons">arrow_right_alt </i>
                                        </a>
                                    </div>
                                    <div className="dashboard-graph">
                                        <Bar data={data} options={options} plugins={[ChartDataLabels]} height={180} />
                                    </div>
                                </div>
                            </LoadMask>
                            {/*----------------------------------------*/}
                            {/*-------   STATS LINE ALL JOB    --------*/}
                            {/*----------------------------------------*/}
                            <div className="w-100 filter-job py-2 mt-3 px-2 dashboard-list-graph">
                                {(data && data.datasets && data.datasets.length > 0) &&
                                data.datasets.map((job, index) => (
                                    <div
                                        className="mx-auto mb-1 row pl-3 py-1 w-100 justify-content-between text-white"
                                        style={{
                                            backgroundColor: job.borderColor,
                                            fontSize: '0.62rem',
                                        }}
                                        key={`key-${index}-revenue`}
                                    >
                                        <p className="col-5 px-0 py-0 my-0">
                                            {job.label}
                                        </p>
                                        <p className="col-3 px-0 my-0">
                                            {job.count}&nbsp; {job.count > 1 ? 'Jobs' : 'Job'}
                                        </p>
                                        <p className="col-3 px-2 my-0 justify-content-between text-right">
                                            USD &nbsp;{(job.usd === 0) ? '0.00' : job.usd.toFixed(2)}
                                        </p>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Category" key="category" className="py-0">
                    <div className="w-100">
                        <ShowITCategory data={dataShowITCategory} loader={loader} ranger={ranger} setRanger={setRanger}/>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
};

export const JobChart = (props) => {
    //  State
    const { data, loader, jobNames, ranger, dataCategory } = props;
    //  Bind
    const {setRanger} = props;
    const [lastJobName, setLastJobName] = useState("Runner");
    const [lastCategoryName, setLastCategoryName] = useState("Canceled");
    const [totalBars, setTotalBars] = useState(0);
    const [totalCategoryBars, setTotalCategoryBars] = useState(0);
    const [tab, setTab] = useState("jobs");

    const categories = [
        {name: 'Posted', background: '#757575', color: '#FFFFFF'},
        {name: 'Applied', background: '#82b1ff', color: '#FFFFFF'},
        {name: 'Hired/Claimed', background: '#2196f3', color: '#FFFFFF'},
        {name: 'Completed', background: '#2e7d32', color: '#282828'},
        {name: 'Expired', background: '#F2DCBB', color: '#282828'},
        {name: 'Canceled', background: '#c62828', color: '#282828'},
    ]

    useEffect(() => {
        if (data && data.datasets && data.datasets.length) {
            const bars = sumDatasets(data.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalBars(totalBars.length)
        } else {
            setTotalBars(0)
        }
      
    }, [data.datasets])

    useEffect(() => {
        if (dataCategory && dataCategory.datasets && dataCategory.datasets.length) {
            const bars = sumDatasets(dataCategory.datasets)
            const totalBars = _.filter(bars, function(o) {return o > 0})
            setTotalCategoryBars(totalBars.length)
        } else {
            setTotalCategoryBars(0)
        }

        if (dataCategory && dataCategory.datasets && dataCategory.datasets.length) {
            const _lastJobName = dataCategory.datasets[dataCategory.datasets.length-1].label;
            if(lastJobName != _lastJobName) {
                setLastCategoryName(_lastJobName)
            }
        }else {
            setLastCategoryName('Canceled')
        }
      
    }, [dataCategory.datasets])

    const sumDatasets = (array) => {
        const newArray = [];
        array.forEach(sub => {
            sub.data.forEach((num, index) => {
                if (newArray[index]) {
                    newArray[index] += num;
                } else {
                    newArray[index] = num;
                }
            });
        });
        return newArray;
    }

    if (data && data.datasets && data.datasets.length) {
        const _lastJobName = data.datasets[data.datasets.length-1].label;
        if(lastJobName != _lastJobName) {
            setLastJobName(_lastJobName)
        }
    }

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Type of Job",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalBars > 12 ? "9.5": "14", family: "Poppins", weight: 'bold' },
                // formatter: function (value, context) {
                //     const data = context.chart.data;
                //     let total = 0;
                //     data.datasets.forEach(item => {
                //         const _val = item.data[context.dataIndex];
                //         if (_val > 0) {
                //             total += _val;
                //         }
                //     })
                //     if (context.dataset.label.toUpperCase().includes(lastJobName.toUpperCase())) {
                //         if (total > 0) {
                //             return `${total.toFixed(0)}`;
                //         }else {
                //             return ''
                //         }
                //     }else {
                //         return ''
                //     }
                // },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `${total.toFixed(0)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    const optionsCategory = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                title: {
                    display: true,
                    padding: {
                        top: 10,
                    },
                    color: "#282828",
                    text: "Categories",
                    font: {
                        size: 15,
                        weight: 'bolder'
                    }
                },
                labels: {
                    textAlign: 'left',
                    // pointStyle: 'dash',
                    usePointStyle: false,
                }
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: totalCategoryBars > 12 ? "9.5": "14", family: "Poppins", weight: 'bold' },
                // formatter: function (value, context) {
                //     const data = context.chart.data;
                //     let total = 0;
                //     data.datasets.forEach(item => {
                //         const _val = item.data[context.dataIndex];
                //         if (_val > 0) {
                //             total += _val;
                //         }
                //     })
                //     if (context.dataset.label.toUpperCase().includes(lastCategoryName.toUpperCase())) {
                //         if (total > 0) {
                //             return `${total.toFixed(0)}`;
                //         }else {
                //             return ''
                //         }
                //     }else {
                //         return ''
                //     }
                // },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `${total.toFixed(0)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="card border-card-page col ml-lg-2 mt-lg-0 mt-sm-3 px-0">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                    Jobs
                </label>
            </div>
            <Tabs
                    activeKey={tab}
                    defaultActiveKey="jobs"
                    tabBarPosition="top"
                    onChange={(key) => setTab(key)}
                    renderTabBar={() => <InkTabBar/>}
                    renderTabContent={() => <TabContent/>}
                >
                <TabPane tab="Jobs" key="jobs" className="py-0">
                    {/*-------------------------------------------------*/}
                    {/*--------------------- BODY CARD -----------------*/}
                    {/*-------------------------------------------------*/}
                    <div className="px-0 mx-0 row w-100 pb-2">
                        <div className="col-sm-12">
                            <div className="row justify-content-between">
                                <div className="form-group col-sm-12 col-lg-4 mb-0">
                                    <label className="ml-1 label-semi-bold mt-2">Ranger</label>
                                    <div className="w-100 px-1 mb-2">
                                        <RangerField value={ranger} setValue={(e) => {
                                            console.log("Value: ", e);
                                            setRanger(e, 'show_it');
                                        }} />
                                    </div>
                                </div>
                                <div className="mb-2 col-sm-12 col-lg-4">
                                    <h6 className="mb-0 font-weight-bold text-center">Total Jobs</h6>
                                    <h4 className="font-weight-bolder text-center my-0">
                                        {data.total}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 px-0">
                            <LoadMask loading={loader} dark blur>
                                <div className="p-2 flex-1">
                                    <div className="row justify-content-end mx-auto px-3">
                                        <a
                                            className="pointer flex-row align-items-center row px-0"
                                            style={{ fontSize: "0.7rem" }}
                                            href="#/stats/revenue"
                                        >
                                            View all stats
                                            <i className="material-icons">arrow_right_alt </i>
                                        </a>
                                    </div>
                                    <div className="dashboard-graph">
                                        <Bar data={data} options={options} plugins={[ChartDataLabels]} height={200} />
                                    </div>
                                </div>
                            </LoadMask>
                            {/*----------------------------------------*/}
                            {/*-------   STATS LINE ALL JOB    --------*/}
                            {/*----------------------------------------*/}
                            <div className="w-100 filter-job py-2 mt-3 px-2 dashboard-list-graph">
                                {(data && data.datasets && data.datasets.length > 0) &&
                                    data.datasets.map((job, index) => (
                                        <div
                                            className="mx-auto mb-1 row px-2 py-1 align-items-center text-white text-small-chart"
                                            style={{
                                                backgroundColor: job.borderColor,
                                            }}
                                            key={`key-${index}-agent`}
                                        >
                                            <p className="col-6 px-0 py-0 my-0">
                                                {job.label}
                                            </p>
                                            <p className="col-3 px-0 my-0">
                                                {job.count}&nbsp; {job.count > 1 ? 'Jobs' : 'Job'}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab="Category" key="category" className="py-0">
                    {/*-------------------------------------------------*/}
                    {/*--------------------- BODY CARD -----------------*/}
                    {/*-------------------------------------------------*/}
                    <div className="px-0 mx-0 row w-100 pb-2">
                        <div className="col-sm-12">
                            <div className="row justify-content-between">
                                <div className="form-group col-sm-12 col-lg-4 mb-0">
                                    <label className="ml-1 label-semi-bold mt-2">Ranger</label>
                                    <div className="w-100 px-1 mb-2">
                                        <RangerField value={ranger} setValue={(e) => {
                                            console.log("Value: ", e);
                                            setRanger(e, 'show_it');
                                        }} />
                                    </div>
                                </div>
                                <div className="mb-2 col-sm-12 col-lg-4">
                                    <h6 className="mb-0 font-weight-bold text-center">Total Jobs</h6>
                                    <h4 className="font-weight-bolder text-center my-0">
                                        {dataCategory.total}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-12 px-0">
                            <LoadMask loading={loader} dark blur>
                                <div className="px-2 pb-3 flex-1 dashboard-graph">
                                    <Bar data={dataCategory} options={optionsCategory} plugins={[ChartDataLabels]} height={200} />
                                </div>
                            </LoadMask>
                            {/*----------------------------------------*/}
                            {/*-------   STATS LINE ALL JOB    --------*/}
                            {/*----------------------------------------*/}
                            <div className="w-100 filter-job py-2 mt-3 px-2 dashboard-list-graph">
                                {(dataCategory && dataCategory.datasets && dataCategory.datasets.length > 0) &&
                                    dataCategory.datasets.map((item, index) => (
                                        <div
                                            className="mx-auto mb-1 row px-2 py-1 align-items-center text-white text-small-chart"
                                            style={{
                                                backgroundColor: item.backgroundColor,
                                            }}
                                            key={`key-${index}-agent`}
                                        >
                                            <p className="col-6 px-0 py-0 my-0" style={{color: item.color}}>
                                                {item.label}
                                            </p>
                                            <p className="col-3 px-0 my-0" style={{color: item.color}}>
                                                {item.total}&nbsp; {item.total > 1 ? 'Jobs' : 'Job'}
                                            </p>
                                            {/* <div className="col-3 px-0">
                                                <ReactStars
                                                    count={5}
                                                    value={job.score ? job.score : 0}
                                                    edit={false}
                                                    isHalf={true}
                                                    emptyIcon={<i className="material-icons start-small">star_border</i>}
                                                    halfIcon={<i className="material-icons start-small">star_half</i>}
                                                    filledIcon={<i className="material-icons start-small">star</i>}
                                                    size={15}
                                                    color={'#FFFFFF'}
                                                    activeColor={"#FFFFFF"}
                                                />
                                            </div> */}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            {/* <div className="card-title row border-bottom py-3">
                <label className="col-lg-5 col-sm-12 my-auto label-semi-bold">
                    Jobs
                </label>
            </div> */}
            
        </div>
    );
};


export const useContainerDimensions = myRef => {
    const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
    })

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef])

    return dimensions;
};

const EllipsisText = (props) => {
    const {text} = props;
    const componentRef = useRef()
    const { width, height } = useContainerDimensions(componentRef)
    return (
        <div className="label-small flex-1" ref={componentRef} style={{
            width: width,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}>
            <span>{text}</span>
        </div>
    )
}

export const BrokerOffice = (props) => {
    const {data} = props;
    const [finalData, setFinalData] = useState([]);
    
    useEffect(() => {
        if (data) {
            let _length = 0
            let _finalData = [];
            if (data.brokers) {
                _length = data.brokers.length
            }
            if (data.offices && _length < data.offices.length) {
                _length = data.offices.length
            }
            for (let index = 0; index < _length; index++) {
                let item = {}
                if(data.brokers) {
                    item["broker"] = index <= data.brokers.length - 1 ? {
                        name: `${data.brokers[index].owner_first_name} ${data.brokers[index].owner_last_name}`,
                        total: data.brokers[index].total_fee
                    } : null 
                }
                if (data.offices) {
                    item["office"] = index <= data.offices.length - 1 ? {
                        name: `${data.offices[index].firm_name}`,
                        total: data.offices[index].total_fee
                    } : null 
                }
                _finalData.push(item)
            }
            setFinalData(_finalData)
        }else {
            setFinalData([])
        }
    }, [data])
    

    return (
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mt-3" style={{height: 'inherit'}}>
            <div className="card border-card-page border-card-20 py-4 h-100">
                <div className="d-flex flex-row align-items-center ">
                    <div
                        className="px-1 px-lg-2 d-flex flex-column">
                        <div className="w-100 border-bottom py-2 d-flex flex-row align-items-center">
                            <span className="text-12 font-weight-bold" style={{ width: '60px' }}>
                                Signed Brokers
                            </span>
                            <span className="h6 mb-0 pl-2">
                                {data && data.total_brokers ? data.total_brokers : 0}
                            </span>
                        </div>
                        <div className="w-100 py-2 d-flex flex-row align-items-center">
                            <span className="text-12 font-weight-bold" style={{ width: '60px' }}>
                                Signed Offices
                            </span>
                            <span className="h6 mb-0 pl-2">
                                {data && data.total_offices ? data.total_offices : 0}
                            </span>
                        </div>
                    </div>
                    <div
                        className="flex-1 d-flex flex-column px-2" style={{ overflow: 'hidden', position: 'relative' }}>
                        <table>
                            <thead className="border-bottom">
                                <tr>
                                    <th className="text-center" scope="col"><span className="label-small w-100 text-center">Top 5 Brokers</span></th>
                                    <th className="text-center" scope="col"><span className="label-small w-100 text-center">Top 5 Offices</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {finalData.map((item, index) => {
                                    return (
                                        <tr>
                                            <th className="border-right" scope="row">
                                                {item.broker ? (
                                                    <div
                                                        key={index}
                                                        className="d-flex flex-row align-items-center px-1"
                                                    >
                                                        <span
                                                            className="label-small"
                                                            style={{
                                                                width: "20px",
                                                            }}
                                                        >
                                                            {index + 1}.
                                                        </span>
                                                        <EllipsisText
                                                            text={
                                                                item.broker.name
                                                            }
                                                        />
                                                        <span
                                                            className="text-right label-small"
                                                            style={{
                                                                minWidth:
                                                                    "40px",
                                                                paddingLeft:
                                                                    "5px",
                                                            }}
                                                        >
                                                            $
                                                            {item.broker.total.toFixed(
                                                                2
                                                            )}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div
                                                        key={index}
                                                        className=""
                                                    ></div>
                                                )}
                                            </th>
                                            <td className="border-left">
                                                {item.office ? (
                                                    <div
                                                        key={index}
                                                        className="d-flex flex-row align-items-center px-1"
                                                    >
                                                        <span
                                                            className="label-small"
                                                            style={{
                                                                width: "20px",
                                                            }}
                                                        >
                                                            {index + 1}.
                                                        </span>
                                                        <EllipsisText
                                                            text={
                                                                item.office.name
                                                            }
                                                        />
                                                        <span
                                                            className="text-right label-small"
                                                            style={{
                                                                minWidth:
                                                                    "40px",
                                                                paddingLeft:
                                                                    "5px",
                                                            }}
                                                        >
                                                            $
                                                            {item.office.total.toFixed(
                                                                2
                                                            )}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div
                                                        key={index}
                                                        className=""
                                                    ></div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {/* <div className="d-flex flex-row align-items-center  border-bottom">
                            <div className="flex-1 text-center border-right label-small p-1">
                                Top 5 Brokers
                            </div>
                            <div className="flex-1 text-center label-small p-1">
                                Top 5 Offices
                            </div>
                        </div>
                        <div className="d-flex flex-row" style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
                            <div className="flex-1 border-right pt-2 pb-0 px-1 px-lg-2" style={{ height: 'inherit' }}>
                                {data.brokers.map((item, index) => (
                                    <div key={index} className="d-flex flex-row align-items-center">
                                        <span className="label-small" style={{ width: '20px' }}>{index + 1}.</span>
                                        <EllipsisText text={`${item.owner_first_name} ${item.owner_last_name}`}/>
                                        <span className="text-right label-small" style={{ minWidth: '40px', paddingLeft: '5px' }}>${item.total_fee.toFixed(2)}</span>
                                    </div>
                                ))}
                            </div>
                            <div className="flex-1 label-small pt-2 pb-0 px-1 px-lg-2" style={{ height: 'inherit' }}>
                                {data.offices.map((item, index) => (
                                    <div key={index} className="d-flex flex-row align-items-center">
                                        <span className="label-small" style={{ width: '20px' }}>{index + 1}.</span>
                                        <EllipsisText text={`${item.firm_name}`}/>
                                        <span className="text-right label-small" style={{ minWidth: '40px', paddingLeft: '5px' }}>${item.total_fee.toFixed(2)}</span>
                                    </div>
                                ))}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}