import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/influencer';
import InfluencerCreateUpdate from "./InfluencerCreateUpdate";

const ms2p = (state) => {
    return{
        ...state.influencer,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(InfluencerCreateUpdate);