import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderField,
    SelectField,
    renderSwitch
} from "../../Utils/renderField/renderField";

const validate = (values, props) => {
    let errors = {};
    const {is_update, item} = props;

    if (!values.value) {
        errors.value = "This field is required";
    }
    if (!values.label) {
        errors.label = "This field is required";
    }
    if (!values.index) {
        errors.index = "This field is required";
    }
    if (!values.type) {
        errors.type = "This field is required";
    }

    return errors;
};

const Form = (props) => {
    //  State
    const { handleSubmit, is_update, item, typeValue } = props;
    //  Bind
    const { goBack } = props;

    // console.log("ITEM: ", item);

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto mt-3 w-75">
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="label">Text</label>
                    <Field
                        name="label"
                        label="label"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="value">Value</label>
                    <Field
                        name="value"
                        label="value"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={is_update}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="index">Index</label>
                    <Field
                        name="index"
                        label="index"
                        component={renderField}
                        type="number"
                        className="form-control"
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="type">Type</label>
                    <Field
                        name="type"
                        id="type"
                        placeholder="Select..."
                        options={[
                            { value: 100, label: "Cancellation" },
                            { value: 200, label: "Delete Agent Account" },
                            { value: 300, label: "Vendor Cancellation" },
                        ]}
                        component={SelectField}
                        disabled={false}
                        type="text"
                        className="form-control"
                    />
                </div>
                {typeValue != 300 && (
                    <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12 pl-4">
                        <label htmlFor="reason_required" className="mt-4 mb-3">Reason is required?</label>
                        <Field
                            name="reason_required"
                            label=""
                            component={renderSwitch}
                            type="checked"
                        />
                    </div>
                )}
            </div>
            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <button
                    onClick={goBack}
                    type="button"
                    className="btn text-white btn-secondary  mx-3 col-2"
                >
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary mx-3 col-2">
                    Save
                </button>
            </div>
        </form>
    );
};

let CancellationDeleteForm = reduxForm({
    form: "cancellationDeleteOptionForm", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector("cancellationDeleteOptionForm");
CancellationDeleteForm = connect((state) => {
    const typeValue = selector(state, "type");
    return {
        typeValue,
    }

})(CancellationDeleteForm)

export default CancellationDeleteForm
