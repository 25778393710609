import _ from 'lodash'
import moment from 'moment'
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {api} from '../../../../utility/api';
import React, {useState, useEffect} from 'react';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {REGISTERED_RE_ASSIGN} from '../../../../utility/constants'
import {renderFilePicker} from "../../Utils/renderField/renderField";
import {renderField, renderNumber, renderSwitch, renderTextArea, renderAddressField, SelectField} from '../../Utils/renderField';

const validate = (values, props) => {
    const {pendingWholeSaler} = props;
    let errors = {};
    if (!values.first_name) {
        errors.first_name = "This field is required"
    }
    if (!values.last_name) {
        errors.last_name = "This field is required"
    }
    if (!values.email) {
        errors.email = "This field is required"
    }
    if (!values.mobile_number) {
        errors.mobile_number = "This field is required"
    }else {
        if (values.mobile_number.length != 10){
            errors.mobile_number = "Invalid phone number"
        }
    }
    if (!values.street && !pendingWholeSaler) {
        errors.street = "This field is required"
    }

    return errors;
}

const UpdateForm = (props) => {

    const {
        handleSubmit,
        item,
        setAvatar,
        pendingWholeSaler,
        remove,
        teams=[],
        goBack,
        goPage,
        team_idValue,
    } = props;

    const [teamInfo, setTeamInfo] = useState(null);

    useEffect(() => {
        if (team_idValue != "" && team_idValue != null && team_idValue != null)
        {
            api.get(`influencer_team/${team_idValue}`, {from_agent: true}).then(response => {
                setTeamInfo(response);
            }).catch(error => {
                setTeamInfo(null);
            })
        } else {
            setTeamInfo(null)
        }
    }, [team_idValue])

    return (
        <form name="wholeSalerForm" className="my-1 col-sm-12 p-sm-0" style={{minHeight: "20rem"}}
              onSubmit={handleSubmit}>
            <div className="row mx-auto pt-2">
                <div className='col-xl-3 col-lg-3 col-md-5 col-sm-12'>
                    <div className="form-group has-feedback">
                        <Field
                            photo={item.photo ? item.photo : null}
                            setFile={setAvatar}
                            name="photo"
                            component={renderFilePicker}
                            disabled={pendingWholeSaler}
                            className="avatar-round"
                        />
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Sign-up Date</span>
                        <span className="font-weight-bolder text-primary">{moment(item.date_joined).format('ll')}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-5'>
                        <span className="font-weight-bolder h6 mb-1">Status</span>
                        {item.blocked === true ? (
                            <span className={`text-danger font-weight-bold`}>
                            Blocked
                            </span>
                        ): (
                            item.locked === true ? (
                                <span className={`text-danger font-weight-bold`}>
                                    Locked
                                </span>
                            ) : (
                                <React.Fragment>
                                    {item.agent_account_status == 10 && (
                                        <span className="text-warning font-weight-bold">Pending</span>
                                    )}
                                    {item.agent_account_status == 20 && (
                                        <span className="text-success font-weight-bold">Active</span>
                                    )}
                                </React.Fragment>
                            )

                        )}
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Job Posted</span>
                        <span className="font-weight-bolder text-primary">{item.total_posted}</span>
                    </div>
                </div>
                <div className='col-xl-9 col-lg-9 col-md-7 col-sm-12'>
                    <div className='row'>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="first_name">First name</label>
                            <Field
                                name="first_name"
                                label="First name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="last_name">Last Name</label>
                            <Field
                                name="last_name"
                                label="Last Name"
                                type="text"
                                component={renderField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="mobile_number">Mobile number</label>
                            <Field
                                name="mobile_number"
                                component={renderNumber}
                                className="form-control"
                                placeholder="mobile number"
                                numberFormat={"###-###-####"}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="email">Email</label>
                            <Field
                                name="email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                            />
                        </div>
                        <div className={`form-group has-feedback col-lg-6 col-md-12 col-sm-12`}>
                            <label htmlFor="brokerage_id">Team</label>
                            <Field
                                name="team_id"
                                isMulti={false}
                                placeholder="Select Team"
                                options={teams}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="influencer">Influencer</label>
                            <div className='form-control'>
                                {teamInfo && teamInfo.influencer ? `${teamInfo.influencer.first_name} ${teamInfo.influencer.last_name}`: ''}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-md-12 col-sm-12">
                            <label htmlFor="street">Address</label>
                            <Field
                                name="street"
                                label="street"
                                placeholder='Search an address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className="form-group col-lg-6 col-md-12 col-sm-12 mb-0">
                            <label className="mt-n4 d-block" style={{ fontSize: '1.05rem' }}> Account Settings</label>
                            <div className="row w-100 m-0 h-100">
                                <div className='rounded border col-sm-12'>
                                    <div className='row'>
                                        <div className="form-group has-feedback col-sm-12">
                                            <label htmlFor="is_active" className="mt-4 d-block ml-3">Password</label>
                                            {(pendingWholeSaler) ?
                                                <button
                                                    className="btn button-03 ml-3 px-4"
                                                    disabled={true}
                                                    style={{ hover: 'none' }}
                                                >
                                                    Change Password
                                                </button> :
                                                <Link
                                                    className="btn button-03 ml-3 px-4"
                                                    to={`/wholesalers/wholesaler/${item.id && item.id}/edit/changed-password`}
                                                >
                                                    Change Password
                                                </Link>
                                            }
                                        </div>

                                        <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12 pl-4">
                                            <label htmlFor="locked" className="mt-4 mb-3">Lock account</label>
                                            <Field
                                                name="locked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={pendingWholeSaler || item.agent_account_status == REGISTERED_RE_ASSIGN}
                                            />
                                        </div>
                                        <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12 pl-4">
                                            <label htmlFor="blocked" className="mt-4 mb-3">Block account</label>
                                            <Field
                                                name="blocked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={pendingWholeSaler || item.agent_account_status == REGISTERED_RE_ASSIGN}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-auto mt-5 justify-content-center align-items-center form-group has-feedback">
                <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                    <button type="button" onClick={goBack} className={`w-100 btn text-white btn-secondary`}>
                        Back
                    </button>
                </div>
                {!pendingWholeSaler ? (
                    <React.Fragment>
                        <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                            <button type="submit" className="w-100 btn btn-primary">
                                Update
                            </button>
                        </div>
                        { item && item.id &&
                        <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                            <button type="button" onClick={(e) => remove(item.id)} className="w-100 btn btn-danger">
                                Delete
                            </button>
                        </div>
                        }
                    </React.Fragment>
                ): (
                    <React.Fragment>
                        <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                            <button type="submit" className="w-100 btn btn-primary">
                                Activate
                            </button>
                        </div>
                        { item && item.id &&
                        <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                            <button type="button" onClick={(e) => remove(item.id)} className="w-100 btn btn-danger">
                                Delete
                            </button>
                        </div>
                        }
                    </React.Fragment>
                )}
            </div>
        </form>
    );
}

let WholeSalerForm = reduxForm({
    form: 'wholeSalerForm', // a unique identifier for this form
    validate
})(UpdateForm);

const selector = formValueSelector('wholeSalerForm');

const mstp = state => {
    const team_idValue = selector(state, 'team_id');

    return {
        team_idValue,
    }
}

export default connect(mstp, null)(WholeSalerForm);
