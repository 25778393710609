import React, {Component} from 'react';
import PropTypes from "prop-types";
import BrokerageGrid from "./BrokerageGrid";

class Brokerage extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
    };

    constructor(props) {
        super(props)
        this.state = {
            is_view: false,
            is_edit: false,
            valueFile: '',
        };
    }

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const {location} = this.props;
        let brokerId = null;
        const searchParams = new URLSearchParams(location.search)
        const _brokerId = searchParams.get('broker')
        if (_brokerId) {
            brokerId = parseInt(_brokerId)
        }
        if (_brokerId !== null) {
            this.props.resetForm()
            setTimeout(() => {
                this.props.getFranchisesOption(null, true);
                this.props.getBrokerOption(true, true, brokerId);
                // setTimeout(() => {
                //     this.props.newToList();
                // }, 600);
            }, 400);
        }else {
            this.props.getFranchisesOption(null, true);
            this.props.getBrokerOption(null, true, brokerId);
            setTimeout(() => {
                this.props.newToList();
            }, 400);
        }
    }

    onChanged = (e) => {
        const {onChange, setFiles} = this.props;
        this.setState({ valueFile: e.target.files[0].name });
        onChange(e.target.files[0]);
        setFiles(e.target.files[0]);
    };

    render() {
        const {
            loader,
            data,
            brokerName,
            searchChange,
            customSearchChange,
            setFiles,
            toList,
            match,
            newToList,
            removeBrokerage,
            setValueIdBroker,
            optionBroker,
            goToAgents,
            changeFilters,
            brokers,
            franchises,
            onSortChange,
        } = this.props;

        const searchAction = (value) => {
            if (match.params.id) {
                customSearchChange(value);
            } else {
                searchChange(value);
            }
        }
        return (
            <BrokerageGrid
                remove={removeBrokerage}
                brokerName={brokerName}
                text={this.state.valueFile}
                handleOnChange={this.onChanged}
                loader={loader}
                data={data}
                setFiles={setFiles}
                dataBroker={optionBroker}
                actionFilter1={setValueIdBroker}
                searchAction={searchAction}
                toList={newToList}
                is_view={this.state.is_view}
                is_edit={this.state.is_edit}
                goToAgents={goToAgents}
                changeFilters={changeFilters}
                optionBrokers={brokers}
                optionFranchises={franchises}
                onSortChange={onSortChange}
            />
        )
    }
}

export default Brokerage;
