import Swal from 'sweetalert2';
import React, {Component} from 'react';
import {ProfessionsView}  from './VendorProfessionsComponents'
import LoadMask from "../../../Utils/LoadMask/LoadMask";

class VendorProfessions extends Component {
    componentDidMount() {
        const {match, readVendorProfessions, getProfessions} = this.props;
        if (match.params.id) {
            readVendorProfessions(match.params.id);
        }

        getProfessions()
    }

    submit = (data) => {
        const {item} = this.props;
    }

    render() {
        //  State
        const {loader, item, professions, professionsData} = this.props;
        //  Bind
        const {addProfession, removeProfession, approveProfession, rejectProfession} = this.props;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={() => this.props.history.goBack()}>
                                <i className="material-icons pointer">arrow_back</i> 
                            </button>
                            Vendor Expertise
                        </div>
                    </div>
                </div>
                <div
                    className="card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <ProfessionsView
                            item={item}
                            initialValues={professionsData}
                            optionProfessions={professions}
                            loader={loader}
                            goBack={()=> this.props.history.goBack()}
                            addProfession={addProfession}
                            removeProfession={removeProfession}
                            approveProfession={approveProfession}
                            rejectProfession={rejectProfession}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default VendorProfessions;
