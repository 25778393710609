import { connect } from 'react-redux'
import DeletedAgentList from './DeletedAgentList'
import {actions} from '../../../../../redux/modules/deleted/agents'

const mapStateToProps = (state) => ({
    ...state.deletedAgent
})

const mapDispatchToProps = {
    ...actions,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletedAgentList)