import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/twilio/app_version";
import AppVersionList from './AppVersionList';


const ms2p = (state) => {
    return {
        ...state.twilioAppVersion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AppVersionList);