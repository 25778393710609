import _ from "lodash";
import { api } from "api";
import Swal from 'sweetalert2';
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import {SELECT_ALL_OPTION} from '../../../utility/constants'
import { change, initialize as initializeForm } from "redux-form";



// ------------------------------------
// Constants
// ------------------------------------
const endpoint = 'refer'
const LOADER = 'BROKER_REFERRAL_LOADER'
const DATA = 'BROKER_REFERRAL_DATA'


// ------------------------------------
// Pure Actions
// ------------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
})
const setData = data => ({
    type: DATA,
    data,
})

//-------------------------------------
// Base reducer
//-------------------------------------
// const basicReducer = createReducer(
//     "brokerReferral",
//     endpoint,
//     "brokerReferralForm",
//     "/broker-referral"
// );

// ------------------------------------
// Actions
// ------------------------------------

const getBrokerReferral = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const params = { id };
    api.get(`${endpoint}/${id}/info_broker_referral`, params)
        .then((response) => {
            const {total_agents} = response;
            dispatch(setData(response));
            dispatch(initializeForm("brokerReferralForm", {total_agents}));
        })
        .catch((error) => {
            let msg = "It was not possible to get the broker referral data."
            if (error && error.detail) {
                msg = error.detail
            }
            NotificationManager.error(msg, 'Error', 2500);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}

const transferBrokerReferral = (id, data) => (dispatch, getStore) => {
    Swal.fire({
        title: 'Are you sure to transfer the broker referral?',
        text: "This action can't be reverted!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, Transfer!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(true));
            api.put(`${endpoint}/${id}/transfer_broker_referral`, data)
                .then((response) => {
                    NotificationManager.success('Broker referral transferred successfully', 'Success', 2500);
                    dispatch(push("/"));
                })
                .catch((error) => {
                    console.log("error: ", error);
                    let msg = "It was not possible to transfer the broker referral."
                    if (error && error.detail) {
                        msg = error.detail
                    }
                    NotificationManager.error(msg, 'Error', 2500);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    });


}


export const actions = {
    getBrokerReferral,
    transferBrokerReferral,
}

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADER]: (state, { loader }) => ({ ...state, loader }),
    [DATA]: (state, { data }) => ({ ...state, data }),
}

// ------------------------------------
// InitialState
// ------------------------------------
const initialState = {
    loader: false,
    data: {},
}
export default handleActions(reducers, initialState);
