import React, { useRef, useState, useEffect } from "react";
import { GOOGLE_MAPS_KEY } from "../../../../utility/constants";
import { MAPS_THEME } from "../../../../utility/mapsTheme";
import GoogleMap from "google-map-react";
import moment from "moment"
import momentTz from "moment-timezone"
import _ from "lodash";

// const Map = ({ children, map }) => {
//     return (
//         <React.Fragment>
//             {React.Children.map(children, (child) => {
//                 if (React.isValidElement(child)) {
//                     // set the map prop on the child component
//                     return React.cloneElement(child, { map });
//                 }
//             })}
//         </React.Fragment>
//     );
// };

// const Marker = (options) => {
//     const [marker, setMarker] = useState();
//     const [markerSize, setMarkerSize] = useState();

//     useEffect(() => {
//         if (!marker) {
//             setTimeout(() => {
//                 setMarkerSize(new google.maps.Size(20, 20));
//                 setMarker(new google.maps.Marker());
//             }, 800);
//         }

//         // remove marker from map on unmount
//         return () => {
//             if (marker) {
//                 marker.setMap(null);
//             }
//         };
//     }, [marker]);

//     useEffect(() => {
//         if (marker) {
//             const {urlIcon} = options;
//             if (urlIcon) {
//                 const icon = {
//                     url: urlIcon,
//                     size: markerSize
//                 }
//                 const shape = {
//                     coords: [1, 1, 1, 20, 18, 20, 18, 1],
//                     type: "poly",
//                   };
//                 marker.setOptions({...options, icon: icon, shape});
//             }else {
//                 marker.setOptions(options);
//             }
//         }
//     }, [marker, options]);

//     return null;
// };

// const AnyReactComponent = ({ text }) => <div>{text}</div>;
const JobTooltip = (props) => {
    //  State
    const {data} = props
    return (
        <div className="card tooltip_card d-flex flex-column">
            <div
                className="d-flex flex-row"
                style={{ backgroundColor: data.bgColor }}
            >
                <div className="d-flex flex-column p-2" style={{ flex: 2.5 }}>
                    <div className="d-flex flex-row align-items-center my-2">
                        <div className="flex-1 d-flex flex-row align-items-center text-dark">
                            <span
                                className="flex-1 m-0 font-weight-bold text-center text-uppercase rounded bg-white p-1"
                                style={{ color: data.primaryColor }}
                            >
                                {data.title}
                            </span>
                            <span className="mx-2 font-weight-normal text-white">
                                {moment(data.created).format("lll")}
                            </span>
                        </div>
                        {/* <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                # {data.id}
                            </p>
                        </div> */}
                    </div>
                    <div className="d-flex flex-row my-1">
                        <div className="flex-1 d-flex flex-column justify-content-center text-white">
                            {data.addresses &&
                                data.addresses.map((address, index) => {
                                    return (
                                        <p
                                            key={index}
                                            className="m-0 font-weight-bold text-break"
                                        >
                                            {address}
                                        </p>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">Applied: </p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                {data.applied_date
                                    ? moment(data.applied_date).format("lll")
                                    : ""}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">
                                Hired/Claimed:{" "}
                            </p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                {data.hired_claimed_date
                                    ? moment(data.hired_claimed_date).format(
                                          "lll"
                                      )
                                    : ""}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">Start Time: </p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                {moment(data.date).format("ll")}{" "}
                                {data.hiredSetTime
                                    ? momentTz
                                          .tz(data.dateStart, data.timezone)
                                          .format("h:mm A")
                                    : "Not Set"}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column p-2 text-white align-items-center justify-content-between"
                    style={{
                        flex: 1.5,
                        borderLeftColor: "#FFF",
                        borderLeftWidth: "1px",
                        borderLeftStyle: "dotted",
                    }}
                >
                    <div className="w-100 d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">
                                {data.status_text}
                            </p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold"># {data.id}</p>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">Job Fee</p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                $ {data.job_fee ? data.job_fee : "0.00"}
                            </p>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">
                                Increase Service Fee
                            </p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                ${" "}
                                {data.additional_fee
                                    ? data.additional_fee
                                    : "0.00"}
                            </p>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">
                                Job Processing Fee
                            </p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                ${" "}
                                {data.processing_fee
                                    ? data.processing_fee
                                    : "0.00"}
                            </p>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">Stripe Fee</p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                $ {data.stripe_fee ? data.stripe_fee : "0.00"}
                            </p>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row">
                        <div className="flex-1 text-white">
                            <p className="m-0 font-weight-bold">Total Charge</p>
                        </div>
                        <div className="text-white pl-2">
                            <p className="m-0 font-weight-bold">
                                $ {data.charge_fee ? data.charge_fee : "0.00"}
                            </p>
                        </div>
                    </div>
                    {/* <div className="m-0 font-weight-bold">
                        <p className="m-0 text-center"># {data.id}</p>
                    </div>
                    <div className="m-0 font-weight-bold">
                        <p className="m-0">
                            {data.hiredSetTime ? momentTz.tz(data.dateStart, data.timezone).format("h:mm A") : "Not Set"}
                        </p>
                    </div>
                    <div className="m-0 font-weight-bold">
                        <p className="m-0">
                            {moment(data.date).format("MMM YY")}
                        </p>
                    </div> */}
                    {/* <div className="m-0 font-weight-bold">
                        <p className="m-0 text-uppercase text-break">
                            {data.status_text}
                        </p>
                    </div> */}
                </div>
            </div>
            <div
                className="d-flex flex-row"
                style={{ backgroundColor: "#D9D9D9" }}
            >
                <div className="flex-1 d-flex flex-column tooltip_border_right">
                    <div className="d-flex flex-row px-2 py-1 text-dark align-items-center tooltip_border_buttom">
                        <span className="font-weight-bold flex-1">
                            Arrival Confirmation:
                        </span>
                        <span className="font-weight-bold text-right">
                            {data.confirm_arrival_date
                                ? moment(data.confirm_arrival_date).format(
                                      "MMM DD, h:mm A"
                                  )
                                : ""}
                        </span>
                    </div>
                    <div className="d-flex flex-row px-2 py-1 text-dark">
                        <span className="font-weight-bold flex-1">
                            Installation Confirmation:
                        </span>
                        <span className="font-weight-bold text-right">
                            {data.confirm_install_date
                                ? moment(data.confirm_install_date).format(
                                      "MMM DD, h:mm A"
                                  )
                                : ""}
                        </span>
                    </div>
                </div>
                <div className="flex-1 d-flex flex-column">
                    <div className="d-flex flex-row px-2 py-1 text-dark align-items-center tooltip_border_buttom">
                        <span className="font-weight-bold flex-1">
                            Departure Confirmation:
                        </span>
                        <span className="font-weight-bold text-right">
                            {data.confirm_departure_date
                                ? moment(data.confirm_departure_date).format(
                                      "MMM DD, h:mm A"
                                  )
                                : ""}
                        </span>
                    </div>
                    <div className="d-flex flex-row px-2 py-1 text-dark">
                        <span className="font-weight-bold flex-1">
                            Drop-off Confirmation:
                        </span>
                        <span className="font-weight-bold text-right">
                            {data.confirm_drop_off_date
                                ? moment(data.confirm_drop_off_date).format(
                                      "MMM DD, h:mm A"
                                  )
                                : ""}
                        </span>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row pb-2">
                <div className="flex-1 d-flex flex-column px-2">
                    <p className="m-0 font-weight-bold text-uppercase text-dark my-2">
                        Hiring Agent
                    </p>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="text-dark pr-2">
                            <i className="fa fa-user"></i>
                        </div>
                        <div className="flex-1 text-dark">
                            <p className="m-0 font-weight-bold text-break">
                                {data.hiring_agent.first_name}{" "}
                                {data.hiring_agent.last_name}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="text-dark pr-2">
                            <i className="fa fa-envelope"></i>
                        </div>
                        <div className="flex-1 text-dark">
                            <p className="m-0 font-weight-bold text-break">
                                {data.hiring_agent.email}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="text-dark pr-2">
                            <i className="fa fa-phone"></i>
                        </div>
                        <div className="flex-1 text-dark">
                            <p className="m-0 font-weight-bold text-break">
                                {data.hiring_agent.mobile_number}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="text-dark pr-2">
                            <i className="fa fa-home"></i>
                        </div>
                        <div className="flex-1 text-dark">
                            <p className="m-0 font-weight-bold text-break">
                                {data.hiring_agent.firm_name}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="text-dark pr-2">
                            <i className="fa fa-briefcase"></i>
                        </div>
                        <div className="flex-1 text-dark">
                            <p className="m-0 font-weight-bold text-break">
                                {data.hiring_agent.franchise}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mb-1">
                        <div className="text-dark pr-2">
                            <i className="fa fa-cog"></i>
                        </div>
                        <div className="flex-1 text-dark">
                            <p className="m-0 font-weight-bold text-break">
                                {data.hiring_agent.operation_mode}
                            </p>
                        </div>
                    </div>
                </div>
                {data.performing_agent && (
                    <div className="flex-1 d-flex flex-column px-2">
                        <p className="m-0 font-weight-bold text-uppercase text-dark my-2">
                            Performing Agent
                        </p>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <div className="text-dark pr-2">
                                <i className="fa fa-user"></i>
                            </div>
                            <div className="flex-1 text-dark">
                                <p className="m-0 font-weight-bold text-break">
                                    {data.performing_agent.first_name}{" "}
                                    {data.performing_agent.last_name}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <div className="text-dark pr-2">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div className="flex-1 text-dark">
                                <p className="m-0 font-weight-bold text-break">
                                    {data.performing_agent.email}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <div className="text-dark pr-2">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div className="flex-1 text-dark">
                                <p className="m-0 font-weight-bold text-break">
                                    {data.performing_agent.mobile_number}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <div className="text-dark pr-2">
                                <i className="fa fa-home"></i>
                            </div>
                            <div className="flex-1 text-dark">
                                <p className="m-0 font-weight-bold text-break">
                                    {data.performing_agent.firm_name}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <div className="text-dark pr-2">
                                <i className="fa fa-briefcase"></i>
                            </div>
                            <div className="flex-1 text-dark">
                                <p className="m-0 font-weight-bold text-break">
                                    {data.performing_agent.franchise}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-1">
                            <div className="text-dark pr-2">
                                <i className="fa fa-cog"></i>
                            </div>
                            <div className="flex-1 text-dark">
                                <p className="m-0 font-weight-bold text-break">
                                    {data.performing_agent.operation_mode}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const CustomMarker = (props) => {
    //  State
    const { bgColor, icon, title, activeJobMarker, data } = props;
    //  Bind
    const {setActiveJobMarker} = props;

    const markerRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (markerRef.current && !markerRef.current.contains(event.target)) {
                setActiveJobMarker(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [markerRef])

    return (
        <div ref={markerRef}>
            <div
                style={{
                    position: "relative",
                    width: "35px",
                    height: "35px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: bgColor,
                    borderRadius: "50%",
                }}
                data-bs-toggle="tooltip"
                // title={title}
                onClick={(e) => {
                    e.preventDefault();
                    // console.log("CLICK: ", data);
                    const id = data && data.id ? data.id : null;
                    setActiveJobMarker(id != activeJobMarker ? id : null);
                }}
            >
                <div
                    style={{
                        width: "27px",
                        height: "27px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#FFF",
                        borderRadius: "50%",
                    }}
                >
                    <img
                        style={{ backgroundColor: "transparent" }}
                        width={15}
                        height={15}
                        className="img-fluid"
                        src={icon}
                        alt="icon"
                        // title={title}
                    />
                </div>
            </div>
            {data && data.id == activeJobMarker && <JobTooltip data={data} />}
        </div>
    );
};

const AgentTooltip = (props) => {
    //  State
    const {data} = props
    // console.log("DATA: ", data);
    return (
        <div className="card tooltip_agent_card d-flex flex-column">
            <div
                className="d-flex flex-column p-2"
                style={{ backgroundColor: "#EEEEEE" }}
            >
                <div className="flex-3 d-flex flex-row text-dark mb-1">
                    <i className="fa fa-id-card mr-2"></i>
                    <span className="font-weight-bold text-break flex-1">
                        {data.name}
                    </span>
                </div>
                <div className="flex-3 d-flex flex-row text-dark mb-1">
                    <i className="fa fa-home mr-2"></i>
                    <span className="font-weight-bold text-break flex-1">
                        {data.firm_name}
                    </span>
                </div>
                <div className="flex-3 d-flex flex-row text-dark mb-1">
                    <i className="fa fa-briefcase mr-2"></i>
                    <span className="font-weight-bold text-break flex-1">
                        {data.franchise}
                    </span>
                </div>
                <div className="flex-3 d-flex flex-row text-dark">
                    <i className="fa fa-cog mr-2"></i>
                    <span className="font-weight-bold text-break flex-1">
                        {data.operation_mode}
                    </span>
                </div>
            </div>
        </div>
    );
}

const AgentMarker = (props) => {
    //  State
    const { data, activeAgentMarker } = props;
    
    //  Bind
    const {setActiveAgentMarker} = props;
    return (
        <div>
            <div
                style={{
                    position: "relative",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "orange",
                    borderRadius: "50%",
                }}
                data-bs-toggle="tooltip"
                // title={data.name}
                onClick={(e) => {
                    e.stopPropagation();
                    const id = data && data.id ? data.id : null;
                    setActiveAgentMarker(id != activeAgentMarker ? id : null);
                }}
            >
                <div
                    style={{
                        width: "22px",
                        height: "22px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#FFF",
                        borderRadius: "50%",
                    }}
                >
                    {/* <i class="fa fa-map-marker fa-3x" aria-hidden="true"></i> */}
                    <i className="material-icons m-0 p-0" style={{color: 'orange', fontSize: "20px", top: 0}}>person</i>
                </div>
            </div>
            {data && data.id == activeAgentMarker && <AgentTooltip data={data} />}
        </div>
    );
};

const ColorIdicator = () => {
    return (
        <div className="card card-color-indicator p-2 d-flex flex-column">
            <div className="d-flex flex-row align-items-center">
                <div className="indicador-color" style={{backgroundColor: "#757575"}}>

                </div>
                <div className="ml-2 font-weight-bold text-dark">
                    Posted
                </div>
            </div>
            <div className="d-flex flex-row align-items-center">
                <div className="indicador-color" style={{backgroundColor: "#82B1FF"}}>

                </div>
                <div className="ml-2 font-weight-bold text-dark">
                    Applied
                </div>
            </div>
            <div className="d-flex flex-row align-items-center">
                <div className="indicador-color" style={{backgroundColor: "#2196F3"}}>

                </div>
                <div className="ml-2 font-weight-bold text-dark">
                    Hired / Claimed
                </div>
            </div>
        </div>
    )
}

const AgentJobMap = (props) => {
    const {
        center = {
            lat: 38.2568939,
            lng: -100.2780367,
        },
        jobs = [],
        agents= [],
    } = props;

    // console.log("AGENTS: ", agents);

    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(5.45); // initial zoom
    const [markers, setMarkers] = useState([]);
    const [activeJobMarker, setActiveJobMarker] = useState(null);
    const [activeAgentMarker, setActiveAgentMarker] = useState(null);
    
    useEffect(() => {
        if (jobs && jobs.length) {
            let _markers = [];
            jobs.forEach((job, index) => {
                const addresses =
                    job && job.job_description && job.job_description.length
                        ? job.job_description
                        : [];
                let icon = "";
                let bgColor = job.background_color  ? job.background_color : "#CECECE";
                let primaryColor = "#CECECE";
                let title = "";
                if (job && job.type_data) {
                    const type_data = job.type_data;
                    if (type_data.job_type) {
                        icon = type_data.job_type.icon;
                        primaryColor = type_data.job_type.primary_color
                        title = type_data.job_type.name
                    }
                    if (type_data.job_type_option) {
                        icon = type_data.job_type_option.icon;
                        primaryColor = type_data.job_type_option.primary_color
                        title = type_data.job_type_option.name
                    }
                }
                if (addresses.length) {
                    addresses.forEach((address, idx) => {
                        let _center = address
                            ? {
                                  lat: parseFloat(address.latitude.toFixed(7)),
                                  lng: parseFloat(address.longitude.toFixed(7)),
                              }
                            : null;

                        // console.log("CENTER: ", address);
                        if (_center) {
                            _center = getCenter(
                                _center.lat,
                                _center.lng,
                                _markers
                            );
                            _markers.push({
                                ..._center,
                                icon,
                                bgColor,
                                title,
                                data: {
                                    ...job,
                                    bgColor,
                                    title,
                                    icon,
                                    primaryColor,
                                    addresses: addresses.map(addressItem => {
                                        return addressItem.address
                                    }),
                                    address: address.address,
                                    dateStart: address.date_start,
                                    timezone: address.timezone,
                                    hiredSetTime: job.hired_appointments == true ? job.hired_appointments && !address.hired_set_time : true,
                                },
                            });
                        }
                    })
                }
            })
            setMarkers(_markers)
        }else {
            setMarkers([])
        }
    }, [jobs]);

    const getCenter = (lat, lng, _markers) => {
        const center = {lat, lng}
        const similarAddress = _.find(_markers, {lat, lng})
        if (similarAddress) {
            let R = 6378137;
            let dn = 3;
            let de = 0;
            let dLat = dn / R;
            let dLon = de / (R * Math.cos((Math.PI * lat) / 180));
            const _center = {
                lat: lat + dLat * (180 / Math.PI),
                lng: lng + dLon * (180 / Math.PI),
            };
            return getCenter(_center.lat, _center.lng, _markers)
        }
        return center
    }
    

    return (
        <div
            className="rounded"
            style={{ flexGrow: "1", height: "100%", overflow: "hidden" }}
        >
            <GoogleMap
                bootstrapURLKeys={{
                    key: GOOGLE_MAPS_KEY,
                    libraries: ["drawing", "places"],
                }}
                center={center}
                zoom={zoom}
                options={{
                    styles: MAPS_THEME,
                    draggable: true,
                    panControl: false,
                    rotateControl: false,
                    scaleControl: false,
                    zoomControl: true,
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                    streetViewControl: false,
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => {
                    console.log("MAP: ", map);
                    setMap(map);
                }}
            >
                {markers.map((marker, index) => {
                    if (marker.lat && marker.lng) {
                        return (
                            <CustomMarker
                                key={index}
                                draggable={true}
                                lat={marker.lat}
                                lng={marker.lng}
                                icon={marker.icon}
                                title={marker.title}
                                bgColor={marker.bgColor}
                                data={marker.data}
                                activeJobMarker={activeJobMarker}
                                setActiveJobMarker={setActiveJobMarker}
                            />
                        )
                    }

                    return null;
                })}
                {agents.map((agent, inde) => {
                    if (agent.lat && agent.lng) {
                        return (
                            <AgentMarker
                                data={agent}
                                lat={agent.lat}
                                lng={agent.lng}
                                activeAgentMarker={activeAgentMarker}
                                setActiveAgentMarker={setActiveAgentMarker}
                            />
                        );
                    }

                    return null
                })}

            </GoogleMap>
            <ColorIdicator/>
        </div>
    );
};

export default AgentJobMap;
