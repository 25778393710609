import React, {Component} from 'react';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import FranchiseForm from "./FranchiseForm";

class CreateFranchise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
            showPassword: false,
            logo: '' 
        };
    }

    componentWillMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
        }
        if (match.path === '/franchises/:id') {
            this.setState({is_view: true, is_update: false});

        }
        if (match.path === '/franchises/:id/edit') {
            this.setState({is_view: false, is_update: true});
        }
    }

    createFranchise = (data) => {
        this.props.create(data)
    }

    updateFranchise = (data) => {
        const {match} = this.props;
        const _id = data.id? data.id : match.params.id
        this.props.edit(_id, data);
    };

    actionFile = (data) => {
        const {setLogoFile} = this.props;
        setLogoFile(data)
        this.setState({logo: data});
    }

    render() {

        const {match, item, loader} = this.props;
        const {is_view, is_update} = this.state;
        const sendFunction = match.params.id
            ? this.updateFranchise
            : this.createFranchise;


        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_view && "View Franchise"}
                            {is_update && "Update Franchise"}
                            {!is_update && !is_view && "Create Franchise"}
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4">
                    <LoadMask loading={loader} dark blur>
                        <FranchiseForm
                            is_view={is_view}
                            onSubmit={sendFunction}
                            is_update={is_update}
                            item={item}
                            setFiles={this.actionFile}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default CreateFranchise;
