import React from 'react';
import {Field, reduxForm} from "redux-form";
import {renderField, renderNumber, renderSwitch} from "../Utils/renderField";
import {validate, validators, combine, validatorFromFunction} from "validate-redux-form";
import {Link} from 'react-router-dom'

const CreateAdminForm = (props) => {
    const {handleSubmit, is_view, is_update, updatePass, showPassword, showPassAction} = props;

    return (

        <form name="registerAdmin" className="py-4" onSubmit={handleSubmit}>
            <div className="row mx-auto mt-3 col-lg-9 col-sm-12">
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="first_name">First Name</label>
                    <Field
                        name="first_name"
                        label="Firm name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={is_view}
                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="email">Last Name</label>
                    <Field
                        name="last_name"
                        label="last Name"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={is_view}

                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="email">Email</label>
                    <Field
                        name="email"
                        label="email"
                        component={renderField}
                        type="email"
                        className="form-control"
                        disabled={is_view}

                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="mobile_number">Mobile Number</label>
                    <Field
                        disabled={is_view}
                        name="mobile_number"
                        component={renderNumber}
                        className="form-control"
                        numberFormat={"###-###-####"}
                    />
                </div>
                {is_update &&
                <div className="row w-100 mx-auto col-12 my-3">
                    <label htmlFor="showField" className="align-self-end"> Updated password </label>
                    <Field
                        name={'showField'}
                        disabled={false}
                        component={renderSwitch}
                        customClass="text-center pt-2 pl-3"
                    />
                </div>
                }
                {((!is_view && !is_update) || (is_update && updatePass)) &&
                <React.Fragment>
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <label htmlFor="password"> Password </label>
                        <div className="w-100 mx-auto row">
                            <Field
                                name="password"
                                label="password"
                                component={renderField}
                                type={`${showPassword ? 'text' : 'password'}`}
                                className="form-control"
                            />
                            <label
                                className="button-eyes"
                                onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                                {showPassword ?
                                    <i className="material-icons pointer">visibility_off</i> :
                                    <i className="material-icons pointer">visibility</i>
                                }
                            </label>
                        </div>
                    </div>
                    <div className="form-group has-feedback col-lg-6 col-sm-12">
                        <label htmlFor="confirmPassword"> Confirm Password </label>
                        <div className="w-100 mx-auto row">
                            <Field
                                name="confirmPassword"
                                label="NewPassword"
                                component={renderField}
                                type={`${showPassword ? 'text' : 'password'}`}
                                className="form-control"
                            />
                            <label
                                className="button-eyes"
                                onClick={showPassword ? () => showPassAction(false) : () => showPassAction(true)}>
                                {showPassword ?
                                    <i className="material-icons pointer">visibility_off</i> :
                                    <i className="material-icons pointer">visibility</i>
                                }
                            </label>
                        </div>
                    </div>
                </React.Fragment>
                }
            </div>
            <div className="mx-auto w-100 d-flex justify-content-center">
                <div className="mt-5 col-lg-12 col-sm-9 text-center">
                    <Link to="/manager"
                          className={`btn ${is_view ? "button-03" : "btn-danger text-white"} col-sm-12 col-lg-2`}>
                        {is_view ? "Back" : "Cancel"}
                    </Link>
                    {!is_view &&
                    <button type="submit"
                            className="btn btn-primary col-sm-12 col-lg-2 mt-lg-0 mt-sm-3 ml-lg-4 ml-sm-0">
                        {is_update ? "Update" : "Create"}
                    </button>
                    }
                </div>
            </div>
        </form>

    )
}

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export const matchPassword = (passe, confirm) => validatorFromFunction(() => {
    return passe === confirm;
});

export default reduxForm({
    form: 'adminForm', // a unique identifier for this form
    asyncBlurFields: [],
    validate: (data) => {
        return validate(data, {
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            mobile_number: combine(
                validators.exists()('This field is required'),
                numberPhone(data.mobile_number)()('Invalid phone number'),
            ),
            email: combine(
                validators.exists()('This field is required'),
                validators.regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)("Invalid email address")
            ),
            confirmPassword: combine(
                validators.exists()('This field is required'),
                matchPassword(data.password, data.confirmPassword)()('The account password don\'t match'),
            ),
            password: validators.exists()('This field is required'),
        });
    },
})(CreateAdminForm);

