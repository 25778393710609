import React from "react";
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import {renderField, renderNumber, SelectField} from '../../Utils/renderField';
// import {combine, validate, validatorFromFunction, validators} from 'validate-redux-form';
import {
    STAFF,
} from "../../../../utility/constants";
import _ from "lodash";

const validate = (values, props) => {
    let errors = {};

    if (!values.first_name) {
        errors.first_name = "This field is required";
    }
    if (!values.last_name) {
        errors.last_name = "This field is required";
    }
    if (!values.email) {
        errors.email = "This field is required";
    }
    if (!values.phone) {
        errors.phone = "This field is required";
    } else {
        if (values.phone.length < 10) {
            errors.phone = "Invalid phone number";
        }
    }

    if (!values.staff_name) {
        errors.staff_name = "This field is required";
    }

    return errors;
};

const InfluencerTeamContactForm = (props) => {

    const {handleSubmit, is_update, loader, goBack} = props;

    return (
        <form
            name="influencerTeamContactForm"
            className="my-5"
            style={{ minHeight: "28rem" }}
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-5 mx-auto pt-2">
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="first_name">First name</label>
                    <Field
                        name="first_name"
                        label="First name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="last_name">Last Name</label>
                    <Field
                        name="last_name"
                        label="Last Name"
                        component={renderField}
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="email">Email</label>
                    <Field
                        name="email"
                        label="email"
                        component={renderField}
                        type="email"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="phone">Mobile Number</label>
                    <Field
                        numberFormat={"###-###-####"}
                        name="phone"
                        component={renderNumber}
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="staff_name">Add Role</label>
                    <Field
                        name="staff_name"
                        label="Add Role"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row mx-auto pt-5 justify-content-center">
                <button type="button" className="btn button-03 mx-3 pt-1 col-2" onClick={goBack}>Back</button>
                { is_update ? (
                    <button
                        disabled={loader}
                        type="submit"
                        className="btn btn-primary mx-3 py-0 col-2"
                    >
                        Update
                    </button>

                ): (
                    <button
                        disabled={loader}
                        type="submit"
                        className="btn btn-primary mx-3 py-0 col-2"
                    >
                        Save
                    </button>
                
                )}
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'teamContactForm', // a unique identifier for this form
    initialValues: {
        brokerage: null,
        license: null,
        account_status: 1,
        type_user: 5,
        broker: null,
        broker_company: null,
        user: null,
        roles: 30,
    },
    validate,
})(InfluencerTeamContactForm);