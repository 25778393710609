import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/jobTypeVendor";
import CreateUpdateVendorType from './CreateUpdateVendorType';


const ms2p = (state) => {
    return {
        ...state.jobTypeVendor,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateUpdateVendorType);

