import React, {useState} from 'react'
import moment from "moment";
import LockNoteForm from './LockNoteForm'
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { SIZE_PER_PAGE } from "../../../../utility/constants";


const NewNoteModal = (props) => {
    //  State
    const {open, loader, item=null} = props;
    //  Bind
    const {onClose, saveNote, remove} = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-9 col-11", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            {item ? "Edit" : "New"} Note
                        </div>
                    </div>
                </div>
                <LoadMask loading={loader} dark blur>
                    <LockNoteForm
                        loader={loader}
                        isUpdate={item ? true : false}
                        onSubmit={saveNote}
                        onClose={onClose}
                        remove={remove}/>
                </LoadMask>
            </div>
        </Modal>
    )
}

const LockNotesGrid = (props) => {
    //  State
    const { data, page, loader, item, itemLoader } = props;
    //  Bind
    const {changePage, saveNote, read, remove, setItem} = props;

    const [noteModal, setNoteModal] = useState(false);

    const tableOptions = {
        sizePerPage: SIZE_PER_PAGE,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: changePage,
        onRowClick: (row) => {
            read(row.id, (e) => setNoteModal(true))
        }
    };

    const closeModal = () => {
        setItem(null)
        setNoteModal(false);
    }

    const saveForm = (data) =>  {
        saveNote(data, closeModal)
    }

    const removeItem = () => {
        const {id} = item;
        remove(id, closeModal)
    }

    return (
        <div className="w-100 mx-auto">
            <div className="card border-dark border-card-page px-2 pt-2 pb-0 mb-0">
                <div className="card-body px-0 pt-2 mb-0 pb-0">
                    <div className="row justify-content-end mb-3">
                        <div className='col-lg-2 col-md-3 col-sm-12'>
                            <button
                                onClick={(e) => setNoteModal(true)}
                                className="w-100 btn btn-success text-white"
                            >
                                {" "}
                                Add a Note
                            </button>
                        </div>
                    </div>
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            data={loader ? [] : data.results ? data.results : []}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                width="0px"
                                isKey
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                        </div>
                                    )
                                }}
                            />
                            <TableHeaderColumn
                                dataField="note"
                                dataFormat={(cell) => <div className='pointer'>{cell}</div>}
                            >
                                Note
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="150px"
                                dataField="created"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className='pointer'>
                                            {
                                                moment(
                                                    cell
                                                ).format("lll")}
                                        </div>
                                    );
                                }}
                            >
                                Created
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
            
            <NewNoteModal
                open={noteModal}
                item={item}
                loader={itemLoader}
                onClose={closeModal}
                saveNote={saveForm}
                remove={removeItem}/>

        </div>
    )
}

export default LockNotesGrid