import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
// const SET_DATA = "SET_DATA_TUTORIAL_VIDEO";

//-------------------------------------
// Base reducer
//-------------------------------------
export const { reducers, initialState, actions } = createReducer(
    "tutorialVideos",
    "video_media",
    "tutorialVideoFrom",
    "/tutorial-videos",
);

export default handleActions(reducers, initialState);