import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Avatar from "react-avatar";

const defaultAvatar = require("assets/img/person-shape.svg");


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {dropdownOpen: false};
    }

    toggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    };
    render() {
        const { navToggle, logOut, user } = this.props;

        return (
            <nav className="align-items-stretch flex-md-nowrap p-0 navbar">
                <nav className="nav">
                    <a className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                       onClick={navToggle}>
                        <i className="material-icons"></i>
                    </a>
                </nav>
                <ul className="flex-row m-0 p-0">
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle color="light" caret className="nav-item-dropdown m-0 pt-2 px-5 new-black">
                            <div className="rounded-circle bg-white mr-3 align-middle d-inline-flex new-black">
                                <Avatar
                                    size="28"
                                    src={user.photo ? user.photo : defaultAvatar}
                                    round={true}
                                    name={user.first_name ? user.first_name : 'Photo'}
                                />
                                {/*<img*/}
                                {/*    className={`d-inline align-middle my-auto*/}
                                {/*    ${user.photo ? " rounded-circle user-avatar-true" : " user-avatar"}`*/}
                                {/*    }*/}
                                {/*    src={user.photo ? user.photo : defaultAvatar}*/}
                                {/*    alt="User photo"*/}
                                {/*/>*/}
                            </div>
                            <span className="mr-2 d-inline align-middle new-black">{user.first_name} </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            {/*<DropdownItem>*/}
                            {/*    <Link tabIndex="0"*/}
                            {/*          to="/edit-user-profile">*/}
                            {/*        <i className="material-icons"></i>*/}
                            {/*        Profile*/}
                            {/*    </Link>*/}
                            {/*</DropdownItem>*/}
                            <DropdownItem tag="a" href="#/edit-user-profile">
                                {/*<Link tabIndex="0" to="/edit-user-profile">*/}
                                <div className="flex row mx-auto align-items-center py-0">
                                    <i className="material-icons"></i>
                                        <p className="mb-0 mt-2">
                                        Profile
                                        </p>
                                </div>
                                {/*</Link>*/}
                            </DropdownItem>
                            <DropdownItem tag="a" href="#/settings">
                                <div className="flex row mx-auto align-items-center py-0">
                                    <i className="material-icons">settings</i>
                                    <p className="mb-0 mt-1">
                                        Settings
                                    </p>
                                </div>
                            </DropdownItem>
                            {/*<DropdownItem>*/}
                            {/*    <Link tabIndex="0"*/}
                            {/*          to="/file-manager-list">*/}
                            {/*        <i className="material-icons"></i>*/}
                            {/*        Files*/}
                            {/*    </Link>*/}
                            {/*</DropdownItem>*/}
                            {/*<DropdownItem>*/}
                            {/*    <Link tabIndex="0"*/}
                            {/*          to="/transaction-history">*/}
                            {/*        <i className="material-icons"></i>*/}
                            {/*        Transactions*/}
                            {/*    </Link>*/}
                            {/*</DropdownItem>*/}
                            <DropdownItem divider/>
                            <DropdownItem tag="a" href="/" onClick={logOut}>
                                <div className="flex row mx-auto align-items-center py-0">
                                    <i className="material-icons text-danger"></i>
                                        <p className="mb-0 pt-1">
                                        Logout
                                        </p>
                                </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ul>
            </nav>
        );
    }
}

export default Navbar;
