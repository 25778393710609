import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "app_notification"

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "appNotifications",
    endpoint,
    "notificationForm",
    "/app_notifications",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------


const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["appNotifications"];
    const {NotificationFiltersForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (NotificationFiltersForm && NotificationFiltersForm.values) {
        const {search} = NotificationFiltersForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }

    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};



const edit = (id, data = {}) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Notification updated', 'Success', 3000);
        dispatch(push('/app_notifications'));
    }).catch((error) => {
        let msg = "Edition failed"
        if (error && error.detail) {
            msg = error.detail
        }

        NotificationManager.error(msg, 'ERROR', 5000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

export const actions = {
    ...basicReducer.actions,
    toList,
    edit,
    searchChange,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
};

export default handleActions(reducers, initialState);
