import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/admin/admin';
import List from "./AdminList";

const ms2p = (state) => {
    return{
        ...state.admin,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);
