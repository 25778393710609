import { connect } from "react-redux";
import React, {useState} from "react";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { renderFileImageCrop } from "../../Utils/renderField";
import {
    renderField,
    renderTextArea
} from "../../Utils/renderField";


const renderFeatures = ({ fields, meta: { error, submitFailed } }) => {
    return (
        <div className="border border py-3">
            <div className="w-100 px-2">
                {fields.map((row, indexS) => (
                    <div
                    key={`feature-${indexS}-ss`}
                    className={`row w-100 mx-1 mb-1 py-2 ${fields.length == indexS + 1 ? 'mb-3' : 'mb-1'}`}
                    style={{backgroundColor: (indexS % 2 !== 0) ? '#F1F1F1' : 'white'}}>
                        <div className="col-2 col-md-2 justify-content-center">
                            <img
                                src={require("../../../../../assets/img/delete.svg")}
                                alt="del"
                                style={{width: "1.5rem"}}
                                onClick={() => {
                                    fields.remove(indexS);
                                }}
                                className="pointer align-self-center ml-3 mt-1"
                            />
                        </div>
                        <div className="col-12 col-md-8">
                            <label htmlFor="description">Text</label>
                            <Field
                                name={`${row}.description`}
                                rows={3}
                                placeholder="Description"
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-center">
                <button
                    type="button"
                    className="btn button-01 py-0"
                    onClick={() => fields.push({})}
                >
                    Add Feature
                </button>
            </div>
        </div>
    )
}

const Form = (props) => {
    // State
    const { loader, image } = props;
    //  Bind
    const { handleSubmit, goBack, setImage } = props;

    return (
        <div
            className="card flex-1 border-dark border-card-page px-4 py-4"
            style={{
                minWidth: "99%",
            }}
        >
            <form
                name="giftCardTextForm"
                className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
                onSubmit={handleSubmit}
            >
                <LoadMask loading={loader} light>
                    <div className="row">
                        <div className="col-12">
                            <h4>Main</h4>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="gift_card_title">Title</label>
                            <Field
                                name="gift_card_title"
                                component={renderField}
                            />
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="gift_card_main_image">Image</label>
                                <Field
                                    name="gift_card_main_image"
                                    id="gift_card_main_image"
                                    photo={image}
                                    setFile={(file) => setImage(file)}
                                    spectValue={16 / 10}
                                    component={renderFileImageCrop}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4>Purchase</h4>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="purchase_gift_card_title">Title</label>
                            <Field
                                name="purchase_gift_card_title"
                                component={renderField}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="purchase_gift_card_select_design_text">Select Design</label>
                            <Field
                                name="purchase_gift_card_select_design_text"
                                rows={3}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="purchase_gift_card_description">Purchase Description</label>
                            <Field
                                name="purchase_gift_card_description"
                                rows={3}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                </div>
                <div className="row">
                        <div className="col-12">
                            <h4>Successful purchase</h4>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="successful_purchase_gift_card_title">Title</label>
                            <Field
                                name="successful_purchase_gift_card_title"
                                component={renderField}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="successful_purchase_gift_card_description">Description</label>
                            <Field
                                name="successful_purchase_gift_card_description"
                                rows={3}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="successful_purchase_gift_card_steps">Steps</label>
                            <Field
                                name="successful_purchase_gift_card_steps"
                                rows={3}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="successful_purchase_gift_card_description_two">Description Two</label>
                            <Field
                                name="successful_purchase_gift_card_description_two"
                                rows={3}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4>Features</h4>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="purchase_gift_card_feature_title">Title</label>
                            <Field
                                name="purchase_gift_card_feature_title"
                                component={renderField}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="features">Features</label>
                            <FieldArray
                                name="features"
                                component={renderFeatures}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4>Redeem</h4>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="redeem_gift_card_title">Title</label>
                            <Field
                                name="redeem_gift_card_title"
                                component={renderField}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="redeem_gift_card_description">Redeem Description</label>
                            <Field
                                name="redeem_gift_card_description"
                                rows={3}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="w-100 my-4 mx-auto text-center">
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={goBack}
                        >
                            Back
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                            disabled={loader}
                        >
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        </div>
    )
}

// export default reduxForm({
//     form: "giftCardTextForm",
// })(GiftCardTextForm);

let GiftCardTextForm = reduxForm({
    form: "giftCardTextForm",
    initialValues: {},
})(Form)

const selector = formValueSelector("giftCardTextForm");
GiftCardTextForm = connect((state) => {
    return {
        image: selector(state, 'gift_card_main_image'),
    };
})(GiftCardTextForm)

export default GiftCardTextForm;
