import moment from "moment";
import { Link } from "react-router-dom";
import {api} from '../../../../utility/api';
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import ReactStars from "react-rating-stars-component";
import { SIZE_PER_PAGE } from "../../../../utility/constants";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";


export const VendorRawDataGrid = (props) => {
    //  Status
    const { loader, data, page } = props;
    console.log("DATA RAW: ", data);
    const [gridHeight, setGridHeight] = useState(200)
    //  Applicants
    const [vendorsModal, setVendorsModal] = useState(false);
    const [vendorsData, setVendorsData] = useState(null);
    //  Review
    const [reviewModal, setReviewModal] = useState(false);
    const [reviewData, setReviewData] = useState(null);
    //  Note
    const [noteModal, setNoteModal] = useState(false);
    const [noteText, setNoteText] = useState(null);


    //  Bind
    const { nextPage } = props;

    const tableOptions = {
        sizePerPage: SIZE_PER_PAGE,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: nextPage,
    };


    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("STATS-VENDOR-RAW-DATA");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    const openVendorsModal = (data, job_id) => {
        setVendorsData({ ...data, job_id });
        console.log("OPEN VENDORS MODAL: ", data);
        setTimeout(() => {
            setVendorsModal(true);
        }, 300);
    };

    const closeVendorsModal = (data) => {
        setVendorsData(null);
        setTimeout(() => {
            setVendorsModal(false);
        }, 300);
    };

    const openReviewModal = (data) => {
        setReviewData(data);
        setTimeout(() => {
            setReviewModal(true);
        }, 300);
    }

    const closeReviewModal = (data) => {
        setReviewData(null);
        setTimeout(() => {
            setReviewModal(false);
        }, 300);
    }

    const openNoteModal = (note) => {
        setNoteText(note);
        setTimeout(() => {
            setNoteModal(true);
        }, 300);
    }

    const closeNoteModal = (data) => {
        setNoteText(null);
        setTimeout(() => {
            setNoteModal(false);
        }, 300);
    }

    return (
        <div id="STATS-VENDOR-RAW-DATA" className="container-list card border-dark border-card-page px-4 py-0">
            <div className="card-body px-0 py-2 d-flex flex-column overflow-hidden">
                <LoadMask loading={loader} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={gridHeight ? {maxHeight: `${gridHeight}px`}: {}}>
                        <BootstrapTable
                            data={loader ? [] : data && data.results ? data.results : []}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            // expandableRow={isExpandableRow}
                            // expandComponent={expandComponent}
                            // expandColumnOptions={{ expandColumnVisible: true }}
                            containerClass="flex-1"
                            tableContainerClass="table-scroll-auto"
                            tableStyle={gridHeight ? {maxHeight: `${gridHeight - 60}px`, minHeight: `${gridHeight - 60}px`} : {}}
                        >
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="id"
                                isKey
                                width="80px"
                                dataAlign="center"
                            >
                                Job #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="job_type_vendor__name"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className="w-100 d-flex">
                                            {row.job_type_vendor ? row.job_type_vendor.name : null}
                                        </div>
                                    );
                                }}
                            >
                                Vendor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="number_quotes"
                                width="80px"
                                dataAlign="center"
                            >
                                # of quotes
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="job_status"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    let job_status = cell

                                    return (
                                        <div className="d-flex flex-row">
                                            <button
                                                title="Agents"
                                                className="btn btn-light d-flex align-items-center justify-content-center mr-2"
                                                style={{
                                                    minWidth: "1.6rem",
                                                    maxWidth: "1.6rem",
                                                    minHeight: "1.6rem",
                                                    maxHeight: "1.6rem",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openVendorsModal(
                                                        {
                                                            state_job: row.state_job,
                                                            cancellation_date:
                                                                row.cancellation_date
                                                        },
                                                        row.id,
                                                    );
                                                }}
                                            >
                                                <i className="fa fa-users text-success"></i>
                                            </button>
                                            {/* IT IS VALIDATED IF IT IS NO SHOW */}
                                            <span>{job_status}</span>
                                        </div>
                                    );
                                }}
                            >
                                Job Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="created"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{cell && moment(cell).format("lll")}</div>
                                    );
                                }}
                            >
                                Job Posted Date & Time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="first_get_lead_date"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {cell &&
                                                moment(
                                                    cell
                                                ).format("lll")}
                                        </div>
                                    );
                                }}
                            >
                                First Get Lead Date & Time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="complete_date_time"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {cell &&
                                                moment(
                                                    cell
                                                ).format("lll")}
                                        </div>
                                    );
                                }}
                            >
                                Job Completed Date & Time
                            </TableHeaderColumn>
                            {/* HIRING AGENT INFO */}
                            <TableHeaderColumn row="0" colSpan="4" headerAlign="center">
                                Hiring Agent Info
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__first_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.first_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__last_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.last_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__franchise"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.franchise
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="190px"
                            >
                                Fanchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__office_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.office_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="190px"
                            >
                                Office Name
                            </TableHeaderColumn>

                            {/* ADDRESS & DETAILS */}
                            <TableHeaderColumn row="0" colSpan="6" headerAlign="center">
                                Address & Details
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="job_description__state"
                                dataFormat={(cell, row) => {
                                    const job_description = row.job_description && row.job_description.length > 0 ? row.job_description[0] : null
                                    return (
                                        <div>
                                            {job_description
                                                ? job_description.state
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                State
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="job_description__city"
                                dataFormat={(cell, row) => {
                                    const job_description = row.job_description && row.job_description.length > 0 ? row.job_description[0] : null
                                    return (
                                        <div>
                                            {job_description
                                                ? job_description.city
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                City
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="job_description__address"
                                dataFormat={(cell, row) => {
                                    const job_description = row.job_description && row.job_description.length > 0 ? row.job_description[0] : null
                                    return (
                                        <div>
                                            {job_description
                                                ? job_description.address
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="300px"
                            >
                                Address
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="job_description__zip_code"
                                dataFormat={(cell, row) => {
                                    const job_description = row.job_description && row.job_description.length > 0 ? row.job_description[0] : null
                                    return (
                                        <div>
                                            {job_description
                                                ? job_description.zip_code
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                Zip Code
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="notes"
                                dataFormat={(cell, row) => {
                                    const hasNotes = row.notes ? true : false
                                    return (
                                        <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                                            {hasNotes && (
                                                <button
                                                    className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                                    style={{
                                                        minWidth: "1.6rem",
                                                        maxWidth: "1.6rem",
                                                        minHeight: "1.6rem",
                                                        maxHeight: "1.6rem",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openNoteModal(row.notes);
                                                    }}
                                                >
                                                    <i className="fa fa-sticky-note"></i>
                                                </button>
                                            )}
                                            <div className="text-center">
                                                {hasNotes
                                                    ? "Yes"
                                                    : "No"}
                                            </div>
                                        </div>
                                    );
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                Work Scope
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="reviews"
                                dataFormat={(cell, row) => {
                                    const hasReviews = row.reviews && row.reviews.length > 0 ? true : false
                                    return (
                                        <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                                            {hasReviews && (
                                                <button
                                                    className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                                    style={{
                                                        minWidth: "1.6rem",
                                                        maxWidth: "1.6rem",
                                                        minHeight: "1.6rem",
                                                        maxHeight: "1.6rem",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openReviewModal(row.reviews[0]);
                                                    }}
                                                >
                                                    <i className="fa fa-sticky-note"></i>
                                                </button>
                                            )}
                                            <div className="text-center">
                                                {hasReviews
                                                    ? "Yes"
                                                    : "No"}
                                            </div>
                                        </div>
                                    );
                                }}
                                width="150px"
                                dataAlign="center"
                            >
                                Review
                            </TableHeaderColumn>

                            {/* JOB FEE */}
                            <TableHeaderColumn row="0" colSpan="2" headerAlign="center">
                                Job Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="130px"
                                dataFormat={(cell, row) => {
                                    const amount = row.job_fees && row.job_fees.get_lead ? row.job_fees.get_lead : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            $ {parseFloat(amount).toFixed(2)}
                                        </p>
                                    );
                                
                                }}
                            >
                                Get Lead
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="130px"
                                dataFormat={(cell, row) => {
                                    const amount = row.job_fees && row.job_fees.stripe_fee ? row.job_fees.stripe_fee : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            $ {parseFloat(amount).toFixed(2)}
                                        </p>
                                    );
                                
                                }}
                            >
                                Stripe Fee
                            </TableHeaderColumn>

                            {/* BROKERS/INFLUENCER/SHOWIT PROFIT */}
                            <TableHeaderColumn row="0" colSpan="7" headerAlign="center">
                                Brokers/Influencer/ShowIT Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="130px"
                                dataFormat={(cell, row) => {
                                    const amount = row.job_fees && row.job_fees.gross_profit ? row.job_fees.gross_profit : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            $ {parseFloat(amount).toFixed(2)}
                                        </p>
                                    );
                                
                                }}
                            >
                                ShowIT Gross Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="130px"
                                dataFormat={(cell, row) => {
                                    const amount = row.job_fees && row.job_fees.broker_profit ? row.job_fees.broker_profit : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            $ {parseFloat(amount).toFixed(2)}
                                        </p>
                                    );
                                
                                }}
                            >
                                Broker Profit Hiring Agent
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    const result = row.job_fees && row.job_fees.delivery_to_broker ? row.job_fees.delivery_to_broker : false
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            {result ? "Yes" : "No"}
                                        </p>
                                    );
                                
                                }}
                            >
                                ShowIT Share Delivery to the Hiring Agent Broker?
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="130px"
                                dataFormat={(cell, row) => {
                                    const amount = row.job_fees && row.job_fees.influencer_profit ? row.job_fees.influencer_profit : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            $ {parseFloat(amount).toFixed(2)}
                                        </p>
                                    );
                                
                                }}
                            >
                                Influencer Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    const result = row.job_fees && row.job_fees.delivery_to_influencer ? row.job_fees.delivery_to_influencer : false
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            {result ? "Yes" : "No"}
                                        </p>
                                    );
                                
                                }}
                            >
                                Profit delivery to the Influencer?
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="130px"
                                dataFormat={(cell, row) => {
                                    const amount = row.job_fees && row.job_fees.commission_paid ? row.job_fees.commission_paid : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            $ {parseFloat(amount).toFixed(2)}
                                        </p>
                                    );
                                
                                }}
                            >
                                Total Commission Paid
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="job_fee"
                                width="130px"
                                dataFormat={(cell, row) => {
                                    const amount = row.job_fees && row.job_fees.net_profit ? row.job_fees.net_profit : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            $ {parseFloat(amount).toFixed(2)}
                                        </p>
                                    );
                                
                                }}
                            >
                                ShowIT Net Profit
                            </TableHeaderColumn>

                            {/* NOTIFICATION */}
                            <TableHeaderColumn row="0" colSpan="1" headerAlign="center">
                                Notification
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="total_notification"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    const amount = cell ? cell : 0
                                    return (
                                        <p className={"my-0 mx-0 px-0"}>
                                            {parseInt(amount)}
                                        </p>
                                    );
                                
                                }}
                            >
                                # of Vendors received notification
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>

            <VendorsModal
                open={vendorsModal && vendorsData}
                data={vendorsData}
                onClose={closeVendorsModal}
            />
            <ReviewModal
                open={reviewModal && reviewData}
                data={reviewData}
                onClose={closeReviewModal}
            />
            <NoteModal
                open={noteModal && noteText}
                notes={noteText}
                onClose={closeNoteModal}
            />
        </div>
    )
}

const VendorsModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;
    const R_CANCEL = 35;
    const [results, setResults] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dataHistory, setDataHistory] = useState(null);

    useEffect(() => {
        if(data && data.job_id) {
            setLoader(true)
            api.get("vendor_job_posting/vendors_quotes", { job_id: data.job_id })
            .then((response) => {
                if(response) {
                    console.log("RESPONSE APPLICANTS: ", response);
                    const r_agent = response.r_agent ? response.r_agent : null;
                    let _results = [];
                    if (r_agent) {
                        _results.push({...r_agent, is_r_agent: true})
                    }
                    const _resultsAgents = response.results ? response.results : [];
                    setResults([..._results, ..._resultsAgents])
                }

            })
            .finally(() => {
                setLoader(false);
            });
        }

        return () => {
            setResults([])
            setLoader(false)
            setDataHistory(null)
          }
    }, [data])

    const actions = [
        {name: "Get Lead", id: 100, bg: "bg-gray", txt: "text-black"},
        {name: "Appointment", id: 200, bg: "bg-info", txt: "text-white"},
        {name: "No Answer", id: 300, bg: "bg-warning", txt: "text-white"},
        {name: "Follow Up", id: 400, bg: "bg-warning", txt: "text-white"},
        {name: "Declined", id: 500, bg: "bg-danger", txt: "text-white"},
        {name: "Completed", id: 600, bg: "bg-success", txt: "text-white"},
        {name: "Expired", id: 700, bg: "bg-warning", txt: "text-white"},
        {name: "Reviewed", id: 800, bg: "bg-success", txt: "text-white"},
    ]

    const getAction = (action) => {
        let result = null;
        if (action) {
            const _action = _.find(actions, { id: action });
            if (_action) {
                result = _action;
            }
        }

        return result;
    };

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={(e) => {
                if (dataHistory) {
                    setDataHistory(null)
                }else {
                    onClose()
                }
            }}
            classNames={{
                modal: "rounded col-lg-9 col-11",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            {dataHistory && (
                                <button className='btn back-btn mr-3 my-2' onClick={() => setDataHistory(null)}>
                                    <i className="material-icons pointer">arrow_back</i> 
                                </button>
                            )}
                            {dataHistory ? "Edit History": "Agents that applied for the job"}
                        </div>
                    </div>
                </div>
                {!dataHistory ? (
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            striped
                            remote
                            hover
                            containerClass="flex-1"
                            tableContainerClass="table-scroll-auto"
                            containerStyle={{minHeight: '300px'}}
                            data={results}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                width="0px"
                                dataFormat={(cell, row) => {
                                    return <div />;
                                }}
                            />
                            <TableHeaderColumn
                                width="150px"
                                dataField="id"
                                dataFormat={(cell, row) => {
                                    const isRAgent = row.is_r_agent ? true : false;
                                    let name = ""
                                    let agentId = null
                                    if (isRAgent && row.agent) {
                                        name = `${row.agent.first_name} ${row.agent.last_name}`
                                        agentId = row.agent.id
                                    }
                                    if (!isRAgent && row.vendor) {
                                        name = `${row.vendor.first_name} ${row.vendor.last_name}`
                                        agentId = row.vendor.id
                                    }

                                    return (
                                        <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={isRAgent ? `/agents/agent/${agentId}/edit` : `/vendor/vendors/${agentId}/edit`}
                                        className="w-100 text-primary"
                                        >
                                            {name}
                                        </Link>
                                    )
                                }}
                            >
                                Vendor Company Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="150px"
                                dataField="status"
                                dataAlign="center"
                                dataFormat={(cell, row) => {
                                    const isRAgent = row.is_r_agent ? true : false;
                                    let name = ""
                                    let actions = null
                                    if (!isRAgent) {
                                        actions = getAction(row.status)
                                        name = actions ? actions.name : ""
                                    }

                                    return (
                                        <span>{name}</span>
                                    )
                                }}
                            >
                                Job Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="50px"
                                dataField="payment_amount"
                                dataAlign="center"
                                dataFormat={(cell, row) => {
                                    const isRAgent = row.is_r_agent ? true : false;
                                    let amount = null
                                    if (!isRAgent) {
                                        amount = row.payment_amount
                                    }

                                    return (
                                        <span>{isRAgent ? "" : `$${parseFloat(amount).toFixed(2)}`}</span>
                                    )
                                }}
                            >
                                Lead Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="100px"
                                dataField="payment_amount"
                                dataAlign="center"
                                dataFormat={(cell, row) => {
                                    const isRAgent = row.is_r_agent ? true : false;

                                    return (
                                        <span>{isRAgent ? "Hiring Agent" : "Vendor"}</span>
                                    )
                                }}
                            >
                                Type
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="430px"
                                dataField="id"
                                dataFormat={(cell, row) => {
                                    const isRAgent = row.is_r_agent ? true : false;
                                    if (isRAgent) {
                                        return (
                                            <div className="d-flex align-content-between flex-wrap">
                                                {row.history.map((item, index) => {
                                                    let titleStatus = ''
                                                    let color = "bg-info"
                                                    let itemDate = moment(item.created).format("lll")
                                                    if (item.type == 100) {
                                                        titleStatus = "Created"
                                                    } else if (item.type == 200) {
                                                        titleStatus = "Edited"
                                                    }
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 ${color} text-white ${item.type == 200 ? "pointer" : ""}`}
                                                            style={{
                                                                fontSize:
                                                                    '12px',
                                                                width: "135px",
                                                            }}
                                                            onClick={(e) => {
                                                                if (item.type == 200) {
                                                                    setDataHistory(item.data)
                                                                }
                                                            }}
                                                            title={item.type == 100 ? "": "click to see the detail"}
                                                        >
                                                            <span className="font-weight-bold" style={{fontSize: '13px'}}>
                                                                {titleStatus}
                                                            </span>
                                                            <span className="font-weight-lighter">
                                                                {itemDate}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )
                                    }
                                    return (
                                        <div>
                                            {row.history &&
                                                row.history.length ? (
                                                    <div className="d-flex align-content-between flex-wrap">
                                                        {row.history.map(
                                                            (item, index) => {
                                                                const _action = getAction(
                                                                    item.status
                                                                );
                                                                if (_action) {
                                                                    return (
                                                                        <div
                                                                            className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 ${_action.bg} ${_action.txt}`}
                                                                            style={{
                                                                                fontSize:
                                                                                    "10px",
                                                                                width: "120px",
                                                                            }}
                                                                        >
                                                                            <span className="font-weight-bold">
                                                                                {
                                                                                    _action.name
                                                                                }
                                                                            </span>
                                                                            <span className="font-weight-lighter">
                                                                                {moment(
                                                                                    item.created
                                                                                ).format(
                                                                                    "lll"
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                        {data &&
                                                        data.state_job == R_CANCEL && (
                                                            <div
                                                                className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 bg-danger text-white`}
                                                                style={{
                                                                    fontSize: "10px",
                                                                    width: "120px",
                                                                }}
                                                            >
                                                                <span className="font-weight-bold">
                                                                    R-Agent Canceled
                                                                </span>
                                                                <span className="font-weight-lighter">
                                                                    {data.cancellation_date
                                                                        ? moment(
                                                                            data.cancellation_date
                                                                        ).format(
                                                                            "lll"
                                                                        )
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                            ) : (
                                                <div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }}
                            >
                                Actions
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                ): (
                    <EditHistory data={dataHistory} goBack={() => setDataHistory(null)}/>
                )}
            </div>
        </Modal>
    )
}

const EditHistory = (props) => {
    const {data} = props;
    const {goBack} = props;

    return (
        <div className="w-100 d-flex flex-column">
            <div className="col-12">
                <div className="row">
                    {Object.keys(data).map((item, i) => {
                        return (
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <p className="font-weight-bold mb-1">{data[item].name}</p>
                                <div className="d-flex flex-column">
                                    {data[item].before != null && data[item].before != undefined && (
                                        <span className="pl-2 text-justify" style={{textDecoration: 'line-through'}}>{data[item].before}</span>
                                    )}
                                    {data[item].after != null && data[item].after != undefined  && (
                                        <span className="pl-2 font-weight-bold text-success text-justify">{data[item].after}</span>
                                    )}
                                    {data[item].data && (
                                        Object.keys(data[item].data).map((data_key, data_index) => {
                                            const item_data = data[item].data[data_key]
                                            return (
                                                <div className="d-flex flex-column pl-1">
                                                    <p className="font-weight-bold mb-1">{item_data.name}</p>
                                                    {item_data.before != null && item_data.before != undefined && (
                                                        <span className="pl-2 text-justify" style={{textDecoration: 'line-through'}}>{item_data.before}</span>
                                                    )}
                                                    {item_data.after != null && item_data.after != undefined && (
                                                        <span className="pl-2 font-weight-bold text-success text-justify">{item_data.after}</span>
                                                    )}
                                                </div>
                                            )
                                        })
                                    )}
                                    {data[item].list && (
                                        data[item].list.map((list_item, list_index) => {
                                            return (
                                                <div className="d-flex flex-column pl-1">
                                                    {list_item.no != null && list_item.no != undefined ? (
                                                        <p className="font-weight-bold mb-1"># {list_item.no}</p>
                                                    ) : (
                                                        <p className="font-weight-bold mb-1">#</p>
                                                    )}
                                                    {list_item.before != null && list_item.before != undefined && (
                                                        <span className="pl-2 text-justify" style={{textDecoration: 'line-through'}}>{list_item.before}</span>
                                                    )}
                                                    {list_item.after != null && list_item.after != undefined && (
                                                        <span className="pl-2 font-weight-bold text-success text-justify">{list_item.after}</span>
                                                    )}
                                                </div>
                                            )
                                        })
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const ReviewModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Review
                        </div>
                    </div>
                </div>
                <div className="row">
                    {data && data.id ? (
                        <React.Fragment>
                            <div className="form-group col-lg-6 col-md-12">
                                <label htmlFor="">Quality of Service</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.quality_service}
                                />
                            </div>
                            <div className="form-group col-lg-6 col-md-12">
                                <label htmlFor="">Responsiveness</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.responsiveness}
                                />
                            </div>
                            <div className="form-group col-lg-6 col-md-12">
                                <label htmlFor="">Professionalism</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.professionalism}
                                />
                            </div>
                            <div className="form-group col-lg-6 col-md-12">
                                <label htmlFor="">Value</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.value}
                                />
                            </div>
                            <div className="form-group col-lg-6 col-md-12">
                                <label htmlFor="">Flexibility</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.flexibility}
                                />
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="">Notes</label>
                                <textarea
                                    style={{ resize: "none" }}
                                    className="form-control text-area-h-auto"
                                    disabled="true"
                                    value={data.notes}
                                    rows={6}
                                />
                            </div>
                        </React.Fragment>
                    ): (
                        <div className="col-12">
                            <span>
                                No data
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};


const NoteModal = (props) => {
    //  State
    const { open, notes } = props;
    //  Bind
    const { onClose } = props;
    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-4 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Note
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <textarea
                            style={{ resize: "none" }}
                            className="form-control text-area-h-auto"
                            disabled="true"
                            value={notes}
                            rows={6}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
