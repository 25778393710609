import { v4 as uuidv4 } from 'uuid';
import {handleActions} from 'redux-actions';
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";
import {api} from "api";
import {push} from "react-router-redux";
import {change, initialize} from "redux-form";
import Swal from 'sweetalert2';
import {SELECT_ALL_OPTION} from "../../../utility/constants"


// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_AGENT';
const SET_FILES_AGENT = 'SET_FILES_AGENT';
const SET_URL_TEMPLATE = 'SET_URL_TEMPLATE__AGENT';
const SET_OPTION_FILTER = 'SET_OPTION_FILTER_AGENT';
const SET_NAME_OFFICE__FILTER = 'SET_NAME_OFFICE__FILTER';
const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER_TO_AGENT_ROSTER';
const SET_OPTION_BROKER_FILTER = 'SET_OPTION_BROKER_FILTER__AGENT_ROSTER';
const SET_ID_BROKER_TO_FILTER = 'SET_ID_BROKER_TO_FILTER_IN_AGENT_ROSTER';
const SET_PROVIDE_AGENTS_ROSTER = 'SET_PROVIDE_AGENTS_ROSTER_IN_AGENT_ROSTER';
const SET_DOWNLOAD_LOADER = 'SET_DOWNLOAD_LOADER_IN_AGENT_ROSTER';
const TAB = 'TAB__AGENT';
const REQUEST_UUID = 'REQUEST_UUID_IN_AGENT_ROSTER';

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "agentRoster",
    "agent",
    "createAgentRosterForm",
    "/unregistered",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setOption = offices => ({
    type: SET_OPTION_FILTER,
    offices,
});

const setFilterCategory = category => ({
    type: SET_CATEGORY_FILTER,
    category,
});

const setFilterOffice = idOffice => ({
    type: SET_NAME_OFFICE__FILTER,
    idOffice,
});

const setUrl = urlTemp => ({
    type: SET_URL_TEMPLATE,
    urlTemp,
});

const setSelectedBroker = brokerPk => ({
    type: SET_ID_BROKER_TO_FILTER,
    brokerPk,
});

const setOptionBroker = optionsBroker => ({
    type: SET_OPTION_BROKER_FILTER,
    optionsBroker,
});
export const setTab = tab => ({
    type: TAB,
    tab,
});
export const setProvideAgentsRoster = provide_agents_roster => ({
    type: SET_PROVIDE_AGENTS_ROSTER,
    provide_agents_roster,
});

const setDownloadLoader = (downloadLoader) => ({
    type: SET_DOWNLOAD_LOADER,
    downloadLoader,
});

export const setRequestUUID = (requestUUID) => ({
    type: REQUEST_UUID,
    requestUUID,
});

// ------------------------------------
// Actions
// ------------------------------------

const onChange = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('agent', {}, [{
        name: "rosters",
        file: data,
    }])
        .then((response) => {
            if (response.results === "already exist") {
                NotificationManager.info('File already exist', 'NOTIFICATIONS', 0);
            } else {
                dispatch(basicReducer.actions.toList());
            }
        })
        .catch(() => {
            NotificationManager.error('Upload file failed', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const toList2 = (page = 1, loader=true) => (dispatch, getStore) => {
    const store = getStore();
    const resource = store['agentRoster'];
    const params = {page};
    params.ordering = resource.ordering;
    // params.search = resource.search;
    const uuid = uuidv4()
    dispatch(setRequestUUID(uuid))
    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { state, city, franchise, broker, brokerage, unregistered_status, search, team, influencer } =
            AgentFiltersForm.values;
        // if (state !== "" && state !== null && state !== undefined) {
        //     params.brokerage__broker__state__in = state;
        // }
        // if (franchise !== "" && franchise !== null && franchise !== undefined) {
        //     params.brokerage__broker__franchise__id__in = franchise;
        // }
        // if (broker !== "" && broker !== null && broker !== undefined) {
        //     params.brokerage__broker__id__in = broker;
        // }
        // if (brokerage !== "" && brokerage !== null && brokerage !== undefined) {
        //     params.brokerage__id__in = brokerage;
        // }
        // if (unregistered_status !== "" && unregistered_status !== null && unregistered_status !== undefined) {
        //     params.account_status__in = unregistered_status;
        // }
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.brokerage__state__in = "null"
            }else {
                params.brokerage__state__in = state;
            }
        }
        if (city !== "" && city !== null && city !== undefined && city !== SELECT_ALL_OPTION["value"]) {
            if (city.length == 0) {
                params.brokerage__city__in = "null"
            }else {
                params.brokerage__city__in = city;
            }
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.brokerage__broker__franchise__id__in = 0
            }else {
                params.brokerage__broker__franchise__id__in = franchise;
            }
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.brokerage__broker__id__in = 0
            }else {
                params.brokerage__broker__id__in = broker;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage__id__in = 0
            }else {
                params.brokerage__id__in = brokerage;
            }
        }
        if (unregistered_status !== "" && unregistered_status !== null && unregistered_status !== undefined && unregistered_status !== SELECT_ALL_OPTION["value"]) {
            if (unregistered_status.length == 0) {
                params.account_status__in = 0
            }else {
                params.account_status__in = unregistered_status;
            }
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
        if (team !== "" && team !== null && team !== undefined && team !== SELECT_ALL_OPTION["value"]) {
            if (team.length == 0) {
                params.influencer_team_id = 0
            }else {
                params.influencer_team_id = team;
            }
        }
        if (influencer !== "" && influencer !== null && influencer !== undefined && influencer !== SELECT_ALL_OPTION["value"]) {
            if (influencer.length == 0) {
                params.influencer_team__influencer_id = 0
            }else {
                params.influencer_team__influencer_id = team;
            }
        }
    }
    // params.account_status = resource.category;
    // params.brokerage__broker__id = resource.brokerPk;
    // params.brokerage__id = resource.idOffice;
    if (loader) {
        dispatch(setLoader(true));
    }
    api.get('agent', params)
        .then((response) => {
            const store = getStore()
            const otherUUID = store.agentRoster.requestUUID;
            if (otherUUID == uuid) {
                dispatch(basicReducer.actions.setData(response));
                dispatch(basicReducer.actions.setPage(page));
            }
        })
        .catch(() => {
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader(false));
            }
        });
};

const setFilesAgent = (files) => (dispatch) => {
    dispatch({
        type: SET_FILES_AGENT,
        files,
    });
};

const getOffices = (broker_id = '') => (dispatch) => {
    dispatch(setLoader(true));
    api.get('brokerage/option_select', {broker_id})
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = [{
                    label: 'All',
                    value: '',
                }, ...response.results];
            }else {
                option = [...response.results];
                dispatch(change('agentRosterForm', 'filterOffice', response.results[0].value))
            }
            dispatch(setOption({results: option}));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setCategoryFilter = (category) => (dispatch, getStore) => {
    const page = getStore()['agentRoster'].page;
    dispatch(setFilterCategory(category.value));
    setTimeout(() => {
        dispatch(toList2(1));
    }, 50);
};

const setOfficeFilter = (office) => (dispatch, getStore) => {
    const page = getStore()['agentRoster'].page;
    dispatch(setProvideAgentsRoster(office ? office.provide_agents_roster : null))
    dispatch(setFilterOffice(office.value));
    dispatch(toList2(1));
};

const sendAgentInvite = (id) => (dispatch, getStore) => {
    const page = getStore()['agentRoster'].page;
    dispatch(setLoader(true));
    api.put(`agent/${id}/send_invite`)
        .then(response => {
            NotificationManager.success('Invite sent success', 'SUCCESS', 1000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const getBrokerOptionAction = () => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get('brokerage/option_broker')
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = [{
                    label: 'All',
                    value: '',
                }, ...response.results];
            }else {
                option = [...response.results];
                dispatch(change('agentRosterForm', 'filterBroker', response.results[0].value))
            }
            dispatch(setOptionBroker(option));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};


export const getUrlFileTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrl(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const uploadAgentList = (brokerage_id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('brokerage/upload_agent', {'brokerage_id': brokerage_id}, [{
        name: "agents",
        file: data,
    }])
        .then((response) => {
            NotificationManager.success('Agent roster uploaded successfully', 'SUCCESS', 3000);
            dispatch(toList2(1));
        })
        .catch((error) => {
            if (error && error.details && error.details === "already exist") {
                NotificationManager.info('Agent roster already exist', 'NOTIFICATIONS', 0);
            } else {
                NotificationManager.error('Error in transaction', 'ERROR', 3000);
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const updateRosterList = (brokerage_id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('brokerage/update_roster', {'brokerage_id': brokerage_id}, [{
        name: "file",
        file: data,
    }])
        .then((response) => {
            dispatch(toList2(1));
        })
        .catch((error) => {
            NotificationManager.error('Error updating roster list', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const downloadRosterList = () => (dispatch, getStore) => {
    dispatch(setDownloadLoader(true));
    const store = getStore();
    const resource = store['agentRoster'];
    let params = {
        ordering: resource.ordering
    };
    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { state, city, franchise, broker, brokerage, unregistered_status, search, team } =
            AgentFiltersForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.office_state = "null"
            }else {
                params.office_state = state;
            }
        }
        if (city !== "" && city !== null && city !== undefined && city !== SELECT_ALL_OPTION["value"]) {
            if (city.length == 0) {
                params.office_city = "null"
            }else {
                params.office_city = city;
            }
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (unregistered_status !== "" && unregistered_status !== null && unregistered_status !== undefined && unregistered_status !== SELECT_ALL_OPTION["value"]) {
            if (unregistered_status.length == 0) {
                params.status = 0
            }else {
                params.status = unregistered_status;
            }
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
        if (team !== "" && team !== null && team !== undefined && team !== SELECT_ALL_OPTION["value"]) {
            if (team.length == 0) {
                params.team = 0
            }else {
                params.team = team;
            }
        }
    }
    api.get('brokerage/roster_excel', params)
        .then((data) => {
            NotificationManager.info(
                "The download will start in a moment. Please do not reload the page until the file has been downloaded",
                "Download in progress!",
                8000
            );
            dispatch(waitDownload(data.id));
        }).catch((error) => {
            console.log('--- error downloadCurrentRosterList ---', error);
            let message = "Error obtaining data";
            if (error.details) message = error.details;
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setDownloadLoader(true));
        });
};

const waitDownload = (id) => (dispatch) => {
    let intervalPromise;

    function listener() {
        api.get('archive/download_status', { id })
            .then((resp) => {
                if (resp.status === 10) {
                    // PROCESANDO
                    dispatch(setDownloadLoader( true));
                } else if (resp.status === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let name = resp.file
                        ? resp.file.split("/media/archives/")[1]
                        : "current_roster_list.xlsx";

                    const context = {
                        name: name,
                        url: resp.file,
                    };

                    dispatch(setDownloadLoader( false));

                    //  DOWNLOAD EXCEL FILE
                    const a = document.createElement('a');
                    a.href = context.url;
                    a.download = context.name;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    NotificationManager.success('File downloaded successfully', 'SUCCESS', 3000);
                }
            })
            .catch((err) => {
                let msg = "An error occurred while downloading the file. Please try later";
                if (err.status) {
                    msg = err.notes;
                }
                dispatch(setDownloadLoader( false));
                clearInterval(intervalPromise);
                NotificationManager.error(msg, 'ERROR', 3000);
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};


const sendInviteToAllAgent = () => (dispatch, getStore) => {
    const store = getStore();
    const resource = store['agentRoster'];
    Swal.fire({
        title: 'Are you sure you want to invite all agents?',
        text: "",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, Invite!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            let params = {}
            const { AgentFiltersForm } = store.form;
            if (AgentFiltersForm && AgentFiltersForm.values) {
                const { state, city, franchise, broker, brokerage, unregistered_status } =
                    AgentFiltersForm.values;
                if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
                    if (state.length == 0) {
                        params.office_state = "null"
                    }else {
                        params.office_state = state;
                    }
                }
                if (city !== "" && city !== null && city !== undefined && city !== SELECT_ALL_OPTION["value"]) {
                    if (city.length == 0) {
                        params.office_city = "null"
                    }else {
                        params.office_city = city;
                    }
                }
                if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                    if (franchise.length == 0) {
                        params.franchise_id = "null"
                    }else {
                        params.franchise_id = franchise;
                    }
                }
                if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                    if (broker.length == 0) {
                        params.broker_id = "null"
                    }else {
                        params.broker_id = broker;
                    }
                }
                if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
                    if (brokerage.length == 0) {
                        params.brokerage_id = "null"
                    }else {
                        params.brokerage_id = brokerage;
                    }
                }
            }
            dispatch(setLoader(true));
            api.post(`brokerage/invite_all_agent`, params)
                .then(() => {
                    NotificationManager.success('Invites sent success', 'SUCCESS', 1000);
                })
                .catch((error) => {
                    if (error && error.detail) {
                        NotificationManager.error(error.detail, 'Error', 3000);
                    } else {
                        NotificationManager.error('Invite all agent failed', 'Error', 3000);
                    }
                }).finally(() => {
                dispatch(setLoader(false));
            })
        }
    });
}


const setValueBrokerFilter = (selected) => (dispatch, getStore) => {
    const page = getStore()['agentRoster'].page;
    dispatch(setSelectedBroker(selected.value));
    if (selected.value === '') {
        dispatch(getOffices())
    } else {
        dispatch(getOffices(selected.value));
    }
    dispatch(setFilterOffice(''));
    dispatch(change('agentRosterForm', 'filterOffice', ''));
    dispatch(toList2(1));
};

export const searchChange2 = search => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    setTimeout(() => {
        dispatch(toList2());
    }, 400);
};


const setToDefaultValueFilter = () => (dispatch) => {
    dispatch(setSelectedBroker(''));
    dispatch(setFilterCategory(''));
    dispatch(setFilterOffice(''));
}

const initializeFiltersForm = () => (dispatch, getStore) => {
    const resource = getStore()['agentRoster'];
    dispatch(initialize('agentRosterForm', {
        filterCategory: resource.category,
        filterBroker: resource.brokerPk ? resource.brokerPk : null,
        filterOffice: resource.idOffice ? resource.idOffice : null
    }));
    if (resource.brokerPk === '' || resource.brokerPk === null) {
        dispatch(getOffices())
    } else {
        dispatch(getOffices(resource.brokerPk));
    }
}

const newAgentRoster = (data, brokerage_id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`agent`, data, { brokerage_id })
        .then(() => {
            NotificationManager.success(
                "The agent roster was created successfully",
                "SUCCESS",
                1000
            );
            dispatch(push("/agents"));
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, "Error", 3000);
            } else {
                NotificationManager.error(
                    "The agent roster could not be created, try again later",
                    "Error",
                    3000
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const update = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`agent/${data.id}`, {...data, signed: false})
        .then((response) => {
            dispatch(push('/agents'));
            NotificationManager.success('Agent updated successfully', 'SUCCESS', 1000);
        })
        .catch((error) => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, "Error", 3000);
            } else {
                NotificationManager.error(
                    "The agent roster could not be edited, try again later",
                    "Error",
                    3000
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const removeAgentRoster = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`agent/${id}`).then(() => {
        NotificationManager.success('Agent roster removed', 'Success', 3000);
        dispatch(push("/agents"));
    }).catch((error) => {
        console.log("ERROR: ", error);
        NotificationManager.error('Agent roster was not removed, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// ------------------------------------
// Actions
// ------------------------------------

export const actions = {
    onChange,
    setFilesAgent,
    toList2,
    getOffices,
    uploadAgentList,
    getUrlFileTemplate,
    setCategoryFilter,
    setOfficeFilter,
    sendAgentInvite,
    searchChange2,
    setValueBrokerFilter,
    getBrokerOptionAction,
    setToDefaultValueFilter,
    sendInviteToAllAgent,
    ...basicReducer.actions,
    newAgentRoster,
    update,
    removeAgentRoster,
    initializeFiltersForm,
    downloadRosterList,
    updateRosterList,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_FILES_AGENT]: (state, {files}) => {
        return {
            ...state,
            files,
        };
    },
    [SET_OPTION_FILTER]: (state, {offices}) => {
        return {
            ...state,
            offices,
        };
    },
    [SET_CATEGORY_FILTER]: (state, {category}) => {
        return {
            ...state,
            category,
        };
    },
    [SET_NAME_OFFICE__FILTER]: (state, {idOffice}) => {
        return {
            ...state,
            idOffice,
        };
    },
    [SET_URL_TEMPLATE]: (state, {urlTemp}) => {
        return {
            ...state,
            urlTemp,
        };
    },
    [SET_OPTION_BROKER_FILTER]: (state, {optionsBroker}) => {
        return {
            ...state,
            optionsBroker,
        };
    }, [SET_ID_BROKER_TO_FILTER]: (state, {brokerPk}) => {
        return {
            ...state,
            brokerPk,
        };
    },
    [TAB]: (state, {tab}) => {
        return {
            ...state,
            tab,
        };
    },
    [SET_PROVIDE_AGENTS_ROSTER]: (state, {provide_agents_roster}) => {
        return {
            ...state,
            provide_agents_roster,
        };
    },
    [SET_DOWNLOAD_LOADER]: (state, {downloadLoader}) => {
        return {
            ...state,
            downloadLoader,
        };
    },
    [REQUEST_UUID]: (state, {requestUUID}) => {
        return {
            ...state,
            requestUUID,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    files: [],
    category: '',
    idOffice: '',
    urlTemp: '',
    brokerPk: '',
    optionsBroker: [],
    offices: {results: []},
    tab: 'Registered',
    downloadLoader: false,
    provide_agents_roster: null,
    ...basicReducer.initialState,
    requestUUID: null,
};

export default handleActions(reducers, initialState);
