import moment from "moment"
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";

import { renderDayPicker, renderAddressField, renderField, SelectField } from "../../../../Utils/renderField";

const validate = (values, props) => {
    let errors = {};
    if (!values.date) {
        errors.date = "This field is required";
    }
    if (values.descriptions && values.descriptions.length) {
        let DescriptionsErrors = [];
        values.descriptions.forEach((description, index) => {
            DescriptionsErrors[index] = {};
            if (!description.address) {
                DescriptionsErrors[index] = {
                    address: "This field is required",
                };
            }
            if (!description.start_time) {
                DescriptionsErrors[index] = {
                    start_time: "This field is required",
                };
            }
        })
        errors.descriptions = DescriptionsErrors;
    }
    return errors;
};

const renderAddresses = (props) => {
    const {
        disabled,
        fields,
        assignAddressData,
        timeItems,
        isDuration,
        meta: { error, submitFailed },
    } = props
    const isInvalid = error && submitFailed;

    return (
        <div className="col-sm-12">
            {fields.map((item, index) => {
                const itemValues = fields.get(index)
                let duration = itemValues.duration
                const justAdded = itemValues.just_added
                if (isDuration) {
                    const _duration = parseInt(duration.replace("    mins", ""))
                    const hours = parseFloat(_duration / 60).toFixed(1)
                    duration = `${hours}     ${hours > 1 ? 'hours' : 'hour'}`
                }
                // console.log('VALUE: ', itemValues);
                return (
                    <div
                        key={index}
                        className="form-group has-feedback col-12 mt-auto py-2 border">
                        <label htmlFor=""> Address #{index + 1}</label>
                        <Field
                            name={`${item}.address`}
                            label="street"
                            placeholder='Search an address'
                            component={renderAddressField}
                            type="text"
                            className="form-control"
                            disabled={disabled}
                            setCoords={(data)=> {
                                // console.log('COODS: ', data);
                                if (data) {
                                    assignAddressData(data, index, "COORDS")
                                }
                            }}
                            setCityState={(data) => {
                                // console.log('ADDRESS DATA: ', data);
                                if (data) {
                                    assignAddressData(data, index, "ADDRESS_DATA")
                                }
                            }}
                        />
                        <div className="row mt-3">
                            <div className="form-group has-feedback col-lg-4 col-md-4 col-sm-12">
                                <label htmlFor={`${item}.start_time`}>Start Time</label>
                                {itemValues.hired_set_time ? (
                                    <Field
                                        name={`${item}.start_time`}
                                        isMulti={false}
                                        placeholder="Select Time"
                                        disabled={disabled || justAdded}
                                        options={timeItems}
                                        component={SelectField}
                                        className="form-control"
                                        maxHeight="100px"
                                        set_value={(value) => {
                                            console.log("SELECT TIME: ", value);
                                            assignAddressData(value, index, "END_TIME")
                                        }}
                                    />
                                ): (
                                    <div className='form-control'>
                                        Time not set
                                    </div>
                                )}
                            </div>
                            <div className="form-group has-feedback col-lg-4 col-md-4 col-sm-12">
                                <label htmlFor={`${item}.duration`}>Duration</label>
                                {itemValues.hired_set_time ? (
                                    <div className='form-control'>
                                        {duration}
                                    </div>
                                ): (
                                    <div className='form-control'>
                                        Not set
                                    </div>
                                )}
                            </div>
                            <div className="form-group has-feedback col-lg-4 col-md-4 col-sm-12">
                                <label htmlFor={`${item}.date_end`}>End Time</label>
                                {itemValues.hired_set_time ? (
                                    <div className='form-control'>
                                        {itemValues.date_end}
                                    </div>
                                ): (
                                    <div className='form-control'>
                                        Time not set
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const Form = (props) => {
    //  State
    const { loader, disabled, item, descriptionsValue, showCompleteJobBtn } = props;
    //  Bind
    const { handleSubmit, assignAddressData, onCancel, changeDate, validateToCompleteJob, rAgentCancel, dAgentCancel, removeNoShowStatus } = props;
    const [isDuration, setIsDuration] = useState(false)
    const IN_PROGRESS = 30
    const HIRED = 25

    useEffect(() => {
      if (item) {
        //  IS DURATION
        const jobType = item.job_type;
        const jobTypeOption = item.job_type_option
        if (jobTypeOption) {
            setIsDuration(jobTypeOption.is_duration)
        } else {
            setIsDuration(jobType ? jobType.is_duration : false)
        }

      }
    }, [item])

    const startTimeItems = () => {
        let items = []
        const startTime = "07:00:00"
        const endTime = "21:00:00"
        let currentTime = startTime
        while (currentTime != endTime) {
            const _time = moment(currentTime, "kk:mm")
            items.push({label: _time.format("hh:mm A"), value: _time.format("kk:mm:ss")})
            currentTime = _time.add(15, 'minutes').format("kk:mm:ss")
        }

        return items
    }

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-1"
            onSubmit={handleSubmit}
        >
            <div className="row pt-2">
                <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12">
                    <label htmlFor="id"># Job</label>
                    <Field
                        autoFocus={false}
                        name="id"
                        placeholder=""
                        component={renderField}
                        disabled={true}
                        type="text"
                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12">
                    <label htmlFor="date">Date</label>
                    <Field
                        autoFocus={false}
                        name="date"
                        placeholder=""
                        component={renderDayPicker}
                        disabled={disabled}
                        initialDate={""}
                        type="text"
                        outputFormatt="YYYY-MM-DD"
                        onChange={(date) => {
                            if (date) {
                                let finalDate = ''
                                Object.keys(date).forEach((key) => {
                                    console.log('KEY: ', key);
                                    if (key <= 9) {
                                        finalDate += date[key]
                                    }
                                })
                                console.log('DATE1: ', finalDate);
                                changeDate(finalDate)
                            }
                        }}
                    />
                </div>
                <div className="form-group has-feedback col-lg-12 col-sm-12">
                    <label htmlFor="descriptions">Addresses</label>
                    <div className="row">
                        <FieldArray
                            name="descriptions"
                            component={renderAddresses}
                            disabled={disabled}
                            assignAddressData={assignAddressData}
                            timeItems={startTimeItems()}
                            isDuration={isDuration}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-5 justify-content-center flex-wrap align-items-center form-group has-feedback">
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 my-1'>
                    <button type="button" onClick={onCancel} disabled={disabled} className={`w-100 btn text-white btn-secondary`}>
                        Cancel
                    </button>
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 my-1'>
                    <button type="submit" className="w-100 btn btn-primary" disabled={disabled}>
                        Update
                    </button>
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 my-1'>
                    <button type="button" onClick={() => rAgentCancel(item.id, onCancel)} className="w-100 btn btn-danger" disabled={item && item.state_job <= IN_PROGRESS ? false : true}>
                        Cancel by R Agent
                    </button>
                </div>
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 my-1'>
                    <button type="button" onClick={() => dAgentCancel(item.id, onCancel)} className="w-100 btn btn-danger" disabled={item && (item.state_job >= HIRED && item.state_job <= IN_PROGRESS) ? false : true}>
                        Cancel by D Agent
                    </button>
                </div>
                {item && item.no_show && (
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 my-1'>
                        <button type="button" onClick={() => removeNoShowStatus(item.id, onCancel)} className="w-100 btn btn-danger">
                            Remove No Show
                        </button>
                    </div>
                )}
                <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 my-1'>
                    <button type="button" onClick={() => validateToCompleteJob(item.id, onCancel)} disabled={!showCompleteJobBtn} className={`w-100 btn btn-primary`}>
                        Complete
                    </button>
                </div>
            </div>
        </form>
    );
};

let EditJobForm = reduxForm({
    form: "editJobFrom", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector("editJobFrom");

EditJobForm = connect((state) => {
    const descriptionsValue = selector(state, 'descriptions');
    return {
        descriptionsValue
    };
})(EditJobForm);

export default EditJobForm;
