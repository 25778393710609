import React, {useState, useEffect} from 'react';
import moment from "moment";
import Avatar from "react-avatar";
import HeaderCard from "../../HeaderCard";
import TwilioCampaignFilterForm from "./TwilioCampaignFilterForm"
import Grid from "../../Utils/GridTableWithScroll";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {CAMPAIGNS_PAGE_SIZE} from "../../../../utility/constants";


const CampaignsGrid = (props) => {
    const {data, loader, page, searchAction, toList, remove} = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("TWILIO-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">Campaigns in Twilio</div>
                </div>
            </div>
            {/* <HeaderCard
                showFile={false}
                active_link={true}
                text_link={"Create"}
                id_header={'FranchisesList'}
                link={'campaigns/create'}
                justify={'justify-content-between mt-2'}
                searchAction={(value) => searchAction(value)}
                searchStyle="col-lg-4 col-sm-12 img-fluid mb-sm-1 ml-3"
            /> */}
            <TwilioCampaignFilterForm searchAction={searchAction}/>
            <div id="TWILIO-LIST" className="container-list card border-dark border-card-page px-4 py-2">
                <div className="card-body d-flex flex-column px-0 py-2">
                    <Grid
                        hover
                        height={gridHeight}
                        striped
                        page={page}
                        keyField={'id'}
                        loading={loader}
                        data={data ? data : []}
                        wrapperClasses="table-scroll-auto"
                        sizePerPage={CAMPAIGNS_PAGE_SIZE}
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        remove: remove,
                                        view: "campaigns",
                                        edit: 'campaigns',
                                    }),
                            },
                            {
                                dataField: "image",
                                formatter: avatar(),
                                text: " ",
                            },
                            {
                                dataField: "created",
                                formatter: dateCreated(),
                                text: "Date created",
                            },
                            {
                                dataField: "title",
                                sort: false,
                                text: "Title",
                            },
                            {
                                dataField: "text_message",
                                sort: false,
                                text: "Message",
                            },
                            {
                                dataField: "sended_date",
                                sort: false,
                                text: "Sent Date",
                                formatter: (cell, row) => {
                                    return (
                                        <div className='py-1'>
                                            {row.in_progress && (
                                                <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw text-info ml-3"></i>
                                            )}
                                            <span>{cell ? moment(cell).format('ll') : ''}</span>
                                        </div>
                                    )
                                }
                            },
                            {
                                dataField: "total_to_sent",
                                sort: false,
                                text: "Total Sent",
                                align: "center",
                                formatter: (cell, row) => {
                                    if (row.sendgrid_type == 3000) {
                                        return null
                                    }
                                    return (
                                        <div className='py-1'>
                                            {row.total_sent}/{row.total_to_sent}
                                        </div>
                                    )
                                }
                            },
                        ]}
                    />
                </div>
            </div>

        </div>
    );
};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('LLL')}
            </p>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="43"
                    src={cell}
                    // round={true}
                    name={row.title}
                />
            </div>
        );
    };
}


export default CampaignsGrid;
