import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import VendorClientFeedbackForm from "./CreateUpdateVendorClientFeedbackForm";

export default class CreateUpdateVendorClientFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
        };
    }

    componentWillMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
        }
        if (match.path === '/vendor/client-feedback/:id/edit') {
            this.setState({is_update: true});
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        //  State
        const { loader, match: {params: {id}} } = this.props;
        const { is_update } = this.state;
        //  Bind
        const {create, edit} = this.props

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_update && "Update Client Feedback Form"}
                            {!is_update && "Create Client Feedback Form"}
                        </div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <VendorClientFeedbackForm
                            onSubmit={(data) => {
                                if (is_update) {
                                    edit(
                                        id,
                                        data,
                                        this.goBack
                                    )
                                } else {
                                    create(
                                        data,
                                        this.goBack
                                    )
                                }
                            }}
                            goBack={this.goBack}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}
