import React, {useState} from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    renderField,
    renderTextArea,
    renderFileImageCrop,
} from "../../Utils/renderField";

let Form = (props) => {
    const {
        handleSubmit,
        loader,
        proTipIcon,
        proTipDownloadIcon,
        encourageIcon,
    } = props;
    //  Bind
    const {
        goBack,
        setProTipIcon,
        setProTipDownloadIcon,
        setEncourageIcon,
    } = props;

    return (
        <div
            className="card flex-1 border-dark border-card-page px-4 py-4"
            style={{
                minWidth: "99%",
            }}
        >
            <form
                name="brokerageForm"
                className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
                onSubmit={handleSubmit}
            >
                <LoadMask loading={loader} light>
                    <div className="row">
                        <h5>Pro Tip</h5>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-3">
                            <label htmlFor="open_house_pro_tip_icon">Icon</label>
                            <div className="w-100">
                                <Field
                                    name='open_house_pro_tip_icon'
                                    photo={proTipIcon ? proTipIcon : undefined}
                                    setFile={setProTipIcon}
                                    spectValue={null}
                                    locked={false}
                                    component={renderFileImageCrop}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3">
                            <label htmlFor="open_house_pro_tip_download_icon">Download Icon</label>
                            <div className="w-100">
                                <Field
                                    name='open_house_pro_tip_download_icon'
                                    photo={proTipDownloadIcon ? proTipDownloadIcon : undefined}
                                    setFile={setProTipDownloadIcon}
                                    spectValue={null}
                                    locked={false}
                                    component={renderFileImageCrop}
                                />
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="open_house_pro_tip_title">Title</label>
                            <Field
                                name="open_house_pro_tip_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="open_house_pro_tip_text">Description</label>
                            <Field
                                name="open_house_pro_tip_text"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <h5>Encourage</h5>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-3">
                            <label htmlFor="open_house_encourage_icon">Icon</label>
                            <div className="w-100">
                                <Field
                                    name='open_house_encourage_icon'
                                    photo={encourageIcon ? encourageIcon : undefined}
                                    setFile={setEncourageIcon}
                                    spectValue={null}
                                    locked={false}
                                    component={renderFileImageCrop}
                                />
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-3 col-sm-12">
                            <label htmlFor="open_house_encourage_title">Title</label>
                            <Field
                                name="open_house_encourage_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="open_house_encourage_text">Top Description</label>
                            <Field
                                name="open_house_encourage_text"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="open_house_encourage_text_button">Bottom Description</label>
                            <Field
                                name="open_house_encourage_text_button"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="w-100 my-4 mx-auto text-center">
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={goBack}
                        >
                            Back
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                            disabled={loader}
                        >
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        </div>
    )
}

let OpenHouseTextForm = reduxForm({
    form: "openHouseTextForm", // a unique identifier for this form
})(Form);

const selector = formValueSelector("openHouseTextForm");

OpenHouseTextForm = connect((state) => {
    const proTipIcon = selector(state, "open_house_pro_tip_icon");
    const proTipDownloadIcon = selector(state, "open_house_pro_tip_download_icon");
    const encourageIcon = selector(state, "open_house_encourage_icon");
    return {
        proTipIcon,
        proTipDownloadIcon,
        encourageIcon,
    };
})(OpenHouseTextForm);

export default OpenHouseTextForm;
