import React, { Component } from 'react';
import {BrokerRawDataGrid} from './BrokerRawDataComponent';

class BrokerRawDataList extends Component {
    componentDidMount() {
        this.props.getDataBrokerRawData();
    }
    changePage = (page) => {
        this.props.getDataBrokerRawData(page);
    }

    render() {
        //  Status
        const { dataStats, loader, page, downloadLoader } = this.props;
        //  Bind
        const { downloadBrokerRawDataReport } = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Stats / Broker raw data
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-2 w-100 mb-3">
                    <button className="btn btn-primary text-white col-lg-2 col-sm-10"
                        disabled={downloadLoader === true}
                        onClick={downloadBrokerRawDataReport}>
                        <i className={`fa ${!downloadLoader ? "fa-download" : "fa-spinner fa-pulse fa-fw"} mr-2`} />
                        Download Report
                    </button>
                </div>
                <BrokerRawDataGrid
                    page={page}
                    data={dataStats}
                    loader={loader}
                    nextPage={this.changePage}
                />
            </div>
        );
    }
}

export default BrokerRawDataList;
