import Select from 'react-select';
import classNames from 'classnames';
import { Modal } from "react-responsive-modal";
import { Bar, Doughnut } from "react-chartjs-2";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, {useEffect, useRef, useState} from "react";

const YearField = (props) => {
    //  State
    const {value} = props
    //  Bind
    const {setValue} = props;
    const [options, setOptions] = useState([])


    useEffect(() => {
        const date = new Date()
        let _options =[]
        const currentYear = date.getFullYear();
        const minYear = 2022;
        const diffYear = (currentYear - minYear) + 1;
        const lengthYear = diffYear <= 50 ? diffYear : 50;
        for (let x = 0; x < lengthYear; x++) {
            const year  = currentYear - x
            if (year>= minYear) {
                _options.push({
                    label: year.toString(),
                    value: year
                })
            }
        }
        setOptions(_options)
    }, [])
    


    return (
        <Select
            className={classNames('react-select-container')}
            backspaceRemovesValue={false}
            // isMulti={isMulti}
            // isSearchable={isSearchable}
            options={options}
            // placeholder={placeholder}
            onChange={(e) => {
                setValue && setValue(e);
            }}
            value={value}
            // isDisabled={disabled}
            theme={theme => ({
                ...theme,
                borderRadius: 5,
                borderLeft: "1px solid #C7C7C7",
                borderRight: "1px solid #C7C7C7",
                borderTop: "1px solid #C7C7C7",
                borderBottom: "1px solid #C7C7C7",
                fontSize: "12px !important",
                color: '7b7b7b !important',
                colors: {
                    ...theme.colors,
                    primary25: '#F1F1F1', // style to select one option
                    primary: '#7E8A97',  // style of option selected
                },
            })}
        />
    )
}

export const MonthlyPRofiteChart = (props) => {
    //  State
    const { data, loader, year } = props;
    //  Bind
    const {changeYear} = props;

    const options = {
        responsive:true,
        maintainAspectRatio:false,
        defaultFontSize:"14px",
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        plugins: {
            legend: {
                display: false,
                color: "#282828"
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: "14", family: "Poppins", weight: 'bold' },
                formatter: function (value, context) {
                    if (value > 0) {
                        return value
                    }else {
                        return ''
                    }
                },
            }
        }
    };

    return (
        <div className="card border-card-page h-100">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-0 px-2 mb-lg-0 mb-sm-1 my-auto w-100 align-self-center text-center label-semi-bold">
                    Monthly profit
                </label>
            </div>
            <div className="w-50 px-2 form-group mb-0">
                <label className="ml-1 label-semi-bold mt-2">Year</label>
                <div className="w-100 px-1 mb-2">
                    <YearField value={year} setValue={(e) => {
                        console.log("Value: ", e);
                        changeYear(e);
                    }} />
                </div>
            </div>

            <LoadMask loading={loader} dark blur>
                <div className="px-2 flex-1 pb-3 dashboard-graph">
                    <Bar data={data} options={options} plugins={[ChartDataLabels]} height={300} />
                </div>
            </LoadMask>
        </div>
    )
}

export const AnnualyProfiteChart = (props) => {
    //  State
    const { data, loader } = props;

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                font: { size: "14", family: "Poppins", weight: 'bold' },
                formatter: function (value, context) {
                    const data = context.chart.data;
                    let total = 0;
                    const dataLabels = context.chart.legend && context.chart.legend ? context.chart.legend.legendItems : [];
                    const activeLabels = _.filter(dataLabels, {hidden: false})
                    const _lastLabel = activeLabels.length ? activeLabels[activeLabels.length - 1] : null
                    
                    data.datasets.forEach(item => {
                        const isActive = _.find(activeLabels, {text: item.label})
                        const _val = isActive ? item.data[context.dataIndex] : 0;
                        if (_val > 0 && isActive != undefined) {
                            total += _val;
                        }
                    })
                    
                    if (_lastLabel && context.dataset.label.toUpperCase().includes(_lastLabel.text.toUpperCase())) {
                        if (total > 0) {
                            // setTotalBars(totalBars + 1)
                            return `$ ${total.toFixed(2)}`;
                        }else {
                            return ''
                        }
                    }else {
                        return ''
                    }
                },
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 0,
                left: 0,
                bottom: 0
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                grid: {
                    drawBorder: false,
                    color: function (context) {
                        return "#FFFFFF";
                    },
                },
                ticks: {
                    font: {
                        size: 11,
                        weight: 'bold',
                        lineHeight: 1.2,
                        family: "Poppins",
                    },
                },
            },
            y: {
                stacked: true,
                display: true,
                suggestedMin: 0,
                ticks: {
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: "Poppins",
                        min: 0,
                        // forces step size to be 50 units
                        // stepSize: 50
                    },
                },
            },
        },
        animation: false,
        maintainAspectRatio: false,
    };

    return (
        <div className="card border-card-page h-100">
            {/*------------------------------------------*/}
            {/*------------ title -----------*/}
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-0 px-2 mb-lg-0 mb-sm-1 my-auto w-100 align-self-center text-center label-semi-bold">
                    Annually profit
                </label>
            </div>

            <LoadMask loading={loader} dark blur>
                <div className="px-2 flex-1 pb-3 dashboard-graph mt-4">
                    <Bar data={data} options={options} plugins={[ChartDataLabels]} height={355} />
                </div>
            </LoadMask>
        </div>
    )
}

export const AgentsChart = (props) => {
    //  State
    const { total, registered, loader } = props;

         
    const data = {
        labels: ['Registered Agents', 'Unregistered'],
        datasets: [
            {
                data: [registered, (total - registered) === 0 ? 0 : total - registered],
                backgroundColor: ['#147AD6', '#E28B2A'],
            },
        ],
    };

    return (
        <div className="card d-flex flex-column border-card-page h-100">
            <div className="card-title border-bottom py-3 mb-0">
                <label className="p-0 mx-0 px-2 mb-lg-0 mb-sm-1 my-auto w-100 align-self-center text-center label-semi-bold">
                    Signed Agents
                </label>
            </div>
            <div classNae="px-2 pb-3 dashboard-graph flex-column align-items-center justify-content-center" style={{position: 'relative', flex: 1, display: 'flex'}}>
                <Doughnut
                    style={{alignSelf: 'center'}}
                    data={data}
                    options={{
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                                align: 'center',
                            },
                            datalabels: {
                                display: true,
                                color: "black",
                                font: { size: "14", family: "Poppins", weight: 'bold' },
                                formatter: function (value, context) {
                                    let percent = 0;
                                    if (total > 0){
                                        percent = parseFloat(((value * 100) / total).toFixed(1))
                                    }   
                                    return `${percent} %`
                                }
                            }
                        },
                        animation: false,
                    }}
                    width={10}
                    redraw={false}
                    plugins={[ChartDataLabels]}
                />
            </div>
        </div>
    )
}


export const InfluencerLinks = (props) => {
    //  State
    const {open, brokerLink, hasTeam=false, teamId=null} = props;
    //  Bind
    const {onClose} = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-9 col-11", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="col-sm-12">
                    {hasTeam && teamId && (
                        <div className="row mt-3">
                            <div className="col-lg-5 col-sm-12 d-flex flex-column form-group has-feedback d-flex flex-column justify-content-center">
                                <label className="h5 mb-1" htmlFor="">
                                    Sign up link for agents
                                </label>
                                <small>Provide this link to your real estate agents, so once registered to ShowIT Solutions™ app they will be part of your team and your ShowIT Share™.</small>
                            </div>
                            <div className="col-lg-5 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <a className="pointer text-primary" href={`https://share.showitsolutions.com/auth/register/?team_id=${teamId}`}>{`https://share.showitsolutions.com/auth/register/?team_id=${teamId}`}</a>
                            </div>
                            <div className="col-lg-2 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <img style={{width: '100%'}} src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=https://share.showitsolutions.com/auth/register/?team_id=${teamId}&choe=UTF-8`} alt="" />
                            </div>
                        </div>
                    )}
                    {brokerLink && (
                        <div className="row mt-3">
                            <div className="col-lg-5 col-sm-12 d-flex flex-column form-group has-feedback d-flex flex-column justify-content-center">
                                <label className="h5 mb-1" htmlFor="">
                                    Sign up link for Brokers
                                </label>
                                <small>Provide this link to brokers & office owners/managers, so once registered to ShowIT Solutions™ app their Brokerage/office will be part of your team and your ShowIT Share™.</small>
                            </div>
                            <div className="col-lg-5 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <a className="pointer text-primary" href={`${brokerLink}`}>{`${brokerLink}`}</a>
                            </div>
                            <div className="col-lg-2 col-sm-12 form-group has-feedback d-flex flex-column justify-content-center">
                                <img style={{width: '100%'}} src={`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${encodeURIComponent(brokerLink)}&choe=UTF-8`} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}