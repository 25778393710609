import { connect } from 'react-redux';
import { actions } from "../../../redux/modules/feedbackFormClient";
import feedbackFormClientList from './feedbackFormClientList';


const ms2p = (state) => {
    return {
        ...state.feedbackFormClient,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(feedbackFormClientList);
