import {connect} from "react-redux";
import React, {Component} from 'react';
import {
    getDateRange,
    getMonthsLabel,
    getMonthsRange,
    LabelDaysOfWeek, NEW_MONTH_LIST,
} from "../../../utility/constants";
import RevenueCard from "./ChartRevenue/RevenueCard";
import PerformanceCard from "./ChartPerfomance/PerfomanceCard";
import './dashboard.css';
import {ShowITChart, JobChart, BrokerOffice} from "./DashboardComponents"
import {reduxForm, formValueSelector, Field} from "redux-form";
import DoughnutAgent from './DoughnutAgent/DoughnutAgent';
import DashboardFiltersForm from './DashboardFiltersForm'

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const {
            getBrokerOption,
            getFranchisesOption,
            getCategoriesOption,
            getDataShowIT,
            getAllJobType,
            getDataJob,
            getDataRevenueAgents,
            getDataBrokerOffice,
            getDataShowITCategories,
            getDataJobCategories,
            initFilters,
        } = this.props;

        //  Filters
        getAllJobType();
        getBrokerOption(null, true);
        getFranchisesOption(null, true);
        getCategoriesOption(null, true);
        //  Stats and Graph
        getDataShowIT();
        getDataShowITCategories();
        getDataJob();
        getDataJobCategories();
        getDataRevenueAgents();
        getDataBrokerOffice();
        initFilters();
        this.dashboardInterval = setInterval(this.refreshData, 60000)
    }

    componentWillUnmount() {
        if (this.dashboardInterval) {
            clearInterval(this.dashboardInterval)
        }
    }

    // filterMonthAction = (itemSelect) => {
    //     this.props.filterMonth(itemSelect);
    // }

    // filterWeekAction = (itemSelect) => {
    //     this.props.filterWeek(itemSelect);
    // }

    refreshData = () => {
        const {
            getDataShowIT,
            getDataJob,
            getDataRevenueAgents,
            getDataBrokerOffice,
            getDataShowITCategories,
            getDataJobCategories,
        } = this.props;
        getDataShowIT(false);
        getDataShowITCategories(false);
        getDataJob(false);
        getDataJobCategories(false);
        getDataRevenueAgents();
        getDataBrokerOffice();
    }

    render() {
        const {
            data,
            loader,
            loader2,
            brokers,
            brokerages,
            franchises,
            categories,
            jobNames,
            dataShowIT,
            showITRanger,
            dataJob,
            jobRanger,
            dataRevenueAgents,
            dataBrokerOffice,
            dataCategory,
            dataShowITCategory,
        } = this.props;

        //  Bind
        const { changeFilters, setRanger, goToJobRawData } = this.props;

        return (
            <div className="w-100 px-1 pb-5">
                <div className="page-header pl-1 pt-3 no-gutters row justify-content-between w-100 mb-0">
                    <div className="">
                        <div className="header-page">Dashboard</div>
                    </div>
                </div>
                {/* FILTERS */}
                <DashboardFiltersForm
                    optionFranchises={franchises}
                    optionBrokers={brokers}
                    optionBrokerages={brokerages}
                    optionCategories={categories}
                    changeFilters={changeFilters}
                />
                <div className="row px-1">
                    <div className="row w-100 mx-0 px-0 mb-sm-2 mb-lg-0">
                        {/* ShowIT */}
                        <ShowITChart
                            loader={loader}
                            data={dataShowIT}
                            jobNames={jobNames}
                            ranger={showITRanger}
                            setRanger={setRanger}
                            dataShowITCategory={dataShowITCategory}
                        />
                        {/*-------------------------------------------------*/}
                        {/*------------------- CARD RIGHT TOP  -------------*/}
                        {/*-------------------------------------------------*/}
                        {/* Job */}
                        <JobChart
                            loader={loader2}
                            data={dataJob}
                            jobNames={jobNames}
                            ranger={showITRanger}
                            setRanger={setRanger}
                            dataCategory={dataCategory}
                        />
                    </div>
                </div>
                {/*CARD SMALL*/}
                <div className="row mt-3">
                    {/* REVENUE */}
                    <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-3"
                        style={{ height: "inherit" }}
                    >
                        <div className="card border-card-page border-card-20 h-100">
                            <div className="card-title row pt-3 px-3">
                                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                                    Revenue
                                </label>
                            </div>
                            <div className="row px-1 px-lg-2 w-100 m-auto py-2 py-lg-2">
                                <div className="col-lg-4 col-sm-12 text-center m-auto">
                                    <h6 className="row text-info justify-content-center font-weight-bold text-12 mb-0 pb-1 pointer" onClick={(e) => goToJobRawData('5,25')}>
                                        Total Pending Jobs
                                    </h6>
                                    <h5 className=" text-dark row justify-content-center font-weight-bold mb-0 ">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_to_revenue
                                            ? dataRevenueAgents.total_to_revenue
                                                  .total
                                                ? dataRevenueAgents
                                                      .total_to_revenue.total
                                                : 0
                                            : 0}
                                    </h5>
                                    <h5 className=" text-dark row justify-content-center font-weight-bold pt-1 ">
                                        US $
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_to_revenue
                                            ? dataRevenueAgents.total_to_revenue
                                                  .fee
                                                ? dataRevenueAgents.total_to_revenue.fee.toFixed(
                                                      2
                                                  )
                                                : "0.00"
                                            : "0.00"}
                                    </h5>
                                </div>
                                <div className="text-center col-lg-8 col-sm-12 m-auto pt-sm-2 pt-lg-0">
                                    <div className="row border-bottom">
                                        <div className="col-4 border-right label-middle-dashboard py-0 px-1 text-info pointer" onClick={(e) => goToJobRawData('5')}>
                                            Posted Jobs
                                        </div>
                                        <div className="col-4 border-right label-middle-dashboard py-0 px-1 text-info pointer" onClick={(e) => goToJobRawData('5')}>
                                            Applied Jobs
                                        </div>
                                        <div className="col-4 label-middle-dashboard py-0 px-1 text-info pointer" onClick={(e) => goToJobRawData('25')}>
                                            Hired/Claimed Jobs
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 border-right py-0 px-1">
                                            <div className="label-middle">
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.posted
                                                    ? dataRevenueAgents.posted
                                                          .total
                                                        ? dataRevenueAgents
                                                              .posted.total
                                                        : 0
                                                    : 0}
                                            </div>
                                            <div className="label-middle">
                                                US $
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.posted
                                                    ? dataRevenueAgents.posted
                                                          .fee
                                                        ? dataRevenueAgents.posted.fee.toFixed(
                                                              2
                                                          )
                                                        : "0.00"
                                                    : "0.00"}
                                            </div>
                                        </div>
                                        <div className="col-4 border-right label-middle py-0 px-1">
                                            <div className="label-middle">
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.applied
                                                    ? dataRevenueAgents.applied
                                                          .total
                                                        ? dataRevenueAgents
                                                              .applied.total
                                                        : 0
                                                    : 0}
                                            </div>
                                            <div className="label-middle">
                                                US $
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.applied
                                                    ? dataRevenueAgents.applied
                                                          .fee
                                                        ? dataRevenueAgents.applied.fee.toFixed(
                                                              2
                                                          )
                                                        : "0.00"
                                                    : "0.00"}
                                            </div>
                                        </div>
                                        <div className="col-4 label-middle py-0 px-1">
                                            <div className="label-middle">
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.pending
                                                    ? dataRevenueAgents.pending
                                                          .total
                                                        ? dataRevenueAgents
                                                              .pending.total
                                                        : 0
                                                    : 0}
                                            </div>
                                            <div className="label-middle">
                                                US $
                                                {dataRevenueAgents &&
                                                dataRevenueAgents.pending
                                                    ? dataRevenueAgents.pending
                                                          .fee
                                                        ? dataRevenueAgents.pending.fee.toFixed(
                                                              2
                                                          )
                                                        : "0.00"
                                                    : "0.00"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-3"
                        style={{ height: "inherit" }}
                    >
                        <div className="card border-card-page border-card-20 h-100">
                            <div className="card-title row pt-3 px-3 mb-0">
                                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 my-auto align-self-center label-semi-bold">
                                    Agents
                                </label>
                            </div>
                            <div className="row w-100 m-auto pb-2">
                                <div className="col-lg-2 col-sm-12 px-1 text-center m-auto border-right">
                                    <div className="text-dark justify-content-center font-weight-bold text-12 mb-0 pb-1">
                                        Total Agents
                                    </div>
                                    <h5 className=" text-dark justify-content-center font-weight-bold mb-0 ">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_agent
                                            ? dataRevenueAgents.total_agent
                                            : 0}
                                    </h5>
                                </div>
                                <div className="col-lg-2 col-sm-6 px-1 text-center m-auto">
                                    <div className="justify-content-center label-middle font-weight-bold">
                                        Registered
                                    </div>
                                    <div className="justify-content-center label-middle font-weight-bold mt-3">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_user
                                            ? dataRevenueAgents.total_user
                                            : 0}
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6 px-1 text-center m-auto">
                                    <div className="justify-content-center label-middle font-weight-bold">
                                        Active
                                    </div>
                                    <div className="justify-content-center label-middle font-weight-bold mt-3">
                                        {dataRevenueAgents &&
                                        dataRevenueAgents.total_user_active
                                            ? dataRevenueAgents.total_user_active
                                            : 0}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 px-1 text-center m-auto d-flex flex-column align-items-center justify-content-center pt-lg-0 pt-3">
                                    <div className="justify-content-center label-middle font-weight-bold d-lg-block d-none">
                                        Registered
                                    </div>
                                    <DoughnutAgent
                                        total={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_agent
                                                ? dataRevenueAgents.total_agent
                                                : 0
                                        }
                                        count={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_user
                                                ? dataRevenueAgents.total_user
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-lg-3 col-sm-6 px-1 text-center m-auto d-flex flex-column align-items-center justify-content-center pt-lg-0 pt-3">
                                    <div className="justify-content-center label-middle font-weight-bold d-lg-block d-none">
                                        Active
                                    </div>
                                    <DoughnutAgent
                                        total={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_user
                                                ? dataRevenueAgents.total_user
                                                : 0
                                        }
                                        count={
                                            dataRevenueAgents &&
                                            dataRevenueAgents.total_user_active
                                                ? dataRevenueAgents.total_user_active
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BROKER AND OFFICE */}
                    <BrokerOffice data={dataBrokerOffice} />
                </div>
            </div>
        );
    }
}

export default Dashboard;
