import React, { Component } from "react";
import TrackerFilterForm from "./TrackerFilter";
import TrackerGrid from "./TrackerGrid";

export class TrackerList extends Component {
    componentDidMount() {
        this.props.toList();
        this.props.getOptions();
    }

    render() {
        // State
        const {
            loader,
            page,
            data,
            owners,
            severities,
            types,
            statuses,
            agreements,
            noteModal,
            reminderModal,
        } = this.props;
        //  Bind
        const {
            toList,
            searchChange,
            onSortChange,
            remove,
            addRow,
            edit,
            closeNoteModal,
            openNoteModal,
            saveNote,
            deleteNote,
            openReminderModal,
            closeReminderModal,
            saveReminder,
        } = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Tracker List</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <TrackerFilterForm
                        owners={owners}
                        severities={severities}
                        types={types}
                        statuses={statuses}
                        agreements={agreements}
                        searchAction={searchChange}
                    />
                </div>

                <TrackerGrid
                    data={data}
                    owners={owners}
                    severities={severities}
                    types={types}
                    statuses={statuses}
                    agreements={agreements}
                    page={page}
                    loader={loader}
                    toList={toList}
                    onSortChange={onSortChange}
                    remove={remove}
                    addRow={addRow}
                    edit={edit}
                    noteModal={noteModal}
                    reminderModal={reminderModal}
                    openNoteModal={openNoteModal}
                    closeNoteModal={closeNoteModal}
                    saveNote={saveNote}
                    deleteNote={deleteNote}
                    openReminderModal={openReminderModal}
                    closeReminderModal={closeReminderModal}
                    saveReminder={saveReminder}
                />
            </div>
        );
    }
}

export default TrackerList;
