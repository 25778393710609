import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_SETTING';
const ITEM = 'ITEM_SETTING';
const endpoint = 'vendor_setting'

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});
export const setItem = item => ({
    type: ITEM,
    item,
});

// ------------------------------------
// Actions
// ------------------------------------

const update = (data = {}, files = [], resetIcons=null) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let finalFile = []
    console.log("FILES: ", files);
    if (files && files.length) {
        files.map(itemFile => {
            if (itemFile !== undefined) {
                finalFile.push(itemFile)
            }
        })
    }
    console.log('FINAL FILES: ', finalFile);
    api.putAttachments(`${endpoint}/${data.id}`, data, finalFile).then((response) => {
        dispatch(getItem());
        if (resetIcons) {
            resetIcons()
        }
        NotificationManager.success('Setting updated', 'SUCCESS', 3000);
    }).catch((error) => {
        console.log('ERROR[UPDATE-SETTINGS]: ', error);
        NotificationManager.error('Fail to update Setting, please try again', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getItem = () => (dispatch) => {
    dispatch(initializeForm("vendorSettingForm", {}));
    api.get(endpoint)
        .then((response) => {
            dispatch(initializeForm("vendorSettingForm", response));
            dispatch(setItem(response));
        })
        .catch((error) => {
            console.log('ERROR[GET-SETTINGS]: ', error);
            NotificationManager.error('It was not possible to get the data, please try again', 'ERROR', 3000);
        })
        .finally(() => {});
};

export const actions = {
    getItem,
    update,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    item: {},
};

export default handleActions(reducers, initialState);

