import React, {useState, useEffect} from 'react';
import Avatar from 'react-avatar';
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import GridTableWithScroll from "../../../Utils/GridTableWithScroll";
import {STATS_PERFORMED_PAGE_SIZE} from "../../../../../utility/constants";
import {formatPhoneNumber} from "../../../Brokerage/BrokerageGrid";


const PerformanceGrid = (props) => {
    const {loader, data, nextPage} = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("STATS-AGENT-PERFORMANCE");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])
    return (
        <div id="STATS-AGENT-PERFORMANCE" className="container-list card border-dark border-card-page px-4 py-2">
            <div className="card-body px-0 py-2 d-flex flex-column">
                <GridTableWithScroll
                    hover
                    height={gridHeight}
                    data={data}
                    remote={true}
                    wrapperClasses={'table-scroll-auto'}
                    loading={loader}
                    keyField={'agent_id'}
                    sizePerPage={STATS_PERFORMED_PAGE_SIZE}
                    onPageChange={(value) => nextPage(value)}
                    // rowEvents={{
                    //     onClick: (e, row, rowIndex) => {
                    //         goPage(`/stats/agent-performance/${row.agent_id}/details`)
                    //     }
                    // }}
                    columns={[
                        {
                            dataField: "id",
                            formatter: (cell, row) => {
                                return (
                                    <Link
                                        to={`/stats/agent-performance/${cell}/details`}
                                        className="mx-2"
                                    >
                                        <img
                                            title="View"
                                            className="custom-container-icon"
                                            src={require("../../../../../../assets/img/view-details.svg")}
                                            alt="viewSVG"
                                        />
                                    </Link>
                                );
                            },
                            text: "",
                        },
                        {
                            dataField: "photo",
                            formatter: avatar(),
                            text: "",
                        },
                        {
                            dataField: "first_name",
                            short: true,
                            text: "First Name",
                        },
                        {
                            dataField: "last_name",
                            short: true,
                            text: "Last Name",
                        },
                        {
                            dataField: "brokerage_information.firm_name",
                            formatter: (cell, row) => {
                                if (row.brokerage_information.id) {
                                    return (
                                        <Link
                                            onClick={(e) => e.stopPropagation()}
                                            to={`/offices/${row.brokerage_information.id}/edit`}
                                            className="w-100 text-primary"
                                        >
                                            {cell}
                                        </Link>
                                    )
                                }
                                return (
                                    <span>{cell}</span>
                                )
                            },
                            short: true,
                            text: "Office",
                        },
                        {
                            dataField: "brokerage_information.broker.name",
                            formatter: (cell, row) => {
                                if (row.brokerage_information.broker.id) {
                                    return (
                                        <Link
                                            onClick={(e) => e.stopPropagation()}
                                            to={`/brokers/broker/${row.brokerage_information.broker.id}/edit`}
                                            className="w-100 text-primary"
                                        >
                                            {cell}
                                        </Link>
                                    )
                                }
                                return (
                                    <span>{cell}</span>
                                )
                            },
                            short: true,
                            text: "Broker",
                        },
                        {
                            dataField: "email",
                            short: true,
                            text: "Email",
                        },
                        {
                            dataField: "mobile_number",
                            formatter: formatPhoneNumber(),
                            text: "Phone",
                        },
                        {
                            dataField: "real_estate_licence",
                            short: true,
                            text: "License",
                        },
                        {
                            dataField: "total_posted",
                            sort: false,
                            align: "right",
                            text: "Total Posted",
                        },
                        {
                            dataField: "total_performed",
                            sort: false,
                            align: "right",
                            text: "Total Performed",
                        },
                        {
                            dataField: "total_reviews",
                            sort: false,
                            align: "right",
                            formatter: formatReviews(),
                            text: "Total Reviews",
                        },
                        {
                            dataField: "rating",
                            formatter: scoreFormat(),
                            text: "Assessment",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

function avatar() {
    return (cell, row) => {
        return (
            <div style={{ width: "20px" }}>
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/stats/agent-performance/${row.id}/details`}
                    className="w-100 text-primary"
                >
                    <Avatar
                        size="23"
                        src={cell}
                        round={true}
                        name={row.first_name}
                    />
                </Link>
            </div>
        );
    };
}

export function scoreFormat() {
    return (cell) => {
        if (cell){
            return (
                <div style={{width: "170px"}}>
                    <ReactStars
                        size={17}
                        count={5}
                        a11y={true}
                        value={cell}
                        edit={false}
                        isHalf={true}
                        color="#474747"
                        activeColor="#404040"
                        emptyIcon={<i className="material-icons mr-2">star_border</i>}
                        halfIcon={<i className="material-icons mr-2">star_half</i>}
                        filledIcon={<i className="material-icons mr-2" color={'red'}>star</i>}
                        onChange={() => console.log('')}
                    />
                </div>
            );
        }

        return <span></span>
    };
}

export function formatReviews() {
    return (cell, row) => {
        return (
            <Link
                onClick={(e) => e.stopPropagation()}
                to={`/agents/agent-reviews/${row.id}`}
                className="w-100 text-primary"
            >
                {cell}
            </Link>
        )
    };
}

export default PerformanceGrid;
