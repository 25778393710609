import React, {Component} from 'react';
import {Collapse} from 'reactstrap';
import {NavLink} from "react-router-dom";

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            menu: null
        };
    }

    toggle = (idMenu=null) => {
        const {menu, dropdownOpen} = this.state;
        const open = dropdownOpen;
        if (open && menu != idMenu) {
            this.setState({
                menu: idMenu
            });
        }else {
            this.setState({
                dropdownOpen: !open,
                menu: idMenu
            });
        }
    };

    customClick = (idMenu=null) => {
        this.toggle(idMenu);
        // this.props.navToggle();
    };

    customClickNav = () => {
        if (this.state.dropdownOpen) {
            this.toggle();
        }
        this.setState({menu: null});
        this.props.navToggle();
    };

    render() {
        const {toggleOpen, navToggle, logOut, backgroundColor, url} = this.props;
        return (
            <aside className={`main-sidebar main-sidebar px-0 col-md-3 col-lg-x-2 ${toggleOpen ? '' : 'open'}`}>
                <div className="main-navbar my-0 py-0">
                    <nav
                        className="align-items-stretch flex-md-nowrap navbar mb-5 pt-0">
                        <a href="#" className="w-100 h-100">
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                     className="d-inline-block align-top mx-2 p-0 "
                                     src={require('assets/img/logo-01.png')}
                                     alt="Logo"/>
                            </div>
                        </a>
                        <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                           onClick={navToggle}>
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <ul className="nav--no-borders flex-column nav">
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/" exact className="nav-link" activeClassName={'active'}>
                                <i className="material-icons mr-2">home</i>
                                Home
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/manager" className="nav-link" activeClassName={'active'}>
                                <i className="material-icons mr-2">account_circle</i>
                                Managers
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/agents" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">account_box</i>
                                Agents
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/wholesalers" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">perm_contact_calendar</i>
                                WholeSalers
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/stats') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="stats-id"
                                onClick={(e) => this.customClick('stats')}
                            >
                                <i className="material-icons mr-2">bar_chart</i>
                                Stats
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'stats'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/stats/revenue"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Revenue
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/agent-performance"
                                            activeClassName={'active-2'}
                                        >
                                            Agent Performance
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/broker-raw-data"
                                            activeClassName="active-2"
                                        >
                                            Broker Raw Data
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/job-detail-raw-data"
                                            activeClassName="active-2"
                                        >
                                            Job Details Raw Data
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/agent-raw-data"
                                            activeClassName="active-2"
                                        >
                                            Agent Raw Data
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/wallet-management"
                                            activeClassName="active-2"
                                        >
                                            Wallet Management
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/payout"
                                            activeClassName="active-2"
                                        >
                                            Payout
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/reports"
                                            activeClassName="active-2"
                                        >
                                            Reports
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/vendor-raw-data"
                                            activeClassName="active-2"
                                        >
                                            Vendor Raw Data
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/app-review"
                                            activeClassName="active-2"
                                        >
                                            App Review
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/gift-card-raw-data"
                                            activeClassName="active-2"
                                        >
                                            Gift Card
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            className="nav-link pl-5"
                                            to="/stats/referral-raw-data"
                                            activeClassName="active-2"
                                        >
                                            Referral
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/franchises" className="nav-link" activeClassName="active">
                                <i className="material-icons pr-0 mr-2">local_convenience_store</i>
                                Franchises
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/brokers" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">group</i>
                                Brokers
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/offices" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">business</i>
                                Offices
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/influencer-teams" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">group</i>
                                Teams
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/influencers" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">person_add</i>
                                Influencer
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/tracker') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="stats-id"
                                onClick={(e) => this.customClick('tracker')}
                            >
                                <i className="material-icons mr-2">
                                    person
                                </i>
                                Tracker
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'tracker'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/tracker-field"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Fields
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/tracker"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            List
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/agents-jobs" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">map</i>
                                Agents & Jobs
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/job-types" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">work</i>
                                Job Type
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/feedback-form-agent" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">list_alt</i>
                                Agent Feedback Form
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/feedback-form-customer" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">assignment_turned_in</i>
                                Customer Feedback Form
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/feedback-form-client" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">list</i>
                                Client Feedback Form
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/campaigns') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="stats-id"
                                onClick={(e) => this.customClick('campaigns')}
                            >
                                <i className="material-icons mr-2">email</i>
                                Twilio
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'campaigns'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/campaigns"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Campaign
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/campaigns_app_version"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            App Version
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>
                        {/* <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/campaigns" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">sms</i>
                                Twilio
                            </NavLink>
                        </li> */}
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/stats') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="stats-id"
                                onClick={(e) => this.customClick('sendgrid')}
                            >
                                <i className="material-icons mr-2">email</i>
                                Sendgrid
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'sendgrid'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/sendgrid-custom-campaigns"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Campaign
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/sendgrid-campaigns"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Emails
                                        </NavLink>
                                    </li>
                                </ul>
                                
                            </Collapse>
                        </li>
                        {/* <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/sendgrid-campaigns" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">email</i>
                                Sendgrid
                            </NavLink>
                        </li> */}
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/promotions" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">web_asset</i>
                                Promotion
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/tutorial-videos" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">video_library</i>
                                Tutorial Videos
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/app_notifications" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">notifications</i>
                                Notifications
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/app_cancellation_delete_option" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">list_alt</i>
                                Cancellation or Delete Option
                            </NavLink>
                        </li>
                        <li className="nav-item" onClick={this.customClickNav}>
                            <NavLink to="/legal-link" className="nav-link" activeClassName="active">
                                <i className="material-icons mr-2">gavel</i>
                                Legal
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/job-type-vendor') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="job-type-vendor-id"
                                onClick={(e) => this.customClick('job-type-vendor')}
                            >
                                <i className="material-icons mr-2">work</i>
                                Vendor
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'job-type-vendor'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/job-type-vendor"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Vendor Type
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/vendor/settings"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Settings
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/vendor/vendors"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Vendors
                                        </NavLink>
                                    </li>
                                    {/* <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/vendor-client-feedback"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Client Feedback
                                        </NavLink>
                                    </li> */}
                                </ul>
                            </Collapse>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/gift-card') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="gift-card-id"
                                onClick={(e) => this.customClick('gift-card')}
                            >
                                <i className="material-icons mr-2">redeem</i>
                                Gift Card
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'gift-card'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={this.customClickNav}>
                                        <NavLink to="/gift-card/designs" className="nav-link" activeClassName="active">
                                            Design
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={this.customClickNav}>
                                        <NavLink to="/gift-card/stripe-account" className="nav-link" activeClassName="active">
                                            Stripe Account
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/edit-texts') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="edit-texts-id"
                                onClick={(e) => this.customClick('edit-texts')}
                            >
                                <i className="material-icons mr-2">title</i>
                                Edit Texts
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'edit-texts'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/wallet"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Wallet
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/completing-job-offline"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Completing Job Offline
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/leads"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Leads
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/tips-guidelines"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Tips and Guidelines
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/fees-structures"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Fees Structure
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/open-house-text"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Open House Text
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/counter-offer"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Counter Offer
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/gift-card"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Gift Card
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/vendor"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Vendor
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/showing"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Showing
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/refer"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Refer
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/edit-texts/demo"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Text Demo
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${`${url}`.includes('/stats') && 'active'}`}
                                style={{
                                    color: '#000000 !important',
                                    cursor: 'pointer',
                                }}
                                id="stats-id"
                                onClick={(e) => this.customClick('deleted')}
                            >
                                <i className="material-icons mr-2">delete</i>
                                Deleted
                            </a>
                            <Collapse isOpen={this.state.dropdownOpen && this.state.menu == 'deleted'}>
                                <ul className="custom_nav_ul" id='collapse-1'>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/deleted-agents"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Agent
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/deleted-offices"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Office
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" onClick={navToggle}>
                                        <NavLink
                                            to="/deleted-brokers"
                                            className="nav-link pl-5 nav-white"
                                            activeClassName={'active-2'}
                                        >
                                            Broker
                                        </NavLink>
                                    </li>
                                </ul>
                                
                            </Collapse>
                        </li>
                        
                    </ul>
                </div>
            </aside>
        )
    }
}

export default SideBar;
