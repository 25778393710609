import {api} from "api";
import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import { SELECT_ALL_OPTION } from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "app_review";

//-------------------------------------
// Base reducer
//-------------------------------------
export const { reducers, initialState, actions } = createReducer(
    "appReview",
    endpoint,
    "appReviewFrom",
    "/stats/app-review",
);

actions.toList = (page = 1) => (dispatch, getStore) => {
    dispatch(actions.setLoader(true));
    const store = getStore()
    const resource = store.appReview;
    const {appReviewFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (appReviewFilterForm && appReviewFilterForm.values) {
        const {search, recomendation} = appReviewFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
        if (recomendation !== "" && recomendation !== null && recomendation !== undefined && recomendation !== SELECT_ALL_OPTION["value"]) {
            if (recomendation.length == 0) {
                params.how_like__in = -1;
            }else {
                params.how_like__in = recomendation;
            }
        }
    }

    api.get(endpoint, params).then((response) => {
        dispatch(actions.setData(response));
        dispatch(actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });

};

actions.searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(actions.toList());
    }, 400);
};

export default handleActions(reducers, initialState);