import React, {useState, useEffect} from "react";
import moment from "moment";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderCurrency, renderFilePicker, renderNumber } from "../Utils/renderField/renderField";
import { BLOCKED_BROKER, DENIED_BROKER, LOCKED_BROKER, PENDING_BROKER, STATUS_BROKER, VERIFIED_BROKER } from "../../../utility/constants";


const validate = (values) => {
    let errors = {};
    if (!values.total_agents) {
        errors.total_agents = "Total Agents is required";
    }
    if (!values.amount) {
        errors.amount = "Amount is required";
    } else if (values.amount <= 0) {
        errors.amount = "Amount must be greater than 0";
    }
    return errors;
}


const Form = (props) => {
    //  State
    const {
        data,
        total_agentsValue,
    } = props;
    
    //  Bind
    const {
        handleSubmit,
        goBack,
        change,
    } = props;

    console.log("Props: ", props);

    const item = data && data.broker ? data.broker : {};
    const [disableAmount, setDisableAmount] = useState(true);

    useEffect(() => {
        let prices = [];
        if (data && data.prices) {
            prices = data.prices;
            //  Validate total_agents in range of prices
            if (total_agentsValue) {
                const totalAgents = parseInt(total_agentsValue);
                let price = prices.find((item) => {
                    const minAgents = item.from ? item.from : 0;
                    const maxAgents = item.to ? item.to : null;
                    if (totalAgents >= minAgents && maxAgents && totalAgents <= maxAgents) {
                        return true;
                    }
                    if (totalAgents >= minAgents && !maxAgents) {
                        return true;
                    }
                });
                if (price) {
                    change("amount", price.price);
                } else {
                    change("amount", 0);
                }
            }
        }
    }, [total_agentsValue]);

    const getColor = (status) => {
        let colorText = "black";
        if (status === DENIED_BROKER) {
            colorText = "danger";
        } else if (status === PENDING_BROKER) {
            colorText = "warning";
        } else if (status === VERIFIED_BROKER) {
            colorText = "success";
        } else if (status === LOCKED_BROKER) {
            colorText = "danger";
        } else if (status === BLOCKED_BROKER) {
            colorText = "danger";
        }
        return colorText;
    };

    const getStatus = (status) => {
        let text = "";
        STATUS_BROKER.map((item) => {
            if (status === item.value) {
                text = item.label;
            }
        });
        return text;
    };

    return (
        <form
            name="brokerReferralForm"
            className="my-1 col-sm-12 p-sm-0"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    {item.logo_office && (
                        <div className="form-group has-feedback">
                            <Field
                                photo={item.logo_office ? item.logo_office : null}
                                setFile={() => {}}
                                name="photo"
                                component={renderFilePicker}
                                disabled={true}
                                // className="avatar-round"
                            />
                        </div>
                    )}
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            {item && item.name ? item.name : ""}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-3">
                        <span className="font-weight-bolder h6 mb-1">
                            Sign-up Date
                        </span>
                        <span className="font-weight-bolder text-primary">
                            { item.created ? moment(item.created).format("ll") : ""}
                        </span>
                    </div>
                    <div className="col-sm-12 text-center px-0 d-flex flex-column mb-5">
                        <span className="font-weight-bolder h6 mb-1">
                            Status
                        </span>
                        {item && (
                            <span
                                className={`text-${getColor(
                                    item.status
                                )} font-weight-bold`}
                            >
                                {getStatus(item.status)}
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="total_agents">Total Agents</label>
                            <Field
                                name="total_agents"
                                component={renderNumber}
                                placeholder="Total Agents"
                                decimalScale={0}
                                customClass="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="amount">Amount</label>
                            <Field
                                name="amount"
                                component={renderCurrency}
                                placeholder="Amount"
                                customClass="form-control w-100"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mx-auto ">
                <button
                    type="button"
                    onClick={goBack}
                    className={`w-100 btn btn-secondary text-white col-lg-2 col-md-4 col-sm-12 mx-4 mt-2`}
                >
                    Back
                </button>

                <button
                    type="submit"
                    className="btn btn-primary col-lg-2 col-md-4 col-sm-12 mx-4 mt-2"
                >
                    Transfer
                </button>
            </div>
        </form>
    )
}

let BrokerReferralForm =  reduxForm({
    form: "brokerReferralForm", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector('brokerReferralForm');

const mstp = state => ({
    total_agentsValue: selector(state, 'total_agents'),
})


export default connect(mstp, null)(BrokerReferralForm)
