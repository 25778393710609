import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';


const SingUpFormStep1 = (props) => {

    const {
        handleSubmit,
        pristine,
        reset,
        submitting,
    } = props;

    return (
        <form name="Step1RegisterForm" className="row w-75 mx-auto justify-content-center" onSubmit={handleSubmit}>
            <div className="row w-100">
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="first_name">What's your first name?</label>
                    <Field
                        name="first_name"
                        label="First Name"
                        component={renderField}
                        type="text"
                        customClass="form-control-gray"
                        className="form-control"/>
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="first_name">What's last name?</label>
                    <Field
                        name="last_name"
                        label="Last Name"
                        component={renderField}
                        type="text"
                        customClass="form-control-gray"
                        className="form-control"/>
                </div>
            </div>
            <div className="row w-100">
                <div className="form-group has-feedback  col-md-6">
                    <label htmlFor="mobile_number" className="mt-2">What's your mobile number?</label>
                    <Field
                        name="mobile_number"
                        label="Mobile Number"
                        component={renderField}
                        type="number"
                        customClass="form-control-gray mt-2"
                        className="form-control"/>
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="mobile_number">What's your Office Phone Number?</label>
                    <Field
                        name="office_mobile_number"
                        label="Office Mobile Number"
                        component={renderField}
                        type="number"
                        customClass="form-control-gray"
                        className="form-control"/>
                </div>
            </div>

            <div className="row w-100">
                <div className="form-group has-feedback col-md-6 ">
                    <label htmlFor="email">What's your Email address?</label>
                    <Field name="email"
                           label="Email"
                           component={renderField}
                           type="email"
                           customClass="form-control-gray"
                           className="form-control-gray"/>
                </div>
                <div className="form-group has-feedback col-md-6 ">
                    <label htmlFor="confirmEmail">Confirm your Email address*</label>
                    <Field name="confirmEmail"
                           label="Confirm Email"
                           component={renderField}
                           type="email"
                           customClass="form-control-gray"
                           className="form-control-gray"/>
                </div>
            </div>

            <div className="w-100 text-center mt-4">
                <div>
                    <p className="my-0 paragraph">By continuing, I confirm that I have read and agree to </p>
                    <p className="my-0 paragraph">
                        the &nbsp;<Link to="/login"> Terms {'&'} Conditions </Link> and &nbsp;
                        <Link to="/login">Privacy Policy </Link>
                    </p>
                </div>
            </div>

            <div className="buttons-box w-75 justify-content-center mx-auto pt-4">
                <button type="submit" className="btn button-01 w-50 mx-5 px-2 align-self-center">Next</button>
            </div>
        </form>
    );
};

export const matchEmail = (email, confirm) => validatorFromFunction(() => {
    return email === confirm;
});

export default reduxForm({
    form: 'Step1RegisterForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmEmail: combine(
                validators.exists()('This field is required'),
                matchEmail(data.email, data.confirmEmail)()('The Email address don\'t match'),
            ),
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            mobile_number: validators.exists()('This field is required'),
            office_mobile_number: validators.exists()('This field is required'),
            email: validators.exists()('This field is required'),
        });
    },
})(SingUpFormStep1);
