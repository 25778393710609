import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";
import {SELECT_ALL_OPTION} from '../../../utility/constants'

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "contact_tracker_field"

//-------------------------------------
// Base reducer
//-------------------------------------
export const { reducers, initialState, actions } = createReducer(
    "trackerField",
    endpoint,
    "trackerFieldFrom",
    "/tracker-field",
);

actions.toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["trackerField"];
    const {TrackerFieldFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (TrackerFieldFilterForm && TrackerFieldFilterForm.values) {
        const {search, type} = TrackerFieldFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
        if (type !== "" && type !== null && type !== undefined && type !== SELECT_ALL_OPTION["value"]) {
            if (type.length == 0) {
                params.type__in = 0
            }else {
                params.type__in = type;
            }
        }
    }

    dispatch(actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(actions.setData(response));
        dispatch(actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
}

actions.searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(actions.toList());
    }, 400);
};

export default handleActions(reducers, initialState);