import _ from "lodash";
import { api } from "api";
import moment from "moment";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = 'gift_card'

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "giftCardRawData",
    endpoint,
    "giftCardRawDataForm",
    "/stats/gift-card-raw-data"
);

// ------------------------------------
// Pure Actions
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
basicReducer.actions.toList = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()["giftCardRawData"];
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

export const actions = {
    ...basicReducer.actions,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
}

export default handleActions(reducers, initialState);