import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CreateUpdateCampaignForm from './CreateUpdateCampaignForm'

export class CreateUpdateCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
        };
    }

    componentWillMount() {
        const { match, read, getTypesEmails } = this.props;
        getTypesEmails();
        if (match.params.id) {
            read(match.params.id);
        }
        if (match.path === "/sendgrid-custom-campaigns/:id") {
            this.setState({ is_view: true, is_update: false });
        }
        if (match.path === "/sendgrid-custom-campaigns/:id/edit") {
            this.setState({ is_view: false, is_update: true });
        }
    }

    create = (data) => {
        const {create} = this.props
        // console.log("DATA: ", data);
        data.campaign_emails = data.campaign_emails.map((email, index) => {
            return ({
                ...email,
                date_delivery: `${email.date} ${email.time}`
            })
        })
        // console.log("DATA BEFORE: ", data);
        create(data)
    };
    update = (data) => {
        const {edit, item} = this.props
        console.log("DATA: ", data);
        data.campaign_emails = data.campaign_emails.map((email, index) => {
            return ({
                ...email,
                date_delivery: `${email.date} ${email.time}`
            })
        })
        console.log("DATA BEFORE: ", data);
        edit(item.id, data)
    };

    goBack = () => {
        const {history} =this.props

        history.goBack()
    }

    render() {
        //  State
        const { match, item, loader, typesEmails } = this.props;
        const { is_update, is_view } = this.state;

        const sendFunction = match.params.id ? this.update : this.create;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                        {is_view && "View Campaign"}
                        {is_update && "Update Campaign"}
                        {!is_update && !is_view && "Create Campaign"}
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4">
                    <LoadMask loading={loader} dark blur>
                        <CreateUpdateCampaignForm 
                            item={item}
                            loader={loader}
                            is_view={is_view}
                            is_update={is_update}
                            typesEmails={typesEmails}
                            onSubmit={sendFunction}
                            goBack={this.goBack}
                            />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CreateUpdateCampaign;
