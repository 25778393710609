import React from "react";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderTextArea
} from "../../Utils/renderField/renderField";


const validate = (values, props) => {
    let errors = {};
    const {is_update, item} = props;

    if (!values.note) {
        errors.note = "This field is required";
    }

    return errors;
};

const Form = (props) => {
    //  State
    const { handleSubmit } = props;
    //  Bind
    const { goBack } = props;

    return (
        <form
            className="w-100 mx-auto justify-content-center"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto mt-3 w-100">
                <div className="col-lg-12 col-sm-12 form-group has-feedback">
                <label htmlFor="note">Note</label>
                    <Field
                        name="note"
                        rows={4}
                        component={renderTextArea}
                        customClass="text-area-h-auto border"
                        // disabled={is_view}
                    />
                </div>
                
            </div>
            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <button
                    onClick={goBack}
                    type="button"
                    className="btn text-white btn-secondary  mx-3 col-2"
                >
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary mx-3 col-2">
                    Save
                </button>
            </div>
        </form>
    );
};

const TrackerNoteFrom = reduxForm({
    form: "trackerNoteFrom", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector('trackerNoteFrom');

const mstp = state => {
    return {
    }
}

export default connect(mstp, null)(TrackerNoteFrom);
