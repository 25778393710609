import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderTextArea,
} from "../../Utils/renderField";

const validate = (values, props) => {
    const errors = {}

    if (!values.note) {
        errors.note = "This field is required";
    }

    return errors
}

const LockNoteForm = (props) => {
    //  State
    const {loader, isUpdate} = props;
    const {handleSubmit, onClose, remove} = props;

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="form-group has-feedback col-sm-12">
                    <label htmlFor="note">Note</label>
                    <Field
                        name="note"
                        label="Note"
                        component={renderTextArea}
                        type="text"
                        rows={4}
                        customClass="form-control text-area-h-auto"
                    />
                </div>
            </div>

            <div
                className={`row w-75 mx-auto mt-3 justify-content-center`}
            >
                <div className="col-lg-2 col-sm-12">
                    <button
                        onClick={onClose}
                        type="button"
                        className="btn btn-secondary text-white"
                        disabled={loader}
                    >
                        Cancel
                    </button>
                </div>
                <div className="col-lg-2 col-sm-12">
                    <button
                        className={`btn btn-success`}
                        disabled={loader}
                    >
                        {isUpdate ? "Update" : "Create"}
                    </button>

                </div>

                {isUpdate && (
                    <div className="col-lg-2 col-sm-12">
                        <button
                            onClick={(e) => remove()}
                            type="button"
                            className="btn btn-danger text-white"
                            disabled={loader}
                        >
                            Delete
                        </button>

                    </div>

                )}
            </div>
        </form>
    )
}

let LockNoteReduxForm = reduxForm({
    form: "lockNoteForm", // a unique identifier for this form
    validate,
    initialValues: {note: ''}
})(LockNoteForm);

const selector = formValueSelector("lockNoteForm");
LockNoteReduxForm = connect((state) => {
    return {
    };
})(LockNoteReduxForm);

export default LockNoteReduxForm;