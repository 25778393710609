import React, { Component } from "react";
import TrackerFieldForm from "./TrackerFieldFilter";
import TrackerFieldsGrid from './TrackerFieldGrid'

export class TrackerFieldList extends Component {
    componentDidMount() {
        this.props.toList();
    }

    render() {
        // State
        const { loader, page, data } = this.props;
        //  Bind
        const { toList, searchChange, onSortChange, remove } = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Tracker Fields</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <TrackerFieldForm searchAction={searchChange} />
                </div>

                <TrackerFieldsGrid data={data} page={page} loader={loader} toList={toList} onSortChange={onSortChange} remove={remove}/>
            </div>
        );
    }
}

export default TrackerFieldList;
