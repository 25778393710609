import Swal from 'sweetalert2';
import {handleActions} from 'redux-actions';
import {NotificationManager} from 'react-notifications';


export const handleSubmit = () => (dispatch, getStore) => {
    const store = getStore();
    const notiForm = store.form.notificacioForm.values;
    switch (notiForm.typeNoti) {
    case 'info':
        NotificationManager.info(notiForm.message, notiForm.title);
        break;
    case 'success':
        NotificationManager.success(notiForm.message, notiForm.title);
        break;
    case 'warning':
        NotificationManager.warning(notiForm.message, notiForm.title, 3000);
        break;
    case 'error':
        NotificationManager.error(notiForm.message, notiForm.title, 5000, () => {
            alert('callback');
        });
        break;
    default:
        break;
    }
};

export const showSweet = () => (dispatch, getStore) => {
    const store = getStore();
    const notiForm = store.form.notificacioSweetForm.values;
    switch (notiForm.typeNoti) {
    case 'success':
        Swal.fire('Success!',
            'Process successfully completed!',
            notiForm.typeNoti);
        break;
    case 'error':
        Swal.fire({
            type: notiForm.typeNoti,
            title: 'Ups!',
            text: 'An error has occurred!',
            footer: '<a href="/" >we can help you?</a>',
        });
        break;
    case 'info':
        Swal.fire('Information!',
            'This is a sweet2 example!',
            notiForm.typeNoti);
        break;
    case 'warning':
        Swal.fire({
            title: 'You sure want to delete it?',
            text: "If you perform this action, it cannot be reversed!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete!',
        }).then((result) => {
            if (result.value) {
                Swal.fire('Success!', 'The record has been deleted.', 'success')
                     .then(r =>(console.log(r)));
            }
        });
        break;
    default:
        break;
    }
};

export const actions = {
    handleSubmit,
    showSweet,
};

export const reducers = {};

export const initialState = {};

export default handleActions(reducers, initialState);
