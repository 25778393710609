import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/cancellationDeleteOption";
import CreateUpdateCancellationDeleteOption from './CreateUpdateCancellationDeleteOption';


const ms2p = (state) => {
    return {
        ...state.cancellationDeleteOption,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(CreateUpdateCancellationDeleteOption);