import {connect} from 'react-redux';
import {actions} from '../../../../redux/modules/agent/agentRoster';
import AgentRoster from "./AgentRoster";

import formValueSelector from "redux-form/lib/formValueSelector";
const selector = formValueSelector('AgentFiltersForm');

const ms2p = (state) => {
    return {
        ...state.agentRoster,
        office_selected: selector(state, 'brokerage'),
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AgentRoster);
