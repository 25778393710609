import React, {Component} from 'react';
import AppVersionGrid from "./AppVersionGrid";

class AppVersionList extends Component {

    componentWillMount() {
        this.props.toList();
    }

    render() {
        // State
        const {loader, page, data, searchChange, toList, remove} = this.props;

        return (
            <AppVersionGrid
                data={data}
                page={page}
                loader={loader}
                toList={toList}
                remove={remove}
                searchAction={searchChange}
            />
        )
    }
}

export default AppVersionList;
