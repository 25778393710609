import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';

class DoughnutAgent extends Component {
    render() {
        const {total, count} = this.props;

        let percent = 0;
        if (total > 0){
            percent = parseFloat(((count * 100) / total).toFixed(2))
        }        
        const data = {
            labels: ['Registered', 'Unregistered'],
            datasets: [
                {
                    data: [count, (total - count) === 0 ? 0 : total - count],
                    backgroundColor: ['#147AD6', '#CED5E1'],
                },
            ],
        };

        return (
            <div className="mx-auto text-center py-auto">
                <div style={{maxWidth: '80px', position: 'relative'}}>
                    <span className="text-total">
                        {percent}%
                    </span>
                    <Doughnut
                        data={data}
                        options={{
                            plugins: {
                                legend: {
                                    position: null,
                                },
                                datalabels: {
                                    display: false,
                                }
                            },
                            animation: null,
                        }}
                        height={60}
                        redraw={false}
                        // width={100}
                        // height={50}
                    />
                </div>
            </div>
        );
    }
}

export default DoughnutAgent;
