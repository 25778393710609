import React, { Component } from 'react';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import GifCardDesignForm from './GifCardDesignForm'

class GifCardDesignCreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
        };
    }

    componentWillMount() {
        const { match, readGifCardDesign } = this.props;
        if (match.params.id) {
            readGifCardDesign(match.params.id);
        }

        if (match.path === '/gift-card/designs/:id') {
            this.setState({ is_view: true, is_update: false });
        }
        if (match.path === '/gift-card/designs/:id/edit') {
            this.setState({ is_view: false, is_update: true });
        }
    }

    create = (data) => {
        let valid = true;

        if (valid) {
            const photos = [];
            if (this.state.design_photo) {
                photos.push({ name: "image", file: this.state.design_photo });
            }
            if (this.state.design_photo_thumbnail) {
                photos.push({ name: "image_thumbnail", file: this.state.design_photo_thumbnail });
            }
            this.props.createGifCardDesign(data, photos);
        }
    };

    update = (data) => {
        const { match, item } = this.props;
        const _id = data.id ? data.id : match.params.id;

        let valid = true;

        if (valid) {
            const photos = [];
            if (this.state.design_photo) {
                photos.push({ name: "image", file: this.state.design_photo });
            }
            if (this.state.design_photo_thumbnail) {
                photos.push({ name: "image_thumbnail", file: this.state.design_photo_thumbnail });
            }
            this.props.updateGifCardDesign(_id, data, photos);
        }
    };

    onFileChange = (file, key) => {
        if (file) {
            this.setState({ [key]: file });
        }
    }

    render() {
        //  State
        const { match, item, loader } = this.props;
        const { is_view, is_update } = this.state;
        const sendFunction = match.params.id
            ? this.update
            : this.create;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_view && "View Gift Card Design"}
                            {is_update && "Update Gift Card Design"}
                            {!is_update && !is_view && "Create Gift Card Design"}
                        </div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <GifCardDesignForm
                            item={item}
                            loader={loader}
                            isView={is_view}
                            isUpdate={is_update}
                            image={this.state['design_photo']}
                            imageThumbnail={this.state['design_photo_thumbnail']}
                            onSubmit={sendFunction}
                            onFileChange={this.onFileChange}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default GifCardDesignCreateUpdate;
