import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
// const SET_DATA = "SET_DATA_TUTORIAL_VIDEO";

//-------------------------------------
// Base reducer
//-------------------------------------
export const { reducers, initialState, actions } = createReducer(
    "legal",
    "legal_link",
    "legalFrom",
    "/legal-link",
);

export default handleActions(reducers, initialState);