import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/brokerage/brokerage';
import Invites from "./Invites";

import formValueSelector from "redux-form/lib/formValueSelector";
const selector = formValueSelector('invitesForm');

const ms2p = (state) => {
    return{
        ...state.brokerage,
        roles: selector(state, 'roles'),
        me: state.login.me
    };
};

const md2p = {
    setItem: actions.setItem,
    setItem2: actions.setItem2,
    setPermissions: actions.setPermissions,
    customRead: actions.customRead,
    setRealId: actions.setRealId,
    getBrokerages: actions.getBrokerages,
    invites: actions.invites,
    setItemOffices: actions.setItemOffices,
    updateInvite: actions.updateInvite,
};

export default connect(ms2p, md2p)(Invites);