
import React, { Component } from 'react'
import LockNotesGrid from './LockNotesGrid'
import Swal from 'sweetalert2';

class LockNotes extends Component {

    componentDidMount() {
        const {list, match} = this.props;
        if (match.params.id) {
            list(match.params.id)
        }
    }

    changePage = (page) => {
        const {list, match} = this.props;
        if (match.params.id) {
            list(match.params.id, page);
        }
    }

    saveNote = (data, closeModal=null) => {
        const {item, match, create, edit} = this.props;
        if (match.params.id) {
            if (item) {
                edit(item.id, {...data, user: match.params.id}, closeModal)
            }else {
                create(match.params.id, data, closeModal)
            }
        }
        console.log("DATA: ", data);
    }

    read = (id, onOpenModal) => {
        console.log("ID: ", id);
        const {read, match} = this.props;
        if (match.params.id) {
            read(id, match.params.id, onOpenModal)
        }else {
            read(id, null, onOpenModal)
        }
    }

    remove = (id, onCloseModal=null) => {
        const {match} = this.props;
        if (match.params.id) {
            Swal.fire({
                title: 'Are you sure to delete the note?',
                text: "This action can't be reverted!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#7E8A97',
                cancelButtonColor: '#B9BDC1',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.remove(id, match.params.id, onCloseModal)
                }
            });
        }
    }

    render() {
        //  State
        const {data, loader, page, item, itemLoader} = this.props;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={() => this.props.history.goBack()}>
                                <i className="material-icons pointer">arrow_back</i>
                            </button>
                            Lock Notes
                        </div>
                    </div>
                </div>

                <LockNotesGrid
                    data={data}
                    loader={loader}
                    page={page}
                    item={item}
                    itemLoader={itemLoader}
                    changePage={this.changePage}
                    saveNote={this.saveNote}
                    read={this.read}
                    remove={this.remove}
                    setItem={this.props.setItem}
                    />
            </div>
        )
    }
}

export default LockNotes;