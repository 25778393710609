import React, {Component} from 'react';
import FeedbackFormClientForm from './FeedbackFormClientCreateUpdateForm'

export default class FeedbackFormClientCreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
        };
    }

    componentWillMount() {
        const { match, read } = this.props;
        if (match.params.id) {
            read(match.params.id);
        }
        if (match.path === "/feedback-form-client/:id/edit") {
            this.setState({ is_update: true });
        }
    }

    update = (data) => {
        const { match, item } = this.props;
        const _id = data.id ? data.id : match.params.id;
        this.props.edit(_id, data)
    }

    render() {
        //  State
        const { match, loader } = this.props;
        const { is_update } = this.state;
        //  Bind
        const {create} = this.props;
        const sendFunction = match.params.id ? this.update : create;
        return (
            <React.Fragment>
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page"> {is_update ? 'Update': 'Create'} Client Feedback Form</div>
                    </div>
                </div>
                <FeedbackFormClientForm
                    onSubmit={sendFunction}
                    loader={loader}
                    is_update={is_update}
                />
            </React.Fragment>
        );
    }
}