import React, { Component } from "react";
import { JobDetailRawDataGrid } from "./JobDetailRawDataComponent";
import { reduxForm, Field } from "redux-form";
import { SelectField } from "../../../Utils/renderField";

import FilterContainer from "./JobDetailRawDataComponent";
class JobDetailRawDataList extends Component {
    state = {
        withTime: true
    }
    componentDidMount() {
        const {location, changeFilterFieldForm} = this.props;
        let jobStatus = null
        let withTime = true
        if (location && location.search) {
            console.log("LOCATION: ", location);
            const searchParams = new URLSearchParams(location.search)
            console.log('SEARCH: ', searchParams);
            const status = searchParams.get('status')
            if (status) {
                jobStatus = status.split(',')
                jobStatus = jobStatus.map((item) => parseInt(item))
            }
            const job_id = searchParams.get('job_id')
            if (job_id) {
                withTime = false
                this.setState({withTime})
                console.log("SEARCH 2: ", job_id);
                changeFilterFieldForm('', 'time')
                changeFilterFieldForm(job_id, 'search')
            }
        }
        console.log('JOB STATUS: ', jobStatus);
        if (jobStatus) {
            changeFilterFieldForm(jobStatus, 'jobStatus')
        }
        setTimeout(() => {
            this.props.getJobDetailsRawData(1, true, withTime);
        }, 600);

        this.jobDetailRawDataInterval = setInterval(this.refreshData, 300000)
    }
    changePage = (page) => {
        this.props.getJobDetailsRawData(page);
    };

    refreshData = () => {
        const {page} = this.props
        const {withTime} = this.state
        console.log('PAGE: ', page);
        this.props.getJobDetailsRawData(page, false, withTime)
    }

    componentWillUnmount() {
        if (this.jobDetailRawDataInterval) {
            clearInterval(this.jobDetailRawDataInterval)
        }
    }

    setTimeFilter = (value) => {
        this.setState({withTime: true})
        this.props.setTimeFilter(value)
    }

    render() {
        //  Status
        const { dataStats, loader, page, downloadLoader } = this.props;
        //  Bind
        const { downloadJobDetailsRawDataReport, setJobStatusFilter, downloadVisitorReport, changeJobTransferType, setJobSearchFilter } = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Stats / Job details raw data
                        </div>
                    </div>
                </div>
                <FilterContainer
                    downloadLoader={downloadLoader}
                    setStatusFilter={setJobStatusFilter}
                    setTimeFilter={this.setTimeFilter}
                    searchAction={setJobSearchFilter}
                    downloadJobDetailsRawDataReport={downloadJobDetailsRawDataReport}
                />
                <JobDetailRawDataGrid
                    page={page}
                    data={dataStats}
                    loader={loader}
                    nextPage={this.changePage}
                    downloadVisitorReport={downloadVisitorReport}
                    changeJobTransferType={changeJobTransferType}
                />
            </div>
        );
    }
}

export default JobDetailRawDataList;
