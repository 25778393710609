import React, {useState, useEffect} from "react";
import moment from "moment"
import Avatar from "react-avatar";
import Grid from "../../../Utils/GridTable";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import {
    AGENT_REGISTERED_PAGE_SIZE,
    CATEGORY_REGISTERED_LIST,
    REGISTERED_PENDING, REGISTERED_RE_ASSIGN, REGISTERED_ACTIVE, REGISTERED_LOCKED, REGISTERED_BLOCKED,
} from "../../../../../utility/constants";


const VendorGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        remove,
        sendInvite,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("PROMOTION-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PROMOTION-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={AGENT_REGISTERED_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    customRemove: remove,
                                    edit: '/vendor/vendors',
                                }),
                        },
                        {
                            dataField: "photo",
                            formatter: avatar(),
                            text: " ",
                        },
                        {
                            dataField: "agent_account_status",
                            formatter: formatStatusAccount(),
                            text: "Status",
                        },
                        {
                            dataField: "first_name",
                            sort: true,
                            text: "First Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "last_name",
                            sort: false,
                            text: "Last Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "professions",
                            formatter: formatProfesssion(),
                            text: "Professions",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "280px",
                                };
                            },
                        },
                        {
                            dataField: "company_name",
                            sort: false,
                            text: "Company Name",
                        },
                        {
                            dataField: "email",
                            sort: true,
                            text: "E-mail",
                        },
                        {
                            dataField: "street",
                            sort: false,
                            text: "Address",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "290px",
                                };
                            },
                        },
                        {
                            dataField: "office_phone",
                            formatter: formatPhoneNumber(),
                            text: "Office Phone",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "mobile_number",
                            formatter: formatPhoneNumber(),
                            text: "Mobile Phone",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "190px",
                                };
                            },
                        },
                        {
                            dataField: "years_business",
                            sort: false,
                            align: "center",
                            text: "Yeasr in Business",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "190px",
                                    textAlign: 'center'
                                };
                            },
                        },
                        {
                            dataField: "number_employees",
                            sort: false,
                            align: "center",
                            text: "# of Employees",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "190px",
                                    textAlign: 'center'
                                };
                            },
                        },
                        {
                            dataField: "website",
                            sort: false,
                            text: "Website",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "290px",
                                };
                            },
                        },
                        {
                            dataField: "date_joined",
                            text: "P&O Insurance",
                            sort: true,
                            formatter: signedDateFormatt(),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "210px",
                                };
                            },
                        },
                        {
                            dataField: "date_joined",
                            text: "Certification",
                            sort: true,
                            formatter: signedDateFormattCertification(),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "210px",
                                };
                            },
                        },
                        {
                            dataField: "date_joined",
                            text: "Business Registration",
                            sort: true,
                            formatter: signedDateFormattBusiness(),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "210px",
                                };
                            },
                        },
                        {
                            dataField: "id",
                            text: "",
                            sort: false,
                            formatter: formatInvite(sendInvite),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "210px",
                                };
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );

};

function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.first_name}
                />
            </div>
        );
    };
}

export function formatProfesssion() {
    return (cell, row) => {
        const value = cell && cell.length > 0 ? cell.join(', ') : ''
        return (
            <span>{value}</span>
        );
    };
}

export function signedDateFormatt() {
    return (cell, row) => {
        if (row.insurance) {
            return (
                <p className="my-0 pointer text-primary" onClick={() => {
                    window.open(row.insurance, '_blank');
                }}>
                    {cell ? moment(cell).format('LL') : ""}
                </p>
            )
        }
        return (
            <p className="my-0">
            </p>
        )
    };
}

export function signedDateFormattCertification() {
    return (cell, row) => {
        if (row.certification) {
            return (
                <p className="my-0 pointer text-primary" onClick={() => {
                    window.open(row.certification, '_blank');
                }}>
                    {cell ? moment(cell).format('LL') : ""}
                </p>
            )
        }
        return (
            <p className="my-0">
                
            </p>
        )
    };
}

export function signedDateFormattBusiness() {
    return (cell, row) => {
        if (row.business_registration) {
            return (
                <p className="my-0 pointer text-primary" onClick={() => {
                    window.open(row.business_registration, '_blank');
                }}>
                    {cell ? moment(cell).format('LL') : ""}
                </p>
            )
        }
        return (
            <p className="my-0">
                
            </p>
        )
    };
}

export function formatStatusAccount() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            CATEGORY_REGISTERED_LIST.forEach(item => {
                if (row.agent_account_status === item.value) {
                    text = item.label
                }
            })
            return text
        }
        const getColor = () => {
            let colorText = 'black'
            if (row.agent_account_status === REGISTERED_PENDING) {
                colorText = 'warning'
            } else if (row.agent_account_status === REGISTERED_RE_ASSIGN) {
                colorText = 'warning'
            } else if (row.agent_account_status === REGISTERED_ACTIVE) {
                colorText = 'success'
            } else if (row.agent_account_status === REGISTERED_LOCKED) {
                colorText = 'danger'
            } else if (row.agent_account_status === REGISTERED_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <span className={`text-capitalize font-weight-bold text-${getColor()}`}>
                    {getStatus()}
                </span>
            </React.Fragment>
        )
    };
}
function formatInvite(action) {
    return (cell, row) => {
        return (
            <div className="w-100">
                {row.agent_account_status === REGISTERED_ACTIVE ? (
                    <button
                        onClick={(e) => action(cell)}
                        className="w-100 btn btn-success text-white"
                    >
                        Resend Invite
                    </button>
                ): (
                    <button
                        onClick={(e) => action(cell)}
                        className="w-100 btn btn-info text-white"
                    >
                        Active and Invite
                    </button>
                )}
            </div>
        );
    }
}

export default VendorGrid;
