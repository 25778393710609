export const validators = (values, props) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'This field is required';
    }
    if (!values.job_type) {
        errors.job_type = 'This field is required';
    }
    if (!values.app_fee) {
        errors.app_fee = 'This field is required';
    }
    if (values.app_fee && values.app_fee >= 100) {
        errors.app_fee = 'This can\'t be greater than 99.99';
    }
    if (!values.broker_fee) {
        errors.broker_fee = 'This field is required';
    }
    if (!values.agent_feedback_form) {
        errors.agent_feedback_form = 'This field is required';
    }
    if (!values.customer_feedback_form) {
        errors.customer_feedback_form = 'This field is required';
    }
    if (values.broker_fee && values.broker_fee >= 100) {
        errors.broker_fee = 'This can\'t be greater than 99.99';
    }
    // If it's not a submenu
    if (!values.multiple_description) {
        if (!values.customer_type) {
            errors.customer_type = 'This field is required';
        }
        if (!values.primary_color) {
            errors.primary_color = 'This field is required';
        }
        if (!values.icon) {
            errors.icon = 'This field is required';
        }
        if (!values.base_price) {
            errors.base_price = 'This field is required';
        }
    } else {
        if (!values.job_type_option || !values.job_type_option.length) {
            errors.job_type_option = {_error: "At least one characteristic is required"};
        } else {
            const jobTypeOption = [];
            values.job_type_option.forEach((jobOption, indexB) => {
                jobTypeOption[indexB] = {};
                if (!jobOption || !jobOption.name) {
                    jobTypeOption[indexB] = {name: 'This field is required'};
                }
                if (!jobOption || !jobOption.primary_color) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], primary_color: 'This field is required'};
                }
                if (!jobOption || !jobOption.icon) {
                    jobTypeOption[indexB] = { ...jobTypeOption[indexB], icon: 'This field is required' };
                }
                if (!jobOption || !jobOption.base_price) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], base_price: 'This field is required'};
                }
                if (!jobOption || !jobOption.agent_feedback_form) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], agent_feedback_form: 'This field is required'};
                }
                if (!jobOption || !jobOption.customer_feedback_form) {
                    jobTypeOption[indexB] = { ...jobTypeOption[indexB], customer_feedback_form: 'This field is required' };
                }
                if (!jobOption || !jobOption.customer_type) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], customer_type: 'This field is required'};
                }
                if (!jobOption || !jobOption.app_fee) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], app_fee: 'This field is required'};
                }
                if (!!jobOption && jobOption.app_fee >= 100) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], app_fee: 'This can\'t be greater than 99.99'};
                }
                if (!jobOption || !jobOption.broker_fee) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], broker_fee: 'This field is required'};
                }
                if (!!jobOption && jobOption.broker_fee >= 100) {
                    jobTypeOption[indexB] = {...jobTypeOption[indexB], broker_fee: 'This can\'t be greater than 99.99'};
                }
                if (!jobTypeOption[indexB]) {
                    jobTypeOption[indexB] = undefined;
                }
            });
            errors.job_type_option = jobTypeOption;
        }
    }
    return errors;
};
