import React, {useState, useEffect} from "react";
import moment from "moment"
import Avatar from "react-avatar";
import Grid from "../../Utils/GridTable";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {
    AGENT_REGISTERED_PAGE_SIZE,
} from "../../../../utility/constants";


const NotificationGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("APP-NOTIFICATION-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="APP-NOTIFICATION-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={AGENT_REGISTERED_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    edit: 'app_notifications',
                                }),
                        },
                        {
                            dataField: "title",
                            sort: false,
                            text: "Title",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                    textAlign: "left",
                                };
                            },
                        },
                        {
                            dataField: "message",
                            sort: false,
                            text: "Message",
                        },
                        {
                            dataField: "description",
                            sort: false,
                            text: "Description",
                        },
                    ]}
                />
            </div>
        </div>
    );

};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('lll')}
            </p>
        )
    };
}

export default NotificationGrid;
