import {api} from "api";
import moment from "moment";
import {handleActions} from "redux-actions";
import {change, initialize} from "redux-form";
import {getDashboardDateRange, STATE_USA_LIST, SELECT_ALL_OPTION} from "../../../utility/constants";


// -----------------------------------------------------
// ------------------     CONST     --------------------
// -----------------------------------------------------
const SET_LOADER = "SET_LOADER_REPORTS";
const SET_LOADER_2 = "SET_LOADER_2_REPORTS";
const SET_BROKERS = "SET_BROKERS_REPORTS";
const SET_BROKERAGES = "SET_BROKERAGES_REPORTS";
const SET_FRANCHISES = "SET_FRANCHISES_REPORTS";
const SET_JOB_STATUS = "SET_JOB_STATUS_REPORTS";
const SET_DATA_SHOW_IT_CATEGORY = "SET_DATA_SHOW_IT_CATEGORY_REPORTS";
const SET_DATA_CATEGORY = "SET_DATA_CATEGORY_REPORTS";
const SET_SHOWIT_RANGER = "SET_SHOWIT_RANGER_REPORTS";
const SET_DATA_JOB_STATUS = "SET_DATA_JOB_STATUS_REPORTS";
const SET_DATA_JOB_TYPE = "SET_DATA_JOB_TYPE_REPORTS";
const SET_JOB_TYPE_TYPE = "SET_JOB_TYPE_TYPE_REPORTS";
const SET_DATA_ACTIVE_AGENT = "SET_DATA_ACTIVE_AGENT_REPORTS";
const SET_DATA_AGENT_SIGNED_UP = "SET_DATA_AGENT_SIGNED_UP_REPORTS";
const SET_DATA_JOB_PERFORMED = "SET_DATA_JOB_PERFORMED_REPORTS";
const SET_DATA_JOB_POSTED = "SET_DATA_JOB_POSTED_REPORTS";
const SET_DATA_AGENT_SIGN_UP_TREND = "SET_DATA_AGENT_SIGN_UP_TREND_REPORTS";
const SET_AGENT_SIGN_RANGER = "SET_AGENT_SIGN_RANGER_REPORTS";

// -----------------------------------------------------
// ------------------   ACTION      --------------------
// -----------------------------------------------------
const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});
const setLoader2 = (loader2) => ({
    type: SET_LOADER_2,
    loader2,
});
const setJobStatus = (job_status) => ({
    type: SET_JOB_STATUS,
    job_status,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});
const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});

const setOptionsFranchises = franchises => ({
    type: SET_FRANCHISES,
    franchises,
});
const setDataShowITCategory = dataShowITCategory => ({
    type: SET_DATA_SHOW_IT_CATEGORY,
    dataShowITCategory,
});
const setDataCategory = dataCategory => ({
    type: SET_DATA_CATEGORY,
    dataCategory,
});
const setShowITRanger = showITRanger => ({
    type: SET_SHOWIT_RANGER,
    showITRanger,
});

const setJobTypeType = jobType => ({
    type: SET_JOB_TYPE_TYPE,
    jobType,
});
const setDataJobStatus = dataJobStatus => ({
    type: SET_DATA_JOB_STATUS,
    dataJobStatus,
});
const setDataJobType = dataJobType => ({
    type: SET_DATA_JOB_TYPE,
    dataJobType,
});
const setDataActiveAgent = dataActiveAgent => ({
    type: SET_DATA_ACTIVE_AGENT,
    dataActiveAgent,
});
const setDataAgentSignedUp = dataAgentSignedUp => ({
    type: SET_DATA_AGENT_SIGNED_UP,
    dataAgentSignedUp,
});
const setDataJobPerformed = dataJobPerformed => ({
    type: SET_DATA_JOB_PERFORMED,
    dataJobPerformed,
});
const setDataJobPosted = dataJobPosted => ({
    type: SET_DATA_JOB_POSTED,
    dataJobPosted,
});
const setDataAgentSignUpTrend = dataAgentSignUpTrend => ({
    type: SET_DATA_AGENT_SIGN_UP_TREND,
    dataAgentSignUpTrend,
});
const setAgentSignRanges = (agentSignRange) => ({
    type: SET_AGENT_SIGN_RANGER,
    agentSignRange,
});


const changeFilters = (type, value=null) => (dispatch) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'state':
            setTimeout(() => {
                //  ALL
                dispatch(getDataShowITCategories())
                dispatch(getDataJobCategories())
                dispatch(getDataJobStatus())
                dispatch(getDataJobType())
                dispatch(getDataActiveAgent())
                dispatch(getDataAgentSignedUp())
                dispatch(getDataJobPerformed())
                dispatch(getDataJobPosted())
                dispatch(getDataAgentSignUpTrend());
            }, 500)
            break;
        case 'franchise':
            setTimeout(() => {
                dispatch(getBrokerOption(true))
            }, 500)
            break;
        case 'broker':
            setTimeout(() => {
                dispatch(getBrokeragesOption(true))
            }, 500)
            break;
        case 'brokerage':
            setTimeout(() => {
                //  ALL
                dispatch(getDataShowITCategories())
                dispatch(getDataJobCategories())
                dispatch(getDataJobStatus())
                dispatch(getDataJobType())
                dispatch(getDataActiveAgent())
                dispatch(getDataAgentSignedUp())
                dispatch(getDataJobPerformed())
                dispatch(getDataJobPosted())
                dispatch(getDataAgentSignUpTrend());
            }, 500)
            break;
        case 'time':
            setTimeout(() => {
                //  ALL
                dispatch(getDataShowITCategories())
                dispatch(getDataJobCategories())
                dispatch(getDataJobStatus())
                dispatch(getDataJobType())
                dispatch(getDataAgentSignedUp())
                dispatch(getDataJobPerformed())
                dispatch(getDataJobPosted())
                dispatch(getDataAgentSignUpTrend());
            }, 500)
            break;
        case 'start_date':
            setTimeout(() => {
                //  ALL
                dispatch(getDataShowITCategories())
                dispatch(getDataJobCategories())
                dispatch(getDataJobStatus())
                dispatch(getDataJobType())
                dispatch(getDataAgentSignedUp())
                dispatch(getDataJobPerformed())
                dispatch(getDataJobPosted())
                dispatch(getDataAgentSignUpTrend());
            }, 500)
            break;
        case 'end_date':
            setTimeout(() => {
                //  ALL
                dispatch(getDataShowITCategories())
                dispatch(getDataJobCategories())
                dispatch(getDataJobStatus())
                dispatch(getDataJobType())
                dispatch(getDataAgentSignedUp())
                dispatch(getDataJobPerformed())
                dispatch(getDataJobPosted())
                dispatch(getDataAgentSignUpTrend());
            }, 500)
            break;
        default:
            break;
    }
}

const getBrokerOption =
    (getData = null, selectAll=false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { ReportsForm } = store.form;
        let params = {franchise: "", is_multi: true };
        if (ReportsForm && ReportsForm.values) {
            const { state, franchise} = ReportsForm.values;
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                if (franchise.length == 0) {
                    params.franchise = ""
                }else {
                    params.franchise = franchise;
                }
            }
        }

        api.get(`general/option_broker`, params)
            .then((response) => {
                console.log("RESP BRK: ", response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    
                    if (selectAll) {
                        dispatch(
                            change("ReportsForm", "broker", SELECT_ALL_OPTION["value"])
                        );
                    }
                    setTimeout(() => {
                        dispatch(getBrokeragesOption(getData, selectAll));
                    }, 500);
                } else {
                    option = [...response.results];
                    const broker_id = option.length ? option[0].value : "";
                    dispatch(
                        change("ReportsForm", "broker", [broker_id])
                    );
                    setTimeout(() => {
                        dispatch(getBrokeragesOption(getData, selectAll));
                    }, 500);
                }
                dispatch(setBrokers(option));
            })
            .catch(() => {
                dispatch(setBrokers([]));
                dispatch(change("ReportsForm", "broker", []));
                setTimeout(() => {
                    dispatch(getBrokeragesOption(getData, selectAll));
                }, 500);
            })
            .finally(() => {
            });
    };

const getBrokeragesOption =
    (getData = null, selectAll=false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { ReportsForm } = store.form;
        let params = { broker_id: "", state: "", franchise: "", is_multi: true};
        if (ReportsForm && ReportsForm.values) {
            const { state, broker,  franchise } = ReportsForm.values;
            if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                if (broker.length == 0) {
                    params.broker_id = ""
                }else {
                    params.broker_id = broker;
                }
            }

            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                if (franchise.length == 0) {
                    params.franchise = ""
                }else {
                    params.franchise = franchise;
                }
            }
        }

        api.get(`general/option_brokerage`, params)
            .then((response) => {
                console.log("RESP BRKGS: ", response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll) {
                        dispatch(
                            change(
                                "ReportsForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    const brokerage_id = option.length ? option[0].value : "";
                    dispatch(
                        change(
                            "ReportsForm",
                            "brokerage",
                            [brokerage_id]
                        )
                    );
                }
                dispatch(setBrokerages(option));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(change("ReportsForm", "brokerage", []));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        //  ALL
                        dispatch(getDataShowITCategories())
                        dispatch(getDataJobCategories())
                        dispatch(getDataJobStatus())
                        dispatch(getDataJobType())
                        dispatch(getDataActiveAgent())
                        dispatch(getDataAgentSignedUp())
                        dispatch(getDataJobPerformed())
                        dispatch(getDataJobPosted())
                        dispatch(getDataAgentSignUpTrend());
                    }, 500)
                }
            });
    };

const getFranchisesOption = (getData = null, selectAll=false) => (dispatch) => {
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                // // let value = [];
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                //     // value.push(franchise.id)
                // });
                if(selectAll) {
                    dispatch(
                        change(
                            "ReportsForm",
                            "franchise",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
            } else {
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                // });
                const franchise_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "ReportsForm",
                        "franchise",
                        [franchise_id]
                    )
                );
                dispatch(getBrokerOption(franchise_id, 'franchise'))
            }
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
            dispatch(change("ReportsForm", "franchise", ""));
            dispatch(getBrokerOption(null, 'franchise'))
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    //  ALL
                    dispatch(getDataShowITCategories())
                    dispatch(getDataJobCategories())
                    dispatch(getDataJobStatus())
                    dispatch(getDataJobType())
                    dispatch(getDataActiveAgent())
                    dispatch(getDataAgentSignedUp())
                    dispatch(getDataJobPerformed())
                    dispatch(getDataJobPosted())
                    dispatch(getDataAgentSignUpTrend());
                }, 500)
            }
        });
};

const getDataShowITCategories = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader(true));
    }
    const store = getStore();
    const {showITRanger} = store['reports'];
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, category, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            // params.category = category;
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/show_it_category", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'color' && names !== 'state_job' && names !== 'state' && names !== 'usd' && names !== 'background' && names !== 'count_job') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function (o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                                        
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: item.name,
                    borderWidth: 1.6,
                    usd: item.usd,
                    count_job: item.count_job,
                    color: item.color ? item.color : "#cecece",
                    backgroundColor: item.background ? item.background : "#cecece",
                })
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataShowITCategory(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataShowITCategory({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader(false));
            }
        });
};

const getDataJobCategories = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader(true));
    }
    const store = getStore();
    const {showITRanger} = store['reports'];
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, category, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            // params.category = category;
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    console.log("ENTRA FECHA INICIO: ", start_date);
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    console.log("ENTRA FECHA INICIO: ", end_date);
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                console.log("DATES I: ", startDate);
                console.log("DATES E: ", endDate);
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/jobs_category", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'color' && names !== 'state_job' && names !== 'state' && names !== 'total' && names !== 'background') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function (o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                                        
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: item.name,
                    borderWidth: 1.6,
                    total: item.total,
                    color: item.color ? item.color : "#cecece",
                    backgroundColor: item.background ? item.background : "#cecece",
                })
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataCategory(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataCategory({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader(false));
            }
        });
};

const setRanger = (option, typeRange) => (dispatch) => {
    dispatch(setShowITRanger(option))
    setTimeout(() => {
        dispatch(getDataShowITCategories())
        dispatch(getDataJobCategories())
    }, 800)
}

//  SECTION #2
const getDataJobStatus = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {jobType} = store['reports'];
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(jobType) {
        params.category = jobType.value
    }

    api.get("report/job_status", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];
            
            if (params.category && params.category == "job_type") {
                const colorItems = [
                    { color: "#FFF", background: "#2E7D32" },
                    { color: "#282828", background: "#F2DCBB" },
                    { color: "#FFF", background: "#C62828" },
                ];
                response.data.forEach((item, index)=> {
                    labels.push(item.name)
                    if (index == 0) {
                        //  COMPLETED
                        let dataCompleted = response.data.map(item => 0)
                        const itemCompleted = item.completed ? item.completed : {}
                        dataCompleted[index] = itemCompleted.total ? itemCompleted.total : 0
                        dataReceived.push({
                            data: dataCompleted,
                            tension: 0.4,
                            label: itemCompleted.name ? itemCompleted.name : "Completed",
                            borderWidth: 1.6,
                            color: colorItems[0].color,
                            backgroundColor: colorItems[0].background,
                        })
                        //  Expired
                        let dataExpired = response.data.map(item => 0)
                        const itemExpired = item.expired ? item.expired : {}
                        dataExpired[index] = itemExpired.total ? itemExpired.total : 0
                        dataReceived.push({
                            data: dataExpired,
                            tension: 0.4,
                            label: itemExpired.name ? itemExpired.name : "Expired",
                            borderWidth: 1.6,
                            color: colorItems[1].color,
                            backgroundColor: colorItems[1].background,
                        })
                        //  Canceled
                        let dataCanceled = response.data.map(item => 0)
                        const itemCanceled = item.canceled ? item.canceled : {}
                        dataCanceled[index] = itemCanceled.total ? itemCanceled.total : 0
                        dataReceived.push({
                            data: dataCanceled,
                            tension: 0.4,
                            label: itemCanceled.name ? itemCanceled.name : "Canceled",
                            borderWidth: 1.6,
                            color: colorItems[2].color,
                            backgroundColor: colorItems[2].background,
                        })
                    }else {
                        //  COMPLETED
                        let dataCompleted = dataReceived[0].data
                        const itemCompleted = item.completed ? item.completed : {}
                        dataCompleted[index] = itemCompleted.total ? itemCompleted.total : 0
                        dataReceived[0] = {
                            data: dataCompleted,
                            tension: 0.4,
                            label: itemCompleted.name ? itemCompleted.name : "Completed",
                            borderWidth: 1.6,
                            color: colorItems[0].color,
                            backgroundColor: colorItems[0].background,
                        }

                        //  Expired
                        let dataExpired = dataReceived[1].data
                        const itemExpired = item.expired ? item.expired : {}
                        dataExpired[index] = itemExpired.total ? itemExpired.total : 0
                        dataReceived[1] = {
                            data: dataExpired,
                            tension: 0.4,
                            label: itemExpired.name ? itemExpired.name : "Expired",
                            borderWidth: 1.6,
                            color: colorItems[1].color,
                            backgroundColor: colorItems[1].background,
                        }

                        //  Canceled
                        let dataCanceled = dataReceived[2].data
                        const itemCanceled = item.canceled ? item.canceled : {}
                        dataCanceled[index] = itemCanceled.total ? itemCanceled.total : 0
                        dataReceived[2] = {
                            data: dataCanceled,
                            tension: 0.4,
                            label: itemCanceled.name ? itemCanceled.name : "Canceled",
                            borderWidth: 1.6,
                            color: colorItems[2].color,
                            backgroundColor: colorItems[2].background,
                        }
                    }
                })
            } else {
                response.data.forEach((item, index)=> {
                    let data = response.data.map(item => 0)
                    labels.push(item.name)
                    data[index] = item.total
    
                    dataReceived.push({
                        data: data,
                        tension: 0.4,
                        label: item.name,
                        borderWidth: 1.6,
                        color: item.color ? item.color : "#cecece",
                        backgroundColor: item.background ? item.background : "#cecece",
                    })
                })
            }



            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataJobStatus(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataJobStatus({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
}

const getDataJobType = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    api.get("report/job_type", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];
            

            response.data.forEach((item, index)=> {
                let data = response.data.map(item => 0)
                labels.push(item.name)
                
                data[index] = item.total

                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: item.name,
                    borderWidth: 1.6,
                    color: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                    backgroundColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                })
            })


            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataJobType(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataJobType({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
}

const setJobType = (option) => (dispatch) => {
    dispatch(setDataJobStatus({
        total: 0,
        labels: [],
        datasets: [],
    }))
    dispatch(setJobTypeType(option))
    setTimeout(() => {
        dispatch(getDataJobStatus())
    }, 500)
}

const getDataActiveAgent = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
    }

    api.get("report/active_agents", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = ["Yes", "No"];
            const agentActive = response && response.active ? response.active : 0
            const agentInactive = response && response.inactive ? response.inactive : 0 

            dataReceived.push({
                label: "Active Agents",
                data: [ agentActive , agentInactive],
                backgroundColor: [
                    "#E28B29",
                    "#76A4DC",
                ],
                hoverOffset: 4,
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: agentActive + agentInactive,
            }
            dispatch(setDataActiveAgent(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataActiveAgent({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
}

const getDataAgentSignedUp = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    api.get("report/agent_signed_by_office", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];
            let data = response.data.map(() => 0)
            response && response.data.forEach((item, index) => {
                labels.push(item.firm_name)
                data[index] = item.total_agent
            })

            dataReceived.push({
                label: "Agent Signed Up",
                data: data,
                backgroundColor: "#76A4DC",
                hoverOffset: 4,
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total,
            }
            dispatch(setDataAgentSignedUp(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataAgentSignedUp({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
}

const getDataJobPerformed = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    api.get("report/job_performed_by_agents", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];
            let data = response.data.map(() => 0)
            response && response.data.forEach((item, index) => {
                labels.push(`${item.first_name} ${item.last_name}`)
                data[index] = item.total_jobs
            })

            dataReceived.push({
                label: "Job Performed by Agents",
                data: data,
                backgroundColor: "#76A4DC",
                hoverOffset: 4,
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total,
            }
            dispatch(setDataJobPerformed(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataJobPerformed({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
}

const getDataJobPosted = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    api.get("report/job_posted_by_agents", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];
            let data = response.data.map(() => 0)
            response && response.data.forEach((item, index) => {
                labels.push(`${item.first_name} ${item.last_name}`)
                data[index] = item.total_jobs
            })

            dataReceived.push({
                label: "Job Performed by Agents",
                data: data,
                backgroundColor: "#76A4DC",
                hoverOffset: 4,
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total,
            }
            dispatch(setDataJobPosted(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataJobPosted({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
}

const getDataAgentSignUpTrend = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {agentSignRange} = store['reports'];
    const { ReportsForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
    };
    if (ReportsForm && ReportsForm.values) {
        const { state, franchise, broker, brokerage, time, start_date, end_date } =
            ReportsForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(agentSignRange) {
        params.frequency = agentSignRange.value
    }

    api.get("report/agent_sign_up_trend", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];
            response.data.map((item) => {
                let data = []
                for (let names in item) {
                    if (item[names] === null) {
                        data.push(0)
                    } else {
                        data.push(item[names])
                    }
                    const _name = _.find(labels, function(o) {
                        return o == names
                    })
                    if (!_name) {
                        labels.push(names);
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: "Agent Sign Up Trend",
                    borderWidth: 1.6,
                    borderColor: '#76A4DC',
                    backgroundColor: '#76A4DC',
                })
            })

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total,
            }
            dispatch(setDataAgentSignUpTrend(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataAgentSignUpTrend({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
}

const setAgentSignRanger = (option) => (dispatch) => {
    dispatch(setAgentSignRanges(option))
    setTimeout(() => {
        dispatch(getDataAgentSignUpTrend())
    }, 800)
}

export const actions = {
    changeFilters,
    getBrokerOption,
    getBrokeragesOption,
    getFranchisesOption,
    getDataShowITCategories,
    getDataJobCategories,
    setRanger,
    getDataJobStatus,
    getDataJobType,
    setJobType,
    getDataActiveAgent,
    getDataAgentSignedUp,
    getDataJobPerformed,
    getDataJobPosted,
    getDataAgentSignUpTrend,
    setAgentSignRanger,
};

export const reducers = {
    [SET_LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [SET_BROKERS]: (state, {brokers}) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, {brokerages}) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_FRANCHISES]: (state, {franchises}) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_DATA_CATEGORY]: (state, {dataCategory}) => {
        return {
            ...state,
            dataCategory,
        };
    },
    [SET_DATA_SHOW_IT_CATEGORY]: (state, {dataShowITCategory}) => {
        return {
            ...state,
            dataShowITCategory,
        };
    },
    [SET_SHOWIT_RANGER]: (state, {showITRanger}) => {
        return {
            ...state,
            showITRanger,
        };
    },
    [SET_DATA_JOB_STATUS]: (state, {dataJobStatus}) => {
        return {
            ...state,
            dataJobStatus,
        };
    },
    [SET_DATA_JOB_TYPE]: (state, {dataJobType}) => {
        return {
            ...state,
            dataJobType,
        };
    },
    [SET_JOB_TYPE_TYPE]: (state, {jobType}) => {
        return {
            ...state,
            jobType,
        };
    },
    [SET_DATA_ACTIVE_AGENT]: (state, {dataActiveAgent}) => {
        return {
            ...state,
            dataActiveAgent,
        };
    },
    [SET_DATA_AGENT_SIGNED_UP]: (state, {dataAgentSignedUp}) => {
        return {
            ...state,
            dataAgentSignedUp,
        };
    },
    [SET_DATA_JOB_PERFORMED]: (state, {dataJobPerformed}) => {
        return {
            ...state,
            dataJobPerformed,
        };
    },
    [SET_DATA_JOB_POSTED]: (state, {dataJobPosted}) => {
        return {
            ...state,
            dataJobPosted,
        };
    },
    [SET_DATA_AGENT_SIGN_UP_TREND]: (state, {dataAgentSignUpTrend}) => {
        return {
            ...state,
            dataAgentSignUpTrend,
        };
    },
    [SET_AGENT_SIGN_RANGER]: (state, {agentSignRange}) => {
        return {
            ...state,
            agentSignRange,
        };
    },
}

export const initialState = {
    loader: false,
    loader2: false,
    brokers: [],
    brokerages: [],
    franchises: [],
    dataCategory: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataShowITCategory: {
        total: 0,
        labels: [],
        datasets: [],
    },
    showITRanger: {label: 'Daily', value: 'daily'},
    agentSignRange: {label: 'Daily', value: 'daily'},
    dataJobStatus: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataJobType: {
        total: 0,
        labels: [],
        datasets: [],
    },
    jobType: {label: 'All', value: ''},
    dataActiveAgent: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataAgentSignedUp: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataJobPerformed: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataJobPosted: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataAgentSignUpTrend: {
        total: 0,
        labels: [],
        datasets: [],
    },
}

export default handleActions(reducers, initialState);
