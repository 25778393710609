import React, {useState, useEffect} from "react";
import moment from "moment"
import Avatar from "react-avatar";
import Grid from "../../Utils/GridTable";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {
    AGENT_REGISTERED_PAGE_SIZE,
} from "../../../../utility/constants";


const DeletedAgentList = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        remove,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("PROMOTION-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PROMOTION-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={AGENT_REGISTERED_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    remove: remove,
                                    // view: "promotions",
                                    edit: 'promotions',
                                }),
                        },
                        {
                            dataField: "image",
                            formatter: avatar(),
                            text: " ",
                        },
                        {
                            dataField: "created",
                            formatter: dateCreated(),
                            text: "Date created",
                        },
                        {
                            dataField: "title",
                            sort: false,
                            text: "Title",
                        },
                        {
                            dataField: "link",
                            sort: false,
                            text: "Link",
                            formatter: (cell, row) => {
                                return (
                                    <div>
                                        {cell ? (
                                            <a href={cell} target="_blank" style={{color: "#007bff"}}>{cell}</a>
                                        ) : null}
                                    </div>
                                )
                            }
                        },
                        {
                            dataField: "currently_active",
                            sort: false,
                            text: "Active",
                            formatter: (cell, row) => {
                                return (
                                    <div>
                                        {cell ? (
                                            <span className="font-weight-bold text-success">Yes</span>
                                        ): (
                                            <span className="font-weight-bold text-dark">No</span>
                                        )}
                                    </div>
                                )
                            }
                        },
                    ]}
                />
            </div>
        </div>
    );

};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('lll')}
            </p>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.first_name}
                />
            </div>
        );
    };
}

export default DeletedAgentList;
