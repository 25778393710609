import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/feedbackFormAgent/feedbackFormAgent";
import FeedbackFormAgentView from './FeedbackFormAgentView';


const ms2p = (state) => {
    return {
        ...state.feedbackFormAgent,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FeedbackFormAgentView);
