import React, { Component } from 'react'
import FeesStructureForm from './FeesScructureForm'
const typeText = 200

export default class FeesStructure extends Component {
    componentDidMount() {
        this.props.getSettingsText('FeesStructureTextForm', typeText)
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {loader, onSubmitSettingText} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Fees Sctructure Text</div>
                    </div>
                </div>
                <FeesStructureForm
                    onSubmit={(data) => {
                        onSubmitSettingText({...data, type: typeText})
                    }}
                    goBack={this.goBack}
                    loader={loader}
                    />
            </div>
        )
    }
}
