import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import DeletedAgentReinstateForm from "./DeletedAgentReinstateForm"

class DeletedAgentReinstate extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {match, initBrokerBrokerageFormOptions} = this.props;
        if (match.params.id) {
            initBrokerBrokerageFormOptions(match.params.id);
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.item.working_area !== prevProps.item.working_area) {
            this.props.setWorkingArea({
                latitude_center: this.props.item.working_area ? this.props.item.working_area.latitude_center : prevProps.item.working_area.latitude_center,
                longitude_center: this.props.item.working_area ? this.props.item.working_area.longitude_center : prevProps.item.working_area.longitude_center,
            })
        }
    }

    setWorkingArea = (data) => {
        if (data) {
            const {latitude, longitude} = data;
            if (latitude && longitude) {
                this.props.setWorkingArea({
                    latitude_center: latitude,
                    longitude_center: longitude,
                })
            }
        }else {
            this.props.setWorkingArea({
                latitude_center: 0,
                longitude_center: 0,
            })
        }
    }

    goBack = () => {
        const {history} = this.props
        history.goBack()
    }

    submit = (data) => {
        const {reinstateAgent} = this.props;
        reinstateAgent(data.id, data, this.goBack)
    }

    render() {
        //  State
        const {loader, item, brokers, brokerages, workingArea} = this.props;
        //  Bind
        const {getBrokerageFormOption} = this.props;

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={this.goBack}>
                                <i className="material-icons pointer">arrow_back</i> 
                            </button>
                            Reinstate Agent
                        </div>
                    </div>
                </div>
                <div
                    className="general-container card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <DeletedAgentReinstateForm
                            item={item}
                            setWorkingArea={this.setWorkingArea}
                            optionBrokers={brokers}
                            optionBrokerages={brokerages}
                            getBrokerageFormOption={getBrokerageFormOption}
                            loader={loader}
                            goBack={this.goBack}
                            onSubmit={this.submit}
                            center={{
                                lat: workingArea.latitude_center,
                                lng: workingArea.longitude_center
                            }}
                        />
                    </LoadMask>
                </div>

            </div>
        );
    }
}

export default DeletedAgentReinstate;
