import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderSearchField,
    renderNumber,
    SelectField,
    MultiSelectField,
} from "../../Utils/renderField";
import {
    STATE_USA_LIST,
    BROKER_OPERATIONAL_MODE_LIST,
    SELECT_ALL_OPTION,
} from "../../../../utility/constants";

let agentsJobsForm = (props) => {
    //  State
    const {
        optionFranchises = [],
        optionBrokers = [],
        optionBrokerages = [],
        optionJobTypes= [],
    } = props;

    //  Bind
    const { changeFilters } = props;

    return (
        <div className="row">
            {/* <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="state">State</label>
                    <Field
                        name="state"
                        options={STATE_USA_LIST}
                        set_value={(value) => {
                            changeFilters("state", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div> */}
            <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="franchise">Franchise</label>
                    <Field
                        name="franchise"
                        options={optionFranchises}
                        set_value={(value) => {
                            changeFilters("franchise", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="broker">Broker</label>
                    <Field
                        name="broker"
                        options={optionBrokers}
                        set_value={(value) => {
                            changeFilters("broker", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="brokerage">Office</label>
                    <Field
                        name="brokerage"
                        options={optionBrokerages}
                        set_value={(value) => {
                            changeFilters("brokerage", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="operational_mode">Operational Mode</label>
                    <Field
                        name="operational_mode"
                        options={BROKER_OPERATIONAL_MODE_LIST}
                        set_value={(value) => {
                            changeFilters("operational_mode", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div> 
            <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="job_type">Job Type</label>
                    <Field
                        name="job_type"
                        options={optionJobTypes}
                        set_value={(value) => {
                            changeFilters("job_type", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="job_status">Job Status</label>
                    <Field
                        name="job_status"
                        options={[{label: "Posted", value: 5}, {label: "Applied", value: 20}, {label: "Hired/Claimed", value: 25}]}
                        set_value={(value) => {
                            changeFilters("job_status", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div
                className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}
            >
                <div className="form-group has-feedback w-100">
                    <label htmlFor="agent_category">Agent</label>
                    <Field
                        name="agent_category"
                        options={[{label: "All Agents", value: ""}, {label: "Active Agents", value: "active"}]}
                        set_value={(value) => {
                            changeFilters("agent_category", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
        </div>
    );
};

agentsJobsForm = reduxForm({
    form: "agentsJobsForm", // a unique identifier for this form
    initialValues: { state: SELECT_ALL_OPTION["value"], operational_mode: SELECT_ALL_OPTION["value"], job_status: SELECT_ALL_OPTION["value"], agent_category: []},
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(agentsJobsForm);

const selector = formValueSelector("agentsJobsForm");

const mstp = (state) => {
    const brokerageValue = selector(state, "brokerage");

    return {
        brokerageValue,
    };
};

export default connect(mstp, null)(agentsJobsForm);
// export default agentsJobsForm;
