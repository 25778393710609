import { connect } from 'react-redux'
import InfluencerTeamProfile from './InfluencerTeamProfile'
import {actions} from '../../../../redux/modules/influencer/team'

const mapStateToProps = (state, ownProps) => ({
    ...state.influencerTeam
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerTeamProfile)
