import React, {Component} from 'react';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CampaignForm from "./CampaignForm";
import { SubmissionError } from 'redux-form'
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";

const TwilioModal = (props) => {
    //  State
    const {open, item=null, total=0} = props;

    return (
        <Modal
            center
            showCloseIcon={false}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            open={open}
            classNames={{ modal: "rounded col-lg-4 col-11", closeIcon: "bg-transparent rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <h3 className='text-success text-center'>Sending Texts...</h3>
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw text-info"></i>
                    <h6 className='text-info mt-3'>{item.total_sent ? item.total_sent : 0} / {item.total_to_sent ? item.total_to_sent : 0}</h6>
                </div>
            </div>
        </Modal>
    )
}

class CreateCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
            image_error: null,
            agents_error: null
        };
    }

    componentWillMount() {
        const {match, readCampaign, getBrokerOption, getFranchisesOption} = this.props;
        if (match.params.id) {
            readCampaign(match.params.id);
        }else {
            getBrokerOption(null, true, true)
            getFranchisesOption(false, true)
        }
        
        if (match.path === '/campaigns/:id') {
            this.setState({is_view: true, is_update: false});

        }
        if (match.path === '/campaigns/:id/edit') {
            this.setState({is_view: false, is_update: true});
        }
    }

    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar las props):
        if (this.props.agentsData.count !== prevProps.agentsData.count) {
          if (this.props.agentsData.count > 0) {
            this.setAgentsRequired(true)
          }
        }
      }

    // setImageRequired = () => {
    //     this.setState({image_error: 'This field is required'})
    // }
    setAgentsRequired = (valid=false) => {
        this.setState({agents_error: valid==true ? null : 'The list is empty, at least you must have 1 agent'})
    }
    
    create = (data) => {
        const {agentsData} = this.props;
        let valid = true
        if (agentsData.count <= 0) {
            this.setAgentsRequired();
            valid = false;
        }

        // if (!this.state['campaign_photo']) {
        //     valid = false;
        // }

        if (valid) {
            let photos = [];
            if (this.state['campaign_photo'] && data.type === 'mms') {
                photos.push({ name: "campaign_photo", file: this.state.campaign_photo })
            }
            this.props.createCampaign(data, photos)
        }
    };

    update = (data) => {
        const {match, item} = this.props;
        const _id = data.id? data.id : match.params.id;

        const {agentsData} = this.props;
        let valid = true
        if (agentsData.count <= 0) {
            this.setAgentsRequired();
            valid = false;
        }

        // if (!item.image && !this.state['campaign_photo']) {
        //     valid = false;
        // }

        if (valid) {
            let photos = [];
            if (this.state['campaign_photo'] && data.type === 'mms') {
                photos.push({ name: "campaign_photo", file: this.state.campaign_photo });
            }
            this.props.updateCampaign(_id, data, photos)
        }
    };

    onFileChange = (e, file, key) => {
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }
            reader.onload = (e) => {
                this.setState({ [key]: file, image_error: null });
            };
            reader.readAsDataURL(file);
        }
    }

    sendMessage = () => {
        const {match, item, sendMessage} = this.props;
        if (match.params.id && item.id) {
            sendMessage(match.params.id);
        }
    }


    render() {
        //  State
        const {match, item, loader, franchises, brokers, brokerages, agentsData, agentsPage, unregisteredAgentsData, loaderAgentsData, unregisteredAgentsPage, twilioModal, twilioData} = this.props;
        const {is_view, is_update} = this.state;
        const sendFunction = match.params.id
            ? this.update
            : this.create;
        //  Bind
        const {changeFilters, getDataAgents} = this.props

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_view && "View Campaign"}
                            {is_update && "Update Campaign"}
                            {!is_update && !is_view && "Create Campaign"}
                        </div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <CampaignForm
                            item={item}
                            loader={loader}
                            is_view={is_view}
                            onSubmit={sendFunction}
                            is_update={is_update}
                            onFileChange={this.onFileChange}
                            franchises={franchises}
                            brokers={brokers}
                            brokerages={brokerages}
                            data={agentsData}
                            page={agentsPage}
                            image={this.state['campaign_photo']}
                            // image_error={this.state.image_error}
                            agents_error={this.state.agents_error}
                            // setImageRequired={this.setImageRequired}
                            changeFilters={changeFilters}
                            getDataAgents={getDataAgents}
                            sendMessage={this.sendMessage}
                            unregisteredAgentsData={unregisteredAgentsData}
                            loaderAgentsData={loaderAgentsData}
                            unregisteredAgentsPage={unregisteredAgentsPage}
                        />
                    </LoadMask>
                </div>

                <TwilioModal  open={twilioModal} item={twilioData}/>
            </div>
        )
    }
}

export default CreateCampaign;
