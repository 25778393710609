import React, { Component } from 'react'
import SponcerGrid from './SponcerGrid'
import {Modal} from "react-responsive-modal";
import CreateTeamForm from "./CreateTeamForm"
import {MonthlyPRofiteChart, AnnualyProfiteChart, AgentsChart, InfluencerLinks} from './InfluencerProfileComponents'

class InfluencerProfile extends Component {

    state = {
        influencer_id: null,
        stateModal: false,
        nameFile: '',
        active: true,
        tableHeight: 500,
        linkModal: false,
    }

    constructor(props) {
        super(props)

    }

    componentWillMount() {

    }

    async componentDidMount() {
        const {match} = this.props;
        await this.setTableHeigh()
        if (match.params.id) {
            const id = match.params.id;
            this.setState({influencer_id: id})
            setTimeout(() => {
                this.props.toListInfluencer(id)
                this.props.getUrlExelTemplate();
                this.props.getMonthlyProfite(id)
                this.props.getYearlyProfite(id)
            }, 400);
        }

    }

    openModal = () => {
        this.setState({stateModal: true})
    }

    closeModal = () => {
        this.setState({stateModal: false})
    }
    onChanged = (file) => {
        const {setExcel} = this.props;
        this.setState({
            nameFile: file.name,
            active: false,
        });
        setExcel(file);
    };

    setActive = (value) => {
        this.setState({
            nameFile: '',
            active,
        });
        const {setExcel} = this.props;
        setExcel(null);
    };

    onSubmit = (data) => {
        const {influencer_id} = this.state;
        const {createTeam} = this.props;
        if (influencer_id) {
            createTeam(influencer_id, data, this.closeModal)
        }
    }

    setTableHeigh = () => {

        return new Promise((resolve, reject) => {
            const containerItem = document.getElementById('LEADING-CONTAINER')
            const titleItem = document.getElementById('LEADING-TITLE')
            const graphItem = document.getElementById('LEADING-GRAPH')
            let _height = 500
            if (containerItem && titleItem && graphItem) {
                _height = containerItem.clientHeight - titleItem.clientHeight - graphItem.clientHeight
                if (_height < 500) {
                    _height = 500
                }
            }

            this.setState({tableHeight: _height})
            console.log("HEIGHT: ", _height);
            resolve(_height)
        })
    }

    goBack = (e) => {
        this.props.history.goBack()
    }

    openLinkModal = () => {
        this.setState({linkModal: true})
    }

    closeLinkModal = () => {
        this.setState({linkModal: false})
    }

    render() {

        //  State
        const {stateModal, nameFile, active, tableHeight} = this.state;
        const {loader2, loader, urlTemplate, influencerData, monthlyProfite, monthlyProfiteLoader, yearlyProfite, yearlyProfiteLoader, year} = this.props;
        //  Bind
        const {changeYear} = this.props;

        return (
            <div id="LEADING-CONTAINER" className="d-flex flex-column flex-1">
                <div
                    id="LEADING-TITLE"
                    className="page-header pl-1 pt-3 no-gutters row w-100"
                >
                    <div>
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            Influencer Profile
                        </div>
                    </div>
                </div>

                <div className="card border-card-page px-lg-4 py-2 flex-1">
                    <div className="col-sm-12 p-0 w-100">
                        <div className="row align-items-center">
                            <div className="col-md-2 col-sm-12">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img
                                        className="shadow-sm rounded-circle"
                                        src={
                                            influencerData &&
                                            influencerData.extra &&
                                            influencerData.extra.photo
                                                ? influencerData.extra.photo
                                                : require("../../../../../assets/img/uploadLogo.PNG")
                                        }
                                        alt="logo"
                                        style={{
                                            width: "145px",
                                            height: "145px",
                                            border: "2px solid #C7C7C7",
                                        }}
                                    />
                                    <label className='text-center' htmlFor="">
                                        {influencerData &&
                                        influencerData.extra &&
                                        influencerData.extra.influencer
                                            ? influencerData.extra.influencer
                                            : ""}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 align-self-start d-flex flex-row align-items-center justify-content-center">
                                {influencerData &&
                                    influencerData.extra &&
                                    !influencerData.extra.has_team && (
                                        <button
                                            onClick={this.openModal}
                                            className="btn btn-primary text-white mr-3"
                                        >
                                            Create your agents team{" "}
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    )}

                                    <button
                                        onClick={this.openLinkModal}
                                        className="btn btn-secondary text-white"
                                    >
                                        Links
                                        <i className="fa fa-link"></i>
                                    </button>
                            </div>
                            <div className="col-md-3 col-sm-12 d-flex justify-content-end flex-row">
                                <div className="mx-3 my-2 shadow-sm rounded px-3 py-2">
                                    <img
                                        src={
                                            influencerData &&
                                            influencerData.extra &&
                                            influencerData.extra.logo
                                                ? influencerData.extra.logo
                                                : require("../../../../../assets/img/uploadLogo.PNG")
                                        }
                                        alt="logo"
                                        style={{ width: "100px" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 p-0 w-100 mt-3">
                            <SponcerGrid
                                loader={loader}
                                data={influencerData}
                                height={tableHeight}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 p-0 w-100 mt-3"></div>
                </div>
                <div id="LEADING-GRAPH" className="row my-3">
                    <div
                        className="col-lg-5 col-md-4 col-sm-12"
                        style={{ height: "inherit" }}
                    >
                        <MonthlyPRofiteChart
                            data={monthlyProfite}
                            loader={monthlyProfiteLoader}
                            year={year}
                            changeYear={changeYear}
                        />
                    </div>
                    <div
                        className="col-lg-5 col-md-4 col-sm-12"
                        style={{ height: "inherit" }}
                    >
                        <AnnualyProfiteChart
                            data={yearlyProfite}
                            loader={yearlyProfiteLoader}
                        />
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-12"
                        style={{ height: "inherit" }}
                    >
                        <AgentsChart
                            total={
                                influencerData &&
                                influencerData.totals &&
                                influencerData.totals.total_agents
                            }
                            registered={
                                influencerData &&
                                influencerData.totals &&
                                influencerData.totals.total_agents_registered
                            }
                        />
                    </div>
                </div>
                <Modal
                    center
                    closeOnEsc
                    open={stateModal}
                    closeOnOverlayClick={false}
                    onClose={this.closeModal}
                    classNames={{
                        modal: "col-lg-7 col-sm-11 px-0 border-card-20 pt-5 pb-2",
                    }}
                >
                    <CreateTeamForm
                        onSubmit={this.onSubmit}
                        text={nameFile}
                        handleOnChange={this.onChanged}
                        loader2={loader2}
                        active={active}
                        setActive={this.setActive}
                        closeModal={this.closeModal}
                        urlTemplate={urlTemplate}
                    />
                </Modal>
                <InfluencerLinks
                    open={this.state.linkModal}
                    onClose={this.closeLinkModal}
                    brokerLink={influencerData && influencerData.extra ? influencerData.extra.broker_sign_up_link : null}
                    hasTeam={influencerData && influencerData.extra ? influencerData.extra.has_team : false}
                    teamId={influencerData && influencerData.extra ? influencerData.extra.team_id : null}
                    />
            </div>
        );
    }
}

export default InfluencerProfile