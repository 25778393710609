import React from 'react';
import { Link } from "react-router-dom";
import { Field, FieldArray } from 'redux-form';
import {
    renderField,
    SelectField,
    renderFieldCheck,
} from '../Utils/renderField/renderField';
import { ANSWER_TYPE } from "../../../utility/constants";


const optionList = ({ seeView, fields, meta: { error, touched }, loadOptions }) => (
    <React.Fragment>
        {fields.map((item, index) => (
            <div key={index} className="form-group has-feedback col-12 mt-auto py-2 border">
                <div className="row">
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="question">Question {index + 1}</label>
                        <Field
                            name={`${item}.question`}
                            placeholder="Question"
                            component={renderField}
                            disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="question_type">Type</label>
                        <Field
                            name={`${item}.answer_type`}
                            placeholder="Type"
                            options={ANSWER_TYPE}
                            component={SelectField}
                            disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="type">Is Required</label>
                        <Field
                            name={`${item}.is_required`}
                            disabled={seeView}
                            component={renderFieldCheck}
                            type="checkbox"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        {!seeView && (
                            <img src={require("../../../../assets/img/delete.svg")} alt="del" onClick={() => (fields.remove(index))} className="ml-1 pointer" />
                        )}
                    </div>
                </div>
            </div>
        ))}
        {!seeView && (
            <div className="col-12">
                <button className="btn btn-success" type="button" onClick={() => fields.push({ is_required: true })}>Add Option</button>
            </div>
        )}
        {(error) && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
    </React.Fragment>
);


export const FeedbackFormAgentForm = (props) => {
    const { handleSubmit, multiple_description: multipleDescription, editView, seeView, setIcon, icon } = props;
    return (
        <div
            className="card general-container border-dark border-card-page px-4 py-4"
            // style={{
            //     minWidth: '99%',
            //     minHeight: '15%',
            // }}
        >
            <form action="" onSubmit={handleSubmit} className="row w-75 mx-auto justify-content-center mt-5">
                <div className="row w-100">
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="name">Name</label>
                        <Field
                            name="name"
                            placeholder="Name"
                            component={renderField}
                            disabled={seeView}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-md-12 mt-auto">
                        <label htmlFor="feedback_question">Question List</label>
                        <div className="row">
                            <FieldArray seeView={seeView} name="feedback_question" component={optionList} />
                        </div>
                    </div>
                </div>
                <div className="d-flex mb-4">
                    <Link className="btn button-03 ml-auto mr-1" to="/feedback-form-agent">Cancel</Link>
                    {!seeView && (
                        <button type="submit" className="btn btn-primary mr-auto ml-1">Save</button>
                    )}
                </div>
            </form>
        </div>
    );
};
