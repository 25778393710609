import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, SelectField } from '../../../Utils/renderField';


const SingUpFormStep2 = (props) => {

    const {
        handleSubmit,
    } = props;
    const exampleOptions = [
        {"label": "Managing", "value": "1"},
        {"label": "Qualifying", "value": "5"},
    ];

    const data = [];
    return (
        <form name="loginForm" className="row w-75 mx-auto m-5 justify-content-center" onSubmit={handleSubmit}>
            <div className="row w-100">
                <div className="form-group has-feedback  col-md-6">
                    <label htmlFor="broker_license_number">
                        What'´'s your RE License Number?
                    </label>
                    <Field
                        name="broker_license_number"
                        label="broker license number "
                        component={renderField}
                        type="text"
                        customClass="form-control-gray"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6">
                    <label htmlFor="roles" className="mx-0 px-0">What´s your role in the brokerage?</label>
                    <Field
                        name="roles"
                        label="Role"
                        component={SelectField}
                        options={exampleOptions}
                        customClass="form-control-gray"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="buttons-box w-75 mt-5 mx-auto">
                <button type="submit" className="btn button-01 w-50  align-self-center">Next</button>
            </div>

        </form>
    );
};

export default reduxForm({
    form: 'Step2RegisterForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            // firm_name: validators.exists()('This field is required'),
            roles: validators.exists()('This field is required'),
            broker_license_number: validators.exists()('This field is required'),
        });
    },
})(SingUpFormStep2);

