import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import users from './modules/usuarios/usuarios';
import notifications from './modules/notificaciones/notificaciones';
import brokerageAgent from "./modules/agent/agentBrokerage";
import agent from "./modules/agent/agent";
import agentRoster from "./modules/agent/agentRoster";
import agentReviews from "./modules/agent/agentReviews";
import brokerage from "./modules/brokerage/brokerage";
import influencer from './modules/influencer'
import influencerTeam from './modules/influencer/team'
import broker from "./modules/broker/broker";
import jobType from './modules/jobType/jobType';
import feedbackFormAgent from './modules/feedbackFormAgent/feedbackFormAgent';
import feedbackFormCustomer from './modules/feedbackFormCustomer/feedbackFormCustomer';
import feedbackFormClient from './modules/feedbackFormClient';
import admin from "./modules/admin/admin";
import stats from "./modules/stats";
import statsAgentPerformance from "./modules/stats/agentPerformance";
import statsRevenue from "./modules/stats/revenue"
import reports from './modules/stats/reports'
import dashboard from "./modules/dashboard";
import franchises from "./modules/franchises";
import campaigns from "./modules/twilio/campaigns";
import twilioAppVersion from "./modules/twilio/app_version";
import tutorialVideos from "./modules/tutorialVideo";
import sendgridCampaigns from "./modules/sendgrid";
import sendgridCustomCampaigns from "./modules/sendgrid/campaign";
import agentsJobs from './modules/agentsJobs'
import deletedAgent from './modules/deleted/agents'
import deletedOffice from './modules/deleted/office'
import deletedBroker from './modules/deleted/broker'
import setting from './modules/setting'
import promotions from './modules/promotion'
import appNotifications from './modules/notifications';
import cancellationDeleteOption from './modules/cancellationDeleteOption'
import legal from './modules/legal';
import jobTypeVendor  from './modules/jobTypeVendor'
import vendorClientFeedback from './modules/vendorClientFeedbackForm'
import vendorSetting from './modules/vendorSetting'
import vendors from './modules/vendors'
import trackerField from './modules/Tracker/field'
import tracker from './modules/Tracker'
import appReview from './modules/appReview';
import gifCardDesign from './modules/gifCardDesign';
import giftCardRawData from './modules/stats/gift_card';
import vendorRawData from './modules/stats/vendor';
import brokerReferral from './modules/brokerReferral';
import referralRawData from './modules/stats/referral';
import wholesalers from './modules/wholesaler';

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    users,
    jobType,
    feedbackFormAgent,
    feedbackFormCustomer,
    feedbackFormClient,
    routing,
    brokerageAgent,
    agent,
    agentRoster,
    agentReviews,
    brokerage,
    influencer,
    influencerTeam,
    broker,
    notifications,
    admin,
    stats,
    statsAgentPerformance,
    reports,
    dashboard,
    franchises,
    campaigns,
    twilioAppVersion,
    tutorialVideos,
    sendgridCampaigns,
    sendgridCustomCampaigns,
    agentsJobs,
    deletedAgent,
    deletedOffice,
    deletedBroker,
    statsRevenue,
    setting,
    promotions,
    appNotifications,
    cancellationDeleteOption,
    legal,
    jobTypeVendor,
    vendorClientFeedback,
    vendorSetting,
    vendors,
    trackerField,
    tracker,
    appReview,
    gifCardDesign,
    giftCardRawData,
    vendorRawData,
    brokerReferral,
    referralRawData,
    wholesalers,
});
