import React from "react";
import { Field, reduxForm } from "redux-form";
import {Link} from 'react-router-dom';
import { renderField, renderNumber } from "../../Utils/renderField";
import { validate, validators, combine , validatorFromFunction} from "validate-redux-form";

const createAgentRosterForm = (props) => {

    const {
        handleSubmit,
        remove=null
    } = props;

    return (
        <form
            name="createAgentRosterForm"
            className="my-4"
            style={{ minHeight: "20rem" }}
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto flex-1">
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="first_name">First name</label>
                    <Field
                        name="first_name"
                        label="First name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="last_name">Last name</label>
                    <Field
                        name="last_name"
                        label="Last name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row w-75 px-lg-5 mx-auto flex-1">
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="phone">Mobile number</label>
                    <Field
                        name="phone"
                        placeholder="Mobile number"
                        component={renderNumber}
                        className="form-control"
                        numberFormat={"###-###-####"}
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-sm-12">
                    <label htmlFor="email">Email</label>
                    <Field
                        name="email"
                        label="email"
                        component={renderField}
                        type="email"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row w-75 px-lg-5 mx-auto">
                <div className="form-group has-feedback col-md-6 col-sm-12">
                    <label htmlFor="license">License number</label>
                    <Field
                        name="license"
                        label="License number"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <Link to="/agents" className={`btn text-white btn-secondary mx- col-2`}>
                    Cancel
                </Link>
                <button type="submit" className="btn btn-primary mx-3 col-2">
                    Save
                </button>
                {
                    remove && (
                        <button type="button" className="btn btn-danger mx-3 col-2" onClick={remove}>
                            Delete
                        </button>
                    )
                }
            </div>
        </form>
    );
};

const numberPhone = (number) => validatorFromFunction(() => {
    return number.length === 10;
});

export default reduxForm({
    form: 'createAgentRosterForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            first_name: validators.exists()('This field is required'),
            last_name: validators.exists()('This field is required'),
            phone: combine(
                validators.exists()('This field is required'),
                numberPhone(data.phone)()('Invalid phone number'),
            ),
            email: validators.exists()('This field is required'),
        });
    },
})(createAgentRosterForm);