import React from 'react';
import {Field} from "redux-form";
import ChartJobWeek from "./index";
import {SelectField} from "../../Utils/renderField";
import ReactStars from "react-rating-stars-component";
import {NEW_MONTH_LIST, RANKING_LIST} from "../../../../utility/constants";


const PerformanceCard = (props) => {
    const {
        data,
        count,
        labels,
        loader2,
        offices,
        filterWeek,
        filterAgent,
        filterRanking,
        agentRegistered,
        setFilterOfficeWeek,
    } = props;

    return (
        <div className="card border-card-page col ml-lg-2 mt-lg-0 mt-sm-3">
            <div className="card-title row border-bottom py-3">
                <label className="col-lg-5 col-sm-12 my-auto label-semi-bold">
                    Jobs Performance
                </label>
                {/*-----------------------------------------*/}
                {/* ------------- filter by month ----------*/}
                <div className="col-lg-7 col-sm-12 row">
                    <label htmlFor="timeFilterByWeek" className="px-0 my-auto mr-1 label-semi-bold">
                        Time
                    </label>
                    <div className="col-lg-6 col-sm-12 row px-0 mx-1">
                        <div className="w-100">
                            <Field
                                options={NEW_MONTH_LIST}
                                isMulti={false}
                                disabled={false}
                                name="timeFilterPerformance"
                                id="timeFilterPerformance"
                                placeholder={"Week"}
                                component={SelectField}
                                className="w-100"
                                set_value={filterWeek}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body row pt-0 pb-2 px-0">
                <div className="col-lg-8 col-sm-12 px-1">
                    <ChartJobWeek
                        loading={loader2}
                        data={data}
                    />
                    {/*----------------------------------------*/}
                    {/*----------   SELECTED JOB WEEK   -------*/}
                    {/*----------------------------------------*/}
                    <div className="w-100 filter-job py-2 mt-3" style={{maxHeight: '10rem'}}>
                        {(data && data.datasets && data.datasets.length > 0) &&
                        data.datasets.map((job, index) => (
                            <div
                                key={`key-${index}-agent`}
                                style={{backgroundColor: job.backgroundColor}}
                                className="mx-3 mb-1 row px-3 py-1 align-items-center text-white text-small-chart"
                            >
                                <p className="col-6 px-0 py-0 my-0"> {job.label} </p>
                                <p className="col-3 px-0 my-0">{job.count} {job.count === 1 ? 'Job' : 'Jobs'} </p>
                                <div className="col-3 px-0">
                                    <ReactStars
                                        count={5}
                                        value={job.score ? job.score : 0}
                                        edit={false}
                                        isHalf={true}
                                        emptyIcon={<i className="material-icons start-small">star_border</i>}
                                        halfIcon={<i className="material-icons start-small">star_half</i>}
                                        filledIcon={<i className="material-icons start-small">star</i>}
                                        size={18}
                                        color={'#FFFFFF'}
                                        activeColor={"#FFFFFF"}
                                    />
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 w-100 border-left">
                    <div className="d-inline-flex row w-100 mx-auto " style={{minHeight: '100%'}}>
                        <div className="pt-2 w-100 px-3">
                            <div className="row">
                                <label className="px-0 my-auto mr-1">Select Office</label>
                                <div className="w-100 mb-4">
                                    <Field
                                        set_value={setFilterOfficeWeek}
                                        name="officeFilterPerformance"
                                        id={"officeFilterPerformance"}
                                        className="form-control w-100"
                                        placeholder={"Unassigned"}
                                        component={SelectField}
                                        options={offices}
                                        disabled={false}
                                        isMulti={false}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label className="px-0 my-auto mr-1">Select Agent</label>
                                <div className="w-100 mb-4">
                                    <Field
                                        name="agentFilterPerformance"
                                        id="agentFilterPerformance"
                                        isMulti={false}
                                        options={agentRegistered}
                                        set_value={filterAgent}
                                        component={SelectField}
                                        className="form-control w-100"
                                        placeholder={"Unassigned"}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <label className="px-0 my-auto mr-1">For Ranking</label>
                                <div className="w-100">
                                    <Field
                                        name="ratingFilterPerformance"
                                        id="ratingFilterPerformance"
                                        isMulti={false}
                                        disabled={false}
                                        options={RANKING_LIST}
                                        component={SelectField}
                                        set_value={filterRanking}
                                        placeholder={"Unassigned"}
                                        className="form-control w-100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col text-center align-self-end  mt-sm-0">
                            <h6 className="mb-0 font-weight-bold">Total Jobs</h6>
                            <h4 className="row justify-content-center text-dark font-weight-bolder mt-0">
                                {/*{jobWeekItem.count}*/}
                                {count}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerformanceCard;
