import React, { Component } from 'react'
import AppReviewGrid from './AppReviewGrid';

export default class componentName extends Component {
    componentDidMount() {
        this.props.toList();
    }

    render() {
        // State
        const { loader, page, data, searchChange, toList, remove } = this.props;

        return (
            <AppReviewGrid
                data={data}
                page={page}
                loader={loader}
                toList={toList}
                remove={remove}
                searchAction={searchChange}
            />
        )
    }
}
