import _ from 'lodash'
import moment from 'moment'
import "./vendorStyles.css"
import Swal from 'sweetalert2';
import classNames from 'classnames';
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import React, { useEffect, useState } from 'react'
import FileUploader from '../../../Utils/FileUploader/FileUploader';
import { PROFESSION_APPROVED, PROFESSION_PENDING, PROFESSION_REJECTED, PROFESSION_EDIT_FROM_SIGN_UP, PROFESSION_EDIT_FROM_ADMIN, PROFESSION_EDIT_FROM_VENDOR } from '../../../../../utility/constants';

export const ProfessionsView = (props) => {
    //  State
    const { item, optionProfessions, loader, initialValues } = props;
    //  Bind
    const {
        goBack,
        addProfession,
        removeProfession,
        approveProfession,
        rejectProfession,
    } = props;
    const [photo, setPhoto] = useState(null)
    const [notesModal, setNotesModal] = useState(false)
    const [notes, setNotes] = useState([])
    const [profession, setProfession] = useState(null)

    useEffect(() => {
        if (item) {
            if (item.photo) {
                setPhoto(item.photo)
            } else {
                setPhoto(null)
            }
        }
    }, [item])

    if (!item || (item && !item.id)) {
        return null
    }

    return (
        <div>
            <div className="row mx-auto pt-2">
                <div className='col-xl-3 col-lg-3 col-md-5 col-sm-12'>
                    <div className="form-group has-feedback">
                        <div className="avatar-round">
                            <FileUploader
                                disabled={true}
                                img={!!photo ? photo : null}
                            />
                        </div>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Sign-up Date</span>
                        <span className="font-weight-bolder text-primary">{moment(item.date_joined).format('ll')}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-5'>
                        <span className="font-weight-bolder h6 mb-1">Status</span>
                        {item.blocked === true ? (
                            <span className={`text-danger font-weight-bold`}>
                                Blocked
                            </span>
                        ) : (
                            item.locked === true ? (
                                <span className={`text-danger font-weight-bold`}>
                                    Locked
                                </span>
                            ) : (
                                <React.Fragment>
                                    {item.agent_account_status == 10 && (
                                        <span className="text-warning font-weight-bold">Pending</span>
                                    )}
                                    {item.agent_account_status == 20 && (
                                        <span className="text-success font-weight-bold">Active</span>
                                    )}
                                </React.Fragment>
                            )

                        )}
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Request Leads</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.request_leads : 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Contact the Client</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.contact_client : 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Completed Jobs</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.completed : 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">ShowIT Profit</span>
                        <span className="font-weight-bolder text-primary">$ {item && item.stats ? item.stats.profit.toFixed(2) : "0.00"}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Reviews</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.reviews : 0}</span>
                    </div>
                </div>
                <div className='col-xl-9 col-lg-9 col-md-7 col-sm-12'>
                    <ProfessionSelect
                        vendorId={item.id}
                        options={optionProfessions}
                        initialValues={initialValues}

                        addProfession={addProfession}
                        removeProfession={removeProfession}
                        approveProfession={approveProfession}
                        rejectProfession={rejectProfession}
                        openNotes={(notes, profession) => {
                            setNotes(notes)
                            setProfession(profession)
                            setNotesModal(true)
                        }}
                    />
                </div>
            </div>
            <NotesModal 
                open={notesModal}
                notes={notes}
                profession={profession}
                onClose={() => setNotesModal(false)}
            />
        </div>
    )
}

const ProfessionSelect = (props) => {
    //  State
    const {
        vendorId,
        options = [],
        invalid = false,
        initialValues = [],
        classNamesItem = 'col-xl-4 col-lg-6 col-md-12 col-sm-12',
    } = props;
    const [value, setValue] = useState([])
    const [items, setItems] = useState([])
    //  Bind
    const {
        addProfession,
        removeProfession,
        approveProfession,
        rejectProfession,
        openNotes,
    } = props;

    useEffect(() => {
        if (initialValues && initialValues.length > 0) {
            setValue(initialValues)
        }
    }, [initialValues])

    useEffect(() => {
        let _items = []
        if (options && options.length > 0) {
            _items = options.map(item => {
                const _item = getItem(item.value)
                return {
                    ...item,
                    checked: _item && _item.is_active ? true : false
                }
            })
            console.log("CHANGE ITEMS: ", _items);
        }
        setItems(_items)
    }, [options, value])

    const getItem = (vendor_type) => {
        let result = {}
        if (value && value.length > 0) {

            const item = value.find(item => item.vendor_type === vendor_type)
            if (item) {
                result = item
            }
        }
        return result
    }

    const onCheck = (optionProfession) => {
        const item = getItem(optionProfession.value)
        if (item && item.is_active) {
            onRemove(optionProfession.value)
        } else {
            onAdd(optionProfession.value)
        }
    }

    const onAdd = (id) => {
        Swal.fire({
            title: 'Are you sure to add the expertise?',
            text: "If you perform this action, the experty will be approved for this vendor!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, Add it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                addProfession(vendorId, { profession_id: id });
            }
        });
    }

    const onApprove = (id) => {
        Swal.fire({
            title: 'Are you certain you want to appove this expertise?',
            text: "By doing this, you're approving the vendor's offered expertise",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, Approve it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                approveProfession(vendorId, { profession_id: id });
            }
        });
    }

    const onRemove = (id) => {
        Swal.fire({
            title: 'Are you sure to remove the expertise?',
            text: "If you perform this action, the experty will be removed for this vendor!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, Remove it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                removeProfession(vendorId, { profession_id: id });
            }
        });
    }

    const onReject = (id) => {
        Swal.fire({
            title: 'Are you certain you want to reject the expertise offered by the vendor?',
            text: "By doing this, you're rejecting the vendor's offered expertise",
            input: 'textarea',
            inputPlaceholder: "Kindly provide the reason",
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to provide the reason!'
                }
            },
            inputAttributes: {
                autocapitalize: 'off'
            },
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, Reject it!',
            reverseButtons: true,
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.value) {
                rejectProfession(vendorId, { profession_id: id, note: result.value });
            }
        });
    }

    const onRemoveReject = (id) => {
        Swal.fire({
            title: 'Are you sure you want to retract the rejection for this expertise?',
            text: "By performing this action, the expertise will once again be awaiting your approval",
            input: 'textarea',
            inputPlaceholder: "Kindly provide the reason",
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to provide the reason!'
                }
            },
            inputAttributes: {
                autocapitalize: 'off'
            },
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, Reject it!',
            reverseButtons: true,
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.value) {
                rejectProfession(vendorId, { profession_id: id, note: result.value, type: 'remove' });
            }
        });
    }

    return (
        <div className="row">
            {items.map((option, index) => {
                const item = getItem(option.value)
                return (
                    <div className={classNames(classNamesItem)} key={index}>
                        <div className="custom-card d-flex flex-row align-items-center">
                            <div className="checkbox c-checkbox flex-1">
                                <label className="needsclick d-flex align-items-center mb-0">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            onCheck(option)
                                        }}
                                        checked={option.checked}
                                        className={classNames('', { 'is-invalid': invalid })}
                                    />
                                    <span className="fa fa-check" />
                                    &nbsp;{option.label}
                                </label>
                            </div>
                            <div className='mx-1 profession-actions-container'>
                                {item.notes && item.notes.length > 0 && (
                                    <i className='fa fa-comments mx-2 pointer text-dark'
                                        style={{ fontSize: '1.3rem' }}
                                        onClick={() => openNotes(item.notes, option.label)} title='Notes'/>
                                )}
                                {item.state === PROFESSION_PENDING && (
                                    <div className="d-flex flex-row align-items-center">
                                        <span className="badge badge-outline-success pointer mr-1" onClick={() => onApprove(option.value)} title='Approve'>Approve</span>
                                        <span className="badge badge-outline-danger pointer" onClick={() => onReject(option.value)} title='Rejeact'>Reject</span>
                                    </div>
                                )}
                                {item.state === PROFESSION_APPROVED && (
                                    <div className="d-flex flex-row align-items-center">
                                        {item.edit_from === PROFESSION_EDIT_FROM_SIGN_UP ? null : (
                                            <span className="badge badge-outline-success mr-1">
                                                <i className='fa fa-check-circle-o mr-1' />
                                                {item.edit_from === PROFESSION_EDIT_FROM_ADMIN ? "Added by Admin" : "Approved"}
                                            </span>
                                        )}
                                    </div>
                                )}
                                {item.state === PROFESSION_REJECTED && (
                                    <div className="d-flex flex-row align-items-center">
                                        <span className="badge badge-outline-danger pointer" onClick={() => onRemoveReject(option.value)} title='Remove Rejection'>
                                            <i className='fa fa-times-circle-o mr-1' />
                                            Rejected
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const NotesModal = (props) => {
    //  State
    const {notes, profession, open } = props;
    //  Bind
    const { onClose } = props;

    const REJECTED = 100
    const REMOVE_REJECTION = 200

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-5 col-11", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-1 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            {profession} Notes
                        </div>
                    </div>
                </div>
                <div className="row overflow-auto" style={{maxHeight: '70vh'}}>
                    {notes && notes.length > 0 ? (
                        notes.map((item, index) => {
                            return (
                                <div className="col-12" key={index}>
                                    <div className="custom-card py-1">
                                        <div className='d-flex flex-row align-items-center justify-content-between'>
                                            <span className="badge badge-light">
                                                {item.type === REJECTED ? 'Rejected' : 'Remove Rejection'}
                                            </span>
                                            <span className="badge badge-light">
                                                {moment(item.created).format('lll')}
                                            </span>
                                        </div>
                                        <div className="px-2 d-flex flex-row align-items-center mt-1 mb-2">
                                            {item.note}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className="col-12">
                            <div className="d-flex flex-row align-items-center justify-content-center">
                                <span className="text-danger font-weight-bold">No notes found</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}
