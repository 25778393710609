import {handleActions} from 'redux-actions';
import {api} from "api";
import {createReducer} from "../baseReducer/baseReducer";

const endpoint = "user"
export const baseReducer = createReducer(
    "admin",
    endpoint,
    "adminForm",
    "manager",
);

const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["admin"];
    const {AdminFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (AdminFilterForm && AdminFilterForm.values) {
        const {search} = AdminFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }

    dispatch(baseReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(baseReducer.actions.setData(response));
        dispatch(baseReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

export const actions = {
    ...baseReducer.actions,
    toList,
    searchChange,
}


export default handleActions(baseReducer.reducers, baseReducer.initialState);
