import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/franchises';
import CreateFranchise from "./CreateFranchise";

const ms2p = (state) => {
    return{
        ...state.franchises,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateFranchise);
