import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import {SelectField, renderDayPicker, MultiSelectField} from "../Utils/renderField";
import {reduxForm, formValueSelector, Field} from "redux-form";
import {
    STATE_USA_LIST,
    DASHBOARD_TIME,
} from "../../../utility/constants";

let DashboardFiltersForm = (props) => {
    //  State
    const {
        optionFranchises=[],
        optionBrokers=[],
        optionBrokerages=[],
        optionCategories=[],
        timeValue,
        start_dateValue,
        end_dateValue,
    } = props;

    //  Bind
    const {changeFilters} = props;
    return (
        <div className="row px-1">
            <div className="col-12 mr-2 py-2">
                <div className="row w-100 mx-auto">
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="state">State</label>
                            <Field
                                name="state"
                                options={STATE_USA_LIST}
                                set_value={(value) => {
                                    changeFilters(
                                        "state",
                                        value,
                                    )

                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="franchise">Franchise</label>
                            <Field
                                name="franchise"
                                options={optionFranchises}
                                set_value={(value) => {
                                    changeFilters(
                                        "franchise",
                                        value,
                                    )
                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="broker">Broker</label>
                            <Field
                                name="broker"
                                options={optionBrokers}
                                set_value={(value) => {
                                    changeFilters(
                                        "broker",
                                        value
                                    )

                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="brokerage">Office</label>
                            <Field
                                name="brokerage"
                                options={optionBrokerages}
                                set_value={(value) => {
                                    changeFilters(
                                        "brokerage",
                                        value
                                    )

                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="category">Category</label>
                            <Field
                                name="category"
                                options={optionCategories}
                                set_value={(value) => {
                                    changeFilters(
                                        "category",
                                        value
                                    )

                                }}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-12">
                        <div className="form-group has-feedback w-100">
                            <label htmlFor="time">Time</label>
                            <Field
                                name="time"
                                isMulti={false}
                                options={DASHBOARD_TIME}
                                set_value={(option) => {
                                    changeFilters(
                                        "time",
                                        option ? option.value : ""
                                    )
                                }}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                    </div>
                    {timeValue == 'custom' && (
                        <React.Fragment>
                            <div className="col-lg-2 col-12">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="start_date">Start Date</label>
                                    <Field
                                        name="start_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        maxDate={end_dateValue ? moment(end_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            changeFilters(
                                                "start_date",
                                                ""
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="end_date">End Date</label>
                                    <Field
                                        name="end_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        minDate={start_dateValue ? moment(start_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            changeFilters(
                                                "end_date",
                                                ""
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};


const selector = formValueSelector('DashboardForm');

DashboardFiltersForm = reduxForm({
    form: 'DashboardForm', // a unique identifier for this form
})(DashboardFiltersForm);

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    // const brokerageSelect = selector(state, 'officeFilterPerformance');
    // const ratingSelected = selector(state, 'ratingFilterPerformance');
    // filter to chart revenue
    // const timeFilterRevenue = selector(state, 'timeFilterRevenue');
    // const office1 = selector(state, 'officeFilterRevenue');
    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    };
};

export default connect(mstp, null)(DashboardFiltersForm);