import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import {
    renderField,
    renderDayPicker,
    renderNumber,
} from "../../Utils/renderField/renderField";


const validate = (values, props) => {
    let errors = {};
    const {is_update, item} = props;

    if (!values.date) {
        errors.date = "This field is required";
    }
    if (!values.time) {
        errors.time = "This field is required";
    }else {
        const timePattern = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/
        if (!values.time.match(timePattern)) {
            errors.time = "Invalid format, Ex: 13:00";
        }
    }

    if (values.send_to && values.send_to.length) {
        let sendErrors = [];
        values.send_to.forEach((item, indexB) => {
            sendErrors[indexB] = {};
            // if (!item.email) {
            //     sendErrors[indexB] = {
            //         ...sendErrors[indexB],
            //         email: "This field is required",
            //     };
            // }else {
            //     const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            //     if (!item.email.match(emailPattern)) {
            //         sendErrors[indexB] = {
            //             ...sendErrors[indexB],
            //             email: "Invalid email format",
            //         };
            //     }
            // }
            if (!item.mobile) {
                sendErrors[indexB] = {
                    ...sendErrors[indexB],
                    mobile: "This field is required",
                };
            } else {
                if (item.mobile.length < 10) {
                    sendErrors[indexB] = {
                        ...sendErrors[indexB],
                        mobile: "Invalid number",
                    };
                }
            }

            if (!sendErrors[indexB]) {
                sendErrors[indexB] = undefined;
            }
        })
        errors.send_to = sendErrors;
    } else {
        errors.send_to = { _error: "At least one contact is required" };
    }

    return errors;
};

const sendToList = ({
    fields,
    meta: { error, submitFailed },
}) => {
    const isInvalid = error && submitFailed;

    return (
        <React.Fragment>
            {fields.map((item, index) => {
                return (
                    <div
                        key={index}
                        className="form-group has-feedback col-12 mt-auto py-2 border"
                    >
                        <div className="row">
                            <div className="form-group has-feedback col-lg-2 col-md-2 px-0 d-flex flex-row align-items-center justify-content-center">
                                <img
                                    src={require("../../../../../assets/img/delete.svg")}
                                    alt="del"
                                    title="Remove"
                                    width={20}
                                    onClick={() => fields.remove(index)}
                                    className="ml-1 pointer"
                                />
                            </div>
                            {/* <div className="col-lg-6 col-sm-12 form-group has-feedback">
                                <label htmlFor={`${item}.email`}>Email</label>
                                <Field
                                     name={`${item}.email`}
                                    component={renderField}
                                    type="email"
                                />
                            </div> */}
                            <div className="form-group has-feedback col-lg-10 col-md-10">
                                <label htmlFor={`${item}.mobile`}>Mobile number</label>
                                <Field
                                    name={`${item}.mobile`}
                                    component={renderNumber}
                                    className="form-control"
                                    placeholder="mobile number"
                                    numberFormat={"###-###-####"}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}

            <div className="col-12">
                <button
                    className="btn btn-success"
                    type="button"
                    onClick={() =>
                        fields.push({})
                    }
                >
                    Add
                </button>
            </div>
            {isInvalid && (
                <div className="invalid-feedback d-block col-12">{error}</div>
            )}
        </React.Fragment>
    )
}

const Form = (props) => {
    //  State
    const { handleSubmit } = props;
    //  Bind
    const { goBack } = props;

    return (
        <form
            className="w-100 mx-auto justify-content-center"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto mt-3 w-100">
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="date">Date</label>
                    <Field
                        name="date"
                        // initialDate={moment()
                        //     .format("YYYY-MM-DD")
                        //     .toString()}
                        component={renderDayPicker}
                        outputFormatt={"YYYY-MM-DD"}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="time">Time</label>
                    <Field
                        name="time"
                        placeholder="HH:MM"
                        component={renderField}
                        type="time"
                    />
                </div>
                <div className="form-group has-feedback col-lg-12    col-sm-12">
                    <label htmlFor="send_to">Send To</label>
                    <div className="row">
                        <FieldArray
                            name="send_to"
                            component={sendToList}
                        />
                    </div>
                </div>
            </div>
            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <button
                    onClick={goBack}
                    type="button"
                    className="btn text-white btn-secondary  mx-3 col-2"
                >
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary mx-3 col-2">
                    Save
                </button>
            </div>
        </form>
    );
};

const TrackerReminderFrom = reduxForm({
    form: "trackerReminderFrom", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector('trackerReminderFrom');

const mstp = state => {
    return {
    }
}

export default connect(mstp, null)(TrackerReminderFrom);
