import {api} from "api";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const SET_TYPES_EMAILS = "SET_TYPES_EMAILS_SENDGRID_CUSTOM_CAMPAIGNS";

const endpoint = "sendgrid_custom_campaign";

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "sendgridCustomCampaigns",
    endpoint,
    "sendgridCustomCampaignFrom",
    "/sendgrid-custom-campaigns",
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setTypesEmails = (typesEmails) => ({
    type: SET_TYPES_EMAILS,
    typesEmails,
});

// ------------------------------------
// Actions
// ------------------------------------

const getTypesEmails = () => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`sendgrid_campaign/option_email`, {})
        .then(response => {
            let option = [];
            option = response.results;
            dispatch(setTypesEmails(option));
        })
        .catch(() => {
            dispatch(setTypesEmails([]))
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const createCampaign = (data) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.post(endpoint, data)
        .then((response) => {
            if (response) {
                NotificationManager.success('Campaign successfully registered', 'Success', 3000);
                dispatch(push("/sendgrid-campaigns"));
            }
        })
        .catch((error) => {
            let message = error ? error.detail : 'It was not possible to create the campaign, try again later'
            NotificationManager.error(message, 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const updateCampaign = (id, data) => (dispatch, getStore) => {
    const source = getStore()['sendgridCustomCampaigns'];
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        NotificationManager.success('Campaign successfully updated', 'Success', 3000);
        dispatch(push('/sendgrid-campaigns'));
    }).catch((error) => {
        let message = error ? error.detail : 'It was not possible to update the campaign, try again later'
        NotificationManager.error(message, 'ERROR', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["sendgridCustomCampaigns"];
    const {SendgridCampaignFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (SendgridCampaignFilterForm && SendgridCampaignFilterForm.values) {
        const {search} = SendgridCampaignFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }

    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

export const actions = {
    ...basicReducer.actions,
    getTypesEmails,
    createCampaign,
    updateCampaign,
    toList,
    searchChange
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    
    [SET_TYPES_EMAILS]: (state, {typesEmails}) => {
        return {
            ...state,
            typesEmails,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    typesEmails: [],
    ...basicReducer.initialState,
};

export default handleActions(reducers, initialState);