import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import {change, initialize} from "redux-form";
import {push} from "react-router-redux";
import Swal from 'sweetalert2';
import {setIBrokerSelect} from "../brokerage/brokerage"
import {initialize as initializeForm} from "redux-form";
import {LOCKED_BROKER, SELECT_ALL_OPTION} from "../../../utility/constants";

// ------------------------------------
// BASE ACTION
// ------------------------------------
const baseReducer = createReducer(
    "broker",
    "broker",
    "brokerForm",
    "/brokers",
);

// ------------------------------------
// CONSTANTS REDUX
// ------------------------------------

const SET_OPTION_BROKERAGE = 'SET_OPTION_BROKERAGE';
const SET_OPTION_STATES = 'SET_OPTION_STATES_BROKER';
const SET_OPTION_CITIES = 'SET_OPTION_CITIES_BROKER';
const SET_SELECT_FRANCHISES = 'SET_SELECT_ALL_FRANCHISES';
const SET_SELECT_BROKERAGES = 'SET_SELECT_BROKERAGE_BROKER';
const SET_SELECT_PERMISSIONS = 'SET_SELECT_PERMISSIONS';
const SET_URL_EXCEL_TEMPLATE = 'SET_URL_EXCEL_TEMPLATE__BROKER';
const SET_FILES = 'SET_FILES__BROKER';
const SET_EXCEL_DATA = 'SET_EXCEL_DATA__BROKER';
const PAYMENT_PAGE = "SET_PAYMENT_PAGE_BROKER";
const PAYMENT_DATA = "SET_PAYMENT_DATA_BROKER";
const PAYMENT_LOADER = "SET_PAYMENT_LOADER_BROKER";
const OPTION_INFLUENCER = "SET_OPTION_INFLUENCER_BROKER";

// ------------------------------------
// Pure Actions
// ------------------------------------

const setOptionsStates = optionStates => ({
    type: SET_OPTION_STATES,
    optionStates,
});
const setOptionsCities = optionCities => ({
    type: SET_OPTION_CITIES,
    optionCities,
});

const setOptionsFranchises = optionFranchises => ({
    type: SET_SELECT_FRANCHISES,
    optionFranchises,
});


const setOptions = option => ({
    type: SET_OPTION_BROKERAGE,
    option,
});
const setBrokerages = brokerages => ({
    type: SET_SELECT_BROKERAGES,
    brokerages,
});
const setUrlExcelTemplate = urlTemplate => ({
    type: SET_URL_EXCEL_TEMPLATE,
    urlTemplate,
});

const setFiles = (files) => ({
    type: SET_FILES,
    files,
});

const setExcel = excel => ({
    type: SET_EXCEL_DATA,
    excel,
});

const setPaymentLoader = paymentLoader => ({
    type: PAYMENT_LOADER,
    paymentLoader,
});
const setPaymentPage = paymentPage => ({
    type: PAYMENT_PAGE,
    paymentPage,
});

const setPaymentData = paymentData => ({
    type: PAYMENT_DATA,
    paymentData,
});
const setOptionsInfluencer = influencers => ({
    type: OPTION_INFLUENCER,
    influencers,
});

// ------------------------------------
// Actions
// ------------------------------------

const getBrokerages = () => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get('brokerage/option_select')
        .then(response => {
            dispatch(setOptions(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toCustomList());
    }, 400);
};

const toCustomList = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()['broker'];
    const {listGridBroker} = getStore().form;
    const params = {page};
    if (listGridBroker && listGridBroker.values) {
        const { state, city, status_value, franchise_id, brokerage, search, influencer_id} = listGridBroker.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]){
            // params.state__in = state
            if (state.length == 0) {
                params.state__in = "null"
            }else {
                params.state__in = state;
            }
        }
        if (city !== "" && city !== null && city !== undefined && city !== SELECT_ALL_OPTION["value"]){
            // params.city__in = city
            if (city.length == 0) {
                params.city__in = "null"
            }else {
                params.city__in = city;
            }
        }
        if (status_value !== "" && status_value !== null && status_value !== undefined && status_value !== SELECT_ALL_OPTION["value"]){
            // params.status__in = status_value
            if (status_value.length == 0) {
                params.status__in = 0
            }else {
                params.status__in = status_value;
            }
        }
        if (franchise_id !== "" && franchise_id !== null && franchise_id !== undefined && franchise_id !== SELECT_ALL_OPTION["value"]){
            // params.franchise_id__in = franchise_id
            if (franchise_id.length == 0) {
                params.franchise__id__in = 0
            }else {
                params.franchise__id__in = franchise_id;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]){
            // params.brokerage__id__in = brokerage
            if (brokerage.length == 0) {
                params.brokerage__id__in = 0
            }else {
                params.brokerage__id__in = brokerage;
            }
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
        if (influencer_id !== "" && influencer_id !== null && influencer_id !== undefined && influencer_id !== SELECT_ALL_OPTION["value"]) {
            if (influencer_id.length == 0) {
                params.influencer__id__in = 0
            }else {
                params.influencer__id__in = influencer_id;
            }
        }
    }
    params.ordering = resource.ordering;
    // params.search = resource.search;
    dispatch(baseReducer.actions.setLoader(true));
    api.get('broker', params).then((response) => {
        dispatch(baseReducer.actions.setData(response));
        dispatch(baseReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const getInvites = () => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get('broker-user/invites')
        .then(response => {
            // dispatch(setOptions(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const customRead = id => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get(`broker/${id}`, )
        .then((response) => {
            response.franchise = response.franchise? response.franchise.id : null
            dispatch(initializeForm('brokerForm', response));
            dispatch(baseReducer.actions.setItem(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const invites = (data) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    const email = data;
    const permissions = getStore().broker.permissions;
    if (Object.entries(permissions).length === 0) {
        NotificationManager.error('None permission selected, please try again', 'ERROR', 1000);
        dispatch(baseReducer.actions.setLoader(false));
    } else {
        const data = {
            email: email.email,
            permissions,
        };
        api.post('broker-user/send_invites', data)
            .then(() => {
                dispatch(push('/brokers'));
                NotificationManager.success('Invitation sent', 'Success', 1000);
                // const option = { "options": { 'options': [] } };
            })
            .catch(() => {
                NotificationManager.error('Transaction error', 'Error', 1000);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }
};

const setPermissions = (permissions) => (dispatch) => {
    dispatch({
        type: SET_SELECT_PERMISSIONS,
        permissions,
    });
};

const validateBroker = (data) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.post('broker-user/validate_broker', data)
        .then(() => {
            dispatch(push('/brokers'));
            NotificationManager.success('Broker validated', 'Success', 1000);
        })
        .catch((error) => {
            if (error && error.details) {
                NotificationManager.error(error.details, 'Error', 1000);
            }
            NotificationManager.error('Error to validate broker', 'Error', 1000);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
}

const denyBroker = (data) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.post('broker-user/deny_broker', data)
        .then(() => {
            dispatch(push('/brokers'));
            NotificationManager.success('Broker denied', 'Success', 1000);
        })
        .catch(() => {
            NotificationManager.error('Transaction error', 'Error', 1000);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
}

const getStatesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { listGridBroker } = store.form;
    let stateValue = null;
    if (listGridBroker && listGridBroker.values) {
        const {state} = listGridBroker.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                stateValue = SELECT_ALL_OPTION["value"]
            }else {
                stateValue = state
            }
        }
    }
    api.get("general/option_states", {category: "BROKER"})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && stateValue === null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "state",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (stateValue !== null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "state",
                            stateValue
                        )
                    );
                }
            } else {
                dispatch(
                    change(
                        "listGridBroker",
                        "state",
                        SELECT_ALL_OPTION["value"],
                    )
                );
                setTimeout(() => {
                    dispatch(getCitiesOption())
                }, 500);
            }
            dispatch(setOptionsStates(options));
        })
        .catch(() => {
            dispatch(setOptionsStates([]));
            dispatch(change("listGridBroker", "state", SELECT_ALL_OPTION["value"]));
            setTimeout(() => {
                dispatch(getCitiesOption())
            }, 500);
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toCustomList())
                }, 500)
            }
        });
};

const getCitiesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { listGridBroker } = store.form;
    let citiesValue = null;
    let stateValue = ''
    if (listGridBroker && listGridBroker.values) {
        const {city, state} = listGridBroker.values;
        if (city !== "" && city !== null && city !== undefined && city !== SELECT_ALL_OPTION["value"]) {
            if (city.length == 0) {
                citiesValue = SELECT_ALL_OPTION["value"]
            }else {
                citiesValue = city
            }
        }

        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                stateValue = ''
            }else {
                stateValue = state.length == 1 ? state[0] : ''
            }
        }
    }
    api.get("general/option_cities", {state: stateValue, category: "BROKER"})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && citiesValue === null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "city",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (citiesValue !== null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "city",
                            citiesValue
                        )
                    );
                }
            } else {
                dispatch(
                    change(
                        "listGridBroker",
                        "city",
                        SELECT_ALL_OPTION["value"],
                    )
                );
            }
            dispatch(setOptionsCities(options));
        })
        .catch(() => {
            dispatch(setOptionsCities([]));
            dispatch(change("listGridBroker", "city", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toCustomList())
                }, 500)
            }
        });
};


const getAllFranchises = (withAll=false) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get('general/option_franchise', {})
        .then(response => {
            let options = []
            if (withAll){
                options = [{label: 'All', value: ''}]
            }
            options = [...response.results]
            // response.results.map((franchise) => {
            //     options.push({label: franchise.name, value: franchise.id})
            // })
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

export const getFranchisesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { listGridBroker } = store.form;
    let franchiseValue = null;
    if (listGridBroker && listGridBroker.values) {
        const {franchise_id} = listGridBroker.values;
        if (franchise_id !== "" && franchise_id !== null && franchise_id !== undefined && franchise_id !== SELECT_ALL_OPTION["value"]) {
            if (franchise_id.length == 0) {
                franchiseValue = SELECT_ALL_OPTION["value"]
            }else {
                franchiseValue = franchise_id
            }
        }
    }
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                // let value = [];
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                //     value.push(franchise.id)
                // });
                if(selectAll && franchiseValue === null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "franchise_id",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
                if (franchiseValue !== null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "franchise_id",
                            franchiseValue
                        )
                    );
                }
            } else {
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                // });
                const franchise_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "listGridBroker",
                        "franchise_id",
                        [franchise_id]
                    )
                );
                setTimeout(() => {
                    dispatch(getAllOffice())
                }, 500);
            }
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
            dispatch(change("listGridBroker", "franchise_id", ""));
            setTimeout(() => {
                dispatch(getAllOffice())
            }, 500);
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toCustomList())
                }, 600);
            }
        });
};

const getAllOffice = (getData=false, selectAll=false) => (dispatch, getStore) => {
    const store = getStore()
    const form = store.form.listGridBroker;
    let params = { broker_id: "", state: "", franchise: "", is_multi: true };
    let brokerageValue = null;
    if (form) {
        const { franchise_id, state, brokerage } = form.values;
        // if (state != '' && state !== null && state !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
        //     params.state = state
        // }
        if (franchise_id != '' && franchise_id !== null && franchise_id !== undefined && franchise_id !== SELECT_ALL_OPTION["value"]) {
            // params.franchise = franchise_id
            if (franchise_id.length == 0) {
                params.franchise = ""
            }else {
                params.franchise = franchise_id;
            }
        }

        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            if (brokerage.length == 0) {
                brokerageValue = SELECT_ALL_OPTION["value"]
            }else {
                brokerageValue = brokerage
            }
        }
    }

    api.get('general/option_brokerage', params)
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                if (selectAll && brokerageValue == null) {
                    // let value = [];
                    // response.results.forEach((item) => {
                    //     value.push(item.value);
                    // });
                    dispatch(change("listGridBroker", "brokerage", SELECT_ALL_OPTION["value"]));
                }
                if(brokerageValue !== null) {
                    dispatch(change("listGridBroker", "brokerage", brokerageValue));
                }
            } else {
                option = [...response.results];
                // const brokerage_id = option.length ? option[0].value : "";
                dispatch(
                    change(
                        "listGridBroker",
                        "brokerage",
                        SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setBrokerages(option));
        })
        .catch((err) => {
            console.log("ERR: ", err);
            dispatch(setBrokerages([]));
            dispatch(change("listGridBroker", "brokerage", ""));
        })
        .finally(() => {
            if(getData) {
                setTimeout(() => {
                    dispatch(toCustomList())
                }, 600);
            }
        });
};

const changeFilters = (type, value=null) => (dispatch) => {
    console.log("TYPE:, ",  type);
    console.log("VALUE:, ",  value);
    switch (type) {
        case 'state':
            // setTimeout(() => {
            //     dispatch(getAllOffice(true))
            // }, 500);
            setTimeout(() => {
                dispatch(getCitiesOption(true, true))
            }, 500);
            break;
        case 'city':
            setTimeout(() => {
                dispatch(toCustomList())
            }, 500);
            break;
        case 'franchise':
            setTimeout(() => {
                dispatch(getAllOffice(true))
            }, 500);
            break;
        case 'brokerage':
            setTimeout(() => {
                dispatch(toCustomList())
            }, 500);
            break;
        case 'influencer':
            setTimeout(() => {
                dispatch(toCustomList())
            }, 500);
            break;
        case 'status':
            setTimeout(() => {
                dispatch(toCustomList())
            }, 500);
            break;
    }
}

const remove = id => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.eliminar(`broker/${id}`).then(() => {
        dispatch(toCustomList());
        NotificationManager.success('Record removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const removeBroker = (item) => (dispatch) => {
    const {id} = item;
    console.log("ID: ", id);
    if (item.status == LOCKED_BROKER) {
        dispatch(baseReducer.actions.setLoader(true));
        api.post("broker/send_validation_code").then(reponse => {
            Swal.fire({
                title: 'Are you sure you want to delete this Broker?',
                text: "To confirm please enter the verification code",
                input: 'text',
                // inputLabel: "To confirm please enter the verification code",
                // inputPlaceholder: "To confirm please enter the verification code",
                type: 'warning',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Next',
                confirmButtonColor: '#7E8A97',
                cancelButtonColor: '#B9BDC1',
                showLoaderOnConfirm: true,
                preConfirm: (code) => {
                    return api.post("broker/validate_code", {code}).then(response => {
                        return response
                    }).catch(error => {
                        let msg = "Could not validate the code try again"
                        if (error && error.detail) {
                            msg = error.detail
                        }
                        Swal.showValidationMessage(
                            `${msg}`
                        )
                    })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.value && result.value.detail === "Correct code") {
                    let htmlTemplate = `<p style="font-size: 15px;margin-bottom: 0;">by doing that, all the agents under this Broker will be locked from using the ShowIT Solutions™ platform </p><br/>`
                    htmlTemplate += `<p style="font-size: 17px; font-weight: bold;margin-bottom: 0;">This action can't be reverted!</p>`
    
                    Swal.fire({
                        title: 'Are you sure you want to delete this Broker?',
                        html: htmlTemplate,
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: '#7E8A97',
                        cancelButtonColor: '#B9BDC1',
                        confirmButtonText: 'Yes, delete it!',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            dispatch(remove(id))
                        }
                    });
                    console.log("DELETED");
                }
            })
        }).catch(error => {
            NotificationManager.error('Failed to send verification code, please try again later', 'Error', 1000);
        }).finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        })
    }else {
        let htmlTemplate = `<p style="font-size: 17px;margin-bottom: 0;">Broker status must change to locked prior to deleting the Broker</p>`
        Swal.fire({
            html: htmlTemplate,
            type: "info",
            confirmButtonColor: '#7E8A97',
        })
    }
}
const goToAgents = (broker_id) => (dispatch) => {
    // dispatch(initialize('AgentFiltersForm', {broker: [broker_id]}))
    // dispatch(push(`/agents?broker=${broker_id}`, {broker: broker_id}));
    dispatch(push({pathname: '/agents', search:`?broker=${broker_id}`}))
}

const goToOffices = (broker_id) => (dispatch) => {
    // dispatch(initialize('BrokerageGrid', {broker: [broker_id]}))
    // dispatch(setIBrokerSelect(broker_id))
    // setTimeout(() => {
    //     dispatch(push('/offices'));
    // }, 700);
    dispatch(push({pathname: '/offices', search:`?broker=${broker_id}`}))
}

const goToPaymentsHistory = (broker_id) => (dispatch) => {
    // dispatch(initialize('AgentFiltersForm', {broker: [broker_id]}))
    // dispatch(push(`/agents?broker=${broker_id}`, {broker: broker_id}));
    dispatch(push({pathname: `/brokers/${broker_id}/payments-history`}))
}

const getUrlExelTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrlExcelTemplate(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

const createBrokerageAttachment = (broker_id, data = {}, goBack) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    delete data.logo;
    delete data.offfice_w9;
    const logo = getStore().broker.files;

    const archive = getStore().broker.excel;
    if (logo.length !== 0 || archive.length !== 0) {
        let _attachments = []
        if (logo.length !== 0) {
            _attachments.push({name: 'logo', file: logo})
        }
        if (archive.length !== 0) {
            _attachments.push({name: 'office_w9', file: archive})
        }
        console.log("ARCHIVE: ", archive);
        api.postAttachments('brokerage', {...data, broker_id}, _attachments,
            {})
            .then((response) => {
                dispatch(setFiles([]));
                dispatch(setExcel([]));
                goBack()
                NotificationManager.success('Office created', 'SUCCESS', 3000);
            })
            .catch((error) => {
                console.log("ERROR: ", error);
                if (error && error.detail) {
                    NotificationManager.error(error.detail, 'ERROR', 0);
                } else {
                    NotificationManager.error('Create failed', 'ERROR', 3000);
                }
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }else {
        dispatch(baseReducer.actions.setLoader(false));
    }
    // }

};

const getPaymentsHistory = (id, page=1) => (dispatch, getStore) => {
    dispatch(setPaymentLoader(true));
    api.get(`broker/${id}/show_it_share`, {page})
        .then(response => {
            dispatch(setPaymentData(response));
            dispatch(setPaymentPage(page));
        })
        .catch((error) => {
            console.log('----- getPaymentsHistory error -----', error);
        })
        .finally(() => {
            dispatch(setPaymentLoader(false));
        });
};

const getInfluencers = () => (dispatch) => {
    api.get('general/option_influencer', {})
        .then(response => {
            let options = [{label: 'None', value: null},...response.results]
            dispatch(setOptionsInfluencer(options));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};

export const getInfluencerOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { listGridBroker } = store.form;
    let influencerValue = null;
    if (listGridBroker && listGridBroker.values) {
        const {influencer_id} = listGridBroker.values;
        if (influencer_id !== "" && influencer_id !== null && influencer_id !== undefined && influencer_id !== SELECT_ALL_OPTION["value"]) {
            if (influencer_id.length == 0) {
                influencerValue = SELECT_ALL_OPTION["value"]
            }else {
                influencerValue = influencer_id
            }
        }
    }
    api.get("general/option_influencer", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && influencerValue === null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "influencer_id",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
                if (influencerValue !== null) {
                    dispatch(
                        change(
                            "listGridBroker",
                            "influencer_id",
                            influencerValue
                        )
                    );
                }
            } else {
                // const influencer_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "listGridBroker",
                        "influencer_id",
                        SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setOptionsInfluencer(options));
        })
        .catch(() => {
            dispatch(setOptionsInfluencer([]));
            dispatch(change("listGridBroker", "influencer_id", ""));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toCustomList())
                }, 600);
            }
        });
};

const assignAddressData = (data, form="brokerForm") => (dispatch) => {
    if (data) {
        const {state, city, zip_code} = data
        dispatch(change(form, 'state', state))
        dispatch(change(form, 'city', city))
        dispatch(change(form, 'zipcode', zip_code))
    }
}

export const actions = {
    getBrokerages,
    invites,
    setPermissions,
    getInvites,
    customRead,
    validateBroker,
    denyBroker,
    toCustomList,
    getStatesOption,
    getCitiesOption,
    getAllFranchises,
    getAllOffice,
    changeFilters,
    removeBroker,
    goToAgents,
    goToOffices,
    getFranchisesOption,
    getUrlExelTemplate,
    setFiles,
    setExcel,
    createBrokerageAttachment,
    ...baseReducer.actions,
    searchChange,
    goToPaymentsHistory,
    getPaymentsHistory,
    getInfluencers,
    getInfluencerOption,
    assignAddressData,

};
export const reducers = {
    ...baseReducer.reducers,
    [SET_OPTION_BROKERAGE]: (state, {option}) => {
        return {
            ...state,
            option,
        };
    },
    [SET_OPTION_STATES]: (state, {optionStates}) => {
        return {
            ...state,
            optionStates,
        };
    },
    [SET_OPTION_CITIES]: (state, {optionCities}) => {
        return {
            ...state,
            optionCities,
        };
    },
    [SET_SELECT_PERMISSIONS]: (state, {permissions}) => {
        return {
            ...state,
            permissions,
        };
    },
    [SET_SELECT_FRANCHISES]: (state, {optionFranchises}) => {
        return {
            ...state,
            optionFranchises,
        };
    },
    [SET_SELECT_BROKERAGES]: (state, {brokerages}) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_FILES]: (state, {files}) => {
        return {
            ...state,
            files,
        };
    },
    [SET_EXCEL_DATA]: (state, {excel}) => {
        return {
            ...state,
            excel,
        };
    },
    [SET_URL_EXCEL_TEMPLATE]: (state, {urlTemplate}) => {
        return {
            ...state,
            urlTemplate,
        };
    },
    [PAYMENT_LOADER]: (state, { paymentLoader }) => {
        return {
            ...state,
            paymentLoader,
        };
    },
    [PAYMENT_PAGE]: (state, { paymentPage }) => {
        return {
            ...state,
            paymentPage,
        };
    },
    [PAYMENT_DATA]: (state, { paymentData }) => {
        return {
            ...state,
            paymentData,
        };
    },
    [OPTION_INFLUENCER]: (state, { influencers }) => {
        return {
            ...state,
            influencers,
        };
    },
};
export const initialState = {
    files: [],
    excel: [],
    option: [],
    optionStates: [],
    optionCities: [],
    influencers: [],
    permissions: {},
    optionFranchises: [],
    brokerages: [],
    ...baseReducer.initialState,
    paymentLoader: false,
    paymentPage: 1,
    paymentData: {
        broker: null,
        results: []
    }
};

export default handleActions(reducers, initialState);
