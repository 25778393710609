import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/Tracker/field";
import TrackerFieldList from './TrackerFieldList';


const ms2p = (state) => {
    return {
        ...state.trackerField,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TrackerFieldList);
