import React, {useState, useEffect} from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import Grid from "../../../Utils/GridTable";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import {OFFICES_PAGE_SIZE, STATE_USA_LIST, SELECT_ALL_OPTION, BROKERAGE_STATUSES, BROKERAGE_ACTIVE, BROKERAGE_LOCKED, BROKERAGE_BLOCKED, BROKERAGE_REASON_LOCKED_BY} from "../../../../../utility/constants";

const DeletedOfficeList = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        // tabContainerHeight,
    } = props;

    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("DELETE-OFFICE-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="DELETE-OFFICE-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    hover
                    height={gridHeight}
                    striped
                    page={page}
                    data={data}
                    keyField={"id"}
                    loading={loader}
                    sizePerPage={OFFICES_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    wrapperClasses="table-scroll-auto"
                    columns={[
                        // {
                        //     dataField: "id",
                        //     text: "",
                        //     formatter: standardActions({
                        //         customRemove: remove,
                        //         edit: "offices",
                        //     }),
                        // },
                        {
                            dataField: "logo",
                            formatter: loadImg(),
                            text: "",
                        },
                        {
                            dataField: "status",
                            formatter: formatStatus(),
                            text: "status",
                        },
                        {
                            dataField: "firm_name",
                            sort: true,
                            text: "Office Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        // {
                        //     dataField: "broker_info.name",
                        //     sort: true,
                        //     text: "Broker Name",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "130px",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "broker_info.franchise.name",
                        //     sort: true,
                        //     text: "Franchise",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "130px",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "broker_info.group_name",
                        //     sort: true,
                        //     text: "Group Name",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "130px",
                        //         };
                        //     },
                        // },
                        {
                            dataField: "license",
                            sort: true,
                            text: "Office License Number",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "170px",
                                };
                            },
                        },
                        {
                            dataField: "broker_info.state",
                            formatter: formattedState(),
                            sort: true,
                            text: "State",
                        },
                        {
                            dataField: "city",
                            sort: true,
                            text: "City",
                        },
                        {
                            dataField: "address",
                            sort: true,
                            text: "Address",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "150px",
                                };
                            },
                        },
                        {
                            dataField: "address_line2",
                            sort: true,
                            text: "Address Line 2",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "150px",
                                };
                            },
                        },
                        {
                            dataField: "telephone",
                            formatter: formatPhoneNumber(),
                            text: "Phone Number",
                        },
                        {
                            dataField: "total_registered_agents",
                            text: "# Registered Agents",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                    textAlign: "right",
                                };
                            },
                            style: (colum, colIndex) => {
                                return {
                                    textAlign: "right",
                                };
                            },
                        },
                        {
                            dataField: "total_unregistered_agents",
                            text: "# Unregistered Agents",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                    textAlign: "right",
                                };
                            },
                            style: (colum, colIndex) => {
                                return {
                                    textAlign: "right",
                                };
                            },
                        },
                        // {
                        //     dataField: "agents_stats.total_user",
                        //     formatter: formatAgentTotal(),
                        //     text: "# of Registered Agents",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "150px",
                        //             textAlign: "right",
                        //         };
                        //     },
                        //     style: (colum, colIndex) => {
                        //         return {
                        //             textAlign: "right",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "agents_stats.total_unregistered",
                        //     formatter: formatAgentTotal(),
                        //     text: "# of Unregistered Agents",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "150px",
                        //             textAlign: "right",
                        //         };
                        //     },
                        //     style: (colum, colIndex) => {
                        //         return {
                        //             textAlign: "right",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "agents_stats.total_user_active",
                        //     formatter: formatAgentTotal(),
                        //     text: "# of Active Agents",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "130px",
                        //             textAlign: "right",
                        //         };
                        //     },
                        //     style: (colum, colIndex) => {
                        //         return {
                        //             textAlign: "right",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "total_job_posted",
                        //     text: "# of Job Posted",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "120px",
                        //             textAlign: "right",
                        //         };
                        //     },
                        //     style: (colum, colIndex) => {
                        //         return {
                        //             textAlign: "right",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "total_job_performed",
                        //     text: "# of Job Performed",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "120px",
                        //             textAlign: "right",
                        //         };
                        //     },
                        //     style: (colum, colIndex) => {
                        //         return {
                        //             textAlign: "right",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "total_show_it_share",
                        //     formatter: formatCurrency(),
                        //     text: "Total ShowIT Share",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "130px",
                        //             textAlign: "right",
                        //         };
                        //     },
                        //     style: (colum, colIndex) => {
                        //         return {
                        //             textAlign: "right",
                        //         };
                        //     },
                        // },
                        // {
                        //     dataField: "total_show_it_revenue",
                        //     formatter: formatCurrency(),
                        //     text: "Total ShowIT Revenue",
                        //     headerStyle: (colum, colIndex) => {
                        //         return {
                        //             minWidth: "150px",
                        //             textAlign: "right",
                        //         };
                        //     },
                        //     style: (colum, colIndex) => {
                        //         return {
                        //             textAlign: "right",
                        //         };
                        //     },
                        // },
                        {
                            dataField: "previous_brokers",
                            sort: false,
                            formatter: formatPreviousBrokers(),
                            text: "Previous Broker Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "150px",
                                };
                            },
                        },
                        {
                            dataField: "previous_franchise",
                            sort: false,
                            formatter: formatPreviousFranchise(),
                            text: "Previous Franchise",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "110px",
                                };
                            },
                        },
                        {
                            dataField: "updated",
                            sort: false,
                            formatter: formatDateOfDeletion(),
                            text: "Date of deletion",
                        },
                        {
                            dataField: 'reason_locked',
                            formatter: setReason(),
                            text: 'Reason'
                        },
                        {
                            dataField: "id",
                            formatter: formatReinstate(),
                            text: "",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export function loadImg() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <div style={{width: cell ? "28px" : "30px", height: cell ? "22px" : "25px"}} className="p-0 m-0">
                    <img
                        src={cell ? cell : require('../../../../../../assets/img/uploadLogo.PNG')}
                        alt='logo'
                        className="align-self-center w-75 justify-content-center"
                    />
                </div>
            </React.Fragment>
        )
    };
}

export function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}


export function loadOffice() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <div>
                    <a
                        href={cell}
                    >
                        {cell && cell.substring(34, cell.length - 5)}
                    </a>
                </div>
            </React.Fragment>
        )
    };
}

export function formattedState() {
    return (cell, row) => {
        const getState = () => {
            let text = ''
            STATE_USA_LIST.map(option => {
                if (option.value === cell) {
                    text = option.label
                }
            })
            return text;
        }
        return (
            <p className={`my-0 text-capitalize`}>
                {getState()}
            </p>
        )
    };
}

const formatAgentTotal = () => (cell, row) => {
    return(
        <p className="my-0">{cell}</p>
    )
}

const formatCurrency = () => (cell, row) => {
    return (<div>
        $ {cell ? cell.toFixed(2) : "0.00"}
    </div>)
}

export function formatStatus() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            BROKERAGE_STATUSES.map(item => {
                if (cell === item.value) {
                    text = item.label
                }
            })
            return text
        }

        const getColor = () => {
            let colorText = 'black'
            if (cell === BROKERAGE_ACTIVE) {
                colorText = 'success'
            } else if (cell === BROKERAGE_LOCKED) {
                colorText = 'danger'
            }
            else if (cell === BROKERAGE_BLOCKED) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <p
                    className={`my-0 text-capitalize font-weight-bold text-${getColor()}`}
                    style={{
                        font: 'normal normal medium 13px/22px Poppins',
                        letterSpacing: '0px',
                        opacity: '1'
                    }}
                >
                    {getStatus()}
                </p>
            </React.Fragment>
        )
    };
}

function formatPreviousBrokers() {
    return (cell, row) => {
        let result = "";

        return (
            <div>
                {row.previous_brokers && row.previous_brokers.map((item, index) => {
                    if (index == 0) {
                        return (
                            <React.Fragment>
                                {item.is_active ? (
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`brokers/broker/${item.id}/edit`}
                                        className="w-100 text-primary"
                                    >
                                        {item.broker}
                                    </Link>
                                ): (
                                    <span>
                                        {item.broker}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment>
                            <span>, </span>
                            {item.is_active ? (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`brokers/broker/${item.id}/edit`}
                                    className="w-100 text-primary"
                                >
                                    {item.broker}
                                </Link>
                            ) : (
                                <span>{item.broker}</span>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        )
    };
}

function formatPreviousFranchise() {
    return (cell, row) => {
        let result = "";

        if (row.previous_brokers && row.previous_brokers.length) {
            row.previous_brokers.forEach((item, index) => {
                if (index == 0) {
                    result += `${item.franchise}`
                }else {
                    result += `, ${item.franchise}`
                }
            })
        }

        return (
            <span>{result}</span>
        )
    };
}

function formatDateOfDeletion() {
    return (cell, row) => {

        return (
            <span>{moment(cell).format('ll')}</span>
        )
    };
}

function setReason() {
    return (cell, row) => {
        let reason = ''
        if (cell) {
            BROKERAGE_REASON_LOCKED_BY.forEach((item) => {
                if (item.value === cell) (reason = item.label)
            })
        }

        return (
            <div className="d-flex flex-row align-items-center justify-content-between" style={{width: '150px', overflow: 'hidden'}}>
                <span className="text-danger font-weight-bold">
                    {reason}
                </span>
            </div>
        );
    };
}

function formatReinstate() {
    return (cell, row) => {
        return (
            <div className="w-100">
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`deleted-offices/${cell}/reinstate`}
                    className="w-100 btn btn-danger text-white"
                >
                    Reinstate
                </Link>
            </div>
        );
    }
}

export default DeletedOfficeList;
