import { connect } from "react-redux";
import {Link} from "react-router-dom";
import {api} from "../../../../../utility/api";
import { formValueSelector } from "redux-form";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import FileUploader from "../../../Utils/FileUploader/FileUploader";
import {
    renderField,
    ColorPicker,
    renderNumber,
    renderTextArea,
    renderSwitch,
    renderFilePicker,
    renderCurrency,
    renderFieldCheck,
    AsyncSelectField,
    renderFileImageCrop
} from "../../../Utils/renderField/renderField";

const validate = (values, props) => {
    const errors = {}

    if (!values.title) {
        errors.title = "This field is required";
    }
    if (!values.sub_title) {
        errors.sub_title = "This field is required";
    }
    if (!values.name) {
        errors.name = "This field is required";
    }
    if (!values.primary_color) {
        errors.primary_color = 'This field is required';
    }
    if (!values.icon) {
        errors.icon = 'This field is required';
    }
    if (!values.image) {
        errors.image = 'This field is required';
    }
    if (!values.broker_fee) {
        errors.broker_fee = 'This field is required';
    }
    if (!values.influencer_fee) {
        errors.influencer_fee = 'This field is required';
    }

    if (values.prices && values.prices.length > 0) {
        const priceErrors = [];
        values.prices.forEach((price, index) => {
            priceErrors[index] = {};
            if (!price || !price.quote_number) {
                priceErrors[index] = {quote_number: 'This field is required'};
            }
            if (!price || !price.price) {
                priceErrors[index] = {...priceErrors[index], price: 'This field is required'};
            }
        })
        errors.prices = priceErrors;
    } else {
        errors.prices = 'This field is required';
    }

    return errors
}

const renderPrices = (props) => {
    const {fields, meta: {error}} = props
    // console.log('ERROR PRICE: ', props);
    const invalid =  error;
    return (
        <div className="border py-3">
            <div className="w-100 px-2">
                {/* <div className="row w-100 mx-1 header-services">
                    <div className={"col-1"}><label className={"text-white"}> </label></div>
                    <div className={"col-8"}><label> # Vendor </label></div>
                    <div className={"col-3"}><label> Fee</label></div>
                </div> */}
                {fields.map((row, indexS) => {
                    const itemValues = fields.get(indexS)
                    return (
                        <div
                            key={`prices-${indexS}-ss`}
                            className={`row w-100 mx-1 py-2 ${fields.length == indexS + 1 ? 'mb-3' : 'mb-1'}`}
                            style={{backgroundColor: (indexS % 2 !== 0) ? '#E6E6E5' : 'white'}}>
                            <div className="col-12 col-md-1 justify-content-center">
                                <img
                                    src={require("../../../../../../assets/img/delete.svg")}
                                    alt="del"
                                    style={{width: "1.5rem"}}
                                    onClick={() => {
                                        fields.remove(indexS);
                                    }}
                                    className="pointer align-self-center ml-3 mt-1"
                                />
                            </div>
                            <div className="col-12 col-md-8">
                                <Field
                                    name={`${row}.quote_number`}
                                    id="quote_number"
                                    placeholder=""
                                    allowNegative={false}
                                    // prefix="# "
                                    suffix={itemValues && itemValues.quote_number > 1 ? " Vendors" : " Vendor"}
                                    decimalScale={0}
                                    component={renderNumber}
                                    type="text"
                                    disabled
                                    className="form-control"
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <Field
                                    name={`${row}.price`}
                                    component={renderCurrency}
                                    placeholder="Fee"
                                    customClass="form-control w-100"
                                />
                            </div>
                        </div>
                    )
                })
                }
            </div>
            <div className="d-flex justify-content-center">
                <button
                    type="button"
                    className="btn button-01 py-0"
                    onClick={() => fields.push({
                        quote_number:
                            fields.length > 0 ?
                                (parseInt(fields.get(fields.length - 1).quote_number) + parseInt('1'))
                                : parseInt('1')
                    })}
                >
                    Add
                </button>
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
}

const Form = (props) => {
    //  State
    const {icon, map_icon, image, enableClientFeedback, icon_colorValue, map_icon_colorValue} = props
    //  Bind
    const { handleSubmit, setMainIcon, setMainImage, setMainMapIcon } = props;

    const loadClientForms = (search, callback) => {
        api.get("vendor-client-feedback-form", {search}).then((response) => {
            const options = [];
            response.results.forEach((result) => {
                options.push({
                    label: result.name,
                    value: result.id,
                });
            });
            callback(options);
        }).catch(() => {
        });
    };

    return (
        <form
            action=""
            onSubmit={handleSubmit}
            className="row mx-auto justify-content-center mt-5"
            style={{ width: "80%" }}
        >
            <div className="row w-100">
                <div className="form-group has-feedback col-md-6 col-12 mt-auto">
                    <label htmlFor="title">Title</label>
                    <Field
                        name="title"
                        id="title"
                        placeholder="Title"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12 mt-auto">
                    <label htmlFor="sub_title">Sub Title</label>
                    <Field
                        name="sub_title"
                        id="sub_title"
                        placeholder="Sub Title"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12 mt-auto">
                    <label htmlFor="name">Name</label>
                    <Field
                        name="name"
                        id="name"
                        placeholder="Name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12 mt-auto">
                    <label htmlFor="primary_color">Name Color</label>
                    <Field
                        name="primary_color"
                        id="primary_color"
                        placeholder="Name Color"
                        component={ColorPicker}
                        type="text"
                        className="form-control"
                        />
                </div>
                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="icon">Icon</label>
                    <Field
                        name="icon"
                        id="icon"
                        photo={icon}
                        setFile={setMainIcon}
                        spectValue={1}
                        component={renderFileImageCrop}
                        customImgStyle={{filter: icon_colorValue ? `opacity(0.5) drop-shadow(0 0 0 ${icon_colorValue})` : 'none'}}
                        // className="form-control"
                    />
                    <label htmlFor="icon_color">Icon Color</label>
                    <Field
                        name="icon_color"
                        id="icon_color"
                        placeholder="Icon Color"
                        component={ColorPicker}
                        type="text"
                        className="form-control"
                        />
                </div>

                <div className="form-group has-feedback col-md-6 col-12">
                    <label htmlFor="map_icon">Map Icon</label>
                    <Field
                        name="map_icon"
                        id="map_icon"
                        photo={map_icon}
                        setFile={setMainMapIcon}
                        spectValue={1}
                        component={renderFileImageCrop}
                        customImgStyle={{filter: map_icon_colorValue ? `opacity(0.5) drop-shadow(0 0 0 ${map_icon_colorValue})` : 'none'}}
                        // className="form-control"
                    />
                    <label htmlFor="map_icon_color">Map Icon Color</label>
                    <Field
                        name="map_icon_color"
                        id="map_icon_color"
                        placeholder="Map Icon Color"
                        component={ColorPicker}
                        type="text"
                        className="form-control"
                        />
                </div>

                <div className="form-group has-feedback col-md-8 col-12">
                    <label htmlFor="image">Image</label>
                    <Field
                        name="image"
                        id="image"
                        photo={image}
                        setFile={setMainImage}
                        // component={renderFilePicker}
                        component={renderFileImageCrop}
                        // className="form-control"
                    />
                </div>
                {/* <div className="form-group has-feedback col-md-6 col-12 mt-auto">
                    <label htmlFor="broker_fee">Broker Fee (Over profit)</label>
                    <Field
                        name="broker_fee"
                        id="broker_fee"
                        placeholder="Broker Fee"
                        allowNegative={false}
                        suffix="%"
                        decimalScale={2}
                        component={renderNumber}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-md-6 col-12 mt-auto">
                    <label htmlFor="influencer_fee">Influencer Fee (Over profit)</label>
                    <Field
                        name="influencer_fee"
                        id="influencer_fee"
                        placeholder="Influencer Fee"
                        allowNegative={false}
                        suffix="%"
                        decimalScale={2}
                        component={renderNumber}
                        type="text"
                        className="form-control"
                    />
                </div> */}
                <div className="form-group has-feedback col-md-12 mt-auto">
                    <label htmlFor="description">Description</label>
                    <Field
                        name="description"
                        id="description"
                        rows={5}
                        placeholder="Description"
                        component={renderTextArea}
                        customClass="text-area-h-auto border"
                    />
                </div>
                {/* <div className="form-group has-feedback col-md-6 mt-auto">
                    <label htmlFor="available">Available</label>
                    <Field
                        name="available"
                        label=""
                        component={renderSwitch}
                        type="checked"
                    />
                </div> */}
                <div className="col-12 w-100 form-group mt-3">
                    <div className="row mb-2">
                        <div className="form-group has-feedback col-12 mt-auto">
                            <label htmlFor="prices">Number of vendors than can request the leads</label>
                            <FieldArray
                                id="prices"
                                name="prices"
                                component={renderPrices}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row w-100 border mb-2">
                <div className="form-group has-feedback col-md-6 mt-auto">
                    <label htmlFor="enable_client_feedback">Enable Client Feedback Form</label>
                    <Field
                        name="enable_client_feedback"
                        id="enable_client_feedback"
                        component={renderFieldCheck}
                        type="checkbox"
                        className="form-control"
                    />
                </div>
                {enableClientFeedback && (
                    <div className="form-group has-feedback col-md-6 mt-auto">
                        <label htmlFor="client_feedback_form">Client Feedback Form</label>
                        <Field
                            name="client_feedback_form"
                            id="client_feedback_form"
                            placeholder="Client Form Type"
                            loadOptions={loadClientForms}
                            component={AsyncSelectField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                )}
            </div> */}
            <div className="d-flex mb-4">
                <Link className="btn button-03 ml-auto mr-1 px-3" to="/job-type-vendor">Cancel</Link>
                <button type="submit" className="btn btn-primary ml-1 px-3">Save</button>
            </div>
        </form>
    );
};

let VendorTypeForm = reduxForm({
    form: "vendorTypeForm", // a unique identifier for this form
    validate,
})(Form);

const selector = formValueSelector("vendorTypeForm");
VendorTypeForm = connect((state) => {
    const iconValue= selector(state, 'icon')
    const mapIconValue= selector(state, 'map_icon')
    const imageValue= selector(state, 'image')
    const icon_colorValue= selector(state, 'icon_color')
    const map_icon_colorValue= selector(state, 'map_icon_color')
    const enableClientFeedback = selector(state, 'enable_client_feedback')
    return {
        icon: iconValue,
        map_icon: mapIconValue,
        image: imageValue,
        enableClientFeedback,
        icon_colorValue,
        map_icon_colorValue,
    };
})(VendorTypeForm);

export default VendorTypeForm;
