import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import _ from "lodash"
import Swal from 'sweetalert2';
import {push} from "react-router-redux";
import broker from "../broker/broker";
import {initialize as initializeForm, change} from "redux-form";
import {SELECT_ALL_OPTION, BROKERAGE_LOCKED} from "../../../utility/constants";

const DATA_BROKERAGE = 'DATA_BROKERAGE';
const SEARCH_VALUE = 'SEARCH_BROKERAGE';
const SET_FILES = 'SET_FILES_BROKERAGE';
const SET_AGENT_DATA = 'SET_AGENT_DATA';
const SET_ID_BROKER = 'SET_ID_BROKER';
const SET_BROKER_NAME = 'SET_BROKER_NAME';
const SET_EXCEL_DATA = 'SET_EXCEL_DATA';
const SET_DATA_OPTION_BROKER = 'SET_DATA_OPTION_BROKER_BG';
const SET_ID_BROKER_SELECTED = 'SET_ID_BROKER_SELECTED_BG';
const SET_LOADER_2 = 'SET_LOADER_2_DETAILS';
const SET_FRANCHISES = 'SET_FRANCHISES_BROKERAGE';
const SET_BROKERS = 'SET_BROKERS_BROKERAGE';

// new const
const DATA_ROSTER_LIST = 'DATA_ROSTER_LIST_DETAILS';
const SET_SEARCH_IN_DETAILS = 'SET_SEARCH_IN_DETAILS';
const SET_PAGE_AGENT_IN_DETAILS = 'SET_PAGE_AGENT_IN_DETAILS';
const SET_URL_EXCEL_TEMPLATE = 'SET_URL_EXCEL_TEMPLATE';
const SET_ITEM_OFFICES = 'SET_ITEM_OFFICES';
const SET_ITEM_2 = 'SET_ITEM_2_INVITE';
const SET_REAL_ID = 'SET_REAL_ID';
const SET_SELECT_PERMISSIONS = 'SET_SELECT_PERMISSIONS';
const SET_OPTION_BROKERAGE = 'SET_OPTION_BROKERAGE';
const SET_USER_ITEM = 'SET_USER_ITEM_BROKERAGE';


const baseReducer = createReducer(
    "brokerage", //unique identified on store.
    "brokerage", //endpoint for request.
    "detailsWithBroker", //form name.
    "/brokers", //url of component in frontend
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setUrlExcelTemplate = urlTemplate => ({
    type: SET_URL_EXCEL_TEMPLATE,
    urlTemplate,
});

const setRosterList = roster => ({
    type: DATA_ROSTER_LIST,
    roster,
});

const setSearchAgent = searchAgent => ({
    type: SET_SEARCH_IN_DETAILS,
    searchAgent,
});

const setPageAgentList = pageAgent => ({
    type: SET_PAGE_AGENT_IN_DETAILS,
    pageAgent,
});

const setLoader2 = loader2 => ({
    type: SET_LOADER_2,
    loader2,
});

// const setRosterList = data => ({
//     type: DATA_BROKERAGE,
//     data,
// });

const setAgentData = agent => ({
    type: SET_AGENT_DATA,
    agent,
});

const setIdBroker = idBroker => ({
    type: SET_ID_BROKER,
    idBroker,
});

const setBroker = brokerName => ({
    type: SET_BROKER_NAME,
    brokerName,
});

const setExcel = excel => ({
    type: SET_EXCEL_DATA,
    excel,
});

const setDataOptionBroker = optionBroker => ({
    type: SET_DATA_OPTION_BROKER,
    optionBroker,
});

export const setIBrokerSelect = idBrokerSelected => ({
    type: SET_ID_BROKER_SELECTED,
    idBrokerSelected,
});

const setRealId = real_id => ({
    type: SET_REAL_ID,
    real_id,
});

const setItem2 = item2 => ({
    type: SET_ITEM_2,
    item2,
});
const setPermissions = (permissions) => (dispatch) => {
    dispatch({
        type: SET_SELECT_PERMISSIONS,
        permissions,
    });
};

const setOptions = option => ({
    type: SET_OPTION_BROKERAGE,
    option,
});

const setItemOffices = itemOffices => ({
    type: SET_ITEM_OFFICES,
    itemOffices,
});

const setOptionsFranchises = franchises => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});
const setUserItem = (userItem) => ({
    type: SET_USER_ITEM,
    userItem,
});

// ------------------------------------
// Actions
// ------------------------------------

const onChange = (data, id) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.postAttachments('brokerage/upload_agent', {'brokerage_id': id}, [{
        name: "agents",
        file: data,
    }])
        .then((response) => {
            dispatch(customToListDetails(1, id));
        })
        .catch((error) => {
            if (error.details === "already exist") {
                NotificationManager.info('Agent roster already exist', 'NOTIFICATIONS', 0);
            } else {
                NotificationManager.error('Error in transaction', 'ERROR', 3000);
            }
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const customToListDetails = (page = 1, id = null) => (dispatch) => {
    const params = {id, page};
    dispatch(baseReducer.actions.setLoader(true));
    api.get(`brokerage/custom_list`, params)
        .then((response) => {
            dispatch(baseReducer.actions.setPage(page));
            dispatch(setAgentData(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const createAttachment = (id = null, data = {}) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    delete data.logo;
    delete data.office_w9;
    const logo = getStore().brokerage.files;
    const archive = getStore().brokerage.excel;
    data.id_broker = id ? id : getStore().brokerage.brokerName.id;
    if (logo.length !== 0 || archive.length !== 0) {
        api.postAttachments('brokerage', data,
            [logo.length !== 0 && {name: 'logo', file: logo},
                archive.length !== 0 && {name: 'office_w9', file: archive}],
            {})
            .then((response) => {
                NotificationManager.success('Record created', 'SUCCESS', 3000);
                dispatch(setFiles([]));
                dispatch(setExcel([]));
                dispatch(push(`/brokers/broker/${data.id_broker}/brokerages/`));
            })
            .catch(() => {
                NotificationManager.error('Create failed', 'ERROR');
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    }

};

const updateAttachment = (id = null, data = {}) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    delete data.logo;
    delete data.office_w9;
    const logo = getStore().brokerage.files;
    const broker = getStore().brokerage.idBroker;
    const archive = getStore().brokerage.excel;
    if (logo.length !== 0 || archive.length !== 0) {
        api.putAttachments(`brokerage/${id}`, data,
            [logo.length !== 0 && {name: 'logo', file: logo},
                archive.length !== 0 && {name: 'office_w9', file: archive}],
            {})
            .then((response) => {
                dispatch(setFiles([]));
                dispatch(setExcel([]));
                dispatch(push(`/brokers/broker/${broker}/brokerages/`));
                NotificationManager.success('Record updated', 'SUCCESS', 3000);
            })
            .catch(() => {
                NotificationManager.error('Update failed', 'ERROR');
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    } else {
        api.put(`brokerage/${id}`, data).then(() => {
            NotificationManager.success('Record updated', 'Success', 3000);
            dispatch(push(`/brokers/broker/${broker}/brokerages/`));
        }).catch(() => {
            NotificationManager.error('Edition failed', 'ERROR', 0);
        }).finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
    }

};

const newToList = (page = 1, id = null) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    const store = getStore()
    const resource = store.brokerage;
    const params = {page};
    params.ordering = resource.ordering;
    // params.search = resource.search;
    // if (resource.idBrokerSelected) {
    //     params.broker_id = resource.idBrokerSelected;
    // }
    const { BrokerageGrid } = store.form;
    if (BrokerageGrid && BrokerageGrid.values) {
        const { state, franchise, broker, status, search } = BrokerageGrid.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.broker__state__in = "null"
            }else {
                params.broker__state__in = state;
            }
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.broker__franchise__id__in = 0
            }else {
                params.broker__franchise__id__in = franchise;
            }
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker__id__in = 0
            }else {
                params.broker__id__in = broker;
            }
        }
        if (status !== "" && status !== null && status !== undefined && status !== SELECT_ALL_OPTION["value"]) {
            if (status.length == 0) {
                params.status__in = 0
            }else {
                params.status__in = status;
            }
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
    }

    api.get('brokerage', params).then((response) => {
        dispatch(baseReducer.actions.setPage(page));
        // dispatch(baseReducer.actions.setData({...response, ...user}));
        dispatch(baseReducer.actions.setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const getBroker = (id) => (dispatch, getStore) => {
    if (id !== null) {
        dispatch(setIdBroker(id))
    }
    const idBroker = getStore().brokerage.idBroker;
    api.get('brokerage/name_user', {'id': id !== null ? id : idBroker})
        .then((response) => {
            dispatch(setBroker(response));
            dispatch(setIdBroker(response.id))
        }).catch(() => {
    }).finally(() => {
    });
}

const removeBrokerage = (item) => (dispatch, getStore) => {
    const {id} = item;
    if (item.status == BROKERAGE_LOCKED) {
        dispatch(baseReducer.actions.setLoader(true));
        api.post("brokerage/send_validation_code").then(reponse => {
            Swal.fire({
                title: 'Are you sure you want to delete this Office?',
                text: "To confirm please enter the verification code",
                input: 'text',
                // inputLabel: "To confirm please enter the verification code",
                // inputPlaceholder: "To confirm please enter the verification code",
                type: 'warning',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Next',
                confirmButtonColor: '#7E8A97',
                cancelButtonColor: '#B9BDC1',
                showLoaderOnConfirm: true,
                preConfirm: (code) => {
                    return api.post("brokerage/validate_code", {code}).then(response => {
                        return response
                    }).catch(error => {
                        let msg = "Could not validate the code try again"
                        if (error && error.detail) {
                            msg = error.detail
                        }
                        Swal.showValidationMessage(
                            `${msg}`
                        )
                    })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.value && result.value.detail === "Correct code") {
                    let htmlTemplate = `<p style="font-size: 15px;margin-bottom: 0;">by doing that, all the agents under this office will be locked from using the ShowIT Solutions™ platform</p><br/>`
                    htmlTemplate += `<p style="font-size: 17px; font-weight: bold;margin-bottom: 0;">This action can't be reverted!</p>`
    
                    Swal.fire({
                        title: 'Are you sure you want to delete this Office?',
                        html: htmlTemplate,
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: '#7E8A97',
                        cancelButtonColor: '#B9BDC1',
                        confirmButtonText: 'Yes, delete it!',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            dispatch(remove(id))
                        }
                    });
                    console.log("DELETED");
                }
            })
        }).catch(error => {
            NotificationManager.error('Failed to send verification code, please try again later', 'Error', 1000);
        }).finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        })
    } else {
        let htmlTemplate = `<p style="font-size: 17px;margin-bottom: 0;">Office status must change to locked prior to deleting the office</p>`
        Swal.fire({
            html: htmlTemplate,
            type: "info",
            confirmButtonColor: '#7E8A97',
        })
    }

    
};

const remove = id => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.eliminar(`brokerage/${id}`).then(() => {
        dispatch(newToList());
        NotificationManager.success('Record removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const validate = (id) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.put(`brokerage/validate/${id}`)
        .then(response => {
            dispatch(setRosterList(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};


const getFormBrokerOption = () => (dispatch) => {
    // dispatch(baseReducer.actions.setLoader(true));
    api.get('general/option_broker')
        .then(response => {
            let option = response.results;
            dispatch(setDataOptionBroker(option));
        })
        .catch(() => {
            dispatch(setDataOptionBroker([]));
        })
        .finally(() => {
            // dispatch(baseReducer.actions.setLoader(false));
        });
};

const agentList = (page = 1, brokerage_id = '') => (dispatch, getStore) => {
    const resource = getStore()['brokerage'];
    const params = {page};
    // params.account_status = '';
    params.ordering = resource.ordering;
    params.search = resource.searchAgent;
    params.company_id = brokerage_id !== '' ? brokerage_id : resource.item.id;
    dispatch(setLoader2(true));
    // api.get('brokerage-user', params)
    //     .then((response) => {
    //         dispatch(setPageAgentList(page));
    //         dispatch(setRosterList(response));
    //     })
    //     .catch(() => {
    //     })
    //     .finally(() => {
    //         dispatch(setLoader2(false));
    //     });

    api.get('broker-invite', params)
        .then((response) => {
            dispatch(setPageAgentList(page));
            dispatch(setRosterList(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader2(false));
        });
};

const searchAgent = search => (dispatch) => {
    dispatch(setSearchAgent(search));
    dispatch(agentList());
};

const getUrlExelTemplate = () => (dispatch) => {
    api.get('brokerage/template_excel')
        .then((response) => {
            dispatch(setUrlExcelTemplate(response.url));
        })
        .catch(() => {
        })
        .finally(() => {
        });
};


const customSearchChange = search => (dispatch) => {
    dispatch(baseReducer.actions.setSearch(search));
    dispatch(newToList());
};

const setFiles = (files) => (dispatch) => {
    dispatch({
        type: SET_FILES,
        files,
    });
};

const setValueIdBroker = (selected) => (dispatch, getStore) => {
    console.log('selected: ', selected)
    dispatch(setIBrokerSelect(selected.value));
    dispatch(newToList(1));
};

const getBrokerages = (brokerage_id) => (dispatch) => {
    dispatch(setLoader2(true));
    api.get('brokerage/option_select', {brokerage_id})
        .then(response => {
            dispatch(setOptions(response));
        })
        .catch(() => {
        })
        .finally(() => {
            dispatch(setLoader2(false));;
        });
};

const customRead = (id, brokerage_id='') => (dispatch, getStore) => {
    const resource = getStore()['brokerage'];
    dispatch(setLoader2(true));
    let params = {id}
    params.company_id = brokerage_id !== '' ? brokerage_id : resource.item.id;
    api.get(`broker-invite/${id}`, params)
        .then((response) => {
            if (response.user) {
                response.roles = response.user.roles.value
            }
            dispatch(initializeForm('invitesForm', response));
            dispatch(setPermissions(response["broker_user"]));
            dispatch(setItem2(response));
            dispatch(setItemOffices(response["broker_user"]));
        })
        .catch(() => {
            NotificationManager.error('Error in transaction', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(setLoader2(false));
        });
};


const invites = (company_id, goBack=null) => (dispatch, getStore) => {
    //create invite
    const dataForm = getStore().form.invitesForm.values;
    //validate if contains offices permission
    const resource = getStore().brokerage;
    let data = dataForm
    data.permissions = resource.permissions
    if (data.roles > 1 && resource.permissions.length < 1) {
        NotificationManager.error('None Offices selected', 'Error', 2000)
    } else {
        if (data.roles === 1) {
            delete data.permissions
        }
        dispatch(setLoader2(true));
        const _body = {...data, company_id}
        api.post('broker-invite', _body)
            .then(() => {
                NotificationManager.success('Invitation sent', 'Success', 2000);
                dispatch(setItemOffices({}));
                if (goBack) {
                    goBack()
                }
                // dispatch(push(`/offices/${company_id}/`));
            })
            .catch((error) => {
                if (error.detail) {
                    NotificationManager.error(`${error.detail}`, 'Error', 5000);
                } else {
                    NotificationManager.error('Transaction error', 'Error', 2500);
                }
            })
            .finally(() => {
                dispatch(setLoader2(false));
            });
    }
};

const removeInvite = id => (dispatch) => {
    dispatch(setLoader2(true));
    api.eliminar(`broker-invite/${id}`).then(() => {
        dispatch(agentList());
        NotificationManager.success('Record removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader2(false));
    });
};

const updateInvite = (company_id, goBack=null) => (dispatch, getStore) => {

    const dataForm = getStore().form.invitesForm.values;
    const resource = getStore().brokerage;
    const item2 = getStore().brokerage.item2;
    //update
    let data = dataForm
    data.permissions = resource.permissions
    if (data.roles > 1 && resource.permissions.length < 1) {
        NotificationManager.error('None Offices selected', 'Error', 2000)
    } else {
        if (data.roles === 1) {
            delete data.permissions
        }
        dispatch(setLoader2(true));
        const _body = {...data, company_id}
        api.put(`broker-invite/${item2.id}`, _body)
            .then((response) => {
                NotificationManager.success(`${data.roles !== 1 ? 'Permission' : 'Role'} updated`, 'Success', 2000);
                dispatch(baseReducer.actions.setItem({}));
                dispatch(setItem2({}));
                dispatch(setRealId(0));
                if (goBack) {
                    goBack()
                }
                // dispatch(push(`/offices/${company_id}/`));
            })
            .catch(() => {
                if (error.detail) {
                    NotificationManager.error(`${error.detail}`, 'Error', 4000);
                } else {
                    NotificationManager.error('Update invite failed, try again later', 'Error', 2000);
                }
            })
            .finally(() => {
                dispatch(setLoader2(false));
            });
    }
};

const goToAgents = (office_id) => (dispatch) => {
    dispatch(push({pathname: '/agents', search:`?brokerage=${office_id}`}))
}

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(newToList());
    }, 400);
};

export const changeFilters = (type, value=null) => (dispatch, getStore) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'state':
            setTimeout(() => {
                dispatch(newToList())
            }, 500)
            break;
        case 'franchise':
            setTimeout(() => {
                dispatch(getBrokerOption(true))
            }, 500)
            break;
        case 'broker':
            setTimeout(() => {
                dispatch(newToList())
            }, 500)
            break;
        case 'status':
            setTimeout(() => {
                dispatch(newToList())
            }, 500)
            break;
        default:
            break;
    }
}

export const getFranchisesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { BrokerageGrid } = store.form;
    let franchiseValue = null;
    if (BrokerageGrid && BrokerageGrid.values) {
        const {franchise} = BrokerageGrid.values;
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                franchiseValue = SELECT_ALL_OPTION["value"]
            }else {
                franchiseValue = franchise
            }
        }
    }
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                // let value = [];
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                //     value.push(franchise.id)
                // });
                if(selectAll && franchiseValue == null) {
                    dispatch(
                        change(
                            "BrokerageGrid",
                            "franchise",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if(franchiseValue !== null) {
                    dispatch(
                        change(
                            "BrokerageGrid",
                            "franchise",
                            franchiseValue
                        )
                    );
                }
            } else {
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                // });
                const franchise_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "BrokerageGrid",
                        "franchise",
                        [franchise_id]
                    )
                );
                setTimeout(() => {
                    dispatch(getBrokerOption())
                }, 500);
            }
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
            dispatch(change("BrokerageGrid", "franchise", ""));
            setTimeout(() => {
                dispatch(getBrokerOption())
            }, 500);
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(newToList())
                }, 500)
            }
        });
};

export const getBrokerOption =
    (getData = null, selectAll=false, brokerId=null, brokerageId=null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { BrokerageGrid } = store.form;
        let params = { state: "", franchise: "", is_multi: true };
        let brokerValue = null;
        if (BrokerageGrid && BrokerageGrid.values) {
            const { state, franchise, broker} = BrokerageGrid.values;
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                // params.franchise = franchise;
                if (franchise.length == 0) {
                    params.franchise = ""
                }else {
                    params.franchise = franchise;
                }
            }
            if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                if (broker.length == 0) {
                    brokerValue = SELECT_ALL_OPTION["value"]
                }else {
                    brokerValue = broker
                }
            }
            // if (state !== "" && state !== null && state !== undefined) {
            //     params.state = state;
            // }
        }

        api.get(`general/option_broker`, params)
            .then((response) => {
                console.log("RESP BRK: ". response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll && brokerId == null && brokerValue == null ) {
                        dispatch(
                            change("BrokerageGrid", "broker", SELECT_ALL_OPTION["value"])
                        );
                    }
                    if (brokerValue !== null && brokerId == null) {
                        dispatch(
                            change("BrokerageGrid", "broker", brokerValue)
                        );
                    }
                    if (brokerId !== null) {
                        dispatch(
                            change("BrokerageGrid", "broker", [brokerId])
                        );
                    }
                } else {
                    option = [...response.results];
                    console.log("OPT: ", option);
                    const broker_id = option.length ? option[0].value : "";
                    dispatch(
                        change("BrokerageGrid", "broker", [broker_id])
                    );
                }
                dispatch(setBrokers(option));
            })
            .catch(() => {
                dispatch(setBrokers([]));
                dispatch(change("BrokerageGrid", "broker", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(newToList())
                    }, 500)
                }
            });
    };

const update = (data = {}, attachments = [], setLogo=null) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    let body = _.cloneDeep(data)
    delete body.logo;
    delete body.office_w9;
    api.putAttachments(`brokerage/${body.id}`, body, attachments).then((response) => {
        NotificationManager.success('Office updated', 'SUCCESS', 1000);
        if (setLogo) {
            setLogo(null)
        }
        dispatch(baseReducer.actions.read(data.id))
    }).catch((error) => {
        NotificationManager.error('Error, please try again', 'ERROR', 1000);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const edit = (id, data) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    delete data.logo;
    delete data.office_w9;
    api.put(`brokerage/${id}`, data).then(() => {
        NotificationManager.success('Office updated', 'Success', 3000);
        dispatch(baseReducer.actions.read(id))
    }).catch(() => {
        NotificationManager.error('Edition failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const readUser = (id) =>  (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get(`user/${id}`).then((response) => {
        if (response) {
            dispatch(setUserItem(response))
        }
    }).catch(() => {
        NotificationManager.error('Error', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
}

const changeUserPassword = (data, goToBack=null) =>  (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.post(`brokerage/changed_user_password`, data).then((response) => {
        if (response) {
            NotificationManager.success('Password updated', 'Success', 3000);
            if (goToBack) {
                goToBack()
            }
        }
    }).catch(() => {
        NotificationManager.error('Password reset failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
}

const resetForm = () => (dispatch) => {
    dispatch(initializeForm("BrokerageGrid", {state: SELECT_ALL_OPTION['value'], status: SELECT_ALL_OPTION['value']}))
}

const assignAddressData = (data) => (dispatch) => {
    if (data) {
        const {state, city, zip_code} = data
        dispatch(change('detailsWithBroker', 'state', state))
        dispatch(change('detailsWithBroker', 'city', city))
        dispatch(change('detailsWithBroker', 'zipcode', zip_code))
    }

}

export const actions = {
    onChange,
    validate,
    setFiles,
    customToListDetails,
    newToList,
    createAttachment,
    customSearchChange,
    getBroker,
    setBroker,
    updateAttachment,
    setExcel,
    removeBrokerage,
    getBrokerOption,
    getFranchisesOption,
    setValueIdBroker,
    searchAgent,
    getUrlExelTemplate,
    agentList,

    getBrokerages,
    customRead,
    setRealId,
    setItem2,
    setPermissions,
    invites,
    removeInvite,
    setItemOffices,
    updateInvite,
    goToAgents,
    changeFilters,
    getFormBrokerOption,
    ...baseReducer.actions,
    edit,
    update,
    readUser,
    searchChange,
    changeUserPassword,
    resetForm,
    assignAddressData,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...baseReducer.reducers,
    [DATA_BROKERAGE]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [SEARCH_VALUE]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    }
    ,
    [SET_FILES]: (state, {files}) => {
        return {
            ...state,
            files,
        };
    },
    [SET_AGENT_DATA]: (state, {agent}) => {
        return {
            ...state,
            agent,
        };
    },
    [SET_ID_BROKER]: (state, {idBroker}) => {
        return {
            ...state,
            idBroker,
        };
    },
    [SET_BROKER_NAME]: (state, {brokerName}) => {
        return {
            ...state,
            brokerName,
        };
    },
    [SET_DATA_OPTION_BROKER]: (state, {optionBroker}) => {
        return {
            ...state,
            optionBroker,
        };
    },
    [SET_ID_BROKER_SELECTED]: (state, {idBrokerSelected}) => {
        return {
            ...state,
            idBrokerSelected,
        };
    },
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [DATA_ROSTER_LIST]: (state, {roster}) => {
        return {
            ...state,
            roster,
        };
    },
    [SET_SEARCH_IN_DETAILS]: (state, {searchAgent}) => {
        return {
            ...state,
            searchAgent,
        };
    },
    [SET_PAGE_AGENT_IN_DETAILS]: (state, {pageAgent}) => {
        return {
            ...state,
            pageAgent,
        };
    },
    [SET_URL_EXCEL_TEMPLATE]: (state, {urlTemplate}) => {
        return {
            ...state,
            urlTemplate,
        };
    },
    [SET_REAL_ID]: (state, {real_id}) => {
        return {
            ...state,
            real_id,
        };
    },
    [SET_ITEM_2]: (state, {item2}) => {
        return {
            ...state,
            item2,
        };
    },
    [SET_SELECT_PERMISSIONS]: (state, {permissions}) => {
        return {
            ...state,
            permissions,
        };
    },
    [SET_OPTION_BROKERAGE]: (state, {option}) => {
        return {
            ...state,
            option,
        };
    },
    [SET_ITEM_OFFICES]: (state, {itemOffices}) => {
        return {
            ...state,
            itemOffices,
        };
    },
    [SET_FRANCHISES]: (state, {franchises}) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, {brokers}) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_USER_ITEM]: (state, {userItem}) => {
        return {
            ...state,
            userItem,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    files: [],
    option: [],
    brokers: [],
    franchises: [],
    excel: [],
    permissions: {},
    agent: {
        results: [],
        count: 0,
    },
    brokerName: null,
    idBroker: null,
    optionBroker: [],
    roster: {
        results: [],
        count: 0
    },
    idBrokerSelected: '',
    searchAgent: '',
    loader2: false,
    pageAgent: 1,
    urlTemplate: '',

    option: [],
    item2: {},
    real_id: 0,
    permissions: [],
    itemOffices: {},
    userItem: null,
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
