import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/wholesaler";
import WholeSalerList from './WholeSalerList';


const ms2p = (state) => {
    return {
        ...state.wholesalers,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(WholeSalerList);