import React, { Component } from 'react'
import VendorFiltersForm from './VendorFilters'
import VendorGrid from './VendorGrid'

class VendorsList extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount() {

    }

    componentDidMount() {
        this.props.toList();
    }

    render() {
        // State
        const {loader, page, data} = this.props;
        //  Bind
        const {toList, searchChange, removeVendor, onSortChange, sendInvite} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Vendors</div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <VendorFiltersForm searchAction={searchChange} />
                </div>

                <VendorGrid
                    data={data}
                    page={page}
                    loader={loader}
                    toList={toList}
                    onSortChange={onSortChange}
                    remove={removeVendor}
                    sendInvite={sendInvite}
                />
            </div>
        );
    }
}

export default VendorsList