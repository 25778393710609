import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
    renderNumber,
    renderField,
    renderFieldCheck,
    renderTextArea,
    renderFilePicker,
    renderFileImageCrop,
} from "../../Utils/renderField/renderField";

const validate = (values, props) => {
    const errors = {};

    return errors;
}

const renderDetails = (props) => {
    const {fields, setIcon, icons, meta: {error}} = props
    // console.log('ERROR Details: ', props);
    const invalid =  error;
    return (
        <div className="border border py-3">
            <div className="w-100 px-2">
                {fields.map((row, indexS) => (
                    <div
                        key={`prices-${indexS}-ss`}
                        className={`row w-100 mx-1 mb-1 py-2 ${fields.length == indexS + 1 ? 'mb-3' : 'mb-1'}`}
                        style={{backgroundColor: (indexS % 2 !== 0) ? '#F1F1F1' : 'white'}}>
                        <div className="col-10 col-md-10 justify-content-center">
                            <label htmlFor={`${row}.icon`}>Icon</label>
                            <div className="row ">
                                <div className="col-12 col-md-4">
                                    <Field
                                        name={`${row}.icon`}
                                        photo={icons[indexS] ? icons[indexS].icon : undefined}
                                        setFile={setIcon(indexS)}
                                        spectValue={null}
                                        locked={false}
                                        component={renderFileImageCrop}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-md-2 justify-content-center">
                            <img
                                src={require("../../../../../assets/img/delete.svg")}
                                alt="del"
                                style={{width: "1.5rem"}}
                                onClick={() => {
                                    fields.remove(indexS);
                                }}
                                className="pointer align-self-center ml-3 mt-1"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="title">Title</label>
                            <Field
                                name={`${row}.title`}
                                placeholder="Title"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="description">Description</label>
                            <Field
                                name={`${row}.description`}
                                rows={3}
                                placeholder="Description"
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-center">
                <button
                    type="button"
                    className="btn button-01 py-0"
                    onClick={() => fields.push({})}
                >
                    Add Detail
                </button>
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
        </div>
    )
}

const Form = (props) => {
    //  State
    const {detailsValue: icons} = props
    //  Bind
    const { handleSubmit, goBack, setIcon } = props;

    return (
        <form
            action=""
            onSubmit={handleSubmit}
            className="row mx-auto justify-content-center mt-5"
            style={{ width: "80%" }}
        >
            <div className="row w-100">
                <div className="form-group has-feedback col-lg-12 col-sm-12">
                    <label htmlFor="leads_description">Available Soon</label>
                    <Field
                        name="leads_description"
                        rows={5}
                        component={renderTextArea}
                        customClass="text-area-h-auto border"
                    />
                </div>
                <div className="col-12 w-100 form-group mt-4">
                    <div className="row mb-2">
                        <div className="form-group has-feedback col-12 mt-auto">
                            <label htmlFor="leads_details">Details</label>
                            <FieldArray
                                id="leads_details"
                                name="leads_details"
                                icons={icons}
                                setIcon={setIcon}
                                component={renderDetails}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex mb-4">
                <button type="button" className="btn button-03 ml-auto mr-1 px-3" onClick={(e) => goBack()}>Back</button>
                <button type="submit" className="btn btn-primary ml-1 px-3">Save</button>
            </div>
        </form>
    )
}

let LeadsSettingForm = reduxForm({
    form: "leadsSettingForm", // a unique identifier for this form
    validate
})(Form);

const selector = formValueSelector("leadsSettingForm");
LeadsSettingForm = connect((state) => {
    const detailsValue = selector(state, 'leads_details')

    return {
        detailsValue,
    };
})(LeadsSettingForm);

export default LeadsSettingForm;
