import React, {Component} from 'react';
import {reduxForm} from "redux-form";
import {FeedbackFormAgentForm} from "../FeedbackFormAgentForm";
import {validators} from "../validation";


const FeedbackFormAgentCreateForm = reduxForm({
    form: 'FeedbackFormAgentCreateForm', // a unique identifier for this form
    validate: validators,
})(FeedbackFormAgentForm);


export default class FeedbackFormAgentCreate extends Component {
    render() {
        const {create} = this.props;
        return (
            <React.Fragment>
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page"> Create Agent Feedback Form</div>
                    </div>
                </div>
                <FeedbackFormAgentCreateForm
                    onSubmit={create}
                    setIcon={this.setIcon}
                />
            </React.Fragment>
        );
    }
}
