import moment from 'moment'
import _ from 'lodash'
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {api} from '../../../../../utility/api';
import React, {useState, useEffect} from 'react';
import ProfileMap from '../../../Utils/Map/profileMap';
import ReactStars from "react-rating-stars-component";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderFilePicker} from "../../../Utils/renderField/renderField";
import {PROFESSION_APPROVED, PROFESSION_PENDING, PROFESSION_REJECTED, REGISTERED_RE_ASSIGN} from '../../../../../utility/constants'
import {renderField, renderNumber, renderAddressField, MultiSelectField, renderFileField} from '../../../Utils/renderField/renderField';

const validate = (values, props) => {
    let errors = {};
    if (!values.first_name) {
        errors.first_name = "This field is required"
    }
    if (!values.las_name) {
        errors.las_name = "This field is required"
    }
    if (!values.profession_ids) {
        errors.profession_ids = "This field is required"
    }
    if (!values.company_name) {
        errors.company_name = "This field is required"
    }
    if (!values.email) {
        errors.email = "This field is required"
    }
    if (!values.office_phone) {
        errors.office_phone = "This field is required"
    }
    if (!values.mobile_number) {
        errors.mobile_number = "This field is required"
    }
    if (!values.street) {
        errors.street = "This field is required"
    }

    return errors;
}


const UpdateForm = (props) => {

    const {
        handleSubmit,
        loader,
        item,
        goBack,
        optionProfessions=[],
        setAvatar=() => {},
        setInsuranceFile,
        setCertificationFile,
        setBusinessRegistrationFile,
        setWorkingArea,
        goToEditProfessions,
    } = props
    
    const [professionsData, setProfessionsData] = useState([])

    useEffect(() => {
        if (item && item.professions_data) {
            let professions = _.filter(item.professions_data, {is_active: true})
            professions = professions.sort((a, b) => a.state == PROFESSION_APPROVED ? -1 : (a.state == PROFESSION_PENDING ? 0 : 1))
            // item.professions_data.forEach((item) => {
            // })
            setProfessionsData(professions)
        }
    }, [item])

    return (

        <form name="updateVendorForm" className="my-1 col-sm-12 p-sm-0" style={{minHeight: "20rem"}}
              onSubmit={handleSubmit}>
            <div className="row mx-auto pt-2">
                <div className='col-xl-3 col-lg-3 col-md-5 col-sm-12'>
                    <div className="form-group has-feedback">
                        <Field
                            photo={item.photo ? item.photo : null}
                            setFile={setAvatar}
                            name="photo"
                            component={renderFilePicker}
                            disabled={true}
                            className="avatar-round"
                        />
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Sign-up Date</span>
                        <span className="font-weight-bolder text-primary">{moment(item.date_joined).format('ll')}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-5'>
                        <span className="font-weight-bolder h6 mb-1">Status</span>
                        {item.blocked === true ? (
                            <span className={`text-danger font-weight-bold`}>
                            Blocked
                            </span>
                        ): (
                            item.locked === true ? (
                                <span className={`text-danger font-weight-bold`}>
                                    Locked
                                </span>
                            ) : (
                                <React.Fragment>
                                    {item.agent_account_status == 10 && (
                                        <span className="text-warning font-weight-bold">Pending</span>
                                    )}
                                    {item.agent_account_status == 20 && (
                                        <span className="text-success font-weight-bold">Active</span>
                                    )}
                                </React.Fragment>
                            )

                        )}
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Request Leads</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.request_leads : 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Contact the Client</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.contact_client : 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Completed Jobs</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.completed : 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">ShowIT Profit</span>
                        <span className="font-weight-bolder text-primary">$ {item && item.stats ? item.stats.profit.toFixed(2) : "0.00"}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Reviews</span>
                        <span className="font-weight-bolder text-primary">{item && item.stats ? item.stats.reviews : 0}</span>
                    </div>
                </div>
                <div className='col-xl-9 col-lg-9 col-md-7 col-sm-12'>
                    <div className='row'>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="first_name">First name</label>
                            <Field
                                name="first_name"
                                label="First name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <div className='d-flex flex-row align-items-center flex-wrap'>
                                <label className='mb-0' htmlFor="profession_ids">Expertise</label>
                                {item && item.need_validate_profession> 0 && (
                                    <div className="mx-2 my-1">
                                        <span className='badge badge-warning blink_me ' style={{color: '#000'}}>
                                            <i className="fa fa-warning mr-1" />
                                            There is pending
                                        </span>
                                    </div>
                                )}
                                <button
                                    className='btn btn-outline-primary mx-2 my-1'
                                    style={{minWidth: '4rem'}}
                                    type='button'
                                    onClick={goToEditProfessions}>
                                    <i className="fa fa-pencil mr-1" aria-hidden="true" />
                                    Edit
                                </button>
                            </div>
                            <div className='d-flex flex-row align-items-center flex-wrap mt-2'>
                                {item && professionsData && professionsData.length > 0 && professionsData.map((item, index) => {
                                    if (item.state === PROFESSION_PENDING) {
                                        return (
                                            <span key={index} className="my-1 badge bg-gray mr-1 fnt-15 font-weight-bold text-dark">{item.name}</span>
                                        )
                                    } else if (item.state === PROFESSION_REJECTED) {
                                        return (
                                            <span key={index} className="my-1 badge badge-danger mr-1 fnt-15 font-weight-bold">{item.name}</span>
                                        )
                                    } else if (item.state === PROFESSION_APPROVED) {
                                        return (
                                            <span key={index} className="my-1 badge badge-success mr-1 fnt-15 font-weight-bold">{item.name}</span>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="last_name">Last Name</label>
                            <Field
                                name="last_name"
                                label="Last Name"
                                type="text"
                                component={renderField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="company_name">Company Name</label>
                            <Field
                                name="company_name"
                                label="Company Name"
                                type="text"
                                component={renderField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="email">Email</label>
                            <Field
                                name="email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="office_phone">Office #</label>
                            <Field
                                name="office_phone"
                                component={renderNumber}
                                className="form-control"
                                placeholder="mobile number"
                                numberFormat={"###-###-####"}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="mobile_number">Mobile #</label>
                            <Field
                                name="mobile_number"
                                component={renderNumber}
                                className="form-control"
                                placeholder="mobile number"
                                numberFormat={"###-###-####"}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="street">Address</label>
                            <Field
                                name="street"
                                label="street"
                                placeholder='Search an address'
                                component={renderAddressField}
                                type="text"
                                className="form-control"
                                setCoords={(data)=> {
                                    setWorkingArea(data)
                                }}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="years_business">Years in Business</label>
                            <Field
                                name="years_business"
                                component={renderNumber}
                                className="form-control"
                                placeholder=""
                                numberFormat={"########"}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="website">Website</label>
                            <Field
                                name="website"
                                label="Website"
                                type="text"
                                component={renderField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="number_employees">Number of Employees</label>
                            <Field
                                name="number_employees"
                                component={renderNumber}
                                className="form-control"
                                placeholder=""
                                numberFormat={"########"}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="insurance">P&O Insurance</label>
                            <Field
                                name="insurance"
                                setFile={setInsuranceFile}
                                fileAccept="*"
                                component={renderFileField}
                                fileData={item && item.insurance_data ? item.insurance_data : null}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="certification">Certifications</label>
                            <Field
                                name="certification"
                                setFile={setCertificationFile}
                                fileAccept="*"
                                component={renderFileField}
                                fileData={item && item.certification_data ? item.certification_data : null}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="certification">Busines Registration</label>
                            <Field
                                name="certification"
                                setFile={setBusinessRegistrationFile}
                                fileAccept="*"
                                component={renderFileField}
                                fileData={item && item.business_registration_data ? item.business_registration_data : null}
                            />
                        </div>
                        <div className="form-group col-lg-6 col-md-12 col-sm-12 mb-0 mt-4">
                            <label className="mt-n4 d-block" style={{ fontSize: '1.05rem' }}> Account Settings</label>
                            <div className="row w-100 m-0 h-100">
                                <div className='rounded border col-sm-12'>
                                    <div className='row'>
                                        <div className="form-group has-feedback col-sm-12">
                                            <label htmlFor="is_active" className="mt-4 d-block ml-3">Password</label>
                                            <Link
                                                className="btn button-03 ml-3 px-4"
                                                to={`/vendor/vendors/${item.id}/changed-password`}
                                            >
                                                Change Password
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-auto mt-5 justify-content-center align-items-center form-group has-feedback">
                <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                    <button type="button" onClick={goBack} className={`w-100 btn text-white btn-secondary`}>
                        Back
                    </button>
                </div>
                <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                    <button type="submit" className="btn btn-primary">
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}

let UpdateVendorForm = reduxForm({
    form: 'vendorUserForm', // a unique identifier for this form
    validate
})(UpdateForm);

const selector = formValueSelector('vendorUserForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(UpdateVendorForm);
