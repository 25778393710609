import _ from 'lodash'
import React from 'react';
import HeaderCard from "../HeaderCard";
import Grid from "../Utils/GridTable";
import {standardActions} from "../Utils/Grid/StandardActions";
import {
    BROKER_PAGE_SIZE,
    DENIED_BROKER,
    LOCKED_BROKER,
    PENDING_BROKER,
    STATE_USA_LIST,
    STATUS_BROKER,
    VERIFIED_BROKER,
    BLOCKED_BROKER,
    BROKER_ROLES_LIST,
    BROKER_OPERATIONAL_MODE_LIST,
    SELECT_ALL_OPTION,
} from "../../../utility/constants";
import {Field, reduxForm} from "redux-form";
import {SelectField, MultiSelectField, renderSearchField} from "../Utils/renderField";
import {formatPhoneNumber} from "../Brokerage/BrokerageGrid";


const ListGrid = (props) => {
    const {
        data,
        loader,
        page,
        searchAction,
        // filter2,
        changePage,
        remove,
        // filter1,
        optionStates=[],
        optionCities=[],
        changeFilters,
        optionFranchises = [],
        brokerages=[],
        optionInfluencers=[],
        goToAgents,
        goToOffices,
        onSortChange,
    } = props;

    return (
        <div className="d-flex flex-column flex-overflow-hidden-1">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">Brokers</div>
                </div>
            </div>
            <div className="w-100 row mx-auto">
                {/* <HeaderCard
                    link={false}
                    showFile={false}
                    id_header={"brokerList"}
                    searchAction={(value) => searchAction(value)}
                    searchStyle="col-lg-12 col-sm-12 img-fluid mr-lg-3 mb-sm-1"
                    justify="justify-content-start d-flex col-lg-4 col-sm-12 col-md-6 align-items-end"
                /> */}
                <div
                    className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 d-flex flex-row align-items-start`}
                >
                    <div className="form-group has-feedback w-100 mb-0">
                        <Field
                            name="search"
                            label="Search"
                            placeholder={"Search"}
                            component={renderSearchField}
                            onChange={(e) => {
                                searchAction(
                                    e.target && e.target.value ? e.target.value : ""
                                );
                            }}
                            customClass="mt-4"
                            type="text"
                        />
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                    <label htmlFor="state">State</label>
                    <Field
                        name="state"
                        options={optionStates}
                        set_value={(value) => {
                            changeFilters("state", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                    <label htmlFor="city">City</label>
                    <Field
                        name="city"
                        options={optionCities}
                        set_value={(value) => {
                            changeFilters("city", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                    <label htmlFor="franchise_id">Franchise</label>
                    <Field
                        name="franchise_id"
                        options={optionFranchises}
                        set_value={(value) => {
                            changeFilters("franchise", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                    <label htmlFor="brokerage">Office</label>
                    <Field
                        name="brokerage"
                        options={brokerages}
                        set_value={(value) => {
                            changeFilters("brokerage", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                    <label htmlFor="status_value">Status</label>
                    <Field
                        name="status_value"
                        options={STATUS_BROKER}
                        set_value={(value) => {
                            changeFilters("status", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="form-group has-feedback col-lg-2 col-sm-12 col-md-6">
                    <label htmlFor="influencer_id">Influencer</label>
                    <Field
                        name="influencer_id"
                        options={optionInfluencers}
                        set_value={(value) => {
                            changeFilters("influencer", value);
                        }}
                        component={MultiSelectField}
                        className="form-control"
                        disabled={false}
                    />
                </div>
            </div>
            <div id='BROKER-LIST' className="card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
                <div className="card-body px-0 py-2 d-flex flex-column">
                    <Grid
                        hover
                        page={page}
                        keyField={"id"}
                        data={data ? data : []}
                        loading={loader}
                        sizePerPage={BROKER_PAGE_SIZE}
                        onSortChange={(field, sortOrder, state) => {
                            onSortChange(field)
                        }}
                        onPageChange={(value) => changePage(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter: standardActions({
                                    customRemove: remove,
                                    // view: "brokers/broker",
                                    edit: "brokers/broker",
                                }),
                            },
                            {
                                dataField: "status",
                                formatter: formatStatus(),
                                text: "status",
                            },
                            {
                                dataField: "name",
                                formatter: formatFullName(),
                                sort: true,
                                text: "Broker Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "role",
                                formatter: formattRole(),
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "left",
                                    };
                                },
                                text: "Role",
                            },
                            {
                                dataField: "license",
                                sort: true,
                                text: "RE License Number",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "170px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "state",
                                // formatter: formattedState(),
                                text: "State",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "100px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "city",
                                // formatter: formattedState(),
                                text: "City",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "100px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "office_phone_number",
                                formatter: formatPhoneNumber(),
                                text: "Office #",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "mobile_number",
                                formatter: formatPhoneNumber(),
                                text: "Mobile #",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "owner_email",
                                sort: true,
                                text: "E-mail",
                            },
                            {
                                dataField: "franchise__name",
                                sort: true,
                                formatter:(cell, row) => {
                                    return <span>{row.franchise ? row.franchise.name : ""}</span>
                                },
                                text: "Franchise",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                    };
                                },
                            },
                            {
                                dataField: "operation_access",
                                formatter: formattOperationAccess(),
                                text: "Operational Mode",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "offices",
                                formatter: formattOffices(),
                                text: "Brokerage Licence #",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "160px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "group_name",
                                text: "Group Name",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "left",
                                    };
                                },
                            },
                            {
                                dataField: "influencer__first_name",
                                text: "Influencer",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "left",
                                    };
                                },
                                formatter: (cell, row) => {
                                    return <span>{row.influencer_data ? `${row.influencer_data.first_name} ${row.influencer_data.last_name}`: ''}</span>
                                }
                            },
                            {
                                dataField: "numb_offices",
                                formatter: formatNumOffices(goToOffices),
                                text: "# of Offices",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_agents",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "110px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_user",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Registered Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_unregistered",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Unregistered Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "agents_stats.total_user_active",
                                formatter: formatAgentTotal(goToAgents),
                                text: "# of Active Agents",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_job_posted",
                                text: "# of Job Posted",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "120px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_job_performed",
                                text: "# of Job Performed",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "120px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_show_it_share",
                                formatter: formatCurrency(),
                                text: "Total ShowIT Share",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "show_it_share",
                                formatter: (cell, row) => {
                                    return <span className={`text-center font-weight-bold text-${cell ? "success" : "danger"}`}>{cell ? "Yes" : "No"}</span>
                                },
                                text: "ShowIT Share ?",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "payment_through_broker",
                                formatter: (cell, row) => {
                                    return <span className={`text-center font-weight-bold text-${cell ? "success" : "danger"}`}>{cell ? "Yes" : "No"}</span>
                                },
                                text: "Payment through broker",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "hold_payment_for",
                                formatter: (cell, row) => {
                                    return <span className="text-center font-weight-bold">{cell ? `${cell} ${cell > 0 ? 'hrs' : 'hr'}` : ''}</span>
                                },
                                text: "Hold payment for (hr)",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "130px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                            {
                                dataField: "total_show_it_revenue",
                                formatter: formatCurrency(),
                                text: "Total ShowIT Revenue",
                                headerStyle: (colum, colIndex) => {
                                    return {
                                        minWidth: "150px",
                                        textAlign: "right",
                                    };
                                },
                                style: (colum, colIndex) => {
                                    return {
                                        textAlign: "right",
                                    };
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export function formatStatus() {
    return (cell, row) => {
        const getStatus = () => {
            let text = ''
            STATUS_BROKER.map(item => {
                if (cell === item.value) {
                    text = item.label
                }
            })
            return text
        }

        const getColor = () => {
            let colorText = 'black'
            if (cell === DENIED_BROKER) {
                colorText = 'danger'
            } else if (cell === PENDING_BROKER) {
                colorText = 'warning'
            } else if (cell === VERIFIED_BROKER) {
                colorText = 'success'
            } else if (cell === LOCKED_BROKER) {
                colorText = 'danger'
            }else if (cell === BLOCKED_BROKER) {
                colorText = 'danger'
            }
            return colorText
        }
        return (
            <React.Fragment>
                <p
                    className={`my-0 text-capitalize font-weight-bold text-${getColor()}`}
                    style={{
                        font: 'normal normal medium 13px/22px Poppins',
                        letterSpacing: '0px',
                        opacity: '1'
                    }}
                >
                    {getStatus()}
                </p>
            </React.Fragment>
        )
    };
}

export function formatFullName() {
    return (cell, row) => {
        return (
            <p className={`my-0 text-capitalize`}>
                {row.name}
            </p>
        )
    };
}
const formattRole = () => (cell, row) => {
    const role = _.find(BROKER_ROLES_LIST, {value: cell})
    return (
        <p className={`my-0 text-capitalize`}>
            {role ? role.label : ""}
        </p>
    )
}

const formatAgentTotal = (goTo) => (cell, row) => {
    return(
        <p className="my-0 text-primary pointer" onClick={(e) => goTo(row.id)}>{cell}</p>
    )
}

const formattOperationAccess = () => (cell, row) => {
    const operation = _.find(BROKER_OPERATIONAL_MODE_LIST, {value: cell})
    return (
        <p className={`my-0 text-capitalize`}>
            {operation ? operation.label : ""}
        </p>
    )
}

const formattOffices = () => (cell, row) => {
    const license = cell && cell.length ? cell[0].license : ""
    return (
        <p className={`my-0 text-capitalize`}>
            {license}
        </p>
    )
}

export function formattedState() {
    return (cell, row) => {
        const getState = () => {
            let text = ''
            STATE_USA_LIST.map(option => {
                if (option.value === cell) {
                    text = option.label
                }
            })
            return text;
        }
        return (
            <p className={`my-0 text-capitalize`}>
                {getState()}
            </p>
        )
    };
}

const formatOffices = () => (cell, row) => {
    return (<div>
        {cell.join(',')}
    </div>)
}

const formatNumOffices = (goTo) => (cell, row) => {
    return (
        <p className="my-0 text-primary pointer" onClick={(e) => goTo(row.id)}>
            {row.offices ? row.offices.length : 0}
        </p>
    );
};
const formatCurrency = () => (cell, row) => {
    return (<div>
        $ {cell ? cell.toFixed(2) : "0.00"}
    </div>)
}

export default reduxForm({
    form: 'listGridBroker', // a unique identifier for this form
    initialValues: {state: SELECT_ALL_OPTION["value"], status_value: SELECT_ALL_OPTION["value"]},
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ListGrid);
