import React, {Component} from 'react';
import SendgridCampaignGrid from "./SendgridCampaignGrid";

class SendgridCampaigns extends Component {

    componentWillMount() {
        this.props.toList();
    }

    render() {
        // State
        const { loader, page, data, searchChange, toList, remove, unStop } = this.props;

        return (
            <SendgridCampaignGrid
                data={data}
                page={page}
                loader={loader}
                toList={toList}
                remove={remove}
                searchAction={searchChange}
                unStop={unStop}
            />
            // <h1>test</h1>
        );
    }
}

export default SendgridCampaigns;
