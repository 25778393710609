import React from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { renderSearchField } from "../../Utils/renderField";
import { Field, reduxForm, formValueSelector } from "redux-form";

let PromotionFiltersForm = (props) => {
    //  Bind
    const {searchAction} = props;

    return (
        <div className="row justify-content-between mt-2 w-100 mb-3">
            <div
                className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}
            >
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search'}
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>

            </div>
            <Link
                to={"promotions/create"}
                style={{height: '35px'}}
                className="btn btn-primary px-3 col-lg-2 col-sm-10 pt-2 text-white mt-2"
            >
                Create
            </Link>
        </div> 
    );
};

PromotionFiltersForm = reduxForm({
    form: 'promotionFiltersForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PromotionFiltersForm);

const selector = formValueSelector('promotionFiltersForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(PromotionFiltersForm);