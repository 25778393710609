import { connect } from 'react-redux'
import BrokerReferral from './BrokerReferral'
import {actions} from '../../../redux/modules/brokerReferral'

const mapStateToProps = (state) => ({
    ...state.brokerReferral
})

const mapDispatchToProps = {
    ...actions
}

export default connect(mapStateToProps, mapDispatchToProps)(BrokerReferral)