import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import {NotificationContainer} from 'react-notifications';

import {Login, Profile, Register} from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';

// DASHBOARD
import Dashboard from './common/components/Dashboard';
// JOB TYPE
import JobTypeList from './common/components/JobType';
import JobTypeEdit from "./common/components/JobType/JobTypeEdit";
import JobTypeCreate from "./common/components/JobType/JobTypeCreate";
import JobTypeView from "./common/components/JobType/JobTypeView";
// FEEDBACK FORM AGENTS
import FeedbackFormAgentList from './common/components/FeedbackFormAgent';
import FeedbackFormAgentEdit from "./common/components/FeedbackFormAgent/FeedbackFormAgentEdit";
import FeedbackFormAgentCreate from "./common/components/FeedbackFormAgent/FeedbackFormAgentCreate";
import FeedbackFormAgentView from "./common/components/FeedbackFormAgent/FeedbackFormAgentView";
// FEEDBACK FORM CUSTOMERS
import FeedbackFormCustomerList from './common/components/FeedbackFormCustomer';
import FeedbackFormCustomerEdit from "./common/components/FeedbackFormCustomer/FeedbackFormCustomerEdit";
import FeedbackFormCustomerCreate from "./common/components/FeedbackFormCustomer/FeedbackFormCustomerCreate";
import FeedbackFormCustomerView from "./common/components/FeedbackFormCustomer/FeedbackFormCustomerView";
//  FEEDBACK FORM CLIENT
import FeedbackFormClientList from './common/components/FeedbackFormClient';
import FeedbackFormClientCreateUpdate from './common/components/FeedbackFormClient/FeedbackFormClientCreateUpdate';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
// EXAMPLES
import Grids from "./common/components/Examples/Grids";
import Notifications from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
// BROKERAGES
import Brokerage from './common/components/Brokerage/BrokerageContainer';
import BrokerageDetail from './common/components/Brokerage/DetailsContainer';
import CreateBrokerage from './common/components/Brokerage/CrateContainer';
import Invite from './common/components/Brokerage/Invite'
import OfficeUserPassword from './common/components/Brokerage/UserChangePassword'
//  INFLUENCER
import {InfluencerList, InfluencerForm, InfluencerProfile} from './common/components/Influencer'
import {InfluencerTeamList, InfluencerTeamProfile, InfluencerTeamContact} from './common/components/InfluencerTeam'
// AGENTS
import Agents from './common/components/Agent/index';
import CreateAgentRoster from './common/components/Agent/CreateAgentRoster';
import UpdateAgent from './common/components/Agent/AgentRegistered/UpdateContainer';
import ChangedPassword from './common/components/Agent/Password/Index';
import AgentLockNote from './common/components/Agent/LockNotes';
import AgentPendingNote from './common/components/Agent/PendingNotes';
import {AgentReviewList, AgentReviewEdit} from './common/components/Agent/AgentReview'
//  WHOLESALER
import {WholeSalerList, WholeSalerUpdate} from './common/components/WholeSaler'
// STATS
import Stats from './common/components/Stats/Revenue';
import AgentPerformance from './common/components/Stats/AgentPerformance/JobPerformance'
import AgentPerformanceDetails from './common/components/Stats/AgentPerformance/AgentDetail';
import AgentPostsDetails from './common/components/Stats/AgentPerformance/PostsDetails';
import { BrokerRawDataList } from './common/components/Stats/BrokerRawData';
import { JobDetailRawDataList } from './common/components/Stats/JobDetailRawData'
import { AgentRawDataList } from './common/components/Stats/AgentRawData'
import { WalletManagementList } from './common/components/Stats/WalletManagement'
import { PayoutList } from './common/components/Stats/Payout'
import Reports from './common/components/Stats/Reports';
import AppReview from './common/components/Stats/AppReview';
import GiftCardRawData from './common/components/Stats/GiftCardRawData'
import VendorRawData from './common/components/Stats/VendorRawData';
import ReferralRawData from './common/components/Stats/ReferralRawData';
// LIST BROKERS
import Brokers from './common/components/Broker/ListContainer';
import EditBroker from './common/components/Broker/ProfileBroker';
import CreateBrokerageBroker from './common/components/Broker/CreateBrokerage';
import PaymentsHistory from './common/components/Broker/PaymentsHistory';
// MANAGER
import Admin from './common/components/Admin/AdminListContainer';
import CreateAdmin from './common/components/Admin/CreateAdminContainer';
// FRANCHISES
import Franchises from "./common/components/Franchises";
import CreateFranchise from "./common/components/Franchises/CreateFranchise";
//  CAMPAIGNS
import {CampaignList, CreateCampaign} from './common/components/Twilio'
import {TwilioAppVersionList, TwilioCreateUpdateAppVersion} from './common/components/Twilio/AppVersion'
//  VIDEO TUTORIAL
import {TutorialVideosList, TutorialVideoCreateUpdate} from './common/components/TutorialVideo'
//  SENDGRID CAMPAIGNS
import {SendgridCampaignList, CreateUpdateSendgridCampaign} from './common/components/Sendgrid'
import {SendgridCustomCampaignList, CreateUpdateSendgridCustomCampaign} from './common/components/Sendgrid/Campaign'
//  DELETED
import {DeletedAgentList, DeletedAgentReinstate} from './common/components/Deleted/DeletedAgents'
import {DeletedOfficeList, DeleteOfficeReinstate} from './common/components/Deleted/DeletedOffice'
import {DeletedBrokerList, DeleteBrokerReinstate} from './common/components/Deleted/DeletedBroker'
//  SETTING
import Setting from './common/components/Setting'
//  PROMOTION
import {PromotionList, PromotionCreateUpdate} from './common/components/Promotion'

//  MAP AGENTS AND JOBS
import {MapAgentsJobs} from './common/components/AgentsJobs'

//  NOTIFICATIONS
import {NotificationList, UpdateNotification} from './common/components/Notification'
//  CANCELLATION OR AGENT DELETE OPTION
import {CancellationDeleteOptionList, CreateUpdateCancellationDeleteOption} from './common/components/CancellationDeleteOption'
//  LEGAL
import {LegalList, CreateUpdateLegal} from './common/components/Legal'
//  VENDOR MODULE
import {VendorTypeList, CreateUpdateVendorType} from './common/components/Vendor/VendorType'
import {VendorClientFeedbackList, CreateUpdateVendorClientFeedback} from './common/components/Vendor/VendorClientFeedback'
import VendorSetting from './common/components/Vendor/VendorSetting'
//  VENDORS
import {VendorsList, VendorProfile, VendorProfessions} from './common/components/Vendor/Vendors'
//  EDIT TEXTS
import {WalletText, CompletingJobOffline, Leads, TipsGuidelines, FeesStructure, OpenHouseText, CounterOffer, GiftCardText, VendorText, ShowingText, ReferText, HtmlDemo} from './common/components/EditTexts'
//  TRACKER
import {TrackerFieldList, CreateUpdateTrackerField, TrackerList} from './common/components/Tracker'
//  GIF CARD DESIGN
import { GiftCardDesignList, GiftCardDesignCreateUpdate, GiftCardStripe } from './common/components/GiftCard';
import BrokerReferral from "./common/components/BrokerReferral";

// STYLES
require('../assets/fonts/fonts.css');
require('../style/index.css');


module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/register" component={Register}/>
                {/*<ProtectedRoute exact path="/" component={Demo}/>*/}
                {/* Agents */}
                {/* Dashboard */}
                <ProtectedRoute exact path="/" component={Dashboard}/>
                <ProtectedRoute exact path="/agents" component={Agents}/>
                <ProtectedRoute exact path="/agents/agent/:id/edit/" component={UpdateAgent}/>
                <ProtectedRoute exact path="/agents/agent-details/:id/" component={UpdateAgent}/>
                <ProtectedRoute exact path="/agents/:brokerage_id/create-agent-roster" component={CreateAgentRoster}/>
                <ProtectedRoute exact path="/agents/agent-roster/:id/edit" component={CreateAgentRoster}/>
                <ProtectedRoute exact path="/agents/agent/:id/edit/changed-password" component={ChangedPassword}/>
                <ProtectedRoute exact path="/agents/agent/:id/lock-notes" component={AgentLockNote}/>
                <ProtectedRoute exact path="/agents/agent/:id/pending-notes" component={AgentPendingNote}/>
                <ProtectedRoute exact path="/agents/agent-reviews/:id" component={AgentReviewList}/>
                <ProtectedRoute exact path="/agents/agent-reviews/:agent_id/:id/edit" component={AgentReviewEdit}/>
                {/* Wholesaler */}
                <ProtectedRoute exact path="/wholesalers" component={WholeSalerList}/>
                <ProtectedRoute exact path="/wholesalers/:id/lock-notes" component={AgentLockNote}/>
                <ProtectedRoute exact path="/wholesalers/:id/pending-notes" component={AgentPendingNote}/>
                <ProtectedRoute exact path="/wholesalers/:id/edit" component={WholeSalerUpdate}/>
                <ProtectedRoute exact path="/wholesalers/:type/:id/edit/changed-password" component={ChangedPassword}/>
                {/* Stats */}
                <ProtectedRoute exact path="/stats/revenue" component={Stats}/>
                <ProtectedRoute exact path="/stats/agent-performance" component={AgentPerformance}/>
                <ProtectedRoute exact path="/stats/agent-performance/:id/details" component={AgentPerformanceDetails}/>
                <ProtectedRoute exact path="/stats/agent-performance/:id/posts-details" component={AgentPostsDetails}/>
                <ProtectedRoute exact path="/stats/broker-raw-data" component={BrokerRawDataList} />
                <ProtectedRoute exact path="/stats/job-detail-raw-data" component={JobDetailRawDataList} />
                <ProtectedRoute exact path="/stats/agent-raw-data" component={AgentRawDataList} />
                <ProtectedRoute exact path="/stats/wallet-management" component={WalletManagementList} />
                <ProtectedRoute exact path="/stats/payout" component={PayoutList} />
                <ProtectedRoute exact path="/stats/reports" component={Reports} />
                <ProtectedRoute exact path="/stats/app-review" component={AppReview} />
                <ProtectedRoute exact path="/stats/gift-card-raw-data" component={GiftCardRawData} />
                <ProtectedRoute exact path="/stats/vendor-raw-data" component={VendorRawData} />
                <ProtectedRoute exact path="/stats/referral-raw-data" component={ReferralRawData} />
                {/* Franchises CRUD*/}
                <ProtectedRoute exact path="/franchises" component={Franchises}/>
                <ProtectedRoute exact path="/franchises/create" component={CreateFranchise}/>
                <ProtectedRoute exact path="/franchises/:id" component={CreateFranchise}/>
                <ProtectedRoute exact path="/franchises/:id/edit" component={CreateFranchise}/>
                {/* Broker list and offices */}
                <ProtectedRoute exact path="/brokers" component={Brokers}/>
                <ProtectedRoute exact path="/brokers/broker/:id/" component={EditBroker}/>
                <ProtectedRoute exact path="/brokers/broker/:id/edit/" component={EditBroker}/>
                <ProtectedRoute exact path="/brokers/:type/:id/changed-password" component={ChangedPassword}/>
                <ProtectedRoute exact path="/brokers/:broker_id/new-office" component={CreateBrokerageBroker}/>
                <ProtectedRoute exact path="/brokers/:id/payments-history" component={PaymentsHistory}/>
                {/*<ProtectedRoute exact path="/brokers/broker/:id/brokerages/create-brokerage/" component={CreateBrokerage} />*/}
                {/*<ProtectedRoute exact path="/brokers/broker/:id/brokerages/brokerages/:id/" component={BrokerageDetail} />*/}
                {/*<ProtectedRoute exact path="/brokers/broker/:id/brokerages/brokerages/:id/edit" component={CreateBrokerage} />*/}
                {/* All offices */}
                <ProtectedRoute exact path="/offices" component={Brokerage}/>
                <ProtectedRoute exact path="/offices/:id/edit" component={BrokerageDetail}/>
                <ProtectedRoute exact path="/offices/:company_id/users/invite" component={Invite}/>
                <ProtectedRoute exact path="/offices/:company_id/users/:id/edit" component={Invite}/>
                <ProtectedRoute exact path="/offices/users/:id/change-password" component={OfficeUserPassword}/>
                {/* Influencer */}
                <ProtectedRoute exact path="/influencers" component={InfluencerList}/>
                <ProtectedRoute exact path="/influencers/create" component={InfluencerForm}/>
                <ProtectedRoute exact path="/influencers/:id/edit" component={InfluencerForm}/>
                <ProtectedRoute exact path="/influencers/:id" component={InfluencerProfile}/>
                <ProtectedRoute exact path="/influencers/:type/:id/changed-password" component={ChangedPassword}/>
                {/* Influencer Team */}
                <ProtectedRoute exact path="/influencer-teams" component={InfluencerTeamList}/>
                <ProtectedRoute exact path="/influencer-teams/:id/edit" component={InfluencerTeamProfile}/>
                <ProtectedRoute exact path="/influencer-teams/:team_id/contact/create" component={InfluencerTeamContact}/>
                <ProtectedRoute exact path="/influencer-teams/:team_id/contact/:id/edit" component={InfluencerTeamContact}/>
                {/* Manager */}
                <ProtectedRoute exact path="/manager" component={Admin}/>
                <ProtectedRoute exact path="/manager/create-manager" component={CreateAdmin}/>
                <ProtectedRoute exact path="/manager/:id/" component={CreateAdmin}/>
                <ProtectedRoute exact path="/manager/:id/edit" component={CreateAdmin}/>
                <ProtectedRoute exact path="/user-profile" component={Profile}/>
                {/* Profile */}
                <ProtectedRoute exact path="/edit-user-profile" component={Profile}/>
                {/* Examples */}
                <ProtectedRoute exact path="/grids" component={Grids}/>
                <ProtectedRoute exact path="/notifications" component={Notifications}/>
                <ProtectedRoute exact path="/tabs" component={ExampleTabs}/>
                {/* Job type */}
                <ProtectedRoute exact path="/job-types" component={JobTypeList}/>
                <ProtectedRoute exact path="/job-types/create" component={JobTypeCreate}/>
                <ProtectedRoute exact path="/job-types/:id/edit" component={JobTypeEdit}/>
                <ProtectedRoute exact path="/job-types/:id" component={JobTypeView}/>
                {/* Feedback Form Agent */}
                <ProtectedRoute exact path="/feedback-form-agent" component={FeedbackFormAgentList}/>
                <ProtectedRoute exact path="/feedback-form-agent/create" component={FeedbackFormAgentCreate}/>
                <ProtectedRoute exact path="/feedback-form-agent/:id/edit" component={FeedbackFormAgentEdit}/>
                <ProtectedRoute exact path="/feedback-form-agent/:id" component={FeedbackFormAgentView}/>
                {/* Feedback Form Customer */}
                <ProtectedRoute exact path="/feedback-form-customer" component={FeedbackFormCustomerList}/>
                <ProtectedRoute exact path="/feedback-form-customer/create" component={FeedbackFormCustomerCreate}/>
                <ProtectedRoute exact path="/feedback-form-customer/:id/edit" component={FeedbackFormCustomerEdit}/>
                <ProtectedRoute exact path="/feedback-form-customer/:id" component={FeedbackFormCustomerView}/>
                {/* Feedback Form Client */}
                <ProtectedRoute exact path="/feedback-form-client" component={FeedbackFormClientList}/>
                <ProtectedRoute exact path="/feedback-form-client/create" component={FeedbackFormClientCreateUpdate}/>
                <ProtectedRoute exact path="/feedback-form-client/:id/edit" component={FeedbackFormClientCreateUpdate}/>
                {/* Campaigns */}
                <ProtectedRoute exact path="/campaigns" component={CampaignList}/>
                <ProtectedRoute exact path="/campaigns/create" component={CreateCampaign}/>
                <ProtectedRoute exact path="/campaigns/:id/edit" component={CreateCampaign}/>
                <ProtectedRoute exact path="/campaigns/:id" component={CreateCampaign}/>
                {/* Twilio AppVersion */}
                <ProtectedRoute exact path="/campaigns_app_version" component={TwilioAppVersionList}/>
                <ProtectedRoute exact path="/campaigns_app_version/create" component={TwilioCreateUpdateAppVersion}/>
                <ProtectedRoute exact path="/campaigns_app_version/:id/edit" component={TwilioCreateUpdateAppVersion}/>
                <ProtectedRoute exact path="/campaigns_app_version/:id" component={TwilioCreateUpdateAppVersion}/>
                {/* Tutorial Videos */}
                <ProtectedRoute exact path="/tutorial-videos" component={TutorialVideosList} />
                <ProtectedRoute exact path="/tutorial-videos/create" component={TutorialVideoCreateUpdate}/>
                <ProtectedRoute exact path="/tutorial-videos/:id/edit" component={TutorialVideoCreateUpdate}/>
                {/* Sendgrid Campaigns */}
                <ProtectedRoute exact path="/sendgrid-campaigns" component={SendgridCampaignList}/>
                <ProtectedRoute exact path="/sendgrid-campaigns/create" component={CreateUpdateSendgridCampaign}/>
                <ProtectedRoute exact path="/sendgrid-campaigns/:id/edit" component={CreateUpdateSendgridCampaign}/>
                <ProtectedRoute exact path="/sendgrid-campaigns/:id" component={CreateUpdateSendgridCampaign}/>
                {/* Custom Campaigns */}
                <ProtectedRoute exact path="/sendgrid-custom-campaigns" component={SendgridCustomCampaignList}/>
                <ProtectedRoute exact path="/sendgrid-custom-campaigns/create" component={CreateUpdateSendgridCustomCampaign}/>
                <ProtectedRoute exact path="/sendgrid-custom-campaigns/:id/edit" component={CreateUpdateSendgridCustomCampaign}/>
                <ProtectedRoute exact path="/sendgrid-custom-campaigns/:id" component={CreateUpdateSendgridCustomCampaign}/>
                {/* MAP AGENTS AND JOBS */}
                <ProtectedRoute exact path="/agents-jobs" component={MapAgentsJobs}/>
                {/* DELETED */}
                <ProtectedRoute exact path="/deleted-agents" component={DeletedAgentList}/>
                <ProtectedRoute exact path="/deleted-agents/:id/reinstate" component={DeletedAgentReinstate}/>
                {/* DELETED OFFICE */}
                <ProtectedRoute exact path="/deleted-offices" component={DeletedOfficeList}/>
                <ProtectedRoute exact path="/deleted-offices/:id/reinstate" component={DeleteOfficeReinstate}/>
                {/* DELETED BROKER */}
                <ProtectedRoute exact path="/deleted-brokers" component={DeletedBrokerList}/>
                <ProtectedRoute exact path="/deleted-brokers/:id/reinstate" component={DeleteBrokerReinstate}/>
                {/* SETTING */}
                <ProtectedRoute exact path="/settings" component={Setting}/>
                {/* PROMOTION */}
                <ProtectedRoute exact path="/promotions" component={PromotionList}/>
                <ProtectedRoute exact path="/promotions/create" component={PromotionCreateUpdate}/>
                <ProtectedRoute exact path="/promotions/:id/edit" component={PromotionCreateUpdate}/>

                {/* NOTIFICATIONS */}
                <ProtectedRoute exact path="/app_notifications" component={NotificationList}/>
                <ProtectedRoute exact path="/app_notifications/:id/edit" component={UpdateNotification}/>
                {/* CANCELLATION OR AGENT DELETE OPTION */}
                <ProtectedRoute exact path="/app_cancellation_delete_option" component={CancellationDeleteOptionList}/>
                <ProtectedRoute exact path="/app_cancellation_delete_option/create" component={CreateUpdateCancellationDeleteOption}/>
                <ProtectedRoute exact path="/app_cancellation_delete_option/:id/edit" component={CreateUpdateCancellationDeleteOption}/>
                {/* Tutorial Videos */}
                <ProtectedRoute exact path="/legal-link" component={LegalList} />
                <ProtectedRoute exact path="/legal-link/create" component={CreateUpdateLegal}/>
                <ProtectedRoute exact path="/legal-link/:id/edit" component={CreateUpdateLegal}/>
                {/* Vendor */}
                {/* Vendor List */}
                <ProtectedRoute exact path="/job-type-vendor" component={VendorTypeList} />
                <ProtectedRoute exact path="/job-type-vendor/create" component={CreateUpdateVendorType} />
                <ProtectedRoute exact path="/job-type-vendor/:id/edit" component={CreateUpdateVendorType} />
                {/* Vendor Settings */}
                <ProtectedRoute exact path="/vendor/settings" component={VendorSetting} />
                {/* Client Vendor Feedback */}
                {/* <ProtectedRoute exact path="/vendor-client-feedback" component={VendorClientFeedbackList} /> */}
                <ProtectedRoute exact path="/vendor/client-feedback/create" component={CreateUpdateVendorClientFeedback} />
                <ProtectedRoute exact path="/vendor/client-feedback/:id/edit" component={CreateUpdateVendorClientFeedback} />
                {/* VENDORS */}
                <ProtectedRoute exact path="/vendor/vendors" component={VendorsList} />
                <ProtectedRoute exact path="/vendor/vendors/:id/edit" component={VendorProfile} />
                <ProtectedRoute exact path="/vendor/vendors/:id/experties" component={VendorProfessions} />
                <ProtectedRoute exact path="/vendor/:type/:id/changed-password" component={ChangedPassword}/>
                {/* EDIT TEXTS */}
                <ProtectedRoute exact path="/edit-texts/wallet" component={WalletText} />
                <ProtectedRoute exact path="/edit-texts/completing-job-offline" component={CompletingJobOffline} />
                <ProtectedRoute exact path="/edit-texts/leads" component={Leads} />
                <ProtectedRoute exact path="/edit-texts/tips-guidelines" component={TipsGuidelines} />
                <ProtectedRoute exact path="/edit-texts/fees-structures" component={FeesStructure} />
                <ProtectedRoute exact path="/edit-texts/open-house-text" component={OpenHouseText} />
                <ProtectedRoute exact path="/edit-texts/counter-offer" component={CounterOffer} />
                <ProtectedRoute exact path="/edit-texts/gift-card" component={GiftCardText} />
                <ProtectedRoute exact path="/edit-texts/vendor" component={VendorText} />
                <ProtectedRoute exact path="/edit-texts/showing" component={ShowingText} />
                <ProtectedRoute exact path="/edit-texts/refer" component={ReferText} />
                <ProtectedRoute exact path="/edit-texts/demo" component={HtmlDemo} />
                {/* Tracker */}
                <ProtectedRoute exact path="/tracker-field" component={TrackerFieldList} />
                <ProtectedRoute exact path="/tracker-field/create" component={CreateUpdateTrackerField}/>
                <ProtectedRoute exact path="/tracker-field/:id/edit" component={CreateUpdateTrackerField}/>
                <ProtectedRoute exact path="/tracker" component={TrackerList} />
                {/* Gif Card Design */}
                <ProtectedRoute exact path="/gift-card/designs" component={GiftCardDesignList} />
                <ProtectedRoute exact path="/gift-card/designs/create" component={GiftCardDesignCreateUpdate} />
                <ProtectedRoute exact path="/gift-card/designs/:id/edit" component={GiftCardDesignCreateUpdate} />
                <ProtectedRoute exact path="/gift-card/stripe-account" component={GiftCardStripe} />
                {/* Broker Referral */}
                <ProtectedRoute exact path="/broker-referral/:id" component={BrokerReferral} />
                {/* Not Found */}
                <Route component={NotFound}/>
            </Switch>
        </div>
        <NotificationContainer/>
    </div>
);
