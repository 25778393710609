import React from "react";
import { Field, reduxForm } from 'redux-form';
import { renderField, customFieldFile } from '../Utils/renderField';
import { validate, validators } from 'validate-redux-form';
import { renderFilePicker } from "../Utils/renderField/renderField";

const brokerageForm = (props) => {

    const {
        handleSubmit,
        is_update,
        goBack,
        item,
        setFiles,
        text,
        handleOnChange,
        active,
        setActive,
        new_file,
        setNewFileButton
    } = props;
    return (

        <form name="brokerageForm" className="my-4" style={{ minHeight: "20rem" }} onSubmit={handleSubmit}>
            <div className="row w-75 px-lg-5 mx-auto flex-1">
                <div className="form-group has-feedback col-6 ml-n5">
                    <label htmlFor="logo" className="my-0 py-0 pl-4 ml-3 pl-4 mb-2">  &nbsp;&nbsp;Logo</label>
                    <Field
                        name="logo"
                        photo={item && item.logo ? item.logo : require("../../../../assets/img/uploadLogo.PNG")}
                        className="w-100"
                        component={renderFilePicker}
                        setFile={setFiles}
                        styles={{marginLeft: '2.9rem'}}
                    />
                </div>
            </div>

            <div className="row w-75 px-lg-5 mx-auto flex-1">
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="firm_name">Office name</label>
                    <Field
                        name="firm_name"
                        label="Firm name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="license">Brokerage License Number</label>
                    <Field
                        name="license"
                        label="License"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={is_update}
                    />
                </div>
            </div>


            <div className="row w-75 px-lg-5 mx-auto">
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="city">City</label>
                    <Field
                        name="city"
                        label="City"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                     <label htmlFor="telephone">Telephone</label>
                    <Field
                        name="telephone"
                        label="telephone"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row w-75 px-lg-5 mx-auto">
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="address">Address</label>
                    <Field
                        name="address"
                        label="Address"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                     <label htmlFor="address_line2">Address Line 2</label>
                    <Field
                        name="address_line2"
                        label="Address line 2"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row w-75 px-lg-5 mx-auto flex-1">
                <div className="form-group has-feedback col-lg-6 col-sm-12">
                    <label htmlFor="excel-office">Office W9</label>
                    {(item !== {} && item.office_w9 !== null) && new_file === false?
                        <div className="form-group has-feedback">
                            <a
                                className="btn button-02 px-4"
                                href={item.office_w9}
                                download
                            >
                                Download File
                            </a>
                            <img
                                title="Add new file"
                                src={require('../../../../assets/img/edit.svg')}
                                alt='delete'
                                className={"mt-2 ml-2"}
                                style={{
                                    width: "25px",
                                    cursor: "pointer",
                                    display: "inline-block"
                                }}
                                onClick={() => setNewFileButton()}
                            />
                        </div>
                        :
                        <div style={{height: "25px"}}>
                            <Field
                                id={'office_excel'}
                                name="excel-office"
                                setFile={handleOnChange}
                                component={customFieldFile}
                                title={'Import File'}
                                text={text}
                                customClass="button-02 col-6 py-1"
                                type={"file"}
                                active={active}
                                accept={"*"}
                                remove={() => setActive(true)}
                            />
                        </div>
                    }
                </div>
            </div>

            <div className="row mx-auto mt-4 mb-0 justify-content-center form-group has-feedback">
                <button type="button" className={`btn button-03 mx-3 col-lg-2 col-sm-6`} onClick={goBack}>
                     Cancel
                </button>
                <button type="submit" className="btn button-01 mx-3 col-lg-2 col-sm-6">
                    {!is_update ? "Create" : "Update"}
                </button>

            </div>
        </form>

    );
};


export default reduxForm({
    form: 'brokerageForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            firm_name: validators.exists()('This field is required'),
            license: validators.exists()('This field is required'),
            address: validators.exists()('This field is required'),
            office_w9: validators.exists()('This field is required'),
            logo: validators.exists()('This field is required'),
            city: validators.exists()('This field is required'),
            telephone: validators.exists()('This field is required'),
        });
    },
})(brokerageForm);
