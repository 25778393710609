import React, { Component } from "react";
import Map from "./Map";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import MapAgentsJobsStats from "./MapAgentsJobsStats"
import AgentsJobsFiltersForm from "./AgentsJobsFiltersForm";
import "./map_agents_jobs.css";

export class MapAgentsJob extends Component {
    state = {
        mapHeight: 200,
    };

    componentDidMount() {
        const { toList, getAgentsData, getFranchisesOption, getBrokerOption, getJobTypesOption, getDataRevenueAgents } = this.props;
        const _heigth = document.getElementById("AGENTS-JOB-MAP").clientHeight;
        this.setState({ mapHeight: _heigth });
        toList();
        // getAgentsData();
        getDataRevenueAgents()
        getJobTypesOption(true);
        getFranchisesOption(null, true)
        getBrokerOption(null, true)
        this.agentsJobInterval = setInterval(this.refreshData, 60000)
    }

    refreshData = () => {
        const {toList, getAgentsData, getDataRevenueAgents} = this.props;
        toList(false)
        getAgentsData(false)
        getDataRevenueAgents()
    }

    componentWillUnmount() {
        if (this.agentsJobInterval) {
            clearInterval(this.agentsJobInterval)
        }
    }

    render() {
        //  State
        const { data, loader, agents, brokers, franchises, brokerages, jobTypes, dataRevenueAgents } = this.props;
        const { mapHeight } = this.state;
        //  Bind
        const {changeFilters} = this.props;

        return (
            <div className="flex-1 d-flex flex-column pb-2">
                <div className="page-header pl-1 pt-3 no-gutters row pb-3">
                    <div className="flex-1 d-flex flex-lg-row flex-column">
                        <div className="header-page w-auto h-auto mr-4">Agents & Jobs</div>
                        {dataRevenueAgents && (
                            <MapAgentsJobsStats dataRevenueAgents={dataRevenueAgents}/>
                        )}
                    </div>
                </div>
                <AgentsJobsFiltersForm
                    optionFranchises={franchises}
                    optionBrokers={brokers}
                    optionBrokerages={brokerages}
                    optionJobTypes={jobTypes}
                    changeFilters={changeFilters}/>
                <LoadMask loading={loader} light>
                    <div className="card border-dark border-card-page flex-1">
                        <div
                            id="AGENTS-JOB-MAP"
                            className="card-body px-0 py-0 d-flex flex-column"
                        >
                            <div
                                className="d-block w-100"
                                style={{ height: `${mapHeight}px` }}
                            >
                                <Map
                                    jobs={
                                        data && data.results ? data.results : []
                                    }
                                    agents={
                                        agents && agents.results
                                            ? agents.results
                                            : []
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </LoadMask>
            </div>
        );
    }
}

export default MapAgentsJob;
