import moment from 'moment'
import _ from 'lodash'
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {api} from '../../../../utility/api';
import React, {useState, useEffect} from 'react';
import ProfileMap from '../../Utils/Map/profileMap';
import ReactStars from "react-rating-stars-component";
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderFilePicker} from "../../Utils/renderField/renderField";
import {REGISTERED_RE_ASSIGN} from '../../../../utility/constants'
import {renderField, renderNumber, renderSwitch, renderTextArea, renderAddressField, SelectField} from '../../Utils/renderField';

const AgentRating = (props) => {
    const { rating } = props;
    const [starKeyForce, setStarKeyForce] = useState(0)

    useEffect(() => {
        setStarKeyForce(prev => prev + 1)
    }, [rating])


    return (
        <ReactStars
            size={28}
            count={5}
            value={rating}
            edit={false}
            isHalf={true}
            key={starKeyForce}
            onChange={() => console.log('')}
        />
    )
}

const validate = (values, props) => {
    const {optionBrokers, pendingAgent} = props;
    let errors = {};
    if (!values.first_name) {
        errors.first_name = "This field is required"
    }
    if (!values.last_name) {
        errors.last_name = "This field is required"
    }
    if (!values.email) {
        errors.email = "This field is required"
    }
    if (!values.mobile_number) {
        errors.mobile_number = "This field is required"
    }else {
        if (values.mobile_number.length != 10){
            errors.mobile_number = "Invalid phone number"
        }
    }

    if (!values.street && !pendingAgent) {
        errors.street = "This field is required"
    }
    if (!values.miles && !pendingAgent) {
        errors.miles = "This field is required"
    }
    if (!values.broker_id) {
        errors.broker_id = "This field is required"
    } else {
        const broker = _.find(optionBrokers, {value: values.broker_id})
        if (broker && broker.is_temporary) {
            if (!values.open_market_office) {
                errors.open_market_office = "This field is required"
            }
            if (!values.open_market_office_franchise) {
                errors.open_market_office_franchise = "This field is required"
            }
        }
    }
    if (!values.brokerage_id) {
        errors.brokerage_id = "This field is required"
    }
    
    return errors;
}

const UpdateForm = (props) => {

    const {
        handleSubmit,
        is_view,
        is_update,
        item,
        setAvatar,
        center,
        milesValue,
        broker_idValue,
        brokerage_idValue,
        setWorkingArea,
        pendingAgent,
        remove,
        franchises=[],
        optionBrokers=[],
        optionBrokerages=[],
        teams=[],
        getBrokerageFormOption,
        blockedValue,
        goBack,
        goPage,
        moveToOpenMarketOffice,
        team_idValue,
    } = props;

    const [hasMap, setHasMap] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [miles, setMiles] = useState(0);
    const [brokerInfo, setBrokerInfo] = useState(null);
    const [brokerageInfo, setBrokerageInfo] = useState(null);
    const [teamInfo, setTeamInfo] = useState(null);

    useEffect(() => {
        const _intervalId = setInterval(() => {
            // console.log("INTERVAL");
            if (window.google && window.google.maps && !hasMap) {
                setHasMap(true);
            }
        }, 300);

        setIntervalId(_intervalId)

        return () => {
            clearInterval(_intervalId);
        }
    }, [])

    useEffect(() => {
        if (hasMap == true && intervalId != null) {
            clearInterval(intervalId)
        }
    }, [hasMap])

    useEffect(() => {
        if (milesValue != "" && milesValue != null && milesValue != undefined)
        {
            setMiles(parseFloat(milesValue))
        }else {
            setMiles(0)
        }
    }, [milesValue])

    useEffect(() => {
        if (broker_idValue != "" && broker_idValue != null && broker_idValue != null)
        {
            api.get(`broker/${broker_idValue}`).then(response => {
                setBrokerInfo(response);
                getBrokerageFormOption(broker_idValue);
            }).catch(error => {
                setBrokerInfo(null);
            })
        } else {
            setBrokerInfo(null)
            getBrokerageFormOption(null);
        }
    }, [broker_idValue])

    useEffect(() => {
        if (team_idValue != "" && team_idValue != null && team_idValue != null)
        {
            api.get(`influencer_team/${team_idValue}`, {from_agent: true}).then(response => {
                setTeamInfo(response);
            }).catch(error => {
                setTeamInfo(null);
            })
        } else {
            setTeamInfo(null)
            getBrokerageFormOption(null);
        }
    }, [team_idValue])
    
    useEffect(() => {
        if (brokerage_idValue != "" && brokerage_idValue != null && brokerage_idValue != null)
        {
            api.get(`brokerage/${brokerage_idValue}`).then(response => {
                setBrokerageInfo(response);
            }).catch(error => {
                setBrokerageInfo(null);
            })
        } else {
            setBrokerageInfo(null)
        }
    }, [brokerage_idValue])

    return (

        <form name="updateAgentForm" className="my-1 col-sm-12 p-sm-0" style={{minHeight: "20rem"}}
              onSubmit={handleSubmit}>
            <div className="row mx-auto pt-2">
                <div className='col-xl-3 col-lg-3 col-md-5 col-sm-12'>
                    <div className="form-group has-feedback">
                        {/* <label htmlFor="photo">Photo</label> */}
                        <Field
                            photo={item.photo ? item.photo : null}
                            setFile={setAvatar}
                            name="photo"
                            component={renderFilePicker}
                            disabled={is_view || pendingAgent}
                            className="avatar-round"
                        />
                    </div>
                    <div className="col-sm-12 d-flex flex-column align-items-center px-0 mb-4">
                        <div className='d-flex align-items-center justify-content-start'>
                            <AgentRating rating={item.rating >= 0 && item.rating}/>
                        </div>
                        <span className="font-weight-bolder h4 mb-0" style={{color: '#F7D701'}}>{item.rating >= 0 ? item.rating: 0}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Sign-up Date</span>
                        <span className="font-weight-bolder text-primary">{moment(item.date_joined).format('ll')}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-5'>
                        <span className="font-weight-bolder h6 mb-1">Status</span>
                        {item.blocked === true ? (
                            <span className={`text-danger font-weight-bold`}>
                            Blocked
                            </span>
                        ): (
                            item.locked === true ? (
                                <span className={`text-danger font-weight-bold`}>
                                    Locked
                                </span>
                            ) : (
                                <React.Fragment>
                                    {item.agent_account_status == 10 && (
                                        <span className="text-warning font-weight-bold">Pending</span>
                                    )}
                                    {item.agent_account_status == 20 && (
                                        <span className="text-success font-weight-bold">Active</span>
                                    )}
                                </React.Fragment>
                            )

                        )}
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mt-3 mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Job Performed</span>
                        <span className="font-weight-bolder text-primary">{item.total_performed}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Job Posted</span>
                        <span className="font-weight-bolder text-primary">{item.total_posted}</span>
                    </div>
                    <div className='col-sm-12 text-center px-0 d-flex flex-column mb-3'>
                        <span className="font-weight-bolder h6 mb-1">Job Reviews</span>
                        <span className="font-weight-bolder text-primary pointer" onClick={() => goPage(`/agents/agent-reviews/${item.id}`)}>{item.total_reviews}</span>
                    </div>
                </div>
                <div className='col-xl-9 col-lg-9 col-md-7 col-sm-12'>
                    <div className='row'>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="first_name">First name</label>
                            <Field
                                name="first_name"
                                label="First name"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="last_name">Last Name</label>
                            <Field
                                name="last_name"
                                label="Last Name"
                                type="text"
                                component={renderField}
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="mobile_number">Mobile number</label>
                            <Field
                                disabled={is_view}
                                name="mobile_number"
                                component={renderNumber}
                                className="form-control"
                                placeholder="mobile number"
                                numberFormat={"###-###-####"}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="email">Email</label>
                            <Field
                                name="email"
                                label="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="real_estate_licence">License number</label>
                            <Field
                                name="real_estate_licence"
                                label="real_estate_licence"
                                component={renderField}
                                type="text"
                                className="form-control"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="languages">Languages</label>
                            <Field
                                name="languages"
                                label="languages"
                                component={renderField}
                                type="text"
                                disabled={is_view}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="broker_id">Broker name</label>
                            <Field
                                name="broker_id"
                                isMulti={false}
                                placeholder="Select Broker"
                                options={optionBrokers}
                                // set_value={(option) => {
                                //     const value = option ? option.value : null
                                //     getBrokerageFormOption(value);
                                // }}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className={`form-group has-feedback ${brokerInfo && brokerInfo.is_temporary ? "col-lg-3": "col-lg-6"} col-md-12 col-sm-12`}>
                            <label htmlFor="brokerage_id">Office name</label>
                            <Field
                                name="brokerage_id"
                                isMulti={false}
                                placeholder="Select Office"
                                options={optionBrokerages}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        { brokerInfo && brokerInfo.is_temporary && (
                            <div className="form-group has-feedback col-lg-3 col-md-12 col-sm-12">
                                <label htmlFor="open_market_office">Office Name by Agent</label>
                                <Field
                                    name="open_market_office"
                                    label="open_market_office"
                                    component={renderField}
                                    type="text"
                                    disabled={is_view}
                                />
                            </div>
                        )}
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="open_market_office_franchise">Franchise</label>
                            { brokerInfo && brokerInfo.is_temporary ? (
                                <Field
                                    name="open_market_office_franchise"
                                    isMulti={false}
                                    placeholder="Select Franchise"
                                    options={franchises}
                                    component={SelectField}
                                    className="form-control"
                                />
                            )
                            : (
                                <div className='form-control'>
                                    {brokerInfo && brokerInfo.franchise && brokerInfo.franchise.name}
                                </div>
                            )}
                        </div>
                        { brokerInfo && brokerInfo.is_temporary ? (
                            <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="open_market_office_license">Office License by Agen</label>
                                <Field
                                    name="open_market_office_license"
                                    label="open_market_office_license"
                                    component={renderField}
                                    type="text"
                                    disabled={is_view}
                                />
                            </div>
                        ): (
                            <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                                <label htmlFor="languages">Office License number</label>
                                <div className='form-control'>
                                {brokerageInfo && brokerageInfo.license !== undefined && brokerageInfo.license}
                                </div>
                            </div>
                        )}

                        <div className={`form-group has-feedback col-lg-6 col-md-12 col-sm-12`}>
                            <label htmlFor="brokerage_id">Team</label>
                            <Field
                                name="team_id"
                                isMulti={false}
                                placeholder="Select Team"
                                options={teams}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="influencer">Influencer</label>
                            <div className='form-control'>
                                {teamInfo && teamInfo.influencer ? `${teamInfo.influencer.first_name} ${teamInfo.influencer.last_name}`: ''}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            <label htmlFor="about">About</label>
                            <Field
                                name="about"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                                disabled={is_view || pendingAgent}
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-md-12 col-sm-12">
                            {hasMap && (
                                <React.Fragment>
                                    <label htmlFor="about">Preferred working area</label>
                                    <Field
                                        name="street"
                                        label="street"
                                        placeholder='Search an address'
                                        component={renderAddressField}
                                        type="text"
                                        className="form-control"
                                        disabled={is_view}
                                        setCoords={(data)=> {
                                            setWorkingArea(data)
                                        }}
                                    />
                                </React.Fragment>
                            )}
                            <label className='mt-4' htmlFor="about">Radius around this address (miles)</label>
                            <div className='row'>
                                <div className="form-group has-feedback col-lg-5 col-md-6 col-sm-12">
                                    <Field
                                        name="miles"
                                        label="miles"
                                        placeholder='xx miles'
                                        component={renderNumber}
                                        type="text"
                                        className="form-control"
                                        disabled={is_view}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="form-group col-lg-6 col-md-12 col-sm-12 mb-0">
                            <label className="mt-n4 d-block" style={{ fontSize: '1.05rem' }}> Account Settings</label>
                            <div className="row w-100 m-0 h-100">
                                <div className='rounded border col-sm-12'>
                                    <div className='row'>
                                        <div className="form-group has-feedback col-sm-12">
                                            <label htmlFor="is_active" className="mt-4 d-block ml-3">Password</label>
                                            {(is_view || pendingAgent) ?
                                                <button
                                                    className="btn button-03 ml-3 px-4"
                                                    disabled={true}
                                                    style={{ hover: 'none' }}
                                                >
                                                    Change Password
                                                </button> :
                                                <Link
                                                    className="btn button-03 ml-3 px-4"
                                                    to={`/agents/agent/${item.id && item.id}/edit/changed-password`}
                                                    disabled={is_view}
                                                >
                                                    Change Password
                                                </Link>
                                            }
                                        </div>

                                        <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12 pl-4">
                                            <label htmlFor="locked" className="mt-4 mb-3">Lock account</label>
                                            <Field
                                                name="locked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view || pendingAgent || item.agent_account_status == REGISTERED_RE_ASSIGN}
                                            />
                                        </div>
                                        <div className="form-group has-feedback col-lg-6 col-md-6 col-sm-12 pl-4">
                                            <label htmlFor="blocked" className="mt-4 mb-3">Block account</label>
                                            <Field
                                                name="blocked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view || pendingAgent || item.agent_account_status == REGISTERED_RE_ASSIGN}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12" style={{ minHeight: '200px' }}>
                            <ProfileMap center={center} radius={miles} />
                        </div>
                    </div>
                    {/* ------------------------- SETTINGS ACCOUNT ------------------ */}
                    <div className='row'>
                        {/* <div className="form-group col-sm-12 mt-5">
                            <label className="mt-n4 d-block" style={{ fontSize: '1.05rem' }}> Account Settings</label>
                            <div className="row w-100 m-0">
                                <div className='rounded border col-sm-12'>
                                    <div className='row'>
                                        <div className="form-group has-feedback col-lg-4 col-md-6 col-sm-12">
                                            <label htmlFor="is_active" className="mt-4 d-block ml-3">Password</label>
                                            {is_view ?
                                                <button
                                                    className="btn button-03 ml-3 px-4"
                                                    disabled={true}
                                                    style={{ hover: 'none' }}
                                                >
                                                    Change Password
                                                </button> :
                                                <Link
                                                    className="btn button-03 ml-3 px-4"
                                                    to={`/agents/agent/${item.id && item.id}/edit/changed-password`}
                                                    disabled={is_view}
                                                >
                                                    Change Password
                                                </Link>
                                            }
                                        </div>

                                        <div className="form-group has-feedback col-lg-4 col-md-6 col-sm-12 pl-4">
                                            <label htmlFor="locked" className="mt-4 mb-3">Lock account</label>
                                            <Field
                                                name="locked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view}
                                            />
                                        </div>
                                        <div className="form-group has-feedback col-lg-4 col-md-6 col-sm-12 pl-4">
                                            <label htmlFor="blocked" className="mt-4 mb-3">Block account</label>
                                            <Field
                                                name="blocked"
                                                label=""
                                                component={renderSwitch}
                                                type="checked"
                                                disabled={is_view}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            
            <div className="row mx-auto mt-5 justify-content-center align-items-center form-group has-feedback">
                <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                    <button type="button" onClick={goBack} className={`w-100 btn text-white ${is_view ? "button-03" : "btn-secondary"}`}>
                        Back
                    </button>
                </div>
                {!pendingAgent ? (
                    item.agent_account_status == REGISTERED_RE_ASSIGN ? (
                        <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                            <button type="submit" className="w-100 btn btn-primary">
                                Re-Assign
                            </button>
                        </div>
                    ): (
                        <React.Fragment>
                            {!is_view && is_update &&
                            <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                                <button type="submit" className="w-100 btn btn-primary">
                                    Update
                                </button>
                            </div>
                            }
                            {!is_view && is_update && item && item.id &&
                            <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                                <button type="button" onClick={(e) => remove(item.id)} className="w-100 btn btn-danger">
                                    Delete
                                </button>
                            </div>
                            }
                            {!is_view && is_update && item && item.id && brokerInfo && !brokerInfo.is_temporary &&
                                <div className='col-lg-2 col-md-4 col-sm-12 my-md-0 my-1'>
                                    <button type="button" onClick={(e) => moveToOpenMarketOffice(item.id)} className="w-100 btn btn-info">
                                        Move to Open market office
                                    </button>
                                </div>
                            }
                        </React.Fragment>
                    )
                ): (
                    <React.Fragment>
                        <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                            <button type="submit" className="w-100 btn btn-primary">
                                Activate
                            </button>
                        </div>
                        { is_update && item && item.id &&
                        <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                            <button type="button" onClick={(e) => remove(item.id)} className="w-100 btn btn-danger">
                                Delete
                            </button>
                        </div>
                        }
                    </React.Fragment>
                )}
            </div>
        </form>
    );
};

let UpdateAgentForm = reduxForm({
    form: 'updateAgentForm', // a unique identifier for this form
    validate
})(UpdateForm);

const selector = formValueSelector('updateAgentForm');

const mstp = state => {
    const milesValue = selector(state, 'miles');
    const broker_idValue = selector(state, 'broker_id');
    const brokerage_idValue = selector(state, 'brokerage_id');
    const team_idValue = selector(state, 'team_id');
    const blockedValue = selector(state, 'blocked')

    return {
        milesValue,
        broker_idValue,
        brokerage_idValue,
        blockedValue,
        team_idValue,
    }
}

export default connect(mstp, null)(UpdateAgentForm);
