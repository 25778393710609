import { api } from "api";
import { push } from "react-router-redux";
import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from "redux-form";
import { createReducer } from "../baseReducer/baseReducer";

// ------------------------------------
// Constants
// ------------------------------------
const HAVE_STRIPE_ACCOUNT = 'HAVE_STRIPE_ACCOUNT_GIF_CARD_DESIGN';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT_GIF_CARD_DESIGN';
const BALANCE = 'BALANCE_GIF_CARD_DESIGN';
const endpoint = "gif_card_design";
const GIFT_CARD_CHARGE_DATA = 'GIFT_CARD_CHARGE_DATA_GIF_CARD_STRIPE';
const GIFT_CARD_CHARGE_PAGE = 'GIFT_CARD_CHARGE_PAGE_GIF_CARD_STRIPE';
const GIFT_CARD_CHARGE_LOADER = 'GIFT_CARD_CHARGE_LOADER_GIF_CARD_STRIPE';

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "gifCardDesign",
    endpoint,
    "gifCardDesignFrom",
    "/gift-card/designs",
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setHaveStripeAccount = (haveStripeAccount) => ({
    type: HAVE_STRIPE_ACCOUNT,
    haveStripeAccount,
});
const setStripeAccount = (stripeAccount) => ({
    type: STRIPE_ACCOUNT,
    stripeAccount,
});
const setBalance = (balance) => ({
    type: BALANCE,
    balance,
});

const setGiftCardChargeData = (giftCardChargeData) => ({
    type: GIFT_CARD_CHARGE_DATA,
    giftCardChargeData,
});
const setGiftCardChargePage = (giftCardChargePage) => ({
    type: GIFT_CARD_CHARGE_PAGE,
    giftCardChargePage,
});
const setGiftCardChargeLoader = (giftCardChargeLoader) => ({
    type: GIFT_CARD_CHARGE_LOADER,
    giftCardChargeLoader,
});

// ------------------------------------
// Actions
// ------------------------------------
const toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore();
    const resource = store.gifCardDesign;
    const { gifCardDesignFiltersForm } = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (gifCardDesignFiltersForm && gifCardDesignFiltersForm.values) {
        const { search } = gifCardDesignFiltersForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search;
        }
    }

    dispatch(basicReducer.actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(basicReducer.actions.setData(response));
        dispatch(basicReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(toList());
    }, 400);
};

const createGifCardDesign = (data, photos) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    if (photos.length) {
        api.postAttachments(endpoint, data, photos)
            .then((response) => {
                if (response) {
                    NotificationManager.success(
                        "Design successfully registered",
                        "Success",
                        3000
                    );
                    dispatch(push("/gift-card/designs"));
                }
            })
            .catch((error) => {
                const message = error
                    ? error.detail
                    : "It was not possible to create the design, try again later";
                NotificationManager.error(message, "ERROR", 3000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    } else {
        dispatch(basicReducer.actions.setLoader(false));
        NotificationManager.error("Image is required", "ERROR", 3000);
    }
};

const readGifCardDesign = id => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
            dispatch(initializeForm('gifCardDesignFrom', response));
        })
        .catch((error) => {
            console.error(error);
            dispatch(basicReducer.actions.setItem({}));
            NotificationManager.error('Data could not be obtained', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const updateGifCardDesign = (id, data, photos = []) => (dispatch, getStore) => {
    const source = getStore().gifCardDesign;
    dispatch(basicReducer.actions.setLoader(true));
    if (photos.length > 0) {
        api.putAttachments(`${endpoint}/${id}`, data, photos)
            .then((response) => {
                if (response) {
                    NotificationManager.success('Design successfully updated', 'Success', 3000);
                    dispatch(push("/gift-card/designs"));
                }
            })
            .catch((error) => {
                const message = error ? error.detail : 'It was not possible to update the design, try again later';
                NotificationManager.error(message, 'ERROR', 3000);
            })
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    } else {
        api.put(`${endpoint}/${id}`, data).then(() => {
            NotificationManager.success('Design successfully updated', 'Success', 3000);
            dispatch(push('/gift-card/designs'));
        }).catch((error) => {
            const message = error ? error.detail : 'It was not possible to update the design, try again later';
            NotificationManager.error(message, 'ERROR', 3000);
        }).finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
    }
};

const getSettings = () => {
    return new Promise((resolve, reject) => {
        api.get('general/setting').then((response) => {
            resolve({result: true, data: response})
        }).catch((error) => {
            reject({result: false, message: error})
        });
    })
}

/*
    Get Stripe Account
*/
const getGifCardStripeAccount = (openModalOnBoarding, openModalOnConfiguring=null) => async (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    dispatch(setHaveStripeAccount(false));
    dispatch(setStripeAccount({}));
    try {
        const settingsResponse = await getSettings();
        const {result: settingsReult} = settingsResponse;
        if (!settingsReult) {
            NotificationManager.error('Data could not be obtained', 'ERROR', 3000);
            dispatch(basicReducer.actions.setLoader(false));
            return;
        }
        const {data: settings} = settingsResponse;

        if (!settings.gif_card_account_id) {
            //  Create Stripe Account
            dispatch(basicReducer.actions.setLoader(false));
            return;
        } else {
            dispatch(setHaveStripeAccount(true));
            //  Get Stripe Account
            const accountResponse = await api.get(`general/gift_card_account_detail`);
            let validGetBalance = true;
            if (!accountResponse.tos_acceptance || !!(accountResponse.tos_acceptance && !accountResponse.tos_acceptance.date)) {
                openModalOnBoarding();
                validGetBalance = false;
            } else {
                if (openModalOnConfiguring && (!accountResponse.charges_enabled || !accountResponse.payouts_enabled)) {
                    validGetBalance = false;
                    openModalOnConfiguring();
                }
                // else {
                //     if (accountResponse.external_accounts && accountResponse.external_accounts.data) {
                //         const banks = accountResponse.external_accounts.data;
                //         if (banks.length > 0) {
                //             dispatch(setData(banks, BANK_ACCOUNT));
                //         }
                //     }
                // }
            }
            dispatch(setStripeAccount(accountResponse));
            //BANK_ACCOUNT
            if (validGetBalance) {
                dispatch(getBalance());
            }
        }
        dispatch(basicReducer.actions.setLoader(false));
    } catch (error) {
        dispatch(basicReducer.actions.setLoader(false));
        console.error(error);
        NotificationManager.error('Data could not be obtained', 'ERROR', 3000);
    }
}

const getBalance = () => (dispatch) => {
    api.get('general/balance_gift_card_account')
        .then(response => {
            dispatch(setBalance(response));
        })
        .catch((error) => {
            console.log('getBalance error -----------------------', error);
        })
};

const crateStripeAccounts = (openModalOnBoarding) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.put('general/create_gift_card_account', {})
        .then(response => {
            dispatch(getGifCardStripeAccount(openModalOnBoarding));
        })
        .catch((error) => {
            console.log('true error: ', error)
            NotificationManager.error('Error creating the stripe account', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const linkOnBoard = (setStateData) => (dispatch) => {
    try {
        let frontend_url = window.location.origin
        api.get('general/get_gift_card_onboard_link', { frontend_url })
            .then(response => {
                console.log('onboard_link: ', response);
                if (setStateData) {
                    setStateData(response.url);
                }
            })
            .catch((error) => {
                console.log("linkOnBoard ", error);
                NotificationManager.error('Failed to get account details', 'ERROR', 2000);
            });
    } catch (error) {
        NotificationManager.error('Failed to get account details', 'ERROR', 2000);
    }
};

const getGiftCardChargeData = (page = 1) => (dispatch) => {
    dispatch(setGiftCardChargeLoader(true));
    api.get('gift_card/charges', { page })
        .then(response => {
            dispatch(setGiftCardChargeData(response));
        })
        .catch((error) => {
            console.log('getBalance error -----------------------', error);
            NotificationManager.error('Failed to get charge history data', 'ERROR', 2000);
        })
        .finally(() => {
            dispatch(setGiftCardChargeLoader(false));
        });
}

export const actions = {
    ...basicReducer.actions,
    toList,
    searchChange,
    createGifCardDesign,
    readGifCardDesign,
    updateGifCardDesign,
    getGifCardStripeAccount,
    crateStripeAccounts,
    linkOnBoard,
    getGiftCardChargeData,
};


// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    ...basicReducer.reducers,
    [HAVE_STRIPE_ACCOUNT]: (state, { haveStripeAccount }) => {
        return {
            ...state,
            haveStripeAccount,
        };
    },
    [STRIPE_ACCOUNT]: (state, { stripeAccount }) => {
        return {
            ...state,
            stripeAccount,
        };
    },
    [BALANCE]: (state, { balance }) => {
        return {
            ...state,
            balance,
        };
    },
    [GIFT_CARD_CHARGE_DATA]: (state, { giftCardChargeData }) => {
        return {
            ...state,
            giftCardChargeData,
        };
    },
    [GIFT_CARD_CHARGE_PAGE]: (state, { giftCardChargePage }) => {
        return {
            ...state,
            giftCardChargePage,
        };
    },
    [GIFT_CARD_CHARGE_LOADER]: (state, { giftCardChargeLoader }) => {
        return {
            ...state,
            giftCardChargeLoader,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
    haveStripeAccount: false,
    stripeAccount: {},
    balance: {
        available: [{ amount: 0 }],
        pending: null,
    },
    giftCardChargeData: {
        results: [],
        total: 0
    },
    giftCardChargePage: 1,
    giftCardChargeLoader: false,
};
export default handleActions(reducers, initialState);
