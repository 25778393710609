import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/sendgrid";
import CreateUpdateSendgridCampaign from './CreateUpdateSendgridCampaign';


const ms2p = (state) => {
    return {
        ...state.sendgridCampaigns,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateUpdateSendgridCampaign);