import React, {Component} from 'react';
import ChangedPasswordForm from "./UserChangePasswordForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ChangedPassword extends Component {

    static defaultProps = {
        loading: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };

    }

    componentDidMount() {
        const {match, readUser} = this.props;
        readUser(match.params.id);
    }

    goBack = () => {
        const {history} = this.props;
        history.goBack();
    }

    sendData = (data) => {
        const {userItem, changeUserPassword} = this.props;
        changeUserPassword({...data, "email": userItem.email, id:userItem.id}, this.goBack);
    }

    showPass = (value) => {
        this.setState({showPassword: value})
    }

    render() {
        const {loader, userItem} = this.props;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">Change Password</div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-2">
                    <LoadMask loading={loader} dark blur>
                        <ChangedPasswordForm
                            loader={loader}
                            goBack={this.goBack}
                            onSubmit={this.sendData}
                            item={userItem}
                            showPassAction={this.showPass}
                            showPassword={this.state.showPassword}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ChangedPassword;
