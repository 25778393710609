import React, { Component } from "react";
import CampaignGrid from "./CampaignGrid";

export class CampaignList extends Component {
    componentWillMount() {
        this.props.toList();
    }

    render() {
        // State
        const { loader, page, data, searchChange, toList, remove } = this.props;

        return (
            <CampaignGrid
                data={data}
                page={page}
                loader={loader}
                toList={toList}
                remove={remove}
                searchAction={searchChange}
            />
        );
    }
}

export default CampaignList;
