import React from "react";
import {connect} from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderSearchField } from "../../../Utils/renderField";

let Form = (props) => {
    //  State
    const {downloadLoader} = props;
    //  Bind
    const {changeFilters, downloadAgentRawDataReport} = props

    return (
        <div className="row">
            <div className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}>
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search Agent'}
                        component={renderSearchField}
                        onChange={(e) => {
                            changeFilters(
                                "search",
                                e.target && e.target.value ? e.target.value : ""
                            )
                        }}
                        type="text"
                    />
                </div>
            </div>
            <div className="col-xl-2 col-lg-8 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-start justify-content-end">
                <button className="btn btn-primary text-white"
                    disabled={downloadLoader === true}
                    onClick={downloadAgentRawDataReport}>
                    <i className={`fa ${!downloadLoader ? "fa-download" : "fa-spinner fa-pulse fa-fw"} mr-2`} />
                    Download Report
                </button>
            </div>
        </div>
    )
}

Form = reduxForm({
    form: 'AgentRawDataFilterForm', // a unique identifier for this form
    initialValues: {},
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(Form);

const selector = formValueSelector('AgentRawDataFilterForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(Form);