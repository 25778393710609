import React, {useState, useEffect} from 'react';
import moment from "moment";
import Avatar from "react-avatar";
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {CUSTOM_PAGE_SIZE_IN_BACKEND} from "../../../../utility/constants";

const GiftCardRawDataGrid = (props) => {
    //  State
    const {data, loader, page, toList} = props;
    const [gridHeight, setGridHeight] = useState(200)
    //  Bind
    const { nextPage } = props;

    console.log('DATA: ', data);

    const tableOptions = {
        sizePerPage: CUSTOM_PAGE_SIZE_IN_BACKEND,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: nextPage,
    };

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("GIFT-CARD-RAW-DATA-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        
        <div id="GIFT-CARD-RAW-DATA-LIST" className="container-list card border-dark border-card-page px-4 py-0">
            <div className="card-body px-0 py-2 d-flex flex-column overflow-hidden">
                <LoadMask loading={loader} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={gridHeight ? {maxHeight: `${gridHeight}px`}: {}}>
                        <BootstrapTable
                            data={loader ? [] : data.results ? data.results : []}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            containerClass="flex-1"
                            tableContainerClass="table-scroll-auto"
                            tableStyle={gridHeight ? {maxHeight: `${gridHeight - 60}px`, minHeight: `${gridHeight - 60}px`} : {}}
                        >
                            <TableHeaderColumn
                                isKey
                                row="0"
                                rowSpan="2"
                                dataField="id"
                                width="80px"
                                dataAlign="right"
                            >
                                Order #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="0"
                                rowSpan="2"
                                dataField="design"
                                dataFormat={avatar()}
                                width="60px"
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="0"
                                rowSpan="2"
                                dataField="Date purchased"
                                width="190px"
                                dataAlign="center"
                                dataFormat={dateCreated()}
                            >
                                Date purchased
                            </TableHeaderColumn>
                            {/* To */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="2"
                                headerAlign="center"
                                width="150px"
                            >
                               To
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="to_data__fist_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.to_data
                                                ? row.to_data.name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="to_data__fist_email"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.to_data
                                                ? row.to_data.email
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="250px"
                            >
                                Email
                            </TableHeaderColumn>
                            {/* Purchased by */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="4"
                                headerAlign="center"
                                width="150px"
                            >
                               Purchased by
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent__fist_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent
                                                ? `${row.agent.first_name} ${row.agent.last_name}`
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent__brokerage_information__firm_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent
                                                ? row.agent.brokerage_information.firm_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="250px"
                            >
                                Office Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent__brokerage_information__franchise"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent
                                                ? row.agent.brokerage_information.franchise
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Franchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent__state"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent
                                                ? row.agent.state
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                State
                            </TableHeaderColumn>
                            {/* Redeemed by */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="4"
                                headerAlign="center"
                                width="150px"
                            >
                               Redeemed by
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent_redeem__fist_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent_redeem
                                                ? `${row.agent_redeem.first_name} ${row.agent_redeem.last_name}`
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent_redeem__brokerage_information__firm_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent_redeem
                                                ? row.agent_redeem.brokerage_information.firm_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Office Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent_redeem__brokerage_information__franchise"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent_redeem
                                                ? row.agent_redeem.brokerage_information.franchise
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Franchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agent_redeem__state"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.agent_redeem
                                                ? row.agent_redeem.state
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                State
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                    row="0"
                                    rowSpan="2"
                                    dataField="message"
                                    width="250px"
                                >
                                Message
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                    row="0"
                                    rowSpan="2"
                                    dataField="uuid"
                                    width="330px"
                                >
                                Code
                            </TableHeaderColumn>
                            {/* JOB FEES */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="3"
                                headerAlign="center"
                                width="150px"
                            >
                                Total
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="amount"
                                width="150px"
                                dataFormat={currencyFormatted()}
                            >
                                Amount
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="stripe_fee"
                                width="150px"
                                dataFormat={currencyFormatted()}
                            >
                                Stripe Fee{"  "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="charge"
                                width="150px"
                                dataFormat={currencyFormatted()}
                            >
                                Charge
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        </div>
    )
}

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('LLL')}
            </p>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={row.design ? row.design.image_thumbnail : null}
                    // round={true}
                    name={row.design  ? row.design.name : ""}
                />
            </div>
        );
    };
}

function currencyFormatted() {
    return (cell, row) => {
        return (
            <p className={"my-0 mx-0 px-0"}>
                $ {parseFloat(cell ? cell : 0).toFixed(2)}
            </p>
        );
    }
};

export default GiftCardRawDataGrid;