import React, {useState, useEffect} from "react";
import _ from "lodash";
import moment from "moment";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { SIZE_PER_PAGE, STATE_USA_LIST } from "../../../../../utility/constants";

const phoneFormatted = (cell) => {
    let newCell = cell;

    if (newCell && newCell.includes("+")) {
        newCell = newCell.replace("+", "");
    }
    if (newCell && newCell.includes("-")) {
        newCell = newCell.replace("-", "");
    }
    if (newCell && newCell.length > 10) {
        newCell = newCell.substr(newCell.length - 10, 12);
    }
    if (newCell) {
        newCell = `${newCell.substr(0, 3)}-${newCell.substr(
            3,
            3
        )}-${newCell.substr(6, 4)}`;
    }
    return <p className={"my-0 mx-0 px-0"}>{newCell}</p>;
};

const currencyFormatted = (cell) => {
    return (
        <p className={"my-0 mx-0 px-0"}>
            $ {parseFloat(cell ? cell : 0).toFixed(2)}
        </p>
    );
};


export const PayoutGrid = (props) => {
    const { loader = false, data, nextPage, page } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("STATS-PAYOUT");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    const tableOptions = {
        sizePerPage: SIZE_PER_PAGE,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: nextPage,
    };

    return (
        <div id="STATS-PAYOUT" className="container-list card border-dark border-card-page px-4 py-0">
            <div className="card-body px-0 py-2 d-flex flex-column overflow-hidden">
                <LoadMask loading={loader} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={gridHeight ? {maxHeight: `${gridHeight - 30}px`}: {}}>
                        <BootstrapTable
                            data={loader ? [] : data.results ? data.results : []}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            containerClass="flex-1"
                            tableContainerClass="react-bs-table-bordered-95"
                        >
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="id"
                                isKey
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                        </div>
                                    )
                                }}
                            />

                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="created"
                                width="100px"
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p className="p-0 m-0">
                                                {cell && moment(cell).format("ll")}
                                            </p>
                                        </div>
                                    );
                                }}
                            >
                                Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="created"
                                width="100px"
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p className="p-0 m-0">
                                                {cell && moment(cell).format("YYYY")}
                                            </p>
                                        </div>
                                    );
                                }}
                            >
                                Year
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="created"
                                width="100px"
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p className="p-0 m-0">
                                                {cell && moment(cell).format("MMM")}
                                            </p>
                                        </div>
                                    );
                                }}
                            >
                                Month
                            </TableHeaderColumn>

                            {/* PERSONAL INFORMATION */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="5"
                                headerAlign="center"
                                width="150px"
                            >
                                Personal Information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__first_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.first_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                First Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__last_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.last_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__real_estate_licence"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information
                                                    .real_estate_licence
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                RE Licence #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__mobile_number"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? phoneFormatted(
                                                    row.personal_information
                                                        .mobile_number
                                                )
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Cell Phone
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="personal_information__email"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.personal_information
                                                ? row.personal_information.email
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="180px"
                            >
                                Email
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                row="0"
                                rowSpan="2"
                                dataField="amount"
                                dataFormat={currencyFormatted}
                                dataAlign="right"
                                width="150px"
                            >
                                Payout
                            </TableHeaderColumn>

                            {/* PAYOUT */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="2"
                                headerAlign="center"
                                width="150px"
                            >
                                Payout
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="stripe_fee"
                                dataFormat={currencyFormatted}
                                dataAlign="right"
                                width="150px"
                            >
                                Stripe fee 0.25% + $0.25
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="charge_fee_amount"
                                dataFormat={currencyFormatted}
                                dataAlign="right"
                                width="170px"
                            >
                                Stripe Fee to Move the Fee to ShowIT Wallet
                            </TableHeaderColumn>

                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="show_it_profit"
                                dataFormat={currencyFormatted}
                                dataAlign="right"
                                width="150px"
                            >
                                ShowIT Profit
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        </div>
    );
};
