import React, { Component } from "react";
import ListGrid from "./ListGrid";

class List extends Component {
    componentWillMount() {
        this.props.toCustomList();
        this.props.getFranchisesOption(true, true);
        this.props.getAllOffice(true, true);
        this.props.getInfluencerOption(false, true)
        this.props.getStatesOption(false, true)
    }

    // filterByStatus = (selected) => {
    //     console.log('status: ',selected.value)
    //     this.props.toCustomList(1)
    // }

    // filterByFranchise = (selected) => {
    //     console.log('franchise_id: ',selected.value)
    //     this.props.toCustomList(1)
    // }

    render() {
        const {
            loader,
            page,
            data,
            optionStates,
            optionCities,
            searchChange,
            toList,
            removeBroker,
            optionFranchises,
            brokerages,
            influencers,
            changeFilters,
            goToAgents,
            goToOffices,
            onSortChange,
        } = this.props;

        return (
            <ListGrid
                data={data}
                page={page}
                loader={loader}
                toList={toList}
                remove={removeBroker}
                changePage={this.props.toCustomList}
                searchAction={searchChange}
                optionStates={optionStates}
                optionCities={optionCities}
                // filter1={this.filterByStatus}
                // filter2={this.filterByFranchise}
                optionFranchises={optionFranchises}
                optionInfluencers={influencers}
                brokerages={brokerages}
                changeFilters={changeFilters}
                goToAgents={goToAgents}
                goToOffices={goToOffices}
                onSortChange={onSortChange}
            />
        );
    }
}

export default List;
