import React, {Component} from 'react';
import CreateForm from "./CreateForm";
import LoadMask from "../Utils/LoadMask/LoadMask";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_update: false,
            photo: '',
            nameFile: '',
            file: null,
            active: true,
            new_file:false,
        };
    }

    componentWillMount() {
        const {match, read, setItem, brokerName, getBroker, item} = this.props;
        if (brokerName === undefined || brokerName === null) {
            let local_id = localStorage.getItem('idBroker')
            getBroker(local_id);
        }
        if (match.path === "/brokers/broker/:id/brokerages/create-brokerage/") {
            this.setState({is_update: false, photo: '', new_file: true});
            setItem(null);
        }
        if (match.path === "/brokers/broker/:id/brokerages/brokerages/:id/edit") {
            read(match.params.id);
            this.setState({is_update: true, photo: item && item.logo !== undefined && item.logo});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.item !== null && nextProps.item.logo !== undefined) {
            this.setState({
                photo: nextProps.item.logo,
            });
        } else {
            this.setState({
                photo: null,
            });
        }
    }

    goBack = () => {
        const {history} = this.props
        history.goBack();
    }

    actionFile = (data) => {
        const {setFiles} = this.props;
        setFiles(data)
        this.setState({photo: data});
    }

    onChanged = (file) => {
        const {setExcel} = this.props;
        this.setState({nameFile: file.name, file: file, active: false});
        setExcel(file);
    }

    setActive = (value) => {
        this.setState({active: value, file: null, nameFile: ''});
        const {setExcel} = this.props;
        setExcel([]);
    }

    setNewFileButton = () => {
        this.setState({active: true, file: null, nameFile: '', new_file: true});
        const {setExcel} = this.props;
        setExcel([]);
    }

    submitAction = (data) => {
        const {match} = this.props;
        if (match.path === '/brokers/broker/:id/brokerages/brokerages/:id/edit') {
            this.props.updateAttachment(match.params.id, data);
        } else {
            const {createAttachment, brokerName} = this.props;
            createAttachment(brokerName.id, data);
        }
    }

    render() {
        const {loader, item, brokerName} = this.props;
        console.log('item', item)
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="w-100 pr-4">
                        <div className="header-page d-flex justify-content-between">
                            <div>{
                                this.state.is_update ? "update brokerage " : "Create brokerage "
                            }</div>
                            <div>{brokerName && brokerName.full_name !== undefined && brokerName.full_name}</div>
                        </div>
                    </div>
                </div>
                <div
                    className="card border-dark border-card-page px-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <CreateForm
                            text={this.state.nameFile}
                            loader={loader}
                            goBack={this.goBack}
                            setLogo={this.setLogo}
                            handleOnChange={this.onChanged}
                            brokerName={brokerName}
                            setFiles={this.actionFile}
                            item={item}
                            onSubmit={this.submitAction}
                            is_update={this.state.is_update}
                            active={this.state.active}
                            setActive={this.setActive}
                            new_file={this.state.new_file}
                            setNewFileButton={this.setNewFileButton}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Create;
