import React, {useState} from "react";
import { Modal } from "react-responsive-modal";
import { Field, reduxForm } from "redux-form";
import LoadMask from "../Utils/LoadMask/LoadMask";
import {
    renderField,
    renderTextArea
} from "../Utils/renderField";

const InstrucctionsModal = (props) => {
    //  State
    const {open, type='android'} = props;
    //  Bind
    const {onClose} = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{ modal: "rounded col-lg-5 col-11", closeIcon: "bg-gray rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            {type == 'android' ? 'Android ': "Apple"} Link
                        </div>
                        {type == 'android' && (
                            <ul>
                                <li>Go to Google and search "Google Play"</li>
                                <li>In Google Play you have to search for "ShowIT Solutions"</li>
                                <li>Click on the App and Copy the url of the page</li>
                            </ul>
                        )}
                        {type == 'apple' && (
                            <ul>
                                <li>Go to Google and search "ShowIT Solutions apple store"</li>
                                <li>Click on the link that has "ShowIT Solutions LLC" in its description</li>
                                <li>Click on the App and Copy the url of the page</li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const settingForm = (props) => {
    const {
        handleSubmit,
        loader,
        goBack,
    } = props;

    const [modal, setModal] = useState(false)
    const [type, setType] = useState('android')

    return (
        <div
            className="card flex-1 border-dark border-card-page px-4 py-4"
            style={{
                minWidth: "99%",
            }}
        >
            <form
                name="brokerageForm"
                className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
                onSubmit={handleSubmit}
            >
                <LoadMask loading={loader} light>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-12 col-sm-12">
                            <div className="d-flex flex-row align-items-center">
                                <label htmlFor="play_store_url">
                                    Play Store Url
                                </label>
                                <i
                                    title="how to get it?"
                                    className="ml-3 text-info fa fa-question-circle pointer rounded-circle"
                                    style={{
                                        fontSize: "25px",
                                        marginBottom: "0.5rem",
                                    }}
                                    onClick={() => {
                                        setType('android')
                                        setModal(true)
                                    }}
                                />
                            </div>
                            <Field
                                name="play_store_url"
                                label="Play Store Url"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-12 col-sm-12">
                            <div className="d-flex flex-row align-items-center">
                                <label htmlFor="app_store_url">
                                    App Store Url
                                </label>
                                <i
                                    title="how to get it?"
                                    className="ml-3 text-info fa fa-question-circle pointer rounded-circle"
                                    style={{
                                        fontSize: "25px",
                                        marginBottom: "0.5rem",
                                    }}
                                    onClick={() => {
                                        setType('apple')
                                        setModal(true)
                                    }}
                                />
                            </div>
                            <Field
                                name="app_store_url"
                                label="App Store Url"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        {/* <div className="form-group has-feedback col-lg-12 col-sm-12 p-0 mt-3">
                            <h5 className="font-weight-bold m-0">Wallet Text</h5>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="about">Available Soon</label>
                            <Field
                                name="available_soon"
                                rows={8}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="about">Available Balance</label>
                            <Field
                                name="available_balance"
                                rows={8}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div> */}
                        {/* <div className="form-group has-feedback col-lg-12 col-sm-12 p-0 mt-3">
                            <h5 className="font-weight-bold m-0">Texts when completing offline</h5>
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="title_completing_job_offline">Title</label>
                            <Field
                                name="title_completing_job_offline"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="completing_job_offline">Description</label>
                            <Field
                                name="completing_job_offline"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div> */}
                    </div>

                    {/*-------------------------------------------------------------------------------*/}
                    {/*-----------------        ACTIONS CREATE OR UPDATE           -------------------*/}
                    {/*-------------------------------------------------------------------------------*/}
                    <div className="w-100 my-4 mx-auto text-center">
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={goBack}
                        >
                            Cancel
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                            disabled={loader}
                        >
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
            <InstrucctionsModal type={type} open={modal} onClose={() => setModal(false)}/>
        </div>
    );
};

export default reduxForm({
    form: "settingForm",
})(settingForm);
