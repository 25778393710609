import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from "../../Utils/Grid";
import {standardActions} from "../../Utils/Grid/StandardActions";


export default class Grids extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange } = this.props;

        return (
            <div className="py-4">
                <h2>GRIDS</h2>
                <div className="row">
                    {/*<div className="mb-4 col-lg-12">*/}
                    {/*    <div className="mb-4 card card-small">*/}
                    {/*        <div className="p-0 px-3 pt-3">*/}
                    {/*            <Grid*/}
                    {/*                hover*/}
                    {/*                keyField={'username'}*/}
                    {/*                striped*/}
                    {/*                data={data}*/}
                    {/*                loading={loader}*/}
                    {/*                onPageChange={onPageChange}*/}
                    {/*                onSortChange={onSortChange}*/}
                    {/*                columns={[*/}
                    {/*                    {*/}
                    {/*                        dataField: "username",*/}
                    {/*                        sort: true,*/}
                    {/*                        text: "Usuario",*/}
                    {/*                    },*/}
                    {/*                    {*/}
                    {/*                        dataField: "first_name",*/}
                    {/*                        sort: true,*/}
                    {/*                        text: "Nombre",*/}
                    {/*                    },*/}
                    {/*                    {*/}
                    {/*                        dataField: "last_name",*/}
                    {/*                        sort: true,*/}
                    {/*                        text: "Apellidos",*/}
                    {/*                    },*/}
                    {/*                ]}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="mb-4 col-12">
                        <div className="mb-4 card card-small">
                            <div className="border-bottom card-header"><h6 className="m-0">With Actions</h6></div>
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    loading={loader}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                    columns={[
                                        {
                                            dataField: "id",
                                            text: "Acciones",
                                            formatter: standardActions({ on_delete: () => {}, edit: "grids", ver: "grids"  })
                                        },
                                        {
                                            dataField: "first_name",
                                            text: "Name",
                                        },
                                        {
                                            dataField: "email",
                                            text: "Email",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
