import React, {useState, useEffect} from 'react';
import moment from "moment";
import Avatar from "react-avatar";
import HeaderCard from "../../HeaderCard";
import Grid from "../../Utils/GridTableWithScroll";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {CAMPAIGNS_PAGE_SIZE} from "../../../../utility/constants";


const TutorialVideosGrid = (props) => {
    const {data, loader, page, searchAction, toList, remove} = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("TUTORIAL-VIDEO-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">Tutorials Videos</div>
                </div>
            </div>
            <HeaderCard
                showFile={false}
                active_link={true}
                text_link={"Create"}
                id_header={'TutorialVideosList'}
                link={'tutorial-videos/create'}
                justify={'justify-content-between mt-2'}
                searchAction={(value) => searchAction(value)}
                searchStyle="col-lg-4 col-sm-12 img-fluid mb-sm-1 ml-3"
            />
            <div id="TUTORIAL-VIDEO-LIST" className="container-list card border-dark border-card-page px-4 py-2">
                <div className="card-body d-flex flex-column px-0 py-2">
                    <Grid
                        hover
                        height={gridHeight}
                        striped
                        page={page}
                        keyField={'id'}
                        loading={loader}
                        data={data ? data : []}
                        wrapperClasses="table-scroll-auto"
                        sizePerPage={CAMPAIGNS_PAGE_SIZE}
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        remove: remove,
                                        // view: "campaigns",
                                        edit: 'tutorial-videos',
                                    }),
                            },
                            {
                                dataField: "index",
                                sort: false,
                                text: "Index",
                            },
                            {
                                dataField: "created",
                                formatter: dateCreated(),
                                text: "Date created",
                                // headerStyle: { width: '100px' },
                            },
                            {
                                dataField: "title",
                                sort: false,
                                text: "Title",
                            },
                            {
                                dataField: "url",
                                sort: false,
                                text: "Url",
                            },
                        ]}
                    />
                </div>
            </div>

        </div>
    );
};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('LLL')}
            </p>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="43"
                    src={cell}
                    // round={true}
                    name={row.title}
                />
            </div>
        );
    };
}


export default TutorialVideosGrid;
