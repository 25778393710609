import React, { Component } from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CreateBrokerageForm from "./CreateBrokerageForm"

class CreateBrokerage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            photo: "",
            nameFile: "",
            file: null,
            active: true,
            new_file: false,
        };
    }

    componentWillMount() {

        this.setState({
            photo: '',
            nameFile: '',
        });

        this.props.getUrlExelTemplate();
    }

    actionFile = (data) => {
        const { setFiles } = this.props;
        setFiles(data);
        this.setState({ photo: data });
    };

    onChanged = (file) => {
        const { setExcel } = this.props;
        this.setState({
            nameFile: file.name,
            file: file,
            active: false,
        });
        setExcel(file);
    };

    setActive = (value) => {
        this.setState({
            active: value,
            file: null,
            nameFile: '',
        });
        const { setExcel } = this.props;
        setExcel([]);
    };

    setNewFileButton = () => {
        this.setState({
            active: true,
            file: null,
            nameFile: '',
            new_file: true,
        });
        const { setExcel } = this.props;
        setExcel([]);
    };

    goBack = () => {
        const {history} = this.props
        history.goBack()
    }

    submitAction = (data) => {
        const { match, createBrokerageAttachment } = this.props;
        if (match.params.broker_id) {
            if (this.state.file) {
                createBrokerageAttachment(match.params.broker_id, data, this.goBack);
            } else {
                createBrokerageAttachment(match.params.broker_id, data, this.goBack);
            }
        }
    };

    render() {
        //  State
        const {loader, urlTemplate, assignAddressData} = this.props;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            New Office
                        </div>
                    </div>
                </div>
                <div
                    className="card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: "99%",
                        minHeight: "15%",
                    }}
                >
                    <LoadMask loading={loader} light>
                        <CreateBrokerageForm
                            text={this.state.nameFile}
                            loader={loader}
                            handleOnChange={this.onChanged}
                            setFiles={this.actionFile}
                            onSubmit={this.submitAction}
                            active={this.state.active}
                            setActive={this.setActive}
                            new_file={this.state.new_file}
                            setNewFileButton={this.setNewFileButton}
                            urlTemplate={urlTemplate}
                            goBack={this.goBack}
                            assignAddressData={assignAddressData}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CreateBrokerage