import React from 'react';
import {Field} from "redux-form";
import LineChartRevenue from "./index";
import {NEW_MONTH_LIST} from "../../../../utility/constants";
import {radioSelect2, SelectField} from "../../Utils/renderField";

const RevenueCard = (props) => {
    const {
        offices,
        nameJob,
        filterMonth,
        revenueItem,
        addFilterJobId,
        filterBrokerage,
        removeFilterJobId,
        loader,
        rangeMonth,
    } = props
    return (
        <div className="card border-card-page col-lg-6 col-sm-12 mr-2 py-4">
            {/*------------------------------------------*/}
            {/*------------ filters and title -----------*/}
            <div className="card-title row w-100 mx-auto border-bottom pb-3">
                <label className="p-0 mx-2 mb-lg-0 mb-sm-1 align-self-center label-semi-bold">
                    Revenue
                </label>
                {/*-----------------------------------------*/}
                {/* ------------- filter by month ----------*/}
                <div className="col-lg-4 col-sm-12 row px-0 mx-1">
                    <label htmlFor="timeFilter" className="px-0 my-auto label-semi-bold">Time &nbsp; </label>
                    <div className="w-75 mb-lg-0 mb-sm-3">
                        <Field
                            id="timeFilterRevenue"
                            name="timeFilterRevenue"
                            isMulti={false}
                            options={NEW_MONTH_LIST}
                            set_value={filterMonth}
                            component={SelectField}
                            className="form-control w-100"
                            disabled={false}
                            // placeholder={"disable"}
                        />
                    </div>
                </div>
                {/*-----------------------------------------*/}
                {/* ----------- filter by brokerage --------*/}
                {/*-----------------------------------------*/}
                <div className="col-lg-5 col-sm-12 row px-0 ml-1">
                    <label htmlFor="filterOffice" className="px-0 my-auto mr-1 label-semi-bold">
                        Office
                    </label>
                    <div className="w-75">
                        <Field
                            id="officeFilterRevenue"
                            name="officeFilterRevenue"
                            isMulti={false}
                            options={offices}
                            set_value={filterBrokerage}
                            component={SelectField}
                            className="form-control w-100"
                            placeholder={"Select Office"}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
            {/*-------------------------------------------------*/}
            {/*--------------------- BODY CARD -----------------*/}
            {/*-------------------------------------------------*/}
            <div className="px-0 row">
                <div className="col-sm-12 col-lg-8 px-0">
                    {/*----------------------------------------*/}
                    {/*---------------    PLOT JOB     --------*/}
                    {/*----------------------------------------*/}
                    <LineChartRevenue
                        data={revenueItem}
                        loading={loader}
                    />
                    {/*----------------------------------------*/}
                    {/*-------   STATS LINE ALL JOB    --------*/}
                    {/*----------------------------------------*/}
                    <div className="w-100 filter-job py-2 mt-3 px-2" style={{maxHeight: '10rem'}}>
                        {(revenueItem && revenueItem.datasets && revenueItem.datasets.length > 0) &&
                        revenueItem.datasets.map((job, index) => (
                            <div
                                className="mx-auto mb-1 row pl-3 py-2 w-100 justify-content-between text-white"
                                style={{
                                    backgroundColor: job.borderColor,
                                    fontSize: '0.62rem',
                                }}
                                key={`key-${index}-revenue`}
                            >
                                <p className="col-5 px-0 py-0 my-0">
                                    {job.label}
                                </p>
                                <p className="col-3 px-0 my-0">
                                    {job.count}&nbsp; {job.count > 1 ? 'Jobs' : 'Job'}
                                </p>
                                <p className="col-3 px-0 my-0 justify-content-between">
                                    USD &nbsp;{(job.usd === 0) ? '0.00' : job.usd}
                                </p>
                            </div>
                        ))
                        }
                    </div>
                </div>
                {/* ---------------------------------------- */}
                {/* --------------- Name all job  ----------- */}
                {/* ---------------------------------------- */}
                <div className="border-left w-100 px-1 col-sm-12 col-lg-4">
                    <div className="d-inline-flex row w-100 mx-auto " style={{minHeight: '100%'}}>
                        <label className="ml-2 label-semi-bold">Type of Job</label>
                        <div className="w-100 mx-auto px-3 filter-job" id="filter-job-2">
                            {nameJob.map((job, index) => {
                                return (
                                    <div className="row justify-content-between" key={`xd${index}`}>
                                        {job.title ?
                                            // <label className="ml-1 label-color-gray">
                                            <label
                                                className="pl-2 px-0 pointer name-small pt-1 mb-0 pb-0">
                                                {' ' + job.name}
                                            </label>
                                            :
                                            <React.Fragment>
                                                <Field
                                                    name={`name-${job.name}-${index}`}
                                                    component={radioSelect2}
                                                    set_value={() => console.log('value')}
                                                    addFilter={addFilterJobId}
                                                    removeFilter={removeFilterJobId}
                                                    idOption={job.id}
                                                    isSub={job.isSub}
                                                    type={'checkbox'}
                                                />
                                                <p className={"col px-0 pointer name-small py-1 mb-0"}>
                                                    {job.name}
                                                </p>
                                                <div
                                                    style={{backgroundColor: job.primary_color}}
                                                    className="px-0 ml-1 m-auto square-color"
                                                />
                                            </React.Fragment>
                                        }
                                    </div>
                                );
                            })
                            }
                        </div>
                        <div className="col text-center mt-3 flex align-self-end">
                            <h6 className="mb-0 font-weight-bold">Total Revenue</h6>
                            <h4 className="row text-dark justify-content-center font-weight-bolder my-0">
                                $ {parseFloat(revenueItem.revenue ? revenueItem.revenue : 0).toFixed(2)}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RevenueCard;
