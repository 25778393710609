import React, {Component} from 'react';
// import DetailsForm from "./DetailsGrid";
import DetailsWithBroker from "./DetailsWithBroker";
import LoadMask from "../Utils/LoadMask/LoadMask";

class RosterDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photo: null,
            brokerage_id: 0,
            broker: 0,
            license: '',
            namFile: '',
            file: null,
            active: true,
            id: null
        };
    }

    componentWillMount() {
        // const {match, customToListDetails, page, brokerName, getBroker} = this.props;
        // if (match.params.id) {
        //     const id = match.params.id;
        //     customToListDetails(page, id);
        //     if (brokerName === undefined || brokerName === null) {
        //         let local_id = localStorage.getItem('idBroker')
        //         getBroker(local_id);
        //         this.setState({broker: this.props.idBroker})
        //     }
        // }
        const {match, page} = this.props;
        if (match.params.id) {
            const id = match.params.id;
            this.setState({id: id})
            this.props.read(id)
            this.props.agentList(page, id);
        
        }
        this.props.getUrlExelTemplate()
        this.props.getFormBrokerOption()
    }

    setLogo = (photo) => {
        this.setState({photo});
    };

    goBack = () => {
        const {history} = this.props
        history.goBack();
    }

    onChanged = (file) => {
        const {match, onChange} = this.props
        this.setState({nameFile: file.name, file: file, active: false});
        onChange(file, match.params.id);
    };

    actionFile = (data) => {
        const {setFiles} = this.props;
        setFiles(data)
        this.setState({photo: data});
    }

    setActive = (value) => {
        this.setState({active: value, file: null, nameFile: ''});
    }

    updateForm = (data) => {
        if (this.state.photo) {
            this.props.update(data, [{"name": "logo", "file": this.state.photo}], this.setLogo)
        }else {
            this.props.edit(data.id, data)
        }
    }

    render() {
        const {loader2,
            agent,
            loader,
            item,
            // update,
            // brokerName,
            agentList,
            searchAgent,
            urlTemplate,
            roster,
            // customToListDetails,
            removeInvite,
            goToAgents,
            optionBroker,
            assignAddressData,
        } = this.props;
        const {id} = this.state;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            <button
                                className="btn back-btn mr-3 my-2"
                                onClick={this.goBack}
                            >
                                <i className="material-icons pointer">
                                    arrow_back
                                </i>
                            </button>
                            Office detail
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        {Object.keys(agent).length > 0 && (
                            <DetailsWithBroker
                                id={id}
                                data={roster}
                                office={item}
                                optionBroker={optionBroker}
                                onSubmit={this.updateForm}
                                setLogo={this.setLogo}
                                loader={loader}
                                searchAction={searchAgent}
                                loader2={loader2}
                                agentList={agentList}
                                handleOnChange={this.onChanged}
                                text={this.state.nameFile}
                                active={this.state.active}
                                setActive={this.setActive}
                                urlTemplate={urlTemplate}
                                removeInvite={removeInvite}
                                goToAgents={goToAgents}
                                goBack={this.goBack}
                                assignAddressData={assignAddressData}
                            />
                        )}
                    </LoadMask>
                </div>
            </div>
        );
    }

}
export default RosterDetails;
