import React, { Component } from "react";
import VendorRawDataFilters from "./VendorRawDataFilters";
import { VendorRawDataGrid } from "./VendorRawDataComponents";

class VendorRawData extends Component {

    componentDidMount() {
        this.props.getList(1, true);
    }

    changePage = (page) => {
        this.props.getList(page);
    };

    render() {
        //  Status
        const {loader, data, page, downloadLoader } = this.props;
        //  Bind
        const {getList, setSearchFilter, setJobStatusFilter, setTimeFilter, downloadReport} = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Stats / Vendor raw data
                        </div>
                    </div>
                </div>
                <VendorRawDataFilters
                    downloadLoader={downloadLoader}
                    setStatusFilter={setJobStatusFilter}
                    setTimeFilter={setTimeFilter}
                    searchAction={setSearchFilter}
                    downloadReport={downloadReport}
                />
                <VendorRawDataGrid
                    page={page}
                    data={data}
                    loader={loader}
                    nextPage={this.changePage}
                />
            </div>
        );
    }
}

export default VendorRawData;
