import React, { Component } from 'react'
import GiftCardRawDataFilterForm from './GiftCardRawDataFilterForm'
import GiftCardRawDataGrid from './GiftCardRawDataGrid';

export class GiftCardRawData extends Component {
    componentDidMount() {
        this.props.toList();
    }

    changePage = (page) => {
        this.props.toList(page);
    };

    render() {
        //  State
        const { loader, page, data } = this.props;
        //  Bind
        const { searchChange, toList } = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Stats / Gift Card</div>
                    </div>
                </div>
                <GiftCardRawDataFilterForm
                    searchAction={searchChange}
                />
                <GiftCardRawDataGrid
                    page={page}
                    data={data}
                    loader={loader}
                    nextPage={this.changePage}
                />
            </div>
        )
    }
}

export default GiftCardRawData