import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import ProfileForm from "./ProfileForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";


class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photo: null,
            is_view: false,
            showPassword: false,
        };

    }

    setAvatar = (photo) => {
        this.setState({photo});
    };

    componentDidMount() {
        const {match, getMe} = this.props;
        getMe();
        if (match.path === "/user-profile") {
            this.setState({is_view: true});
        }
        if (match.path === "/edit-user-profile") {
            this.setState({is_view: false});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.path === "/edit-user-profile") {
            this.setState({is_view: false});
        }
        if (nextProps.match.path === "/user-profile") {
            this.setState({is_view: true});
        }
    }

    update = (data) => {
        const {update} = this.props;
        update({
            ...data,
            photo: null,
        }, [{
            "file": this.state.photo,
            "name": "photo",
        }]);
    };

    changePass = (data) => {
        const {changePassword, me} = this.props;
        changePassword({
            ...data,
            id: me.id,
        });
    };

    showPass = (value) => {
        this.setState({showPassword: value})
    }

    render() {
        const {me, loader} = this.props;

        return (
            <LoadMask loading={loader} dark blur>
            <ProfileForm
                me={me}
                is_view={this.state.is_view}
                onSubmit={this.update}
                setAvatar={this.setAvatar}
                onSubmit2={this.changePass}
                showPassAction={this.showPass}
                showPassword={this.state.showPassword}
            />
            </LoadMask>
        );
    }
}

export default reduxForm({
    form: 'profile', // a unique identifier for this form
})(Profile);
