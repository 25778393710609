import Swal from 'sweetalert2';
// import UpdateForm from "./UpdateForm";
import React, {Component} from 'react';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import WholeSalerUpdateForm from './WholeSalerUpdateForm';

class WholeSalerUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photo: null,
        };
    }

    componentDidMount() {
        const { match, read, getTeams } = this.props;
        getTeams()
        if (match.params.id) {
            console.log("PATH", match.path);
            if (match.path === '/wholesalers/:id/edit') {
                read(match.params.id);
            }
        }
    }

    remove = (id) => {
        Swal.fire({
            title: 'Are you sure to delete the agent?',
            text: "This action can't be reverted!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.removeWholeSaler(id);
            }
        });
    };

    updateForm = (data) => {
        const {editWholeSaler} = this.props;
        if (this.state.photo) {
            const {update} = this.props;
            update({...data, photo: null}, [{"name": "photo", "file": this.state.photo}]);
        } else {
            editWholeSaler(data.id, data);
        }
    };

    activateWholeSaler = (data) => {
        const {activateWholeSaler} = this.props;
        activateWholeSaler(data.id, data);
    }

    setAvatar = (photo) => {
        this.setState({photo});
    };

    render() {
        //  State
        const {loader, item, teams} = this.props;
        // Bind
        const {goPage } = this.props;
        const pendingWholeSaler = item && item.agent_account_status == 10 ? true : false;
        const submit = pendingWholeSaler ? this.activateWholeSaler : this.updateForm;

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={() => this.props.history.goBack()}>
                                <i className="material-icons pointer">arrow_back</i> 
                            </button>
                            {pendingWholeSaler && "Pending WholeSaler"}
                            {!pendingWholeSaler && "Agent WholeSaler"}
                        </div>
                    </div>
                </div>
                <div
                    className="card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <WholeSalerUpdateForm
                            loader={loader}
                            item={item}
                            setAvatar={this.setAvatar}
                            pendingWholeSaler={pendingWholeSaler}
                            remove={this.remove}
                            teams={teams}
                            onSubmit={submit}
                            goBack={()=> this.props.history.goBack()}
                            goPage={goPage}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default WholeSalerUpdate