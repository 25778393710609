import React, {Component} from 'react';
import {reduxForm} from "redux-form";
import {validate, validators} from "validate-redux-form";
import {FeedbackFormAgentForm} from "../FeedbackFormAgentForm";


const JobTypeEditForm = reduxForm({
    form: 'feedbackFormAgentForm', // a unique identifier for this form
    asyncBlurFields: [],
    validate: (data) => {
        return validate(data, {
            name: validators.exists()('This field is required'),
            job_type: validators.exists()('This field is required'),
            base_price: validators.exists()('This field is required'),
            primary_color: validators.regex(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/i)('This field is required'),
        });
    },
})(FeedbackFormAgentForm);


export default class FeedbackFormAgentEdit extends Component {

    componentDidMount() {
        const {read, match: {params: {id}}} = this.props;
        read(id);
    }

    render() {
        const {edit, match: {params: {id}}} = this.props;
        return (
            <React.Fragment>
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page"> Edit Agent Feedback Form</div>
                    </div>
                </div>
                <JobTypeEditForm
                    onSubmit={(data) => {
                        edit(id, data);
                    }}
                    editView
                    setIcon={this.setIcon}
                />
            </React.Fragment>
        );
    }
}
