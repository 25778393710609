import React, {useState} from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    renderField,
    renderTextArea,
    renderFileImageCrop,
} from "../../Utils/renderField";

let Form = (props) => {
    //  State
    const {loader} = props;
    //  Bind
    const {
        handleSubmit,
        goBack,
    } = props;

    return (
        <div
            className="card flex-1 border-dark border-card-page px-4 py-4"
            style={{
                minWidth: "99%",
            }}
        >
            <form
                name="counterOfferForm"
                className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
                onSubmit={handleSubmit}
            >
                <LoadMask loading={loader} light>
                    <div className="row">
                        <h5>Counter Offer</h5>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="counter_offer_title">Title</label>
                            <Field
                                name="counter_offer_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="counter_offer_text">Description</label>
                            <Field
                                name="counter_offer_text"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <h5>Counter Accept Offer</h5>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="counter_accept_offer_title">Title</label>
                            <Field
                                name="counter_accept_offer_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="counter_accept_offer_text">Description</label>
                            <Field
                                name="counter_accept_offer_text"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="w-100 my-4 mx-auto text-center">
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={goBack}
                        >
                            Back
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                            disabled={loader}
                        >
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        </div>
    )
}

let counterOfferForm = reduxForm({
    form: "counterOfferForm", // a unique identifier for this form
})(Form);

const selector = formValueSelector("counterOfferForm");

counterOfferForm = connect((state) => {
    return {
    };
})(counterOfferForm);

export default counterOfferForm;