import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import {
    renderField,
    renderDayPicker
} from "../../../Utils/renderField";

const validate = (values, props) => {
    const errors = {}

    if (!values.android) {
        errors.android = "This field is required";
    }
    if (!values.ios) {
        errors.ios = "This field is required";
    }
    if (!values.released) {
        errors.released = "This field is required";
    }

    return errors
}

const Form = (props) => {
    //  State
    const {
        loader
    } = props;

    //  Bind
    const {handleSubmit, goBack} = props;

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-100 px-2 pt-2">
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="released">Released</label>
                        <Field
                            name="released"
                            placeholder=""
                            component={renderDayPicker}
                            initialDate={""}
                            type="text"
                            outputFormatt="YYYY-MM-DD"
                        />
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="android">Android</label>
                        <Field
                            name="android"
                            label="Android"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="ios">IOS</label>
                        <Field
                            name="ios"
                            label="IOS"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div
                className={`row mx-auto mt-3 col-lg-8 col-sm-12 justify-content-between mt-4 mb-5`}
            >
                <button
                    className={`btn btn-danger col-lg-5 col-sm-12`}
                    disabled={loader}
                    type="button"
                    onClick={goBack}
                >
                    Cancel
                </button>
                <button
                    className={`btn btn-success col-lg-5 col-sm-12`}
                    disabled={loader}
                >
                    Save
                </button>
            </div>
        </form>
    )
}

let AppVersionForm = reduxForm({
    form: "appVersionForm", // a unique identifier for this form
    validate,
})(Form);

export default AppVersionForm;