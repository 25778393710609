import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
    renderField,
    renderTextArea,
    renderStarsField,
} from "../../../Utils/renderField";

const validate = (values) => {
    let errors = {};

    return errors;
};

const UpdateReviewForm = (props) => {
    //  State
    const { item, loader } = props;
    //  Bind
    const { handleSubmit, goBack } = props;

    return (
        <form
            name="updateAgentForm"
            className="my-1 col-sm-12 p-sm-0"
            style={{ minHeight: "20rem" }}
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto pt-2">
                <div className="col-sm-12">
                    <h5>Review</h5>
                </div>
                <div className="form-group has-feedback col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="professionalism">Professionalism</label>
                    <Field
                        name="professionalism"
                        label="Professionalism"
                        component={renderStarsField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="timeliness">Timeliness</label>
                    <Field
                        name="timeliness"
                        label="Timeliness"
                        component={renderStarsField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="communication">Communication</label>
                    <Field
                        name="communication"
                        label="Communication"
                        component={renderStarsField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="quality">Quality</label>
                    <Field
                        name="quality"
                        label="Quality"
                        component={renderStarsField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="feedback">Feedback</label>
                    <Field
                        name="feedback"
                        label="Feedback"
                        component={renderStarsField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="form-group has-feedback col-lg-9 col-md-6 col-sm-12">
                    <label htmlFor="notes">Notes</label>
                    <Field
                        name="notes"
                        rows={3}
                        component={renderTextArea}
                        customClass="text-area-h-auto border"
                        // disabled={is_view}
                    />
                </div>
            </div>
            {item &&
                item.job &&
                item.job.customer_feedback &&
                item.job.customer_feedback.id && (
                    <div className="row mx-auto pt-2">
                        <div className="col-sm-12">
                            <h5>Customer Review</h5>
                        </div>
                        <div className="form-group has-feedback col-lg-12 col-md-12 col-sm-12">
                            <label htmlFor="customer_feedback_notes">
                                Notes
                            </label>
                            <Field
                                name="customer_feedback_notes"
                                rows={3}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                                // disabled={is_view}
                            />
                        </div>
                    </div>
                )}

            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <div className="col-lg-2 col-md-3 col-sm-12 my-md-0 my-1">
                    <button
                        type="button"
                        onClick={goBack}
                        className={`w-100 btn text-white btn-secondary`}
                    >
                        Back
                    </button>
                </div>
                <div className='col-lg-2 col-md-3 col-sm-12 my-md-0 my-1'>
                    <button type="submit" className="w-100 btn btn-primary">
                        Update
                    </button>
                </div>
            </div>
        </form>
    );
};

let UpdateAgentReviewForm = reduxForm({
    form: "updateAgentReviewForm", // a unique identifier for this form
    validate,
})(UpdateReviewForm);

const selector = formValueSelector("updateAgentReviewForm");

const mstp = (state) => {
    return {};
};

export default connect(mstp, null)(UpdateAgentReviewForm);
