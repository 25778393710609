import {connect} from 'react-redux';
import {actions} from '../../../../redux/modules/agent/agentRoster';
import CreateAgentRoster from "./CreateAgentRoster";

const ms2p = (state) => {
    return {
        ...state.agentRoster
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateAgentRoster);