import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {api} from "api";
import {push} from "react-router-redux";
import {NotificationManager} from "react-notifications";

// ------------------------------------
// BASE ACTION
// ------------------------------------
const baseReducer = createReducer(
    "register",
    "user",
    "adminForm",
    "/manager",
);

// ------------------------------------
// CONSTANTS REDUX
// ------------------------------------


// ------------------------------------
// Pure Actions
// ------------------------------------


// ------------------------------------
// Actions
// ------------------------------------


const createAdmin = data => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.post('user/create_admin', data).then(() => {
        NotificationManager.success('Record created', 'Success', 3000);
        dispatch(push('/manager'));
    }).catch(() => {
        NotificationManager.error('Creation failed', 'ERROR');
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const customEdit = (id, data) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.put(`user/update_admin`, data).then(() => {
        NotificationManager.success('Record created', 'Success', 3000);
        dispatch(push('/manager'));
    }).catch(() => {
        NotificationManager.error('Creation failed', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};


export const actions = {
    createAdmin,
    customEdit,
    ...baseReducer.actions,
};
export const reducers = {
    ...baseReducer.reducers,
};
export const initialState = {
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
