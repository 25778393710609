import React, {useState} from "react";
import { Field, reduxForm } from "redux-form";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {
    renderField,
    renderTextArea
} from "../../Utils/renderField";

const ShowingTextForm = (props) => {
    const {
        handleSubmit,
        loader,
        goBack,
    } = props;

    return (
        <div
            className="card flex-1 border-dark border-card-page px-4 py-4"
            style={{
                minWidth: "99%",
            }}
        >
            <form
                name="brokerageForm"
                className="pt-2 flex col-sm-12 col-lg-10 mx-auto"
                onSubmit={handleSubmit}
            >
                <LoadMask loading={loader} light>
                    <div className="row">
                        <h5>Add another property</h5>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="showing_add_property_title">Title for Hiring Agent</label>
                            <Field
                                name="showing_add_property_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="showing_add_property_description">Description for Hiring Agen</label>
                            <Field
                                name="showing_add_property_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="showing_d_agent_add_property_title">Title for Showing Agent</label>
                            <Field
                                name="showing_d_agent_add_property_title"
                                label="Title"
                                placeholder={""}
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback col-lg-6 col-sm-12">
                            <label htmlFor="showing_d_agent_add_property_description">Description for Showing Agen</label>
                            <Field
                                name="showing_d_agent_add_property_description"
                                rows={6}
                                component={renderTextArea}
                                customClass="text-area-h-auto border"
                            />
                        </div>
                    </div>
                    <div className="w-100 my-4 mx-auto text-center">
                        <label
                            className={`btn button-03 mx-3 col-lg-4 col-sm-10 my-0`}
                            onClick={goBack}
                        >
                            Back
                        </label>
                        <button
                            type="submit"
                            className="btn btn-primary mx-3 col-lg-4 col-sm-10 mt-sm-1 mt-lg-0 py-0"
                            disabled={loader}
                        >
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        </div>
    )
}

export default reduxForm({
    form: "showingTextForm",
})(ShowingTextForm);
