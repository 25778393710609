import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/deleted/agents'
import DeletedAgentReinstate from './DeletedAgentReinstate';

const mapStateToProps = (state) => {
    return{
        ...state.deletedAgent
    };
};

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(DeletedAgentReinstate)
