import React, { Component } from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import ReferTextForm from './ReferTextForm';

export default class ReferText extends Component {
    state = {
        referImage: null,
        referBrokerImage: null,
    }
    componentDidMount() {
        this.props.getSettings('referTextForm')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    setReferImage = (icon) => {
        this.setState({referImage: icon})
    }

    setReferBrokerImage = (icon) => {
        this.setState({referBrokerImage: icon})
    }

    resetImages = () => {
        this.setState({
            referImage: null,
            referBrokerImage: null,
        })
    }

    submit = (data) => {
        const {referImage, referBrokerImage} = this.state
        const {onSubmitReferText} = this.props
        //  Images
        const images = []
        if (referImage) {
            images.push({file: referImage, name: 'refer_image'})
        }
        if (referBrokerImage) {
            images.push({file: referBrokerImage, name: 'refer_broker_image'})
        }

        //  Update
        onSubmitReferText({...data}, images, () => {
            this.resetImages()
        })
    }
    render() {
        const {loader} = this.props

        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Refer Texts</div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <ReferTextForm
                            loader={loader}
                            setReferImage={this.setReferImage}
                            setReferBrokerImage={this.setReferBrokerImage}
                            goBack={this.goBack}
                            onSubmit={this.submit}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}
