import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import {
    renderField,
    renderNumber,
    renderTextArea,
    SelectField,
} from "../../Utils/renderField";

const validate = (values, props) => {
    const errors = {}

    if (!values.title) {
        errors.title = "This field is required";
    }
    if (!values.index) {
        errors.index = "This field is required";
    }
    if (values.url) {
        if(values.url.length > 200){
            errors.url = "Must be 200 characters or less";
        }
        let res = values.url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

        if (!res) {
            errors.url = "Invalid URL";
        }
    }

    return errors
}

const TutorialVideoForm = (props) => {
    //  State
    const {
        is_update,
        loader,
        item,
    } = props;

    //  Bind
    const {handleSubmit} = props;

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="title">Title</label>
                        <Field
                            name="title"
                            label="Title"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-4 col-sm-12 mb-0">
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="index">Index</label>
                        <Field
                            name="index"
                            label="index"
                            component={renderNumber}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-group has-feedback col-lg-6 col-sm-12 mb-0">
                    <div className="form-group has-feedback w-100">
                        <label htmlFor="title">Url</label>
                        <Field
                            name="url"
                            label="Url"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div
                className={`row mx-auto mt-3 col-lg-5 col-sm-12 justify-content-between mt-4 mb-5`}
            >
                <a
                    className={`btn btn-danger text-white col-lg-5 col-sm-12`}
                    href="/#/tutorial-videos"
                >
                    {" "}
                    Cancel
                </a>
                <button
                    className={`btn btn-success col-lg-5 col-sm-12`}
                    disabled={loader}
                >
                    {is_update ? "update" : "create"}
                </button>
            </div>
        </form>
    )
}

let CampaignReduxForm = reduxForm({
    form: "tutorialVideoFrom", // a unique identifier for this form
    validate,
})(TutorialVideoForm);

export default CampaignReduxForm;