import React, { Component } from 'react'
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { GiftCardStripeChargeHistoryGrid, ModalStripe } from './GifCardStripeComponents';

class GiftCardStripe extends Component {
    state = {
        url: null,
        modalOnBoarding: false,
        modalOnConfiguring: false,
    }

    componentDidMount() {
        this.props.getGifCardStripeAccount(this.openModalOnBoarding, this.openModalOnConfiguring)
        this.props.getGiftCardChargeData()
    }

    openModalOnBoarding = () => {
        this.openLink();
        this.setState({
            modalOnBoarding: true,
            modalOnConfiguring: false
        });
    };

    openModalOnConfiguring = () => {
        this.openLink();
        this.setState({
            modalOnConfiguring: true,
            modalOnboarding: false,
        });
    };

    openLink = () => {
        const { linkOnBoard } = this.props;
        linkOnBoard(this.setUri);

    };

    setUri = (url) => {
        this.setState({ url });
    };

    fixMount = () => {
        const { balance } = this.props;
        let value = 0;
        try {
            value = balance.available[0].amount
        } catch (error) {
            
        }
        if (value > 0) {
            return (value / 100);
        }
        return 0;
    };

    render() {
        //  State
        const { loader, haveStripeAccount, stripeAccount, giftCardChargeData, giftCardChargePage, giftCardChargeLoader } = this.props;
        const { url, modalOnBoarding, modalOnConfiguring } = this.state;
        let bankAccount = [];
        
        if (stripeAccount && stripeAccount.external_accounts && stripeAccount.external_accounts.data) {
            bankAccount = stripeAccount.external_accounts.data;
        }
        //  Bind
        const { crateStripeAccounts, getGiftCardChargeData } = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Gift Card Stripe Account</div>
                    </div>
                </div>
                <div className="card border-card-page px-4 py-2 mt-1 mb-2">
                    <LoadMask loading={loader} light>
                        <div className="py-1">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="border-bottom d-flex flex-row justify-content-between">
                                            <h6 className="font-weight-bold">Total Purchased</h6>
                                            <h6 className="ml-2 font-weight-bold">$ {giftCardChargeData && giftCardChargeData.total_purchased > 0 ? parseFloat(giftCardChargeData.total_purchased).toFixed(2) : '0.00'}</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="border-bottom d-flex flex-row justify-content-between">
                                            <h6 className="font-weight-bold">Total Used</h6>
                                            <h6 className="ml-2 font-weight-bold">$ {giftCardChargeData && giftCardChargeData.total > 0 ? parseFloat(giftCardChargeData.total).toFixed(2) : '0.00'}</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="border-bottom d-flex flex-row justify-content-between">
                                            <h6 className="font-weight-bold">Remaining balance</h6>
                                            <h6 className="ml-2 font-weight-bold">$ {this.fixMount()} </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12">
                                        {haveStripeAccount ? (
                                            <div className="mt-3 col px-0">
                                                {bankAccount.length > 0 ?
                                                    bankAccount.map((bank, index) => {
                                                        return (
                                                            <div
                                                                className="d-flex flex-row justify-content-between border-bottom"
                                                                key={`bank-${index}`}
                                                            >
                                                                <p className="mb-1 text-12">{bank.bank_name || bank.brand}</p>
                                                                <p className="mb-1 text-12 justify-content-between">Account &nbsp;&nbsp; **** {bank.last4}</p>
                                                            </div>
                                                        );
                                                    })
                                                    :
                                                    <p> No bank accounts </p>
                                                }
                                            </div>
                                        ) : (
                                            <div className="mt-3 col px-0">
                                                <button type='button' className='btn btn-primary px-5 text-white align-self-center' onClick={() => crateStripeAccounts(this.openModalOnBoarding)}>
                                                    Create Account
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </LoadMask>
                </div>
                <GiftCardStripeChargeHistoryGrid
                    data={giftCardChargeData}
                    page={giftCardChargePage}
                    loader={giftCardChargeLoader}
                    toList={getGiftCardChargeData}
                />

                <ModalStripe
                    open={(modalOnBoarding || modalOnConfiguring) && url}
                    modalOnConfiguring={modalOnConfiguring}
                    url={url}
                />
            </div>
        )
    }
}


export default GiftCardStripe