import {api} from "api";
import moment from "moment";
import {push} from "react-router-redux";
import {handleActions} from "redux-actions";
import {change, initialize} from "redux-form";
import {getDashboardDateRange, STATE_USA_LIST, SELECT_ALL_OPTION} from "../../../utility/constants";
import broker from "../broker/broker";

const SET_DATA = "SET_DATA_DASHBOARD";
const SET_LOADER = "SET_LOADER_DASHBOARD";
const SET_LOADER_2 = "SET_LOADER_PERFORMANCE_DASHBOARD";
const SET_BROKERS = "SET_BROKERS_DASHBOARD";
const SET_BROKERAGES = "SET_BROKERAGES_DASHBOARD";
const SET_FRANCHISES = "SET_FRANCHISES_DASHBOARD";
const SET_CATEGORIES = "SET_CATEGORIES_DASHBOARD";
const SET_DATA_SHOW_IT = "SET_DATA_SHOW_IT_DASHBOARD";
const SET_DATA_SHOW_IT_CATEGORY = "SET_DATA_SHOW_IT_CATEGORY_DASHBOARD";
const SET_JOB_NAMES = "SET_JOB_NAMES_DASHBOARD";
const SET_SHOWIT_RANGER = "SET_SHOWIT_RANGER_DASHBOARD";
const SET_JOB_RANGER = "SET_JOB_RANGER_DASHBOARD";
const SET_DATA_JOB = "SET_DATA_JOB_DASHBOARD";
const SET_DATA_REVENUE_AGENTS = "SET_DATA_REVENUE_AGENTS_DASHBOARD";
const SET_DATA_CATEGORY = "SET_DATA_CATEGORY_DASHBOARD";
const SET_DATA_BROKER_OFFICE = "SET_DATA_BROKER_OFFICE_DASHBOARD";

// -----------------------------------------------------
// ------------------   ACTION      --------------------
// -----------------------------------------------------
const setLoader = (loader) => ({
    type: SET_LOADER,
    loader,
});

const setLoader2 = (loader2) => ({
    type: SET_LOADER_2,
    loader2,
});

const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});

const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});

const setOptionsFranchises = franchises => ({
    type: SET_FRANCHISES,
    franchises,
});
const setCategories = categories => ({
    type: SET_CATEGORIES,
    categories,
});
const setNameJob = (jobNames) => ({
    type: SET_JOB_NAMES,
    jobNames,
});
const setDataShowIT = dataShowIT => ({
    type: SET_DATA_SHOW_IT,
    dataShowIT,
});
const setDataShowITCategory = dataShowITCategory => ({
    type: SET_DATA_SHOW_IT_CATEGORY,
    dataShowITCategory,
});
const setShowITRanger = showITRanger => ({
    type: SET_SHOWIT_RANGER,
    showITRanger,
});
const setJobRanger = jobRanger => ({
    type: SET_JOB_RANGER,
    jobRanger,
});
const setDataJob = dataJob => ({
    type: SET_DATA_JOB,
    dataJob,
});
const setDataRevenueAgents = dataRevenueAgents => ({
    type: SET_DATA_REVENUE_AGENTS,
    dataRevenueAgents,
});
const setDataBrokerOffice = dataBrokerOffice => ({
    type: SET_DATA_BROKER_OFFICE,
    dataBrokerOffice,
});
const setDataCategory = dataCategory => ({
    type: SET_DATA_CATEGORY,
    dataCategory,
});

const changeFilters = (type, value=null) => (dispatch) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'state':
            // setTimeout(() => {
            //     dispatch(getBrokerOption(true))
            // }, 500)
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataRevenueAgents())
            }, 500)
            break;
        case 'franchise':
            setTimeout(() => {
                dispatch(getBrokerOption(true))
            }, 500)
            // dispatch(getBrokerOption(value, 'franchise', true))
            break;
        case 'broker':
            setTimeout(() => {
                dispatch(getBrokeragesOption(true))
            }, 500)
            break;
        case 'brokerage':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataRevenueAgents())
            }, 500)
            break;
        case 'category':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
            }, 500)
            break;
        case 'time':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
                dispatch(getDataBrokerOffice())
            }, 500)
            break;
        case 'start_date':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
            }, 500)
            break;
        case 'end_date':
            setTimeout(() => {
                dispatch(getDataShowIT())
                dispatch(getDataShowITCategories())
                dispatch(getDataJob())
                dispatch(getDataJobCategories())
            }, 500)
            break;
        default:
            break;
    }
}

const getBrokerOption =
    (getData = null, selectAll=false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { DashboardForm } = store.form;
        let params = {franchise: "", is_multi: true };
        if (DashboardForm && DashboardForm.values) {
            const { state, franchise} = DashboardForm.values;
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                if (franchise.length == 0) {
                    params.franchise = ""
                }else {
                    params.franchise = franchise;
                }
            }
            // if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            //     if (state.length == 0) {
            //         params.state = "null"
            //     }else {
            //         params.state = state;
            //     }
            // }
        }

        api.get(`general/option_broker`, params)
            .then((response) => {
                console.log("RESP BRK: ". response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    
                    if (selectAll) {
                        dispatch(
                            change("DashboardForm", "broker", SELECT_ALL_OPTION["value"])
                        );
                    }
                    setTimeout(() => {
                        dispatch(getBrokeragesOption(getData, selectAll));
                    }, 500);
                } else {
                    option = [...response.results];
                    const broker_id = option.length ? option[0].value : "";
                    dispatch(
                        change("DashboardForm", "broker", [broker_id])
                    );
                    setTimeout(() => {
                        dispatch(getBrokeragesOption(getData, selectAll));
                    }, 500);
                }
                dispatch(setBrokers(option));
            })
            .catch(() => {
                dispatch(setBrokers([]));
                dispatch(change("DashboardForm", "broker", []));
                setTimeout(() => {
                    dispatch(getBrokeragesOption(getData, selectAll));
                }, 500);
            })
            .finally(() => {
                // if (getData) {
                //     setTimeout(() => {
                //         dispatch(getDataShowIT())
                //         dispatch(getDataShowITCategories())
                //         dispatch(getDataJob())
                //         dispatch(getDataJobCategories())
                //         dispatch(getDataRevenueAgents())
                //     }, 500)
                // }
            });
    };

const getBrokeragesOption =
    (getData = null, selectAll=false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { DashboardForm } = store.form;
        let params = { broker_id: "", state: "", franchise: "", is_multi: true};
        if (DashboardForm && DashboardForm.values) {
            const { state, broker,  franchise } = DashboardForm.values;
            if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                if (broker.length == 0) {
                    params.broker_id = ""
                }else {
                    params.broker_id = broker;
                }
                // params.broker_id = broker;
            }
            // if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            //     if (state.length == 0) {
            //         params.state = "null"
            //     }else {
            //         params.state = state;
            //     }
            //     // params.state = state;
            // }
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                if (franchise.length == 0) {
                    params.franchise = ""
                }else {
                    params.franchise = franchise;
                }
                // params.franchise = franchise;
            }
        }

        api.get(`general/option_brokerage`, params)
            .then((response) => {
                console.log("RESP BRKGS: ". response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll) {
                        // let value = [];
                        // response.results.forEach(item => {
                        //     value.push(item.value)
                        // })
                        dispatch(
                            change(
                                "DashboardForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    const brokerage_id = option.length ? option[0].value : "";
                    dispatch(
                        change(
                            "DashboardForm",
                            "brokerage",
                            [brokerage_id]
                        )
                    );
                }
                dispatch(setBrokerages(option));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(change("DashboardForm", "brokerage", []));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(getDataShowIT())
                        dispatch(getDataShowITCategories())
                        dispatch(getDataJob())
                        dispatch(getDataJobCategories())
                        dispatch(getDataRevenueAgents())
                    }, 500)
                }
            });
    };

const getFranchisesOption = (getData = null, selectAll=false) => (dispatch) => {
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                // let value = [];
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                //     // value.push(franchise.id)
                // });
                if(selectAll) {
                    dispatch(
                        change(
                            "DashboardForm",
                            "franchise",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
            } else {
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                // });
                const franchise_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "DashboardForm",
                        "franchise",
                        [franchise_id]
                    )
                );
                dispatch(getBrokerOption(franchise_id, 'franchise'))
            }
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
            dispatch(change("DashboardForm", "franchise", ""));
            dispatch(getBrokerOption(null, 'franchise'))
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(getDataShowIT())
                    dispatch(getDataShowITCategories())
                    dispatch(getDataJob())
                    dispatch(getDataJobCategories())
                    dispatch(getDataRevenueAgents())
                }, 500)
            }
        });
};

const getCategoriesOption = (getData = null, selectAll=false) => (dispatch) => {
    api.get("general/option_job_category", {})
        .then((response) => {
            let options = [...response.results];
            if (selectAll) {
                // let value = [];
                // response.results.forEach(item => {
                //     value.push(item.value)
                // })

                dispatch(
                    change(
                        "DashboardForm",
                        "category",
                        SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setCategories(options));
        })
        .catch(() => {
            dispatch(setCategories([]));
            dispatch(change("DashboardForm", "category", ""));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(getDataShowIT())
                    dispatch(getDataJob())
                    dispatch(getDataJobCategories())
                    dispatch(getDataShowITCategories())
                }, 500)
            }
        });
};

const getAllJobType = () => (dispatch) => {
    api.get("job_type/all_job_type", {})
        .then((response) => {
            let JOB = [];
            response.map((nameJob) => {
                if (nameJob.job_type_option.length > 0) {
                    if(nameJob.name.toUpperCase().includes('VENDOR')) {
                        JOB.push({
                            ...nameJob,
                            primary_color: nameJob.job_type_option[0].primary_color
                        });
                    }else {
                        // JOB.push({title: true, name: nameJob.name})
                        nameJob.job_type_option.map((option) => {
                            JOB.push({
                                ...option,
                                isSub: false,
                                title: false,
                            });
                        });
                    }
                } else {
                    JOB.push(nameJob);
                }
            });
            dispatch(setNameJob(JOB));
        })
        .catch((error) => {
            console.log('error- ', error);
        })
        .finally(() => {
        });
};

const setRanger = (option, typeRange) => (dispatch) => {
    if (typeRange == "show_it") {
        // console.log("OPTION: ", option);
        dispatch(setShowITRanger(option))
        setTimeout(() => {
            dispatch(getDataShowIT())
            dispatch(getDataJob())
            dispatch(getDataJobCategories())
            dispatch(getDataShowITCategories())
        }, 800)
    }else {
        dispatch(setJobRanger(option))
        setTimeout(() => {
            dispatch(getDataJob())
        }, 800)
    }
}

const getDataShowIT = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, broker, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            // params.category = category;
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    // console.log("PARAMS: ", params);

    api.get("dashboard/show_it", params)
        .then((response) => {
            let dataReceived = []
            let labels = [];
            response.job_type.map((item) => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'usd' && names !== 'job_type_option__primary_color' && names !== 'cancellation_history') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function(o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data,
                    tension: 0.4,
                    usd: item.usd,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                    backgroundColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                })
            })
            response.job_option.map((item) => {
                let data2 = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'usd' && names !== 'job_type__name' && names !== 'job_type_option__primary_color' && names !== 'cancellation_history') {
                        if (item[names] === null) {
                            data2.push(0)
                        } else {
                            data2.push(item[names])
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data2,
                    tension: 0.4,
                    usd: item.usd,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.primary_color,
                    job_type_name: item.job_type__name,
                    backgroundColor: item.primary_color,
                })
            })
            let revenue1 = response.revenue1;
            let revenue2 = response.revenue2;
            if (revenue1 === undefined || revenue1 === null) {
                revenue1 = 0
            }
            if (revenue2 === undefined || revenue2 === null) {
                revenue2 = 0
            }
            let total = parseFloat(revenue1) + parseFloat(revenue2)
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                revenue: total,
            }
            dispatch(setDataShowIT(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader(false));
            }
        });
};

const getDataShowITCategories = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, broker, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            // params.category = category;
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/show_it_category", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'color' && names !== 'state_job' && names !== 'state' && names !== 'usd' && names !== 'background' && names !== 'count_job') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function (o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                                        
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: item.name,
                    borderWidth: 1.6,
                    usd: item.usd,
                    count_job: item.count_job,
                    color: item.color ? item.color : "#cecece",
                    backgroundColor: item.background ? item.background : "#cecece",
                })
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataShowITCategory(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataShowITCategory({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
};

const getDataJob = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, broker, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            // params.category = category;
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    // console.log("PARAMS: ", params);

    api.get("dashboard/jobs", params)
        .then((response) => {
            let dataReceived = []
            let labels = [];
            response.job_type.map((item) => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'score' && names !== 'job_type_option__primary_color') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function(o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data,
                    tension: 0.4,
                    score: item.score,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                    backgroundColor: item.job_type_option__primary_color ? item.job_type_option__primary_color : item.primary_color,
                })
            })
            response.job_option.map((item) => {
                let data2 = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'primary_color' && names !== 'count_job' && names !== 'score' && names !== 'job_type__name' && names !== 'job_type_option__primary_color') {
                        if (item[names] === null) {
                            data2.push(0)
                        } else {
                            data2.push(item[names])
                        }
                    }
                }
                dataReceived.push({
                    itemId: item.id,
                    data: data2,
                    tension: 0.4,
                    score: item.score,
                    label: item.name,
                    borderWidth: 1.6,
                    count: item.count_job,
                    borderColor: item.primary_color,
                    job_type_name: item.job_type__name,
                    backgroundColor: item.primary_color,
                })
            })
            let count1 = response.count1;
            let count2 = response.count2;
            if (count1 === undefined || count1 === null) {
                count1 = 0
            }
            if (count2 === undefined || count2 === null) {
                count2 = 0
            }
            let total = parseFloat(count1) + parseFloat(count2)
            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: total,
            }
            dispatch(setDataJob(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataJob({
                labels: [],
                datasets: [],
                total: 0,
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
};

const getDataJobCategories = (loader=true) => (dispatch, getStore) => {
    if (loader) {
        dispatch(setLoader2(true));
    }
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
        time: "",
        category: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, broker, brokerage, category, time, start_date, end_date } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                params.state = "null"
            }else {
                params.state = state;
            }
            // params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
            // params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
            // params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
        if (category !== "" && category !== null && category !== undefined && category !== SELECT_ALL_OPTION["value"]) {
            // params.category = category;
            if (category.length == 0) {
                params.category = [0]
            }else {
                params.category = category;
            }
        }
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    console.log("ENTRA FECHA INICIO: ", start_date);
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    console.log("ENTRA FECHA INICIO: ", end_date);
                    endDate = moment(end_date).format("DD-MM-YYYY")
                }
                console.log("DATES I: ", startDate);
                console.log("DATES E: ", endDate);
                params.time = [startDate, endDate];
            }else {
                params.time = time;
            }
        }else {
            params.time = getDashboardDateRange(1, "weeks");
        }
    }else {
        params.time = getDashboardDateRange(1, "weeks");
    }

    if(showITRanger) {
        params.frequency = showITRanger.value
    }

    api.get("dashboard/jobs_category", params)
        .then((response) => {
            // console.log("RESPONSE: ", response);
            let dataReceived = []
            let labels = [];

            response.data.forEach(item => {
                let data = []
                for (let names in item) {
                    if (names !== 'id' && names !== 'name' && names !== 'color' && names !== 'state_job' && names !== 'state' && names !== 'total' && names !== 'background') {
                        if (item[names] === null) {
                            data.push(0)
                        } else {
                            data.push(item[names])
                        }
                        const _name = _.find(labels, function (o) {
                            return o == names
                        })
                        if (!_name) {
                            labels.push(names);
                        }
                                        
                    }
                }
                dataReceived.push({
                    data: data,
                    tension: 0.4,
                    label: item.name,
                    borderWidth: 1.6,
                    total: item.total,
                    color: item.color ? item.color : "#cecece",
                    backgroundColor: item.background ? item.background : "#cecece",
                })
            });

            const dataFormatter = {
                labels: labels,
                datasets: dataReceived,
                total: response.total ? response.total : 0,
            }
            dispatch(setDataCategory(dataFormatter));
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataCategory({
                total: 0,
                labels: [],
                datasets: [],
            }))
        })
        .finally(() => {
            if (loader) {
                dispatch(setLoader2(false));
            }
        });
};

const getDataRevenueAgents = () => (dispatch, getStore) => {
    // dispatch(setLoader2(true));
    const store = getStore();
    const { jobRanger } = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        state: "",
        franchise: "",
        broker: "",
        brokerage: "",
    };
    if (DashboardForm && DashboardForm.values) {
        const { state, franchise, broker, brokerage, category, time } =
            DashboardForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            params.state = state;
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            params.franchise = franchise;
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            params.broker = broker;
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            params.brokerage = brokerage;
        }
    }

    api.get("dashboard/revenue", params)
        .then((response) => {
            if (response) {
                dispatch(setDataRevenueAgents(response.results));
            } else {
                dispatch(setDataRevenueAgents({
                    total_agent: 0,
                    total_user: 0,
                    posted: {},
                    applied: {},
                    pending: {},
                    total_to_revenue: {}
                }));
            }
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataRevenueAgents({
                total_agent: 0,
                total_user: 0,
                posted: {},
                applied: {},
                pending: {},
                total_to_revenue: {}
            }));
        })
        .finally(() => {
            // dispatch(setLoader2(false));
        });
};

const getDataBrokerOffice = () => (dispatch, getStore) => {
    const store = getStore();
    const {showITRanger} = store['dashboard'];
    const { DashboardForm } = store.form;
    let params = {
        start_date: '',
        end_date: '',
    }
    if (DashboardForm && DashboardForm.values) {
        const {time, start_date, end_date } =  DashboardForm.values;
        if (time !== "" && time !== null && time !== undefined) {
            if (time === 'custom') {
                let startDate = moment().format("DD-MM-YYYY")
                let endDate = moment().format("DD-MM-YYYY")

                if (start_date && start_date !== "" & start_date !== null && start_date !== undefined){
                    startDate = moment(start_date).format("DD-MM-YYYY")
                }
                if (end_date && end_date !== "" & end_date !== null && end_date !== undefined){
                    startDate = moment(end_date).format("DD-MM-YYYY")
                }
                // params.time = [startDate, endDate];
                params.start_date = startDate;
                params.end_date = endDate;
            }else {
                params.start_date = time[0];
                params.end_date = time[1];
            }
        }else {
            const _time  = getDashboardDateRange(1, "weeks");
            params.start_date = _time[0];
            params.end_date = _time[1];
        }
    }else {
        const _time  = getDashboardDateRange(1, "weeks");
        params.start_date = _time[0];
        params.end_date = _time[1];
    }
    api.get("dashboard/broker_office_stats", params)
        .then((response) => {
            if (response) {
                dispatch(setDataBrokerOffice(response.results));
            } else {
                dispatch(setDataRevenueAgents({
                    total_brokers: 0,
                    total_offices: 0,
                    brokers: [],
                    offices: []
                }));
            }
        })
        .catch((error) => {
            console.log("--- error job_by_week ---", error);
            dispatch(setDataRevenueAgents({
                total_brokers: 0,
                total_offices: 0,
                brokers: [],
                offices: []
            }));
        })
        .finally(() => {
        });
};

const initFilters = () => (dispatch) =>  {
    dispatch(initialize("DashboardForm", {state: "<SELECT_ALL>", category: "<SELECT_ALL>"}))
}

const goToJobRawData = (types) => (dispatch) => {
    dispatch(push({pathname: '/stats/job-detail-raw-data', search:`?status=${types}`}))
}

export const actions = {
    changeFilters,
    getBrokerOption,
    getBrokeragesOption,
    getFranchisesOption,
    getCategoriesOption,
    getAllJobType,
    getDataShowIT,
    getDataShowITCategories,
    setRanger,
    getDataJob,
    getDataJobCategories,
    getDataRevenueAgents,
    getDataBrokerOffice,
    getDataJobCategories,
    initFilters,
    goToJobRawData,
};


export const reducers = {
    [SET_LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_LOADER_2]: (state, {loader2}) => {
        return {
            ...state,
            loader2,
        };
    },
    [SET_BROKERS]: (state, {brokers}) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, {brokerages}) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_FRANCHISES]: (state, {franchises}) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_CATEGORIES]: (state, {categories}) => {
        return {
            ...state,
            categories,
        };
    },
    [SET_DATA_SHOW_IT]: (state, {dataShowIT}) => {
        return {
            ...state,
            dataShowIT,
        };
    },
    [SET_JOB_NAMES]: (state, {jobNames}) => {
        return {
            ...state,
            jobNames,
        };
    },
    [SET_SHOWIT_RANGER]: (state, {showITRanger}) => {
        return {
            ...state,
            showITRanger,
        };
    },
    [SET_JOB_RANGER]: (state, {jobRanger}) => {
        return {
            ...state,
            jobRanger,
        };
    },
    [SET_DATA_JOB]: (state, {dataJob}) => {
        return {
            ...state,
            dataJob,
        };
    },
    [SET_DATA_REVENUE_AGENTS]: (state, {dataRevenueAgents}) => {
        return {
            ...state,
            dataRevenueAgents,
        };
    },
    [SET_DATA_BROKER_OFFICE]: (state, {dataBrokerOffice}) => {
        return {
            ...state,
            dataBrokerOffice,
        };
    },
    [SET_DATA_CATEGORY]: (state, {dataCategory}) => {
        return {
            ...state,
            dataCategory,
        };
    },
    [SET_DATA_SHOW_IT_CATEGORY]: (state, {dataShowITCategory}) => {
        return {
            ...state,
            dataShowITCategory,
        };
    },
};

export const initialState = {
    // loader: false,
    // loader2: false,
    brokers: [],
    brokerages: [],
    franchises: [],
    categories: [],
    jobNames: [],
    showITRanger: {label: 'Daily', value: 'daily'},
    jobRanger: {label: 'Daily', value: 'daily'},
    dataShowIT: {
        labels: [],
        datasets: [],
        revenue: 0,
    },
    dataJob: {
        labels: [],
        datasets: [],
        total: 0,
    },
    dataRevenueAgents: {
        total_agent: 0,
        total_user: 0,
        posted: {},
        applied: {},
        pending: {},
        total_to_revenue: {}
    },
    dataBrokerOffice: {
        total_brokers: 0,
        total_offices: 0,
        brokers: [],
        offices: []
    },
    dataCategory: {
        total: 0,
        labels: [],
        datasets: [],
    },
    dataShowITCategory: {
        total: 0,
        labels: [],
        datasets: [],
    }
};

export default handleActions(reducers, initialState);
