import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {api} from "../../../utility/api";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";


// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "job_type";
export const {reducers, initialState, actions} = createReducer(
    "jobType",
    "job_type",
    "JobTypeEditForm",
    "/job-types",
);

actions.create = (data, files) => (dispatch) => {
    actions.setLoader(true)
    api.postAttachments("job_type", data, files).then(() => {
        NotificationManager.success('Record created', 'Success', 3000);
        dispatch(push("/job-types"));
    }).catch(() => {
        NotificationManager.error('Creation failed', 'ERROR');
    }).finally(() => {
        actions.setLoader(false)
    });
};

actions.edit = (id, data, files) => (dispatch) => {
    actions.setLoader(true)
    let finalFile = []
    if (files && files.length) {
        files.map(itemFile => {
            if (itemFile !== undefined) {
                finalFile.push(itemFile)
            }
        })
    }
    api.putAttachments(`job_type/${id}`, data, finalFile).then(() => {
        NotificationManager.success('Record updated', 'Success', 3000);
        dispatch(push("/job-types"));
    }).catch(() => {
        NotificationManager.error('Edition failed', 'ERROR');
    }).finally(() => {
        actions.setLoader(false)
    });
};

actions.toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["jobType"];
    const {JobTypeFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;
    if (JobTypeFilterForm && JobTypeFilterForm.values) {
        const {search} = JobTypeFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }
    dispatch(actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(actions.setData(response));
        dispatch(actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};
actions.searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(actions.toList());
    }, 400);
};

export default handleActions(reducers, initialState);
