import React, {Component} from 'react';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import SendgridCampaignForm from "./SendgridCampaignForm";
import { SubmissionError } from 'redux-form'
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";


const SendgridModal = (props) => {
    //  State
    const {open, item=null, total=0} = props;

    return (
        <Modal
            center
            showCloseIcon={false}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            open={open}
            classNames={{ modal: "rounded col-lg-4 col-11", closeIcon: "bg-transparent rounded" }}
        >
            <div className="d-flex flex-column mt-4">
                <h3 className='text-success text-center'>Sending Emails/Texts...</h3>
                <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                    <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw text-info"></i>
                    <h6 className='text-info mt-3'>{item.total_sent ? item.total_sent : 0} / {item.total_to_sent ? item.total_to_sent : 0}</h6>
                </div>
            </div>
        </Modal>
    )
}

class CreateUpdateSendgridCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
            image_error: null,
            agents_error: null
        };
    }

    componentWillMount() {
        const {match, readCampaign, getBrokerOption, getFranchisesOption, getTypesEmails, getTypesCampaigns} = this.props;
        if (match.params.id) {
            readCampaign(match.params.id);
        }else {
            getBrokerOption(null, true, true)
            getFranchisesOption(false, true)
        }
        getTypesEmails();
        getTypesCampaigns();
        if (match.path === '/sendgrid-campaigns/:id') {
            this.setState({is_view: true, is_update: false});

        }
        if (match.path === '/sendgrid-campaigns/:id/edit') {
            this.setState({is_view: false, is_update: true});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.agentsData.count !== prevProps.agentsData.count || this.props.unregisteredAgentsData.count !== prevProps.unregisteredAgentsData.count) {
            const {agentsData, unregisteredAgentsData} = this.props
            const _total = (agentsData && agentsData.count ? agentsData.coun : 0) + (unregisteredAgentsData && unregisteredAgentsData.count ? unregisteredAgentsData.coun : 0)
            if (_total > 0) {
                this.setAgentsRequired(true)
            }
        }
      }

    // setImageRequired = () => {
    //     this.setState({image_error: 'This field is required'})
    // }
    setAgentsRequired = (valid=false) => {
        this.setState({agents_error: valid==true ? null : 'The list is empty, at least you must have 1 agent'})
    }
    
    create = (data) => {
        const {agentsData, unregisteredAgentsData} = this.props;
        let valid = true
        const _total = (agentsData && agentsData.count ? agentsData.coun : 0) + (unregisteredAgentsData && unregisteredAgentsData.count ? unregisteredAgentsData.coun : 0)
        if (_total <= 0) {
            this.setAgentsRequired();
            valid = false;
        }

        if (valid) {
            this.props.createCampaign(data)
        }
    };

    update = (data) => {
        const {match, item} = this.props;
        const _id = data.id? data.id : match.params.id;

        const {agentsData, unregisteredAgentsData} = this.props;
        let valid = true
        const _total = (agentsData && agentsData.count ? agentsData.coun : 0) + (unregisteredAgentsData && unregisteredAgentsData.count ? unregisteredAgentsData.coun : 0)
        if (_total <= 0) {
            this.setAgentsRequired();
            valid = false;
        }

        if (valid) {
            this.props.updateCampaign(_id, data)
        }
    };

    onFileChange = (e, file, key) => {
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }
            reader.onload = (e) => {
                this.setState({ [key]: file, image_error: null });
            };
            reader.readAsDataURL(file);
        }
    }

    sendMessage = () => {
        const {match, item, sendMessage} = this.props;
        if (match.params.id && item.id) {
            sendMessage(match.params.id);
        }
    }

    markRedyToSend = () => {
        const {match, item, markRedyToSend} = this.props;
        if (match.params.id && item.id) {
            markRedyToSend(match.params.id, {ready_to_send: !item.ready_to_send});
        }
    }


    render() {
        //  State
        const {match, item, loader, franchises, brokers, brokerages, typesEmails, typesCampaigns, agentsData, agentsPage, unregisteredAgentsData, loaderAgentsData, unregisteredAgentsPage, sendgridModal, sendgridData} = this.props;
        const {is_view, is_update} = this.state;
        const sendFunction = match.params.id
            ? this.update
            : this.create;
        //  Bind
        const {changeFilters, getDataAgents} = this.props

        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page">
                            {is_view && "View Email"}
                            {is_update && "Update Email"}
                            {!is_update && !is_view && "Create Email"}
                        </div>
                    </div>
                </div>
                <div className="card border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4">
                    <LoadMask loading={loader} dark blur>
                        <SendgridCampaignForm
                            item={item}
                            loader={loader}
                            is_view={is_view}
                            onSubmit={sendFunction}
                            is_update={is_update}
                            onFileChange={this.onFileChange}
                            franchises={franchises}
                            brokers={brokers}
                            brokerages={brokerages}
                            typesEmails={typesEmails}
                            typesCampaigns={typesCampaigns}
                            data={agentsData}
                            page={agentsPage}
                            image={this.state['campaign_photo']}
                            // image_error={this.state.image_error}
                            agents_error={this.state.agents_error}
                            // setImageRequired={this.setImageRequired}
                            changeFilters={changeFilters}
                            getDataAgents={getDataAgents}
                            sendMessage={this.sendMessage}
                            markRedyToSend={this.markRedyToSend}
                            unregisteredAgentsData={unregisteredAgentsData}
                            loaderAgentsData={loaderAgentsData}
                            unregisteredAgentsPage={unregisteredAgentsPage}
                        />
                    </LoadMask>
                </div>

                <SendgridModal  open={sendgridModal} item={sendgridData}/>
            </div>
        )
    }
}

export default CreateUpdateSendgridCampaign;
