import {api} from "api";
import Swal from 'sweetalert2';
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";
import {SELECT_ALL_OPTION} from '../../../utility/constants'

// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "contact_tracker"
const OWNERS = "OWNERS_TRACKER";
const SEVERITIES = "SEVERITIES_TRACKER";
const TYPES = "TYPES_TRACKER";
const STATUSES = "STATUSES_TRACKER";
const AGREEMENTS = "AGREEMENTS_TRACKER";
const NOTE_MODAL = "NOTE_MODAL_TRACKER";
const ID_NOTE = "ID_NOTE_TRACKER";
const REMINDER_MODAL = "REMINDER_MODAL_TRACKER";

//-------------------------------------
// Base reducer
//-------------------------------------
export const { reducers, initialState, actions } = createReducer(
    "tracker",
    endpoint,
    "trackerFrom",
    "/tracker",
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setOwners = (owners) => ({
    type: OWNERS,
    owners,
});
const setSeverities = (severities) => ({
    type: SEVERITIES,
    severities,
});
const setTypes = (types) => ({
    type: TYPES,
    types,
});
const setStatuses = (statuses) => ({
    type: STATUSES,
    statuses,
});
const setAgreements = (agreements) => ({
    type: AGREEMENTS,
    agreements,
});
const setNoteModal= (noteModal) => ({
    type: NOTE_MODAL,
    noteModal,
});
const setIdNote= (idNote) => ({
    type: ID_NOTE,
    idNote,
});
const setReminderModal= (reminderModal) => ({
    type: REMINDER_MODAL,
    reminderModal,
});

actions.toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["trackerField"];
    const {TrackerFieldFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;

    if (TrackerFieldFilterForm && TrackerFieldFilterForm.values) {
        const {search, owner, severity, status, type, agreement} = TrackerFieldFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
        if (owner !== "" && owner !== null && owner !== undefined && owner !== SELECT_ALL_OPTION["value"]) {
            if (owner.length == 0) {
                params.owner__in = 0
            }else {
                params.owner__in = owner;
            }
        }
        if (severity !== "" && severity !== null && severity !== undefined && severity !== SELECT_ALL_OPTION["value"]) {
            if (severity.length == 0) {
                params.severity__in = 0
            }else {
                params.severity__in = severity;
            }
        }
        if (status !== "" && status !== null && status !== undefined && status !== SELECT_ALL_OPTION["value"]) {
            if (status.length == 0) {
                params.status__in = 0
            }else {
                params.status__in = status;
            }
        }
        if (type !== "" && type !== null && type !== undefined && type !== SELECT_ALL_OPTION["value"]) {
            if (type.length == 0) {
                params.type__in = 0
            }else {
                params.type__in = type;
            }
        }
        if (agreement !== "" && agreement !== null && agreement !== undefined && agreement !== SELECT_ALL_OPTION["value"]) {
            if (agreement.length == 0) {
                params.agreement__in = 0
            }else {
                params.agreement__in = agreement;
            }
        }
    }

    dispatch(actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(actions.setData(response));
        dispatch(actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
}

actions.searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(actions.toList());
    }, 400);
};

actions.getOptions = () => (dispatch) => {
    const _TYPES = {
        OWNER: 100,
        SEVERITY: 200,
        TYPE: 300,
        STATUS: 400,
        AGREEMENT: 500,
    }
    api.get('contact_tracker_field/options', {data_type: _TYPES.OWNER})
        .then((response) => {
            dispatch(setOwners(response && response.results ? response.results: []))
        })
    api.get('contact_tracker_field/options', {data_type: _TYPES.SEVERITY})
        .then((response) => {
            dispatch(setSeverities(response && response.results ? response.results: []))
        })
    api.get('contact_tracker_field/options', {data_type: _TYPES.TYPE})
        .then((response) => {
            dispatch(setTypes(response && response.results ? response.results: []))
        })
    api.get('contact_tracker_field/options', {data_type: _TYPES.STATUS})
        .then((response) => {
            dispatch(setStatuses(response && response.results ? response.results: []))
        })
    api.get('contact_tracker_field/options', {data_type: _TYPES.AGREEMENT})
        .then((response) => {
            dispatch(setAgreements(response && response.results ? response.results: []))
        })
}

actions.addRow = search => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.post(endpoint, {}).then((response) => {
        dispatch(actions.setLoader(false));
        dispatch(actions.toList())
        NotificationManager.success('Row added successfully', 'Success', 3000);
    }).catch((error) => {
        NotificationManager.error('It was not possible to add new row, try again later', 'ERROR', 3000);
        dispatch(actions.setLoader(false));
    })
}
actions.edit = (id, data) => (dispatch) => {
    dispatch(actions.setLoader(true));
    api.put(`${endpoint}/${id}`, data).then(() => {
        dispatch(actions.toList())
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};

actions.openNoteModal = (data, id=null) => (dispatch) => {
    dispatch(setIdNote(id))
    dispatch(initializeForm('trackerNoteFrom', data))
    setTimeout(() => {
        dispatch(setNoteModal(true))
    }, 600);
}

actions.closeNoteModal = () => (dispatch) => {
    dispatch(setNoteModal(false))
    dispatch(initializeForm('trackerNoteFrom', {}))
}

actions.saveNote = (data) => (dispatch, getStore) => {
    const store = getStore()
    const {idNote} = store['tracker']
    if (idNote) {
        api.put(`${endpoint}/update_note`, data).then(() => {
            dispatch(setNoteModal(false))
            dispatch(setIdNote(null))
            dispatch(initializeForm('trackerNoteFrom', {}))
            dispatch(actions.toList())
        }).catch(() => {
            NotificationManager.error('Could not update note, try again later', 'ERROR', 3000);
        }).finally(() => {
        });
    }else {
        api.post(`${endpoint}/create_note`, data).then(() => {
            dispatch(setNoteModal(false))
            dispatch(initializeForm('trackerNoteFrom', {}))
            dispatch(actions.toList())
        }).catch(() => {
            NotificationManager.error('Could not create note, try again later', 'ERROR', 3000);
        }).finally(() => {
        });
    }
}

actions.deleteNote = (id) => (dispatch) => {
    Swal.fire({
        title: 'Are you sure to delete the note?',
        text: "This action can't be reverted!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7E8A97',
        cancelButtonColor: '#B9BDC1',
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            api.eliminar(`${endpoint}/destroy_note`, {id}).then(() => {
                dispatch(actions.toList())
            }).catch(() => {
                NotificationManager.error('Could not delete note, try again later', 'ERROR', 3000);
            }).finally(() => {
            });
        }
    });
}

actions.openReminderModal = (data) => (dispatch) => {
    dispatch(initializeForm('trackerReminderFrom', data))
    setTimeout(() => {
        dispatch(setReminderModal(true))
    }, 600);
}

actions.closeReminderModal = () => (dispatch) => {
    dispatch(setReminderModal(false))
    dispatch(initializeForm('trackerReminderFrom', {}))
}

actions.saveReminder = (data) => (dispatch, getStore) => {
    api.post(`${endpoint}/config_reminder`, data).then(() => {
        dispatch(setReminderModal(false))
        dispatch(initializeForm('trackerReminderFrom', {}))
        dispatch(actions.toList())
    }).catch(() => {
        NotificationManager.error('Could not save the reminder, try again later', 'ERROR', 3000);
    }).finally(() => {
    });
}

// ------------------------------------
// Reducers
// ------------------------------------
reducers[OWNERS] = (state, { owners }) => {
    return {
        ...state,
        owners,
    };
}
reducers[SEVERITIES] = (state, { severities }) => {
    return {
        ...state,
        severities,
    };
}
reducers[TYPES] = (state, { types }) => {
    return {
        ...state,
        types,
    };
}
reducers[STATUSES] = (state, { statuses }) => {
    return {
        ...state,
        statuses,
    };
}
reducers[AGREEMENTS] = (state, { agreements }) => {
    return {
        ...state,
        agreements,
    };
}
reducers[NOTE_MODAL] = (state, { noteModal }) => {
    return {
        ...state,
        noteModal,
    };
}
reducers[ID_NOTE] = (state, { idNote }) => {
    return {
        ...state,
        idNote,
    };
}
reducers[REMINDER_MODAL] = (state, { reminderModal }) => {
    return {
        ...state,
        reminderModal,
    };
}

// ------------------------------------
// InitialState
// ------------------------------------
initialState.owners = []
initialState.severities = []
initialState.types = []
initialState.statuses = []
initialState.agreements = []
initialState.data = []
initialState.noteModal = false
initialState.idNote = null
initialState.reminderModal = false

export default handleActions(reducers, initialState);