import React, { Component } from 'react'
import VendorTextForm from './VendorTextForm'

export default class CompletingJobOffline extends Component {
    componentDidMount() {
        this.props.getSettings('vendorTextForm')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {loader, onSubmitVendorText} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Vendor Text</div>
                    </div>
                </div>
                <VendorTextForm
                    onSubmit={(data) => {
                        onSubmitVendorText(data)
                    }}
                    goBack={this.goBack}
                    loader={loader}
                    />
            </div>
        )
    }
}
