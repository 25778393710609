import React, { Component } from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import LeadsForm from './LeadsForm'

export default class Leads extends Component {
    state = {
        icons: [],
    }
    componentDidMount() {
        this.props.getSettings('leadsSettingForm')
    }

    goBack = () => {
        this.props.history.goBack()
    }

    setIcon = position => (icon) => {
        const {icons} = this.state;
        if (!!icons[position]) {
            icons[position].file = icon;
        } else {
            icons[position] = {file: icon, name: position};
        }
        this.setState({icons: [...icons]});
    }

    resetIcons = () => {
        this.setState({icons: []})
    }

    render() {
        const {loader, updateLeads} = this.props
        const { icons } = this.state
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Leads Info</div>
                    </div>
                </div>
                <div className="card general-container  border-dark border-card-page px-4 py-4">
                    <LoadMask loading={loader} dark blur>
                        <LeadsForm
                            onSubmit={(data) => {
                                console.log('SUB ICONS: ', icons);
                                updateLeads({...data}, [...icons], () => {
                                    this.resetIcons()
                                    location.reload()
                                })
                            }}
                            goBack={this.goBack}
                            setIcon={this.setIcon}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}