import _, { set } from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import {api} from '../../../../../utility/api';
import EditJobModal from './EditJob'
import ReactStars from "react-rating-stars-component";
import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import TrackingMap from "../../../Utils/Map/TrackingMap";
import {NotificationManager} from "react-notifications";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { Modal } from "react-responsive-modal";
import { SelectField, renderDayPicker, MultiSelectField, renderSearchField } from "../../../Utils/renderField";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import {
    SIZE_PER_PAGE,
    TEXT,
    TEXTAREA,
    NUMBER,
    BOOLEAN,
    OPTIONS_MAYBE,
    OPTIONS_NOTSURE,
    ANSWERS_SHOWING,
    ANSWERS_SHOWING_WAS_NOT_SHOWN,
    JOB_DETAILS_RAW_DATA_TIME,
    SELECT_ALL_OPTION,
} from "../../../../../utility/constants";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../../../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const currencyFormatted = (cell) => {
    return (
        <p className={"my-0 mx-0 px-0"}>
            $ {parseFloat(cell ? cell : 0).toFixed(2)}
        </p>
    );
};
const currencyAndYesNoFormatted = (cell) => {
    return (
        <p className={"my-0 mx-0 px-0"}>
            <span className={`mr-2 font-weight-bold ${cell && cell > 0 ? "text-success" : "text-danger"}`}>{cell && cell > 0 ? "Yes" : "No"}</span> $ {parseFloat(cell ? cell : 0).toFixed(2)}
        </p>
    );
};
const yesNoFormatted = (cell) => {
    return (
        <p className={"my-0 mx-0 px-0"}>
            {cell ? "Yes" : "No"}
        </p>
    );
};

const cancellationReasonFormatted = (cell, row) => {

    if (row.state_job == 35) {
        return (
            <div className="w-100 d-flex flex-column">
                {row.cancellation_reason_type_data && <span>{row.cancellation_reason_type_data.label} {cell ? ': ': ''} </span>}
                {cell && <span>{cell}</span>}
            </div>
        );
    } else {
        return null
    }

};

const PhotoModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-5 col-11",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="w-100 row py-2 px-0 mx-auto d-flex flex-column align-items-center">
                {/* <img src={url} alt="photo" className="img-fluid rounded mx-auto d-block" style={{width: '600px'}}/> */}
                {data && data.length ? (
                    <Carousel className="w-100">
                        {data.map((item, index) => {
                            return (
                                <div key={index}>
                                    <img
                                        src={item.url}
                                        className="img-fluid rounded mx-auto d-block"
                                    />
                                    <p className="legend font-weight-bold">
                                        {item.name}
                                    </p>
                                </div>
                            );
                        })}
                    </Carousel>
                ) : (
                    <h4>No images</h4>
                )}
            </div>
        </Modal>
    );
};


const EditHistory = (props) => {
    const {data} = props;
    console.log("DATA HISTORY: ", data);
    const {goBack} = props;
    return (
        <div className="w-100 d-flex flex-column">
            <div className="col-12">
                <div className="row">
                    {Object.keys(data).map((item, i) => {
                        return (
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <p className="font-weight-bold mb-1">{data[item].name}</p>
                                <div className="d-flex flex-column">
                                    {data[item].before != null && data[item].before != undefined && (
                                        <span className="pl-2 text-justify" style={{textDecoration: 'line-through'}}>{data[item].before}</span>
                                    )}
                                    {data[item].after != null && data[item].after != undefined  && (
                                        <span className="pl-2 font-weight-bold text-success text-justify">{data[item].after}</span>
                                    )}
                                    {data[item].data && (
                                        Object.keys(data[item].data).map((data_key, data_index) => {
                                            const item_data = data[item].data[data_key]
                                            return (
                                                <div className="d-flex flex-column pl-1">
                                                    <p className="font-weight-bold mb-1">{item_data.name}</p>
                                                    {item_data.before != null && item_data.before != undefined && (
                                                        <span className="pl-2 text-justify" style={{textDecoration: 'line-through'}}>{item_data.before}</span>
                                                    )}
                                                    {item_data.after != null && item_data.after != undefined && (
                                                        <span className="pl-2 font-weight-bold text-success text-justify">{item_data.after}</span>
                                                    )}
                                                </div>
                                            )
                                        })
                                    )}
                                    {data[item].list && (
                                        data[item].list.map((list_item, list_index) => {
                                            return (
                                                <div className="d-flex flex-column pl-1">
                                                    {list_item.no != null && list_item.no != undefined ? (
                                                        <p className="font-weight-bold mb-1"># {list_item.no}</p>
                                                    ) : (
                                                        <p className="font-weight-bold mb-1">#</p>
                                                    )}
                                                    {list_item.before != null && list_item.before != undefined && (
                                                        <span className="pl-2 text-justify" style={{textDecoration: 'line-through'}}>{list_item.before}</span>
                                                    )}
                                                    {list_item.after != null && list_item.after != undefined && (
                                                        <span className="pl-2 font-weight-bold text-success text-justify">{list_item.after}</span>
                                                    )}
                                                </div>
                                            )
                                        })
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const ActionHistory = (props) => {
    const {data} = props;
    console.log("DATA HISTORY: ", data);

    const makeValueType = (type, value, timezone=null) => {
        switch (type) {
            case "date":
                return momentTz(value).tz(timezone).format("ll");
                break;
            case "time":
                return momentTz(value).tz(timezone).format("LT");
                break;
            case "currency":
                return `$ ${parseFloat(value).toFixed(2)}`;
                break;
            default:
                return value;
                break;
        }

    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="col-12">
                <div className="row">
                    {data && data.items && data.items.length && data.items.map((item, i) => {
                        return (
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <p className="font-weight-bold mb-1">{item.name}</p>
                                <span className="pl-2 font-weight-bold text-success text-justify">{makeValueType(item.type, item.value, data.timezone ? data.timezone : null)}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

const ApplicantsModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;
    const R_CANCEL = 35;

    const [results, setResults] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dataHistory, setDataHistory] = useState(null);
    const [titleHistory, setTitleHistory] = useState("Edit History");
    const [isActionHistory, setIsActionHistory] = useState(false);

    useEffect(() => {
      if(data && data.job_id) {
        setLoader(true)
        api.get("broker-user/job_applicants", { id: data.job_id })
            .then((response) => {
                if(response) {
                    console.log("RESPONSE APPLICANTS: ", response);
                    const r_agent = response.r_agent ? response.r_agent : null;
                    let _results = [];
                    if (r_agent) {
                        _results.push(r_agent)
                    }
                    const _resultsAgents = response.results ? response.results : [];
                    setResults([..._results, ..._resultsAgents])
                }

            })
            .finally(() => {
                setLoader(false);
            });
      }
    
      return () => {
        setResults([])
        setLoader(false)
        setTitleHistory("Edit History")
        setDataHistory(null)
        setIsActionHistory(false)
      }
    }, [data])
    

    const actions = [
        { name: "Applied", id: 5, bg: "bg-gray", txt: "text-black" },
        {name: "Counter Offer", id: 6, bg: "bg-info", txt: "text-white"},
        { name: "Rejected", id: 10, bg: "bg-warning", txt: "text-white" },
        {
            name: "Canceled Application",
            id: 15,
            bg: "bg-danger",
            txt: "text-white",
        },
        { name: "Hired", id: 20, bg: "bg-info", txt: "text-white" },
        { name: "Claimed", id: 25, bg: "bg-info", txt: "text-white" },
        { name: "Completed", id: 30, bg: "bg-success", txt: "text-white" },
        { name: "Canceled", id: 35, bg: "bg-danger", txt: "text-white" },
    ];

    const getAction = (action) => {
        let result = null;
        if (action) {
            const _action = _.find(actions, { id: action });
            if (_action) {
                result = _action;
            }
        }

        return result;
    };

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={(e) => {
                if (dataHistory) {
                    setIsActionHistory(false)
                    setTitleHistory("Edit History")
                    setDataHistory(null)
                }else {
                    onClose()
                }
            }}
            classNames={{
                modal: "rounded col-lg-9 col-11",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            {dataHistory && (
                                <button className='btn back-btn mr-3 my-2' onClick={() => setDataHistory(null)}>
                                    <i className="material-icons pointer">arrow_back</i> 
                                </button>
                            )}
                            {dataHistory ? titleHistory: "Agents that applied for the job"}
                        </div>
                    </div>
                </div>
                {!dataHistory ? (
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            striped
                            remote
                            hover
                            containerClass="flex-1"
                            tableContainerClass="table-scroll-auto"
                            containerStyle={{minHeight: '300px'}}
                            data={results}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                width="0px"
                                dataFormat={(cell, row) => {
                                    return <div />;
                                }}
                            />
                            <TableHeaderColumn
                                width="150px"
                                dataField="agent__first_name"
                                dataFormat={(cell, row) => {
                                    // return <span>{row.agent.first_name}</span>;

                                    return (
                                        <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`/agents/agent/${row.agent.id}/edit`}
                                        className="w-100 text-primary"
                                        >
                                            {row.agent.first_name}
                                        </Link>
                                    )
                                }}
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="150px"
                                dataField="agent__last_name"
                                dataFormat={(cell, row) => {
                                    // return <span>{row.agent.last_name}</span>;
                                    
                                    return (
                                        <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`/agents/agent/${row.agent.id}/edit`}
                                        className="w-100 text-primary"
                                        >
                                            {row.agent.last_name}
                                        </Link>
                                    )
                                }}
                            >
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="150px"
                                dataField="agent__name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <span>
                                            {row.agent.brokerage_information
                                                ? row.agent.brokerage_information
                                                    .franchise
                                                : ""}
                                        </span>
                                    );
                                }}
                            >
                                Franchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="150px"
                                dataField="agent__name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <span>
                                            {row.agent.brokerage_information
                                                ? row.agent.brokerage_information
                                                    .firm_name
                                                : ""}
                                        </span>
                                    );
                                }}
                            >
                                Office Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="150px"
                                dataField="type"
                                dataFormat={(cell, row) => {
                                    return <span>{row.type == "R_AGENT" ? "Hiring Agent": "Showing Agent"}</span>;
                                }}
                            >
                                Type
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                width="430px"
                                dataField="id"
                                dataFormat={(cell, row) => {
                                    if (row.type == "R_AGENT") {
                                        return (
                                            <div className="d-flex align-content-between flex-wrap">
                                                {row.history.map((item, index) => {
                                                    let titleStatus = ''
                                                    let color = "bg-info"
                                                    let typeApplication = null
                                                    let itemDate = moment(item.created).format("lll")
                                                    if (item.type == 100) {
                                                        titleStatus = "Created"
                                                        if (item.data) {
                                                            console.log("DATA: ", item.data);
                                                            const keys = Object.keys(item.data)
                                                            console.log("KEYS: ", keys);
                                                            const _typeApplicationKey = _.find(keys, function(o) {
                                                                return o == 'type_application'
                                                            })
                                                            console.log("KEY: ", _typeApplicationKey);
                                                            if (_typeApplicationKey) {
                                                                const _typeApplication = item.data['type_application']
                                                                typeApplication = _typeApplication && _typeApplication["after"] ? _typeApplication["after"] : null
                                                            }
                                                        }
                                                    } else if (item.type == 200) {
                                                        titleStatus = "Edited"
                                                    } else if (item.type == 300) {
                                                        itemDate = item.data ? moment(item.data.date).format("lll") : itemDate
                                                        color = item.data ? (item.data.state == 'Completed' ? "bg-success" : "bg-danger") : null
                                                        titleStatus = item.data ? `By Job not Complete Process (${item.data.state})`  : "By Job not Complete Process ()"
                                                    }
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 ${color} text-white ${item.type == 200 ? "pointer" : ""}`}
                                                            style={{
                                                                fontSize:
                                                                    '12px',
                                                                width: "125px",
                                                            }}
                                                            onClick={(e) => {
                                                                if (item.type == 200) {
                                                                    setDataHistory(item.data)
                                                                }
                                                            }}
                                                            title={item.type == 100 ? "": "click to see the detail"}
                                                        >
                                                            <span className="font-weight-bold" style={{fontSize: '13px'}}>
                                                                {titleStatus}{typeApplication ? ` / ${typeApplication}` : ''}
                                                            </span>
                                                            <span className="font-weight-lighter">
                                                                {itemDate}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )
                                    }
                                    return (
                                        <div>
                                            {row.job_history &&
                                            row.job_history.length ? (
                                                <div className="d-flex align-content-between flex-wrap">
                                                    {row.job_history.map(
                                                        (item, index) => {
                                                            const _action = getAction(
                                                                item.action
                                                            );
                                                            if (_action) {
                                                                return (
                                                                    <div
                                                                        className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 ${_action.bg} ${_action.txt}  ${item.data != null && item.data != undefined ? "pointer" : ""}`}
                                                                        style={{
                                                                            fontSize:
                                                                                "10px",
                                                                            width: "120px",
                                                                        }}
                                                                        onClick={(e) => {
                                                                            if (item.data != null && item.data != undefined) {
                                                                                setIsActionHistory(true)
                                                                                setTitleHistory(_action.name)
                                                                                setDataHistory({items: item.data, timezone: item.timezone})
                                                                            }
                                                                        }}
                                                                    >
                                                                        <span className="font-weight-bold">
                                                                            {
                                                                                _action.name
                                                                            }
                                                                        </span>
                                                                        <span className="font-weight-lighter">
                                                                            {moment(
                                                                                item.created
                                                                            ).format(
                                                                                "lll"
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    )}
                                                    {/* IT IS VALIDATED IF IT IS NO SHOW */}
                                                    {data && data.no_show && (
                                                        <div
                                                            className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 bg-warning text-white`}
                                                            style={{
                                                                fontSize: "10px",
                                                                width: "120px",
                                                            }}
                                                        >
                                                            <span className="font-weight-bold">
                                                                No Show
                                                            </span>
                                                            <span className="font-weight-lighter">
                                                                {data.date_no_show
                                                                    ? moment(
                                                                        data.date_no_show
                                                                    ).format(
                                                                        "lll"
                                                                    )
                                                                    : ""}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {data &&
                                                        data.state_job == R_CANCEL && (
                                                            <div
                                                                className={`rounded d-flex flex-column justify-content-center align-items-center m-1 p-1 bg-danger text-white`}
                                                                style={{
                                                                    fontSize: "10px",
                                                                    width: "120px",
                                                                }}
                                                            >
                                                                <span className="font-weight-bold">
                                                                    R-Agent Canceled
                                                                </span>
                                                                <span className="font-weight-lighter">
                                                                    {data.cancellation_date
                                                                        ? moment(
                                                                            data.cancellation_date
                                                                        ).format(
                                                                            "lll"
                                                                        )
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        )}
                                                </div>
                                            ) : (
                                                <div>
                                                    {row.accepted && !row.reviewed ? (
                                                        <span className="bg-success rounded p-1 font-weight-bold text-white">
                                                            Accepted
                                                        </span>
                                                    ) : null}
                                                    {row.canceled ? (
                                                        <span className="bg-danger rounded p-1 font-weight-bold text-white">
                                                            Canceled
                                                        </span>
                                                    ) : null}
                                                    {row.rejected ? (
                                                        <span className="bg-warning rounded p-1 font-weight-bold text-white">
                                                            Rejected
                                                        </span>
                                                    ) : null}
                                                    {row.accepted && row.reviewed ? (
                                                        <span className="bg-info rounded p-1 font-weight-bold text-white">
                                                            Reviewed
                                                        </span>
                                                    ) : null}
                                                    {!row.accepted &&
                                                    !row.canceled &&
                                                    !row.rejected &&
                                                    !row.reviewed ? (
                                                        <span className="bg-gray rounded p-1 font-weight-bold text-black">
                                                            Applied
                                                        </span>
                                                    ) : null}
                                                    {data && data.no_show && (
                                                        <span className="bg-danger rounded p-1 font-weight-bold text-white">
                                                            No Show
                                                        </span>
                                                    )}
                                                    {data &&
                                                        data.state_job == R_CANCEL && (
                                                            <span className="bg-danger rounded p-1 font-weight-bold text-white">
                                                                R-Agent Canceled
                                                            </span>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            >
                                Actions
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                width="150px"
                                dataField="id"
                                dataFormat={(cell, row) => {
                                    return (
                                        <span>{moment(row.modified).format("lll")}</span>
                                    );
                                }}
                            >
                                Date & Time
                            </TableHeaderColumn> */}
                        </BootstrapTable>
                    </LoadMask>
                ): (
                    isActionHistory ? (
                        <ActionHistory data={dataHistory}/>
                    ): (
                        <EditHistory data={dataHistory} goBack={() => setDataHistory(null)}/>
                    )
                )}
            </div>
        </Modal>
    );
};

const AgentsModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-11",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">Agents</div>
                    </div>
                </div>

                <BootstrapTable striped remote hover data={data}>
                    <TableHeaderColumn
                        dataField="agent_id"
                        isKey={true}
                        width="0px"
                        dataFormat={(cell, row) => {
                            return <div />;
                        }}
                    />
                    <TableHeaderColumn width="150px" dataField="first_name">
                        Name
                    </TableHeaderColumn>
                    <TableHeaderColumn width="150px" dataField="last_name">
                        Last Name
                    </TableHeaderColumn>
                    <TableHeaderColumn width="150px" dataField="email">
                        Email
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </Modal>
    );
};

const CancellationPoliciesModal = (props) => {
    //  State
    const { open } = props;
    //  Bind
    const { onClose } = props;

    const data = [
        {
            id: 1,
            type: 1,
            description:
                "For cancellations at least two (2) hours in advance of the accepted job start time there shall be no cancellation fee",
            agent: "D and R Agent",
        },
        {
            id: 2,
            type: 2,
            description:
                "For job cancellations up to 30 minutes “grace Period” after claiming a job of being hired to a job, the agent can cancel the job without any penalty",
            agent: "D and R Agent",
        },
        {
            id: 3,
            type: 3,
            description:
                "For job cancellation of 2 hours or less in advance of the accepted job start time, and after the “Grace Period” time, Immediate $30 penalty to the cancelled agent ($25 to the cancelling agent and $5 to ShowIT)",
            agent: "D and R Agent",
        },
        {
            id: 4,
            type: 4,
            description:
                "In instances where a job was posted 24 hours or more before the job’s start time and the hiring agent’s decision to hire was four (4) hours or less before the job’s start time, the Showing agent may cancel the job without penalty. ($25 to the cancelling agent and $5 to ShowIT)",
            agent: "D Agent",
        },
        // {
        //     id: 5,
        //     type: 5,
        //     description:
        //         "Jobs posted 24hours or more before job start time and hiring agent took decision to hire 4 hours or less before job start time. If showing agent cancel a job it will be one of the two allowed cancellation during the month",
        //     agent: "D Agent",
        // },
        // {
        //     id: 6,
        //     type: 6,
        //     description:
        //         "Less than (2) hours in advance of the accepted Job start time $30 penalty",
        //     agent: "R Agent",
        // },
        {
            id: 5,
            type: 5,
            description: "Job has been cancelled due to Showing agent No Show",
            agent: "R Agent",
        },
        {
            id: 6,
            type: 6,
            description:
                "Job has been cancelled due to showing agent not meeting all job requirements",
            agent: "R Agent",
        },
    ];

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-sm-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Cancellation Policies
                        </div>
                    </div>
                </div>

                <BootstrapTable striped remote hover data={data}>
                    <TableHeaderColumn
                        dataField="id"
                        isKey={true}
                        width="0px"
                        dataFormat={(cell, row) => {
                            return <div />;
                        }}
                    />
                    <TableHeaderColumn
                        width="50px"
                        dataField="type"
                        dataFormat={(cell, row) => {
                            return (
                                <h5 className="font-weight-bold mb-0">
                                    {cell}
                                </h5>
                            );
                        }}
                    >
                        Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="description"
                        dataFormat={(cell, row) => {
                            return <span>{cell}</span>;
                        }}
                    >
                        Description
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="120px"
                        dataField="agent"
                        dataFormat={(cell, row) => {
                            return (
                                <span className="font-weight-bold mb-0">
                                    {cell}
                                </span>
                            );
                        }}
                    >
                        Agent
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        </Modal>
    );
};

const CancellationReasonsModal = (props) => {
    //  State
    const { open } = props;
    //  Bind
    const { onClose } = props;

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        api.get("app_cancellation_delete_option/options", { type: 100 })
            .then((response) => {
                if (response) {
                    console.log("RESPONSE APPLICANTS: ", response);
                    const { results } = response;
                    setData(results ? results : []);
                }
            })
            .catch((error) => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
            });

        return () => {
            setData([]);
            setLoader(false);
        };
    }, []);

    // const data = [
    //     {
    //         id: 1,
    //         type: 1,
    //         description:
    //             "Not needed anymore",
    //     },
    //     {
    //         id: 2,
    //         type: 2,
    //         description:
    //             "Client cancelled",
    //     },
    //     {
    //         id: 3,
    //         type: 3,
    //         description:
    //             "Found an agent by other means",
    //     },
    //     {
    //         id: 4,
    //         type: 4,
    //         description:
    //             "House not available anymore",
    //     },
    //     {
    //         id: 5,
    //         type: 5,
    //         description: "Job was not picked up",
    //     },
    //     {
    //         id: 6,
    //         type: 6,
    //         description:
    //             "Other",
    //     },
    // ];

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-sm-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between">
                    <div className="">
                        <div className="header-page col">
                            Cancellation Reason
                        </div>
                    </div>
                </div>
                <LoadMask loading={loader} dark blur>
                    <BootstrapTable striped remote hover data={data} containerClass="flex-1"
                            tableContainerClass="table-scroll-auto">
                        <TableHeaderColumn
                            dataField="id"
                            isKey={true}
                            width="0px"
                            dataFormat={(cell, row) => {
                                return <div />;
                            }}
                        />
                        <TableHeaderColumn
                            width="50px"
                            dataField="value"
                            dataFormat={(cell, row) => {
                                return (
                                    <h5 className="font-weight-bold mb-0">
                                        {cell}
                                    </h5>
                                );
                            }}
                        >
                            Type
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="label"
                            dataFormat={(cell, row) => {
                                return <span>{cell}</span>;
                            }}
                        >
                            Description
                        </TableHeaderColumn>
                    </BootstrapTable>
                </LoadMask>
            </div>
        </Modal>
    );
};

const ClientReviewModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;
    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-4 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Client Review
                        </div>
                    </div>
                </div>
                {data && data.feedback && data.feedback.length
                    ? data.feedback.map((item, index) => {
                          return (
                              <div className="form-group" key={index}>
                                  <label htmlFor="">{item.question}</label>
                                  {item.type == 5 ? (
                                      <textarea
                                          style={{ resize: "none" }}
                                          className="form-control text-area-h-auto"
                                          disabled="true"
                                          value={item.answer}
                                          rows={6}
                                      />
                                  ) : null}

                                  {item.type == 10 ? (
                                      <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                                          <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center">
                                              <label>YES</label>
                                              <label className="needsclick">
                                                  <input
                                                      type="checkbox"
                                                      disabled={true}
                                                      checked={
                                                          item.answer != "N/A"
                                                              ? item.answer ==
                                                                "Yes"
                                                                  ? true
                                                                  : false
                                                              : null
                                                      }
                                                  />
                                                  <span className="fa fa-check" />
                                                  {/* &nbsp;{label} */}
                                              </label>
                                          </div>
                                          <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center ">
                                              <label>No</label>
                                              <label className="needsclick">
                                                  <input
                                                      type="checkbox"
                                                      disabled={true}
                                                      checked={
                                                          item.answer != "N/A"
                                                              ? item.answer ==
                                                                "No"
                                                                  ? true
                                                                  : false
                                                              : null
                                                      }
                                                  />
                                                  <span className="fa fa-check" />
                                                  {/* &nbsp;{label} */}
                                              </label>
                                          </div>
                                      </div>
                                  ) : null}
                              </div>
                          );
                      })
                    : null}
            </div>
        </Modal>
    );
};

const JobFeedbackModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;

    const getValueSpecialAnswer = (value, was_not_shown) => {
        let result = "";

        if (was_not_shown) {
            const _item = _.find(ANSWERS_SHOWING_WAS_NOT_SHOWN, { id: value });

            result = _item ? _item.name : "";
        } else {
            const _item = _.find(ANSWERS_SHOWING, { id: value });

            result = _item ? _item.name : "";
        }

        return result;
    };

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-4 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Job Feedback
                        </div>
                    </div>
                </div>
                <div className="row">
                    {data &&
                        data.special_answer &&
                        data.special_answer.showing_especial_value && (
                            <div className="form-group col-sm-12">
                                <label htmlFor="">
                                    {data.was_not_shown
                                        ? "House was not shown?"
                                        : "Was the house shown?"}
                                </label>
                                <input
                                    style={{ resize: "none" }}
                                    className="form-control"
                                    disabled="true"
                                    value={getValueSpecialAnswer(
                                        data.special_answer
                                            .showing_especial_value,
                                        data.was_not_shown
                                    )}
                                    type="text"
                                />
                            </div>
                        )}
                    {data && data.answers && data.answers.length
                        ? data.answers.map((item, index) => {
                              return (
                                  <div
                                      className="form-group col-sm-12"
                                      key={index}
                                  >
                                      <label htmlFor="">
                                          {item.question_text}
                                      </label>
                                      {item.question_type == TEXTAREA ? (
                                          <textarea
                                              style={{ resize: "none" }}
                                              className="form-control text-area-h-auto"
                                              disabled="true"
                                              value={item.answer}
                                              rows={6}
                                          />
                                      ) : null}
                                      {item.question_type == TEXT ||
                                      item.question_type == NUMBER ? (
                                          <input
                                              style={{ resize: "none" }}
                                              className="form-control"
                                              disabled="true"
                                              value={item.answer}
                                              type={
                                                  item.question_type == TEXT
                                                      ? "text"
                                                      : "number"
                                              }
                                          />
                                      ) : null}

                                      {item.question_type == BOOLEAN ? (
                                          <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                                              <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center">
                                                  <label>YES</label>
                                                  <label className="needsclick">
                                                      <input
                                                          type="checkbox"
                                                          disabled={true}
                                                          checked={
                                                              item.answer_boolean ==
                                                              1
                                                                  ? true
                                                                  : false
                                                          }
                                                      />
                                                      <span className="fa fa-check" />
                                                  </label>
                                              </div>
                                              <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center ">
                                                  <label>No</label>
                                                  <label className="needsclick">
                                                      <input
                                                          type="checkbox"
                                                          disabled={true}
                                                          checked={
                                                              item.answer_boolean ==
                                                              0
                                                                  ? true
                                                                  : false
                                                          }
                                                      />
                                                      <span className="fa fa-check" />
                                                  </label>
                                              </div>
                                          </div>
                                      ) : null}
                                      {item.question_type == OPTIONS_MAYBE ||
                                      item.question_type == OPTIONS_NOTSURE ? (
                                          <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                                              <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center">
                                                  <label>YES</label>
                                                  <label className="needsclick">
                                                      <input
                                                          type="checkbox"
                                                          disabled={true}
                                                          checked={
                                                              item.answer_boolean ==
                                                              1
                                                                  ? true
                                                                  : false
                                                          }
                                                      />
                                                      <span className="fa fa-check" />
                                                  </label>
                                              </div>
                                              <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center ">
                                                  <label>
                                                      {item.question_type ==
                                                      OPTIONS_MAYBE
                                                          ? "Maybe"
                                                          : "Not Sure"}
                                                  </label>
                                                  <label className="needsclick">
                                                      <input
                                                          type="checkbox"
                                                          disabled={true}
                                                          checked={
                                                              item.answer_boolean ==
                                                              2
                                                                  ? true
                                                                  : false
                                                          }
                                                      />
                                                      <span className="fa fa-check" />
                                                  </label>
                                              </div>
                                              <div className="checkbox c-checkbox d-flex flex-column px-3 align-items-center justify-content-center ">
                                                  <label>No</label>
                                                  <label className="needsclick">
                                                      <input
                                                          type="checkbox"
                                                          disabled={true}
                                                          checked={
                                                              item.answer_boolean ==
                                                              0
                                                                  ? true
                                                                  : false
                                                          }
                                                      />
                                                      <span className="fa fa-check" />
                                                  </label>
                                              </div>
                                          </div>
                                      ) : null}
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
        </Modal>
    );
};

const RowDetail = (props) => {
    const {
        id,
        viewPhoto,
        isValueExperience,
        hasVisitorReport,
        downloadVisitorReport,
        openJobFeedbackModal,
        noShowData,
        photoVideoLinks=[],
        hiredAgentNote="",
        trackingDistance=null,
        openTrackingModal=() => {},
        jobCompleted=false,
    } = props;

    const copyLinks = async(links) => {
        try {
            const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(links);
            } else {
                unsecuredCopyToClipboard(links);
            }
            NotificationManager.info("The links have been copied to the clipboard")
        } catch (e) {
            console.log(e);
        }
    }
    const downloadFile = (file) => {
        // var link = document.createElement("a");
        const url = file.url;
        const splitPart = file.media_type == 1 ? "media/job/photo/" : "media/job/video/"
        const parts = url.split(splitPart);
        console.log("PARTS: ", parts);
        const filename = parts.length > 1 ? parts[1] : "file";
        fetch(url).then(res => res.blob()).then(file => {
            let tempUrl = URL.createObjectURL(file);
            const aTag = document.createElement("a");
            aTag.href = tempUrl;
            aTag.download = filename;
            document.body.appendChild(aTag);
            aTag.click();
            URL.revokeObjectURL(tempUrl);
            aTag.remove();
        }).catch(() => {
        });
    }

    const donwloadFiles = (files) => {
        NotificationManager.info("Downloading...")
        files.forEach(file => {
            downloadFile(file);
        });
    } 

    return (
        <BootstrapTable containerClass="row-detail" data={props.data}>
            <TableHeaderColumn
                dataField="id"
                isKey={true}
                width="0px"
                dataFormat={(cell, row) => {
                    return <div />;
                }}
            />
            <TableHeaderColumn width="150px" dataField="state">
                State
            </TableHeaderColumn>
            <TableHeaderColumn width="150px" dataField="city">
                City
            </TableHeaderColumn>
            <TableHeaderColumn width="450px" dataField="address">
                Address
            </TableHeaderColumn>
            <TableHeaderColumn width="150px" dataField="zip_code">
                Zip Code
            </TableHeaderColumn>
            <TableHeaderColumn width="150px" dataField="zip_code"
                dataFormat={(cell, row) => {
                    const PENDING = 100;
                    const APPROVED = 200;
                    const REJECTED = 300;
                    let status = "";
                    let color = "bg-gray font-weight-bold text-dark";
                    if (row.status == PENDING) {
                        color = "bg-warning font-weight-bold text-white";
                        status = "Pending";
                    } else if (row.status == APPROVED) {
                        color = "bg-success font-weight-bold text-white";
                        status = "Approved";
                    } else if (row.status == REJECTED) {
                        color = "bg-danger font-weight-bold text-white";
                        status = "Rejected";
                    }

                    return (
                        <div className="d-flex flex-row align-items-center justify-content-center m-1">
                            {row.just_added ? (
                                <span className={`badge ${color}`} style={{fontSize: '0.8rem'}}>
                                    {status}
                                </span>
                            ): null}
                        </div>
                    )
                }}
                >
                Status
            </TableHeaderColumn>
            <TableHeaderColumn width="150px" dataField="added_by"
                dataFormat={(cell, row) => {
                    const BY_R_AGENT = 1000;
                    const BY_D_AGENT = 2000;
                    
                    if (row.just_added) {
                        return (
                            <span className="">
                                {cell == BY_R_AGENT ? "Hiring Agent" : "Showing Agent" }
                            </span>
                        )
                    }
                    return null;
                }}
                >
                Added By
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="confirm_arrival"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (row.confirm_arrival && row.confirm_arrival.photo) {
                        _listImages.push({
                            name: "Confirm arrival",
                            url: row.confirm_arrival.photo,
                        });
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {row.confirm_arrival ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : row.was_not_shown ? null : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {row.confirm_arrival &&
                                row.confirm_arrival.created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_arrival.created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                                {row.was_not_shown && (
                                    <span className="text-dark text-small-chart p-1 font-weight-bold rounded bg-warning">
                                        Was Not Shown
                                    </span>
                                )}
                                {/* IT IS VALIDATED IF IT IS NO SHOW */}
                                {noShowData && noShowData.no_show && (
                                    <span className="mx-1 text-dark text-small-chart p-1 font-weight-bold rounded bg-warning">
                                        No Show {`${noShowData.date ? moment(noShowData.date).format("lll") : ''}`}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Arrival
            </TableHeaderColumn>

            <TableHeaderColumn
                width="250px"
                dataField="confirm_departure"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (row.confirm_departure && row.confirm_departure.photo) {
                        _listImages.push({
                            name: "Confirm departure",
                            url: row.confirm_departure.photo,
                        });
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {row.confirm_departure ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {row.confirm_departure &&
                                row.confirm_departure.created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_departure.created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Departure
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="feedback"
                dataFormat={(cell, row) => {
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {row.feedback_answers &&
                                row.feedback_answers.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() =>
                                            openJobFeedbackModal({
                                                answers: row.feedback_answers,
                                                special_answer:
                                                    row.feedback_special_answer
                                                        ? row.feedback_special_answer
                                                        : null,
                                                was_not_shown:
                                                    row.was_not_shown,
                                            })
                                        }
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : null}
                                {row.feedback ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {row.feedback && row.feedback.created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.feedback.created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            >
                Submit Feedback
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="confirm_installation"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (
                        row.confirm_installation &&
                        row.confirm_installation.length
                    ) {
                        row.confirm_installation.forEach((item) => {
                            _listImages.push({
                                name:
                                    item.name != ""
                                        ? item.name
                                        : "Confirm Install",
                                url: item.photo,
                            });
                        });
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {_listImages.length ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {_listImages.length &&
                                row.confirm_installation[0].created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_installation[0]
                                                    .created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Install
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="confirm_drop_off"
                dataFormat={(cell, row) => {
                    let _listImages = [];
                    if (row.confirm_drop_off && row.confirm_drop_off.photo) {
                        _listImages.push({
                            name: "Confirm Drop Off",
                            url: row.confirm_drop_off.photo,
                        });
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                {_listImages.length ? (
                                    <button
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => viewPhoto(_listImages)}
                                    >
                                        <i className="fa fa-image"></i>
                                    </button>
                                ) : null}
                                {row.confirm_drop_off ? (
                                    <b className="font-weight-bold">Yes</b>
                                ) : (
                                    <b className="font-weight-bold">No</b>
                                )}
                                {row.confirm_drop_off &&
                                row.confirm_drop_off.created ? (
                                    <div className="d-flex flex-row">
                                        <p className="mx-2 mb-0">-</p>
                                        <p className="mb-0">
                                            {moment(
                                                row.confirm_drop_off.created
                                            ).format("lll")}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            >
                Confirm Drop Off
            </TableHeaderColumn>
            <TableHeaderColumn
                width="450px"
                dataField="has_visitors_feedback"
                dataFormat={(cell, row) => {
                    if (hasVisitorReport) {
                        return (
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <button
                                    className="btn btn-primary btn-sm text-white"
                                    style={{minWidth: '190px'}}
                                    onClick={(e) => downloadVisitorReport(id)}
                                >
                                    <i className={`fa fa-download mr-2`} />
                                    Download Report
                                </button>
                                {isValueExperience && (
                                    <button
                                        className="ml-3 btn btn-primary btn-sm text-white"
                                        style={{minWidth: '190px'}}
                                        onClick={(e) => downloadVisitorReport(id, 'lite')}
                                    >
                                        <i className={`fa fa-download mr-2`} />
                                        Download Lite Report
                                    </button>
                                )}
                            </div>
                        );
                    }

                    return null;
                }}
            >
                Visitors Feedback
            </TableHeaderColumn>
            <TableHeaderColumn
                width="150px"
                dataField="links"
                dataFormat={(cell, row, formatExtraData, rowIndex) => {
                    let _listImages = [];
                    let links = photoVideoLinks.map((item) => {
                        let link = ''
                        link = item.photo ? item.photo : item.video_url

                        return link
                    })
                    // let links = ["https://nyc3.digitaloceanspaces.com/showit-develop/media/job/photo/image-0blxr9fdmq9yvbjf0x1n4q.jpg"]
                    // let files = [{media_type: 1, url: "https://nyc3.digitaloceanspaces.com/showit-develop/media/job/photo/image-0blxr9fdmq9yvbjf0x1n4q.jpg"}]
                    let files = photoVideoLinks.map((item) => {
                        let link = ''
                        link = item.photo ? item.photo : item.video_url
                        return {media_type: item.media_type, url: link}
                    })
                    if (rowIndex > 0) {
                        return null
                    }
                    return (
                        <div className="w-100 d-flex">
                            <div className="d-flex flex-row align-items-center py-1">
                                <button
                                    title="Copy links"
                                    className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                    style={{
                                        minWidth: "1.6rem",
                                        maxWidth: "1.6rem",
                                        minHeight: "1.6rem",
                                        maxHeight: "1.6rem",
                                    }}
                                    disabled={links.length <= 0}
                                    onClick={() => {
                                        if (links.length > 0) {
                                            copyLinks(links.join('\n'))
                                        }
                                    }}
                                >
                                    <i className="fa fa-clipboard"></i>
                                </button>
                            </div>
                            <div className="d-flex flex-row align-items-center py-1">
                                <button
                                    title="Download"
                                    className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                    style={{
                                        minWidth: "1.6rem",
                                        maxWidth: "1.6rem",
                                        minHeight: "1.6rem",
                                        maxHeight: "1.6rem",
                                    }}
                                    disabled={files.length <= 0}
                                    onClick={() => {
                                        if (files.length > 0) {
                                            donwloadFiles(files)
                                        }
                                    }}
                                >
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                        </div>
                    );
                }}
            >
                Photo/Video Links
            </TableHeaderColumn>
            <TableHeaderColumn
                width="250px"
                dataField="links"
                dataFormat={(cell, row, formatExtraData, rowIndex) => {
                    return (
                        <div className="w-100 d-flex">
                            {rowIndex == 0 ? (
                                <p>{hiredAgentNote}</p>
                            ): null}
                        </div>
                    );
                }}
            >
                Note to the hired agent
            </TableHeaderColumn>
            <TableHeaderColumn
                width="150px"
                dataField="links"
                dataFormat={(cell, row, formatExtraData, rowIndex) => {
                    return (
                        <div className="w-100 d-flex">
                            {rowIndex == 0 && trackingDistance > 0 ? (
                                <div className="d-flex flex-row align-items-center py-1">
                                    <button
                                        title="Copy links"
                                        className="btn btn-link d-flex align-items-center justify-content-center mr-2"
                                        style={{
                                            minWidth: "1.6rem",
                                            maxWidth: "1.6rem",
                                            minHeight: "1.6rem",
                                            maxHeight: "1.6rem",
                                        }}
                                        onClick={() => {
                                            openTrackingModal()
                                        }}
                                    >
                                        <i className="fa fa-map"></i>
                                    </button>
                                </div>
                            ): null}
                        </div>
                    );
                }}
            >
                Route
            </TableHeaderColumn>
            <TableHeaderColumn
                width="150px"
                dataField="payed"
                dataFormat={(cell, row, formatExtraData, rowIndex) => {
                    return (
                        <div className="w-100 d-flex">
                            {jobCompleted && (
                                cell ? (
                                    <span className="text-white text-small-chart p-1 font-weight-bold rounded bg-success">
                                        Payed
                                    </span>
                                ) : (
                                    <span className="text-white text-small-chart p-1 font-weight-bold rounded bg-danger">
                                        Not Payed
                                    </span>
                                )
                            )}
                        </div>
                    );
                }}
            >
                Payed
            </TableHeaderColumn>

            {/* <TableHeaderColumn width="150px" dataField="zip_code">Zip Code</TableHeaderColumn> */}
            <TableHeaderColumn dataField="zip_code2"></TableHeaderColumn>
        </BootstrapTable>
    );
};

const JobTrackingModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-6 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Job Tracking
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h6><b>Total Miles: </b>{data.distance ? parseFloat(data.distance).toFixed(2) : 0}</h6>
                    </div>
                    <div className="col-12" style={{ minHeight: '60vh' }}>
                        <TrackingMap 
                            route={data.route}
                            addreses={data.addreses}
                            iconData={data.mapIcon}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
const InfoModal = (props) => {
    //  State
    const { open, message, title=null } = props;
    //  Bind
    const { onClose } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-xl-4 col-lg-5 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-5">
                {title ? (
                    <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                        <div className="">
                            <div className="header-page ml-lg-3 ml-0" style={{width: "auto"}}>
                                {title}
                            </div>
                        </div>
                    </div>
                ): null}
                <span className="font-weight-bold mx-3" style={{whiteSpace: "pre-line"}}>
                    {message ? message : ""}
                </span>
            </div>
        </Modal>
    )
}


const JobReviewModal = (props) => {
    //  State
    const { open, data } = props;
    //  Bind
    const { onClose } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-4 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Review
                        </div>
                    </div>
                </div>
                <div className="row">
                    {data && data.review ? (
                        <React.Fragment>
                            <div className="form-group col-md-6 col-12">
                                <label htmlFor="">Professionalism</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.review.professionalism}
                                />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <label htmlFor="">Timeliness</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.review.timeliness}
                                />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <label htmlFor="">Communication</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.review.communication}
                                />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <label htmlFor="">Quality</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.review.quality}
                                />
                            </div>
                            <div className="form-group col-md-6 col-12">
                                <label htmlFor="">Feedback</label>
                                <ReactStars
                                    size={17}
                                    count={5}
                                    a11y={true}
                                    edit={false}
                                    isHalf={true}
                                    color="#474747"
                                    activeColor="#FFD700"
                                    emptyIcon={<i className="material-icons mr-2">star_border</i>}
                                    halfIcon={<i className="material-icons mr-2">star_half</i>}
                                    filledIcon={<i className="material-icons mr-2">star</i>}
                                    onChange={() => console.log('')}
                                    value={data.review.feedback}
                                />
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="">Notes</label>
                                <textarea
                                    style={{ resize: "none" }}
                                    className="form-control text-area-h-auto"
                                    disabled="true"
                                    value={data.review.notes}
                                    rows={6}
                                />
                            </div>
                        </React.Fragment>
                    ): null}
                </div>
            </div>
        </Modal>
    )
}

export const JobDetailRawDataGrid = (props) => {
    const [photoModal, setPhotoModal] = useState(false);
    const [listImages, setListImages] = useState([]);
    //  Applicants
    const [applicantsModal, setApplicantsModal] = useState(false);
    const [applicantsData, setApplicantsData] = useState(null);
    //  Cancellation Policies
    const [cancellationPoliciesModal, setCancellationPoliciesModal] =
        useState(false);
    //  Cancellation Policies
    const [cancellationReasonsModal, setCancellationReasonsModal] =
        useState(false);
    //  Others
    const [agentsNewJobNotificationModal, setAgentsNewJobNotificationModal] =
        useState(false);
    const [agentsNewJobNotification, setAgentsNewJobNotification] = useState(
        []
    );
    //  Client review
    const [showClientReviewModal, setShowClientReviewModal] = useState(false);
    const [clientReviewData, setClientReviewData] = useState(null);

    //  Job Feedback
    const [showJobFeedbackModal, setShowJobFeedbackModal] = useState(false);
    const [jobFeedbackData, setJobFeedbackData] = useState(null);

    //  Edit Job
    const [showEditJobModal, setShowEditJobModal] = useState(false);
    const [jobId, setJobId] = useState(null);

    //  Tracking Modal
    const [showTrackingModal, setShowTrackingModal] = useState(false);
    const [trackingData, setTrackingData] = useState({});

    //  Ifo Modal
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [infoTitle, setInfoTitle] = useState("");
    //  Job review
    const [showJobReviewModal, setShowJobReviewModal] = useState(false);
    const [jobReviewData, setJobReviewData] = useState(null);

    const {
        loader = false,
        data,
        nextPage,
        page,
        downloadVisitorReport,
        changeJobTransferType,
    } = props;

    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("STATS-JOB-DETAILS-RAW-DATA");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    const tableOptions = {
        sizePerPage: SIZE_PER_PAGE,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: nextPage,
    };

    const viewPhoto = (list) => {
        setListImages(list);
        setTimeout(() => {
            setPhotoModal(true);
        }, 300);
    };

    const closeViewPhoto = () => {
        setListImages([]);
        setPhotoModal(false);
    };

    const openApplicantsModal = (data, job_id) => {
        setApplicantsData({ ...data, job_id });
        setTimeout(() => {
            setApplicantsModal(true);
        }, 300);
    };

    const closeApplicantsModal = (data) => {
        setApplicantsData(null);
        setTimeout(() => {
            setApplicantsModal(false);
        }, 300);
    };

    const isExpandableRow = (row) => {
        if (row.job_description && row.job_description.length > 0) return true;
        else return false;
    };

    const expandComponent = (row, index) => {
        // console.log("ROWS EXPAND: ", row);
        const COMPLETED = 45
        const REVIEWED = 50
        const isValueExperience = () => {
            let result = false;
            if (row && row.job_sub_type && row.job_sub_type.toUpperCase().includes("VALUE EXPERIENCE")) {
                result = true
            }

            return result
        }

        return (
            <RowDetail
                id={row.id}
                isValueExperience={isValueExperience()}
                data={row.job_description}
                jobCompleted={row.state_job == COMPLETED || row.state_job == REVIEWED}
                hasVisitorReport={
                    row.has_visitors_feedback ? row.has_visitors_feedback : null
                }
                downloadVisitorReport={downloadVisitorReport}
                viewPhoto={viewPhoto}
                openJobFeedbackModal={openJobFeedbackModal}
                // NO SHOW
                noShowData={{no_show: row.no_show, date: row.date_no_show}}
                // PHOTO/VIDEO
                photoVideoLinks={row && row.photo_video_urls? row.photo_video_urls : []}
                hiredAgentNote={row && row.notes ? row.notes : ''}
                trackingDistance={row && row.tracking_distance ? row.tracking_distance : ''}
                // trackingRoute={row && row.tracking_route ? row.tracking_route : ''}
                openTrackingModal={() => {
                    setTrackingData({
                        distance: row && row.tracking_distance ? row.tracking_distance : '',
                        route: row && row.tracking_route ? row.tracking_route : [],
                        addreses: row && row.job_description ? row.job_description : [],
                        mapIcon: row && row.job_map_icon ? row.job_map_icon : null,
                    })
                    setTimeout(() => {
                        setShowTrackingModal(true)
                    }, 300);
                }}
            />
        );
    };

    const openAgentsModal = (data) => {
        setAgentsNewJobNotification(data);
        setTimeout(() => {
            setAgentsNewJobNotificationModal(true);
        }, 300);
    };

    const closeAgentsModal = (data) => {
        setAgentsNewJobNotification([]);
        setTimeout(() => {
            setAgentsNewJobNotificationModal(false);
        }, 300);
    };

    const openClientReviewModal = (data) => {
        setClientReviewData(data);
        setTimeout(() => {
            setShowClientReviewModal(true);
        }, 300);
    };

    const closeClientReviewModal = () => {
        setShowClientReviewModal(false);
        setClientReviewData(null);
    };

    const openJobFeedbackModal = (data) => {
        setJobFeedbackData(data);
        setTimeout(() => {
            setShowJobFeedbackModal(true);
        }, 300);
    };

    const closeJobFeedbackModal = () => {
        setShowJobFeedbackModal(false);
        setJobFeedbackData(null);
    };

    const openJobReviewModal = (data) => {
        setJobReviewData(data);
        setTimeout(() => {
            setShowJobReviewModal(true);
        }, 300);
    };

    const closeJobReviewModal = () => {
        setShowJobReviewModal(false);
        setJobReviewData(null);
    };

    return (
        <div id="STATS-JOB-DETAILS-RAW-DATA" className="container-list card border-dark border-card-page px-4 py-0">
            <div className="card-body px-0 py-2 d-flex flex-column overflow-hidden">
                <LoadMask loading={loader} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={gridHeight ? {maxHeight: `${gridHeight}px`}: {}}>
                        <BootstrapTable
                            data={loader ? [] : data.results ? data.results : []}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            expandableRow={isExpandableRow}
                            expandComponent={expandComponent}
                            expandColumnOptions={{ expandColumnVisible: true }}
                            containerClass="flex-1"
                            tableContainerClass="table-scroll-auto"
                            tableStyle={gridHeight ? {maxHeight: `${gridHeight - 60}px`, minHeight: `${gridHeight - 60}px`} : {}}
                        >
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="id"
                                width="80px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <img
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setJobId(cell)
                                                setTimeout(() => {
                                                    setShowEditJobModal(true)
                                                }, 400);
                                            }}
                                            title="Edit"
                                            className="custom-container-icon"
                                            src={require('../../../../../../assets/img/edit.svg')}
                                            alt="editSVG"/>
                                    )
                                }}
                                dataAlign="center"
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="job_no"
                                width="80px"
                                dataAlign="right"
                            >
                                Work number
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="id"
                                isKey
                                width="80px"
                                dataAlign="right"
                            >
                                Job #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="status"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    let job_status = cell
                                    if (row.action_by_r_agent) {
                                        job_status += " by R-Agent"
                                    }

                                    return (
                                        <div className="d-flex flex-row">
                                            <button
                                                title="Agents"
                                                className="btn btn-light d-flex align-items-center justify-content-center mr-2"
                                                style={{
                                                    minWidth: "1.6rem",
                                                    maxWidth: "1.6rem",
                                                    minHeight: "1.6rem",
                                                    maxHeight: "1.6rem",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openApplicantsModal(
                                                        {
                                                            state_job: row.state_job,
                                                            cancellation_date:
                                                                row.cancellation_date,
                                                            // NO SHOW
                                                            no_show: row.no_show,
                                                            date_no_show: row.date_no_show
                                                        },
                                                        row.id,
                                                    );
                                                }}
                                            >
                                                <i className="fa fa-users text-success"></i>
                                            </button>
                                            {/* IT IS VALIDATED IF IT IS NO SHOW */}
                                            <span>{row.no_show ? `${job_status} (No Show)` : job_status}</span>
                                        </div>
                                    );
                                }}
                            >
                                Job Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="job_type__name"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className="w-100 d-flex">
                                            {row.job_type ? row.job_type.name : null}
                                        </div>
                                    );
                                }}
                            >
                                Job Type
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="job_sub_type"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    let isSignOrLockbox = false;
                                    let isOpenHouseOrVendor = false;
                                    let duration = 0

                                    if (cell) {
                                        if (cell.toLowerCase().includes('lockbox')) {
                                            isSignOrLockbox = true
                                        }
                                        if (cell.toLowerCase().includes('sign', 'directional')) {
                                            isSignOrLockbox = true
                                            const descriptions = row.job_description ? row.job_description : []

                                            if (descriptions.length > 0) {
                                                duration = descriptions[0].duration ? parseFloat((descriptions[0].duration / 60).toFixed(1)) : 0
                                            }
                                        }

                                        if (row.job_type && row.job_type.name.toLowerCase().includes('open house')) {
                                            isOpenHouseOrVendor = true
                                            const descriptions = row.job_description ? row.job_description : []

                                            if (descriptions.length > 0) {
                                                duration = descriptions[0].duration ? parseFloat((descriptions[0].duration / 60).toFixed(1)) : 0
                                            }
                                        }

                                        if (row.job_type && row.job_type.name.toLowerCase().includes('vendor')) {
                                            isOpenHouseOrVendor = true
                                        }

                                        return <span>
                                            {cell} <span className="font-weight-bold">{isSignOrLockbox ? (row.install ? "(Install)" : row.remove && "(Remove)") : ""}</span>
                                            {isOpenHouseOrVendor && duration > 0 && (
                                                <span className="font-weight-bold">
                                                    ({duration}{duration <= 1 ? "h" : "hrs"})
                                                </span>
                                            )}
                                        </span>
                                    }

                                    return null
                                }}
                            >
                                Job Sub Type
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="created"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{cell && moment(cell).format("lll")}</div>
                                    );
                                }}
                            >
                                Job Posted Date & Time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="first_applied__created"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.first_applied &&
                                                moment(
                                                    row.first_applied.created
                                                ).format("lll")}
                                        </div>
                                    );
                                }}
                            >
                                First Applied Date & Time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="claimed_hired__created"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.claimed_hired &&
                                                moment(
                                                    row.claimed_hired.created
                                                ).format("lll")}
                                        </div>
                                    );
                                }}
                            >
                                Job Claimed/Hired Date & Time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="0"
                                rowSpan="2"
                                dataField="start_date"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{cell && moment(cell).format("lll")}</div>
                                    );
                                }}
                            >
                                Job Start Date & Time
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="0"
                                rowSpan="2"
                                dataField="date_completed"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{cell && moment(cell).format("lll")}</div>
                                    );
                                }}
                            >
                                Job Completed Date & Time
                            </TableHeaderColumn>

                            {/* HIRING AGENT INFO */}
                            <TableHeaderColumn row="0" colSpan="4" headerAlign="center">
                                Hiring Agent Info
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__first_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.first_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__last_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.last_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__franchise"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.franchise
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="190px"
                            >
                                Fanchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="hiring_agent_info__office_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.hiring_agent_info
                                                ? row.hiring_agent_info.office_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="190px"
                            >
                                Office Name
                            </TableHeaderColumn>

                            {/* SHOWING AGENT INFO */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="4"
                                headerAlign="center"
                                width="150px"
                            >
                                Showing Agent info
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="showing_agent_info__first_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.showing_agent_info
                                                ? row.showing_agent_info.first_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="showing_agent_info__last_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.showing_agent_info
                                                ? row.showing_agent_info.last_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Last Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="showing_agent_info__franchise"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.showing_agent_info
                                                ? row.showing_agent_info.franchise
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="190px"
                            >
                                Fanchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="showing_agent_info__office_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.showing_agent_info
                                                ? row.showing_agent_info.office_name
                                                : ""}
                                        </div>
                                    );
                                }}
                                width="190px"
                            >
                                Office Name
                            </TableHeaderColumn>

                            {/* JOB FEES */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="10"
                                headerAlign="center"
                                width="150px"
                            >
                                Job Fees
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="job_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Job Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="increase_service_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Increase Service Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="add_property_additional_fee"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            {row.possible_additional_fee ? (
                                                <span>${parseFloat(row.possible_additional_fee).toFixed(2)}</span>
                                            ) : null}
                                            <span className="ml-2 text-dark">($ {parseFloat(cell ? cell : 0).toFixed(2)})</span>
                                        </div>
                                    )
                                }}
                            >
                                <div
                                    style={{ cursor: "pointer" }}
                                    className="d-flex flex-row align-items-center"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setInfoTitle("Add property, Additonal Service Fee")
                                        setInfoMessage("1. Amount without Parenthesis: This is the extra service fee for additional showings added by the hiring agent. It reflects the cost for the hiring agent's efforts in arranging and managing more showings.\n\n2. Amount in Parenthesis: The figure inside the parenthesis represents the extra service fee for additional showings received by the showing agent.")
                                        setShowInfoModal(true);
                                    }}
                                >
                                    <i
                                        className="fa fa-info-circle text-info mr-2"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                    Add property, Additonal Service Fee
                                </div>
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="processing_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Processing Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="stripe_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Stripe Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="total_charge"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Total Charge
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="tip"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Tip
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="tip_stripe_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Tip Stripe Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="monthly_payment_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Stripe Montly Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="agent_receiving_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Agent Receiving Fee
                            </TableHeaderColumn>

                            {/* CANCELLATION FEE */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="11"
                                headerAlign="center"
                                width="150px"
                            >
                                Cancellation Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="cancellation_by"
                                width="150px"
                            >
                                Job Cancellation by
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="canceled_at"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{cell && moment(cell).format("lll")}</div>
                                    );
                                }}
                            >
                                Job Canceled at
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="cancellation_fee_recipient"
                                width="150px"
                            >
                                Cancellation fee recipient
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="cancellation_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Cancellation Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="cancellation_recipient_compensation_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Recipient compensation fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="cancellation_received_recipient"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Received by Recipient
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="cancellation_stripe_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Cancellation Stripe Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="cancellation_fee_profit"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                ShowIT Cancellation Fee profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="cancellation_reason"
                                width="200px"
                                dataFormat={cancellationReasonFormatted}
                            >
                                Reason of Cancellation
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="cancellation_type"
                                width="150px"
                                header
                            >
                                <div
                                    style={{ cursor: "pointer" }}
                                    className="d-flex flex-row align-items-center"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCancellationPoliciesModal(true);
                                    }}
                                >
                                    <i
                                        className="fa fa-info-circle text-info mr-2"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                    Cancellation Type (1,2,3)
                                </div>
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="cancellation_reason_type"
                                width="150px"
                                header
                                dataFormat={(cell, row) => {
                                    if (row.state_job == 35) {
                                        return <span>{cell ? cell : ''}</span>
                                    }
                                    return null
                                }}
                            >
                                <div
                                    style={{ cursor: "pointer" }}
                                    className="d-flex flex-row align-items-center"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCancellationReasonsModal(true);
                                    }}
                                >
                                    <i
                                        className="fa fa-info-circle text-info mr-2"
                                        style={{ fontSize: "20px" }}
                                    ></i>
                                    Cancellation Reason Type
                                </div>
                            </TableHeaderColumn>

                            {/* NO SHOW FEE */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="7"
                                headerAlign="center"
                                width="150px"
                            >
                                No Show Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="no_show_by"
                                width="150px"
                            >
                                No Show By
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="no_show_penalty_recipient"
                                width="150px"
                            >
                                No Show Penalty Recipient
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="no_show_penalty"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                No Show Penalty
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="no_show_recipient_compensation"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                No Show Recipient Compensation
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="no_show_received_by_recipient"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Received by Recipient
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="no_show_stripe_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                No Show Stripe Fee
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="no_show_show_it_profit"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                No Show ShowIT profit
                            </TableHeaderColumn>

                            {/* BROKERS/INFLUENCERS/SHOWIT PROFIT */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="11"
                                headerAlign="center"
                                width="150px"
                            >
                                Brokers/Influencers/ShowIT Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="show_it_gross_profit"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                ShowIT Gross Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="broker_hiring_agent"
                                width="150px"
                                dataFormat={currencyAndYesNoFormatted}
                            >
                                Broker Profit Hiring Agent
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="r_agent_broker_paid"
                                width="150px"
                                dataFormat={yesNoFormatted}
                            >
                                ShowIT Share Delivery to the Hiring Agent Broker?
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="broker_showing_agent"
                                width="150px"
                                dataFormat={currencyAndYesNoFormatted}
                            >
                                Broker Profit Showing Agent
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="d_agent_broker_paid"
                                width="150px"
                                dataFormat={yesNoFormatted}
                            >
                                ShowIT Share Delivery to the Showing Agent Broker?
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="influencer_hiring_agent"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    if (row.influencer_hiring_agent) {
                                        return (
                                            <p className="my-0 mx-0 px-0">
                                                <span className="mr-2 font-weight-bold text-dark">{row.influencer_hiring_agent.influencer ? row.influencer_hiring_agent.influencer : ''}</span>
                                                <span>$ {parseFloat(row.influencer_hiring_agent.total ? row.influencer_hiring_agent.total : 0).toFixed(2)}</span>
                                            </p>
                                        )
                                    }
                                    return null
                                }}
                            >
                                Hiring Agent Influencer Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="other_influencer_hiring_agent"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    if (row.other_influencer_hiring_agent) {
                                        return (
                                            <p className="my-0 mx-0 px-0">
                                                <span className="mr-2 font-weight-bold text-dark">{row.other_influencer_hiring_agent.influencer ? row.other_influencer_hiring_agent.influencer : ''}</span>
                                                <span>$ {parseFloat(row.other_influencer_hiring_agent.total ? row.other_influencer_hiring_agent.total : 0).toFixed(2)}</span>
                                            </p>
                                        )
                                    }
                                    return null
                                }}
                            >
                                Hiring Agent Other Influencer Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="influencer_showing_agent"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    if (row.influencer_showing_agent) {
                                        return (
                                            <p className="my-0 mx-0 px-0">
                                                <span className="mr-2 font-weight-bold text-dark">{row.influencer_showing_agent.influencer ? row.influencer_showing_agent.influencer : ''}</span>
                                                <span>$ {parseFloat(row.influencer_showing_agent.total ? row.influencer_showing_agent.total : 0).toFixed(2)}</span>
                                            </p>
                                        )
                                    }
                                    return null
                                }}
                            >
                                Showing Agent Influencer Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="other_influencer_showing_agent"
                                width="190px"
                                dataFormat={(cell, row) => {
                                    if (row.other_influencer_showing_agent) {
                                        return (
                                            <p className="my-0 mx-0 px-0">
                                                <span className="mr-2 font-weight-bold text-dark">{row.other_influencer_showing_agent.influencer ? row.other_influencer_showing_agent.influencer : ''}</span>
                                                <span>$ {parseFloat(row.other_influencer_showing_agent.total ? row.other_influencer_showing_agent.total : 0).toFixed(2)}</span>
                                            </p>
                                        )
                                    }
                                    return null
                                }}
                            >
                                Showing Agent Other Influencer Profit
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="total_comission_paid"
                                width="190px"
                                dataFormat={currencyFormatted}
                            >
                                Total Comission Paid
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="show_it_net_profit"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                ShowIT Net Profit
                            </TableHeaderColumn>

                            {/* PAYMENTS & STRIPE FEES */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="7"
                                headerAlign="center"
                                width="150px"
                            >
                                Payments & Stripe Fees
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="payment_cc"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Payment With CC
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="payment_wallet"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Payment With Wallet
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="payment_gift_card"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Payment With Gift Card
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="payment_cc_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Stripe Fee, Payment With CC
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="payment_wallet_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Stripe Fee, Payment With Wallet
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="payment_gift_card_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Stripe Fee, Payment With Gift Card
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="show_it_remaining_fee"
                                width="150px"
                                dataFormat={currencyFormatted}
                            >
                                Stripe Fee ShowIT Remaining
                            </TableHeaderColumn>

                            {/* Feedback */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="2"
                                headerAlign="center"
                                width="150px"
                            >
                                Feedback
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="agent_review"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className="d-flex flex-row align-items-center justify-content-center py-1 w-100">
                                            <span>{cell ? "Yes" : "No"}</span>
                                            {cell ? (
                                                <button
                                                    className="btn btn-link d-flex align-items-center justify-content-center ml-3"
                                                    style={{
                                                        minWidth: "1.6rem",
                                                        maxWidth: "1.6rem",
                                                        minHeight: "1.6rem",
                                                        maxHeight: "1.6rem",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openJobReviewModal({
                                                            review:
                                                                row.review,
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                            ) : null}
                                        </div>
                                    )
                                }}
                            >
                                Agent review
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="client_review"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className="d-flex flex-row align-items-center justify-content-center py-1 w-100">
                                            <span>{cell ? "Yes" : "No"}</span>
                                            {cell ? (
                                                <button
                                                    className="btn btn-link d-flex align-items-center justify-content-center ml-3"
                                                    style={{
                                                        minWidth: "1.6rem",
                                                        maxWidth: "1.6rem",
                                                        minHeight: "1.6rem",
                                                        maxHeight: "1.6rem",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openClientReviewModal({
                                                            feedback:
                                                                row.customer_feedback,
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                            ) : null}
                                        </div>
                                    );
                                }}
                            >
                                Client Review
                            </TableHeaderColumn>

                            {/* CLIENT ARRIVAL */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="1"
                                headerAlign="center"
                                width="150px"
                            >
                                Client Arrival
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="center"
                                dataField="has_client_arrived"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    return <span>{cell ? "Yes" : "No"}</span>;
                                }}
                            >
                                Did the Client / Vendor arrived
                            </TableHeaderColumn>
                            {/* Total Agents with New Job Notification */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="2"
                                headerAlign="center"
                                width="150px"
                            >
                                Total Agents with New Job Notification
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="agents_new_job_notification_working_area"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    const _agents =
                                        row.agents_new_job_notification &&
                                        row.agents_new_job_notification.working_area
                                            ? row.agents_new_job_notification
                                                .working_area
                                            : [];
                                    return (
                                        <div className="d-flex flex-row">
                                            <button
                                                title="Agents"
                                                className="btn btn-light d-flex align-items-center justify-content-center mr-2"
                                                style={{
                                                    minWidth: "1.6rem",
                                                    maxWidth: "1.6rem",
                                                    minHeight: "1.6rem",
                                                    maxHeight: "1.6rem",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openAgentsModal(_agents);
                                                }}
                                            >
                                                <i className="fa fa-users text-info"></i>
                                            </button>

                                            <span>{_agents.length}</span>
                                        </div>
                                    );
                                }}
                            >
                                Working area
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataAlign="right"
                                dataField="agents_new_job_notification_follower"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    const _agents =
                                        row.agents_new_job_notification &&
                                        row.agents_new_job_notification.follower
                                            ? row.agents_new_job_notification.follower
                                            : [];
                                    return (
                                        <div className="d-flex flex-row">
                                            <button
                                                title="Agents"
                                                className="btn btn-light d-flex align-items-center justify-content-center mr-2"
                                                style={{
                                                    minWidth: "1.6rem",
                                                    maxWidth: "1.6rem",
                                                    minHeight: "1.6rem",
                                                    maxHeight: "1.6rem",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openAgentsModal(_agents);
                                                }}
                                            >
                                                <i className="fa fa-users text-info"></i>
                                            </button>

                                            <span>{_agents.length}</span>
                                        </div>
                                    );
                                }}
                            >
                                Followers
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataAlign="center"
                                dataField="transfer_type"
                                width="150px"
                                dataFormat={(cell, row) => {
                                    console.log("CELL HOLD: ", cell);
                                    let disabled = false
                                    const RELEASE = 100
                                    const HOLD = 200
                                    let transferType = HOLD
                                    if (cell == RELEASE) {
                                        transferType = HOLD
                                    } else {
                                        transferType = RELEASE
                                    }
                                    if (row.state_job == 35) {
                                        if (row.cancellation_transferred_from_broker) {
                                            disabled = true
                                        }
                                    } else {
                                        if (row.transferred_from_broker) {
                                            disabled = true
                                        }
                                    }
                                    
                                    if (row.payment_through_broker == false) {
                                        return null
                                    }

                                    return (
                                        <div className="d-flex flex-row">
                                            <button
                                                disabled={disabled}
                                                title="Hold / Release"
                                                className="btn btn-danger d-flex align-items-center justify-content-center mr-2"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    changeJobTransferType({id: row.id, transfer_type: transferType});
                                                }}
                                            >
                                                {cell == 100 ? "HOLD": "RELEASE"}
                                            </button>
                                        </div>
                                    );
                                }}
                            >
                                Hold / Release
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
            <PhotoModal
                open={photoModal && listImages}
                data={listImages}
                onClose={closeViewPhoto}
            />

            <ApplicantsModal
                open={applicantsModal && applicantsData}
                data={applicantsData}
                onClose={closeApplicantsModal}
            />

            <AgentsModal
                open={agentsNewJobNotificationModal}
                data={agentsNewJobNotification}
                onClose={closeAgentsModal}
            />

            <CancellationPoliciesModal
                open={cancellationPoliciesModal}
                onClose={() => setCancellationPoliciesModal(false)}
            />

            <ClientReviewModal
                open={showClientReviewModal && clientReviewData}
                data={clientReviewData}
                onClose={closeClientReviewModal}
            />

            <JobFeedbackModal
                open={showJobFeedbackModal && jobFeedbackData}
                data={jobFeedbackData}
                onClose={closeJobFeedbackModal}
            />
            <CancellationReasonsModal
                open={cancellationReasonsModal}
                onClose={() => setCancellationReasonsModal(false)}
            />

            <EditJobModal
                open={showEditJobModal}
                id={jobId}
                onClose={() => {
                    setShowEditJobModal(false);
                    setJobId(null);
                }}
            />
            <JobTrackingModal
                open={showTrackingModal}
                data={trackingData}
                onClose={() => {
                    setShowTrackingModal(false);
                    setTrackingData({});
                }}
            />
            <InfoModal
                open={showInfoModal}
                onClose={() => {
                    setShowInfoModal(false);
                    setInfoMessage("");
                    setInfoTitle("");
                }}
                message={infoMessage}
                title={infoTitle}
            />
            <JobReviewModal
                open={showJobReviewModal && jobReviewData}
                data={jobReviewData}
                onClose={closeJobReviewModal}
            />
        </div>
    );
};

const FilterContainer = (props) => {
    //  State
    const { downloadLoader, timeValue, start_dateValue, end_dateValue } = props;
    //  Bind
    const { setStatusFilter, downloadJobDetailsRawDataReport, setTimeFilter, searchAction } = props;

    // STATUS FILTER
    const JOB_STATUS = [
        // { label: "All", value: "" },
        { label: "Posted", value: 5 },
        { label: "Complete", value: "complete" },
        { label: "Expired", value: 55 },
        { label: "Cancelled", value: 35 },
        { label: "No Show", value: "no_show" },
        { label: "Hired/Claimed", value: 25 },
    ];

    return (
        <div className="row w-100 align-items-center">
            <div
                className="col-xl-3 col-lg-5 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end"
            >
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder="Search"
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>
            </div>
            <div className="col-xl-9 col-lg-7 col-sm-12">
                <div className="row justify-content-end align-items-center">
                    <div className="form-group has-feedback col-xl-3 col-lg-4 col-sm-12 col-md-6">
                        <label htmlFor="filter_category">Job Status</label>
                        <Field
                            name="jobStatus"
                            // isMulti={false}
                            options={JOB_STATUS}
                            set_value={setStatusFilter}
                            component={MultiSelectField}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-xl-3 col-lg-4 col-sm-12 col-md-6">
                        <label htmlFor="time">Time</label>
                        <Field
                            name="time"
                            isMulti={false}
                            options={JOB_DETAILS_RAW_DATA_TIME}
                            set_value={(option) => {
                                setTimeFilter(option ? option.value : "")
                            }}
                            component={SelectField}
                            className="form-control"
                            disabled={false}
                        />
                    </div>
                    {timeValue == 'custom' && (
                        <React.Fragment>
                            <div className="col-xl-3 col-lg-4 col-12">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="start_date">Start Date</label>
                                    <Field
                                        name="start_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        maxDate={end_dateValue ? moment(end_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            setTimeFilter("")
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-12">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="end_date">End Date</label>
                                    <Field
                                        name="end_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        minDate={start_dateValue ? moment(start_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            setTimeFilter("")
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <button
                        className="btn btn-primary text-white col-xl-3 col-lg-4 col-sm-10 h-75"
                        disabled={downloadLoader === true}
                        onClick={downloadJobDetailsRawDataReport}
                    >
                        <i
                            className={`fa ${
                                !downloadLoader
                                    ? "fa-download"
                                    : "fa-spinner fa-pulse fa-fw"
                            } mr-2`}
                        />
                        Download Report
                    </button>
                </div>
            </div>
        </div>
    );
};

const selector = formValueSelector('jobDetailsRawDataForm');

let JobDetailsRawDataForm = reduxForm({
    form: "jobDetailsRawDataForm", // a unique identifier for this form
    initialValues: {'jobStatus': SELECT_ALL_OPTION.value}
})(FilterContainer);

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    };
};

export default connect(mstp, null)(JobDetailsRawDataForm);
