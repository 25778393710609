import React, {useState, useEffect} from "react";
import moment from "moment"
import Grid from "../../Utils/GridTable";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {
    AGENT_REGISTERED_PAGE_SIZE,
    CATEGORY_REGISTERED_LIST,
    REGISTERED_PENDING, REGISTERED_ACTIVE, REGISTERED_LOCKED, REGISTERED_BLOCKED, REASON_LOCKED_BY
} from "../../../../utility/constants";


const DeletedAgentList = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        sendInvite,
        reDendInvite,
        remove,
        // tabContainerHeight,
    } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("INFLUENCER-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="INFLUENCER-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <Grid
                    // height={tabContainerHeight}
                    hover
                    height={gridHeight}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={data}
                    sizePerPage={AGENT_REGISTERED_PAGE_SIZE}
                    onPageChange={(value) => toList(value)}
                    onSortChange={(field, sortOrder, state) => {
                        onSortChange(field)
                    }}
                    columns={[
                        {
                            dataField: "id",
                            text: "",
                            formatter:
                                standardActions({
                                    // remove: remove,
                                    customRemove: remove,
                                    view: "influencers",
                                    edit: 'influencers',
                                }),
                        },
                        {
                            dataField: "logo",
                            formatter: avatar(),
                            text: " ",
                        },
                        {
                            dataField: "first_name",
                            sort: true,
                            text: "First Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "last_name",
                            sort: true,
                            text: "Last Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "email",
                            text: "Email",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "address",
                            text: "Address",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "250px",
                                };
                            },
                        },
                        {
                            dataField: "office_phone_number",
                            formatter: formatPhoneNumber(),
                            text: "Office Phone",
                        },
                        {
                            dataField: "phone_number",
                            formatter: formatPhoneNumber(),
                            text: "Mobile Phone",
                        },
                        {
                            dataField: "re_license",
                            text: "RE License",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "franchise_name",
                            text: "Franchise",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "broker_office_name",
                            text: "Broker Office Name",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "180px",
                                };
                            },
                        },
                        {
                            dataField: "broker_office_license",
                            text: "Office License #",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "sponsored_by",
                            text: "Sponcered by",
                            formatter: formatSponsored(),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                        },
                        {
                            dataField: "signed_agreement",
                            text: "Signed Agreement",
                            sort: true,
                            formatter: signedDateFormatt(),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "210px",
                                };
                            },
                        },
                        {
                            dataField: "total_showit_share",
                            text: "Total Profit Share",
                            align: "center",
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "130px",
                                };
                            },
                            formatter: (cell, row) => {
                                if (cell) {
                                    return (
                                        <span>$ {cell.toFixed(2)}</span>
                                    );
                                }
                                return <span>$ 0.00</span>;
                            },
                        },
                        {
                            dataField: "id",
                            text: "",
                            sort: false,
                            formatter: formatInvite(sendInvite, reDendInvite),
                            headerStyle: (colum, colIndex) => {
                                return {
                                    minWidth: "210px",
                                };
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );

};


export function signedDateFormatt() {
    return (cell, row) => {
        if (row.agreement) {
            return (
                <p className="my-0 pointer text-primary" onClick={() => {
                    window.open(row.agreement, '_blank');
                }}>
                    {cell ? moment(cell).format('LL') : ""}
                </p>
            )
        }
        return (
            <p className="my-0">
                {cell ? moment(cell).format('LL') : ""}
            </p>
        )
    };
}

export function avatar() {
    return (cell, row) => {
        return (
            <div style={{width: '20px'}} className="mx-auto">
                <Avatar
                    size="23"
                    src={cell}
                    round={true}
                    name={row.first_name}
                />
            </div>
        );
    };
}

function formatSponsored() {
    return (cell, row) => {
        let result = ''
        if(row.sponsored_by && row.sponsored_by_data) {
            result = `${row.sponsored_by_data.first_name} ${row.sponsored_by_data.last_name}`
        }

        return (
            <div>
                {result}
            </div>
        );
    };
}

export function formatPhoneNumber() {
    return (cell, row) => {
        let newCell = cell
        if (newCell && newCell.includes('+')) {
            newCell = newCell.replace('+', '')
        }
        if (newCell && newCell.includes('-')) {
            newCell = newCell.replace('-', '')
        }
        if (newCell && newCell.length > 10) {
            newCell = newCell.substr(newCell.length - 10, 12)
        }
        if (newCell) {
            newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
        }
        return (
            <p className={'my-0 mx-0 px-0'} style={{minWidth: '100px'}}>
                {newCell}
            </p>
        )
    };
}
function formatPreviousBroker() {
    return (cell, row) => {
        let result = "";
        return (
            <div>
                {row.previous_offices && row.previous_offices.map((item, index) => {
                    if (index == 0) {
                        return (
                            <React.Fragment>
                                {item.broker_is_active ? (
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`brokers/broker/${item.broker_id}/edit`}
                                        className="w-100 text-primary"
                                    >
                                        {item.broker}
                                    </Link>
                                ): (
                                    <span>
                                        {item.broker}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment>
                            <span>, </span>
                            {item.broker_is_active ? (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`brokers/broker/${item.broker_id}/edit`}
                                    className="w-100 text-primary"
                                >
                                    {item.broker}
                                </Link>
                            ) : (
                                <span>{item.broker}</span>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        )
    };
}

function formatPreviousOffice() {
    return (cell, row) => {
        let result = "";

        // if (row.previous_offices && row.previous_offices.length) {
        //     row.previous_offices.forEach((item, index) => {
        //         if (index == 0) {
        //             result += `${item.office}`
        //         }else {
        //             result += `, ${item.office}`
        //         }
        //     })
        // }

        return (
            <div>
                {row.previous_offices && row.previous_offices.map((item, index) => {
                    if (index == 0) {
                        return (
                            <React.Fragment>
                                {item.is_active ? (
                                    <Link
                                        onClick={(e) => e.stopPropagation()}
                                        to={`offices/${item.id}/edit`}
                                        className="w-100 text-primary"
                                    >
                                        {item.office}
                                    </Link>
                                ): (
                                    <span>
                                        {item.office}
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    }
                    return (
                        <React.Fragment>
                            <span>, </span>
                            {item.is_active ? (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`offices/${item.id}/edit`}
                                    className="w-100 text-primary"
                                >
                                    {item.office}
                                </Link>
                            ) : (
                                <span>{item.office}</span>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        )
    };
}

function formatPreviousFranchise() {
    return (cell, row) => {
        let result = "";

        if (row.previous_offices && row.previous_offices.length) {
            row.previous_offices.forEach((item, index) => {
                if (index == 0) {
                    result += `${item.franchise}`
                }else {
                    result += `, ${item.franchise}`
                }
            })
        }

        return (
            <span>{result}</span>
        )
    };
}

function formatDateOfDeletion() {
    return (cell, row) => {

        return (
            <span>{moment(cell).format('ll')}</span>
        )
    };
}

function formatInvite(action, actionReSend) {
    return (cell, row) => {
        return (
            <div className="w-100">
                {row.user ? (
                    <button
                        onClick={(e) => actionReSend(row.id)}
                        className="w-100 btn btn-success text-white"
                    >
                        Resend Invite
                    </button>
                ): (
                    <button
                        onClick={(e) => action(row.id)}
                        className="w-100 btn btn-info text-white"
                    >
                        Send Invite
                    </button>
                )}
            </div>
        );
    }
}

export default DeletedAgentList;
