import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/sendgrid/campaign";
import CreateUpdateCampaign from './CreateUpdateCampaign';


const ms2p = (state) => {
    return {
        ...state.sendgridCustomCampaigns,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateUpdateCampaign);