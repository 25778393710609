import React, { Component } from 'react'
import SettingForm from './SettingForm'

class Setting extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount() {

    }

    componentDidMount() {
        this.props.getSettings()
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {loader, onSubmit} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Settings</div>
                    </div>
                </div>

                <SettingForm
                    onSubmit={(data) => {
                        onSubmit(data)
                    }}
                    goBack={this.goBack}
                    loader={loader}
                />
            </div>
        )
    }
}

export default Setting