import Swal from 'sweetalert2';
import UpdateForm from "./UpdateForm";
import React, {Component} from 'react';
import LoadMask from "../../Utils/LoadMask/LoadMask";

class Brokerage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_view: false,
            is_update: false,
            photo: null,
        };
    }

    componentDidMount() {
        const {match, read, initBrokerBrokerageFormOptions, getItemFranchises, getItemTeams} = this.props;
        if (match.params.id) {
            
            if (match.path === '/agents/agent/:id/edit/') {
                initBrokerBrokerageFormOptions(match.params.id);
                this.setState({is_update: true, is_view: false});
            }
            if (match.path === '/agents/agent-details/:id/') {
                read(match.params.id);
                this.setState({is_update: false, is_view: true});
            }

            getItemFranchises()
            getItemTeams()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.item.working_area !== prevProps.item.working_area) {
            this.props.setWorkingArea({
                latitude_center: this.props.item.working_area ? this.props.item.working_area.latitude_center : prevProps.item.working_area.latitude_center,
                longitude_center: this.props.item.working_area ? this.props.item.working_area.longitude_center : prevProps.item.working_area.longitude_center,
            })
        }
    }

    updateForm = (data) => {
        const {editAgent} = this.props;
        if (data.signed) {
            if (this.state.photo) {
                const {update} = this.props;
                update({...data, photo: null}, [{"name": "photo", "file": this.state.photo}]);
            } else {
                editAgent(data.id, data);
            }
        }
        if (!data.signed) {
            editAgent(data.id, data);
        }
    };

    activateUser = (data) => {
        const {activateAgent} = this.props;
        activateAgent(data.id, data);
    }

    setAvatar = (photo) => {
        this.setState({photo});
    };

    setWorkingArea = (data) => {
        if (data) {
            const {latitude, longitude} = data;
            if (latitude && longitude) {
                this.props.setWorkingArea({
                    latitude_center: latitude,
                    longitude_center: longitude,
                })
            }
        }else {
            this.props.setWorkingArea({
                latitude_center: 0,
                longitude_center: 0,
            })
        }
    }

    remove = (id) => {
        Swal.fire({
            title: 'Are you sure to delete the agent?',
            text: "This action can't be reverted!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7E8A97',
            cancelButtonColor: '#B9BDC1',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.removeAgent(id);
            }
        });
    };

    render() {
        const {loader, item, workingArea, brokerages, brokers, getBrokerageFormOption, goPage, moveToOpenMarketOffice, franchises, teams} = this.props;
        // console.log("PROPS: ", this.props);
        const pendingAgent = item && item.agent_account_status == 10 ? true : false;
        const submit = pendingAgent ? this.activateUser : this.updateForm;
        return (
            <div className="d-flex flex-column">
                <div className="page-header pl-1 pt-3 no-gutters row pt-0">
                    <div className="">
                        <div className="header-page">
                            <button className='btn back-btn mr-3 my-2' onClick={() => this.props.history.goBack()}>
                                <i className="material-icons pointer">arrow_back</i> 
                            </button>
                            {pendingAgent && !this.state.is_view && "Pending Agent"}
                            {(this.state.is_view && "Agent details") || (this.state.is_update && !pendingAgent && "Agent Profile")}
                        </div>
                    </div>
                </div>
                <div
                    className="card border-card-page px-lg-4 py-2"
                    style={{
                        minWidth: '99%',
                        minHeight: '15%',
                    }}
                >
                    <LoadMask loading={loader} light>
                        <UpdateForm
                            setWorkingArea={this.setWorkingArea}
                            loader={loader}
                            onSubmit={submit}
                            is_view={this.state.is_view}
                            is_update={this.state.is_update}
                            item={item}
                            setAvatar={this.setAvatar}
                            center={{
                                lat: workingArea.latitude_center,
                                lng: workingArea.longitude_center
                            }}
                            pendingAgent={pendingAgent}
                            remove={this.remove}
                            optionBrokers={brokers}
                            optionBrokerages={brokerages}
                            franchises={franchises}
                            teams={teams}
                            getBrokerageFormOption={getBrokerageFormOption}
                            goBack={()=> this.props.history.goBack()}
                            goPage={goPage}
                            moveToOpenMarketOffice={moveToOpenMarketOffice}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Brokerage;
