export const validators = (values, props) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'This field is required';
    }
    if (values.feedback_question && values.feedback_question.length) {
        const feedbackQuestion = [];
        values.feedback_question.forEach((question, indexB) => {
            feedbackQuestion[indexB] = {};
            if (!question.question) {
                feedbackQuestion[indexB] = { question: 'This field is required' };
            }
            if (!question.question_type) {
                feedbackQuestion[indexB] = { ...feedbackQuestion[indexB], question_type: 'This field is required' };
            }
            if (!feedbackQuestion[indexB]) {
                feedbackQuestion[indexB] = undefined;
            }
        });
        errors.feedback_question = feedbackQuestion;
    } else {
        errors.feedback_question = "At least one question is required";
    }
    return errors;
};
