import React from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { SELECT_ALL_OPTION } from '../../../../utility/constants'
import { renderSearchField, MultiSelectField } from "../../Utils/renderField";

let TrackerFilterForm = (props) => {
    const {
        owners=[],
        severities=[],
        types=[],
        statuses=[],
        agreements=[],
    } = props
    //  Bind
    const {searchAction} = props;

    return (
        <div className="row mt-2 w-100 mb-3 align-items-center">
            <div
                className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}
            >
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search'}
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>

            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="owner">Owner</label>
                    <Field
                        name="owner"
                        options={owners}
                        set_value={(value) => {
                            searchAction(
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="severity">Severity</label>
                    <Field
                        name="severity"
                        options={severities}
                        set_value={(value) => {
                            searchAction(
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="type">Type</label>
                    <Field
                        name="type"
                        options={types}
                        set_value={(value) => {
                            searchAction(
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="status">Status</label>
                    <Field
                        name="status"
                        options={statuses}
                        set_value={(value) => {
                            searchAction(
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="agreement">Agreement Signed</label>
                    <Field
                        name="agreement"
                        options={agreements}
                        set_value={(value) => {
                            searchAction(
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
        </div> 
    );
};

TrackerFilterForm = reduxForm({
    form: 'TrackerFilterForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        owner: SELECT_ALL_OPTION["value"],
        severity: SELECT_ALL_OPTION["value"],
        status: SELECT_ALL_OPTION["value"],
        type: SELECT_ALL_OPTION["value"],
        agreement: SELECT_ALL_OPTION["value"]
    }
})(TrackerFilterForm);

const selector = formValueSelector('TrackerFilterForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(TrackerFilterForm);