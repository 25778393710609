import {api} from "api";
import { handleActions } from 'redux-actions';
import { createReducer } from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------
const endpoint = "feedback-form-agent-customer";
export const { reducers, initialState, actions } = createReducer(
    "feedbackFormClient",
    "feedback-form-agent-customer",
    "feedbackFormClientForm",
    "/feedback-form-client",
);

actions.toList = (page = 1) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store["feedbackFormClient"];
    const {ClientFeedbackFilterForm} = store.form;
    const params = { page };
    params.ordering = resource.ordering;
    if (ClientFeedbackFilterForm && ClientFeedbackFilterForm.values) {
        const {search} = ClientFeedbackFilterForm.values;
        if (search != "" && search != undefined && search != null) {
            params.search = search
        }
    }
    dispatch(actions.setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(actions.setData(response));
        dispatch(actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(actions.setLoader(false));
    });
};
actions.searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(actions.toList());
    }, 400);
};

export default handleActions(reducers, initialState);
