import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {validate, validators} from 'validate-redux-form';
import {renderField, renderFieldRadio, renderTextArea} from '../../Utils/renderField/renderField';


const NotificacionForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="notificacioForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <h4>Notification types</h4>
                    <div className="row">
                        <div className="col-lg-12">
                            <Field
                                type="radio"
                                name="typeNoti"
                                label="Success"
                                value="success"
                                component={renderFieldRadio}
                            />
                            <Field
                                type="radio"
                                name="typeNoti"
                                label="Information"
                                value="info"
                                component={renderFieldRadio}
                            />
                            <Field
                                type="radio"
                                name="typeNoti"
                                label="warning"
                                value="warning"
                                component={renderFieldRadio}
                            />
                            <Field
                                type="radio"
                                name="typeNoti"
                                label="Error"
                                value="error"
                                component={renderFieldRadio}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <h4>Write the message</h4>
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="">Title </label>
                            <Field
                                name="titulo"
                                label="Title"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <label htmlFor="">Message</label>
                            <Field
                                name="message"
                                label="message"
                                component={renderTextArea}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row mb-5 mt-4">
                        <div className="col-lg-12 d-flex justify-content-end">
                            <button
                                className="btn btn-primary">
                                SEND NOTIFICATIONS
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'notificacioForm',
    initialValues: {
        typeNoti: 'error',
    },
    validate: (data) => {
        return validate(data, {
            typeNoti: validators.exists()('This field is required'),
            title: validators.exists()('This field is required'),
            message: validators.exists()('This field is required'),
        });
    },
})(NotificacionForm);
