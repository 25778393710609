import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderSearchField } from "../Utils/renderField";

let FeedbackAgentFilterForm = (props) => {
    //  Bind
    const {searchAction} = props;
    return (
        <div className="row justify-content-between mt-2 w-100 mb-3">
            <div
                className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}
            >
                <div className="form-group has-feedback w-100 mb-0">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={"Search"}
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>
            </div>
            <Link
                to={"feedback-form-agent/create"}
                className="btn btn-primary px-3 col-lg-2 col-sm-10 pt-2 text-white"
            >
                Create Feedback Form
            </Link>
        </div>
    );
}


FeedbackAgentFilterForm = reduxForm({
    form: 'FeedbackAgentFilterForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(FeedbackAgentFilterForm);


export default FeedbackAgentFilterForm;