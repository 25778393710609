import { connect } from 'react-redux';
import CreateUpdateTrackerField from './CreateUpdateTrackerField';
import { actions } from "../../../../../redux/modules/Tracker/field";


const ms2p = (state) => {
    return {
        ...state.trackerField,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CreateUpdateTrackerField);
