import {connect} from 'react-redux';
import {actions} from '../../../../redux/modules/broker/broker';
import CreateBrokerage from "./CreateBrokerage";

const ms2p = (state) => {
    return {
        ...state.broker,
    };
};

const md2p = {...actions};

export default connect(ms2p, md2p)(CreateBrokerage);