import React, {Component} from 'react';
import {reduxForm} from "redux-form";
import {validate, validators} from "validate-redux-form";
import {FeedbackFormCustomerForm} from "../FeedbackFormCustomerForm";


const JobTypeEditForm = reduxForm({
    form: 'feedbackFormCustomerForm', // a unique identifier for this form
    asyncBlurFields: [],
    validate: (data) => {
        return validate(data, {
            name: validators.exists()('This field is required'),
            job_type: validators.exists()('This field is required'),
            base_price: validators.exists()('This field is required'),
        });
    },
})(FeedbackFormCustomerForm);


export default class FeedbackFormCustomerView extends Component {
    componentDidMount() {
        const {read, match: {params: {id}}} = this.props;
        read(id);
    }

    render() {
        const {edit, match: {params: {id}}} = this.props;
        return (
            <React.Fragment>
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div>
                        <div className="header-page"> View Customer Feedback Form</div>
                    </div>
                </div>
                <JobTypeEditForm
                    onSubmit={(data) => {
                        edit(id, data);
                    }}
                    seeView
                />
            </React.Fragment>
        );
    }
}
