import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderSearchField } from "../../Utils/renderField";

let ReferralRawDataFilterForm = (props) => {
    //  State
    const { statistics } = props;
    //  Bind
    const { searchAction } = props;
    return (
        <div className="row mt-2 w-100 mb-3 align-items-center justify-content-between">
            <div
                className={`col-xl-3 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end order-2 order-lg-1`}
            >
                <div className="form-group has-feedback w-100 mb-0 mt-3 mt-lg-0">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={"Search"}
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>
            </div>
            {/* STATISTICS */}
            <div className="col-xl-7 col-lg-8 col-md-12 d-flex flex-column flex-lg-row order-1 order-lg-2">
                <div className="flex-1 px-2">
                    <h6 style={{font: "normal normal 600 1.1rem Poppins-SemiBold"}}>Referal Sent</h6>
                    <div className="rounded border p-2 d-flex align-items-center justify-content-center">
                        <h6 className="mb-0">{statistics && statistics.total_sent ? statistics.total_sent : 0}</h6>
                    </div>
                </div>
                <div className="flex-1 px-2">
                    <h6 style={{font: "normal normal 600 1.1rem Poppins-SemiBold"}}>Agents registered</h6>
                    <div className="rounded border p-2 d-flex align-items-center justify-content-center">
                        <h6 className="mb-0">{statistics && statistics.total_agent_registered ? statistics.total_agent_registered : 0} ({statistics && statistics.percent_agent ? statistics.percent_agent : 0}%)</h6>
                    </div>
                </div>
                <div className="flex-1 px-2">
                    <h6 style={{font: "normal normal 600 1.1rem Poppins-SemiBold"}}>Broker sign</h6>
                    <div className="rounded border p-2 d-flex align-items-center justify-content-center">
                        <h6 className="mb-0">{statistics && statistics.total_broker_registered ? statistics.total_broker_registered : 0} ({statistics && statistics.percent_broker ? statistics.percent_broker : 0}%)</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}


ReferralRawDataFilterForm = reduxForm({
    form: 'referralRawDataFilterForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ReferralRawDataFilterForm);


export default ReferralRawDataFilterForm;