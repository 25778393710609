import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/feedbackFormCustomer/feedbackFormCustomer";
import FeedbackFormCustomerEdit from './FeedbackFormCustomerEdit';


const ms2p = (state) => {
    return {
        ...state.feedbackFormCustomer,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FeedbackFormCustomerEdit);
