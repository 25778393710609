import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/agent/agent';
import ChangedPassword from "./ChangedPassword";

const ms2p = (state) => {
    return{
        ...state.agent,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ChangedPassword);
