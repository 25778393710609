import React, { Component } from 'react'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import NotificationForm from './NotificationForm';

class UpdateNotification extends Component {
    constructor(props) {
        super(props)

    }

    componentWillMount() {

    }

    componentDidMount() {
        const {match, read} = this.props;
        if (match.params.id) {
            read(match.params.id);
        }
    }

    goBack = () => {
        const {history} = this.props;
        history.goBack()
    }

    submit = (data) => {
        const {match, item} = this.props;
        let itemId = item ? item.id : null
        if (match.params.id) {
            itemId = match.params.id
        }
        if (itemId) {
            this.props.edit(itemId, data)
        }
    }

    render() {
        // State
        const {loader, item} = this.props;
        
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Update Notification</div>
                    </div>
                </div>

                <div className="card border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4">
                    <LoadMask loading={loader} dark blur>
                        <NotificationForm
                            loader={loader}
                            item={item}
                            onSubmit={this.submit}
                            goBack={this.goBack}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default UpdateNotification