import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import { MultiSelectField, SelectField, renderDayPicker, renderSearchField } from "../../Utils/renderField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { JOB_DETAILS_RAW_DATA_TIME, SELECT_ALL_OPTION } from "../../../../utility/constants";


const Form = (props) => {
    //  State
    const { timeValue, start_dateValue, end_dateValue, downloadLoader } = props;
    //  Bind
    const { searchAction, setStatusFilter, setTimeFilter, downloadReport } = props;
    // STATUS FILTER
    const JOB_STATUS = [
        {value: 'OPENED', label: 'Opened'},
        {value: 'CLAIMED', label: 'Claimed'},
        {value: 'COMPLETED', label: 'Completed'},
        {value: 'CANCELLED', label: 'Cancelled'},
    ]
    return (
        <div className="row w-100 align-items-center">
            <div
                className="col-xl-3 col-lg-5 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end"
            >
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder="Search"
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>
            </div>
            <div className="col-xl-9 col-lg-7 col-sm-12">
                <div className="row justify-content-end align-items-center">
                    <div className="form-group has-feedback col-xl-3 col-lg-4 col-sm-12 col-md-6">
                        <label htmlFor="filter_category">Job Status</label>
                        <Field
                            name="jobStatus"
                            // isMulti={false}
                            options={JOB_STATUS}
                            set_value={setStatusFilter}
                            component={MultiSelectField}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback col-xl-3 col-lg-4 col-sm-12 col-md-6">
                        <label htmlFor="time">Time</label>
                        <Field
                            name="time"
                            isMulti={false}
                            options={JOB_DETAILS_RAW_DATA_TIME}
                            set_value={(option) => {
                                setTimeFilter(option ? option.value : "")
                            }}
                            component={SelectField}
                            className="form-control"
                            disabled={false}
                        />
                    </div>
                    {timeValue == 'custom' && (
                        <React.Fragment>
                            <div className="col-xl-3 col-lg-4 col-12">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="start_date">Start Date</label>
                                    <Field
                                        name="start_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        maxDate={end_dateValue ? moment(end_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            setTimeFilter("")
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-12">
                                <div className="form-group has-feedback w-100">
                                    <label htmlFor="end_date">End Date</label>
                                    <Field
                                        name="end_date"
                                        initialDate={moment().format('YYYY-MM-DD').toString()}
                                        component={renderDayPicker}
                                        minDate={start_dateValue ? moment(start_dateValue).toDate() : new Date()}
                                        onChange={(e) => {
                                            setTimeFilter("")
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                     <button
                        className="btn btn-primary text-white col-xl-3 col-lg-4 col-sm-10 h-75"
                        disabled={downloadLoader === true}
                        onClick={downloadReport}
                    >
                        <i
                            className={`fa ${
                                !downloadLoader
                                    ? "fa-download"
                                    : "fa-spinner fa-pulse fa-fw"
                            } mr-2`}
                        />
                        Download Report
                    </button>
                </div>
            </div>
        </div>
    )
}

const selector = formValueSelector('vendorRawDataForm');

let VendorRawDataForm = reduxForm({
    form: "vendorRawDataForm", // a unique identifier for this form
    initialValues: {'jobStatus': SELECT_ALL_OPTION.value}
})(Form);

const mstp = state => {
    const timeValue = selector(state, 'time');
    const start_dateValue = selector(state, 'start_date');
    const end_dateValue = selector(state, 'end_date');

    return {
        timeValue,
        start_dateValue,
        end_dateValue,
    };
};

export default connect(mstp, null)(VendorRawDataForm);

