import React from 'react';
import {Line} from 'react-chartjs-2';
import LoadMask from "../../Utils/LoadMask/LoadMask";

const dataExample = {
    labels: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
        {
            data: [],
            fill: false,
            tension: 0.4,
            label: 'Jobs',
            borderWidth: 1.5,
            borderColor: 'rgb(25,164,239)',
            backgroundColor: 'rgb(99,133,255)',
        },
    ],
};
// link to library doc
// https://www.chartjs.org/docs/latest/samples/scale-options/ticks.html
const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        x: {
            display: true,
            grid: {
                drawBorder: false,
                color: function (context) {
                    return '#FFFFFF';
                },
            },
            ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                // callback: function (val, index) {
                //     // Hide the label of every 2nd dataset
                //     return index % 2 === 0 ? this.getLabelForValue(val) : '';
                // },
                // color: 'red',
                font: {
                    family: 'Poppins',
                    size: 10,
                    // weight: 'bold',
                    lineHeight: 1.2,
                },
            },
        },
    },
    plugins: {
        legend: {
            position: null,
        },
    },
    animation: false
};

const ChartJobWeek = (props) => {
    const {data, loading} = props;
    return (
        <LoadMask loading={loading} dark blur>
            <div className="p-2 flex-1">
                <div className="row justify-content-end mx-auto px-3">
                    <a
                        className="pointer flex-row align-items-center row px-0"
                        style={{fontSize: '0.7rem'}}
                        href="#/stats/agent-performance">
                        View all stats
                        <i className="material-icons">arrow_right_alt </i>
                    </a>
                </div>
                <Line
                    data={data}
                    options={options}
                    height={190}
                />
            </div>
        </LoadMask>
    );
};
export default ChartJobWeek;
