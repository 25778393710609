import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/stats/revenue'
import Stats from "./Stats";

const ms2p = (state) => {
    return{
        ...state.statsRevenue,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Stats);
