import _ from "lodash";
import { api } from "api";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { change, initialize } from "redux-form";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { SELECT_ALL_OPTION } from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const SET_FRANCHISES = "SET_FRANCHISES_DELETEDAGENT";
const SET_BROKERS = "SET_BROKERS_DELETEDAGENT";
const SET_BROKERAGES = "SET_BROKERAGES_DELETEDAGENT";
const SET_WORKING_AREA = "SET_WORKING_AREA_DELETEDAGENT";

//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "deletedAgent",
    "deleted_agent",
    "deletedAgentForm",
    "/deleted-agents"
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsFranchises = (franchises) => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});

const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});

const setWorkingArea = (workingArea) => ({
    type: SET_WORKING_AREA,
    workingArea,
});

// ------------------------------------
// Actions
// ------------------------------------
export const searchChange = (search) => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    setTimeout(() => {
        dispatch(toList());
    }, 400)
};
export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case "state":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "franchise":
                setTimeout(() => {
                    dispatch(getBrokerOption(true));
                }, 500);
                break;
            case "broker":
                setTimeout(() => {
                    dispatch(getBrokeragesOption(true));
                }, 500);
                break;
            case "brokerage":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "registered_status":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "unregistered_status":
                setTimeout(() => {}, 500);
                break;
            case "search":
                dispatch(searchChange(value));
                break;
            default:
                break;
        }
    };

export const getFranchisesOption =
    (getData = null, selectAll = false) =>
    (dispatch) => {
        api.get("general/option_franchise", { all: true })
            .then((response) => {
                let options = response.results;
                if (response.results.length > 1) {
                    // let value = [];
                    // response.results.map((franchise) => {
                    //     options.push({
                    //         label: franchise.name,
                    //         value: franchise.id,
                    //     });
                    //     value.push(franchise.id);
                    // });
                    if (selectAll) {
                        dispatch(
                            change(
                                "DeletedAgentFiltersForm",
                                "franchise",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    // response.results.map((franchise) => {
                    //     options.push({
                    //         label: franchise.name,
                    //         value: franchise.id,
                    //     });
                    // });
                    const franchise_id = options.length ? options[0].value : "";
                    dispatch(
                        change("DeletedAgentFiltersForm", "franchise", [
                            franchise_id,
                        ])
                    );
                    setTimeout(() => {
                        dispatch(getBrokerOption());
                    }, 500);
                }
                dispatch(setOptionsFranchises(options));
            })
            .catch(() => {
                dispatch(setOptionsFranchises([]));
                dispatch(change("DeletedAgentFiltersForm", "franchise", ""));
                setTimeout(() => {
                    dispatch(getBrokerOption());
                }, 500);
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                    }, 500);
                }
            });
    };

export const getBrokerOption =
    (getData = null, selectAll = false, brokerId = null, brokerageId = null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { DeletedAgentFiltersForm } = store.form;
        let params = { state: "", franchise: "", is_multi: true, all: true };
        if (DeletedAgentFiltersForm && DeletedAgentFiltersForm.values) {
            const { state, franchise } = DeletedAgentFiltersForm.values;
            if (
                franchise !== "" &&
                franchise !== null &&
                franchise !== undefined &&
                franchise !== SELECT_ALL_OPTION["value"]
            ) {
                // params.franchise = franchise;
                if (franchise.length == 0) {
                    params.franchise = "";
                } else {
                    params.franchise = franchise;
                }
            }
        }

        api.get(`general/option_broker`, params)
            .then((response) => {
                console.log("RESP BRK: ".response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll) {
                        dispatch(
                            change(
                                "DeletedAgentFiltersForm",
                                "broker",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }

                    dispatch(getBrokeragesOption(getData, selectAll));
                } else {
                    option = [...response.results];
                    console.log("OPT: ", option);
                    const broker_id = option.length ? option[0].value : "";
                    dispatch(
                        change("DeletedAgentFiltersForm", "broker", [broker_id])
                    );
                    setTimeout(() => {
                        dispatch(getBrokeragesOption(getData, selectAll));
                    }, 500);
                }
                dispatch(setBrokers(option));
            })
            .catch(() => {
                dispatch(setBrokers([]));
                dispatch(change("DeletedAgentFiltersForm", "broker", ""));
                setTimeout(() => {
                    dispatch(getBrokeragesOption(getData, selectAll));
                }, 500);
            })
            .finally(() => {});
    };

export const getBrokeragesOption =
    (getData = null, selectAll = false) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { DeletedAgentFiltersForm } = store.form;
        let params = {
            broker_id: "",
            state: "",
            franchise: "",
            is_multi: true,
            all: true
        };
        if (DeletedAgentFiltersForm && DeletedAgentFiltersForm.values) {
            const { state, franchise, broker } = DeletedAgentFiltersForm.values;
            // if (
            //     broker !== "" &&
            //     broker !== null &&
            //     broker !== undefined &&
            //     broker !== SELECT_ALL_OPTION["value"]
            // ) {
            //     // params.broker_id = broker;
            //     if (broker.length == 0) {
            //         params.broker_id = "";
            //     } else {
            //         params.broker_id = broker;
            //     }
            // }
            // if (
            //     franchise !== "" &&
            //     franchise !== null &&
            //     franchise !== undefined &&
            //     franchise !== SELECT_ALL_OPTION["value"]
            // ) {
            //     // params.franchise = franchise;
            //     if (franchise.length == 0) {
            //         params.franchise = "";
            //     } else {
            //         params.franchise = franchise;
            //     }
            // }
        }

        api.get(`general/option_brokerage`, params)
            .then((response) => {
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll) {
                        dispatch(
                            change(
                                "DeletedAgentFiltersForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    const brokerage_id = option.length ? option[0].value : "";
                    dispatch(
                        change("DeletedAgentFiltersForm", "brokerage", [
                            brokerage_id,
                        ])
                    );
                    if (option.length) {
                        if (option[0].provide_agents_roster) {
                            dispatch(setProvideAgentsRoster(true));
                        } else {
                            dispatch(setProvideAgentsRoster(null));
                        }
                    } else {
                        dispatch(setProvideAgentsRoster(null));
                    }
                }
                dispatch(setBrokerages(option));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(change("DeletedAgentFiltersForm", "brokerage", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                    }, 500);
                }
            });
    };

const toList =
    (page = 1) =>
    (dispatch, getStore) => {
        const store = getStore();
        const resource = store["deletedAgent"];
        let params = { page };
        params.ordering = resource.ordering;
        // params.search = resource.search;
        const { DeletedAgentFiltersForm } = store.form;
        if (DeletedAgentFiltersForm && DeletedAgentFiltersForm.values) {
            const { state, franchise, broker, brokerage, registered_status, search } =
                DeletedAgentFiltersForm.values;
            if (
                state !== "" &&
                state !== null &&
                state !== undefined &&
                state !== SELECT_ALL_OPTION["value"]
            ) {
                if (state.length == 0) {
                    params.brokerage_information__broker__state__in = "null";
                } else {
                    params.brokerage_information__broker__state__in = state;
                }
            }
            if (
                franchise !== "" &&
                franchise !== null &&
                franchise !== undefined &&
                franchise !== SELECT_ALL_OPTION["value"]
            ) {
                if (franchise.length == 0) {
                    params.removed_from__brokerage_information__broker__franchise__id__in = 0;
                } else {
                    params.removed_from__brokerage_information__broker__franchise__id__in =
                        franchise;
                }
            }
            if (
                broker !== "" &&
                broker !== null &&
                broker !== undefined &&
                broker !== SELECT_ALL_OPTION["value"]
            ) {
                if (broker.length == 0) {
                    params.removed_from__brokerage_information__broker__id__in = 0;
                } else {
                    params.removed_from__brokerage_information__broker__id__in = broker;
                }
            }
            if (
                brokerage !== "" &&
                brokerage !== null &&
                brokerage !== undefined &&
                brokerage !== SELECT_ALL_OPTION["value"]
            ) {
                if (brokerage.length == 0) {
                    params.removed_from__brokerage_information__id__in = 0;
                } else {
                    params.removed_from__brokerage_information__id__in = brokerage;
                }
            }
            if (search != "" && search != undefined && search != null) {
                params.search = search
            }
        }
        dispatch(basicReducer.actions.setLoader(true));
        api.get("deleted_agent", params)
            .then((response) => {
                dispatch(basicReducer.actions.setData(response));
                dispatch(basicReducer.actions.setPage(page));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(basicReducer.actions.setLoader(false));
            });
    };

export const initBrokerBrokerageFormOptions = (id) => (dispatch) => {
    dispatch(initialize("deletedAgentForm", null));
    api.get(`general/option_broker`, {})
        .then(async (response) => {
            let options = [];
            options = [
                {
                    label: "",
                    value: "",
                },
                ...response.results,
            ];
            dispatch(setBrokers(options));
            await api
                .get(`general/option_brokerage`, {})
                .then((response) => {
                    let options = [];
                    options = [
                        {
                            label: "",
                            value: "",
                        },
                        ...response.results,
                    ];
                    dispatch(setBrokerages(options));
                })
                .catch(() => {
                    dispatch(setBrokerages([]));
                })
                .finally(() => {});

            dispatch(basicReducer.actions.read(id));
        })
        .catch(() => {
            dispatch(setBrokers([]));
        })
        .finally(() => {});
};
export const getBrokerageFormOption =
    (broker_id = null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { deletedAgentForm } = store.form;
        let brokerage_idValue = null;
        if (deletedAgentForm.values) {
            const { brokerage_id } = deletedAgentForm.values;
            if (brokerage_id) {
                brokerage_idValue = brokerage_id;
            }
        }
        let params = {};
        if (broker_id) {
            params.broker_id = broker_id;
        }
        api.get(`general/option_brokerage`, params)
            .then((response) => {
                let options = [];
                options = [
                    {
                        label: "",
                        value: "",
                    },
                    ...response.results,
                ];
                const isOffice = _.find(options, { value: brokerage_idValue });

                if (!isOffice) {
                    dispatch(change("deletedAgentForm", "brokerage_id", ""));
                }
                dispatch(setBrokerages(options));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
            })
            .finally(() => {});
    };

const reinstateAgent = (id, data, goBack) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    const store = getStore();
    const { workingArea } = store["deletedAgent"];
    const working_area = { ...data.working_area, ...workingArea };
    api.put(`deleted_agent/${id}`, { ...data, working_area })
        .then(() => {
            NotificationManager.success("Reinstated Agent", "Success", 3000);
            goBack()
        })
        .catch(() => {
            NotificationManager.error("Agent Reinstatement Failed", "ERROR", 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

export const actions = {
    ...basicReducer.actions,
    searchChange,
    changeFilters,
    getFranchisesOption,
    getBrokerOption,
    getBrokeragesOption,
    toList,
    initBrokerBrokerageFormOptions,
    setWorkingArea,
    getBrokerageFormOption,
    reinstateAgent,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_FRANCHISES]: (state, { franchises }) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, { brokers }) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, { brokerages }) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_WORKING_AREA]: (state, { workingArea }) => {
        return {
            ...state,
            workingArea,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
    brokers: [],
    brokerages: [],
    franchises: [],
    workingArea: {
        latitude_center: 0,
        longitude_center: 0,
    },
};

export default handleActions(reducers, initialState);
