import React, { Component } from 'react'
import TipsGuidelinesForm from './TipsGuidelinesForm'
const typeText = 100

export default class TipsGuidelines extends Component {
    componentDidMount() {
        this.props.getSettingsText('TipsGuidelinesTextForm', typeText)
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const {loader, onSubmitSettingText} = this.props
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Tips and Guidelines Text</div>
                    </div>
                </div>
                <TipsGuidelinesForm
                    onSubmit={(data) => {
                        onSubmitSettingText({...data, type: typeText})
                    }}
                    goBack={this.goBack}
                    loader={loader}
                    />
            </div>
        )
    }
}
