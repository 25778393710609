import React, {useState, useEffect} from 'react';
import moment from "moment";
import HeaderCard from "../HeaderCard";
import Grid from "../Utils/GridTableWithScroll";
import FranchisesFilterForm from "./FranchisesFilterForm"
import {standardActions} from "../Utils/Grid/StandardActions";
import {FRANCHISE_PAGE_SIZE} from "../../../utility/constants";


const FranchisesGrid = (props) => {
    const {data, loader, page, searchAction, toList, remove} = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("FRANCHISE-LIST");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div className="">
                    <div className="header-page">Franchises</div>
                </div>
            </div>
            {/* <HeaderCard
                showFile={false}
                active_link={true}
                text_link={"Create"}
                id_header={'FranchisesList'}
                link={'franchises/create'}
                justify={'justify-content-between mt-2'}
                searchAction={(value) => searchAction(value)}
                searchStyle="col-lg-4 col-sm-12 img-fluid mb-sm-1 ml-3"
            /> */}
            <FranchisesFilterForm searchAction={searchAction}/>
            <div id="FRANCHISE-LIST" className="container-list card border-dark border-card-page px-4 py-0">
                <div className="card-body d-flex flex-column px-0 py-2">
                    <Grid
                        hover
                        height={gridHeight}
                        striped
                        page={page}
                        keyField={'id'}
                        loading={loader}
                        data={data ? data : []}
                        wrapperClasses="table-scroll-auto"
                        sizePerPage={FRANCHISE_PAGE_SIZE}
                        onPageChange={(value) => toList(value)}
                        columns={[
                            {
                                dataField: "id",
                                text: "",
                                formatter:
                                    standardActions({
                                        remove: remove,
                                        // view: "franchises",
                                        edit: 'franchises',
                                    }),
                            },
                            {
                                dataField: "logo",
                                formatter: loadImg(),
                                text: "",
                            },
                            {
                                dataField: "created",
                                formatter: dateCreated(),
                                text: "Date created",
                            },
                            {
                                dataField: "name",
                                sort: true,
                                text: "Name",
                            },
                            // {
                            //     dataField: "activity",
                            //     sort: true,
                            //     text: "Activity",
                            // },
                        ]}
                    />
                </div>
            </div>

        </div>
    );
};

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('LL')}
            </p>
        )
    };
}

export function loadImg() {
    return (cell, row) => {
        return (
            <React.Fragment>
                <div style={{width: cell ? "28px" : "30px", height: cell ? "22px" : "25px"}} className="p-0 m-0">
                    <img
                        src={cell ? cell : require('../../../../assets/img/uploadLogo.PNG')}
                        alt='logo'
                        className="align-self-center w-75 justify-content-center"
                    />
                </div>
            </React.Fragment>
        )
    };
}


export default FranchisesGrid;
