import React, {useState, useEffect} from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { api } from '../../../../utility/api';



const HtmlDemo = () => {
    const [html, setHtml] = useState('')
    const [loader, setLoader] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        getHtmlApi()
        
    }, [])

    const getHtmlApi = () => {
        setLoader(true)
        api.get('general/get_html_demo')
            .then(response => {
                console.log("RESPONSE: ", response);
                const {html_demo} = response
                const htmlDemo = html_demo ? html_demo : ''
                setHtml(htmlDemo)
                const contentBlock = htmlToDraft(htmlDemo);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setEditorState(editorState);
                }
            }).catch(error => {
                console.log("ERROR: ", error)
                setHtml('')
            }).finally(() => {
                setLoader(false)
            })
    }

    const saveHtmlApi = () => {
        setLoader(true)
        api.put('general/change_html_demo', {html_demo: html})
            .then(response => {
                console.log("RESPONSE: ", response);
                getHtmlApi()
            }).catch(error => {
                console.log("ERROR: ", error)
            }).finally(() => {
                setLoader(false)
            })
    }

    return (
        <div id="PAGE-CONTAINER">
            <div className="page-header pl-1 pt-3 no-gutters row">
                <div>
                    <div className="header-page">
                        Text Demo
                    </div>
                </div>
            </div>

            <div className="card border-dark border-card-page px-4 py-4 mb-lg-0 mb-sm-4 flex-1">
                <LoadMask loading={loader} dark blur>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper-text"
                        editorClassName="demo-editor-text"
                        onEditorStateChange={(editorState) => {
                            const formato = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                            console.log("VALUE: ", formato);
                            // input.onChange(formato);
                            setHtml(formato)
                            setEditorState(editorState);
                        }}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'image', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                            inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript']
                            },
                            textAlign: {
                                inDropdown: true,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['left', 'center', 'right', 'justify']
                            },
                            image: {
                                // uploadCallback: this.uploadImagen,
                                previewImage: true,
                                alt:""
                            }
                        }}
                    />
                    <div
                        className={`row mx-auto justify-content-center my-3`}
                    >
                        <button
                            onClick={saveHtmlApi}
                            type="button"
                            className="btn btn-secondary"
                            disabled={loader}
                        >
                            Save Changes
                        </button>
                    </div>
                </LoadMask>
            </div>

        </div>
    )
}

export default HtmlDemo