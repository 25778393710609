import React from "react";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderTextArea
} from "../../Utils/renderField/renderField";

const validate = (values, props) => {
    let errors = {};

    if (!values.title) {
        errors.title = "This field is required";
    }
    if (!values.message) {
        errors.message = "This field is required"
    }

    return errors;
};

const NotificationForm = (props) => {
    //  State
    const {loader, item} = props
    //  Bind
    const { handleSubmit, goBack } = props

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row mx-auto mt-3 w-75">
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="title">Title</label>
                    <Field
                        name="title"
                        label="title"
                        component={renderField}
                        type="text"
                        className="form-control"
                        disabled={false}
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="variables">Variables</label>
                    <br />
                    {item && item.variables && item.variables.length > 0 && (
                        <small className="text-info">These variables can be embedded in the message</small>
                    )}
                    <ul>
                        {item && item.variables && item.variables.map((itemVar, index) => {
                            return (
                                <li key={index}>{itemVar}</li>
                            )
                        })}
                    </ul>
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="message">Message</label>
                    <Field
                        name="message"
                        label="Message"
                        component={renderTextArea}
                        type="text"
                        rows={4}
                        customClass="form-control text-area-h-auto"
                    />
                </div>
                <div className="col-lg-6 col-sm-12 form-group has-feedback">
                    <label htmlFor="description">Description</label>
                    <Field
                        name="description"
                        label="Description"
                        component={renderTextArea}
                        type="text"
                        rows={4}
                        customClass="form-control text-area-h-auto"
                    />
                </div>
            </div>
            <div className="row mx-auto mt-5 justify-content-center form-group has-feedback">
                <button
                    onClick={goBack}
                    type="button"
                    disabled={loader}
                    className="btn text-white btn-secondary  mx-3 col-2"
                >
                    Cancel
                </button>
                <button disabled={loader} type="submit" className="btn btn-primary mx-3 col-2">
                    Save
                </button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: "notificationForm", // a unique identifier for this form
    validate,
})(NotificationForm);