import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import momentTz from 'moment-timezone'
import "react-responsive-modal/styles.css";
import DayPicker from "../../Utils/DayPicker";
import TrackerNoteForm from './TrackerNoteForm'
import TrackerReminderForm from './TrackerReminderForm'
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../../../../../../node_modules/bootstrap/dist/css/bootstrap.css";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { CUSTOM_PAGE_SIZE_IN_BACKEND } from "../../../../utility/constants";

const SelectEditor = (props) => {
    //  State
    const { row, field, options = [] } = props;
    const [value, setValue] = useState(null);
    //  Bind
    const { onUpdate } = props;
    console.log("PROPS: ", props);

    useEffect(() => {
        if (field) {
            const option = _.find(options, { value: row[field] });
            if (option) {
                setValue(option);
            }
        }
    }, [options]);

    return (
        <div>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                name="color"
                options={options}
                defaultValue={value}
                onChange={(e) => {
                    console.log("E: ", e);
                    onUpdate(e ? e["value"] : null);
                }}
            />
        </div>
    );
};
const DateEditor = (props) => {
    //  State
    const { row, field } = props;
    const [startDate, setStartDate] = useState(null);
    //  Bind
    const { onUpdate } = props;

    useEffect(() => {
      if (row && row[field]) {
        setStartDate(moment(row[field]).toDate())
      } else {
        setStartDate(null)
      }
    }, [])
    

    return (
        <div>
            {/* <input type="date" name={field} id="date" data-date-format="DD MMMM YYYY" /> */}
            <DayPicker
                value={startDate}
                onChange={(date) => {
                    setStartDate(date)
                    onUpdate(date)
                }}
                />
        </div>
    )
}

const NoteModal = (props) => {
    //  State
    const { open } = props;
    //  Bind
    const { onClose, onSave } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-lg-4 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Tracker Note
                        </div>
                    </div>
                </div>
                <TrackerNoteForm
                    onSubmit={(data) => {
                        onSave(data)
                    }}
                    goBack={onClose}
                />
            </div>

        </Modal>
    )
}

const ReminderModal = (props) => {
    //  State
    const { open } = props;
    //  Bind
    const { onClose, onSave } = props;

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            onClose={onClose}
            classNames={{
                modal: "rounded col-xl-4 col-lg-5 col-md-8 col-10",
                closeIcon: "bg-gray rounded",
            }}
        >
            <div className="d-flex flex-column mt-4">
                <div className="page-header pl-1 pt-2 no-gutters row justify-content-between mb-3">
                    <div className="">
                        <div className="header-page ml-lg-3 ml-0">
                            Reminder
                        </div>
                    </div>
                </div>
                <TrackerReminderForm
                    onSubmit={(data) => {
                        const timezone = 'America/New_York'
                        console.log('DATA: ', data);
                        const {id, date, time, send_to} = data
                        const _date = moment(date).format('YYYY-MM-DD');
                        let timeStr = `${_date} ${time}`
                        const finalTime = momentTz.tz(timeStr, timezone).format()

                        const finalData = {
                            id,
                            date: finalTime,
                            data: {
                                contacts: send_to
                            }
                        }

                        console.log('FINAL DATA: ', finalData);
                        onSave(finalData)
                    }}
                    goBack={onClose}
                />
            </div>

        </Modal>
    )
}

const TrackerGrid = (props) => {
    const {
        data,
        page,
        toList,
        loader,
        onSortChange,
        remove,
        hidePagination = false,
        addRow,
        owners = [],
        severities = [],
        types = [],
        statuses = [],
        agreements = [],
        edit,
        noteModal=false,
        reminderModal=false,
        openNoteModal=() => {},
        closeNoteModal=() => {},
        saveNote=() => {},
        deleteNote=()=> {},
        saveReminder=() => {},
        openReminderModal=() => {},
        closeReminderModal=() => {},
    } = props;
    const [gridHeight, setGridHeight] = useState(200);

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("TRACKER-LIST");
            if (elementId) {
                _height = elementId.clientHeight;
            }
            setGridHeight(_height);
        }, 300);
    }, []);

    const tableOptions = {
        sizePerPage: CUSTOM_PAGE_SIZE_IN_BACKEND,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: "No data",
        page,
        onPageChange: changePage,
    };

    const changePage = (page) => {
        toList(page);
    };

    // const cellEditProp = {
    //     mode: "click",
    //     blurToSave: true,
    //     beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    //     afterSaveCell: onAfterSaveCell  // a hook for after saving cell
    // };

    const onAfterSaveCell = (row, cellName, cellValue) => {
        const _row = _.cloneDeep(row);
        console.log("ROW: ", row);
        console.log("CELL: ", cellName);
        console.log("CELL VALUE: ", cellValue);
        if (cellName) {
            _row[cellName] = cellValue ? cellValue : null;
            edit(row.id, _row);
        }
    };

    const onBeforeSaveCell = (row, cellName, cellValue) => {
        // You can do any validation on here for editing value,
        // return false for reject the editing
        console.log("ROW 2: ", row);
        return true;
    };

    const createSelectEditor = (onUpdate, props) => (
        <SelectEditor onUpdate={onUpdate} {...props} />
    );
    const createDateEditor = (onUpdate, props) => (
        <DateEditor onUpdate={onUpdate} {...props} />
    );

    return (
        <div
            id="TRACKER-LIST"
            className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1"
        >
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <LoadMask loading={loader} dark blur>
                    <div
                        className="d-flex flex-column flex-overflow-hidden-1"
                        style={
                            gridHeight
                                ? { maxHeight: `${gridHeight - 30}px` }
                                : {}
                        }
                    >
                        <div className="pb-1 d-flex justify-content-end">
                            <button
                                disabled={loader}
                                onClick={(e) => addRow()}
                                type="button"
                                className="btn btn-primary text-white"
                            >
                                Add Row
                            </button>
                        </div>
                        <BootstrapTable
                            data={
                                loader ? [] : data ? data : []
                            }
                            striped
                            // remote
                            // pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            containerClass="flex-1"
                            tableContainerClass="table-scroll-auto"
                            tableStyle={
                                gridHeight
                                    ? {
                                          maxHeight: `${gridHeight - 60}px`,
                                          minHeight: `${gridHeight - 60}px`,
                                      }
                                    : {}
                            }
                            cellEdit={{
                                mode: "click",
                                blurToSave: true,
                                afterSaveCell: onAfterSaveCell,
                            }}
                        >
                            <TableHeaderColumn
                                rowSpan="2"
                                isKey={true}
                                dataField="id"
                                width="50px"
                                dataFormat={standardActions({
                                    remove
                                })}
                                editable={false}
                            >
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="0"
                                colSpan="6"
                                headerAlign="center"
                            >
                                General Information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="id"
                                headerAlign="center"
                                dataAlign="center"
                                dataFormat={(cell, row, enumObject, index) => {
                                    return <span className="text-center">{index + 1}</span>
                                }}
                                width="70px"
                            >
                                Row #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="status"
                                dataFormat={(cell, row) => {
                                    const bg = cell ? row.status_data.color : 'transparent'
                                    return (
                                        <div className="w-100 d-flex justify-content-center">
                                            <span className="rounded px-3 py-2" style={{backgroundColor: bg, color: bg != 'transparent' ? invertColor(bg, true) : null}}>
                                                {cell ? row.status_data.name : ""}
                                            </span>
                                        </div>
                                    );
                                    // return (
                                    //     <div className="w-100 d-flex justify-content-center">
                                    //         <span className="rounded text-dark px-3 py-2" style={{backgroundColor: cell ? row.status_data.color : 'transparent'}}>
                                    //             {cell ? row.status_data.name : ""}
                                    //         </span>
                                    //     </div>
                                    // );
                                }}
                                width="150px"
                                tdStyle={{ minHeight: "50px" }}
                                customEditor={{
                                    getElement: createSelectEditor,
                                    customEditorParameters: {
                                        options: [
                                            { label: "None", value: '' },
                                            ...statuses,
                                        ],
                                        field: "status",
                                    },
                                }}
                            >
                                Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="date"
                                // editable={{ type: "date", attrs: {
                                //     placeholder: 'xd',
                                //     dateformat: "DD MMMM YYYY"
                                // }}}
                                dataFormat={(cell, row) => {
                                    return <span>{cell ? moment(cell).format('ll') : ''}</span>
                                }}
                                width="150px"
                                customEditor={{
                                    getElement: createDateEditor,
                                    customEditorParameters: {
                                        field: "date",
                                    },
                                }}
                            >
                                Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="owner"
                                dataFormat={(cell, row) => {
                                    return (
                                        <span>
                                            {cell ? row.owner_data.name : ""}
                                        </span>
                                    );
                                }}
                                width="150px"
                                tdStyle={{ minHeight: "50px" }}
                                customEditor={{
                                    getElement: createSelectEditor,
                                    customEditorParameters: {
                                        options: [
                                            { label: "None", value: '' },
                                            ...owners,
                                        ],
                                        field: "owner",
                                    },
                                }}
                            >
                                Owner
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="severity"
                                dataFormat={(cell, row) => {
                                    const bg = cell ? row.severity_data.color : 'transparent'
                                    return (
                                        <div className="w-100 d-flex justify-content-center">
                                            <span className="rounded px-3 py-2" style={{backgroundColor: bg, color: bg != 'transparent' ? invertColor(bg, true) : null}}>
                                                {cell ? row.severity_data.name : ""}
                                            </span>
                                        </div>
                                    );
                                }}
                                width="150px"
                                tdStyle={{ minHeight: "50px" }}
                                customEditor={{
                                    getElement: createSelectEditor,
                                    customEditorParameters: {
                                        options: [
                                            { label: "None", value: '' },
                                            ...severities,
                                        ],
                                        field: "severity",
                                    },
                                }}
                            >
                                Severity
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="type"
                                dataFormat={(cell, row) => {
                                    return (
                                        <span>
                                            {cell ? row.type_data.name : ""}
                                        </span>
                                    );
                                }}
                                width="150px"
                                tdStyle={{ minHeight: "50px" }}
                                customEditor={{
                                    getElement: createSelectEditor,
                                    customEditorParameters: {
                                        options: [
                                            { label: "None", value: '' },
                                            ...types,
                                        ],
                                        field: "type",
                                    },
                                }}
                            >
                                Type
                            </TableHeaderColumn>

                            {/* BROKERAGE / INFLUENCER INFORMATION */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="3"
                                headerAlign="center"
                            >
                                Brokerage / Influencer Information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_name"
                                editable={{ type: "text" }}
                                width="250px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="brokerage_franchise"
                                editable={{ type: "text" }}
                                width="150px"
                            >
                                Franchise
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                headerAlign='center'
                                dataAlign="center"
                                dataField="brokerage_number_agents"
                                editable={{ type: "number" }}
                                width="180px"
                            >
                                Number of Agents
                            </TableHeaderColumn>

                            {/* BROKER / INFLUENCER CONTACT INFORMATION */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="5"
                                headerAlign="center"
                            >
                                Brokerage / Influencer Information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="contact_name"
                                editable={{ type: "text" }}
                                width="150px"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="contact_mobile"
                                editable={{ type: "text" }}
                                width="150px"
                            >
                                Mobile #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="contact_office_number"
                                editable={{ type: "text" }}
                                width="150px"
                            >
                                Office #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="contact_email"
                                editable={{ type: "text" }}
                                width="150px"
                            >
                                Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="contact_position"
                                editable={{ type: "text" }}
                                width="150px"
                            >
                                Position
                            </TableHeaderColumn>

                            {/* PATH FORWARD */}
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="notes"
                                width="380px"
                                dataFormat={noteFormat(openNoteModal, deleteNote)}
                                editable={false}
                            >
                                Path Forward
                            </TableHeaderColumn>
                            {/* GENERAL */}
                            <TableHeaderColumn
                                row="0"
                                colSpan="5"
                                headerAlign="center"
                            >
                                General
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="reminder"
                                editable={false}
                                dataFormat={(cell, row) => {
                                    let data = {id: row.id}
                                    console.log('CELL REM: ', cell);
                                    if (!cell) {
                                        data.send_to = [{}]
                                    } else {
                                        data.date = cell.date
                                        data.time = cell.time
                                        const {data: cellData} = cell
                                        data.send_to = cellData && cellData.contacts ? cellData.contacts : []
                                    }

                                    return (
                                        <div className="d-flex flex-row justify-content-end">
                                            <span className="flex-1">{cell && row.remainder_date ? momentTz.tz(row.remainder_date, 'America/New_York').format('lll') : ''}</span>
                                            <i
                                                onClick={(e) => {
                                                    openReminderModal(data)
                                                }}
                                                className="pointer fa fa-clock-o fa-2x text-success"
                                            ></i>
                                        </div>
                                    )
                                }}
                                width="190px"
                            >
                                Reminder
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="refferal "
                                editable={{ type: "text" }}
                                width="150px"
                            >
                                Refferal
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="agreement_signed"
                                dataFormat={(cell, row) => {
                                    return (
                                        <span>
                                            {cell ? row.agreement_signed_data.name : ""}
                                        </span>
                                    );
                                }}
                                width="150px"
                                tdStyle={{ minHeight: "50px" }}
                                customEditor={{
                                    getElement: createSelectEditor,
                                    customEditorParameters: {
                                        options: [
                                            { label: "None", value: '' },
                                            ...agreements,
                                        ],
                                        field: "agreement_signed",
                                    },
                                }}
                            >
                                Agreement Signed
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
            <NoteModal
                open={noteModal}
                onClose={closeNoteModal}
                onSave={saveNote}
            />
            <ReminderModal
                open={reminderModal}
                onClose={closeReminderModal}
                onSave={saveReminder}
            />
        </div>
    );
};

export function dateCreated() {
    return (cell, row) => {
        return <p className="my-0">{moment(cell).format("lll")}</p>;
    };
}

function noteFormat (openNoteModal, deleteNote) {
    return (cell, row) => {
        return (
            <div className="w-100 d-flex justify-content-center">
                <div className="flex-1 d-flex flex-column">
                    {cell && cell.map((note, index) => {
                        return (
                            <div className="w-100 p-1 d-flex flex-row">
                                <div className="pr-2">
                                    <i
                                        onClick={(e) => {
                                            if (openNoteModal) {
                                                openNoteModal({contact_tracker_id: row.id, note: note.note, id: note.id}, note.id)
                                            }
                                        }}
                                        title="Edit"
                                        style={{ fontSize: "1.1rem" }}
                                        className="pointer fa fa-pencil-square text-info mr-1"
                                    ></i>
                                    <i
                                        onClick={(e) => {
                                            if (deleteNote) {
                                                deleteNote(note.id)
                                            }
                                        }}
                                        title="Delete"
                                        style={{ fontSize: "1.1rem" }}
                                        className="pointer fa fa-trash text-danger ml-1"
                                    ></i>
                                </div>
                                <p className="mb-0 flex-1">{note.note}</p>
                            </div>
                        );
                    })}
                </div>
                <div>
                    <div
                        className="pointer"
                        title="Add Note"
                        style={{
                            width: "2rem",
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        onClick={(e) => {
                            if (openNoteModal) {
                                openNoteModal({contact_tracker_id: row.id, note: ''})
                            }
                        }}
                    >
                        <i
                            className="fa fa-sticky-note fa-2x text-success"
                            style={{ position: "relative" }}
                        ></i>
                        <i
                            class="fa fa-plus text-white"
                            aria-hidden="true"
                            style={{ position: "absolute" }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    }
}

function invertColor(hex, bw) {
    try {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + padZero(r) + padZero(g) + padZero(b);
    } catch (error) {
        return 'transparent'
    }
}

export default TrackerGrid;
