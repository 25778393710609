import _ from 'lodash'
import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = 'LOGIN_LOADER';

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const getSettings = (form='settingForm') => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('general/setting').then((response) => {
        dispatch(initializeForm(form, response));
    }).catch(() => {
        dispatch(initializeForm(form, {}));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.put('general/change_setting', data).then((response) => {
        dispatch(initializeForm('settingForm', response));
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch(() => {
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const updateLeads = (data = {}, files = [], resetIcons=null) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let finalFile = []
    console.log("FILES: ", files);
    if (files && files.length) {
        files.map(itemFile => {
            if (itemFile !== undefined) {
                finalFile.push(itemFile)
            }
        })
    }
    console.log('FINAL FILES: ', finalFile);
    api.putAttachments(`general/change_lead_setting`, data, finalFile).then((response) => {
        dispatch(getSettings('leadsSettingForm'));
        if (resetIcons) {
            resetIcons()
        }
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch((error) => {
        console.log('ERROR[UPDATE-SETTINGS]: ', error);
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getSettingsText = (form='settingForm', type) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('general/setting').then((response) => {
        const {texts} = response
        let data = {
            title: null,
            description: null,
        }

        if (texts) {
            const settingText = _.find(texts, {type})
            if (settingText) {
                data.title = settingText.title
                data.description = settingText.description
            }
        }
        console.log(('DATA: ', data));

        dispatch(initializeForm(form, data));
    }).catch(() => {
        dispatch(initializeForm(form, {}));
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onSubmitSettingText = (data = {}, formName, type) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.put('general/change_setting_text', data).then((response) => {
        dispatch(getSettingsText(formName, type));
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch(() => {
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onSubmitOpenHouseText = (data = {}, files=[], resetIcons=null) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.putAttachments('general/change_open_house_text', data, files).then((response) => {
        dispatch(getSettings('openHouseTextForm'));
        if (resetIcons) {
            resetIcons()
        }
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch(() => {
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onSubmitCounterOffer = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.put('general/change_counter_offer_text', data).then((response) => {
        dispatch(getSettings('counterOfferForm'));
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch((error) => {
        console.log("ERROR: ", error);
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getGiftCardSetting = (form='giftCardTextForm') => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('general/get_gift_card_texts').then((response) => {
        dispatch(initializeForm(form, response));
    }).catch(() => {
        dispatch(initializeForm(form, {}));
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const updateGiftCardSetting = (data = {}, files = []) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    if (files && files.length > 0) {
        api.putAttachments(`general/change_gift_card_texts`, data, files).then((response) => {
            dispatch(getGiftCardSetting('giftCardTextForm'));
            NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
        }).catch((error) => {
            console.log('ERROR[UPDATE-SETTINGS]: ', error);
            NotificationManager.error('Error registering data', 'ERROR', 0);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        api.put(`general/change_gift_card_texts`, data).then((response) => {
            dispatch(getGiftCardSetting('giftCardTextForm'));
            NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
        }).catch((error) => {
            console.log('ERROR[UPDATE-SETTINGS]: ', error);
            NotificationManager.error('Error registering data', 'ERROR', 0);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
}

export const onSubmitVendorText = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.put('general/change_add_remove_vendor_profession_text', data).then((response) => {
        dispatch(getSettings('vendorTextForm'));
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch((error) => {
        console.log("ERROR: ", error);
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onSubmitShowingText = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.put('general/change_showing_text', data).then((response) => {
        dispatch(getSettings('showingTextForm'));
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch((error) => {
        console.log("ERROR: ", error);
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onSubmitReferText = (data = {}, files=[], resetImages=null) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.putAttachments('general/change_refer_text', data, files).then((response) => {
        dispatch(getSettings('referTextForm'));
        if (resetImages) {
            resetImages()
        }
        NotificationManager.success('Data registered successfully', 'SUCCESS', 1000);
    }).catch(() => {
        NotificationManager.error('Error registering data', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    getSettings,
    onSubmit,
    updateLeads,
    getSettingsText,
    onSubmitSettingText,
    onSubmitOpenHouseText,
    onSubmitCounterOffer,
    getGiftCardSetting,
    updateGiftCardSetting,
    onSubmitVendorText,
    onSubmitShowingText,
    onSubmitReferText,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
};

export default handleActions(reducers, initialState);
