import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadMask from "../LoadMask/LoadMask";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            keyField='id',
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            sizePerPage=10,
            hidePagination,
            ...other
        } = this.props;


        const paginar = !(pagination === false);

        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <BootstrapTable
                        headerWrapperClasses="showit-table-header"
                        bordered={false}
                        keyField={keyField}
                        tableHeaderClass='customs-headers'
                        headerContainerClass='customs-headers'
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        headerClasses={"customs-header2"}
                        onTableChange={(type, newState) => {
                            if (type === "filter") {
                                // Not implemented
                            } else if (type === "pagination") {
                                onPageChange(newState.page, newState)
                            } else if (type === "sort") {
                                onSortChange(newState.sortField, newState.sortOrder, newState)
                            } else if (type === "cellEdit") {
                                onPageChange(newState.page, newState)
                            }
                        }}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : (data.results? data.results: []) }
                        remote={remote}
                        pagination={hidePagination? null :
                            paginationFactory({
                                page,
                                sizePerPage,
                                hideSizePerPage: true,
                                totalSize: data.count? data.count : 0 || 0,
                            })
                        }
                        fetchInfo={{ dataTotalSize: data.count? data.count : 0 }}
                        {...other}
                    />
                </LoadMask>
            </div>
        )
    }
}
