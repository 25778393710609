import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderFileImageCrop } from "../../../Utils/renderField/renderField";
import {
    renderField,
    MultiSelectField,
} from "../../../Utils/renderField";

const validate = (values, props) => {
    const errors = {};

    if (!values.title) {
        errors.title = "This field is required";
    }

    if (!values.image) {
        errors.image = "This field is required";
    }

    if (!values.image_thumbnail) {
        errors.image_thumbnail = "This field is required";
    }

    return errors;
};

const GifCardDesignForm = (props) => {
    //  State
    const {
        isView,
        isUpdate,
        loader,
        designPhoto,
        designPhotoThumbnail,
        image_error = null,
    } = props;

    //  Bind
    const { handleSubmit, onFileChange } = props;
    console.log("PHOTO: ", designPhoto)
    console.log("PHOTO THUMB: ", designPhotoThumbnail)
    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="mt-3 col-lg-8 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="title">Title</label>
                                <Field
                                    name="title"
                                    label="Title"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    disabled={isView}
                                />
                            </div>
                        </div>
                        <div className="content-drop-zone col-lg-4 col-sm-12">
                            <label htmlFor="image_thumbnail">Thumbnail</label>
                            <Field
                                name="image_thumbnail"
                                id="image_thumbnail"
                                photo={designPhotoThumbnail}
                                setFile={(file) => onFileChange(file, "design_photo_thumbnail")}
                                spectValue={16 / 9}
                                disabled={isView}
                                component={renderFileImageCrop}
                            />
                        </div>
                        <div className="mt-3 col-lg-8 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="image">Image</label>
                                <Field
                                    name="image"
                                    id="image"
                                    photo={designPhoto}
                                    setFile={(file) => onFileChange(file, "design_photo")}
                                    spectValue={16 / 9}
                                    disabled={isView}
                                    component={renderFileImageCrop}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row mx-auto mt-3 col-lg-5 col-sm-12 justify-content-between mt-4 mb-5"
            >
                <a
                    className="btn btn-danger text-white col-lg-5 col-sm-12"
                    href="/#/gift-card/designs"
                >
                    {" "}
                    Cancel
                </a>
                {!isView && (
                    <button
                        type="submit"
                        className="btn btn-success col-lg-5 col-sm-12"
                        disabled={loader}
                    >
                        {isUpdate ? "Update" : "Create"}
                    </button>
                )}
            </div>
        </form>
    );
};

let GifCardDesignReduxForm = reduxForm({
    form: "gifCardDesignFrom", // a unique identifier for this form
    validate,
    initialValues: {},
})(GifCardDesignForm);

const selector = formValueSelector("gifCardDesignFrom");
GifCardDesignReduxForm = connect((state) => {
    return {
        designPhoto: selector(state, 'image'),
        designPhotoThumbnail: selector(state, 'image_thumbnail'),
    };
})(GifCardDesignReduxForm);

export default GifCardDesignReduxForm;
