import React, { Component } from 'react'
import GiftCardTextForm from './GiftCardTextForm'

class GiftCardText extends Component {
    state = {
        image: null,
    }

    componentDidMount() {
        this.props.getGiftCardSetting('giftCardTextForm')
    }

    setImage = (image) => {
        this.setState({ image });
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        //  State
        const { loader } = this.props;
        return (
            <div id="PAGE-CONTAINER">
                <div className="page-header pl-1 pt-3 no-gutters row">
                    <div className="">
                        <div className="header-page">Gift Card Text</div>
                    </div>
                </div>
                <GiftCardTextForm
                    loader={loader}
                    onSubmit={(data) => {
                        console.log("DATA: ", data);
                        const images = [];
                        if (this.state.image) {
                            images.push({ name: "image", file: this.state.image });
                        }
                        this.props.updateGiftCardSetting(data, images);
                    }}
                    goBack={this.goBack}
                    setImage={this.setImage}
                />
            </div>
        )
    }
}

export default GiftCardText