import moment from 'moment';
import { Link } from "react-router-dom";
import GridTable from '../../Utils/GridTable';
import { Modal } from "react-responsive-modal";
import React, { useEffect, useState } from 'react';
import { CUSTOM_PAGE_SIZE_IN_BACKEND } from '../../../../utility/constants';

export const ModalStripe = (props) => {
    const { open, url, modalOnConfiguring } = props;
    let text1 = "Before proceeding you must complete the Stripe registration.";
    let text2 = "Please click on the button to view the registration.";

    const isInfluencer = () => {
        const userType = localStorage.getItem('user_type')

        return userType == 20
    }

    if (modalOnConfiguring) {
        text1 = "Before continuing, you must complete the settings to enable payouts and charges.";
        text2 = "Please click on the button to configure.";
    }

    return (
        <Modal
            center
            closeOnEsc
            open={open}
            showCloseIcon={false}
            closeOnOverlayClick={false}
            classNames={{ modal: 'col-lg-6 col-sm-10 px-0 py-5' }}
        // initialFocusRef={document.getElementById('app-container')}
        >
            <div className="w-100 row py-2 px-0 mx-auto">
                <div className="row justify-content-center w-100 px-0 mx-auto">
                    <div className='swal2-icon swal2-warning swal2-animate-warning-icon d-flex' />
                </div>
                <div className="w-100 text-center py-1">
                    <p className="mb-0">
                        {text1}
                    </p>
                    <p className="mb-0">
                        {text2}
                    </p>
                </div>
                <div className="row w-100 justify-content-center mx-auto mt-3">
                    <a className='btn btn-primary px-4 text-white' href={url} target="_blank">
                        Register on stripe
                    </a>
                    <a className="btn btn-danger px-5 ml-lg-2 text-white" href={"#/"}>
                        Go Home
                    </a>
                </div>


            </div>
        </Modal>
    );
};


export const GiftCardStripeChargeHistoryGrid = (props) => {
    //  State
    const { data, page, loader } = props;
    //  Bind
    const { toList } = props;
    const [gridHeight, setGridHeight] = useState(200);

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("GIFT-CARD-STRIPE-CHARGES-LIST");
            if (elementId) {
                _height = elementId.clientHeight;
            }
            setGridHeight(_height);
        }, 300);
    }, []);

    return (
        <div id="GIFT-CARD-STRIPE-CHARGES-LIST" className="container-list card border-dark border-card-page px-4 py-2 flex-overflow-hidden-1">
            <div className="card-body px-0 pt-2 mb-0 pb-0 d-flex flex-column">
                <h5 className='font-weight-bold'>History</h5>
                <GridTable
                    hover
                    height={gridHeight - 50}
                    wrapperClasses="table-scroll-auto"
                    keyField={'id'}
                    loading={loader}
                    page={page}
                    data={{ results: data.results }}
                    // data={{ results: [] }}
                    sizePerPage={CUSTOM_PAGE_SIZE_IN_BACKEND}
                    trClassName={'text-success'}
                    onPageChange={value => toList(value)}
                    columns={[
                        {
                            dataField: "created",
                            formatter: dateCreated(),
                            text: "Date",
                        },
                        {
                            dataField: "job__id",
                            formatter: (cell, row) => {
                                const job_id = row.job ? row.job.id : null;
                                if (job_id) {
                                    return (
                                        <Link
                                            onClick={(e) => e.stopPropagation()}
                                            to={`/stats/job-detail-raw-data/?job_id=${job_id}`}
                                            className="w-100 text-primary"
                                            >
                                            {job_id}
                                        </Link>
                                    );
                                }
                                return null
                            },
                            text: "Job #",
                        },
                        {
                            dataField: "job__hired",
                            formatter: (cell, row) => {
                                const agent_id = row.job && row.job.hired && row.job.hired.agent ? row.job.hired.agent.id : null;
                                if (agent_id) {
                                    return (
                                        <Link
                                            onClick={(e) => e.stopPropagation()}
                                            to={`/agents/agent/${agent_id}/edit`}
                                            className="w-100 text-primary"
                                            >
                                            {`${row.job.hired.agent.first_name} ${row.job.hired.agent.last_name}`}
                                        </Link>
                                    );
                                }
                                return null
                            },
                            text: "Showing Agent",
                        },
                        {
                            dataField: "type",
                            formatter: (cell, row) => {
                                return (
                                    <p className="font-weight-bold my-0">
                                        {cell}
                                    </p>
                                );
                            },
                            text: "Type",
                        },
                        {
                            dataField: "amount",
                            formatter: amountFormatter(),
                            text: "Amount",
                            align: "right",
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export function dateCreated() {
    return (cell, row) => {
        return (
            <p className="my-0">
                {moment(cell).format('lll')}
            </p>
        );
    };
}

export function amountFormatter() {
    return (cell, row) => {
        return (
            <p className="my-0">
                ${cell ? parseFloat(cell).toFixed(2) : "0.00"}
            </p>
        );
    };
}
