import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/notifications";
import UpdateNotification from './UpdateNotification';


const ms2p = (state) => {
    return {
        ...state.appNotifications,
    };
};

const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(UpdateNotification);