import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { formValueSelector } from "redux-form";
import {formatPhoneNumber} from "../../Brokerage/BrokerageGrid";
import FileUploader from "../../Utils/FileUploader/FileUploader";
import GridTableWithScroll from "../../Utils/GridTableWithScroll";
import {formatName, formatText, brokerName} from "../../Agent/AgentUnregistered/AgentUnregisteredGrid";
import {
    renderField,
    renderSwitch,
    MultiSelectField
} from "../../Utils/renderField";
import {
    DEFAULT_STATUS_CAMPAIGN_UNREGISTERED,
    STATE_USA_LIST,
    AGENT_UNREGISTERED_PAGE_SIZE,
    SELECT_ALL_OPTION,
} from "../../../../utility/constants";

const validate = (values, props) => {
    const errors = {}

    if (!values.title) {
        errors.title = "This field is required";
    }

    if (!values.text_message) {
        errors.text_message = "This field is required";
    }

    if (values.link) {
        const pattern = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/;
        if (!values.link.match(pattern)) {
            errors.link = "Invalid URL";
        }
    }

    // if (!props.image) {
    //     props.setImageRequired();
    // }

    return errors
}

const PromotionForm = (props) => {
    //  State
    const {
        is_view,
        is_update,
        brokers,
        brokerages,
        teams,
        page,
        loader,
        data,
        image,
        image_error=null,
        agents_error,
        anyTouched,
        item
    } = props;
    const itemImg = ((is_update || is_view) && item && item.image) ? item.image : null;


    console.log("IMAGE IT: ", itemImg);
    console.log("IMAGE: ", is_update);

    //  Bind
    const {handleSubmit, changeFilters, onFileChange, getDataAgents} = props;

    const [tab, setTab] = useState('Registered')

    return (
        <form
            className="w-100 mx-auto justify-content-center mt-5"
            onSubmit={handleSubmit}
        >
            <div className="row w-75 px-lg-5 mx-auto pt-2">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="mt-3 col-lg-8 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="title">Title</label>
                                <Field
                                    name="title"
                                    label="Title"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="content-drop-zone col-lg-4 col-sm-12">
                            <label htmlFor="campaign_photo">Image</label>
                            <FileUploader
                                onFileChange={onFileChange}
                                fieldName={"campaign_photo"}
                                width="100%"
                                dashed={true}
                                img={image ? null : itemImg}
                                disabled={is_view}
                            />
                            {image_error && anyTouched && (
                                <div className="invalid-feedback d-block">
                                    {image_error}
                                </div>
                            )}
                        </div>
                        <div className="mt-3 col-lg-8 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="link">Link</label>
                                <Field
                                    name="link"
                                    label="Link"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="state">State</label>
                                <Field
                                    name="state"
                                    options={STATE_USA_LIST}
                                    set_value={(option) =>
                                        changeFilters(
                                            "state",
                                            option ? option.value : ""
                                        )
                                    }
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="broker_id">Broker</label>
                                <Field
                                    name="broker_id"
                                    options={brokers ? brokers : []}
                                    set_value={(option) => {
                                        console.log("OPTION: ", option);
                                        changeFilters(
                                            "broker",
                                            option ? option.value : ""
                                        );
                                    }}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="brokerage_id">Office</label>
                                <Field
                                    name="brokerage_id"
                                    options={brokerages ? brokerages : []}
                                    set_value={() => changeFilters("brokerage")}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="team_id">Team</label>
                                <Field
                                    name="team_id"
                                    options={teams ? teams : []}
                                    set_value={() => changeFilters("team")}
                                    component={MultiSelectField}
                                    className="form-control"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                        <div className="mt-3 col-lg-6 col-sm-12">
                            <div className="form-group has-feedback w-100">
                                <label htmlFor="currently_active" className="mt-4 mb-3">Active ?</label>
                                <Field
                                    name="currently_active"
                                    label=""
                                    component={renderSwitch}
                                    type="checked"
                                    disabled={is_view}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`row mx-auto mt-3 col-lg-5 col-sm-12 justify-content-between mt-4 mb-5`}
            >
                <a
                    className={`btn btn-danger text-white col-lg-5 col-sm-12`}
                    href="/#/promotions"
                >
                    {" "}
                    Cancel
                </a>
                {!is_view && (
                    <button
                        className={`btn btn-success col-lg-5 col-sm-12`}
                        disabled={loader}
                    >
                        {is_update ? "Update" : "Create"}
                    </button>
                )}
            </div>
            {agents_error && anyTouched && (
                <div className="row mt-3 w-75 px-lg-5 mx-auto">
                    <div className="col-sm-12">
                        <div className="invalid-feedback d-block font-weight-bold">
                            {agents_error}
                        </div>
                    </div>
                </div>
            )}
            <div className="row w-75 px-lg-5 mx-auto mt-5">
                <div className="col-12 px-0">
                    <GridTableWithScroll
                        hover
                        page={page}
                        keyField={"id"}
                        data={data}
                        loading={loader}
                        sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                        wrapperClasses="table-scroll-5"
                        onPageChange={(value) => getDataAgents(value, 5)}
                        columns={[
                            {
                                dataField: "first_name",
                                formatter: formatName(),
                                text: "First Name",
                            },
                            {
                                dataField: "last_name",
                                formatter: formatName(),
                                text: "Last Name",
                            },
                            {
                                dataField: "email",
                                short: true,
                                text: "E-mail",
                            },
                            {
                                dataField: "phone",
                                formatter: formatPhoneNumber(),
                                text: "phone",
                            },
                            {
                                dataField: "license",
                                formatter: formatText(),
                                text: "License",
                            },
                            {
                                dataField: "broker",
                                formatter: brokerName(),
                                text: "Broker",
                            },
                            {
                                dataField: "account_status",
                                text: "Status",
                            },
                        ]}
                    />
                </div>
            </div>
            {/* {(agent_statusValue == SELECT_ALL_OPTION["value"]) && (
                <div className="row w-75 px-lg-5 mx-auto mt-5">
                    <div className="col-12 px-0">
                        <Tabs
                            activeKey={tab}
                            defaultActiveKey="Registered"
                            tabBarPosition="top"
                            onChange={(key) => setTab(key)}
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Registered" key="Registered" className="py-0">
                                <div className="px-3 pt-2 pb-3">
                                    <GridTableWithScroll
                                        hover
                                        page={page}
                                        keyField={"id"}
                                        data={data}
                                        loading={loader}
                                        sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                        wrapperClasses="table-scroll-5"
                                        onPageChange={(value) => getDataAgents(value, 5)}
                                        columns={[
                                            {
                                                dataField: "first_name",
                                                formatter: formatName(),
                                                text: "First Name",
                                            },
                                            {
                                                dataField: "last_name",
                                                formatter: formatName(),
                                                text: "Last Name",
                                            },
                                            {
                                                dataField: "email",
                                                short: true,
                                                text: "E-mail",
                                            },
                                            {
                                                dataField: "phone",
                                                formatter: formatPhoneNumber(),
                                                text: "phone",
                                            },
                                            {
                                                dataField: "license",
                                                formatter: formatText(),
                                                text: "License",
                                            },
                                            {
                                                dataField: "broker",
                                                formatter: brokerName(),
                                                text: "Broker",
                                            },
                                            {
                                                dataField: "account_status",
                                                text: "Status",
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Unregistered" key="Unregistered">
                                <div className="px-3 pt-2 pb-3">
                                    <GridTableWithScroll
                                        hover
                                        page={unregisteredAgentsPage}
                                        keyField={"id"}
                                        data={unregisteredAgentsData}
                                        loading={loaderAgentsData}
                                        sizePerPage={AGENT_UNREGISTERED_PAGE_SIZE}
                                        wrapperClasses="table-scroll-5"
                                        onPageChange={(value) => getDataAgents(value, 1)}
                                        columns={[
                                            {
                                                dataField: "first_name",
                                                formatter: formatName(),
                                                text: "First Name",
                                            },
                                            {
                                                dataField: "last_name",
                                                formatter: formatName(),
                                                text: "Last Name",
                                            },
                                            {
                                                dataField: "email",
                                                short: true,
                                                text: "E-mail",
                                            },
                                            {
                                                dataField: "phone",
                                                formatter: formatPhoneNumber(),
                                                text: "phone",
                                            },
                                            {
                                                dataField: "license",
                                                formatter: formatText(),
                                                text: "License",
                                            },
                                            {
                                                dataField: "broker",
                                                formatter: brokerName(),
                                                text: "Broker",
                                            },
                                            {
                                                dataField: "account_status",
                                                text: "Status",
                                            },
                                        ]}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            )} */}
        </form>
    );
};

let PromotionReduxForm = reduxForm({
    form: "promotionFrom", // a unique identifier for this form
    validate,
    initialValues: {state: SELECT_ALL_OPTION["value"]}
})(PromotionForm);

const selector = formValueSelector("promotionFrom");
PromotionReduxForm = connect((state) => {
    return {
    };
})(PromotionReduxForm);

export default PromotionReduxForm;
