import React from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { SELECT_ALL_OPTION } from '../../../../../utility/constants'
import { renderSearchField, MultiSelectField } from "../../../Utils/renderField";

let TrackerFieldFilterForm = (props) => {
    //  Bind
    const {searchAction} = props;
    const options = [
        {label: "Owner", value: 100},
        {label: "Severity", value: 200},
        {label: "Type", value: 300},
        {label: "Status", value: 400},
        {label: "Agreement Signed", value: 500}
    ]

    return (
        <div className="row mt-2 w-100 mb-3 align-items-center">
            <div
                className={`col-xl-2 col-lg-4 col-sm-12 col-md-12 mt-2 d-flex flex-row align-items-end`}
            >
                <div className="form-group has-feedback w-100">
                    <Field
                        name="search"
                        label="Search"
                        placeholder={'Search'}
                        component={renderSearchField}
                        onChange={(e) => {
                            searchAction(
                                e.target && e.target.value ? e.target.value : ""
                            );
                        }}
                        type="text"
                    />
                </div>

            </div>
            <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2`}>
                <div className="form-group has-feedback w-100">
                    <label htmlFor="type">Type</label>
                    <Field
                        name="type"
                        options={options}
                        set_value={(value) => {
                            searchAction(
                                value
                            )
                        }}
                        component={MultiSelectField}
                        className="form-control"
                    />
                </div>
            </div>
            <div className={`d-flex col-xl-10 col-lg-6 col-md-8 col-sm-12 justify-content-end`}>
                <Link
                    to={"tracker-field/create"}
                    style={{height: 'max-content'}}
                    className="btn btn-primary text-white"
                >
                    Create
                </Link>
            </div>
        </div> 
    );
};

TrackerFieldFilterForm = reduxForm({
    form: 'TrackerFieldFilterForm', // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {type: SELECT_ALL_OPTION["value"]}
})(TrackerFieldFilterForm);

const selector = formValueSelector('TrackerFieldFilterForm');

const mstp = state => {

    return {
    }
}

export default connect(mstp, null)(TrackerFieldFilterForm);