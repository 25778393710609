import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';

const SponcerGrid = (props) => {
    //  State
    const {loader, data, height=500} = props

    const getTotal = (key) => {
        let totals = {
            total_agents: 0,
            total_agents_registered: 0,
            current_month_jobs: 0,
            current_month_fee: 0,
            current_year_jobs: 0,
            current_year_fee: 0,
        };
        if (data && data.totals) {
            totals = {...totals, ...data.totals}
        }
        return totals[key]
    }

    const getPenetration = () => {
        let result = 0;
        let totals = {
            total_agents: 0,
            total_agents_registered: 0,
            current_month_jobs: 0,
            current_month_fee: 0,
            current_year_jobs: 0,
            current_year_fee: 0,
        };
        if (data && data.totals) {
            totals = {...totals, ...data.totals}
        }

        if (totals.total_agents && totals.total_agents_registered) {
            result = parseFloat((totals.total_agents_registered * 100) / totals.total_agents)
        }

        return result
    }

    return (
        <div className="d-flex flex-column flex-1" style={{maxHeight: `${height}px`}}>
            <BootstrapTable
                wrapperClasses={'table-scroll-auto simple-table-vertical-border'}
                // classes="table-scroll-1"
                keyField={'id'}
                bordered={false}
                striped={true}
                hover={true}
                noDataIndication={'No data'}
                trStyle={{cursor:'pointer'}}
                data={loader ? [] : (data.results ? data.results : [])}
                remote={false}
                pagination={null}
                fetchInfo={{dataTotalSize: data.count ? data.count : 0}}
                loading={loader}
                footerClasses="table-footer"
                columns={[
                    {
                        dataField: "name",
                        text: "Name",
                        footer: 'Total',
                    },
                    {
                        dataField: "type",
                        text: "Influencer/Broker",
                        footer: '',
                    },
                    {
                        dataField: "broker_team_name",
                        text: "Broker/Team Name",
                        footer: '',
                    },
                    {
                        dataField: "no_agent",
                        text: "# of agents",
                        align: 'center',
                        footer: '',
                        footerAlign: 'center',
                        footer: `${getTotal('total_agents')}`,
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "100px",
                            };
                        },
                    },
                    {
                        dataField: "registered_agents",
                        text: "Registered Agents",
                        align: 'center',
                        footerAlign: 'center',
                        footer: `${getTotal('total_agents_registered')}`,
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "100px",
                            };
                        },
                    },
                    {
                        dataField: "penetration",
                        text: "Penetration",
                        align: 'center',
                        formatter: (cell) => {
                            return <span>{cell} %</span>
                        },
                        footerAlign: 'center',
                        footer: `${getPenetration().toFixed(2)} %`,
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "100px",
                            };
                        },
                    },
                    {
                        dataField: "current_month.jobs",
                        text: "Current month Completed jobs",
                        align: 'center',
                        footerAlign: 'center',
                        footer: `${getTotal('current_month_jobs')}`,
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "135px",
                            };
                        },
                    },
                    {
                        dataField: "current_month.fees",
                        text: "Current month Profit",
                        align: 'center',
                        footerAlign: 'center',
                        footer: `$ ${getTotal('current_month_fee')}`,
                        formatter: formatCurrency(),
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "125px",
                            };
                        },
                    },
                    {
                        dataField: "current_year.jobs",
                        text: "Current year jobs Completed",
                        align: 'center',
                        footerAlign: 'center',
                        footer: `${getTotal('current_year_jobs')}`,
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "125px",
                            };
                        },
                    },
                    {
                        dataField: "current_year.fees",
                        text: "Current year Total Profit",
                        formatter: formatCurrency(),
                        align: 'center',
                        footerAlign: 'center',
                        footer: `$ ${getTotal('current_year_fee')}`,
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "115px",
                            };
                        },
                    },
                    {
                        dataField: "profit_share",
                        text: "Profit Share",
                        align: 'center',
                        footer: '',
                        formatter: (cell) => {
                            return <span>{cell} %</span>
                        },
                        headerStyle: (colum, colIndex) => {
                            return {
                                maxWidth: "100px",
                            };
                        },
                    },
                ]}
            />
        </div>
    );
}

export function formatCurrency() {
    return (cell) => {
        return (
            <span className="p-0 m-0 text-center" style={{width: '4.5rem'}}>
                $ {cell}
            </span>
        );
    };
}

export function footerNumberFormatter(totals) {
    return (row, rowIndex) => {
        return <div>
            <span>{totals.total_agents}</span>
        </div>
    }
}

export default SponcerGrid;