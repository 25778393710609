import React, { Component } from 'react';


class Demo extends Component {
    render() {
        return (
            <div className="row position-relative">
                <div className="position-absolute w-100">
                </div>
            </div>
        );
    }
}

export default Demo;
