import { connect } from 'react-redux';
import { listPendingNotes, createPendingNotes, readPendingNotes, editPendingNotes, deletePendingNotes, setNotesItem } from '../../../../redux/modules/agent/agent';
import PendingNotes from './PendingNotes';

const ms2p = (state) => {
    const { notesLoader, notesPage, notesData, notesItem, notesItemLoader } = state.agent;
    return{
        loader: notesLoader,
        itemLoader: notesItemLoader,
        page: notesPage,
        data: notesData,
        item: notesItem,
    };
};

const md2p = {
    list: listPendingNotes,
    create: createPendingNotes,
    read: readPendingNotes,
    edit: editPendingNotes,
    remove: deletePendingNotes,
    setItem: setNotesItem,
};

export default connect(ms2p, md2p)(PendingNotes);