import {api} from "api";
import Swal from "sweetalert2";
import {push} from "react-router-redux";
import {handleActions} from 'redux-actions';
import {NotificationManager} from "react-notifications";
import {createReducer} from "../baseReducer/baseReducer";

 // ------------------------------------
// Constants
// ------------------------------------
const endpoint = "job_type_vendor"

//-------------------------------------
// Base reducer
//-------------------------------------
export const {reducers, initialState, actions}  = createReducer(
    "jobTypeVendor",
    endpoint,
    "vendorTypeForm",
    "/job-type-vendor",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
actions.searchChange = search => (dispatch) => {
    setTimeout(() => {
        dispatch(actions.toList());
    }, 400);
};

actions.create = (data, files) => (dispatch) => {
    actions.setLoader(true)
    api.postAttachments(endpoint, data, files).then(() => {
        NotificationManager.success('Record created', 'Success', 3000);
        dispatch(push("/job-type-vendor"));
    }).catch(() => {
        NotificationManager.error('Creation failed', 'ERROR');
    }).finally(() => {
        actions.setLoader(false)
    });
};

actions.edit = (id, data, files) => (dispatch) => {
    actions.setLoader(true)
    let finalFile = []
    if (files && files.length) {
        files.map(itemFile => {
            if (itemFile !== undefined) {
                finalFile.push(itemFile)
            }
        })
    }
    api.putAttachments(`${endpoint}/${id}`, data, finalFile).then(() => {
        NotificationManager.success('Record updated', 'Success', 3000);
        dispatch(push("/job-type-vendor"));
    }).catch(() => {
        NotificationManager.error('Edition failed', 'ERROR');
    }).finally(() => {
        actions.setLoader(false)
    });
};

// ------------------------------------
// Reducers
// ------------------------------------

// ------------------------------------
// InitialState
// ------------------------------------

export default handleActions(reducers, initialState);