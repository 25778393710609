import React, { useRef, useState, useEffect } from 'react'
import { GOOGLE_MAPS_KEY } from '../../../../utility/constants'
import { MAPS_THEME } from '../../../../utility/mapsTheme';
import GoogleMap from 'google-map-react';

const Map = ({
    children,
    map
}) => {

    return (
        <React.Fragment>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}
        </ React.Fragment>
    );
};

const Marker = (options) => {
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            setTimeout(() => {
                setMarker(new google.maps.Marker());
            }, 800);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions({
                ...options,
                icon: {
                    url: options.iconData.icon,
                    scaledSize: new google.maps.Size(18, 18),
                },
                optimized: true
            });
        }
    }, [marker, options]);

    return null;
};

const Wrapper = (props) => {
    const {children} = props;
    return (
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "18px",
            height: "18px",
            backgroundColor: "#000",
            border: "2px solid #fff",
            borderRadius: "100%",
            userSelect: "none",
            // transform: "translate(-50%, -50%)",
        }} />
    )
}

// const Marker = (props) => {
//     const {iconData} = props;
//     return <Wrapper {...props}/>
// }

const Polyline = (options) => {
    const [polyline, setPolyline] = useState();

    useEffect(() => {
        if (!polyline) {
            setTimeout(() => {
                setPolyline(new google.maps.Polyline());
            }, 800);
        }

        // remove Polyline from map on unmount
        return () => {
            if (polyline) {
                polyline.setMap(null);
            }
        };
    }, [polyline]);

    useEffect(() => {
        if (polyline) {
            polyline.setOptions(options);
        }
    }, [polyline, options]);

    return null;
};

const TrackingMap = (props) => {
    //  State
    const {
        route=[],
        addreses=[],
        iconData=null,
    } = props;

    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(5); // initial zoom
    const [initLatitud, setLatitud] = useState(38.908736);
    const [initLongitude, setLongitude] = useState(-77.038475);

    const getFirstLatitude = () => {
        if (addreses && addreses.length) {
            const lessLatitude = getLessLatitude();
            const delta = getDeltaLatitude() / 2;
            return lessLatitude + delta;
        }
        return initLatitud;
    };

    const getFirstLongitude = () => {
        if (addreses && addreses.length) {
            const lessLongitude = getLessLongitude();
            const delta = getDeltaLongitude() / 2;
            return lessLongitude + delta;
        }
        return initLongitude;
    };

    const getLessLatitude = () => {
        let lessLatitude = initLatitud;
        if (addreses && addreses.length) {
            lessLatitude = addreses[0].latitude;

            // find the minimum longitude in the addreses
            addreses.forEach((item) => {
                if (item.latitude < lessLatitude) {
                    lessLatitude = item.latitude;
                }
            });
        }
        if (route && route.length) {
            if (lessLatitude == initLatitud ) {
                lessLatitude = route[0].latitude
            }
            // find the minimum longitude in the addreses
            route.forEach((item) => {
                if (item.latitude < lessLatitude) {
                    lessLatitude = item.latitude;
                }
            });
        }
        return lessLatitude;
    };

    const getHigherLatitude = () => {
        let higherLatitude = initLatitud;
        if (addreses && addreses.length) {
            higherLatitude = addreses[0].latitude;

            // find the higher longitude between addreses
            addreses.forEach((item) => {
                if (item.latitude > higherLatitude) {
                    higherLatitude = item.latitude;
                }
            });
        }
        if (route && route.length) {
            if (higherLatitude == initLatitud ) {
                higherLatitude = route[0].latitude
            }
            // find the minimum longitude in the addreses
            route.forEach((item) => {
                if (item.latitude > higherLatitude) {
                    higherLatitude = item.latitude;
                }
            });
        }
        return higherLatitude;
    };

    const getLessLongitude = () => {
        let lessLongitude = initLongitude;
        if (addreses && addreses.length) {
            lessLongitude = addreses[0].longitude;

            // find the minimum lingitude between addreses
            addreses.forEach((item) => {
                if (item.longitude < lessLongitude) {
                    lessLongitude = item.longitude;
                }
            });
        }
        if (route && route.length) {
            if (lessLongitude == initLongitude ) {
                lessLongitude = route[0].longitude
            }
            // find the minimum longitude in the addreses
            route.forEach((item) => {
                if (item.longitude < lessLongitude) {
                    lessLongitude = item.longitude;
                }
            });
        }
        return lessLongitude;
    };

    const getHigherLongitude = () => {
        let higherLongitude = initLongitude;
        if (addreses && addreses.length) {
            higherLongitude = addreses[0].longitude;

            // get the higher longitude between addreses
            addreses.forEach((item) => {
                if (item.longitude > higherLongitude) {
                    higherLongitude = item.longitude;
                }
            });
        }
        if (route && route.length) {
            if (higherLongitude == initLongitude ) {
                higherLongitude = route[0].longitude
            }
            // find the minimum longitude in the addreses
            route.forEach((item) => {
                if (item.longitude > higherLongitude) {
                    higherLongitude = item.longitude;
                }
            });
        }
        return higherLongitude;
    };

    const getDeltaLatitude = () => {
        if (addreses && addreses.length) {
            let delta = getHigherLatitude() - getLessLatitude();
            if (delta < -1) {
                delta = delta * -1;
            }
            return delta;
        }
        return 0.0015;
    };

    const getGainDeltaLatitude = () => {
        const delta = getDeltaLatitude();
        return delta / 4;
    };

    const getDeltaLongitude = () => {
        const lessLongitude = getLessLongitude();
        const higherLongitude = getHigherLongitude();
        if (addreses && addreses.length) {
            let delta = higherLongitude - lessLongitude;
            if (delta < -1) {
                delta = delta * -1;
            }
            return delta;
        }
        return 0.0015;
    };

    const getGainDeltaLongitude = () => {
        const delta = getDeltaLongitude();
        return delta / 4;
    };

    const getRegion = () => {
        let result = {
            lat: getFirstLatitude(),
            lng: getFirstLongitude(),
            latitudeDelta: getDeltaLatitude() + getGainDeltaLatitude() * 2.3,
            longitudeDelta: getDeltaLongitude() + getGainDeltaLongitude() * 2,
        }
        console.log("RESULT REGION: ", result);
        return result
    }

    const apiIsLoaded = (map, maps) => {
        // Get bounds by our places
        // let places = [];
        let places = addreses.map((place) => {
            return {
                lat: place.latitude,
                lng: place.longitude,
            };
        })
        route.forEach((item) => {
            places.push({
                lat: item.latitude,
                lng: item.longitude,
            })
        })
        const bounds = getMapBounds(map, maps, places);
        // Fit map to bounds
        map.fitBounds(bounds);
        console.log("BOUNDS 2: ", bounds);
    };

    // Return map bounds based on list of places
    const getMapBounds = (map, maps, places) => {
        const bounds = new maps.LatLngBounds();

        places.forEach((place) => {
            bounds.extend(new maps.LatLng(
                place.lat,
                place.lng,
            ));
        });
        // const bounds = places.map((place) => {
        //     return [place.lat, place.lng]
        // })
        return bounds;
    };

    // // Re-center map when resizing the window
    // const bindResizeListener = (map, maps, bounds) => {
    //     maps.event.addDomListenerOnce(map, 'idle', () => {
    //         maps.event.addDomListener(window, 'resize', () => {
    //             console.log("BOUNDS: ", bounds);
    //             map.fitBounds(bounds);
    //         });
    //     });
    // };

    return (
        <div className='rounded' style={{ flexGrow: '1', height: '100%', overflow: "hidden"}}>
            <GoogleMap
                bootstrapURLKeys={{
                    key: GOOGLE_MAPS_KEY,
                    libraries: ['drawing', 'places']
                }}
                center={getRegion()}
                zoom={zoom}
                options={{
                    styles: MAPS_THEME,
                    draggable: true,
                    panControl: true,
                    rotateControl: false,
                    scaleControl: false,
                    zoomControl: false,
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                    streetViewControl: false,
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => {
                    console.log("MAP: ", map);
                    setMap(map)
                    apiIsLoaded(map, maps)
                }}
            >
                {map && <Map map={map}>
                    {addreses && addreses.map((item, index) => {
                        return (
                            <Marker
                                position={{lat: item.latitude, lng:item.longitude}}
                                iconData={iconData}
                                // lat={item.latitude}
                                // lng={item.longitude}
                            />
                        )
                    })}

                    {route && route.length > 1 && (
                        <Polyline
                            path={route.map((item) => {
                                return {
                                    lat: item.latitude,
                                    lng: item.longitude,
                                }
                            })}
                            geodesic={true}
                            strokeColor={"#000"}
                            strokeOpacity={1.0}
                            strokeWeight={2}
                            />
                    )}
                </Map>}
            </GoogleMap>
        </div>
    )
}

const AnyReactComponent = ({ text }) => <i className="material-icons text-danger">place</i>;

export default TrackingMap