import React, {useState, useEffect} from 'react';
import moment from "moment";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { SIZE_PER_PAGE } from "../../../../../utility/constants";
import GridTableWithScroll from "../../../Utils/GridTableWithScroll";

const loadImg = (cell) => {
    return (
        <div className="p-0 m-0 justify-content-center container-cell-icon mx-auto">
            <img
                src={cell || require('../../../../../../assets/img/uploadLogo.PNG')}
                alt=" "
                className="align-self-center"
                style={{ maxWidth: '95%', maxHeight: '90%' }}
            />
        </div>
    );
};

const phoneFormatted = (cell) => {
    let newCell = cell;

    if (newCell && newCell.includes('+')) {
        newCell = newCell.replace('+', '')
    }
    if (newCell && newCell.includes('-')) {
        newCell = newCell.replace('-', '')
    }
    if (newCell && newCell.length > 10) {
        newCell = newCell.substr(newCell.length - 10, 12)
    }
    if (newCell) {
        newCell = `${newCell.substr(0, 3)}-${newCell.substr(3, 3)}-${newCell.substr(6, 4)}`
    }
    return (
        <p className={'my-0 mx-0 px-0'}>
            {newCell}
        </p>
    );
};

export const BrokerRawDataGrid = (props) => {
    const { loader = false, data, nextPage, page } = props;
    const [gridHeight, setGridHeight] = useState(200)

    useEffect(() => {
        let _height = 200;
        setTimeout(() => {
            const elementId = document.getElementById("STATS-BROKER-RAW-DATA");
            if (elementId) {
                _height = elementId.clientHeight
            }
            setGridHeight(_height)
        }, 300);
    }, [])

    const tableOptions = {
        sizePerPage: SIZE_PER_PAGE,
        hideSizePerPage: true,
        alwaysShowAllBtns: true,
        noDataText: 'No data',
        page,
        onPageChange: nextPage,
    };
    return (
        <div id="STATS-BROKER-RAW-DATA" className="container-list card border-dark border-card-page px-4 py-2">
            <div className="card-body px-0 py-2 d-flex flex-column overflow-hidden">
                <LoadMask loading={loader} dark blur>
                    <div className="d-flex flex-column flex-overflow-hidden-1" style={gridHeight ? {maxHeight: `${gridHeight - 30}px`}: {}}>
                        <BootstrapTable
                            data={loader ? [] : (data.results ? data.results : [])}
                            striped
                            remote
                            pagination
                            hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={tableOptions}
                            containerClass="flex-1"
                            tableContainerClass="react-bs-table-bordered-95"
                        >
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="id"
                                isKey
                                width="5px"
                                dataFormat={(cell, row) => {
                                    return <div />;
                                }}
                            />
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="broker__franchise__name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{row.broker && row.broker.franchise ? row.broker.franchise.name : ''}</div>
                                    );
                                }}
                                width="150px"
                            >
                                Brokerage Franchise
                            </TableHeaderColumn>
                            <TableHeaderColumn rowSpan="2" dataField="firm_name" width="150px">
                                Brokerage Name
                            </TableHeaderColumn>
                            <TableHeaderColumn rowSpan="2" dataField="license" width="150px">
                                Brokerage License #
                            </TableHeaderColumn>
                            <TableHeaderColumn row="0" rowSpan="2" dataField="logo" dataFormat={loadImg} width="150px">
                                Office logo
                            </TableHeaderColumn>

                            {/* BROKERAGE OFFICE INFO */}
                            <TableHeaderColumn row="0" colSpan="4" headerAlign="center" width="150px">
                                Brokerage Office Info
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="broker__state"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{row.broker ? row.broker.state : ''}</div>
                                    );
                                }}
                                width="150px"
                            >
                                State
                            </TableHeaderColumn>
                            <TableHeaderColumn row="1" dataField="city" width="150px">
                                City
                            </TableHeaderColumn>
                            <TableHeaderColumn row="1" dataField="address" width="150px">
                                Address
                            </TableHeaderColumn>
                            <TableHeaderColumn row="1" dataField="telephone" width="115px" dataFormat={phoneFormatted}>
                                Phone #
                            </TableHeaderColumn>

                            {/* SIGN-UP BY INFORMATION */}
                            <TableHeaderColumn row="0" colSpan="5" headerAlign="center" width="150px">
                                Sign-up by information
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="created_by__full_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{row.created_by ? row.created_by.full_name : ''}</div>
                                    );
                                }}
                                width="150px"
                            >
                                Full Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="created"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p className="p-0 m-0" style={{ width: '5rem' }}>
                                                {cell && row.created_by && moment(cell).format('lll')}
                                            </p>
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Time & Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="created_by__email"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{row.created_by ? row.created_by.email : ''}</div>
                                    );
                                }}
                                width="150px"
                            >
                                Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="created_by__roles_name"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{row.created_by ? row.created_by.roles_name : ''}</div>
                                    );
                                }}
                                width="150px"
                            >
                                Role
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                row="1"
                                dataField="created_by__real_estate_licence"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{row.created_by ? row.created_by.real_estate_licence : ''}</div>
                                    );
                                }}
                                width="110px"
                            >
                                RE License
                            </TableHeaderColumn>
                            <TableHeaderColumn rowSpan="2" dataField="agents_in_office" width="100px" dataAlign="right">
                                # of Agents in Office
                            </TableHeaderColumn>
                            <TableHeaderColumn rowSpan="2" dataField="registered_agents" width="100px" dataAlign="right">
                                # of Registered Agents
                            </TableHeaderColumn>
                            <TableHeaderColumn rowSpan="2" dataField="operation_access" width="150px">
                                Operation Access
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="total_shared_to_date"
                                width="150px"
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            $
                                            {cell}
                                        </div>
                                    );
                                }}
                            >
                                ShowIT Share™ profit to date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="broker__confirmed_data__user"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>{row.broker ? row.broker.confirmed_data.user : ''}</div>
                                    );
                                }}
                                width="150px"
                            >
                                Sign-up Confirmed By
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                rowSpan="2"
                                dataField="broker__confirmed_data__date"
                                dataFormat={(cell, row) => {
                                    return (
                                        <div>
                                            <p className="p-0 m-0" style={{ width: '5rem' }}>
                                                {row && row.broker && moment(row.broker.confirmed_data.date).format('lll')}
                                            </p>
                                        </div>
                                    );
                                }}
                                width="150px"
                            >
                                Sign-up Confirmed time/Date
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </LoadMask>
            </div>
        </div>
    );
};
