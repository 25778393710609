import _ from "lodash";
import { api } from "api";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm, change } from "redux-form";
import {
    SELECT_ALL_OPTION,
    BROKERAGE_LOCKED,
} from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const SET_FRANCHISES = "SET_FRANCHISES_DELETEDBROKER";
const SET_BROKERAGES = "SET_BROKERAGES_DELETEDBROKER";

const baseReducer = createReducer(
    "deletedBroker", //unique identified on store.
    "deleted_broker", //endpoint for request.
    "deletedBrokersForm", //form name.
    "/deleted-brokers" //url of component in frontend
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsFranchises = (franchises) => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});

// ------------------------------------
// Actions
// ------------------------------------

export const getFranchisesOption = (getData = null, selectAll=false) => (dispatch) => {
    api.get("general/option_franchise", { all: true })
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                // let value = [];
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                //     value.push(franchise.id)
                // });
                if(selectAll) {
                    dispatch(
                        change(
                            "DeletedBrokerFiltersForm",
                            "franchise_id",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }
            } else {
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                // });
                const franchise_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "DeletedBrokerFiltersForm",
                        "franchise_id",
                        [franchise_id]
                    )
                );
                setTimeout(() => {
                    dispatch(getAllOffice())
                }, 500);
            }
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
            dispatch(change("DeletedBrokerFiltersForm", "franchise_id", ""));
            setTimeout(() => {
                dispatch(getAllOffice())
            }, 500);
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList())
                }, 600);
            }
        });
};

const getAllOffice = (getData=false, selectAll=false) => (dispatch, getStore) => {
    const store = getStore()
    const form = store.form.DeletedBrokerFiltersForm;
    let params = { broker_id: "", state: "", franchise: "", is_multi: true };
    if (form) {
        const { franchise_id, state } = form.values;
        // if (state != '' && state !== null && state !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
        //     params.state = state
        // }
        if (franchise_id != '' && franchise_id !== null && franchise_id !== undefined && franchise_id !== SELECT_ALL_OPTION["value"]) {
            // params.franchise = franchise_id
            if (franchise_id.length == 0) {
                params.franchise = ""
            }else {
                params.franchise = franchise_id;
            }
        }
    }

    api.get('general/option_brokerage', params)
        .then(response => {
            let option = [];
            if (response.results.length > 1) {
                option = response.results;
                if (selectAll) {
                    // let value = [];
                    // response.results.forEach((item) => {
                    //     value.push(item.value);
                    // });
                    dispatch(change("DeletedBrokerFiltersForm", "brokerage", SELECT_ALL_OPTION["value"]));
                }
            } else {
                option = [...response.results];
                const brokerage_id = option.length ? option[0].value : "";
                dispatch(
                    change(
                        "DeletedBrokerFiltersForm",
                        "brokerage",
                        [brokerage_id]
                    )
                );
            }
            dispatch(setBrokerages(option));
        })
        .catch((err) => {
            console.log("ERR: ", err);
            dispatch(setBrokerages([]));
            dispatch(change("DeletedBrokerFiltersForm", "brokerage", ""));
        })
        .finally(() => {
            if(getData) {
                setTimeout(() => {
                    dispatch(toList())
                }, 600);
            }
        });
};

// ------------------------------------
// Actions
// ------------------------------------
export const searchChange = (search) => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    dispatch(toList());
};

const changeFilters = (type, value=null) => (dispatch) => {
    console.log("TYPE:, ",  type);
    console.log("VALUE:, ",  value);
    switch (type) {
        case 'state':
            setTimeout(() => {
                dispatch(toList())
            }, 500);
            break;
        case 'franchise':
            setTimeout(() => {
                // dispatch(getAllOffice(true))
                dispatch(toList())
            }, 500);
            break;
        // case 'brokerage':
        //     setTimeout(() => {
        //         dispatch(toList())
        //     }, 500);
        //     break;
        case "search":
            dispatch(searchChange(value));
            break;
        default:
            break;
    }
}

const toList = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()['broker'];
    const {DeletedBrokerFiltersForm} = getStore().form;
    const params = {page};
    if (DeletedBrokerFiltersForm && DeletedBrokerFiltersForm.values) {
        const { state, franchise_id, brokerage} = DeletedBrokerFiltersForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]){
            // params.state__in = state
            if (state.length == 0) {
                params.state__in = "null"
            }else {
                params.state__in = state;
            }
        }
        if (franchise_id !== "" && franchise_id !== null && franchise_id !== undefined && franchise_id !== SELECT_ALL_OPTION["value"]){
            // params.franchise_id__in = franchise_id
            if (franchise_id.length == 0) {
                params.franchise__id__in = 0
            }else {
                params.franchise__id__in = franchise_id;
            }
        }
        // if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]){
        //     // params.brokerage__id__in = brokerage
        //     if (brokerage.length == 0) {
        //         params.brokerage__id__in = 0
        //     }else {
        //         params.brokerage__id__in = brokerage;
        //     }
        // }
    }
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(baseReducer.actions.setLoader(true));
    api.get('deleted_broker', params).then((response) => {
        dispatch(baseReducer.actions.setData(response));
        dispatch(baseReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const reinstateBroker = (id, data, goBack) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.put(`deleted_broker/${id}`, data)
        .then(() => {
            NotificationManager.success("Reinstated Broker", "Success", 3000);
            goBack()
        })
        .catch(() => {
            NotificationManager.error("Broker Reinstatement Failed", "ERROR", 3000);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

export const actions = {
    ...baseReducer.actions,
    getFranchisesOption,
    getAllOffice,
    changeFilters,
    toList,
    reinstateBroker,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...baseReducer.reducers,
    [SET_FRANCHISES]: (state, { franchises }) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERAGES]: (state, { brokerages }) => {
        return {
            ...state,
            brokerages,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...baseReducer.initialState,
    brokerages: [],
    franchises: [],
};

export default handleActions(reducers, initialState);
