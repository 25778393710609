import _ from "lodash";
import { api } from "api";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm, change } from "redux-form";
import {
    SELECT_ALL_OPTION,
    BROKERAGE_LOCKED,
} from "../../../utility/constants";

// ------------------------------------
// Constants
// ------------------------------------
const SET_FRANCHISES = "SET_FRANCHISES_DELETEDOFFICE";
const SET_BROKERS = "SET_BROKERS_DELETEDOFFICE";

const basicReducer = createReducer(
    "deletedOffice", //unique identified on store.
    "deleted_office", //endpoint for request.
    "deletedOfficesForm", //form name.
    "/deleted-offices" //url of component in frontend
);

// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsFranchises = (franchises) => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});


// ------------------------------------
// Actions
// ------------------------------------
export const searchChange = (search) => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    setTimeout(() => {
        dispatch(toList());
    }, 400)
};
export const changeFilters =
    (type, value = null) =>
    (dispatch, getStore) => {
        console.log("TYPE:, ", type);
        switch (type) {
            case "state":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "franchise":
                setTimeout(() => {
                    dispatch(getBrokerOption(true));
                }, 500);
                break;
            case "broker":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "registered_status":
                setTimeout(() => {
                    dispatch(toList());
                }, 500);
                break;
            case "unregistered_status":
                setTimeout(() => {}, 500);
                break;
            case "search":
                dispatch(searchChange(value));
                break;
            default:
                break;
        }
    };

export const getFranchisesOption =
    (getData = null, selectAll = false) =>
    (dispatch) => {
        api.get("general/option_franchise", { all: true })
            .then((response) => {
                let options = response.results;
                if (response.results.length > 1) {
                    // let value = [];
                    // response.results.map((franchise) => {
                    //     options.push({
                    //         label: franchise.name,
                    //         value: franchise.id,
                    //     });
                    //     value.push(franchise.id);
                    // });
                    if (selectAll) {
                        dispatch(
                            change(
                                "DeletedOfficeFiltersForm",
                                "franchise",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                } else {
                    // response.results.map((franchise) => {
                    //     options.push({
                    //         label: franchise.name,
                    //         value: franchise.id,
                    //     });
                    // });
                    const franchise_id = options.length ? options[0].value : "";
                    dispatch(
                        change("DeletedOfficeFiltersForm", "franchise", [franchise_id])
                    );
                    setTimeout(() => {
                        dispatch(getBrokerOption());
                    }, 500);
                }
                dispatch(setOptionsFranchises(options));
            })
            .catch(() => {
                dispatch(setOptionsFranchises([]));
                dispatch(change("DeletedOfficeFiltersForm", "franchise", ""));
                setTimeout(() => {
                    dispatch(getBrokerOption());
                }, 500);
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                    }, 500);
                }
            });
    };

export const getBrokerOption =
    (getData = null, selectAll = false, brokerId = null, brokerageId = null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { DeletedOfficeFiltersForm } = store.form;
        let params = { state: "", franchise: "", is_multi: true, all: true };
        if (DeletedOfficeFiltersForm && DeletedOfficeFiltersForm.values) {
            const { state, franchise } = DeletedOfficeFiltersForm.values;
            if (
                franchise !== "" &&
                franchise !== null &&
                franchise !== undefined &&
                franchise !== SELECT_ALL_OPTION["value"]
            ) {
                // params.franchise = franchise;
                if (franchise.length == 0) {
                    params.franchise = "";
                } else {
                    params.franchise = franchise;
                }
            }
            // if (state !== "" && state !== null && state !== undefined) {
            //     params.state = state;
            // }
        }

        api.get(`general/option_broker`, params)
            .then((response) => {
                console.log("RESP BRK: ".response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll && brokerId == null) {
                        dispatch(
                            change(
                                "DeletedOfficeFiltersForm",
                                "broker",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }
                    if (brokerId !== null) {
                        dispatch(change("DeletedOfficeFiltersForm", "broker", [brokerId]));
                    }
                } else {
                    option = [...response.results];
                    console.log("OPT: ", option);
                    const broker_id = option.length ? option[0].value : "";
                    dispatch(change("DeletedOfficeFiltersForm", "broker", [broker_id]));
                }
                dispatch(setBrokers(option));
            })
            .catch(() => {
                dispatch(setBrokers([]));
                dispatch(change("DeletedOfficeFiltersForm", "broker", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList());
                    }, 500);
                }
            });
    };

    const toList = (page = 1, id = null) => (dispatch, getStore) => {
        dispatch(basicReducer.actions.setLoader(true));
        const store = getStore()
        const resource = store.brokerage;
        const params = {page};
        params.ordering = resource.ordering;
        // params.search = resource.search;
        // if (resource.idBrokerSelected) {
        //     params.broker_id = resource.idBrokerSelected;
        // }
        const { DeletedOfficeFiltersForm } = store.form;
        if (DeletedOfficeFiltersForm && DeletedOfficeFiltersForm.values) {
            const { state, franchise, broker, status, search } = DeletedOfficeFiltersForm.values;
            if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
                if (state.length == 0) {
                    params.removed_from__broker__state__in = "null"
                }else {
                    params.removed_from__broker__state__in = state;
                }
            }
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                if (franchise.length == 0) {
                    params.removed_from__broker__franchise__id__in = 0
                }else {
                    params.removed_from__broker__franchise__id__in = franchise;
                }
            }
            if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                if (broker.length == 0) {
                    params.removed_from__broker__id__in = 0
                }else {
                    params.removed_from__broker__id__in = broker;
                }
            }
            if (status !== "" && status !== null && status !== undefined && status !== SELECT_ALL_OPTION["value"]) {
                if (status.length == 0) {
                    params.status__in = 0
                }else {
                    params.status__in = status;
                }
            }
            if (search != "" && search != undefined && search != null) {
                params.search = search
            }
        }
    
        api.get('deleted_office', params).then((response) => {
            dispatch(basicReducer.actions.setPage(page));
            dispatch(basicReducer.actions.setData(response));
        }).catch(() => {
        }).finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
    };

const getFormBrokerOption = () => (dispatch) => {
    api.get("general/option_broker")
        .then((response) => {
            let option = response.results;
            dispatch(setBrokers(option));
        })
        .catch(() => {
            dispatch(setBrokers([]));
        })
        .finally(() => {
        });
};

const reinstateOffice = (id, data, goBack) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`deleted_office/${id}`, data)
        .then(() => {
            NotificationManager.success("Reinstated Office", "Success", 3000);
            goBack()
        })
        .catch(() => {
            NotificationManager.error("Office Reinstatement Failed", "ERROR", 3000);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

export const actions = {
    ...basicReducer.actions,
    searchChange,
    changeFilters,
    getFranchisesOption,
    getBrokerOption,
    toList,
    getFormBrokerOption,
    reinstateOffice,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_FRANCHISES]: (state, { franchises }) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, { brokers }) => {
        return {
            ...state,
            brokers,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    ...basicReducer.initialState,
    brokers: [],
    brokerages: [],
    franchises: [],
};

export default handleActions(reducers, initialState);
