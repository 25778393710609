import React from 'react';
import {Line} from 'react-chartjs-2';
import LoadMask from "../../Utils/LoadMask/LoadMask";

const dataExample = {
    labels: ['Jan', 'Feb', 'March', 'April', 'Jun', 'Jul'],
    datasets: [
        {
            data: [],
            fill: false,
            tension: 0.4,
            label: 'Jobs',
            borderWidth: 1.5,
            borderColor: 'rgb(25,164,239)',
            backgroundColor: 'rgb(99,133,255)',
        },
    ],
};

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: 10,
                        lineHeight: 1.2,
                        family: 'Poppins',
                    },
                },
            },
        ],
        y: {
            display: false,
            suggestedMin: 0,
            ticks: {
                font: {
                    size: 10,
                    lineHeight: 1.2,
                    family: 'Poppins',
                    min: 0,
                    // forces step size to be 50 units
                    // stepSize: 50
                },
            },
        },
        x: {
            display: true,
            grid: {
                drawBorder: false,
                color: function (context) {
                    // if (context.tick.value > 0) {
                    //     return green;
                    // } else if (context.tick.value < 0) {
                    //     return red;
                    // }
                    return '#FFFFFF';
                },
            },
            ticks: {
                font: {
                    size: 10,
                    lineHeight: 1.2,
                    family: 'Poppins',
                },
            },
        },
    },
    // plugins: false, // if you want hide legend, color square and message
    plugins: {
        legend: {
            position: null,
        },
    },
    // animations: {
    //     tension: {
    //         duration: 0,
    //         easing: 'linear',
    //         from: 0,
    //         to: 0.4,
    //         loop: false
    //     },
    //     color: false
    //
    // },
    transitions: {
        active: {
            animation: {
                duration: 0
            }
        }
    },
    animation: false
};

const LineChartRevenue = (props) => {
    const {data, loading} = props;
    return (
        <LoadMask loading={loading} dark blur>
            <div className="p-2 flex-1">
                <div className="row justify-content-end mx-auto px-3">
                    <a className="pointer flex-row align-items-center row px-0" style={{fontSize: '0.7rem'}}
                       href="#/stats/revenue">
                        View all stats
                        <i className="material-icons">arrow_right_alt </i>
                    </a>
                </div>
                <Line
                    data={data}
                    options={options}
                    height={180}
                />
            </div>
        </LoadMask>
    );
};

export default LineChartRevenue;
