import { v4 as uuidv4 } from 'uuid';
import {handleActions} from 'redux-actions';
import {toList2 as toList2Roster, searchChange2, setProvideAgentsRoster} from './agentRoster'
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {api} from "api";
import _ from "lodash"
import {push} from "react-router-redux";
import {SELECT_ALL_OPTION} from "../../../utility/constants"
import {change, initialize} from "redux-form";

// ------------------------------------
// Constants
// ------------------------------------
const SET_FILES_AGENT = 'SET_FILES_AGENT';
const SET_STATES = 'SET_STATES_AGENT';
const SET_CITIES = 'SET_CITIES_AGENT';
const SET_STATES_OFFICE = 'SET_STATES_OFFICE_AGENT';
const SET_CITIES_OFFICE = 'SET_CITIES_OFFICE_AGENT';
const SET_FRANCHISES = 'SET_FRANCHISES_AGENT';
const SET_BROKERS = 'SET_BROKERS_AGENT';
const SET_BROKERAGES = 'SET_BROKERAGES_AGENT';
const SET_STATS = 'SET_STATS_AGENT';
const SET_WORKING_AREA = 'SET_WORKING_AREA_AGENT';
const SET_NOTES_LOADER = 'SET_NOTES_LOADER_GENT';
const SET_NOTES_ITEM_LOADER = 'SET_NOTES_ITEM_LOADER_GENT';
const SET_NOTES_PAGE = 'SET_NOTES_PAGE_GENT';
const SET_NOTES_DATA = 'SET_NOTES_DATA_GENT';
const SET_NOTES_ITEM = 'SET_NOTES_ITEM_GENT';
const REQUEST_UUID = 'REQUEST_UUID_GENT';
const SET_TEAMS = 'SET_TEAMS_AGENT';
const SET_INFLUENCERS = 'SET_INFLUENCERS_AGENT';


//-------------------------------------
// Base reducer
//-------------------------------------
const basicReducer = createReducer(
    "agent",
    "registered_agents",
    "updateAgentForm",
    "/agents",
);

// ------------------------------------
// Pure Actions
// ------------------------------------

const setOptionsStates = states => ({
    type: SET_STATES,
    states,
});
const setOptionsCities = cities => ({
    type: SET_CITIES,
    cities,
});

const setOptionsStatesOffice = officeStates => ({
    type: SET_STATES_OFFICE,
    officeStates,
});
const setOptionsCitiesOffice = officeCities => ({
    type: SET_CITIES_OFFICE,
    officeCities,
});

const setOptionsFranchises = franchises => ({
    type: SET_FRANCHISES,
    franchises,
});
const setBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});

const setBrokerages = (brokerages) => ({
    type: SET_BROKERAGES,
    brokerages,
});
const setStats = (stats) => ({
    type: SET_STATS,
    stats,
});
const setWorkingArea = (workingArea) => ({
    type: SET_WORKING_AREA,
    workingArea,
});
const setNotesItemLoader = (notesItemLoader) => ({
    type: SET_NOTES_ITEM_LOADER,
    notesItemLoader,
});
const setNotesLoader = (notesLoader) => ({
    type: SET_NOTES_LOADER,
    notesLoader,
});
const setNotesPage = (notesPage) => ({
    type: SET_NOTES_PAGE,
    notesPage,
});
const setNotesData = (notesData) => ({
    type: SET_NOTES_DATA,
    notesData,
});
export const setNotesItem = (notesItem) => ({
    type: SET_NOTES_ITEM,
    notesItem,
});
export const setRequestUUID = (requestUUID) => ({
    type: REQUEST_UUID,
    requestUUID,
});
export const setTeams = (teams) => ({
    type: SET_TEAMS,
    teams,
});

export const setInfluencers = (influencers) => ({
    type: SET_INFLUENCERS,
    influencers,
});

// ------------------------------------
// Actions
// ------------------------------------

const changedPasse = (id, data) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.post(`registered_agents/changed_passe`, {...data, 'id': id})
        .then((response) => {
            NotificationManager.success('Password updated', 'SUCCESS', 1000);
            dispatch(push(`/agents/agent/${id}/edit/`))
        }).catch(() => {
        NotificationManager.error('Change Password failed', 'Error', 1000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}
const changedBrokerPassword = (id, data, goBack) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.post(`broker/change_password`, { ...data, 'id': id })
        .then((response) => {
            NotificationManager.success('Password updated', 'SUCCESS', 1000);
            goBack()
        }).catch(() => {
            NotificationManager.error('Change Password failed', 'Error', 1000);
        }).finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const readBrokerUser = id => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`broker-user/${id}`).then((response) => {
        dispatch(basicReducer.actions.setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const getAgentUser = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`agent-registered/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const onChange = (data) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.postAttachments('registered_agents', {}, [{name: "rosters", file: data}])
        .then((response) => {
            if (response.results === "already exist") {
                NotificationManager.info('File already exist', 'NOTIFICATIONS', 0);
            } else {
                dispatch(basicReducer.actions.toList())
            }
        })
        .catch(() => {
            NotificationManager.error('Upload file failed', 'ERROR', 0);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const update = (data = {}, attachments = []) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    const store = getStore()
    const {workingArea} = store["agent"]

    const working_area =  {...data.working_area, ...workingArea}
    api.putAttachments(`registered_agents/${data.id}`, {...data, working_area}, attachments).then((response) => {
        // dispatch(push('/agents'));
        NotificationManager.success('Agent updated', 'SUCCESS', 1000);
        dispatch(basicReducer.actions.read(id))
    }).catch(() => {
        NotificationManager.error('Error, please try again', 'ERROR', 1000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const editAgent = (id, data) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    const store = getStore()
    const {workingArea} = store["agent"]
    const working_area =  {...data.working_area, ...workingArea}
    api.put(`registered_agents/${id}`, {...data, working_area}).then(() => {
        NotificationManager.success('Record Updated', 'Success', 3000);
        dispatch(basicReducer.actions.read(id))
        // dispatch(push('/agents'));
    }).catch(() => {
        NotificationManager.error('Update failed', 'ERROR', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

const activateAgent = (id, data) => (dispatch, getStore) => {
    dispatch(basicReducer.actions.setLoader(true));
    const store = getStore()
    const {workingArea} = store["agent"]
    const working_area =  {...data.working_area, ...workingArea}
    api.put(`registered_agents/${id}/activate_agent`, {...data, working_area}).then(() => {
        NotificationManager.success('Agent activated', 'Success', 3000);
        dispatch(basicReducer.actions.read(id))
    }).catch(() => {
        NotificationManager.error('Activate failed', 'ERROR', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

export const toList2 = (page = 1, loader=true) => (dispatch, getStore) => {
    const store = getStore()
    const resource = store['agent'];
    let params = {page};
    params.ordering = resource.ordering;
    // params.search = resource.search;
    const uuid = uuidv4()
    dispatch(setRequestUUID(uuid))
    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { state_registered, city_registered, state, franchise, broker, brokerage, registered_status, search, operation_access, team, influencer } =
            AgentFiltersForm.values;
        if (state_registered !== "" && state_registered !== null && state_registered !== undefined && state_registered !== SELECT_ALL_OPTION["value"]) {
            if (state_registered.length == 0) {
                params.state__in = "null"
            }else {
                params.state__in = state_registered;
            }
        }
        if (city_registered !== "" && city_registered !== null && city_registered !== undefined && city_registered !== SELECT_ALL_OPTION["value"]) {
            if (city_registered.length == 0) {
                params.city__in = "null"
            }else {
                params.city__in = city_registered;
            }
        }
        // if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
        //     // params.brokerage_information__broker__state__in = state;
        //     if (state.length == 0) {
        //         params.brokerage_information__broker__state__in = "null"
        //     }else {
        //         params.brokerage_information__broker__state__in = state;
        //     }
        // }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage_information__broker__franchise__id__in = franchise;
            if (franchise.length == 0) {
                params.franchise = 0
            }else {
                params.franchise = franchise;
            }
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage_information__broker__id__in = broker;
            if (broker.length == 0) {
                params.brokerage_information__broker__id__in = 0
            }else {
                params.brokerage_information__broker__id__in = broker;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage_information__id__in = brokerage;
            if (brokerage.length == 0) {
                params.brokerage_information__id__in = 0
            }else {
                params.brokerage_information__id__in = brokerage;
            }
        }
        if (registered_status !== "" && registered_status !== null && registered_status !== undefined && registered_status !== SELECT_ALL_OPTION["value"]) {
            if (registered_status.length == 0) {
                params.agent_account_status__in = 0
            }else {
                params.agent_account_status__in = registered_status;
            }
            // params.agent_account_status__in = registered_status;
        }
        if (search !== "" && search !== null && search !== undefined) {
            params.search = search;
        }
        if (operation_access !== "" && operation_access !== null && operation_access !== undefined && operation_access !== SELECT_ALL_OPTION["value"]) {
            if (operation_access.length == 0) {
                params.brokerage_information__broker__operation_access__in = 0
            }else {
                params.brokerage_information__broker__operation_access__in = operation_access;
            }
        }
        if (team !== "" && team !== null && team !== undefined && team !== SELECT_ALL_OPTION["value"]) {
            if (team.length == 0) {
                params.team_id = 0
            }else {
                params.team_id = team;
            }
        }
        if (influencer !== "" && influencer !== null && influencer !== undefined && influencer !== SELECT_ALL_OPTION["value"]) {
            if (influencer.length == 0) {
                params.team__influencer_id = 0
            }else {
                params.team__influencer_id = team;
            }
        }
    }
    if (loader) {
        dispatch(basicReducer.actions.setLoader(true));
    }
    api.get('registered_agents', params)
        .then((response) => {
            const store = getStore()
            const otherUUID = store.agent.requestUUID;
            if (otherUUID == uuid) {
                dispatch(basicReducer.actions.setData(response));
                dispatch(basicReducer.actions.setPage(page));
            }
        })
        .catch(() => {
        })
        .finally(() => {
            if (loader) {
                dispatch(basicReducer.actions.setLoader(false));
            }
        });
};



const blockUserTypeAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`agent-registered/${id}/to_block`)
        .then(response => {
            NotificationManager.success('Block agent successfully', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            NotificationManager.error('Block agent failed, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const unblockUserTypeAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`agent-registered/${id}/unblock`)
        .then(response => {
            NotificationManager.success('Unblock user successfully', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            NotificationManager.error('Failed Unblock agent, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const activeUserTypeAgent = (id) => (dispatch, getStore) => {
    const page = getStore()['agent'].page;
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`agent-registered/${id}/validate`)
        .then(response => {
            NotificationManager.success('The user was successfully validated', 'SUCCESS', 2000);
            dispatch(toList2(page));
        })
        .catch((error) => {
            NotificationManager.error('Failed validate agent, please try again later', 'ERROR', 2000);
            console.log('error:', error);
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
};

const customSearchChange = search => (dispatch) => {
    dispatch(basicReducer.actions.setSearch(search));
    dispatch(toList2());
};


const initializeFiltersForm = () => (dispatch, getStore) => {
    const resource = getStore()['agent'];
    dispatch(initialize('agentForm', {
        filterCategory: resource.category,
        filterBroker: resource.idBroker ? resource.idBroker : null,
        filterOffice: resource.idOffice ? resource.idOffice : null
    }));
    if (resource.idBroker === '' || resource.idBroker === null) {
        dispatch(getOffices())
    } else {
        dispatch(getOffices(resource.idBroker));
    }
}


const setFilesAgent = (files) => (dispatch) => {
    dispatch({
        type: SET_FILES_AGENT,
        files,
    });
};

const goPage = (url) => (dispatch) => {
    dispatch(push(url));
};

export const changeFilters = (type, value=null) => (dispatch, getStore) => {
    console.log("TYPE:, ",  type);
    switch (type) {
        case 'state':
            setTimeout(() => {
                dispatch(getOfficeCitiesOption(true, true))
            }, 500)
            break;
        case 'city':
            setTimeout(() => {
                dispatch(toList2Roster())
                dispatch(getStats())
            }, 500)
            break;
        case 'state_registered':
            setTimeout(() => {
                dispatch(getCitiesOption(true, true))
            }, 500)
            break;
        case 'city_registered':
            setTimeout(() => {
                dispatch(toList2())
                dispatch(getStats())
            }, 500)
            break;
        case 'franchise':
            setTimeout(() => {
                dispatch(getBrokerOption(true))
            }, 500)
            break;
        case 'broker':
            setTimeout(() => {
                dispatch(getBrokeragesOption(true))
            }, 500)
            break;
        case 'brokerage':
            setTimeout(() => {
                const {brokerages} = getStore()['agent']
                dispatch(toList2())
                dispatch(toList2Roster())
                dispatch(getStats())
                if (value && value.length) {
                    const _value = value[0]
                    if (_value != SELECT_ALL_OPTION["value"]) {
                        const brokerage = _.find(brokerages, {value: _value})
                        if (brokerage && brokerage.provide_agents_roster) {
                            dispatch(setProvideAgentsRoster(true))
                        }else {
                            dispatch(setProvideAgentsRoster(null))
                        }
                    }
                }else {
                    dispatch(setProvideAgentsRoster(null))
                }
            }, 500)
            break;
        case 'registered_status':
            setTimeout(() => {
                dispatch(toList2())
            }, 500)
            break;
        case 'unregistered_status':
            setTimeout(() => {
                dispatch(toList2Roster())
            }, 500)
            break;
        case 'search':
            dispatch(basicReducer.actions.setSearch(value));
            setTimeout(() => {
                dispatch(toList2());
                dispatch(searchChange2(value))
            }, 400);
            break;
        case 'operation_access':
            setTimeout(() => {
                dispatch(toList2())
            }, 500)
            break;
        case 'team':
            setTimeout(() => {
                dispatch(toList2())
                dispatch(toList2Roster())
                dispatch(getStats())
            }, 500)
            break;
        default:
            break;
    }
}

export const getStatesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { AgentFiltersForm } = store.form;
    let stateValue = null;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const {state_registered} = AgentFiltersForm.values;
        if (state_registered !== "" && state_registered !== null && state_registered !== undefined && state_registered !== SELECT_ALL_OPTION["value"]) {
            if (state_registered.length == 0) {
                stateValue = SELECT_ALL_OPTION["value"]
            }else {
                stateValue = state_registered
            }
        }
    }
    api.get("general/option_states", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && stateValue === null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "state_registered",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (stateValue !== null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "state_registered",
                            stateValue
                        )
                    );
                }
            } else {
                dispatch(
                    change(
                        "AgentFiltersForm",
                        "state_registered",
                        SELECT_ALL_OPTION["value"],
                    )
                );
                setTimeout(() => {
                    dispatch(getCitiesOption())
                }, 500);
            }
            dispatch(setOptionsStates(options));
        })
        .catch(() => {
            dispatch(setOptionsStates([]));
            dispatch(change("AgentFiltersForm", "state_registered", SELECT_ALL_OPTION["value"]));
            setTimeout(() => {
                dispatch(getCitiesOption())
            }, 500);
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList2())
                    dispatch(getStats())
                }, 500)
            }
        });
};

export const getOfficeStatesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { AgentFiltersForm } = store.form;
    let stateValue = null;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const {state} = AgentFiltersForm.values;
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                stateValue = SELECT_ALL_OPTION["value"]
            }else {
                stateValue = state
            }
        }
    }
    api.get("general/option_states", {category: "OFFICE"})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && stateValue === null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "state",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (stateValue !== null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "state",
                            stateValue
                        )
                    );
                }
            } else {
                dispatch(
                    change(
                        "AgentFiltersForm",
                        "state",
                        SELECT_ALL_OPTION["value"],
                    )
                );
                setTimeout(() => {
                    dispatch(getOfficeCitiesOption())
                }, 500);
            }
            dispatch(setOptionsStatesOffice(options));
        })
        .catch(() => {
            dispatch(setOptionsStatesOffice([]));
            dispatch(change("AgentFiltersForm", "state", SELECT_ALL_OPTION["value"]));
            setTimeout(() => {
                dispatch(getOfficeCitiesOption())
            }, 500);
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList2Roster())
                    dispatch(getStats())
                }, 500)
            }
        });
};

export const getCitiesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { AgentFiltersForm } = store.form;
    let citiesValue = null;
    let state = ''
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const {city_registered, state_registered} = AgentFiltersForm.values;
        if (city_registered !== "" && city_registered !== null && city_registered !== undefined && city_registered !== SELECT_ALL_OPTION["value"]) {
            if (city_registered.length == 0) {
                citiesValue = SELECT_ALL_OPTION["value"]
            }else {
                citiesValue = city_registered
            }
        }

        if (state_registered !== "" && state_registered !== null && state_registered !== undefined && state_registered !== SELECT_ALL_OPTION["value"]) {
            if (state_registered.length == 0) {
                state = ''
            }else {
                state = state_registered.length == 1 ? state_registered[0] : ''
            }
        }
    }
    api.get("general/option_cities", {state})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && citiesValue === null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "city_registered",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (citiesValue !== null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "city_registered",
                            citiesValue
                        )
                    );
                }
            } else {
                dispatch(
                    change(
                        "AgentFiltersForm",
                        "city_registered",
                        SELECT_ALL_OPTION["value"],
                    )
                );
            }
            dispatch(setOptionsCities(options));
        })
        .catch(() => {
            dispatch(setOptionsCities([]));
            dispatch(change("AgentFiltersForm", "city_registered", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList2())
                    dispatch(getStats())
                }, 500)
            }
        });
};

export const getOfficeCitiesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { AgentFiltersForm } = store.form;
    let citiesValue = null;
    let stateValue = ''
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const {city, state} = AgentFiltersForm.values;
        if (city !== "" && city !== null && city !== undefined && city !== SELECT_ALL_OPTION["value"]) {
            if (city.length == 0) {
                citiesValue = SELECT_ALL_OPTION["value"]
            }else {
                citiesValue = city
            }
        }

        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            if (state.length == 0) {
                stateValue = ''
            }else {
                stateValue = state.length == 1 ? state[0] : ''
            }
        }
    }
    api.get("general/option_cities", {state: stateValue, category: "OFFICE"})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && citiesValue === null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "city",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (citiesValue !== null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "city",
                            citiesValue
                        )
                    );
                }
            } else {
                dispatch(
                    change(
                        "AgentFiltersForm",
                        "city",
                        SELECT_ALL_OPTION["value"],
                    )
                );
            }
            dispatch(setOptionsCitiesOffice(options));
        })
        .catch(() => {
            dispatch(setOptionsCitiesOffice([]));
            dispatch(change("AgentFiltersForm", "city", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList2Roster())
                    dispatch(getStats())
                }, 500)
            }
        });
};

export const getFranchisesOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { AgentFiltersForm } = store.form;
    let franchiseValue = null;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const {franchise} = AgentFiltersForm.values;
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            if (franchise.length == 0) {
                franchiseValue = SELECT_ALL_OPTION["value"]
            }else {
                franchiseValue = franchise
            }
        }
    }
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                
                // let value = [];
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                //     value.push(franchise.id)
                // });
                if(selectAll && franchiseValue === null) {
                    console.log("FRANCHISE ALL: ", selectAll);
                    console.log("FRANCHISE ALL: ", franchiseValue);
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "franchise",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (franchiseValue !== null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "franchise",
                            franchiseValue
                        )
                    );
                }
            } else {
                // response.results.map((franchise) => {
                //     options.push({
                //         label: franchise.name,
                //         value: franchise.id,
                //     });
                // });
                const franchise_id = options.length ? options[0].value : "";
                console.log("FRANCHISE ID: ", franchise_id);
                dispatch(
                    change(
                        "AgentFiltersForm",
                        "franchise",
                        franchise_id !== "" ? [franchise_id] : SELECT_ALL_OPTION["value"],
                    )
                );
                setTimeout(() => {
                    dispatch(getBrokerOption())
                }, 500);
            }
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
            dispatch(change("AgentFiltersForm", "franchise", SELECT_ALL_OPTION["value"]));
            setTimeout(() => {
                dispatch(getBrokerOption())
            }, 500);
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList2())
                    dispatch(toList2Roster())
                    dispatch(getStats())
                }, 500)
            }
        });
};

export const getBrokerOption =
    (getData = null, selectAll=false, brokerId=null, brokerageId=null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { AgentFiltersForm } = store.form;
        let params = { state: "", franchise: "", is_multi: true };
        let brokerValue = null;
        if (AgentFiltersForm && AgentFiltersForm.values) {
            const { state, franchise, broker} = AgentFiltersForm.values;
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                // params.franchise = franchise;
                if (franchise.length == 0) {
                    params.franchise = ""
                }else {
                    params.franchise = franchise;
                }
            }
            if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                if (broker.length == 0) {
                    brokerValue = SELECT_ALL_OPTION["value"]
                }else {
                    brokerValue = broker
                }
            }
            // if (state !== "" && state !== null && state !== undefined) {
            //     params.state = state;
            // }
        }

        api.get(`general/option_broker`, params)
            .then((response) => {
                console.log("RESP BRK: ". response);
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (selectAll && brokerId == null && brokerValue == null ) {
                        dispatch(
                            change("AgentFiltersForm", "broker", SELECT_ALL_OPTION["value"])
                        );
                    }
                    if (brokerValue !== null && brokerId == null) {
                        dispatch(
                            change("AgentFiltersForm", "broker", brokerValue)
                        );
                    }
                    if (brokerId !== null) {
                        dispatch(
                            change("AgentFiltersForm", "broker", [brokerId])
                        );
                    }
                    setTimeout(() => {
                        if (brokerId !== null) {
                            dispatch(getBrokeragesOption(getData, false, brokerageId));
                        }else {
                            dispatch(getBrokeragesOption(getData, selectAll, brokerageId));
                        }
                    }, 500);
                } else {
                    option = [...response.results];
                    // console.log("OPT: ", option);
                    // const broker_id = option.length ? option[0].value : "";
                    dispatch(
                        change("AgentFiltersForm", "broker", SELECT_ALL_OPTION["value"])
                    );
                    setTimeout(() => {
                        dispatch(getBrokeragesOption(getData, selectAll, brokerageId));
                    }, 500);
                }
                dispatch(setBrokers(option));
            })
            .catch(() => {
                dispatch(setBrokers([]));
                dispatch(change("AgentFiltersForm", "broker", ""));
                setTimeout(() => {
                    dispatch(getBrokeragesOption(getData, selectAll, brokerageId));
                }, 500);
            })
            .finally(() => {
                // if (getData) {
                //     setTimeout(() => {
                //         dispatch(toList2())
                //         dispatch(toList2Roster())
                //         dispatch(getStats())
                //     }, 500)
                // }
            });
    };

export const getBrokeragesOption =
    (getData = null, selectAll=false, brokerageId=null) =>
    (dispatch, getStore) => {
        const store = getStore();
        const { AgentFiltersForm } = store.form;
        let params = { broker_id: "", state: "", franchise: "", is_multi: true };
        let brokerageValue = null;
        if (AgentFiltersForm && AgentFiltersForm.values) {
            const { state, franchise, broker, brokerage } = AgentFiltersForm.values;
            if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
                // params.broker_id = broker;
                if (broker.length == 0) {
                    params.broker_id = ""
                }else { 
                    params.broker_id = broker;
                }
            }
            // if (state !== "" && state !== null && state !== undefined) {
            //     params.state = state;
            // }
            if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
                // params.franchise = franchise;
                if (franchise.length == 0) {
                    params.franchise = ""
                }else {
                    params.franchise = franchise;
                }
            }

            if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
                if (brokerage.length == 0) {
                    brokerageValue = SELECT_ALL_OPTION["value"]
                }else {
                    brokerageValue = brokerage
                }
            }
        }

        api.get(`general/option_brokerage`, params)
            .then((response) => {
                let option = [];
                if (response.results.length > 1) {
                    option = response.results;
                    if (brokerageId !== null) {
                        dispatch(
                            change("AgentFiltersForm", "brokerage", [brokerageId])
                        );
                    }
                    if (selectAll && brokerageId == null && brokerageValue == null) {
                        // let value = [];
                        // response.results.forEach(item => {
                        //     value.push(item.value)
                        // })
                        
                        dispatch(
                            change(
                                "AgentFiltersForm",
                                "brokerage",
                                SELECT_ALL_OPTION["value"]
                            )
                        );
                    }

                    if(brokerageValue !== null) {
                        dispatch(
                            change(
                                "AgentFiltersForm",
                                "brokerage",
                                brokerageValue
                            )
                        );
                    }
                } else {
                    option = [...response.results];
                    // const brokerage_id = option.length ? option[0].value : "";
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "brokerage",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                    if (option.length) {
                        if (option[0].provide_agents_roster) {
                            dispatch(setProvideAgentsRoster(true))
                        }else {
                            dispatch(setProvideAgentsRoster(null))
                        }
                    }else {
                        dispatch(setProvideAgentsRoster(null))
                    }
                }
                dispatch(setBrokerages(option));
            })
            .catch(() => {
                dispatch(setBrokerages([]));
                dispatch(change("AgentFiltersForm", "brokerage", ""));
            })
            .finally(() => {
                if (getData) {
                    setTimeout(() => {
                        dispatch(toList2())
                        dispatch(toList2Roster())
                        dispatch(getStats())
                    }, 500)
                }
            });
    };

export const getStats = () => (dispatch, getStore) => {
    const store = getStore()
    let params = {};
    const { AgentFiltersForm } = store.form;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const { state_registered, city_registered, state, city, franchise, broker, brokerage, registered_status } =
            AgentFiltersForm.values;
        if (state_registered !== "" && state_registered !== null && state_registered !== undefined && state_registered !== SELECT_ALL_OPTION["value"]) {
            if (state_registered.length == 0) {
                params.state_registered = "null"
            }else {
                params.state_registered = state_registered;
            }
        }
        if (city_registered !== "" && city_registered !== null && city_registered !== undefined && city_registered !== SELECT_ALL_OPTION["value"]) {
            if (city_registered.length == 0) {
                params.city_registered = "null"
            }else {
                params.city_registered = city_registered;
            }
        }
        if (state !== "" && state !== null && state !== undefined && state !== SELECT_ALL_OPTION["value"]) {
            // params.state = state;
            if (state.length == 0) {
                params.office_state = "null"
            }else {
                params.office_state = state;
            }
        }
        if (city !== "" && city !== null && city !== undefined && city !== SELECT_ALL_OPTION["value"]) {
            if (city.length == 0) {
                params.office_city = "null"
            }else {
                params.office_city = city;
            }
        }
        if (franchise !== "" && franchise !== null && franchise !== undefined && franchise !== SELECT_ALL_OPTION["value"]) {
            // params.franchise = franchise;
            if (franchise.length == 0) {
                params.franchise = 0
            } else {
                params.franchise = franchise;
            }
        }
        if (broker !== "" && broker !== null && broker !== undefined && broker !== SELECT_ALL_OPTION["value"]) {
            // params.broker = broker;
            if (broker.length == 0) {
                params.broker = 0
            }else {
                params.broker = broker;
            }
        }
        if (brokerage !== "" && brokerage !== null && brokerage !== undefined && brokerage !== SELECT_ALL_OPTION["value"]) {
            // params.brokerage = brokerage;
            if (brokerage.length == 0) {
                params.brokerage = 0
            }else {
                params.brokerage = brokerage;
            }
        }
    }
    api.get('registered_agents/stats', params)
        .then((response) => {
            if (response) {
                dispatch(setStats(response.results))
            }else {
                dispatch(setStats({
                    total_agent: 0,
                    total_registered: 0,
                    total_user_active: 0
                }))
            }
        })
        .catch(() => {
            dispatch(setStats({
                total_agent: 0,
                total_registered: 0,
                total_user_active: 0
            }))
        })
};

const removeAgent = id => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.eliminar(`registered_agents/${id}`).then(() => {
        dispatch(push('/agents'));
        NotificationManager.success('Agent removed', 'Success', 3000);
    }).catch(() => {
        NotificationManager.error('Transaction error', 'Error', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
};

export const initBrokerBrokerageFormOptions = (id) =>(dispatch) => {
    dispatch(initialize("updateAgentForm", null));
    api.get(`general/option_broker`, {})
        .then(async (response) => {
            let options = [];
            options = [
                {
                    label: "",
                    value: "",
                },
                ...response.results,
            ];
            dispatch(setBrokers(options));
            await api.get(`general/option_brokerage`, {})
                .then((response) => {
                    let options = [];
                    options = [
                        {
                            label: "",
                            value: "",
                        },
                        ...response.results,
                    ];
                    dispatch(setBrokerages(options));
                })
                .catch(() => {
                    dispatch(setBrokerages([]));
                })
                .finally(() => {
                });

            dispatch(basicReducer.actions.read(id))
        })
        .catch(() => {
            dispatch(setBrokers([]));
        })
        .finally(() => {
        });
}

export const getBrokerFormOption =
    (value = null, typeFilter = null, getData = null) =>
        (dispatch, getStore) => {
            const store = getStore();

            api.get(`general/option_broker`, {})
                .then((response) => {
                    let options = [];
                    options = [
                        {
                            label: "",
                            value: "",
                        },
                        ...response.results,
                    ];
                    dispatch(setBrokers(options));
                })
                .catch(() => {
                    dispatch(setBrokers([]));
                })
                .finally(() => {
                });
        };

export const getBrokerageFormOption =
    (broker_id=null) =>
        (dispatch, getStore) => {
            const store = getStore();
            let params = {}
            if (broker_id) {
                params.broker_id = broker_id
            }
            api.get(`general/option_brokerage`, params)
                .then((response) => {
                    let options = [];
                    options = [
                        {
                            label: "",
                            value: "",
                        },
                        ...response.results,
                    ];
                    dispatch(setBrokerages(options));
                })
                .catch(() => {
                    dispatch(setBrokerages([]));
                })
                .finally(() => {
                });
        };

// ------------------------------------
// Actions
// ------------------------------------

export const listLockNotes = (user_id, page = 1) => (dispatch, getStore) => {
    const resource = getStore()["agent"];
    const params = { page, user_id, type: 100 };
    // params.ordering = resource.ordering;
    // params.search = resource.search;
    dispatch(setNotesLoader(true));
    api.get('lock_note', params).then((response) => {
        dispatch(setNotesData(response));
        dispatch(setNotesPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setNotesLoader(false));
    });
};

export const createLockNotes = (user_id, data, onCloseModal=null) => (dispatch, getStore) => {
    dispatch(setNotesItemLoader(true));
    const body = { ...data, user: user_id, type: 100 };
    api.post('lock_note', body).then((response) => {
        dispatch(listLockNotes(user_id))
        if(onCloseModal) onCloseModal()
        NotificationManager.success('Note created successfully', 'SUCCESS', 2000);
    }).catch(() => {
        NotificationManager.error('The note was not registered, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const readLockNotes = (id, user_id=null, onOpenModal=null) => (dispatch) => {
    let params = {};
    if (user_id) {
        params.user_id = user_id
    }
    dispatch(setNotesLoader(true));
    api.get(`lock_note/${id}`, params).then((response) => {
        dispatch(setNotesItem(response));
        if (onOpenModal) {
            dispatch(initialize('lockNoteForm', {note: response.note ? response.note : ''}))
            onOpenModal()
        }
    }).catch(() => {
        dispatch(setNotesItem(null));
        dispatch(initialize('lockNoteForm', {}))
    }).finally(() => {
        dispatch(setNotesLoader(false));
    });
};

export const editLockNotes = (id, data,  onCloseModal=null) => (dispatch) => {
    dispatch(setNotesItemLoader(true));
    api.put(`lock_note/${id}`, {...data, type: 100}).then((response) => {
        dispatch(setNotesItem(null));
        if (onCloseModal) {
            dispatch(initialize('lockNoteForm', {}))
            onCloseModal()
        }
        dispatch(listLockNotes(data.user))
        NotificationManager.success('Note updated successfully', 'SUCCESS', 2000);
    }).catch(() => {
        NotificationManager.error('The note was not updated, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const deleteLockNotes = (id, user_id, onCloseModal=null) => (dispatch) => {
    dispatch(setNotesItemLoader(true));
    api.eliminar(`lock_note/${id}`).then((response) => {
        dispatch(setNotesItem(null));
        if (onCloseModal) {
            dispatch(initialize('lockNoteForm', {}))
            onCloseModal()
        }
        dispatch(listLockNotes(user_id))
        NotificationManager.success("Note deleted successfully", 'SUCCESS', 2000)
    }).catch(() => {
        NotificationManager.error('It was not possible to delete the note', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const resetFilterForm = () => (dispatch) => {
    dispatch(
        initialize("AgentFiltersForm", {
            state: SELECT_ALL_OPTION["value"],
            registered_status: SELECT_ALL_OPTION["value"],
            unregistered_status: SELECT_ALL_OPTION["value"],
        })
    );
};

//  PENDING NOTES
export const listPendingNotes = (user_id, page = 1) => (dispatch, getStore) => {
    const resource = getStore()["agent"];
    const params = { page, user_id, type: 200 };
    // params.ordering = resource.ordering;
    // params.search = resource.search;
    dispatch(setNotesLoader(true));
    api.get('lock_note', params).then((response) => {
        dispatch(setNotesData(response));
        dispatch(setNotesPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setNotesLoader(false));
    });
};

export const createPendingNotes = (user_id, data, onCloseModal=null) => (dispatch, getStore) => {
    dispatch(setNotesItemLoader(true));
    const body = { ...data, user: user_id, type: 200 };
    api.post('lock_note', body).then((response) => {
        dispatch(listPendingNotes(user_id))
        if(onCloseModal) onCloseModal()
        NotificationManager.success('Note created successfully', 'SUCCESS', 2000);
    }).catch(() => {
        NotificationManager.error('The note was not registered, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const readPendingNotes = (id, user_id=null, onOpenModal=null) => (dispatch) => {
    let params = {};
    if (user_id) {
        params.user_id = user_id
    }
    dispatch(setNotesLoader(true));
    api.get(`lock_note/${id}`, params).then((response) => {
        dispatch(setNotesItem(response));
        if (onOpenModal) {
            dispatch(initialize('lockNoteForm', {note: response.note ? response.note : ''}))
            onOpenModal()
        }
    }).catch(() => {
        dispatch(setNotesItem(null));
        dispatch(initialize('lockNoteForm', {}))
    }).finally(() => {
        dispatch(setNotesLoader(false));
    });
};

export const editPendingNotes = (id, data,  onCloseModal=null) => (dispatch) => {
    dispatch(setNotesItemLoader(true));
    api.put(`lock_note/${id}`, {...data, type: 200}).then((response) => {
        dispatch(setNotesItem(null));
        if (onCloseModal) {
            dispatch(initialize('lockNoteForm', {}))
            onCloseModal()
        }
        dispatch(listPendingNotes(data.user))
        NotificationManager.success('Note updated successfully', 'SUCCESS', 2000);
    }).catch(() => {
        NotificationManager.error('The note was not updated, try again later', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const deletePendingNotes = (id, user_id, onCloseModal=null) => (dispatch) => {
    dispatch(setNotesItemLoader(true));
    api.eliminar(`lock_note/${id}`).then((response) => {
        dispatch(setNotesItem(null));
        if (onCloseModal) {
            dispatch(initialize('lockNoteForm', {}))
            onCloseModal()
        }
        dispatch(listPendingNotes(user_id))
        NotificationManager.success("Note deleted successfully", 'SUCCESS', 2000)
    }).catch(() => {
        NotificationManager.error('It was not possible to delete the note', 'Error', 3000);
    }).finally(() => {
        dispatch(setNotesItemLoader(false));
    });
};

export const moveToOpenMarketOffice = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.put(`registered_agents/${id}/move_to_temp_office`, {}).then(() => {
        NotificationManager.success('The agent was successfully moved', 'Success', 3000);
        dispatch(basicReducer.actions.read(id))
    }).catch(() => {
        NotificationManager.error('Activate failed', 'ERROR', 3000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

export const getItemFranchises = () => (dispatch, getStore) => {
    api.get("general/option_franchise", {})
        .then((response) => {
            let options = response.results;
            // response.results.map((franchise) => {
            //     options.push({
            //         label: franchise.name,
            //         value: franchise.id,
            //     });
            // });
            dispatch(setOptionsFranchises(options));
        })
        .catch(() => {
            dispatch(setOptionsFranchises([]));
        })
        .finally(() => {
        });
};

export const getTeamOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { AgentFiltersForm } = store.form;
    let teamValue = null;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const {team} = AgentFiltersForm.values;
        if (team !== "" && team !== null && team !== undefined && team !== SELECT_ALL_OPTION["value"]) {
            if (team.length == 0) {
                teamValue = SELECT_ALL_OPTION["value"]
            }else {
                teamValue = team
            }
        }
    }
    api.get("general/option_team", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && teamValue === null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "team",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (teamValue !== null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "team",
                            teamValue
                        )
                    );
                }
            } else {
                // const team_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "AgentFiltersForm",
                        "team",
                        SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setTeams(options));
        })
        .catch(() => {
            dispatch(setTeams([]));
            dispatch(change("AgentFiltersForm", "team", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList2())
                    dispatch(toList2Roster())
                    dispatch(getStats())
                }, 500)
            }
        });
};

export const getInfluencerOption = (getData = null, selectAll=false) => (dispatch, getStore) => {
    const store = getStore();
    const { AgentFiltersForm } = store.form;
    let influencerValue = null;
    if (AgentFiltersForm && AgentFiltersForm.values) {
        const {influencer} = AgentFiltersForm.values;
        if (influencer !== "" && influencer !== null && influencer !== undefined && influencer !== SELECT_ALL_OPTION["value"]) {
            if (influencer.length == 0) {
                influencerValue = SELECT_ALL_OPTION["value"]
            }else {
                influencerValue = influencer
            }
        }
    }
    api.get("general/option_influencer", {})
        .then((response) => {
            let options = response.results;
            if (response.results.length > 1) {
                if(selectAll && influencerValue === null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "influencer",
                            SELECT_ALL_OPTION["value"]
                        )
                    );
                }

                if (influencerValue !== null) {
                    dispatch(
                        change(
                            "AgentFiltersForm",
                            "influencer",
                            influencerValue
                        )
                    );
                }
            } else {
                // const influencer_id = options.length ? options[0].value : "";
                dispatch(
                    change(
                        "AgentFiltersForm",
                        "influencer",
                        SELECT_ALL_OPTION["value"]
                    )
                );
            }
            dispatch(setInfluencers(options));
        })
        .catch(() => {
            dispatch(setInfluencers([]));
            dispatch(change("AgentFiltersForm", "influencer", SELECT_ALL_OPTION["value"]));
        })
        .finally(() => {
            if (getData) {
                setTimeout(() => {
                    dispatch(toList2())
                    dispatch(toList2Roster())
                    dispatch(getStats())
                }, 500)
            }
        });
};

export const getItemTeams = () => (dispatch, getStore) => {
    api.get("general/option_team", {})
        .then((response) => {
            let options = [{label: 'None', value: null}, ...response.results];
            dispatch(setTeams(options));
        })
        .catch(() => {
            dispatch(setTeams([]));
        })
        .finally(() => {
        });
};
export const changeFilterFieldForm = (value, field) => (dispatch) => {
    dispatch(change('AgentFiltersForm', field, value))
}

const changedInfluencerPassword = (id, data, goBack=null) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.post(`registered_agents/changed_passe`, {...data, 'id': id})
        .then((response) => {
            NotificationManager.success('Password updated', 'SUCCESS', 1000);
            if (goBack) {
                goBack()
            }
        }).catch(() => {
        NotificationManager.error('Change Password failed', 'Error', 1000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

const getVendor = (id) => (dispatch) => { 
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`vendor_user/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const getWholeSaler = (id) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.get(`wholesaler/${id}`)
        .then((response) => {
            dispatch(basicReducer.actions.setItem(response));
        })
        .catch((error) => {
        })
        .finally(() => {
            dispatch(basicReducer.actions.setLoader(false));
        });
}

const changedWholeSalerPassword = (id, data, goBack=null) => (dispatch) => {
    dispatch(basicReducer.actions.setLoader(true));
    api.post(`wholesaler/changed_password`, {...data, 'id': id})
        .then((response) => {
            NotificationManager.success('Password updated', 'SUCCESS', 1000);
            if (goBack) {
                goBack()
            }
        }).catch(() => {
        NotificationManager.error('Change Password failed', 'Error', 1000);
    }).finally(() => {
        dispatch(basicReducer.actions.setLoader(false));
    });
}

export const actions = {
    update,
    toList2,
    onChange,
    editAgent,
    changedPasse,
    setFilesAgent,
    blockUserTypeAgent,
    unblockUserTypeAgent,
    customSearchChange,
    initializeFiltersForm,
    activeUserTypeAgent,
    goPage,
    changeFilters,
    getFranchisesOption,
    getBrokerOption,
    getBrokeragesOption,
    getStats,
    setWorkingArea,
    removeAgent,
    initBrokerBrokerageFormOptions,
    getBrokerageFormOption,
    listLockNotes,
    changedBrokerPassword,
    readBrokerUser,
    activateAgent,
    resetFilterForm,
    ...basicReducer.actions,
    listPendingNotes,
    createPendingNotes,
    readPendingNotes,
    editPendingNotes,
    deletePendingNotes,
    moveToOpenMarketOffice,
    getItemFranchises,
    getTeamOption,
    getItemTeams,
    changeFilterFieldForm,
    getAgentUser,
    changedInfluencerPassword,
    getVendor,
    getWholeSaler,
    changedWholeSalerPassword,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    ...basicReducer.reducers,
    [SET_FILES_AGENT]: (state, {files}) => {
        return {
            ...state,
            files,
        };
    },
    [SET_STATES]: (state, {states}) => {
        return {
            ...state,
            states,
        };
    },
    [SET_CITIES]: (state, {cities}) => {
        return {
            ...state,
            cities,
        };
    },
    [SET_STATES_OFFICE]: (state, {officeStates}) => {
        return {
            ...state,
            officeStates,
        };
    },
    [SET_CITIES_OFFICE]: (state, {officeCities}) => {
        return {
            ...state,
            officeCities,
        };
    },
    [SET_FRANCHISES]: (state, {franchises}) => {
        return {
            ...state,
            franchises,
        };
    },
    [SET_BROKERS]: (state, {brokers}) => {
        return {
            ...state,
            brokers,
        };
    },
    [SET_BROKERAGES]: (state, {brokerages}) => {
        return {
            ...state,
            brokerages,
        };
    },
    [SET_STATS]: (state, {stats}) => {
        return {
            ...state,
            stats,
        };
    },
    [SET_WORKING_AREA]: (state, {workingArea}) => {
        return {
            ...state,
            workingArea,
        };
    },
    [SET_NOTES_LOADER]: (state, {notesLoader}) => {
        return {
            ...state,
            notesLoader,
        };
    },
    [SET_NOTES_ITEM_LOADER]: (state, {notesItemLoader}) => {
        return {
            ...state,
            notesItemLoader,
        };
    },
    [SET_NOTES_PAGE]: (state, {notesPage}) => {
        return {
            ...state,
            notesPage,
        };
    },
    [SET_NOTES_DATA]: (state, {notesData}) => {
        return {
            ...state,
            notesData,
        };
    },
    [SET_NOTES_ITEM]: (state, {notesItem}) => {
        return {
            ...state,
            notesItem,
        };
    },
    [REQUEST_UUID]: (state, {requestUUID}) => {
        return {
            ...state,
            requestUUID,
        };
    },
    [SET_TEAMS]: (state, {teams}) => {
        return {
            ...state,
            teams,
        };
    },
    [SET_INFLUENCERS]: (state, {influencers}) => {
        return {
            ...state,
            influencers,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    files: [],
    brokers: [],
    brokerages: [],
    franchises: [],
    teams: [],
    states: [],
    cities: [],
    officeStates: [],
    officeCities: [],
    influencers: [],
    stats: {
        total_agent: 0,
        total_registered: 0,
        total_user_active: 0
    },
    workingArea: {
        latitude_center: 0,
        longitude_center: 0,
    },
    notesLoader: false,
    notesItemLoader: false,
    notesPage: 1,
    notesData: {
        results: [],
        count: 0,
    },
    notesItem: null,
    ...basicReducer.initialState,
    requestUUID: null,
};

export default handleActions(reducers, initialState);
